import currentInstitutionSlice from './currentInstitutionSlice';
import userPermissionsSlice from './userPermissionsSlice';
import notificationsReducer from './notificationsSlice';
import onlineStatusReducer from './onlineStatusSlice';
import institutionsSlice from './institutionsSlice';
import { configureStore } from '@reduxjs/toolkit';
import userDataSlice from './userDataSlice';

const store = configureStore({
  reducer: {
    notifications: notificationsReducer,
    userData: userDataSlice,
    currentInstitution: currentInstitutionSlice,
    userPermissions: userPermissionsSlice,
    institutions: institutionsSlice,
    onlineStatus: onlineStatusReducer,
  },
});

export default store;