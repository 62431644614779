import { Alert, Typography, Box, Button, TextField, Stack, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem, FormHelperText, Grid } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState, useEffect, useCallback } from 'react';
import { StyledFieldset } from "../../utils/styledFieldSet";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';
import { AddItemPoilicy } from "./addItemPolicy";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Page from "../Page";
import * as Yup from 'yup';
import axios from "axios";
import '../../css/policies/addPolicy.css';

export const AddPolicy = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [msg, setMsg] = useState('');
    const [label, setLabel] = useState('');
    const [isPublic, setIsPublic] = useState(null);
    const [type, setType] = useState('');
    const [newVersion, setNewVersion] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [index, setIndex] = useState("");
    const [descTitle, setDescTitle] = useState("");
    const [descSubTitle, setDescSubTitle] = useState("");
    const [description, setDescription] = useState([]);
    const [openAddItemsDialog, setOpenAddItemsDialog] = useState(false);

    const LANGS = [
        {
            value: 'en',
            label: t('Eng'),
            icon: '/images/ic_flag_en.svg',
        },
        {
            value: 'fr',
            label: t('Fr'),
            icon: '/images/ic_flag_fr.svg',
        },
    ];

    const fetchCountries = useCallback(async () => {
        try {
            const response = await axios.get('institutions/getCountries');
            if (response.status === 200 || response.status === 201) {
                setCountries(response.data.map(country => country.name));
            }
        } catch (error) {
            console.log('Error fetching countries:', error);
        }
    }, []);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    const validationSchema = Yup.object().shape({
        label: Yup.string().trim().required(t('titleRequired')),
        language: Yup.string().trim().required(t('LanguageRequired')),
        description: Yup.array()
            .of(
                Yup.object().shape({
                    descTitle: Yup.string().trim().required('descTitle is required'),
                    descSubTitle: Yup.string().trim().required('descSubtitle is required'),

                })
            )
            .required('Description is required')
            .min(1, 'At least one description is required'),
    });

    const data = {
        label,
        description,
        version: uuidv4(),
        language: selectedLanguage,
        isPublic,
        type,
        countryName: selectedCountry,
        newVersion
    };

    const handleAdd = (event) => {
        event.preventDefault();
        axios
            .post(`policies`, data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    navigate(-1)
                }
            })
            .catch((err) => { console.log(err.message); setMsg(err.message) });
    };

    const handleEditPolicies = (item, index) => {
        setIndex(index)
        setDescTitle(item.descTitle)
        setDescSubTitle(item.descSubTitle)
        setOpenAddItemsDialog(true)
    }

    const handleRemovePolicy = (item) => {
        const allPolicies = [...description];
        const index = allPolicies.indexOf(item);
        allPolicies.splice(index, 1);
        setDescription(allPolicies);
    };

    const handleBackBtn = () => {
        navigate(-1);
        setMsg("")
    };

    return (
        <Page title={t("addPolicy")}>

            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                <ArrowBackIosIcon className='addPolicy-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                <Stack className="addPolicy-main-page-header">
                    <Box className="addPolicy-page-title-box">
                        <Typography className='addPolicy-page-title'>{t("addPolicy")}</Typography>
                    </Box>
                </Stack>
            </Box>

            {msg && <Alert severity="error">{msg}</Alert>}
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="dense"
                            id="name"
                            name="mainPolicyTitle"
                            label={t('label')}
                            required
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setLabel(e.target.value)}
                            error={!label}
                            helperText={!label && (t('isRequired'))}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            required
                            fullWidth
                            margin="dense"
                            error={!type}
                        >
                            <InputLabel id="demo-simple-select-label-policyType">{t('policyType')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-policyType"
                                id="gender"
                                label={t('policyType')}
                                name="gender"
                                defaultValue=""
                                onChange={event => setType(event.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="generalAccessPolicy">{t('generalAccessPolicy')}</MenuItem>
                                <MenuItem value="medicalFilePolicy">{t('medicalFilePolicy')}</MenuItem>
                                <MenuItem value="medicalFileSharingPolicy">{t('medicalFileSharingPolicy')}</MenuItem>
                            </Select>
                            {!type && <FormHelperText style={{ color: 'rgb(252, 72, 72)', marginLeft: '14px' }}>{t('isRequired')}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            required
                            fullWidth
                            margin="dense"
                            error={isPublic === null}
                        >
                            <InputLabel id="demo-simple-select-label-isPublic">{t('policyScope')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-policyType"
                                id="gender"
                                label={t('policyScope')}
                                name="gender"
                                defaultValue=""
                                onChange={event => setIsPublic(event.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={true}>{t('allCountries')}</MenuItem>
                                <MenuItem value={false}>{t('specificCountry')}</MenuItem>
                            </Select>
                            {isPublic === null && <FormHelperText style={{ color: 'rgb(252, 72, 72)', marginLeft: '14px' }}>{t('isRequired')}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <StyledFieldset component="fieldset" >
                            <legend>{t('newVersionOrLanguage')}</legend>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={newVersion}
                                name="radio-buttons-group"
                                row
                                onChange={event => setNewVersion(event.target.value === 'true')}
                            >
                                <FormControlLabel value={false} control={<Radio />} label={t('newLanguage')} />
                                <FormControlLabel value={true} control={<Radio />} label={t('newVersion')} />
                            </RadioGroup>
                        </StyledFieldset>
                    </Grid>

                    {isPublic === false &&
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                labelId="demo-multiple-chip-label-selectCountry"
                                id="countries"
                                name="countries"
                                options={countries}
                                getOptionLabel={(option) => t(option)}
                                onChange={(event, newValue) => setSelectedCountry(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label={t('country')}
                                        variant="outlined"
                                        error={!selectedCountry}
                                        helperText={!selectedCountry && (t('isRequired'))}
                                    />
                                )}
                            />
                        </Grid>
                    }

                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth
                            required
                            error={!selectedLanguage}
                        >
                            <InputLabel id="demo-multiple-chip-label-selectLanguage">{t('lang')}</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label-selectLanguage"
                                id="type"
                                name="type"
                                label={t('lang')}
                                onChange={(e) => setSelectedLanguage(e.target.value)}
                                value={selectedLanguage}
                            >
                                {LANGS.map((item, keyid) => (
                                    <MenuItem key={keyid} value={item.value}>{t(item.label)}</MenuItem>
                                ))}
                            </Select>
                            {!selectedLanguage && <FormHelperText style={{ color: 'rgb(252, 72, 72)', marginLeft: '14px' }}>{t('isRequired')}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

                <Box>
                    {description && description.map((item, index) => (
                        <Box
                            key={index}
                            style={{
                                backgroundColor: '#f0f0f0',
                                padding: '10px',
                                marginBottom: '10px',
                                marginTop: '10px',
                                position: 'relative',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >

                            <Box>
                                <Typography variant="h6" sx={{ whiteSpace: 'pre-wrap' }}>{item.descTitle}</Typography>
                                <Typography noWrap={false} sx={{ whiteSpace: 'pre-wrap' }}>{item.descSubTitle}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <EditIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleEditPolicies(item, index)}
                                />

                                <CloseRoundedIcon
                                    onClick={() => handleRemovePolicy(item)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>

                        </Box>
                    ))}
                </Box>

                <AddCircleIcon
                    onClick={() => setOpenAddItemsDialog(true)}
                    sx={{ color: '#34697E', marginLeft: '50%', marginTop: '5%', height: '100%', fontSize: '50px', cursor: 'pointer' }} />
            </Box>

            {openAddItemsDialog &&
                <AddItemPoilicy
                    index={index}
                    setIndex={setIndex}
                    descTitle={descTitle}
                    setDescTitle={setDescTitle}
                    descSubTitle={descSubTitle}
                    setDescSubTitle={setDescSubTitle}
                    description={description}
                    setDescription={setDescription}
                    openAddItemsDialog={openAddItemsDialog}
                    setOpenAddItemsDialog={setOpenAddItemsDialog}
                />
            }

            <Box className="addPolicy-actions-box">
                <Button onClick={() => handleBackBtn()} className='new-button'>{t('cancel')}</Button>
                <Button className='new-button' onClick={handleAdd} disabled={!validationSchema.isValidSync(data) || (type === 'specific' && selectedCountry === '')}>{t('add')}</Button>
            </Box>
        </Page >
    )
}