import { Box, styled } from "@mui/material";

export const StyledFieldset = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 0, 0, 2),
    marginTop: theme.spacing(0),
    '& legend': {
        padding: `0 ${theme.spacing(1)}`,
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
    },
}));