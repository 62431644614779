import { MedicalFileTemplatesStatistics } from '../components/dashboard/Medical_File_templates_Statistics';
import { MedicalFileStatistics } from '../components/dashboard/Medical_File_Statistics';
import { AppWidget } from '../components/dashboard/AppWidget';
import { HasPermission } from '../utils/checkUserPermission';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import '../css/dashboard/dashboardApp.css';
import { MedicalFileSpecialityExpertiseStatistics } from '../components/dashboard/Medical_File_Speciality_Expertise_Statistics';

export const DashboardApp = () => {

  const { t } = useTranslation();
  const userData = useSelector(state => state.userData);
  const currentInstitution = useSelector(state => state.currentInstitution);
  //check user permissions
  const isAllowedReadAllMedicalFile = HasPermission('read_All_Medical_File');
  const isAllowedReadInstitutionMedicalFile = HasPermission('read_institution_Medical_File');
  const isAllowedReadMyMedicalFile = HasPermission('read_my_Medical_File');
  const isAllowedReadMyInstitutionTemplate = HasPermission('read_myInstitution_template');
  const isAllowedReadMyOwnTemplate = HasPermission('read_myOwn_template');

  return (
    <Page title={t("sideBar-Dashboard")}>
      <Box sx={{ display: 'flex', gap: '5px' }}>
        <Typography variant="h4" sx={{ mb: 5, marginLeft: '20px' }}>
          {t('welcomeBack')}
        </Typography>
        <Typography className='imeah_propre_name' variant="h4" sx={{ mb: 5 }}>{userData.firstname} {userData.lastname} 👋</Typography>
      </Box>

      {!currentInstitution ?
        <Typography sx={{ backgroundColor: 'lavender', padding: '25px', borderRadius: '10px' }}>{t('componentComingSoon')}</Typography> :
        <Box>
          <AppWidget />
          <Box className='dashboard-medical-statistics-box' >
            {(isAllowedReadAllMedicalFile || isAllowedReadInstitutionMedicalFile || isAllowedReadMyMedicalFile) && <MedicalFileStatistics />}
            {(isAllowedReadAllMedicalFile || isAllowedReadInstitutionMedicalFile || isAllowedReadMyMedicalFile) && <MedicalFileSpecialityExpertiseStatistics />}
            {(isAllowedReadMyInstitutionTemplate || isAllowedReadMyOwnTemplate) && <MedicalFileTemplatesStatistics />}
          </Box>
        </Box>
      }

    </Page>
  );
}
