import { Alert, Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import Iconify from '../../Iconify';
import Page from '../../Page';
import * as Yup from 'yup';
import axios from 'axios';

export const EditPermissionGroup = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    const [englishName, setEnglishName] = useState('');
    const [frenchName, setFrenchName] = useState('');
    const [englishDescription, setEnglishDescription] = useState('');
    const [frenchDescription, setFrenchDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const validationSchema = Yup.object().shape({
        englishName: Yup.string().trim().required(t('permissionGroupNameRequired')),
        frenchName: Yup.string().trim().optional(),
        englishDescription: Yup.string().trim().required(t('permissionGroupDescriptionRequired')),
        frenchDescription: Yup.string().trim().optional(),
    });

    const fetchPermission = useCallback(async () => {
        try {
            await axios.get(`permission-entity/${id}`)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        const { PermissionEntityName, PermissionEntityDescription } = response.data;

                        const englishName = PermissionEntityName.find(record => record.language === 'en')?.label || '';
                        const frenchName = PermissionEntityName.find(record => record.language === 'fr')?.label || '';
                        const englishDescription = PermissionEntityDescription.find(record => record.language === 'en')?.label || '';
                        const frenchDescription = PermissionEntityDescription.find(record => record.language === 'fr')?.label || '';

                        setEnglishName(englishName);
                        setFrenchName(frenchName);
                        setEnglishDescription(englishDescription);
                        setFrenchDescription(frenchDescription);
                    }
                })

        } catch (err) {
            console.error(err);
        }
    }, [id]);

    useEffect(() => {
        fetchPermission();
    }, [fetchPermission]);

    const handleEditPermissionGroup = async () => {
        setIsLoading(true);
        try {
            const bodyData = {
                englishName: englishName.trim(),
                frenchName: frenchName.trim(),
                englishDescription: englishDescription.trim(),
                frenchDescription: frenchDescription.trim(),
            };

            await validationSchema.validate(bodyData, { abortEarly: false });

            const response = await axios.patch(`permission-entity/${id}`, bodyData);
            if (response.status === 200 || response.status === 201) {
                navigate(-1)
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                // If validation fails, set the error message
                setErrMsg(err?.errors?.join(` ${t('and')} `));
            } else {
                // If API call fails, set the error message
                console.error('Error adding role:', err);
                setErrMsg(err.message);
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <Page title={t("editPermissionGroup")}>

            <Stack className="role-main-page-header">
                <Box className="role-page-title-box">
                    <Box className="role-page-title-icon-box">
                        <Iconify className="role-page-title-icon" icon="arcticons:permissionsmanager" />
                        <Typography className='role-page-title'>{t("editPermissionGroup")}</Typography>
                    </Box>
                </Box>
            </Stack>

            {errMsg && <Alert severity="error">{errMsg}</Alert>}

            <TextField
                id="permissionEnglishName"
                name="name"
                margin="dense"
                label={t('englishName')}
                type="text"
                fullWidth
                required
                variant="outlined"
                value={englishName}
                onChange={(e) => setEnglishName(e.target.value)}
                error={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName)}
                helperText={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName) && t('isRequired')}
            />
            <TextField
                id="permissionFrenchName"
                name="name"
                margin="dense"
                label={t('frenchName')}
                type="text"
                fullWidth
                variant="outlined"
                value={frenchName}
                onChange={(e) => setFrenchName(e.target.value)}
            />
            <TextField
                id="permissionEnglishDescription"
                name="name"
                margin="dense"
                label={t('englishDescription')}
                type="text"
                fullWidth
                required
                variant="outlined"
                value={englishDescription}
                onChange={(e) => setEnglishDescription(e.target.value)}
                error={!Yup.reach(validationSchema, 'englishDescription').isValidSync(englishDescription)}
                helperText={!Yup.reach(validationSchema, 'englishDescription').isValidSync(englishDescription) && t('isRequired')}
            />
            <TextField
                id="permissionFrenchDescription"
                name="name"
                margin="dense"
                label={t('frenchDescription')}
                type="text"
                fullWidth
                variant="outlined"
                value={frenchDescription}
                onChange={(e) => setFrenchDescription(e.target.value)}
            />

            <Box sx={{ display: 'flex', gap: '5px', position: 'fixed', bottom: '10px', right: '15px', zIndex: '1' }}>
                <Button onClick={() => navigate(-1)} className='new-button'>{t('cancel')}</Button>
                <LoadingButton loading={isLoading} className='new-button' onClick={handleEditPermissionGroup}>{t('save')}</LoadingButton>
            </Box>

        </Page>
    )
}