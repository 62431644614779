import { Autocomplete, TextField, CircularProgress, Box, FormControl } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const MuiMultiSelect = (props) => {

    const { t } = useTranslation();
    const { label, value = [], onChange, fetchFunction, required, searchable, className, valueField = 'value', labelField = 'label' } = props;

    const [options, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const [inputValue, setInputValue] = useState("");
    const limit = 10;

    const fetchOptions = useCallback(async (page, searchTerm) => {
        try {
            const newOptions = await fetchFunction(page, searchTerm);
            setOptions(prevOptions => page === 0 ? newOptions : [...prevOptions, ...newOptions]);
            setHasMore(newOptions.length >= limit);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setIsFetching(false);
        }
    }, [fetchFunction]);

    useEffect(() => {
        if (isFetching) {
            fetchOptions(page, search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, page, search]);

    const handleScroll = (event) => {
        const bottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 1;
        if (bottom && hasMore && !isFetching) {
            setPage(prevPage => prevPage + 1);
            setIsFetching(true);
        }
    };

    const handleOpen = () => {
        if (options.length === 0 && !isFetching) {
            setIsFetching(true);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue !== search) {
            setOptions([]);
            setPage(0);
            setSearch(newInputValue);
            setIsFetching(true);
        }
    };

    const selectedOptions = useMemo(() => {
        return value.map(val => val || { [valueField]: val, [labelField]: val });
    }, [value, valueField, labelField]);

    return (
        <Box>
            <FormControl fullWidth margin="dense">
                <Autocomplete
                    multiple
                    id="autocomplete-select"
                    value={selectedOptions}
                    onChange={(event, newValues) => {
                        onChange(newValues);
                    }}
                    onOpen={handleOpen}
                    options={options}
                    getOptionLabel={(option) => option[labelField] || ""}
                    isOptionEqualToValue={(option, value) => option[valueField] === value[valueField]}
                    onInputChange={searchable ? handleInputChange : undefined}
                    inputValue={inputValue}
                    disableCloseOnSelect={true}
                    renderInput={(params) => (
                        <TextField
                            className={className}
                            required={required}
                            {...params}
                            label={label}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isFetching ? <CircularProgress size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                            error={!value.length && required}
                            helperText={(!value.length && required) && ((t('isRequired')))}
                        />
                    )}
                    ListboxProps={{
                        onScroll: handleScroll,
                        style: {
                            maxHeight: 300,
                        },
                    }}
                />
            </FormControl>
        </Box>
    );
};