export const CanCopyTemplate = (template, AuthenticatedUser) => {

    const { filteredPermissions, institutionId } = AuthenticatedUser;
    const { type } = template;

    const userPermissions = filteredPermissions || [];

    const isAllowedCopyDefaultTemplate = userPermissions?.some(item => item.trim() === 'copy_default_template') ?? false;
    const isAllowedCopyPublicTemplate = userPermissions?.some(item => item.trim() === 'copy_public_template') ?? false;
    const isAllowedCopyPrivateTemplate = userPermissions?.some(item => item.trim() === 'copy_private_template') ?? false;

    // Determine the type of the template
    const isDefaultTemplate = type === 'default';
    const isPublicTemplate = type === 'public';
    const isPrivateTemplate = type === 'private';

    const isSameInstitution = template.institutionId  === institutionId;
    const isForImeah = template.institutionId  === 'imeah';

    // Return the result of the conditions
    return (
        (isAllowedCopyDefaultTemplate && isDefaultTemplate && (isSameInstitution || isForImeah)) ||
        (isAllowedCopyPublicTemplate && isPublicTemplate && (isSameInstitution || isForImeah)) ||
        (isAllowedCopyPrivateTemplate && isPrivateTemplate && isSameInstitution)
    );
}