import { Box, Typography, Card, Button, TextField, Snackbar, Alert } from '@mui/material'
import { useFormik, Form, FormikProvider } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import '../../css/homePage/contact_us.css';
import React, { useState } from 'react';
import Scrollbar from '../Scrollbar';
import * as Yup from 'yup';
import axios from 'axios';

export const ContactUs = () => {

    const useStyles = makeStyles((theme) => ({
        notchedOutline: {
            borderWidth: "1px",
            borderColor: "#09C3CC !important",
        },
    }));

    const { t } = useTranslation();
    const classes = useStyles();

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            profession: "",
            organization: "",
            message: "",
        },
        validationSchema: Yup.object().shape({
            first_name: Yup.string().trim().required(t("fstnmReq")),
            last_name: Yup.string().trim().required(t("lstnmReq")),
            email: Yup.string().trim().email(t("validEml")).required(t("emailReq")),
            profession: Yup.string().trim().required(t("profession_Required")),
            organization: Yup.string().trim().required(t("Organization_required")),
            message: Yup.string().trim().required(t("message_required")),
        }),
        onSubmit: (values, { resetForm }) => {
            axios.post(`contact-us`, values)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        resetForm();
                        setOpenSnackbar(true);
                    }
                })
                .catch((err) => console.log(err));
        },
    });
    const { errors, touched, values, handleBlur, handleSubmit } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Scrollbar>
                    <Box className='contactUs-main-box'>
                        <Card className='contactUs-card'>
                            <Typography className='contactUs-title'>{t("contactUs")} !</Typography>

                            <Box className='contactUs-inputs-container'>
                                <Box className='contactUs-input-container'>
                                    <Typography className='contactUs-label'>{t('firstname')+ ' *'}</Typography>
                                    <TextField
                                        fullWidth
                                        required
                                        id="first_name"
                                        name="first_name"
                                        className="contactUs-input"
                                        placeholder='John'
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                        value={values.first_name}
                                        onBlur={handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(touched.first_name && errors.first_name)}
                                        helperText={touched.first_name && errors.first_name}
                                    />

                                </Box>
                                <Box className='contactUs-input-container'>
                                    <Typography className='contactUs-label'>{t('lastname')+ ' *'}</Typography>
                                    <TextField
                                        fullWidth
                                        id="last_name"
                                        name="last_name"
                                        className="contactUs-input"
                                        placeholder='Doe'
                                        InputProps={{
                                            classes: {
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                        value={values.last_name}
                                        onBlur={handleBlur}
                                        onChange={formik.handleChange}
                                        error={Boolean(touched.last_name && errors.last_name)}
                                        helperText={touched.last_name && errors.last_name}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography className='contactUs-label'>{t('email')+ ' *'}</Typography>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    placeholder='John.doe@gmail.com'
                                    className="contactUs-input"
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={values.email}
                                    onBlur={handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Box>
                            <Box>
                                <Typography className='contactUs-label'>{t('profession')+ ' *'}</Typography>
                                <TextField
                                    fullWidth
                                    id="profession"
                                    name="profession"
                                    placeholder={t("professionExample")}
                                    className="contactUs-input"
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={values.profession}
                                    onBlur={handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(touched.profession && errors.profession)}
                                    helperText={touched.profession && errors.profession}
                                />
                            </Box>
                            <Box>
                                <Typography className='contactUs-label'>{t('Institution_Organization')+ ' *'}</Typography>
                                <TextField
                                    fullWidth
                                    id="organization"
                                    name="organization"
                                    placeholder={t("Institution_OrganizationExample")}
                                    className="contactUs-input"
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    value={values.organization}
                                    onBlur={handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(touched.organization && errors.organization)}
                                    helperText={touched.organization && errors.organization}
                                />
                            </Box>
                            <Box>
                                <Typography className='contactUs-label'>{t("message")+ ' *'}</Typography>
                                <TextField
                                    fullWidth
                                    id="message"
                                    name="message"
                                    placeholder={t('messageExapmle')}
                                    className="contactUs-input"
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.notchedOutline,
                                        },
                                    }}
                                    multiline
                                    rows={4}
                                    value={values.message}
                                    onBlur={handleBlur}
                                    onChange={formik.handleChange}
                                    error={Boolean(touched.message && errors.message)}
                                    helperText={touched.message && errors.message}
                                />

                            </Box>
                            <Button className='contactUs-Button' type="submit">{t('send')}</Button>

                        </Card>
                    </Box >
                </Scrollbar>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>{t('thankYourFeedbackMessage')}</Alert>
                </Snackbar>
            </Form>
        </FormikProvider>
    )
}