import { Box, Button, Card, CardContent, Divider, CardActions } from '@mui/material';
import { ReadUserAvailability } from './userAvailability/readUserAvailability';
import { AddUserAvailability } from './userAvailability/addUserAvailability';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DeleteInstitutionsUser } from '../deleteInstitutionsUser';
import { HasPermission } from '../../../utils/checkUserPermission';
import { EditAccountProfileData } from './editAccountProfileData';
import { UserProfileCredentials } from './userProfileCredentials';
import { AddGuestToInstitution } from './addGuestToInstitution';
import { ProfileSkillsSummary } from './profileSkillsSummary';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddEducation } from './CV/education/AddEducation';
import { EducationCV } from "./CV/education/educationCV";
import { AddSummary } from './CV/summary/addSummary';
import '../../../css/User-css/accountProfile.css';
import { ProfilePicCard } from './profilePicCard';
import { ProfileDetails } from './profileDetails';
import { AddSkills } from './CV/skills/addSkill';
import { useTranslation } from 'react-i18next';
import { AddWork } from './CV/work/addWork';
import { WorkCV } from "./CV/work/workCv";
import MapComponent from './userLocation';
import { useSelector } from 'react-redux';
import Page from "../../Page";
import axios from 'axios';

export default function UserProfile(props) {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useSelector(state => state.userData);
  const currentInstitution = useSelector(state => state.currentInstitution);

  const isSuperAdmin = currentInstitution?.role?.id === 'super_admin';
  const isGuest = !currentInstitution || location.state.institutionUserId === 'No userInstitutionId';

  const isAllowedCreateAllUsers = HasPermission('Create_All_User');
  const isAllowedEditAllUsers = HasPermission('Edit_All_Users');
  const isAllowedEditInstitutionUsers = HasPermission('Edit_Institution_User');
  const isAllowedDeleteAllUsers = HasPermission('Delete_All_Users');

  const [institutionUserData, setInstitutionUserData] = useState([]);
  const [userInfo, setUserInfo] = useState('');
  const [education, setEducation] = useState([]);
  const [work, setWork] = useState([]);
  const [skills, setSkills] = useState([]);
  const [summary, setSummary] = useState("");
  const [rolePermissins, setRolePermissins] = useState([]);
  const [customPermissions, setCustomPermissions] = useState([]);
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState("");
  const [userCvId, setUserCvId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddGuest, setOpenAddGuest] = useState(false);
  const [inVacation, setInVacation] = useState({ checked: false, endVaccation: "" });
  const [availability, setAvailability] = useState({
    monday: { checked: "" },
    tuesday: { checked: "" },
    wednesday: { checked: "" },
    thursday: { checked: "" },
    friday: { checked: "" },
    saturday: { checked: "" },
    sunday: { checked: "" }
  });

  const Sections = useMemo(() => [
    currentInstitution && { label: t('availability'), value: "Availability" },
    { label: t('summary'), value: "Summary" },
    { label: t('education'), value: "Education" },
    { label: t('work'), value: "Work" },
    { label: t('skills'), value: "Skills" },
  ].filter(Boolean), [currentInstitution, t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(`users/${location.state?.userId}`);
        if (userResponse.status === 200 || userResponse.status === 201) {
          const userData = userResponse.data;
          setUserInfo(userData);
          if (userData.cv) {
            setEducation(userData.cv.education || []);
            setWork(userData.cv.workExperience || []);
            setSummary(userData.cv.summary || "");
            setSkills(userData.cv.skills || []);
          }
        }
      } catch (error) {
        console.error(error);
      }

      if (location.state?.institutionUserId) {
        try {
          const response = await axios.get(`institutions-users/${location.state?.institutionUserId}`);
          if (response.status === 200 || response.status === 201) {
            const institutionData = response.data.data;
            setInstitutionUserData(institutionData);
            setRolePermissins(response.data.rolePermissions || []);
            setCustomPermissions(response.data.customPermissions || []);
            setAvailability(institutionData?.UserAvailability?.availability || {});
            setInVacation(institutionData?.UserAvailability?.inVaccation || { checked: false, endVacation: "" });
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [location.state, userData]);

  const renderAddGuestToInstitution = () => (
    <AddGuestToInstitution
      setOpenAddGuest={setOpenAddGuest}
      email={userInfo.email}
    />
  );

  const renderActionButton = useCallback(() => {
    if (isGuest && isAllowedCreateAllUsers && userData.id !== userInfo.id) {
      return <Button fullWidth x={{ backgroundColor: '#17536B', color: 'white', '&:hover': { backgroundColor: '#749fe8', } }} onClick={() => setOpenAddGuest(true)}>{t('changeProfile')}</Button>;
    }
    if ((isAllowedEditInstitutionUsers && userData.id !== institutionUserData.user?.id && currentInstitution.institution?.id === institutionUserData?.institutionId) || (isAllowedEditAllUsers && userData.id !== institutionUserData.user?.id)) {
      return (
        <CardActions>
          <Button fullWidth sx={{ backgroundColor: '#17536B', color: 'white', '&:hover': { backgroundColor: '#749fe8', } }} onClick={() => navigate('edit', { state: { InstitutionUserData: institutionUserData, userInfo } })}>{t('editUsrInfo')}</Button>
        </CardActions>
      );
    }
    if (userData.id === userInfo.id) {
      return (
        <CardActions>
          <Button fullWidth sx={{ backgroundColor: '#17536B', color: 'white', '&:hover': { backgroundColor: '#749fe8', } }} onClick={() => setOpen(true)}>{t('editInfo')}</Button>
          {!isGuest && <Button fullWidth sx={{ backgroundColor: '#17536B', color: 'white', '&:hover': { backgroundColor: '#749fe8' } }} onClick={() => setOpenDelete(true)}>{t('leave_institution_button')}</Button>}
        </CardActions>
      );
    }
    return null;
  }, [isGuest, isAllowedCreateAllUsers, userData.id, userInfo, isAllowedEditInstitutionUsers, institutionUserData, currentInstitution?.institution?.id, isAllowedEditAllUsers, t, navigate]);

  const renderMainProfileCard = () => (
    <Box className='account-prof-page'>
      <Card {...props} className='account-prof-card'>
        <CardContent className="account-prof-card-content">
          <Box className="account-prof-card-pic-details-box">
            <ProfilePicCard userInfo={userInfo} setOpenDelete={setOpenDelete} isSuperAdmin={isSuperAdmin} isAllowedDeleteAllUsers={isAllowedDeleteAllUsers} location={location} isGuest={isGuest} Sections={Sections} setSection={setSection} />
            <ProfileDetails userInfo={userInfo} InstitutionUserData={institutionUserData} isGuest={isGuest} />
          </Box>
          <ProfileSkillsSummary userInfo={userInfo} skills={skills} summary={summary} setSection={setSection} />
        </CardContent>
        <Divider />
        {renderActionButton()}
      </Card>
      {/* {userData.id === userInfo?.id && <UserRequests />} */}
      {(userData.id === userInfo?.id && (rolePermissins.length > 0 || customPermissions.length > 0)) && <UserProfileCredentials rolePermissins={rolePermissins} customPermissions={customPermissions} />}
      <ReadUserAvailability availability={availability} inVaccation={inVacation} setInVaccation={setInVacation} setSection={setSection} userInfo={userInfo} />
      <WorkCV work={work} setWork={setWork} userCvId={userInfo?.id} setSection={setSection} />
      <EducationCV education={education} setEducation={setEducation} userCvId={userInfo?.id} setSection={setSection} />
    </Box>
  );

  const renderAddProfileSection = () => {
    switch (section) {
      case "Education":
        return <AddEducation setSection={setSection} education={education} setEducation={setEducation} setUserCvId={setUserCvId} />;
      case "Work":
        return <AddWork setSection={setSection} work={work} setWork={setWork} setUserCvId={setUserCvId} userCvId={userCvId} />;
      case "Summary":
        return <AddSummary setSection={setSection} summary={summary} setSummary={setSummary} />;
      case "Skills":
        return <AddSkills setSection={setSection} skills={skills} setSkills={setSkills} />;
      case "Availability":
        return <AddUserAvailability setSection={setSection} availability={availability} setAvailability={setAvailability} inVaccation={inVacation} setInVaccation={setInVacation} />;
      case "location":
        return <MapComponent setSection={setSection} userInfo={userInfo} />
      default:
        return openDelete &&
          <DeleteInstitutionsUser
            superAdmin='superAdmin'
            IdInstitutionUserForDelete={location.state.institutionUserId}
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
          />;
    }
  };

  return (
    <Page title={t("Profile_Page_Title")}>
      {openAddGuest ? renderAddGuestToInstitution() : renderMainProfileCard()}
      {open ? <EditAccountProfileData setOpen={setOpen} userInfo={userInfo} setUserInfo={setUserInfo} InstitutionUserData={institutionUserData} /> : renderAddProfileSection()}
    </Page>
  )

}