import { TextField, MenuItem, Dialog, DialogActions, DialogTitle, DialogContent, Button, Grid } from '@mui/material';
import { differenceInYears, differenceInMonths, differenceInDays } from 'date-fns';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { ImeahPhoneInput } from '../../../utils/imeah_phone_input';
import React, { useCallback, useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from "formik";
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import axios from 'axios';

export const EditStaticData = ({ medicalFileData, setMedicalFileData, editStaticData, setEditStaticData }) => {

    const { t } = useTranslation();

    const [changes, setChanges] = useState({});
    const [loading, setLoading] = useState(false);

    const editValidationSchema = Yup.object().shape({
        first_name: Yup.string().trim().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, t('firstNameOnlyLetters')).required(t('firstNameIsRequired')),
        last_name: Yup.string().trim().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, t('lastNameOnlyLetters')).required(t('lastNameIsRequired')),
        birthDate: Yup.date().max(new Date(), t('birthDateNotFuture')).required(t('birthDateIsRequired')),
        BirthPlace: Yup.string().trim().required(t('birthPlaceIsRequired')),
        address: Yup.string().trim().required(t('addressIsRequired')),
        Phone_number: Yup.string().trim().matches(/^\+?[0-9]+$/, t('PhoneNumberOnlyDigits')).optional(),
        age: Yup.string().trim().required(t('ageIsRequired')),
        ageUnit: Yup.string().trim().required(t('ageUnitIsRequired')),
        gender: Yup.string().trim().required(t('genderIsRequired')),
        Social_Security_Number: Yup.string().trim().optional(),
        patient_Email: Yup.string().trim().email(t('invalidEmail')).optional(),
    });

    const formik = useFormik({
        initialValues: {
            first_name: medicalFileData?.first_name,
            last_name: medicalFileData?.last_name,
            gender: medicalFileData?.gender,
            birthDate: medicalFileData?.birthDate,
            age: medicalFileData?.age,
            ageUnit: medicalFileData?.ageUnit,
            BirthPlace: medicalFileData?.BirthPlace,
            address: medicalFileData?.address,
            Phone_number: medicalFileData?.Phone_number,
            patient_Email: medicalFileData?.patient_Email,
            Social_Security_Number: medicalFileData?.Social_Security_Number,
        },
        enableReinitialize: true,
        validationSchema: editValidationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const newLogs = Object.entries(changes).map(([name, value]) => ({
                    description: `change "${name}" to "${value}"`,
                }));

                const response = await axios.patch(`medical-file/updateOneFileStaticData/${medicalFileData.id}`, { fileStaticData: values, newLogs });

                if (response.status === 200 || response.status === 201) {
                    setMedicalFileData(prevData => ({
                        ...prevData,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        gender: response.data.gender,
                        birthDate: response.data.birthDate,
                        age: response.data.age,
                        ageUnit: response.data.ageUnit,
                        BirthPlace: response.data.BirthPlace,
                        address: response.data.address,
                        Phone_number: response.data.Phone_number,
                        patient_Email: response.data.patient_Email,
                        Social_Security_Number: response.data.Social_Security_Number,
                        medical_Files_logs: response.data.medical_Files_logs
                    }));
                    setEditStaticData(false);
                    setChanges({});
                }
            } catch (error) {
                console.error('Error edit static info of medical file:', error);
                CustomErrorLogger(error, 'Error edit static info of medical file');
            } finally {
                setLoading(false);
            }
        }
    });

    const { errors, touched, values, setFieldValue, handleBlur, handleSubmit } = formik;

    const handleChangeStaticValues = useCallback((event) => {
        const { name, value } = event.target;
        setFieldValue(name, value); // Update formik values
        setChanges(prevChanges => ({ ...prevChanges, [name]: value }));

    }, [setFieldValue]);


    const calculateAge = useCallback((birthDate) => {
        const now = new Date();

        if (!birthDate || isNaN(birthDate.getTime())) return { age: "", ageUnit: "" };

        const years = differenceInYears(now, birthDate);
        if (years >= 1) {
            return { age: years, ageUnit: `year${years > 1 ? "s" : ""}` };
        }

        const months = differenceInMonths(now, birthDate);
        if (months >= 1) {
            return { age: months, ageUnit: `month${months > 1 ? "s" : ""}` };
        }

        const days = differenceInDays(now, birthDate);
        return { age: days, ageUnit: `day${days > 1 ? "s" : ""}` };
    }, []);

    useEffect(() => {
        if (values.birthDate) {
            const birthDate = new Date(values.birthDate);
            const { age, ageUnit } = calculateAge(birthDate);

            if (age !== values.age) setFieldValue("age", age.toString());
            if (ageUnit !== values.ageUnit) setFieldValue("ageUnit", ageUnit);
        }
    }, [values.birthDate, values.age, setFieldValue, calculateAge, values.ageUnit]);

    return (
        <Dialog open={editStaticData} keepMounted fullWidth>
            <DialogTitle className='imeahSubTitle'>{t('editpatientInfo')}</DialogTitle>

            <DialogContent >

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="first_name"
                                    name="first_name"
                                    fullWidth
                                    defaultValue={values.first_name}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues}
                                    type="text"
                                    label={t('firstname')}
                                    error={Boolean(touched.first_name && errors.first_name)}
                                    helperText={(touched.first_name && errors.first_name)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="last_name"
                                    name="last_name"
                                    fullWidth
                                    defaultValue={values.last_name}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues} type="text"
                                    label={t('lastname')}
                                    error={Boolean(touched.last_name && errors.last_name)}
                                    helperText={(touched.last_name && errors.last_name)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="birthDate"
                                    name="birthDate"
                                    fullWidth
                                    defaultValue={values.birthDate}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues} type="date"
                                    label={t('birthDate')}
                                    error={Boolean(touched.birthDate && errors.birthDate)}
                                    helperText={(touched.birthDate && errors.birthDate)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="gender"
                                    name="gender"
                                    fullWidth
                                    defaultValue={values.gender}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues} select
                                    label={t('gender')}
                                    error={Boolean(touched.gender && errors.gender)}
                                    helperText={(touched.gender && errors.gender)}
                                >
                                    <MenuItem key={'male'} value={'male'}>{t('male')}</MenuItem>
                                    <MenuItem key={'female'} value={'female'}>{t('female')}</MenuItem>
                                    <MenuItem key={'other'} value={'other'}>{t('other')}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="BirthPlace"
                                    name="BirthPlace"
                                    fullWidth
                                    defaultValue={values.BirthPlace}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues} type="text"
                                    label={t('Birth_Place')}
                                    error={Boolean(touched.BirthPlace && errors.BirthPlace)}
                                    helperText={(touched.BirthPlace && errors.BirthPlace)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="address"
                                    name="address"
                                    fullWidth
                                    defaultValue={values.address}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues} type="text"
                                    label={t('Address')}
                                    error={Boolean(touched.address && errors.address)}
                                    helperText={(touched.address && errors.address)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ImeahPhoneInput
                                    required={false}
                                    name="Phone_number"
                                    value={values.Phone_number}
                                    label={t('Enter_phone_number') + ' (' + t('optional') + ')'}
                                    onChange={(value) => handleChangeStaticValues({ target: { name: 'Phone_number', value } })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="Social_Security_Number"
                                    name="Social_Security_Number"
                                    fullWidth
                                    defaultValue={values.Social_Security_Number}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues}
                                    type="text"
                                    label={t('Social_Security_Number') + ' (' + t('optional') + ')'}
                                    error={Boolean(touched.Social_Security_Number && errors.Social_Security_Number)}
                                    helperText={(touched.Social_Security_Number && errors.Social_Security_Number)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className='customTextField'
                                    id="patient_Email"
                                    name="patient_Email"
                                    fullWidth
                                    defaultValue={values.patient_Email}
                                    onBlur={handleBlur}
                                    onChange={handleChangeStaticValues}
                                    type="text"
                                    label={t('patient_Email') + ' (' + t('optional') + ')'}
                                    error={Boolean(touched.patient_Email && errors.patient_Email)}
                                    helperText={(touched.patient_Email && errors.patient_Email)}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button className='buttonOutlined' onClick={() => setEditStaticData(false)} >{t("cancel")}</Button>
                            <LoadingButton className='buttonFilled' loading={loading} type="submit">{t("save")}</LoadingButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </DialogContent>
        </Dialog >

    );
}