import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box, Typography, IconButton, TextField, Checkbox } from '@mui/material';
import React, { useState, useCallback, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import Iconify from '../../Iconify';

const filter = createFilterOptions();

export const MultiValueInList = ({ allowedActions, info, item, handleUpdateOneListFieldData }) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false); // Open the dropdown
    const [value, setValue] = useState(Array.isArray(item.ItemValue) ? item.ItemValue : []);
    const options = useMemo(() => Array.isArray(item.multiValues) ? item.multiValues : [], [item.multiValues]);
    const [inputValue, setInputValue] = useState('');

    const handleRemove = (valToRemove) => {
        const updatedValues = value.filter(val => val !== valToRemove);
        setValue(updatedValues);
        handleUpdateOneListFieldData(info.id, item.id, updatedValues);
    }

    const handleAutocompleteChange = useCallback((event, newValue) => {
        // Convert all values to strings (handle both existing and newly created options)
        const stringValues = newValue.flatMap((val) => typeof val === 'string' ? val : val.inputValue || val.title);
        const newValueToToggle = stringValues[0];

        setValue((prevValues) => {
            let updatedValues;
            if (prevValues.includes(newValueToToggle)) {
                // If the value exists, remove it
                updatedValues = prevValues.filter((val) => val !== newValueToToggle);
            } else {
                // If the value doesn't exist, add it
                updatedValues = [...prevValues, newValueToToggle];
            }

            handleUpdateOneListFieldData(info.id, item.id, updatedValues);
            return updatedValues;
        });
        // Reset input value
        setInputValue('');
    }, [handleUpdateOneListFieldData, info.id, item.id]);

    const customFilterOptions = (options = [], params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        if (inputValue && !options?.includes(inputValue)) {
            filtered.push({
                inputValue,
                title: `${t('add')} "${inputValue}"`,
            });
        }
        return filtered;
    };

    const renderOption = useCallback((props, option, { index }) => {
        if (index === 0) {
            return (
                <>
                    {/* Helper text as a non-interactive list item */}
                    <li>
                        <Box className='fieldSelect_helper_text_box'>
                            <Iconify icon="akar-icons:info" />
                            <Typography className="imeahCaption"> {t("currentOptionAvailableAreOnlyEnglish")}</Typography>
                        </Box>
                    </li>
                    {/* Render the actual option */}
                    <li {...props} style={{ color: option.inputValue ? '#16536B' : 'inherit' }}>
                        {options.includes(option) && (
                            <Checkbox
                                checked={value.includes(option)}
                                className="imeahCheckbox"
                            />
                        )}
                        <Typography>{option.title || option}</Typography>
                    </li>
                </>
            );
        }

        // Default rendering for other options
        return (
            <li {...props} style={{ color: option.inputValue ? '#16536B' : 'inherit' }}>
                {options.includes(option) && (
                    <Checkbox
                        checked={value.includes(option)}
                        className="imeahCheckbox"
                    />
                )}
                <Typography>{option.title || option}</Typography>
            </li>
        );
    }, [options, t, value]);

    const renderInput = useCallback((params) => (
        <TextField
            {...params}
            className='customTextField'
            placeholder={t('selectOptionPlaceHolder')}
            InputProps={{
                ...params.InputProps,
                style: {
                    ...params.InputProps.style,
                    opacity: params.disabled ? 0.5 : 1,
                },
                endAdornment: (
                    <Iconify
                        onClick={() => setOpen(prev => !prev)}
                        icon='iconamoon:arrow-down-2-light'
                        className='fieldSelect_dropdown_icon'
                    />
                ),
            }}
        />
    ), [t]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>

            <Typography className='medicalFileFields_listField_label'>{item.ItemLabel}</Typography>

            {allowedActions?.canEditPrivateMedicalFile &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    freeSolo
                    id={item.id}
                    open={open} // controlled open state
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={[]}
                    disabled={!allowedActions?.canEditPrivateMedicalFile}
                    inputValue={inputValue}
                    onChange={handleAutocompleteChange}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Update input value
                    filterOptions={customFilterOptions}
                    options={options}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') return option;// Value selected with enter, right from the input
                        if (option.inputValue) return option.inputValue; // Add "abc" option created dynamically
                        return option; // Regular option
                    }}
                    renderOption={renderOption}
                    renderInput={renderInput}
                />
            }

            {value?.length > 0 && (
                <Box display="flex" gap="10px" flexWrap="wrap">
                    {value.map((val, index) => (
                        <Box key={index} className="imeah_ships_box">
                            <Typography variant="body1">{val}</Typography>
                            {allowedActions?.canEditPrivateMedicalFile &&
                                <IconButton onClick={() => handleRemove(val)} className="imeah_ships_button">
                                    <CloseIcon className="imeah_ships_icon" />
                                </IconButton>
                            }
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};