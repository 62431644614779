import React from 'react'
import { Box, Typography, CardMedia, Button } from '@mui/material'
import '../../css/homePage/homeTenPart.css'
import SquareLogo from '../../images/Square-logo.svg'
import Mobiles from '../../images/imeah-mobiles.svg'
import { useTranslation } from "react-i18next";

export const HomeTenPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeTenPart-main-box'>
            <Box className='homeTenPart-logo-typo-main-box'>
                <Box className='homeTenPart-logo-arrow-box'>
                    <CardMedia
                        className="homeTenPart-logo-icon"
                        component="img"
                        image={SquareLogo}
                        alt="My Image"
                    />
                </Box>
                <Box className='homeTenPart-typo-btn-box'>
                    <Box className='homeTenPart-typo-part-box'>
                        <Typography className='homeTenPart-typo-telecharger'>{t("dawnloadApp")}</Typography>
                        <Typography className='homeTenPart-typo-ehealth'>ehealth pro ?</Typography>
                    </Box>
                    <Button className='homeTenPart-btn-part' href={`#` + t('contactUs')}>{t("contactUs")}</Button>
                </Box>
            </Box>
            <CardMedia
                className="homeTenPart-mobiles-img"
                component="img"
                image={Mobiles}
                alt="My Image"
            />
        </Box>
    )
}