/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Menu, Typography, Chip, Accordion, AccordionSummary, AccordionDetails, CircularProgress, useMediaQuery, Divider, MenuItem } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { HasPermission } from '../../../utils/checkUserPermission';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReadOneTemplateTabs } from './readOneTemplateTabs';
import { useNavigate, useParams } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CanManageTemplate } from '../CanManageTemplate';
import { CanCopyTemplate } from '../CanCopyTemplate';
import { useState, useEffect, useMemo } from 'react';
import '../../../css/templates/readOneTemplate.css';
import { ExportTemplate } from '../exportTemplate';
import { DeleteTemplate } from '../deleteTemplate';
import { useTheme } from '@mui/material/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { act } from '@testing-library/react';
import { useSelector } from 'react-redux';
import Iconify from '../../Iconify';
import Page from "../../Page";
import React from 'react';
import axios from 'axios';

export const OneTemplate = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);
    const filteredPermissions = useSelector(state => state.userPermissions);
    const isAllowedImportTemplate = HasPermission('import_export_template');

    const [OneTemplate, setOneTemplate] = useState([]);
    const [selectlist, setSelectlist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentSection, setCurrentSection] = useState('');
    const [isExportDialog, setIsExportDialog] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);

    useEffect(() => {
        fetchTemplate();
    }, [id, currentInstitution]);

    const fetchTemplate = async () => {
        if (!currentInstitution) return; // Early return for unauthenticated users

        try {
            const response = await axios.get(`medical-file-templates/${id}`);

            if (response.status === 200 || response.status === 201) {
                act(() => {
                    setOneTemplate(response.data);
                    setCurrentSection(response.data?.sections[0]?.id); // Handle potential undefined sections
                })

                const selectListIds = extractListIds(response.data.additional_fields);

                if (selectListIds.length > 0) {
                    await fetchTargetSelectListIds(selectListIds);
                }
            }
            act(() => {
                setLoading(false);
            })
        } catch (error) {
            console.error('Error fetching template:', error);
            CustomErrorLogger(error, 'Error fetching template in readOneTemplate');
        }
    };

    const extractListIds = (additional_fields) => {

        return additional_fields.flatMap((obj) => {
            if (obj.type === 'select' || obj.type === 'Multi_select') {
                return [obj.listId]; // Return an array with the listId
            }

            if (obj.type === 'list') {
                return obj.value.filter((listItem) =>
                    listItem.ItemType === 'select' || listItem.ItemType === 'Multi_select'
                ).map((listItem) => listItem.listItemListId);
            }

            return []; // Return an empty array for other cases
        });
    }

    const fetchTargetSelectListIds = async (TargetSelectlistIds) => {
        try {
            const response = await axios.get('select-list/getSpecificSelectRead', { params: { TargetSelectlistIds } });
            act(() => {
                if (response.status === 200 || response.status === 201) {
                    setSelectlist(response.data);
                }
            })

        } catch (error) {
            console.error('Error fetching select lists:', error);
            CustomErrorLogger(error, 'Error fetching select lists in readOneTemplate');
        }
    };

    const ActivateTemplate = async (id) => {
        if (!currentInstitution) return; // Early return for unauthenticated users

        try {
            const response = await axios.patch(`medical-file-templates/activateTemplate/${id}`);

            if (response.status === 200 || response.status === 201) {
                setOneTemplate({ ...OneTemplate, is_active: response.data });
            }
        } catch (error) {
            console.error('Error activating template:', error);
            CustomErrorLogger(error, 'Error activating template');
        }
    };

    const InActivateTemplate = async (id) => {
        if (!currentInstitution) return; // Early return for unauthenticated users

        try {
            const response = await axios.patch(`medical-file-templates/inactiveTemplate/${id}`);

            if (response.status === 200 || response.status === 201) {
                setOneTemplate({ ...OneTemplate, is_active: response.data });
            }
        } catch (error) {
            console.error('Error inactivating template:', error);
            CustomErrorLogger(error, 'Error inactivating template');
        }
    };

    const renderOwners = useMemo(() => {
        return (owners) => {
            if (owners && owners.length > 0) {
                return owners?.map((data, index) => (
                    <Typography className='imeah_propre_name' sx={{ backgroundColor: '#e0e0e0', borderRadius: '6px', padding: '5px', fontSize: '12px', fontWeight: 'bold' }} key={index}>{data.user?.firstname} {data.user?.lastname}</Typography>
                ));
            }
            return <Typography>{t('has_no_owner')}</Typography>
        };
    }, []);

    const ActionButton = ({ icon, title, onClick }) => (
        <Box className='readTemp-btn-container' onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} className="readTemp-headerBtn-icon" />
            <Typography className="readTemp-btn-title">{title}</Typography>
        </Box>
    );

    const ActionMenuButton = ({ icon, title, onClick }) => (
        <MenuItem className='readTemp-btn-container' onClick={onClick}>
            <Iconify icon={icon} className="readTemp-headerBtn-icon" />
            <Typography className="readTemp-btn-title">{title}</Typography>
        </MenuItem>
    );

    const handleOpenActionMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Page title={t("Template_Details_Page_Title")}>
            {loading ?
                <Box className='edit-temp-loading'><CircularProgress size="5rem" /></Box> :
                <Box>
                    <Box>

                        <Box className='readTemp-top-bar'>
                            < ArrowBackIosIcon className='readTemp-back-btn' onClick={() => navigate('/dashboard/medicalFilesTemplates')} data-testid="backIcon" />

                            {isDesktop &&
                                <Box className='readTemp-action-button-box'>

                                    {(CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) && OneTemplate.is_active) &&
                                        <ActionButton icon="material-symbols:unpublished" title={t('InActiveTemplateButton')} onClick={() => InActivateTemplate(OneTemplate.id)} />
                                    }

                                    {(CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) && !OneTemplate.is_active) &&
                                        <ActionButton icon="material-symbols:publish" onClick={() => ActivateTemplate(OneTemplate.id)} title={t('ActiveTemplateButton')} />
                                    }

                                    {CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) &&
                                        <ActionButton icon="material-symbols:delete" onClick={() => setOpenDelete(true)} title={t('dlt')} />
                                    }

                                    {CanCopyTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) &&
                                        <ActionButton icon="material-symbols:file-copy" onClick={() => navigate(`/dashboard/medicalFilesTemplates/Copy/${id}`)} title={t('copy_for_me')} />
                                    }

                                    {CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) &&
                                        <ActionButton icon="tdesign:edit" onClick={() => navigate(`/dashboard/medicalFilesTemplates/edit/${OneTemplate.id}`, { state: { data: OneTemplate } })} title={t('edit')} />
                                    }

                                    {isAllowedImportTemplate && currentInstitution.institution.id === OneTemplate.institutionId &&
                                        <ActionButton icon="clarity:export-line" onClick={() => setIsExportDialog(true)} title={t('exportMDF')} />
                                    }

                                </Box>
                            }

                            {!isDesktop &&
                                <Box className='readTemp-action-button-box'>

                                    <IconButton
                                        // className="mdf-page-title-icon"
                                        sx={{ color: 'white', padding: 0 }}
                                        id="medicalFilePageActionMenuIcom"
                                        aria-controls={openActionMenu ? 'long-menu' : undefined}
                                        aria-expanded={openActionMenu ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleOpenActionMenu}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>
                                </Box>
                            }

                            <Menu
                                id="templatePageActionMenu"
                                MenuListProps="templatePageActionMenuIcom"
                                open={openActionMenu}
                                anchorEl={anchorEl}
                                onClose={handleCloseActionMenu}
                                onClick={handleCloseActionMenu}
                                slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
                            >

                                {(CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) && OneTemplate.is_active) &&
                                    <ActionMenuButton icon="material-symbols:unpublished" title={t('InActiveTemplateButton')} onClick={() => InActivateTemplate(OneTemplate.id)} />
                                }

                                {(CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) && !OneTemplate.is_active) &&
                                    <ActionMenuButton icon="material-symbols:publish" onClick={() => ActivateTemplate(OneTemplate.id)} title={t('ActiveTemplateButton')} />
                                }

                                {CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) &&
                                    <ActionMenuButton icon="material-symbols:delete" onClick={() => setOpenDelete(true)} title={t('dlt')} />
                                }

                                {CanCopyTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) &&
                                    <ActionMenuButton icon="material-symbols:file-copy" onClick={() => navigate(`/dashboard/medicalFilesTemplates/Copy/${id}`)} title={t('copy_for_me')} />
                                }

                                {CanManageTemplate(OneTemplate, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id }) &&
                                    <ActionMenuButton icon="tdesign:edit" onClick={() => navigate(`/dashboard/medicalFilesTemplates/edit/${OneTemplate.id}`, { state: { data: OneTemplate } })} title={t('edit')} />
                                }

                                {isAllowedImportTemplate && currentInstitution.institution.id === OneTemplate.institutionId &&
                                    <ActionMenuButton icon="clarity:export-line" onClick={() => setIsExportDialog(true)} title={t('export')} />
                                }
                            </Menu>
                        </Box>
                        <Box className='read-onetemp-info-box' >
                            <Box className='read-onetemp-field-info'>
                                <Typography className='read-onetemp-field-label'>{t('templateName')} :</Typography>
                                <Typography className='read-onetemp-field-response'>{OneTemplate.name}</Typography>
                            </Box>
                            <Box className='read-onetemp-field-info'>
                                <Typography className='read-onetemp-field-label'>{t('description')} :</Typography>
                                <Typography className='read-onetemp-field-response'>{OneTemplate.description}</Typography>
                            </Box>
                            <Box className='read-onetemp-field-info'>
                                <Typography className='read-onetemp-field-label'>{t('Template_Owner/s')} :</Typography>
                                <Box className='read-onetemp-field-response'>
                                    {renderOwners(OneTemplate.template_owners)}
                                </Box>
                            </Box>
                        </Box>

                    </Box >

                    <TabContext value={currentSection}>

                        <ReadOneTemplateTabs sections={OneTemplate.sections} setCurrentSection={setCurrentSection} />

                        <TabPanel value={currentSection} sx={{ padding: 1 }}>
                            <Box>
                                {OneTemplate.additional_fields && OneTemplate.additional_fields
                                    .filter((item) => item.section === currentSection)
                                    .map((info) => (
                                        <Box className='read-onetemp-fields-box' key={info.id}>
                                            {(info.type !== 'list') &&
                                                <Box className='read-onetemp-for-nolist-label-type'>
                                                    <Typography className='read-onetemp-for-list-label'>{info.label}</Typography>
                                                    <Box className={`read-onetemp-response-occardion ${info.type === 'select' || info.type === 'Multi_select' || info.type === 'Multi_value' ? 'read-onetemp-response-occardion-column' : ''}`}>
                                                        <Typography className='read-onetemp-for-list-type'>{t(info.type)} </Typography>

                                                        {(info.type === 'select' || info.type === 'Multi_select') &&
                                                            <Box className='read-onetemp-select-multiselect-accordion'>
                                                                {selectlist && selectlist
                                                                    .filter((list) => list.id === info.listId)
                                                                    .map((list, index) =>
                                                                        <Box key={index}>
                                                                            <Accordion className='accordion-icon'>
                                                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} > {list.select_list_label[0].label}</AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    {list.select_list_values.map((item, index) =>
                                                                                        <Chip key={index} label={item.select_list_values_label[0].label} />
                                                                                    )}
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </Box>
                                                                    )}
                                                            </Box>
                                                        }
                                                        {info.type === 'Multi_value' &&
                                                            <Box className='read-onetemp-select-multiselect-accordion'>
                                                                <Accordion className='accordion-icon'>
                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} > {info.label}</AccordionSummary>
                                                                    <AccordionDetails>
                                                                        {info.multiValues.map((item, index) =>
                                                                            <Chip key={index} label={item} />
                                                                        )}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Box>
                                                        }
                                                    </Box>

                                                </Box>

                                            }

                                            {(info.type === 'list') &&
                                                <Box className='read-onetemp-for-list-box'>
                                                    <Typography>{info.label}</Typography>
                                                    {info.value.map((listItem) => (
                                                        <Box className='read-onetemp-for-list-label-type' key={listItem.id}>
                                                            <Typography className='read-onetemp-for-list-label'>{listItem.ItemLabel}</Typography>
                                                            <Box className={`read-onetemp-response-occardion ${listItem.ItemType === 'select' || listItem.ItemType === 'Multi_select' || listItem.ItemType === 'Multi_value' ? 'read-onetemp-response-occardion-column' : ''}`}>
                                                                <Typography className='read-onetemp-for-list-type'>{t(listItem.ItemType)} </Typography>
                                                                {(listItem.ItemType === 'select' || listItem.ItemType === 'Multi_select') &&
                                                                    <Box className='read-onetemp-select-multiselect-accordion'>
                                                                        {selectlist && selectlist
                                                                            .filter((list) => list.id === listItem.listItemListId)
                                                                            .map((list, index) =>
                                                                                <Box key={index} >

                                                                                    <Accordion className='accordion-icon'>
                                                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} > {list.select_list_label[0].label}</AccordionSummary>
                                                                                        <AccordionDetails>
                                                                                            {list.select_list_values.map((item, index) =>
                                                                                                <Chip key={index} label={item.select_list_values_label[0].label} />
                                                                                            )}
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Box>
                                                                            )}
                                                                    </Box>
                                                                }
                                                                {listItem.ItemType === 'Multi_value' &&
                                                                    <Box className='read-onetemp-select-multiselect-accordion'>
                                                                        <Accordion className='accordion-icon'>
                                                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} > {listItem.ItemLabel}</AccordionSummary>
                                                                            <AccordionDetails>
                                                                                {listItem.multiValues.map((item, index) =>
                                                                                    <Chip key={index} label={item} />
                                                                                )}
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>

                                                    ))}
                                                </Box>
                                            }

                                        </Box>
                                    ))}
                            </Box>
                        </TabPanel>
                    </TabContext>

                    {isExportDialog &&
                        <ExportTemplate
                            OneTemplate={OneTemplate}
                            isExportDialog={isExportDialog}
                            setIsExportDialog={setIsExportDialog}
                        />
                    }
                </Box>
            }

            {openDelete &&
                <DeleteTemplate
                    id={id}
                    openDelete={openDelete}
                    setOpenDelete={setOpenDelete}
                />
            }
        </Page >
    )
}