import { HasPermission } from '../../utils/checkUserPermission';
import { Container, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import Iconify from '../Iconify';

const NoFileLength = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentInstitution = useSelector(state => state.currentInstitution);
    const { id } = currentInstitution.institution;

    const isAllowedCreateMedicalFileForSpecialist = HasPermission('create_Medical_File_for_specialist');
    const isAllowedCreateMedicalFileForExpert = HasPermission('create_Medical_File_for_expert');

    // Memoize check for allowed action
    const canCreateMedicalFile = useMemo(
        () => (isAllowedCreateMedicalFileForSpecialist || isAllowedCreateMedicalFileForExpert) && id !== 'imeah',
        [isAllowedCreateMedicalFileForSpecialist, isAllowedCreateMedicalFileForExpert, id]
    );

    return (
        <Container>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="70vh"
                gap={'32px'}
            >
                <Typography className="imeahTitle" sx={{ textAlign: 'center' }}>{t('noMedicalFileLengthTitle')}</Typography>
                <Typography className="imeahCaption" sx={{ textAlign: 'center' }}>{t('noMedicalFileLengthSubTitle')}</Typography>
                {canCreateMedicalFile && (
                    <Button
                        className="buttonFilled"
                        onClick={() => navigate('/dashboard/medicalFiles/createFile')}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        {t('New_Medical_File')}
                    </Button>
                )}
            </Box>
        </Container>
    );
};

export default NoFileLength;