import ImeahActionIcon from '../../../../../utils/imeah_action_icon';
import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import '../../../../../css/medicalFiles/fieldType/file.css';

const MAX_FILE_SIZE_MB = 100;

const UploadArea = ({ info, data, setDocuments }) => {

    const { t } = useTranslation();
    const [errorDuplicationMessage, setErrorDuplicationMessage] = useState(null);

    // Calculate the total size of the files in MB
    const calculateFileSizeMB = (files) => Array.from(files).reduce((total, file) => total + file.size, 0) / (1024 * 1024);

    // Prepare the form data
    const prepareFormData = useCallback((fieldId, files, medicalFileId) => {

        const formData = new FormData();
        formData.append('medicalFileId', medicalFileId);
        formData.append('fieldId', fieldId);
        formData.append('isNeedFileLogs', 'true');
        formData.append('isNeedRedDot', 'false');

        Array.from(files).forEach((file) => {
            formData.append('files', file);
        });

        return formData;
    }, []);

    // Handle the upload of the documents
    const handleUploadDocuments = useCallback((fieldId, event, imageInformation) => {

        const totalSizeMB = calculateFileSizeMB(imageInformation);

        // Check if the total size of the files exceeds the limit
        if (totalSizeMB > MAX_FILE_SIZE_MB) {
            setErrorDuplicationMessage(t('file_size_exceeds_limit'));
            return;
        }

        // Prepare the form data
        const formData = prepareFormData(fieldId, imageInformation, data.id);

        // axios.post('documents/uploadManyDocuments', formData).then((response) => {
        axios.post('documents/uploadManyDocuments', formData).then((response) => {

            if (response.status === 200 || response.status === 201) {
                // Update the documents array
                setDocuments((prevDoc) => [...prevDoc, ...response.data]);
            }
        })
            .catch((err) => {
                setErrorDuplicationMessage(t('upload_document_faild'));
                console.log(err.message);
            });
    }, [prepareFormData, data.id, t, setDocuments]);

    return (
        <Box className='fieldType-upload-box' onClick={() => document.getElementById(info.id).click()}>
            <ImeahActionIcon icon="material-symbols:upload-rounded" color='#727179' />
            <Typography sx={{ color: '#16536B' }} >{t('clickToUpload')}</Typography>
            <Typography variant='caption' sx={{ color: '#353535' }} > ({t('maxFileSize')})</Typography>

            {errorDuplicationMessage && <Typography color='error'>{errorDuplicationMessage}</Typography>}

            <input
                style={{ display: 'none' }}
                type={info.type}
                id={info.id}
                multiple
                onChange={(event) => handleUploadDocuments(info.id, event, event.target.files)}
            />
        </Box>
    );
}

export default React.memo(UploadArea); 