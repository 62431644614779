/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { MuiSelect } from '../../utils/muiSelect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import axios from 'axios';

export const TransferManyFile = ({ files, setFiles, dialogTransferAllMedicalFiles, setDialogTransferAllMedicalFiles }) => {

    const { t } = useTranslation();
    const currentInstitution = useSelector(state => state.currentInstitution);

    const [AllInstitutioUser, setAllInstitutioUser] = useState([]);
    const [optionsKey, setOptionsKey] = useState(0);
    const [loading, setLoading] = useState(false);

    const [newDoctorOwnerName, setNewDoctorOwnerName] = useState('');
    const [currentOwnerName, setcurrentOwnerName] = useState('');
    const [newDoctorOwnerId, setNewDoctorOwnerId] = useState('');
    const [currentOwnerId, setcurrentOwnerId] = useState('');

    const validationGoodData = Yup.object().shape({
        currentOwnerId: Yup.string().required(),
        newDoctorOwnerId: Yup.string().required(),
    });

    const checkValidation = validationGoodData.isValidSync({
        currentOwnerId,
        newDoctorOwnerId
    })

    const fetchExistingDoctors = useCallback(async (page, search) => {
        try {
            const limit = 10;
            const offset = page * limit;
            const response = await axios.get('institutions-users/getAllForTransferMedicalFile', { params: { offset, limit, search } });

            setAllInstitutioUser(prev => (offset === 0 ? response.data : [...prev, ...response.data]));

            return response.data.map(record => ({
                value: record.id, label: currentInstitution.role.id === 'super_admin' ?
                    `${record.user.firstname} ${record.user.lastname} - (${record.institution.name})` :
                    `${record.user.firstname} ${record.user.lastname}`,
            }));


        } catch (error) {
            console.error('Error fetching existing doctors:', error);
            return [];
        }
    }, [currentInstitution]);

    const fetchTargetDoctors = useCallback(async (page, search) => {

        //manage case you dont choose current owner with empty options
        if (dialogTransferAllMedicalFiles && currentOwnerId === '') {
            return [];
        };

        try {
            const limit = 10;
            const offset = page * limit;

            // Find the object with the desired id
            const currentOwnerInfo = AllInstitutioUser.find(record => record.id === currentOwnerId);
            // Extract the values if a matching object is found
            const InstitutioUserId = currentOwnerInfo?.id
            const specialityId = currentOwnerInfo?.specialityId;
            const roleId = currentOwnerInfo?.roleId;
            const expertises = currentOwnerInfo?.institutionUserExpertise.map(expertise => expertise.expertise.id);

            const response = await axios.get('institutions-users/getTargetDoctorToTransferMedicalFileWith', { params: { offset, limit, search, currentOwnerId: InstitutioUserId, roleId, specialityId, expertises } });

            return response.data.map(record => ({
                value: record.id, label: currentInstitution.role.id === 'super_admin' ?
                    `${record.user.firstname} ${record.user.lastname} - (${record.institution.name})` :
                    `${record.user.firstname} ${record.user.lastname}`,
            }));


        } catch (error) {
            console.error('Error fetching target doctors:', error);
            return [];
        }
    }, [currentInstitution.role.id, currentOwnerId, dialogTransferAllMedicalFiles]);


    useEffect(() => {
        if (currentOwnerId) {
            fetchTargetDoctors('', [])
            setOptionsKey(prevKey => prevKey + 1);
        }
    }, [currentOwnerId, fetchTargetDoctors]);


    const handleChangeCurrentOwner = useCallback((newCurrentData) => {        
        setcurrentOwnerName(newCurrentData)
        setcurrentOwnerId(newCurrentData.value)
        setNewDoctorOwnerName('')
        setNewDoctorOwnerId('')
    }, []);

    const handleChangeNewOwner = useCallback((newOwnerData) => {
        setNewDoctorOwnerName(newOwnerData)
        setNewDoctorOwnerId(newOwnerData.value)
    }, []);

    const transferMultiFile = async () => {
        try {
            // Early return if validation fails
            if (!checkValidation) return;

            const filesCopy = [...files];
            const data = { ownerId: currentOwnerId, newOwnerId: newDoctorOwnerId };
            setLoading(true);

            const response = await axios.put(`medical-file/updateManyFilesOwner`, data);
            if (response.status === 200 || response.status === 201) {
                const updatedFiles = filesCopy
                    .filter((file) => file.doctorId !== currentOwnerId && file.doctorId !== newDoctorOwnerId)
                    .concat(response.data)
                    .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

                setFiles(updatedFiles);
                setLoading(false);
                setDialogTransferAllMedicalFiles(false);
            }
        } catch (error) {
            console.error('Error transferring multiple files:', error);
        }
    };

    return (
        <Dialog open={dialogTransferAllMedicalFiles} fullWidth>
            <DialogTitle>{t('transfer')}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: "column", gap: '15px' }}>
                <MuiSelect
                    className="form-control"
                    label={t('oldOwner')}
                    required={true}
                    searchable={true}
                    value={currentOwnerName}
                    fetchFunction={fetchExistingDoctors}
                    onChange={(selectedInstitution) => handleChangeCurrentOwner(selectedInstitution.target)}
                />
                <MuiSelect
                    key={optionsKey}
                    label={t('newOwner')}
                    required={true}
                    searchable={true}
                    value={newDoctorOwnerName}
                    fetchFunction={fetchTargetDoctors}
                    onChange={(selectedInstitution) => handleChangeNewOwner(selectedInstitution.target)}
                />
            </DialogContent>
            <DialogActions>
                <Button fullWidth className='buttonOutlined' onClick={() => setDialogTransferAllMedicalFiles(false)}>{t('cancel')}</Button>
                <LoadingButton fullWidth loading={loading} className='buttonFilled' onClick={transferMultiFile} disabled={checkValidation === false}>{t('transfer')}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};