import { Dialog, DialogTitle, Button } from '@mui/material'
import { DialogActions } from '@material-ui/core'
import { useTranslation } from "react-i18next";
import React from 'react'
import '../../../../../css/CV/education.css'
import axios from 'axios';
import { useSelector } from 'react-redux';

export const DeleteEducation = ({ openDelete, setOpenDelete, dataIndex, education, setEducation }) => {
    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    const deleteEducation = () => {
        if (dataIndex !== null) {
            education.splice(dataIndex, 1)
            axios.post(`user-cv`, { education, userId: userData.id })
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setEducation(education)
                        setOpenDelete(false);
                    }
                });
        }
    };

    return (
        <Dialog
            open={openDelete}
            keepMounted
            onClose={() => setOpenDelete(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{t('deleteEducationDialogTitle')}</DialogTitle>
            <DialogActions>
                <Button className='new-button' onClick={() => setOpenDelete(false)}>{t('cancel')}</Button>
                <Button className='new-button' onClick={() => deleteEducation()}>{t('dlt')}</Button>
            </DialogActions>
        </Dialog>
    )
}