import { TextField, Typography, MenuItem, Popover, Box, InputAdornment } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';
import React, { useState } from 'react';

const ImeahFilterMenu = ({ open, anchorEl, onClose, options, handleChange, isMobile, currentFilter }) => {
    const { t } = useTranslation();
    const [searchTexts, setSearchTexts] = useState({});

    // Ensure anchorEl has a unique identifier
    const currentAnchorEl = anchorEl ? anchorEl.getAttribute('data-id') : null;
    const searchText = searchTexts[currentAnchorEl] || '';

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleReset = () => {
        setSearchTexts(prev => ({ ...prev, [currentAnchorEl]: '' }));
        handleChange(null);
        onClose();
    };

    const handleOptionClick = (option) => {
        if (typeof handleChange === 'function') {
            handleChange(option); // Call handleChange with the selected option
        } else {
            console.error('handleChange is not a function');
        }
        onClose(); // Close the popover
    };

    const handleChangeSearch = (searchText) => {
        // Update the searchTexts state with the current anchorEl and filter options by the searchText
        setSearchTexts(prev => ({ ...prev, [currentAnchorEl]: searchText }));
        // If the current filter is 'name', call handleChange with the searchText in order to filter by name
        if (currentFilter === 'name') {
            handleChange(searchText);
        }
    };

    return (
        <Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ marginTop: '10px', marginLeft: isMobile ? '0px' : currentFilter === 'name' ? '120px' : '20px', backgroundColor: 'transparent', minWidth: 'max-content' }}
            >
                <Box sx={{ p: 2, minWidth: 'max-content' }}>
                    {/* Search Bar */}
                    <TextField
                        placeholder={(t('search'))}
                        value={searchText}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        size="small"
                        fullWidth
                        sx={{ mb: 2, height: '40px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon='ic:round-search' />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                searchText && (
                                    <InputAdornment position="end">
                                        <Iconify
                                            icon='ic:round-close'
                                            onClick={() => setSearchTexts('')}
                                            sx={{ cursor: 'pointer', fontSize: '20px' }}
                                        />
                                    </InputAdornment>
                                )
                            ),
                        }}
                    />

                    <Typography
                        onClick={handleReset}
                        sx={{ mb: 1, color: '#727179', fontSize: '14px', fontWeight: '500', cursor: 'pointer', width: 'max-content' }}
                    >
                        {t('resetFilter')}
                    </Typography>

                    {currentFilter !== 'name' &&
                        <List
                            height={filteredOptions.length > 2 ? 130 : 100}
                            itemCount={filteredOptions.length}
                            itemSize={35}
                            className="imeahFilterMenu-scrollbar"
                        >
                            {({ index, style }) => {
                                const option = filteredOptions[index]; // Use filteredOptions[index]
                                return (
                                    <MenuItem
                                        key={option.id}
                                        onClick={() => handleOptionClick(option)}
                                        style={style}
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                        }}
                                        className="custom-scrollbar"
                                    >
                                        <Typography className='imeah_propre_name'>{option.label}</Typography>
                                    </MenuItem>
                                );
                            }}
                        </List>
                    }
                </Box>
            </Popover>
        </Box>
    );
};

export default ImeahFilterMenu;