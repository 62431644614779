import React, { useState } from 'react'
import { Box, Typography, CardMedia, List, ListItem } from '@mui/material'
import '../../css/homePage/homeFirstPage.css'
import '../../css/homePage/homeSecondPart.css'
import Eye from '../../images/Vector.svg'
import Arrow from '../../images/arrow.svg'
import Home from '../../images/home-icon.svg'
import Exclamation from '../../images/exclamation-marc.svg'
import ReverseArrow from '../../images/reverse-arrow.svg'
import { FiberManualRecord } from '@mui/icons-material';
import Loop from '../../images/illustration-loupe.svg';
import { useTranslation } from "react-i18next";

export const HomeSecondPart = () => {
    const { t } = useTranslation();

    const [arrow, setArrow] = useState(false);
    const [arrow2, setArrow2] = useState(false);
    const [arrow3, setArrow3] = useState(false);

    return (
        <Box className='homeSecondPart-main-box'>
            <Box className='homeSecondPart-card-box'>
                <Typography className='homeSecondPart-card-typo'>
                    {t("complexity")}
                </Typography>
                <CardMedia
                    className='homeSecondPart-card-icon-eye'
                    component="img"
                    image={Eye}
                    alt="My Image"
                />
                {!arrow ?
                    <CardMedia
                        onClick={() => { setArrow(true) }}
                        className='homeSecondPart-card-icon-arrow'
                        component="img"
                        image={Arrow}
                        alt="My Image"
                    /> :
                    <Box>
                        <Typography>
                            <List>
                                <ListItem className='homeSecondPart-card-list-item'>
                                    <Box className='homeSecondPart-card-list'>
                                        <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                        <Typography className='homeSecondPart-card-hidden-typo'>{t("lackVisibility")}</Typography>
                                    </Box>
                                    <Box className='homeSecondPart-card-list'>
                                        <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                        <Typography className='homeSecondPart-card-hidden-typo'>{t("availabilityComplicated")}</Typography>
                                    </Box>
                                    <Box className='homeSecondPart-card-list'>
                                        <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                        <Typography className='homeSecondPart-card-hidden-typo'>{t("difficultAccessOutside")}</Typography>
                                    </Box>
                                </ListItem>
                            </List>
                        </Typography>
                        <CardMedia
                            onClick={() => { setArrow(false) }}
                            className='homeSecondPart-card-icon-arrow'
                            component="img"
                            image={ReverseArrow}
                            alt="My Image" />
                    </Box>
                }
            </Box>
            <Box className='homeSecondPart-card-box'>
                <Typography className='homeSecondPart-card-typo'>
                    {t("lackOfAppreciation")}

                </Typography>
                <CardMedia
                    className='homeSecondPart-card-icon-home'
                    component="img"
                    image={Home}
                    alt="My Image"
                />
                {!arrow2 ?
                    <CardMedia
                        onClick={() => { setArrow2(true) }}
                        className='homeSecondPart-card-icon-arrow'
                        component="img"
                        image={Arrow}
                        alt="My Image"
                    /> :
                    <Box>
                        <List>
                            <ListItem className='homeSecondPart-card-list-item'>
                                <Box className='homeSecondPart-card-list'>
                                    <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                    <Typography className='homeSecondPart-card-hidden-typo'>{t("digitalTimeProvided")}</Typography>
                                </Box>
                                <Box className='homeSecondPart-card-list'>
                                    <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                    <Typography className='homeSecondPart-card-hidden-typo'>{t("phoneCare")}</Typography>
                                </Box>
                                <Box className='homeSecondPart-card-list'>
                                    <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                    <Typography className='homeSecondPart-card-hidden-typo'>{t("inappropriateUse")}</Typography>
                                </Box>
                                <Box className='homeSecondPart-card-list'>
                                    <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                    <Typography className='homeSecondPart-card-hidden-typo'>{t("TélétravailCompliqué")}</Typography>
                                </Box>
                                
                            </ListItem>
                        </List>
                        <CardMedia
                            onClick={() => { setArrow2(false) }}
                            className='homeSecondPart-card-icon-arrow'
                            component="img"
                            image={ReverseArrow}
                            alt="My Image" />
                    </Box>
                }
            </Box>
            <Box className='homeSecondPart-card-box'>
                <Typography className='homeSecondPart-card-typo'>
                {t("healthData")}
                </Typography>
                <CardMedia
                    className='homeSecondPart-card-icon-point'
                    component="img"
                    image={Exclamation}
                    alt="My Image"
                />
                {!arrow3 ?
                    <CardMedia
                        onClick={() => { setArrow3(true) }}
                        className='homeSecondPart-card-icon-arrow'
                        component="img"
                        image={Arrow}
                        alt="My Image"
                    /> :
                    <Box>
                        <List>
                            <ListItem className='homeSecondPart-card-list-item'>
                                <Box className='homeSecondPart-card-list'>
                                    <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                    <Typography className='homeSecondPart-card-hidden-typo'>{t("unprotected")}</Typography>
                                </Box>
                                <Box className='homeSecondPart-card-list'>
                                    <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                    <Typography className='homeSecondPart-card-hidden-typo'>{t("unusableForResearch")}</Typography>
                                </Box>
                                <Box className='homeSecondPart-card-list'>
                                    <FiberManualRecord className='homeSecondPart-card-icon-record' />
                                    <Typography className='homeSecondPart-card-hidden-typo'>{t("NonAccessiblesPourFormation")}</Typography>
                                </Box>
                            </ListItem>
                        </List>
                        <CardMedia
                            onClick={() => { setArrow3(false) }}
                            className='homeSecondPart-card-icon-arrow'
                            component="img"
                            image={ReverseArrow}
                            alt="My Image" />
                    </Box>
                }
            </Box>

            <CardMedia
                className='homeSecondPart-card-loop-img'
                component="img"
                image={Loop}
                alt="My Image" />
        </Box>
    )
}
