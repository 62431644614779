import React from 'react'
import { Box, Typography, CardMedia } from '@mui/material'
import '../../css/homePage/homeFivePart.css'
import rightArrow from '../../images/rightArrow.svg'
import reseauProf from '../../images/imeah-reseauProf.svg'
import reseauTerro from '../../images/imeah-reseauTerritorial.svg'
import reseauWorld from '../../images/imeah-profWorld.svg'
import { useTranslation } from "react-i18next";

export const HomeFivePart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeFivePart-main-box'>
            <Box className="homeFivePart-two-part-box">
                <Box className="homeFivePart-typo-img-box">
                    <Box className="homeFivePart-arrow-typo-box">
                        <CardMedia
                            className="homeFivePart-arrow-icon"
                            component="img"
                            image={rightArrow}
                            alt="My Image"
                        />
                        <Typography className="homeFivePart-typo-reseau">{t('proffesionalNetwork')}</Typography>
                    </Box>
                    <CardMedia
                        className="homeFivePart-reseauProf-img"
                        component="img"
                        image={reseauProf}
                        alt="My Image"
                    />
                </Box>
                <Typography className="homeFivePart-typo-part">{t('manageNetworkDoctors')}<br /> {t('joinNetworkMyHospital')}<br /> {t('costumiseMyEhealth')}<br /> {t("createOwnMedicalRecord")}</Typography>
            </Box>
            <Box className="homeFivePart-two-part-box-reverse">
                    <Typography className="homeFivePart-typo-reverse-part">{t('InRegionalNetwork')}<br/>{t('medicalDeserts')}</Typography>
                <Box className="homeFivePart-typo-img-box">
                    <Box className="homeFivePart-arrow-typo-box">
                        <CardMedia
                            className="homeFivePart-arrow-icon"
                            component="img"
                            image={rightArrow}
                            alt="My Image"
                        />
                        <Typography className="homeFivePart-typo-reseau">{t('regionalNetwork')}</Typography>
                    </Box>
                    <CardMedia
                        className="homeFivePart-reseauProf-img"
                        component="img"
                        image={reseauTerro}
                        alt="My Image"
                    />
                </Box>
            </Box>
            <Box className="homeFivePart-two-part-box">
                <Box className="homeFivePart-typo-img-box">
                    <Box className="homeFivePart-arrow-typo-box">
                        <CardMedia
                            className="homeFivePart-arrow-icon"
                            component="img"
                            image={rightArrow}
                            alt="My Image"
                        />
                        <Typography className="homeFivePart-typo-reseau">{t('internationalNetwork')}</Typography>
                    </Box>
                    <CardMedia
                        className="homeFivePart-reseauProf-img"
                        component="img"
                        image={reseauWorld}
                        alt="My Image"
                    />
                </Box>
                <Typography className="homeFivePart-typo-part">{t('requiringProfessional')}</Typography>
            </Box>
        </Box>
    )
}
