import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import React, { useState, useEffect, useCallback } from 'react';
import { setUserData } from '../../../redux/userDataSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import L from 'leaflet';

// Set marker icons once during app initialization
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapComponent = ({ setSection, userInfo }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);

  const initialLocation = userInfo.location !== null
    ? { lat: userInfo?.location?.lat, lng: userInfo?.location?.lng }
    : { lat: 51.505, lng: -0.09 };

  const [selectedPlace, setSelectedPlace] = useState(initialLocation);

  const handleDragEnd = useCallback(async (e) => {
    const newPos = e.target.getLatLng();

    const provider = new OpenStreetMapProvider();
    const results = await provider.search({ query: `${newPos.lat},${newPos.lng}` });

    const locationName = results?.length > 0 ? results[0].label : 'Dragged Location';

    setSelectedPlace({ lat: newPos.lat, lng: newPos.lng, name: locationName });
  }, []);

  const addLocation = useCallback(() => {
    axios.put(`users/updateMyLocation/${userData.id}`, { location: selectedPlace })
      .then((response) => {
        dispatch(setUserData({ ...userData, location: response.data }));
        setSection("");
      })
      .catch((error) => {
        console.error('Error updating location:', error);
      });
  }, [selectedPlace, userData, dispatch, setSection]);

  const Search = ({ provider }) => {
    const map = useMap();

    useEffect(() => {
      const searchControl = new GeoSearchControl({ provider });

      const handleSearchResult = (event) => {
        const { lat, lng } = event?.marker?.getLatLng();
        const name = event.location.label;
        setSelectedPlace({ lat, lng, name });
        event.marker.remove(); // Remove default marker
      };

      map.on('geosearch/showlocation', handleSearchResult);
      map.addControl(searchControl);

      return () => {
        map.off('geosearch/showlocation', handleSearchResult);
        map.removeControl(searchControl);
      };
    }, [provider, map]);

    return null;
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>{t('chooseYourLocation')}</DialogTitle>
      <DialogContent>
        {(selectedPlace && selectedPlace.lat && selectedPlace.lng) && (
          <MapContainer
            center={selectedPlace}
            zoom={8}
            scrollWheelZoom={true}
            style={{ height: '400px', width: '100%' }}>
            <Search provider={new OpenStreetMapProvider()} />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={selectedPlace}
              draggable
              eventHandlers={{ dragend: handleDragEnd }}
            >
              <Popup>{selectedPlace.name}</Popup>
            </Marker>
          </MapContainer>
        )}
      </DialogContent>
      <DialogActions className="addInstitution-actions-box">
        <Button className='addInstitution-btn' onClick={() => setSection("")}>{t("cancel")}</Button>
        <Button className='addInstitution-btn' onClick={addLocation}>{t("svdtl")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapComponent;