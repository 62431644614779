import { Box, Table, Stack, LinearProgress, InputBase, TableRow, TableHead, Grid, TableBody, TableCell, Typography, useMediaQuery, InputAdornment } from '@mui/material';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HasPermission } from "../utils/checkUserPermission";
import { StyledTableRow } from '../utils/StyledTableRow';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Scrollbar from '../components/Scrollbar';
import { useTranslation } from 'react-i18next';
import useDebounce from '../hooks/useDebounce';
import { useNavigate } from "react-router-dom";
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import axios from 'axios';
import '../css/Roles.css';

export const Roles = () => {

    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const userData = useSelector(state => state.userData);

    //check user permissions
    const isAllowedCreateRole = HasPermission('Create_Role');

    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    //filtration states
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 1000);
    //pagination state
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 20;

    const fetchRoles = useCallback(async () => {
        try {
            const currentOffset = offset.current;
            const response = await axios.get('roles', { params: { offset: currentOffset, limit, search: debouncedSearch } });
            if (response.status === 200 || response.status === 201) {
                setRoles((prevRoles) => (currentOffset === 0 ? response.data : [...prevRoles, ...response.data]));
                setLoading(false);
                hasMore.current = response.data.length === limit;
                offset.current = currentOffset + limit;
            }
        } catch (error) {
            console.error('Error fetching roles:', error);
            CustomErrorLogger(error, 'Error fetching roles in roles main page');
        }
    }, [limit, debouncedSearch]);

    // Fetch roles when user data changes or search query updates
    useEffect(() => {
        setRoles([]);
        offset.current = 0;
        hasMore.current = true;
        fetchRoles();
    }, [fetchRoles, userData.local_code]);

    const handleChangeSearch = useCallback((searchTerm) => {
        setSearch(searchTerm);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    return (
        <Page title={t("role")}>
            <Stack className="role-main-page-header">
                <Box className="role-page-title-box">
                    <Box className="role-page-title-icon-box">
                        <Iconify className="role-page-title-icon" icon="material-symbols:workspace-premium" />
                        <Typography className='role-page-title'>{t("role")}</Typography>
                    </Box>
                    <InputBase
                        className='role-page-search-input'
                        id="outlined-password-input"
                        label={t('search')}
                        type="text"
                        placeholder={t('search')}
                        value={search || ""}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        endAdornment={
                            search && (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                                </InputAdornment>
                            )
                        }
                    />
                </Box>

                <Box className="role-page-action-box">
                    {isAllowedCreateRole &&
                        <Box className='role-page-header-button' onClick={() => navigate('add')}>
                            <Iconify icon="eva:plus-fill" className="plus-icon" />
                            <Typography className="new-button-title">{t('Create')}</Typography>
                        </Box>
                    }
                </Box>
            </Stack>

            {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} /> :

                <Box>
                    <Scrollbar>
                        <InfiniteScroll
                            dataLength={roles.length}
                            next={fetchRoles}
                            hasMore={hasMore.current}
                            loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
                        >

                            {isDesktop &&
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='roles-list-table-titles'>{t('roleName')}</TableCell>
                                            <TableCell className='roles-list-table-titles'>{t('canHaveSpecialityTableTitle')}</TableCell>
                                            <TableCell className='roles-list-table-titles'>{t('canHaveExpertiseTableTitle')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {roles && roles.map((roleLable) => {
                                            return (
                                                <StyledTableRow key={roleLable.id} onClick={() => navigate(`read/${roleLable.roleId}`)}>
                                                    <TableCell className="new-table-cell">{roleLable?.label}</TableCell>
                                                    <TableCell className="new-table-cell">{t(roleLable?.role?.canHaveSpeciality ? 'yes' : 'no')}</TableCell>
                                                    <TableCell className="new-table-cell">{t(roleLable?.role?.canHaveExpertise ? 'yes' : 'no')}</TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            }

                            {!isDesktop &&
                                <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'}>
                                    {roles && roles.map((roleLable) => (
                                        <Grid item xs={12} sm={4} key={roleLable?.id} onClick={() => navigate(`read/${roleLable?.roleId}`)}>
                                            <Box className="roles-list-responsive-card-details">
                                                <Typography className='roles-list-responsive-card-data'>{roleLable?.label}</Typography>
                                                <Typography className='roles-list-responsive-card-data'>{t('canHaveSpecialityTableTitle')} : {t(roleLable?.role?.canHaveSpeciality ? 'yes' : 'no')}</Typography>
                                                <Typography className='roles-list-responsive-card-data'>{t('canHaveExpertiseTableTitle')} : {t(roleLable?.role?.canHaveExpertise ? 'yes' : 'no')}</Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            }

                        </InfiniteScroll>
                    </Scrollbar>
                </Box>
            }

        </Page>
    );
};