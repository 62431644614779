import { Alert, IconButton, InputAdornment, Typography, TextField } from '@mui/material';
import { PasswordStrengthMeter } from '../../utils/PasswordStrengthMeter';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import Iconify from '../Iconify';
import Page from "../Page";
import * as Yup from 'yup';
import axios from 'axios';
import '../../css/User-css/changePassword.css';

const ChangePassword = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector(state => state.userData);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const formik = useFormik({
    initialValues: {
      oldPass: '',
      hpassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      oldPass: Yup.string().required(t('oldPasswordRequired')),
      hpassword: Yup.string()
        .trim()
        .min(12, t('passwordMinLength'))
        .matches(/[a-z]/, t('passwordLowercase'))
        .matches(/[A-Z]/, t('passwordUppercase'))
        .matches(/[0-9]/, t('passwordNumber'))
        .matches(/[!@#$%^&*.]/, t('passwordSpecialChar'))
        .required(t('passwordRequired')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('hpassword')], t('passwordsMustMatch'))
        .required(t('confirmPasswordRequired')),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      setButtonLoading(true);
      setErrorMsg(null);
      try {
        const response = await axios.put(`users/changePass/${userData.id}`, values);
        if (response.status === 200 || response.status === 201) {
          localStorage.clear();
          // Remove the token from axios headers
          delete axios.defaults.headers.common["Authorization"];
          navigate("/login");
        }
      } catch (err) {
        setErrorMsg(err.message);
      } finally {
        setButtonLoading(false);
      }
    },
  });

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isValid } = formik;

  return (
    <Page title={t("Change_Password_Page_Title")}>

      <form onSubmit={handleSubmit} className="CP_form">

        <Typography className="CP_dialog_title">{t('changePassword')}</Typography>
        <Typography className="CP_dialog_SubTitle">{t('passwordHint')}</Typography>

        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <TextField
          id="ForgotPassOldPassword"
          name="oldPass"
          type={showOldPassword ? "text" : "password"}
          fullWidth
          required
          label={t('oldPassword')}
          variant="outlined"
          className="customTextField"
          value={values.oldPass}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.oldPass && Boolean(errors.oldPass)}
          helperText={touched.oldPass && errors.oldPass}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowOldPassword((show) => !show)} edge="end">
                  <Iconify
                    icon={
                      showOldPassword
                        ? "eva:eye-fill"
                        : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id="ForgotPassNewPassword"
          name="hpassword"
          type={showNewPassword ? "text" : "password"}
          fullWidth
          required
          label={t('newPassword')}
          variant="outlined"
          className="customTextField"
          value={values.hpassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.hpassword && Boolean(errors.hpassword)}
          helperText={touched.hpassword && errors.hpassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowNewPassword((show) => !show)} edge="end">
                  <Iconify
                    icon={
                      showNewPassword
                        ? "eva:eye-fill"
                        : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <PasswordStrengthMeter newPassword={values.hpassword} />

        <TextField
          id="ForgotPassConfirmPassword"
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          label={t('confirmPassword')}
          fullWidth
          required
          variant="outlined"
          className="customTextField"
          color={values.confirmPassword === values.newPassword ? "success" : "error"}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
          helperText={touched.confirmPassword && errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword((show) => !show)} edge="end">
                  <Iconify
                    icon={
                      showConfirmPassword
                        ? "eva:eye-fill"
                        : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth loading={buttonLoading} className='CP_button_valider' type="submit" variant="contained" disabled={!isValid || buttonLoading} >{t("saveNewPassword")}</LoadingButton>

      </form>

    </Page >
  );
}

export default ChangePassword;