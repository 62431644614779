import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useMemo, useCallback } from 'react';
import { Button, Menu, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../Iconify';
import axios from 'axios';
import '../../../css/medicalFiles/AddComplementaryField.css'

export const AddComplementaryField = ({ fileId, setdata, setErrorMessage }) => {

    const { t } = useTranslation();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);

    // Handlers for opening and closing the menu
    const openMenu = useCallback((event) => setMenuAnchorEl(event.currentTarget), []);
    const closeMenu = useCallback(() => setMenuAnchorEl(null), []);

    const handleAddComplementaryField = useCallback(async (FieldType) => {
        //prepare object data for new field
        const bodyData = {
            label: '',
            type: FieldType,
        };

        try {
            const response = await axios.patch(`medical-file/addField/${fileId}`, bodyData);

            if (response.status === 200 || response.status === 201) {

                const newFields = response.data.additional_information;
                // Safely add editLabelStatus to the latest field
                if (newFields.length > 0) {
                    const latestFieldIndex = newFields?.length - 1;
                    newFields[latestFieldIndex] = {
                        ...newFields[latestFieldIndex],
                        editLabelStatus: true,
                    };
                }

                setdata(prevData => ({
                    ...prevData,
                    additional_information: newFields,
                    medical_Files_logs: response.data.medical_Files_logs,
                }));
            }
        } catch (error) {
            console.log('Error adding medical file field:', error);
            CustomErrorLogger(error, 'Error adding medical file field');
            setErrorMessage('errorAddingMedicalFileField');
        }
        finally {
            closeMenu();
        }
    }, [closeMenu, fileId, setErrorMessage, setdata]);

    const menu = useMemo(() => (
        <Menu
            anchorEl={menuAnchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
            PaperProps={{
                sx: {
                    width: menuAnchorEl ? menuAnchorEl.getBoundingClientRect().width : 'auto',
                    marginTop: '5px'
                },
            }}
        >
            <MenuItem key='text' value='text' onClick={() => handleAddComplementaryField('long Text')} >
                <Iconify icon='ic:round-text-fields' style={{ marginRight: 8, color: '#727179' }} />
                {t('text')}
            </MenuItem>
            <MenuItem key='document' value='document' onClick={() => handleAddComplementaryField('file')}>
                <Iconify icon='ic:round-insert-drive-file' style={{ marginRight: 8, color: '#727179' }} />
                {t('document')}
            </MenuItem>

        </Menu>
    ), [closeMenu, handleAddComplementaryField, isMenuOpen, menuAnchorEl, t]);

    return (
        <Box className='addComplementaryField_main_box'>
            <Box className='addComplementaryField_subMain_box'>
                {/* Left Divider */}
                <Box className='addComplementaryField_side_border' />

                {/* Centered Button */}
                <Button
                    className='buttonOutlined'
                    onClick={openMenu}
                    startIcon={<Iconify icon="ic:round-add" />}
                    endIcon={<Iconify icon={isMenuOpen ? 'iconamoon:arrow-down-2-light' : 'iconamoon:arrow-right-2-light'} />}
                >
                    {t('insertBlock')}
                </Button>

                {/* Right Divider */}
                <Box className='addComplementaryField_side_border' />
            </Box>

            {menu}
        </Box>
    );
};