import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { CustomErrorLogger } from '../../utils/CustomErrorLogger';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import axios from 'axios';

export const ExportTemplate = ({ OneTemplate, isExportDialog, setIsExportDialog }) => {

    const { t } = useTranslation();
    const [exportLoading, setExportLoading] = useState(false);

    const exportTemplateAsZip = async () => {
        try {
            setExportLoading(true);
            const response = await axios.get(`medical-file-templates/exportTemplateAsZip/${OneTemplate.id}`, {
                responseType: 'blob', // Important to handle binary data
            });

            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Use file-saver to save the file
            saveAs(blob, `${OneTemplate.name}.zip`);
        } catch (error) {
            console.error('Error exporting template:', error);
            CustomErrorLogger(error, 'Error exporting template');
        }
        finally {
            setExportLoading(false)
            setIsExportDialog(false)
        }
    };

    return (
        <Dialog open={isExportDialog} keepMounted onClose={() => setIsExportDialog(false)} aria-describedby="alert-dialog-slide-description">
            <DialogTitle>{t('exportTemplate')}</DialogTitle>
            <DialogContent>
                <Typography>{t('exportTemplateNote')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button className="new-button" fullWidth onClick={() => setIsExportDialog(false)}>{t('Cancel')}</Button>
                <LoadingButton loading={exportLoading} onClick={exportTemplateAsZip} className="new-button" fullWidth >{t('export')}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};