import { Box, Typography, TextField, Divider } from '@mui/material';
import ImeahActionIcon from '../../../../utils/imeah_action_icon';
import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentBox from './DocumentBox';
import { useSelector } from 'react-redux';
import UploadArea from './uploadArea';
import Iconify from '../../../Iconify';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import axios from 'axios';
import '../../../../css/medicalFiles/fieldType/file.css';

export const File = ({ info, data, setdata, currentSection, setDocuments, Documents, debouncedHandleUpdateOneFieldData,
    allowedActions, currentInstitution, handleRemoveRedDotReceiver, handleDeleteFieldOpen
}) => {

    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);
    const [editLabelStatus, setEditLabelStatus] = useState(info.editLabelStatus || false);
    const [label, setLabel] = useState(info.label || '');

    const isRedDotVisible = useMemo(() => info.TargetRedDot?.some((RedDotId) => RedDotId.trim() === currentInstitution.id),
        [info.TargetRedDot, currentInstitution.id]
    );

    const isFieldActionVisible = useMemo(() =>
        currentSection === 'Complementary_Information123' &&
        (allowedActions.canEditPrivateMedicalFile || allowedActions.canEditSharedMedicalFile) &&
        (info.createdBy === currentInstitution.id || info?.createdBy === undefined),
        [currentSection, allowedActions, info.createdBy, currentInstitution.id]
    );

    const isFieldEnabled = useMemo(() => (
        (currentSection === 'Complementary_Information123' &&
            (allowedActions.canEditPrivateMedicalFile || allowedActions.canEditSharedMedicalFile) &&
            (info.createdBy === currentInstitution.id || info?.createdBy === undefined)) ||
        (currentSection !== 'Complementary_Information123' && allowedActions.canEditPrivateMedicalFile)
    ),
        [currentSection, allowedActions.canEditPrivateMedicalFile, allowedActions.canEditSharedMedicalFile, info.createdBy, currentInstitution.id]
    );

    const handleEditLabel = useCallback(async () => {
        try {
            if (!label) return;

            const response = await axios.patch(`medical-file/editFieldLabel/${data.id}`, { label: label, FieldId: info.id });
            if (response.status === 200 || response.status === 201) {
                setdata(prevData => ({
                    ...prevData,
                    additional_information: response.data.additional_information,
                    medical_Files_logs: response.data.medical_Files_logs
                }));
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            setEditLabelStatus(false);
        }
    }, [data.id, info.id, label, setdata])

    const handleCloseEditLable = useCallback(() => {
        setEditLabelStatus(false);
        setLabel(info.label);
    }, [info.label]);

    const handleDeleteDocument = useCallback(async (fileDocument) => {

        axios.delete(`file-documents/${fileDocument.id}`, { data: { isNeedFileLogs: true } }).then((response) => {

            if (response.status === 200 || response.status === 201) {
                const newLogs = {
                    id: uuidv4(),
                    description: `document with name "${fileDocument.document?.fileName}" deleted from this medicale file `,
                    createdBy: `${userData.firstname} ${userData.lastname} `,
                    created_at: new Date().toISOString(),
                    medical_FileId: response.data.medicalFileId
                };

                // Update the medical file logs
                setdata((prevData) => ({ ...prevData, medical_Files_logs: [...prevData.medical_Files_logs, newLogs] }));
                // Update the documents array
                setDocuments((prevDoc) => prevDoc.filter(doc => doc.documentId !== fileDocument.documentId));
            }
        })
            .catch((err) => console.log(err));

    }, [userData, setdata, setDocuments]);


    const handleDownload = useCallback(async (document) => {
        try {
            const response = await axios.get(`/documents/${document.id}`, { responseType: 'blob' });
            if (response.status === 200 || response.status === 201) {
                //  Create a Blob object from the response data.
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                //Save the Blob as a file with the given filename.
                saveAs(blob, document.fileName);
            }
        } catch (error) {
            console.error('Error downloading document:', error);
        }
    }, []);

    const filteredDocuments = useMemo(() => {
        return Documents ? Documents.filter(doc => doc.fieldId === info.id) : [];
    }, [Documents, info.id]);

    return (
        <Box className='fieldType_container' onClick={() => handleRemoveRedDotReceiver(info)} >

            <Box className='fieldType_header_container'>

                <Box className='fieldType_label_container'>

                    {editLabelStatus ?
                        (
                            <Box className='fieldType_edit_label_field_box'>
                                <TextField
                                    className='customTextField'
                                    type='text'
                                    id={`labelfield-${info.id}`}
                                    name={info.label}
                                    placeholder={t('Title')}
                                    defaultValue={typeof label === 'string' ? label : ''}
                                    onChange={(event) => setLabel(event.target.value)}
                                    error={!label}
                                    helperText={!label && t('isRequired')}
                                />
                                {label && (
                                    <Box className='fieldType_label_actions_container'>
                                        <Iconify
                                            onClick={handleEditLabel}
                                            icon="mdi:check"
                                            className='fieldType_valid_label_icon'
                                        />
                                        <Divider orientation="vertical" flexItem />
                                        <Iconify
                                            onClick={handleCloseEditLable}
                                            icon="material-symbols:close-rounded"
                                            className='fieldType_cancel_label_icon'
                                        />
                                    </Box>
                                )}
                            </Box>
                        ) :
                        (<Typography className='medicalFileFields_Field_label' >{info.label}</Typography>)
                    }

                    {isFieldActionVisible && !editLabelStatus &&
                        <Iconify
                            onClick={() => setEditLabelStatus(true)}
                            icon='fe:edit'
                            className='fieldType_edit_label_icon'
                        />
                    }

                    {isRedDotVisible && <Iconify icon="noto:red-circle" />}
                </Box>

                {isFieldActionVisible &&
                    <ImeahActionIcon icon="ic:round-delete" color='#727179' onClick={() => handleDeleteFieldOpen(info.id)} />
                }
            </Box>

            {(info.value || isFieldEnabled) &&
                <TextField
                    className='customTextField'
                    fullWidth
                    type='text'
                    id={`document_description-${info.id}`}
                    multiline
                    disabled={!isFieldEnabled}
                    minRows={2}
                    maxRows={Infinity}
                    name='value'
                    placeholder={t('document_description')}
                    defaultValue={typeof info.value === 'string' ? info.value : ''}
                    onChange={event => debouncedHandleUpdateOneFieldData(info.id, event.target.value)}
                />
            }

            {filteredDocuments?.map((fileDocument) => (
                <Box key={fileDocument.id}>
                    <DocumentBox
                        fileDocument={fileDocument}
                        handleDownload={handleDownload}
                        handleDeleteDocument={handleDeleteDocument}
                        isFieldEnabled={isFieldEnabled}
                    />
                </Box>
            ))}

            {isFieldEnabled &&
                <UploadArea
                    info={info}
                    userData={userData}
                    data={data}
                    currentSection={currentSection}
                    setdata={setdata}
                    setDocuments={setDocuments}
                />
            }
        </Box>
    );
};