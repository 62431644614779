import React from 'react'
import { Box, Typography, CardMedia } from '@mui/material'
import '../../css/homePage/homeSixPart.css'
import Illustration from '../../images/imeahSixPart.svg'
import { useTranslation } from "react-i18next";

export const HomeSixPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeSixPart-main-box'>
            <CardMedia
                className="homeSixPart-image"
                component="img"
                image={Illustration}
                alt="My Image"
            />
            <Box className="homeSixPart-typo-box">
                <Box className="homeSixPart-typo-ehealth-box">
                    <Typography className="homeSixPart-typo-ehealth">ehealth pro</Typography>
                    <Typography className="homeSixPart-typo-aussi">{t('helpsAlso')}</Typography>
                </Box>
                <Typography className="homeSixPart-typo-aussi">{t('inTrainingPromotion')}</Typography>
                <Typography className="homeSixPart-typo-dr-junior">Doctor junior, {t('benifitInnovationOrganisation')}</Typography>
            </Box>
        </Box>
    )
}
