import { Avatar, Box, Button, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { setUserData } from '../../../redux/userDataSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import axios from 'axios';

export const ProfilePicCard = ({ userInfo, setOpenDelete, isSuperAdmin, isAllowedDeleteAllUsers, location, isGuest, Sections, setSection }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, profile, firstname, lastname, email, UserCvDocument } = userInfo;
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);
    // Memoized value for user authentication check
    const isUserAuthenticated = useMemo(() => userData?.id === id, [userData, id]);
    const fileInputRef = useRef(null);

    const [profileUrl, setProfileUrl] = useState('');

    const handleUploadClick = useCallback(() => {
        fileInputRef.current.click();
    }, []);

    useEffect(() => {
        if (profile?.fileKey) {
            const fetchDocument = async () => {
                try {
                    const response = await axios.get(`/documents/fetch_Document?fileKey=${encodeURIComponent(profile?.fileKey)}`, { responseType: 'blob' });
                    const src = URL.createObjectURL(response.data);
                    setProfileUrl(src);
                } catch (error) {
                    console.error('Error fetching document:', error);
                }
            };
            fetchDocument();
        }
    }, [profile?.fileKey]);

    const handleDeleteProfile = useCallback(() => {
        axios.delete(`user-profile/${profile?.id}`)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    setProfileUrl('');
                    dispatch(setUserData({ ...userData, profile: null }))
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, [profile?.id, userData, dispatch]);

    const handleDeleteCvDocument = useCallback(() => {
        axios.delete(`user-cv-document/${UserCvDocument?.id}`)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    dispatch(setUserData({ ...userData, UserCvDocument: null }))
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, [UserCvDocument?.id, userData, dispatch]);

    const handleUploadProfile = async (imageInformation) => {
        try {
            const imageId = `${uuidv4()}-${imageInformation.name}`;
            const fileData = new FormData();
            fileData.append('id', imageId);
            fileData.append('userId', id);
            fileData.append('fileKey', imageId);
            fileData.append('file', imageInformation)

            const response = await axios.post(`user-profile`, fileData);
            const profileData = response.data;

            dispatch(setUserData({ ...userData, profile: profileData }));
        } catch (err) {
            console.error(err);
        }
    };

    const handleDownloadUserCv = useCallback(async (document) => {
        try {
            const response = await axios.get(`/user-cv-document/${document.id}`, { responseType: 'blob' });
            if (response.status === 200 || response.status === 201) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                saveAs(blob, document.fileName);
            }
        } catch (error) {
            console.error('Error downloading user CV document:', error);
        }
    }, []);

    const handleGoBack = useCallback(() => {
        if (location.state?.HistoryPath === "createMedicalFile") {
            const data = {
                targetTemplateOwnerData: location.state.targetTemplateOwnerData,
                targetDoctorInstitutionId: location.state.targetDoctorInstitutionId,
                targetDoctorForShare: location.state.targetDoctorForShare,
                selectedSpeciality: location.state.selectedSpeciality,
                selectedExpertise: location.state.selectedExpertise,
                choosenTemplate: location.state.choosenTemplate,
                activeStep: location.state.activeStep
            };
            navigate('/dashboard/medicalFiles/createFile', { state: data });
        } else if (location.state?.userListTab) {
            navigate('/dashboard/user', { state: location.state.userListTab });
        } else {
            navigate(-1);
        }
    }, [location, navigate]);

    const renderAvatarActions = useCallback(() => {
        if (isUserAuthenticated) {
            return (
                <>
                    <IconButton className="account-prof-avatar-icon" onClick={handleUploadClick}>
                        <PhotoCamera />
                    </IconButton>
                    {profile &&
                        <IconButton onClick={handleDeleteProfile} className="account-prof-avatar-delete-icon" data-testid="removeProfilePic">
                            <HighlightOffIcon className="delete-icon" />
                        </IconButton>
                    }
                </>
            );
        }
        return null;
    }, [isUserAuthenticated, profile, handleUploadClick, handleDeleteProfile]);

    const renderCvDownloadSection = useCallback(() => {
        if (UserCvDocument?.image) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    {isUserAuthenticated &&
                        <IconButton onClick={handleDeleteCvDocument} data-testid="removeCvDoc">
                            <HighlightOffIcon className="delete-icon" sx={{ fontSize: '30px' }} />
                        </IconButton>
                    }
                    <Typography>{t('forDownloadCv')}</Typography>
                    <FileDownloadIcon sx={{ cursor: 'pointer', fontSize: '36px' }} onClick={() => handleDownloadUserCv(UserCvDocument)} data-testid="downloadCvDoc" />
                </Box>
            );
        }
        return null;
    }, [UserCvDocument, isUserAuthenticated, handleDeleteCvDocument, handleDownloadUserCv, t]);

    const renderRemoveUserButton = useCallback(() => {
        if ((isSuperAdmin || isAllowedDeleteAllUsers) && currentInstitution.id !== location.state.institutionUserId && !isGuest) {
            return <Button className='new-button' sx={{ width: "100%", marginBottom: '5px', textTransform: 'none' }} onClick={() => setOpenDelete(true)}>{t('removeUserFromInstitution')}</Button>;
        }
        return null;
    }, [isSuperAdmin, isAllowedDeleteAllUsers, currentInstitution?.id, location.state.institutionUserId, isGuest, setOpenDelete, t]);

    const renderProfileSectionMenu = useCallback(() => {
        if (isUserAuthenticated) {
            return (
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="contained" {...bindTrigger(popupState)} sx={{ width: "100%" }} className='new-button'>
                                {t('addPrfSec')}
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {Sections?.map((item, index) => (
                                    <MenuItem onClick={() => setSection(item.value)} key={index} value={item.value}>
                                        {t(item.label)}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </PopupState>
            );
        }
        return null;
    }, [isUserAuthenticated, Sections, setSection, t]);

    return (
        <Box className="account-prof-card-pic-part">
            <ArrowBackIosIcon className='back-btn' onClick={handleGoBack} sx={{ position: 'absolute', left: '10px', top: '10px' }} />

            <Box className="account-prof-avatar-container">
                <Avatar className="account-prof-avatar" src={profileUrl} />
                {renderAvatarActions()}
            </Box>
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                data-testid="addProfilePic"
                style={{ display: "none" }}
                onChange={event => { handleUploadProfile(event.target.files[0]) }}
            />
            <Typography className='imeah_propre_name' color="textPrimary" variant="h3" sx={{ textAlign: 'center' }}>{firstname} {lastname}</Typography>
            <Typography color="textPrimary" gutterBottom >{email}</Typography>
            {renderCvDownloadSection()}
            {renderRemoveUserButton()}
            {renderProfileSectionMenu()}
        </Box>
    );
};