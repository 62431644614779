import { Button, Dialog, DialogTitle, DialogActions, Box, Alert } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const DeleteList = ({ setOpenDelete, openDelete, id }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState(null);

    const handleDelete = useCallback(async () => {
        try {
            const response = await axios.delete(`select-list/${id}`);
            if (response.status === 200 || response.status === 201) {
                setOpenDelete(false);
                navigate('/dashboard/selectLists');
            }
        } catch (error) {
            console.error(error);
        }
    }, [id, navigate, setOpenDelete]);

    // Close the dialog and reset the error message
    const handleDeleteClose = useCallback(() => {
        setOpenDelete(false);
        setErrMsg(null);
    }, [setOpenDelete]);

    return (
        <Dialog open={openDelete} keepMounted aria-describedby="alert-dialog-slide-description">
            {errMsg ? <DialogTitle className='page-title'>{t("canNotRemove")}</DialogTitle> : <DialogTitle>{t('sureDeleteList')}</DialogTitle>}
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogActions>
                {errMsg ?
                    <Button className="new-button" onClick={handleDeleteClose}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button className='new-button' onClick={handleDeleteClose}>{t('cancel')}</Button>
                        <Button className='new-button' onClick={handleDelete}>{t('dlt')}</Button>
                    </Box>
                }
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(DeleteList);