import { Dialog, DialogActions, DialogTitle, Button, Alert, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import React, { useState } from 'react'
import axios from "axios";

export const DeleteInstitutionsUser = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { IdInstitutionUserForDelete, institutionUsers, setInstitutionUsers, openDelete, setOpenDelete, superAdmin } = props;

    const [msg, setMsg] = useState('');
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);

    const deleteUserInstitution = () => {
        let newData = [];
        if (currentInstitution) {
            axios.delete(`institutions-users/${IdInstitutionUserForDelete}`)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (currentInstitution.id === IdInstitutionUserForDelete) {
                            handleRefreshToken();
                            return;
                        }
                        if (superAdmin === "superAdmin") {
                            navigate('/dashboard/user')
                        }
                        else {
                            newData = institutionUsers.filter((item) => item.id !== IdInstitutionUserForDelete);
                            setInstitutionUsers(newData)
                            setOpenDelete(false)
                        }
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    setMsg(err.message)
                });
        }
    };

    const handleRefreshToken = async () => {
        try {
            const response = await axios.post("/auth/refresh",
                { userId: userData.id },
                { headers: { Authorization: `Bearer ${localStorage.getItem('refreshToken')}` } }
            );

            if (response?.status === 200 || response?.status === 201) {
                localStorage.setItem("token", response.data.accessToken);
                localStorage.setItem("refreshToken", response.data.refreshToken);
                window.dispatchEvent(new Event("storage"));
                axios.defaults.headers.common[["Authorization"]] = `Bearer ${response.data.accessToken} `;
                navigate('/dashboard/app')
            }
        } catch (refreshError) {
            localStorage.clear();
            // Remove the token from axios headers
            delete axios.defaults.headers.common["Authorization"];
            window.location.href = "/login";
        }
    }

    return (
        <Dialog open={openDelete} keepMounted>
            {msg ? <DialogTitle>{t("canNotRemove")}</DialogTitle> : <DialogTitle>{t("sureRemoveUserFromInstitution")}</DialogTitle>}
            {msg && <Alert severity="error">{msg}</Alert>}
            <DialogActions>
                {msg ?
                    <Button className="new-button" onClick={() => setOpenDelete(false)}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button className="new-button" onClick={() => { setOpenDelete(false) }}>{t("cancel")}</Button>
                        <Button className="new-button" onClick={() => deleteUserInstitution()}>{t("dlt")}</Button>
                    </Box>
                }
            </DialogActions>
        </Dialog>
    )
}