/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Dialog, DialogContent, Box, Alert, useMediaQuery, CircularProgress } from '@mui/material';

const DisplayFileDocument = ({ openFileDocunent, setOpenFileDocument, documentFileData }) => {
    const { t } = useTranslation();
    const [contentType, setContentType] = useState(null);
    const [documentSrc, setDocumentSrc] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');

    const inferContentType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'svg':
                return 'image';
            case 'mp4':
            case 'avi':
            case 'mov':
            case 'wmv':
            case 'mkv':
            case 'webm':
                return 'video';
            case 'pdf':
                return 'pdf';
            default:
                return null;
        }
    };

    useEffect(() => {
        setContentType(inferContentType(documentFileData?.fileName));
        if (documentFileData?.fileKey) {
            setLoading(true);
            const fetchDocument = async () => {
                try {
                    const response = await axios.get(`/documents/fetch_Document?fileKey=${encodeURIComponent(documentFileData.fileKey)}`, { responseType: 'blob' });
                    const src = URL.createObjectURL(response.data);
                    setDocumentSrc(src);
                    setLoading(false);
                } catch (error) {
                    setErrMsg('Error fetching document');
                    setLoading(false);
                }
            };

            fetchDocument();
        }
    }, [documentFileData?.fileKey]);

    return (
        <Box>
            {loading ?
                <Box sx={{ position: 'absolute', top: '50%', right: '50%' }}>
                    <CircularProgress size="4rem" />
                </Box> :
                <Dialog
                    open={openFileDocunent}
                    onClose={() => setOpenFileDocument(false)}
                    maxWidth={isMobile ? 'xl' : 'md'}
                    fullWidth
                    PaperProps={{
                        style: {
                            minWidth: '370px',
                            height: 'fit-content',
                        },
                    }}
                >
                    <DialogContent style={{ height: '100%', padding: 0 }}>
                        {errMsg && <Alert severity="error">{t(errMsg)}</Alert>}
                        {contentType === 'image' && documentSrc && <img src={documentSrc} alt="Medical Document" style={{ width: '100%', height: '100%' }} />}
                        {contentType === 'video' && documentSrc && <video controls style={{ width: '100%' }}><source src={documentSrc} type="video/mp4" /></video>}
                        {contentType === 'pdf' && documentSrc &&
                            <Box>
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                    <Viewer fileUrl={documentSrc} />
                                </Worker>
                            </Box>
                        }
                    </DialogContent>
                </Dialog>
            }
        </Box>

    );
};

export default DisplayFileDocument;