/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, Table, TableHead, TableRow, TableCell, TableBody, Box, FormControl, RadioGroup, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';
import Scrollbar from "../components/Scrollbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { fDate } from "../utils/formatTime";
import { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import Label from "../components/Label";
import Page from "../components/Page";
import '../css/meetings/Meeting.css';
import axios from 'axios';

export const MeetingsList = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);

    const [Meetings, setMeetings] = useState([])
    const [loading, setLoading] = useState(true);
    //filtration states
    const [filter, setFilter] = useState('today');
    //pagination state
    const hasMore = useRef(true);
    const offset = useRef(0);
    const limit = 10;

    const fetchMeetingsRelatedToMe = () => {
        const currentOffset = offset.current;
        axios.get(`meetings/AllRelatedToMe`, { params: { filter, limit, offset: currentOffset } })
            .then((response) => {
                setMeetings(currentOffset === 0 ? [...response.data] : [...Meetings, ...response.data]);
                setLoading(false)
                offset.current += limit;
                hasMore.current = response.data.length === limit;
            })
            .catch((err) => { console.log(err) });
    };

    useEffect(() => {
        if (userData) {
            fetchMeetingsRelatedToMe();
        }
    }, [userData, filter]);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        offset.current = 0;
        hasMore.current = true;
    };

    return (
        <Page title={t("meeting")}>

            {loading ? <LinearProgress /> :
                <Box>
                    <Stack className="main-page-header">
                        <Typography className='page-title'>{t("meeting")}</Typography>
                    </Stack>
                    <FormControl>
                        <RadioGroup row defaultValue="today" onChange={handleFilterChange}>
                            <FormControlLabel value="today" control={<Radio />} label={t("Today")} />
                            <FormControlLabel value="thisWeek" control={<Radio />} label={t("thisWeek")} />
                            <FormControlLabel value="thisMonth" control={<Radio />} label={t("thisMonth")} />
                            <FormControlLabel value="all" control={<Radio />} label={t("All")} />
                        </RadioGroup>
                    </FormControl>
                    <Scrollbar>
                        <InfiniteScroll
                            dataLength={Meetings.length}
                            next={fetchMeetingsRelatedToMe}
                            hasMore={hasMore.current}>
                            <Table>
                                <TableHead>
                                    <TableRow className='table-row'>
                                        <TableCell className='table-titles'>{t("Title")}</TableCell>
                                        <TableCell className='table-titles'>{t("type")}</TableCell>
                                        <TableCell className='table-titles'>{t("date")}</TableCell>
                                        <TableCell className='table-titles'>{t("Status")}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Meetings && Meetings.map((meeting) =>
                                        <TableRow key={meeting.id} style={{ cursor: 'pointer' }} onClick={() => navigate(`/dashboard/Meetings/${meeting.id}`)}>
                                            <TableCell align="left">{meeting.title}</TableCell>
                                            <TableCell align="left">{t(meeting.type)}</TableCell>
                                            <TableCell align="left">{fDate(meeting.date, userData.local_code)} </TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    variant="ghost"
                                                    sx={{
                                                        borderRadius: '4px',
                                                        padding: '2px',
                                                        color: 'black',
                                                        backgroundColor: meeting.status === 'Active' ? '#3ce641' : '  #fa464f'
                                                    }}
                                                >
                                                    {meeting.status}
                                                </Label>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </Scrollbar>
                </Box>
            }
        </Page>
    );
}