
import { AppWidgetSummary, } from './AppWidgetSummary';
import { useTranslation } from 'react-i18next';
import '../../css/dashboard/dashboardApp.css';
import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const AppWidget = () => {

    const { t } = useTranslation();
    const currentInstitution = useSelector(state => state.currentInstitution);

    const [userCount, setUserCount] = useState(0)
    // const [specialityCount, setSpecialityCount] = useState(0)
    // const [expertiseCount, setExpertiseCount] = useState(0)
    // const [selectListCount, setSelectListCount] = useState(0)

    const handleFetchAppWidgetCounts = async () => {
        try {
            const response = await axios.get('dashboard/appWidgetCounts');
            if (response.status === 200 || response.status === 201) {
                setUserCount(response.data.userCount);
                // setSpecialityCount(response.data.specialitiesCounts);
                // setExpertiseCount(response.data.expertisesCounts);
                // setSelectListCount(response.data.selectListCounts);
            }
        } catch (error) {
            console.error('Error Fetch App Widget Counts :', error.message);
        }
    };

    useEffect(() => {
        if (!currentInstitution) { return }; // Early return for unauthenticated users
        handleFetchAppWidgetCounts();
    }, [currentInstitution]);

    return (
        <Box className="dashboard-widgetSummary-box">
            <Grid>
                <AppWidgetSummary title={t('user')} total={userCount} icon={'eva:people-fill'} />
            </Grid>
            {/* <Grid>
                <AppWidgetSummary title={t('specialities')} total={specialityCount} icon={'jam:document-f'} />
            </Grid>
            <Grid>
                <AppWidgetSummary title={t('expertises')} total={expertiseCount} icon={'eva:file-text-fill'} />
            </Grid>
            <Grid>
                <AppWidgetSummary title={t('selectList')} total={selectListCount} icon={'material-symbols:lists-rounded'} />
            </Grid> */}
        </Box>
    );
}
