import { Table, TableHead, Stack, TableRow, TableBody, TableCell, Chip, Grid, useMediaQuery, Box, LinearProgress, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingButton } from "@mui/lab";
import Scrollbar from '../Scrollbar';
import axios from 'axios';
import Iconify from '../Iconify';

export const InvitedUsers = () => {

  const theme = useTheme();
  const { t } = useTranslation();
  const userData = useSelector(state => state.userData);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [loading, setLoading] = useState(true);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [inviteDialog, setInviteDialog] = useState(false);
  const [invitationData, setInvitationData] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);

  const fetchInvitedUsers = useCallback(async () => {
    if (userData) {
      try {
        const response = await axios.get('invite-new-users');
        if (response.status === 200 || response.status === 201) {
          setInvitedUsers(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [userData]);

  useEffect(() => {
    fetchInvitedUsers();
  }, [fetchInvitedUsers]);

  const handleHideInvitation = async (invitationId) => {
    try {
      const response = await axios.patch(`invite-new-users/hideRecord/${invitationId}`);
      if (response.status === 200 || response.status === 201) {
        setInvitedUsers(prevInvitations => prevInvitations.filter(invitation => invitation.id !== invitationId));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelInvitation = async (invitationId) => {
    try {
      const response = await axios.patch(`invite-new-users/cancelInvitation/${invitationId}`);
      if (response.status === 200 || response.status === 201) {
        setInvitedUsers(prevInvitations => prevInvitations.map(invitation => invitation.id === invitationId ? { ...invitation, status: 'canceled' } : invitation)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefreshInvitation = async () => {
    setInviteLoading(true);
    const data = {
      id: invitationData.id,
    };
    try {
      const response = await axios.post(`invite-new-users/refreshInvitation`, data);
      if (response.status === 200 || response.status === 201) {
        setInviteLoading(false);
        setInviteDialog(false);
      }
    } catch (error) {
      console.error(error);
      setInviteLoading(false);
    }
  };

  const handleOpenRefrechDialog = (row) => {
    setInviteDialog(true);
    setInvitationData(row)
  };

  return (
    <Box>
      {loading ? <LinearProgress /> :
        <Scrollbar>
          {isDesktop &&
            <Table >
              <TableHead>
                <TableRow className='table-row'>
                  <TableCell className='table-titles'>{t('email')}</TableCell>
                  <TableCell className='table-titles'>{t("institution")}</TableCell>
                  <TableCell className='table-titles'>{t('role')}</TableCell>
                  <TableCell className='table-titles'>{t('specialityExpertise')}</TableCell>
                  <TableCell className='table-titles'>{t('status')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {invitedUsers && invitedUsers.map((invitation, index) => (
                  <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                    <TableCell component="th" scope="row" padding="none" className="new-table-cell">
                      <Stack >{invitation.email}</Stack>
                    </TableCell>
                    <TableCell align="left" className="new-table-cell">{invitation.institution?.name}</TableCell>
                    <TableCell align="left" className="new-table-cell">{invitation.role.roleLabel[0].label}</TableCell>
                    {(invitation.InviteNewUsersExpertises.length > 0) &&
                      <TableCell align="left" className="new-table-cell">
                        {invitation.InviteNewUsersExpertises?.map((expertise, subIndex) => (
                          <Chip key={expertise.id || subIndex} label={expertise.expertise.expertiseLabel[0].label} className='chip' />
                        ))}
                      </TableCell>
                    }
                    {(invitation.specialityId !== 'NoSpeciality' && invitation.specialityId !== null) && (
                      <TableCell align="left" className="new-table-cell">
                        <Chip label={invitation.specialities?.specialityLabel[0].label} className='chip' />
                      </TableCell>
                    )}
                    {(!(invitation.specialityId !== 'NoSpeciality' && invitation.specialityId !== null) && (invitation.InviteNewUsersExpertises.length === 0)) && (
                      <TableCell align="left" className="new-table-cell">
                        <Chip label={t('none')} className='chip' />
                      </TableCell>
                    )}
                    <TableCell align="left" className="new-table-cell"><Typography sx={{
                      color: (invitation.status === 'refused' || invitation.status === 'canceled' || invitation.status === 'accepted') ? 'white' : 'black',
                      backgroundColor: (invitation.status === 'refused' || invitation.status === 'canceled') ? 'red' : invitation.status === 'accepted' ? 'green' : '#f7eb5e',
                      width: 'fit-content',
                      borderRadius: '5px',
                      padding: '3px',
                    }}
                    >{t(invitation.status)}</Typography></TableCell>

                    <TableCell align="left" className="new-table-cell">
                      {(invitation.status === "pending" && invitation.invitedById === userData.id) &&
                        <ReplayIcon onClick={() => handleOpenRefrechDialog(invitation)} className='new-delete-icon actions-btn' />}
                    </TableCell>

                    <TableCell align="left" className="new-table-cell">
                      {(invitation.status === "pending" && invitation.invitedById === userData.id) &&
                        <Iconify icon='material-symbols:cancel' onClick={() => handleCancelInvitation(invitation.id)} className='new-delete-icon actions-btn' data-testid='removeButton' />}
                    </TableCell>

                    <TableCell align="left" className="new-table-cell">
                      {(invitation.invitedById === userData.id && invitation.status !== "pending") &&
                        <Iconify icon='clarity:eye-hide-line' onClick={() => handleHideInvitation(invitation.id)} className='new-delete-icon actions-btn' data-testid='removeButton' />}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }

          {!isDesktop &&
            <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
              {invitedUsers && invitedUsers.map((invitation, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Box className="responsive-card-details">
                    <Typography className='card-resp-part'>{invitation.email}</Typography>
                    <Typography className='card-resp-part'>{invitation.role.roleLabel[0].label}</Typography>
                    <Typography sx={{
                      color: (invitation.status === 'refused' || invitation.status === 'canceled' || invitation.status === 'accepted') ? 'white' : 'black',
                      backgroundColor: (invitation.status === 'refused' || invitation.status === 'canceled') ? 'red' : invitation.status === 'accepted' ? 'green' : '#f7eb5e',
                      width: 'fit-content',
                      borderRadius: '5px',
                      padding: '3px',
                      alignSelf: 'center'
                    }}>{t(invitation.status)}</Typography>
                    <Box className='card-actions'>

                      {(invitation.status === "pending" && invitation.invitedById === userData.id) &&
                        <ReplayIcon onClick={() => handleOpenRefrechDialog(invitation)} className='new-delete-icon actions-btn' />
                      }

                      {(invitation.status === "pending" && invitation.invitedById === userData.id) &&
                        <Iconify icon='material-symbols:cancel' onClick={() => handleCancelInvitation(invitation.id)} className='new-delete-icon actions-btn' />
                      }

                      {(invitation.invitedById === userData.id && invitation.status !== "pending") &&
                        <Iconify icon='clarity:eye-hide-line' onClick={() => handleHideInvitation(invitation.id)} className='new-delete-icon actions-btn' />
                      }

                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          }
        </Scrollbar>
      }

      <Dialog open={inviteDialog} keepMounted>
        <DialogTitle>{t("invitationReminderTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("invitationReminderdescription")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='new-button' onClick={() => setInviteDialog(false)}>{t("cancel")}</Button>
          <LoadingButton className='new-button' loading={inviteLoading} onClick={handleRefreshInvitation} data-testid='inviteButton'>{t("send")}</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};