import { Dialog, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { DialogActions } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import React from "react";

export const AddItemPoilicy = (props) => {

    const { t } = useTranslation();

    const { openAddItemsDialog, setOpenAddItemsDialog, description, setDescription, descTitle, setDescTitle, descSubTitle, setDescSubTitle, index, setIndex } = props;

    const addItems = () => {
        if (index !== '') {
            const updatedDescription = [...description];
            updatedDescription[index] = { descTitle, descSubTitle };
            setDescription(updatedDescription);
        } else {
            setDescription([...description, { descTitle, descSubTitle }]);
        }

        setOpenAddItemsDialog(false);
        setDescTitle('');
        setDescSubTitle('');
        setIndex('');
    };

    const cancelDialog = () => {
        setOpenAddItemsDialog(false)
        setDescTitle('');
        setDescSubTitle('');
        setIndex('')
    }

    return (
        <Dialog fullWidth open={openAddItemsDialog} >
            <DialogTitle className="page-title">{t('policyItems')}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <TextField
                    id="value"
                    name="rulesTitle"
                    required
                    label={t('mainText')}
                    type="text"
                    multiline
                    rows={2}
                    value={descTitle}
                    variant="outlined"
                    onChange={(e) => setDescTitle(e.target.value)}
                    sx={{ marginTop: '15px' }}
                    error={!descTitle}
                    helperText={!descTitle && (t('isRequired'))}
                />

                <TextField
                    id="value"
                    name="rulesTitle"
                    required
                    label={t('subText')}
                    type="text"
                    multiline
                    rows={5}
                    value={descSubTitle}
                    variant="outlined"
                    onChange={(e) => setDescSubTitle(e.target.value)}
                    error={!descSubTitle}
                    helperText={!descSubTitle && (t('isRequired'))}
                />
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button className="new-button" onClick={cancelDialog}>{t('cancel')}</Button>
                <Button className="new-button" onClick={addItems} disabled={!descTitle || !descSubTitle}>{t('save')}</Button>
            </DialogActions>
        </Dialog>
    )
}