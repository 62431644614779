
import { Button, Dialog, Menu, MenuItem, Box, CircularProgress, DialogContent, DialogActions, Typography, Checkbox } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const ShareAgreement = ({ handleAgreeFilePolicies, isAgreementDialogOpen, targetPolicyCountry }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);

    const [currentPolicy, setCurrentPolicy] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [policyChecked, setPolicyChecked] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(userData.local_code);
    const [loading, setLoading] = useState(true);

    const fetchPolicy = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get('policies/currentPolicy', {
                params: {
                    language: selectedLanguage,
                    type: 'medicalFilePolicy',
                    institutionId: targetPolicyCountry,
                },
            });
            if (response.status === 200 || response.status === 201) {
                setLanguages(response.data.availableLanguages);
                setCurrentPolicy(response.data.policyWithSameVersionAndPecificLanguage);
            }
        } catch (error) {
            console.error('Error fetching policy:', error);
        } finally {
            setLoading(false);
        }
    }, [selectedLanguage, targetPolicyCountry]);

    useEffect(() => {
        fetchPolicy();
    }, [fetchPolicy]);

    const languageMenu = useMemo(() => {
        return (
            languages?.length > 1 && (
                <PopupState variant="popover" popupId="shareFileLanguagepopup">
                    {(popupState) => (
                        <>
                            <LanguageIcon
                                className="home-navbar-login"
                                sx={{ alignSelf: 'flex-end' }}
                                {...bindTrigger(popupState)}
                            />
                            <Menu {...bindMenu(popupState)}>
                                {languages.map((language, index) => (
                                    <MenuItem
                                        onClick={() => setSelectedLanguage(language)}
                                        key={index}
                                        value={language}
                                    >
                                        {t(language)}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </PopupState>
            )
        );
    }, [languages, t]);

    return (
        <Dialog open={isAgreementDialogOpen} keepMounted className="policy-dialog">
            {loading ? (
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            ) : (
                <DialogContent>
                    {currentPolicy && (
                        <DialogContent>
                            {languageMenu}
                            {currentPolicy?.description?.map((desc, index) => (
                                <Box key={index} className="policy-section">
                                    <Typography variant="h6" className="subTitle" sx={{ whiteSpace: 'pre-wrap' }}>{desc.descTitle}</Typography>
                                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>{desc.descSubTitle}</Typography>
                                </Box>
                            ))}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    className="imeahCheckbox"
                                    checked={policyChecked}
                                    onChange={() => setPolicyChecked(!policyChecked)}
                                />
                                <Typography className="policy-text">{t('agreeToPolicy')}</Typography>
                            </Box>
                        </DialogContent>
                    )}
                </DialogContent>
            )}

            <DialogActions>
                <Button className='buttonOutlined' onClick={() => navigate(-1)}>{t('cancel')}</Button>
                <Button
                    className='buttonFilled'
                    disabled={loading || !policyChecked}
                    onClick={() => handleAgreeFilePolicies(currentPolicy.id)}
                >{t('agree')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareAgreement;  