import { Box, Stack, Typography, Chip, Tooltip, Divider, IconButton, MenuItem, Menu } from "@mui/material";
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { HasPermission } from "../../utils/checkUserPermission";
import { useNavigate, useParams } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useResponsive from "../../hooks/useResponsive";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DeleteRole } from "./deleteRole";
import Iconify from "../Iconify";
import Page from "../Page";
import axios from 'axios';

export const ReadRole = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);
    const isDesktop = useResponsive('up', 'lg');

    const isAllowedEditRole = HasPermission('Edit_Role');
    const isAllowedDeleteRole = HasPermission('Delete_Role');

    const [roleData, setRoleData] = useState({
        englishName: '',
        frenchName: '',
        institutionId: '',
        canHaveSpeciality: false,
        canHaveExpertise: false,
        selectedPermissionLabels: []
    });
    const [openDelete, setOpenDelete] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);

    // Fetch role details
    useEffect(() => {
        const fetchRole = async () => {
            try {
                const response = await axios.get(`roles/${id}`);
                if (response.status === 200 || response.status === 201) {
                    const englishLabel = response.data?.roleLabel?.find((label) => label.language === "en")?.label || "";
                    const frenchLabel = response.data?.roleLabel?.find((label) => label.language === "fr")?.label || "";
                    setRoleData({
                        englishName: englishLabel,
                        frenchName: frenchLabel,
                        institutionId: response.data.institutionId,
                        canHaveSpeciality: response.data.canHaveSpeciality,
                        canHaveExpertise: response.data.canHaveExpertise,
                        selectedPermissionLabels: response.data.rolePermissionLabels
                    });
                }
            } catch (error) {
                console.error("Failed to fetch role details:", error);
            }
        };
        fetchRole();
    }, [id, userData.local_code]);

    // Memoize permission list for rendering
    const permissionsList = useMemo(() => {
        return (
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {roleData.selectedPermissionLabels?.map(permission => (
                    <Tooltip key={permission?.name} title={permission?.description} arrow>
                        <Chip label={permission?.name} />
                    </Tooltip>
                ))}
            </Box>
        );
    }, [roleData.selectedPermissionLabels]);

    const ActionButton = React.memo(({ icon, title, onClick }) => (
        <Box className='onefile-btn-container' onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem className='mdf-icon-button' />
            <Iconify icon={icon} className="onfile-headerBtn-icon" />
            <Typography className="onefile-btn-title">{title}</Typography>
        </Box>
    ));

    const ActionMenuButton = React.memo(({ icon, title, onClick }) => (
        <MenuItem className='onefile-btn-container' onClick={onClick}>
            <Iconify icon={icon} className="onfile-headerBtn-icon" />
            <Typography className="onefile-btn-title">{title}</Typography>
        </MenuItem>
    ));

    const handleActionMenu = useCallback((event) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    }, []);

    // Menu component
    const renderMenu = useMemo(() => (
        <Menu
            id="role-action-menu"
            open={openActionMenu}
            anchorEl={anchorEl}
            onClose={handleActionMenu}
            onClick={handleActionMenu}
            slotProps={{ paper: { style: { width: "30ch", backgroundColor: "#453f3f" } } }}
        >
            {isAllowedEditRole && currentInstitution?.institution?.id === roleData.institutionId && (
                <ActionMenuButton
                    icon="bytesize:edit"
                    title={t('edit')}
                    onClick={() => navigate(`/dashboard/roles/edit/${id}`)}
                />
            )}
            {isAllowedDeleteRole && currentInstitution?.institution?.id === roleData.institutionId && (
                <ActionMenuButton
                    icon="material-symbols:delete"
                    title={t('delete')}
                    onClick={() => setOpenDelete(true)}
                />
            )}
        </Menu>
    ), [openActionMenu, anchorEl, handleActionMenu, isAllowedEditRole, currentInstitution?.institution?.id, roleData.institutionId, t, isAllowedDeleteRole, navigate, id]);

    return (
        <Page title={t("readRole")}>

            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                <ArrowBackIosIcon className='editPolicy-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                <Stack className="editPolicy-main-page-header">
                    <Typography className='editPolicy-page-title'>{t("readRole")}</Typography>
                    <Box>
                        {isDesktop ? (
                            <Box className='onefile-action-button-box'>
                                {isAllowedEditRole && currentInstitution?.institution?.id === roleData.institutionId && (
                                    <ActionButton icon="bytesize:edit" title={t('edit')} onClick={() => navigate(`/dashboard/roles/edit/${id}`)} />
                                )}
                                {isAllowedDeleteRole && currentInstitution?.institution?.id === roleData.institutionId && (
                                    <ActionButton icon="material-symbols:delete" title={t('dlt')} onClick={() => setOpenDelete(true)} />
                                )}
                            </Box>
                        ) : (
                            <IconButton sx={{ color: 'white', padding: 0 }} onClick={handleActionMenu}>
                                <MoreHorizIcon />
                            </IconButton>
                        )}
                    </Box>
                    {renderMenu}
                </Stack>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="subtitle1">{t('englishName')} :</Typography>
                    <Typography>{roleData.englishName}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="subtitle1">{t('frenchName')} :</Typography>
                    <Typography>{roleData.frenchName}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="subtitle1">{t('canHaveSpeciality')} :</Typography>
                    <Typography>{t(roleData.canHaveSpeciality ? 'yes' : 'no')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="subtitle1">{t('canHaveExpertise')} :</Typography>
                    <Typography>{t(roleData.canHaveExpertise ? 'yes' : 'no')}</Typography>
                </Box>
            </Box>

            <Typography sx={{ marginBottom: '15px', marginTop: '15px', fontWeight: '700', color: '#17536B' }}>{t("permissions")} :</Typography>

            {permissionsList}

            {openDelete &&
                <DeleteRole
                    id={id}
                    openDelete={openDelete}
                    setOpenDelete={setOpenDelete}
                />
            }
        </Page>
    )
}