import { differenceInYears, differenceInMonths, differenceInDays } from 'date-fns';
import { Grid, TextField, MenuItem, Button, Box } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { ImeahPhoneInput } from '../../../utils/imeah_phone_input';
import { useFormik, Form, FormikProvider } from "formik";
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import axios from 'axios';

export const FillDataForm = ({ selectedSpeciality, selectedExpertise, targetDoctorForShare, choosenTemplate, setActiveStep }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().trim().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, t('firstNameOnlyLetters')).required(t('firstNameIsRequired')),
        last_name: Yup.string().trim().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, t('lastNameOnlyLetters')).required(t('lastNameIsRequired')),
        birthDate: Yup.date().max(new Date(), t('birthDateNotFuture')).required(t('birthDateIsRequired')),
        BirthPlace: Yup.string().trim().required(t('birthPlaceIsRequired')),
        address: Yup.string().trim().required(t('addressIsRequired')),
        Phone_number: Yup.string().trim().matches(/^\+?[0-9]+$/, t('PhoneNumberOnlyDigits')).optional(),
        age: Yup.string().trim().required(t('ageIsRequired')),
        gender: Yup.string().trim().required(t('genderIsRequired')),
        idSpeciality: Yup.string().trim().required(t('specialityIsRequired')),
        expertise: Yup.string().trim().required(t('expertiseIsRequired')),
        additional_information: Yup.array(),
        sections: Yup.array(
            Yup.object({
                id: Yup.string().required(t('isRequired')),
                value: Yup.string().required(t('isRequired')),
            })
        )
            .min(1, t('atLeastOneSectionRequired')),
        usedTemplate: Yup.string().trim().required(t('usedTemplateIsRequired')),
        targetDoctorForShare: Yup.string().trim().required(t('targetDoctorForShareIsRequired')),
        Social_Security_Number: Yup.string().trim().optional(),
        patient_Email: Yup.string().trim().email(t('invalidEmail')).optional(),
    }).test(
        'no-speciality-expertise-conflict',
        t('specialityOrExpertiseRequired'),
        (values) => {
            const { idSpeciality, expertise } = values;

            const isSpecialityFilled = idSpeciality !== 'NoSpeciality';
            const isExpertiseFilled = expertise !== 'NoExpertise';

            // One of them must be filled, and the other must remain as "NoSpeciality" or "NoExpertise"
            return (
                (isSpecialityFilled && expertise === 'NoExpertise') ||
                (isExpertiseFilled && idSpeciality === 'NoSpeciality')
            );
        }
    );

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            birthDate: '',
            BirthPlace: '',
            address: '',
            Phone_number: '',
            age: '',
            gender: '',
            idSpeciality: selectedSpeciality?.value || 'NoSpeciality',
            expertise: selectedExpertise?.value || 'NoExpertise',
            additional_information: [],
            sections: [],
            usedTemplate: choosenTemplate?.name || '',
            targetDoctorForShare: targetDoctorForShare || '',
            Social_Security_Number: '',
            patient_Email: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const response = await axios.post(`medical-file`, values);
                if (response.status === 200 || response.status === 201) {
                    navigate(`/dashboard/medicalFiles/${response.data}`);
                }
            } catch (error) {
                console.error('Error creating medical file:', error);
                CustomErrorLogger(error, 'Error creating medical file');
            } finally {
                setLoading(false);
            }
        }
    });

    const { errors, touched, values, setFieldValue, handleBlur, handleSubmit } = formik;

    useEffect(() => {
        const defaultSections = [
            { id: 'Complementary_Information123', value: 'Complementary Information' },
            { id: 'discussion123', value: 'Chat' },
            { id: 'Expertise123', value: 'Expertise' },
            { id: 'Modifications_History123', value: 'Modifications History' }
        ];

        const sectionsList = [...(choosenTemplate?.sections || []), ...defaultSections];

        const editedAdditionalFields = choosenTemplate?.additional_fields?.map((field) => ({
            ...field,
            id: uuidv4(),
            value: field.type === 'list'
                ? field.value.map((listItem) => ({ ...listItem, id: uuidv4() }))
                : field.value,
        })) || [];

        setFieldValue('sections', sectionsList);
        setFieldValue('additional_information', editedAdditionalFields);

    }, [setFieldValue, choosenTemplate]);

    const calculateAge = useCallback((birthDate) => {
        const now = new Date();

        if (!birthDate || isNaN(birthDate.getTime())) return { age: "", ageUnit: "" };

        const years = differenceInYears(now, birthDate);
        if (years >= 1) {
            return { age: years, ageUnit: `year${years > 1 ? "s" : ""}` };
        }

        const months = differenceInMonths(now, birthDate);
        if (months >= 1) {
            return { age: months, ageUnit: `month${months > 1 ? "s" : ""}` };
        }

        const days = differenceInDays(now, birthDate);
        return { age: days, ageUnit: `day${days > 1 ? "s" : ""}` };
    }, []);

    useEffect(() => {
        if (values.birthDate) {
            const birthDate = new Date(values.birthDate);
            const { age, ageUnit } = calculateAge(birthDate);
            if (age !== values.age) setFieldValue("age", age.toString());
            if (ageUnit !== values.ageUnit) setFieldValue("ageUnit", ageUnit);
        }
    }, [values.birthDate, values.age, setFieldValue, calculateAge, values.ageUnit]);

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="first_name"
                            name="first_name"
                            fullWidth
                            defaultValue={values.first_name}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            label={t('firstname')}
                            error={Boolean(touched.first_name && errors.first_name)}
                            helperText={(touched.first_name && errors.first_name)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="last_name"
                            name="last_name"
                            fullWidth
                            defaultValue={values.last_name}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            label={t('lastname')}
                            error={Boolean(touched.last_name && errors.last_name)}
                            helperText={(touched.last_name && errors.last_name)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="birthDate"
                            name="birthDate"
                            fullWidth
                            defaultValue={values.birthDate}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            type="date"
                            label={t('birthdate')}
                            error={Boolean(touched.birthDate && errors.birthDate)}
                            helperText={(touched.birthDate && errors.birthDate)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onFocus={(event) => event.target.showPicker()} // Opens the calendar on focus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="gender"
                            name="gender"
                            fullWidth
                            defaultValue={values.gender}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            select
                            label={t('gender')}
                            error={Boolean(touched.gender && errors.gender)}
                            helperText={(touched.gender && errors.gender)}
                        >
                            <MenuItem key={'male'} value={'male'}>{t('male')}</MenuItem>
                            <MenuItem key={'female'} value={'female'}>{t('female')}</MenuItem>
                            <MenuItem key={'other'} value={'other'}>{t('other')}</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="BirthPlace"
                            name="BirthPlace"
                            fullWidth
                            defaultValue={values.BirthPlace}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            label={t('Birth_Place')}
                            error={Boolean(touched.BirthPlace && errors.BirthPlace)}
                            helperText={(touched.BirthPlace && errors.BirthPlace)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="address"
                            name="address"
                            fullWidth
                            defaultValue={values.address}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            label={t('Address')}
                            error={Boolean(touched.address && errors.address)}
                            helperText={(touched.address && errors.address)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ImeahPhoneInput
                            required={false}
                            name="Phone_number"
                            value={values.Phone_number}
                            label={t('Enter_phone_number') + ' (' + t('optional') + ')'}
                            onChange={(value) => setFieldValue('Phone_number', value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="Social_Security_Number"
                            name="Social_Security_Number"
                            fullWidth
                            defaultValue={values.Social_Security_Number}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            label={t('Social_Security_Number') + ' (' + t('optional') + ')'}
                            error={Boolean(touched.Social_Security_Number && errors.Social_Security_Number)}
                            helperText={(touched.Social_Security_Number && errors.Social_Security_Number)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className='customTextField'
                            id="patient_Email"
                            name="patient_Email"
                            fullWidth
                            defaultValue={values.patient_Email}
                            onBlur={handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            label={t('patient_Email') + ' (' + t('optional') + ')'}
                            error={Boolean(touched.patient_Email && errors.patient_Email)}
                            helperText={(touched.patient_Email && errors.patient_Email)}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', marginTop: '20px', justifyContent: 'flex-end' }}>
                    <Button className='buttonOutlined' onClick={() => setActiveStep(2)} >{t("back")}</Button>
                    <LoadingButton className='buttonFilled' loading={loading} type="submit">{t("Create")}</LoadingButton>
                </Box>
            </Form>
        </FormikProvider>
    );
}