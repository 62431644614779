import axios from "axios";

export const CustomErrorLogger = async (error, context = "General") => {
    try {
        const API_KEY = window?.env?.REACT_APP_LOGGER_API_KEY || process.env.REACT_APP_LOGGER_API_KEY || ""; // Get API key from environment variables
        let errorMessage = "Unknown error";
        let errorStack = "No stack trace";
        let statusCode = null; // To store HTTP status code if available

        // 🔹 Handle different error types
        if (typeof error === "string") {
            errorMessage = error;
        } else if (error instanceof Error) {
            errorMessage = error.message;
            errorStack = error.stack || "No stack trace";
        } else if (error?.response) {
            statusCode = error.response.status; // Extract HTTP status code
            errorMessage = `API Error: ${error.response.status} ${error.response.statusText}`;
            errorStack = JSON.stringify(error.response.data, null, 2);
        } else if (typeof error === "object") {
            errorMessage = JSON.stringify(error, null, 2);
        }

        // 🔥 Skip logging for 401 Unauthorized errors
        if (statusCode === 401) {
            console.warn("Skipping log for 401 Unauthorized error.");
            return;
        }

        const logData = {
            message: errorMessage,
            stack: errorStack,
            level: "error",
            time: new Date().toISOString(),
            url: window.location.href,
            userAgent: navigator.userAgent,
            context,
        };

        // 🔹 Send the API key in headers
        await axios.post("/logger", logData, {
            headers: {
                "x-api-key": API_KEY, // ✅ Send API Key securely
                "Content-Type": "application/json"
            },
        });

    } catch (logError) {
        console.error("Error logging failed:", logError);
    }
};