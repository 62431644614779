import { Box, Typography, TextField, Divider } from '@mui/material';
import ImeahActionIcon from '../../../utils/imeah_action_icon';
import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import Iconify from '../../Iconify';
import axios from 'axios';
import '../../../css/medicalFiles/fieldType/longText.css';

export const LongText = ({ info, debouncedHandleUpdateOneFieldData, currentSection, allowedActions,
    handleDeleteFieldOpen, currentInstitution, handleRemoveRedDotReceiver, data, setdata }) => {

    const { t } = useTranslation();

    const [editLabelStatus, setEditLabelStatus] = useState(info.editLabelStatus || false);
    const [label, setLabel] = useState(info.label || '');

    const isRedDotVisible = useMemo(() => info.TargetRedDot?.some((RedDotId) => RedDotId.trim() === currentInstitution.id),
        [info.TargetRedDot, currentInstitution.id]
    );

    const isFieldActionVisible = useMemo(() =>
        currentSection === 'Complementary_Information123' &&
        (allowedActions.canEditPrivateMedicalFile || allowedActions.canEditSharedMedicalFile) &&
        (info.createdBy === currentInstitution.id || info?.createdBy === undefined),
        [currentSection, allowedActions, info.createdBy, currentInstitution.id]
    );

    const isFieldEnabled = useMemo(() => (
        (currentSection === 'Complementary_Information123' &&
            (allowedActions.canEditPrivateMedicalFile || allowedActions.canEditSharedMedicalFile) &&
            (info.createdBy === currentInstitution.id || info?.createdBy === undefined)) ||
        (currentSection !== 'Complementary_Information123' && allowedActions.canEditPrivateMedicalFile)
    ),
        [currentSection, allowedActions.canEditPrivateMedicalFile, allowedActions.canEditSharedMedicalFile, info.createdBy, currentInstitution.id]
    );

    const handleEditLabel = useCallback(async () => {
        try {
            if (!label) return;

            const response = await axios.patch(`medical-file/editFieldLabel/${data.id}`, { label, FieldId: info.id });
            if (response.status === 200 || response.status === 201) {
                setdata(prevData => ({
                    ...prevData,
                    additional_information: response.data.additional_information,
                    medical_Files_logs: response.data.medical_Files_logs
                }));
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            setEditLabelStatus(false);
        }

    }, [data.id, info.id, label, setdata])

    const handleCloseEditLable = useCallback(() => {
        setEditLabelStatus(false);
        setLabel(info.label);
    }, [info.label]);

    return (
        <Box className='longTextField_container' onClick={() => handleRemoveRedDotReceiver(info)} >

            <Box className='longTextField_header_container'>

                <Box className='longTextField_label_container'>

                    {editLabelStatus ?
                        (
                            <Box className='longTextField_edit_label_field_box'>
                                <TextField
                                    className='customTextField'
                                    type='text'
                                    id={info.id}
                                    name={info.label}
                                    placeholder={t('Title')}
                                    defaultValue={typeof label === 'string' ? label : ''}
                                    onChange={(event) => setLabel(event.target.value)}
                                    error={!label}
                                    helperText={!label && t('isRequired')}
                                />
                                {label && (
                                    <Box className='longTextField_label_actions_container'>
                                        <Iconify
                                            onClick={handleEditLabel}
                                            icon="mdi:check"
                                            className='longTextField_valid_label_icon'
                                        />
                                        <Divider orientation="vertical" flexItem />
                                        <Iconify
                                            onClick={handleCloseEditLable}
                                            icon="material-symbols:close-rounded"
                                            className='longTextField_cancel_label_icon'
                                        />
                                    </Box>
                                )}
                            </Box>
                        ) :
                        (<Typography className='medicalFileFields_Field_label' >{info.label}</Typography>)
                    }

                    {isFieldActionVisible && !editLabelStatus &&
                        <Iconify
                            onClick={() => setEditLabelStatus(true)}
                            icon='fe:edit'
                            className='longTextField_edit_label_icon'
                        />
                    }

                    {isRedDotVisible && <Iconify icon="noto:red-circle" />}
                </Box>

                {isFieldActionVisible &&
                    <ImeahActionIcon icon="ic:round-delete" color='#727179' onClick={() => handleDeleteFieldOpen(info.id)} />
                }
            </Box>

            <TextField
                className='customTextField'
                id={info.id}
                multiline
                minRows={2}
                maxRows={Infinity}
                name={info.label}
                type="text"
                disabled={!isFieldEnabled}
                defaultValue={typeof info.value === 'string' ? info.value : ''}
                onChange={event => debouncedHandleUpdateOneFieldData(info.id, event.target.value)}
            />

        </Box>
    );
};