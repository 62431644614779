import { Box, Typography, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DocumentBox from './DocumentBox';
import UploadArea from './uploadArea';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import axios from 'axios';
import '../../../../css/medicalFiles/fieldType/fileInList.css';

export const FileInList = ({ info, item, data, setdata, setDocuments, Documents, debouncedHandleUpdateOneListFieldData, allowedActions }) => {

    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    const handleDeleteDocument = useCallback(async (fileDocument) => {

        axios.delete(`file-documents/${fileDocument.id}`, { data: { isNeedFileLogs: true } }).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const newLogs = {
                    id: uuidv4(),
                    description: `document with name "${fileDocument.document?.fileName}" deleted from this medicale file `,
                    createdBy: `${userData.firstname} ${userData.lastname} `,
                    created_at: new Date().toISOString(),
                    medical_FileId: response.data.medicalFileId
                };

                // Update the medical file logs
                setdata((prevData) => ({ ...prevData, medical_Files_logs: [...prevData.medical_Files_logs, newLogs] }));
                // Update the documents array
                setDocuments((prevDoc) => prevDoc.filter(doc => doc.documentId !== fileDocument.documentId));
            }
        })
            .catch((err) => console.log(err));

    }, [userData, setdata, setDocuments]);

    const handleDownload = useCallback(async (document) => {
        try {
            const response = await axios.get(`/documents/${document.id}`, { responseType: 'blob' });
            if (response.status === 200 || response.status === 201) {
                //  Create a Blob object from the response data.
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                //Save the Blob as a file with the given filename.
                saveAs(blob, document.fileName);
            }
        } catch (error) {
            console.error('Error downloading document:', error);
        }
    }, []);

    const filteredDocuments = useMemo(() => {
        return Documents ? Documents.filter(doc => doc.fieldId === item.id) : [];
    }, [Documents, item.id]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Typography className='medicalFileFields_listField_label'>{item.ItemLabel}</Typography>

            {(item.ItemValue || allowedActions.canEditPrivateMedicalFile) &&
                <TextField
                    className='customTextField'
                    fullWidth
                    type='text'
                    id={`document_description-${item.id}`}
                    multiline
                    disabled={!allowedActions.canEditPrivateMedicalFile}
                    minRows={2}
                    maxRows={Infinity}
                    name='ItemValue'
                    placeholder={t('document_description')}
                    defaultValue={typeof item.ItemValue === 'string' ? item.ItemValue : ''}
                    onChange={event => debouncedHandleUpdateOneListFieldData(info.id, item.id, event.target.value)}
                />
            }

            {filteredDocuments.map((fileDocument) => (
                <Box key={fileDocument.id}>
                    <DocumentBox
                        fileDocument={fileDocument}
                        handleDownload={handleDownload}
                        handleDeleteDocument={handleDeleteDocument}
                        isFieldEnabled={allowedActions.canEditPrivateMedicalFile}
                    />
                </Box>
            ))}

            {allowedActions.canEditPrivateMedicalFile &&
                <UploadArea
                    info={info}
                    item={item}
                    userData={userData}
                    data={data}
                    setdata={setdata}
                    setDocuments={setDocuments}
                />
            }

        </Box>
    );
};