import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import { Drawer, Box, Typography, Button } from '@mui/material';
import ImeahFilterMenu from '.././../../utils/imeah_filter_menu';
import '../../../css/medicalFiles/medicalFileResponsiveFiltration.css';

const FilterButton = ({ filterKey, currentFilter, onClick, label, selectedItem, handleRemoveItem }) => {
    const { t } = useTranslation();
    return (
        <Box>
            <Button
                sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 20px', color: '#727179', width: '100%', border: '1px solid #727179', borderRadius: '10px', fontSize: '14px', fontWeight: '400' }}
                onClick={(e) => onClick(e, filterKey)}
                endIcon={<Iconify icon={currentFilter === filterKey ? 'iconamoon:arrow-down-2-light' : 'iconamoon:arrow-right-2-light'} />}
                data-id={filterKey}
            >
                {t(label)}
            </Button>
            {selectedItem && (
                <Box className="imeah_ships_box" sx={{ marginTop: '5px', }}>
                    <Typography variant="body2" className='imeah_propre_name'>{selectedItem.label || selectedItem}</Typography>
                    <Iconify icon="material-symbols:close-rounded" sx={{ fontSize: '20px', cursor: 'pointer' }} onClick={handleRemoveItem} />
                </Box>
            )}
        </Box>
    );
};

const MedicalFileResponsiveFiltration = (props) => {

    const { t } = useTranslation();

    const { isDrawerOpen, setIsDrawerOpen, handleChangeSpeciality, selectedSpeciality,
        selectedDoctor, handleChangeDoctor, selectedSharedDoctor, handleChangeSharedDoctor,
        filtersMenu, selectedStatus, handleChangeStatus, searchName, handleChangeSearchName, isMobile
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentFilter, setCurrentFilter] = useState(null);

    const openFilterMenu = (event, filterKey) => {
        if (event.currentTarget) {
            setAnchorEl(event.currentTarget);
            setCurrentFilter(filterKey);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentFilter(null);
        setIsDrawerOpen(false);
    };

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    marginTop: '13%',
                    borderTopLeftRadius: '15px',
                    width: '80vw',
                },
                className: isDrawerOpen ? 'drawer-enter' : 'drawer-exit'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px', alignItems: 'flex-start', padding: '40px 20px 0px 20px' }}>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Iconify
                            icon='material-symbols:filter-alt'
                            sx={{ fontSize: '32px', cursor: 'pointer', color: '#727179' }}
                        />
                        <Typography sx={{ fontSize: '22px' }}>{t('Filter')}</Typography>
                    </Box>
                    <Iconify icon="material-symbols:close-rounded" sx={{ fontSize: '32px', color: '#727179', cursor: 'pointer' }} onClick={handleClose} />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                    <FilterButton
                        filterKey="name"
                        currentFilter={currentFilter}
                        onClick={openFilterMenu}
                        label="Patient_name"
                        selectedItem={searchName}
                        handleRemoveItem={() => handleChangeSearchName('')}
                    />
                    <FilterButton
                        filterKey="specialityExpertise"
                        currentFilter={currentFilter}
                        onClick={openFilterMenu}
                        label="specialityExpertise"
                        selectedItem={selectedSpeciality}
                        handleRemoveItem={() => handleChangeSpeciality(null)}
                    />
                    <FilterButton
                        filterKey="requerant"
                        currentFilter={currentFilter}
                        onClick={openFilterMenu}
                        label="requerant"
                        selectedItem={selectedDoctor}
                        handleRemoveItem={() => handleChangeDoctor(null)}
                    />
                    <FilterButton
                        filterKey="requis"
                        currentFilter={currentFilter}
                        onClick={openFilterMenu}
                        label="requis"
                        selectedItem={selectedSharedDoctor}
                        handleRemoveItem={() => handleChangeSharedDoctor(null)}
                    />
                    <FilterButton
                        filterKey="status"
                        currentFilter={currentFilter}
                        onClick={openFilterMenu}
                        label="Status"
                        selectedItem={selectedStatus}
                        handleRemoveItem={() => handleChangeStatus(null)}
                    />
                </Box>

            </Box>

            <ImeahFilterMenu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                options={currentFilter ? filtersMenu[currentFilter].options : []}
                handleChange={currentFilter ? filtersMenu[currentFilter].handleChange : () => { }}
                isMobile={isMobile}
                currentFilter={currentFilter}
            />

        </Drawer>
    );
};

export default MedicalFileResponsiveFiltration;