import React from 'react'
import { Box } from '@mui/material';
import { HomeFirstPart } from '../components/HomePage/HomeFirstPart';
import { HomeFivePart } from '../components/HomePage/HomeFivePart';
import { HomeFourthPart } from '../components/HomePage/HomeFourthPart';
import { HomeSecondPart } from '../components/HomePage/HomeSecondPart';
import { HomeSevenPart } from '../components/HomePage/HomeSevenPart';
import { HomeSixPart } from '../components/HomePage/HomeSixPart';
import { HomeThirdPart } from '../components/HomePage/HomeThirdPart';
import PersistentDrawerLeft from '../components/HomePage/NavBar';
import { HomeEightPart } from '../components/HomePage/HomeEightPart';
import { HomeNinePart } from '../components/HomePage/HomeNinePart';
import { HomeTenPart } from '../components/HomePage/HomeTenPart';
import { HomeElevenPart } from '../components/HomePage/HomeElevenPart';
import { ContactUs } from '../components/HomePage/contact_us';
import { Footer } from '../components/HomePage/footer';
import Page from "../components/Page";
import '../css/homePage/home.css'
import { useTranslation } from "react-i18next";

export const Home = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("Home_Page_Title")}>
      <Box className='home-main-box'>
        <PersistentDrawerLeft />
        <Box id={t('report')} >
          <HomeFirstPart />
        </Box>
        <Box id={t('networkManagement')} >
          <HomeSecondPart />
        </Box>
        <Box>
          <HomeThirdPart />
        </Box>
        <Box>
          <HomeFourthPart />
        </Box>
        <Box>
          <HomeFivePart />
        </Box>
        <Box id={t('training')} >
          <HomeSixPart />
        </Box>
        <Box id={t('library')} >
          <HomeSevenPart />
        </Box>
        <Box id={t('forWho')} >
          <HomeEightPart />
        </Box>
        <Box id={t('responsibleMedicine')} >
          <HomeNinePart />
        </Box>
        <Box>
          <HomeTenPart />
        </Box>
        <Box id={t('whoWeAre')} >
          <HomeElevenPart />
        </Box>
        <Box id={t('contactUs')} >
          <ContactUs />
        </Box>
        <Footer />
      </Box>
    </Page>
  )
}
