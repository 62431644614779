import { LanguageSwitcher } from "../NoLoginLanguageSwitcher";
import { Typography, TextField, Box } from "@mui/material";
import logo from '../../images/logo_e_health_pro.svg';
import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../../css/User-css/forgotPassword.css';
import { LoadingButton } from "@mui/lab";
import { useFormik } from 'formik';
import Page from "../Page";
import * as Yup from 'yup';
import axios from "axios";

export const ForgotPassword = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(navigator.language.startsWith('en') ? 'en' : 'fr');
  const [buttonLoading, setButtonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('validEml')).required(t('emailReq')),
    }),
    onSubmit: async (values) => {
      setButtonLoading(true);

      try {
        const response = await axios.post('forgot-password/sendEmailForgotPassword', { email: values.email, language });
        if (response.status === 200 || response.status === 201) {
          navigate('/email_successfully_sent')
        }
      } catch (err) {
        console.log(err);
      } finally {
        setButtonLoading(false);
      }
    },
  });

  const handleLangUpdate = useCallback((langData) => setLanguage(langData), []);

  const { handleSubmit, values, handleChange, handleBlur, touched, errors } = formik;

  return (
    <Page title={t("Forgot_Password_Page_Title")} className='FG-container-pass'>
      <Box className='FG-card-pass' >

        <Box display='flex' justifyContent='space-between'>
          <Box sx={{ width: '200px' }} component="img" src={logo} alt="Imeah Logo" />
          <LanguageSwitcher handleLangUpdate={handleLangUpdate} />
        </Box>

        <Typography className="forgotPass_title">{t("resetPassword")}</Typography>

        <Typography className="forgotPass_enterEmail_typo">{t("enterEmailAssociated")}</Typography>

        <form onSubmit={handleSubmit} className="FG-form">
          <TextField
            fullWidth
            className="customTextField"
            id="ForgotPassEmail"
            name="email"
            type="email"
            label={t("email")}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <LoadingButton loading={buttonLoading} className="forgotPass-loginButton" type="submit">{t('sendInstructions')}</LoadingButton>
        </form>
      </Box>
    </Page>
  );
}