import { Typography, Box, Table, TableHead, TableCell, TableBody, Radio, Grid, useMediaQuery, Alert, Button } from '@mui/material';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { HasPermission } from '../../utils/checkUserPermission';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImeahTableRow } from '../../utils/imeah_table_row';
import { useNavigate, useParams } from "react-router-dom";
import { ImeahSelect } from '../../utils/imeah_select';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../Iconify';
import Page from '../Page';
import axios from 'axios';
import '../../css/medicalFiles/copyFile.css';

export const CopyFile = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const currentInstitution = useSelector(state => state.currentInstitution);

    //check user permissions
    const isAllowedcreateMedicalFileForSpecialist = HasPermission('create_Medical_File_for_specialist');
    const isAllowedcreateMedicalFileForExpert = HasPermission('create_Medical_File_for_expert');

    const [selectedSpeciality, setSelectedSpeciality] = useState('')
    const [selectedExpertise, setSelectedExpertise] = useState('')
    const [TargetDoctor, setTargetDoctor] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [errMsg, setErrMsg] = useState(null)
    //pagination state
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 10;

    const specialityId = useMemo(() => selectedSpeciality?.value || 'NoSpeciality', [selectedSpeciality]);
    const expertiseId = useMemo(() => selectedExpertise?.value || 'NoExpertise', [selectedExpertise]);

    const fetchSpecialities = useCallback(async () => {
        try {
            const response = await axios.get('speciality/specialitiesForCreateMedicalFile');
            return response.data?.map(record => ({ value: record?.value, label: record?.label }));
        } catch (err) {
            console.log(err);
            return [];
        }
    }, []);

    const fetchExpertises = useCallback(async () => {
        try {
            const response = await axios.get('expertise/expertisesForCreateMedicalFile');
            return response.data?.map(record => ({ value: record?.value, label: record?.label }));
        } catch (err) {
            console.log(err);
            return [];
        }
    }, []);

    const fetchTargetDoctor = useCallback(async () => {
        try {
            // Exit the function if I don't have specific Speciality or expertise
            if (specialityId === 'NoSpeciality' && expertiseId === 'NoExpertise') {
                setTargetDoctor([]);
                hasMore.current = false;
                offset.current = 0;
                return;
            }

            const currentOffset = offset.current;
            const response = await axios.get('institutions-users/targetInstitutionsUsersForCreateMedicalFile', { params: { offset: currentOffset, limit, specialityId, expertiseId } });
            if (response.status === 200 || response.status === 201) {
                setTargetDoctor(prev => (currentOffset === 0 ? response.data : [...prev, ...response.data]));
                hasMore.current = response.data.length === limit;
                offset.current = currentOffset + limit;
            }
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    }, [specialityId, expertiseId]);

    useEffect(() => {
        if (currentInstitution) {
            fetchTargetDoctor()
        }
    }, [currentInstitution, fetchTargetDoctor]);

    const handleCopyFile = async () => {

        if ((specialityId === 'NoSpeciality' && expertiseId === 'NoExpertise') || selectedDoctor === null) {
            setErrMsg(t('copyFile_emptyData_warning'));
            return
        }

        const CopyMedicalFileData = {
            id,
            specialityId: specialityId,
            expertiseId: expertiseId,
            targetDoctorId: selectedDoctor
        }

        try {
            const response = await axios.post(`medical-file/CopyMedicalFile`, CopyMedicalFileData)
            if (response.status === 200 || response.status === 201) {
                navigate(`/dashboard/medicalFiles/${response.data}`);
            }
        }
        catch (error) {
            console.error('Error copy medical file:', error);
        }
    }

    const handleChangeSpeciality = (selectedSpeciality) => {
        setSelectedSpeciality(selectedSpeciality);
        setSelectedExpertise('');
        setSelectedDoctor(null);
        setErrMsg(null);
        offset.current = 0;
        hasMore.current = true;
    }

    const handleChangeExpertise = (selectedExpertise) => {
        setSelectedExpertise(selectedExpertise);
        setSelectedSpeciality('');
        setSelectedDoctor(null);
        setErrMsg(null);
        offset.current = 0;
        hasMore.current = true;
    }

    const handleChangeDoctor = (choosenDoctor) => {
        setSelectedDoctor(choosenDoctor.id);
        setErrMsg(null)
    }

    return (
        <Page title={t("copyFile_header_title")}>

            <Box display={'flex'} alignItems={'center'} gap={1} mb={'25px'}>
                <Iconify icon='fluent:ios-arrow-24-filled' className='onefile-back-btn' onClick={() => navigate(-1)} />
                <Typography className="imeahTitle">{t("copyFile_header_title")}</Typography>
            </Box>

            {errMsg && <Alert severity="error">{errMsg}</Alert>}

            <Box className='copyFile-select-box'>
                <Grid container spacing={2}>
                    {isAllowedcreateMedicalFileForSpecialist &&
                        <Grid item xs={12} sm={6}>
                            <ImeahSelect
                                label={t('speciality')}
                                required={false}
                                searchable={true}
                                value={selectedSpeciality}
                                fetchFunction={fetchSpecialities}
                                onChange={(selectedSpeciality) => handleChangeSpeciality(selectedSpeciality.target)}
                            />
                        </Grid>}

                    {isAllowedcreateMedicalFileForExpert &&
                        <Grid item xs={12} sm={6}>
                            <ImeahSelect
                                label={t('expertise')}
                                required={false}
                                searchable={true}
                                value={selectedExpertise}
                                fetchFunction={fetchExpertises}
                                onChange={(selectedExpertise) => handleChangeExpertise(selectedExpertise.target)}
                            />
                        </Grid>
                    }
                </Grid>

                {!selectedSpeciality?.value && !selectedExpertise?.value && (
                    <Typography className="imeahCaption">
                        {t('atLeastSpecialityOrExpertiseRequired')}
                    </Typography>
                )}

                {TargetDoctor?.length > 0 &&
                    <InfiniteScroll
                        dataLength={TargetDoctor.length}
                        next={fetchTargetDoctor}
                        hasMore={hasMore.current}
                    >

                        {isDesktop ?
                            <Box
                                sx={{
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    '-ms-overflow-style': 'none', // IE and Edge
                                    'scrollbar-width': 'none',   // Firefox
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <ImeahTableRow titlesRow={true}>
                                            <TableCell >{t('fullName')}</TableCell>
                                            <TableCell >{t('institution')}</TableCell>
                                        </ImeahTableRow>
                                    </TableHead>

                                    <TableBody>
                                        {TargetDoctor && TargetDoctor.map((doctor, index) =>
                                            <ImeahTableRow key={index} onClick={() => handleChangeDoctor(doctor)} >
                                                <TableCell >
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Radio
                                                            className='imeahRadio'
                                                            checked={selectedDoctor === doctor.id}
                                                            name={index.toString()}
                                                        />
                                                        <Typography className='imeah_propre_name' sx={{ fontSize: '14px' }}>{doctor.user?.firstname} {doctor.user?.lastname}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell >{doctor.institution?.name}</TableCell>
                                            </ImeahTableRow>
                                        )}
                                    </TableBody>
                                </Table >
                            </Box> :
                            <Box className="select_sp_exp_responsive_cards_box" >
                                {TargetDoctor && TargetDoctor.map((doctor, index) => (
                                    <Box className="select_sp_exp_responsive_card_details" key={doctor.id} onClick={() => handleChangeDoctor(doctor)}>
                                        <Radio
                                            className='imeahRadio'
                                            sx={{ position: 'absolute', top: '5px', right: '5px' }}
                                            checked={selectedDoctor === doctor.id}
                                            name={index.toString()}
                                        />
                                        <Box className="select_sp_exp_responsive_card_title_response_box">
                                            <Typography className='select_sp_exp_responsive_card_title'>{t('fullName')}</Typography>
                                            <Typography className='select_sp_exp_responsive_card_response imeah_propre_name'>{doctor.user?.firstname}  {doctor.user?.lastname}</Typography>
                                        </Box>
                                        <Box className="select_sp_exp_responsive_card_title_response_box">
                                            <Typography className='select_sp_exp_responsive_card_title'>{t('institution')}</Typography>
                                            <Typography className='select_sp_exp_responsive_card_response'>{doctor.institution?.name}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        }
                    </InfiniteScroll>
                }

                <Box className="copyFile-actions-box">
                    <Button className='buttonOutlined' onClick={() => navigate(-1)}>{t('cancel')}</Button>
                    <Button className='buttonFilled' onClick={handleCopyFile}>{t('duplicateMedicalFileAction')}</Button>
                </Box>

            </Box>
        </Page>

    );
};