import { Button, Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';
import React, { useCallback } from "react";
import '../css/errorDialog.css';

const ErrorDialog = ({ errorMessage, setErrorMessage }) => {

    const { t } = useTranslation();

    const handleCloseDialog = useCallback(() => setErrorMessage(null), [setErrorMessage]);

    if (!errorMessage) return null; // Prevent unnecessary renders

    return (
        <Dialog open keepMounted onClose={handleCloseDialog}>
            <DialogContent className='error_dialog_content'>
                <Iconify icon='ic:round-error' className='error_dialog_icon' />
                <Typography className='error_dialog_title'>{t(errorMessage)}</Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth className='error_dialog_button' onClick={handleCloseDialog}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(ErrorDialog);