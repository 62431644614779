import { Button, TextField, DialogTitle, DialogContent, DialogActions, Dialog, Alert, Box, Typography, CircularProgress, MenuItem } from "@mui/material";
import { setCurrentInstitution } from "../../../redux/currentInstitutionSlice";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { setUserData } from '../../../redux/userDataSlice';
import React, { useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MuiPhone } from "../../../utils/muiPhone";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from "yup";
import axios from "axios";

export const EditAccountProfileData = ({ setOpen, userInfo, setUserInfo, InstitutionUserData }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);

    const fileInputRef = useRef(null);
    const [msg, setMsg] = useState('');
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [maxRecommandationDay, setMaxRecommandationDay] = useState(InstitutionUserData?.recommandationTime);
    const [Scouting, setScouting] = useState({ amount: InstitutionUserData?.Scouting?.amount, currency: InstitutionUserData?.Scouting?.currency });

    // Memoized currencies array
    const currencies = useMemo(() => [
        { value: 'USD', label: '$' },
        { value: 'EUR', label: '€' },
        { value: 'L.L', label: 'L.L' },
    ], []);

    const handlePhoneChange = (value) => {
        setUserInfo(prev => ({ ...prev, phone: value }));
    };

    const handleChange = (event) => {
        setUserInfo(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().trim().required(t("fstnmReq")),
        lastname: Yup.string().trim().required(t("lstnmReq")),
        email: Yup.string().email(t("validEml")).trim().required(t("emailReq")),
    });

    const editUser = () => {
        const data = {
            institutionUserId: currentInstitution?.userInstitutionId,
            Scouting,
            recommandationTime: Number(maxRecommandationDay),
            firstname: userInfo.firstname.toUpperCase(),
            lastname: userInfo.lastname.toUpperCase(),
            email: userInfo.email,
            phone: userInfo.phone,
            RPPS: userInfo.RPPS
        }
        if (
            validationSchema.isValidSync({
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                email: userInfo.email,
            })
        ) {
            axios.put(`users/updateMyProfile/${userData.id}`, data)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        dispatch(setUserData({ ...userData, ...response.data.updateMainInfo }));
                        dispatch(setCurrentInstitution({ ...currentInstitution, ...response.data.updateInstitutionUserInfo }));
                        setOpen(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setMsg(error.message)
                });
        }

    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleUploadCv = async (userId, imageInformation) => {
        setLoadingUpload(true);
        try {
            const uploadPromises = Array.from(imageInformation).map(file => {
                const imageId = `${uuidv4()}-${file.name}`;
                const fileData = new FormData();
                fileData.append('id', imageId);
                fileData.append('userId', userId);
                fileData.append('fileKey', imageId);
                fileData.append('file', file);

                return axios.post(`user-cv-document`, fileData);
            });
            const responses = await Promise.all(uploadPromises);
            const cvData = responses.map(response => response.data);
            dispatch(setUserData({ ...userData, UserCvDocument: cvData }));
            setOpen(false);
        } catch (err) {
            console.error(err);
            setMsg(t('failedUploadFile'));
        } finally {
            setLoadingUpload(false);
        }
    };

    return (
        <Dialog open fullWidth>
            <DialogTitle>{t("editInfo")}</DialogTitle>
            {msg && <Alert severity="error">{msg}</Alert>}
            <DialogContent>
                <TextField
                    fullWidth
                    required
                    margin="dense"
                    label={t("firstname")}
                    name="firstname"
                    onChange={handleChange}
                    value={userInfo?.firstname}
                    error={!userInfo?.firstname}
                    helperText={!userInfo?.firstname && (t('isRequired'))}
                />
                <TextField
                    fullWidth
                    required
                    margin="dense"
                    label={t("lastname")}
                    name="lastname"
                    onChange={handleChange}
                    value={userInfo?.lastname || ""}
                    error={!userInfo?.lastname}
                    helperText={!userInfo?.lastname && (t('isRequired'))}
                />
                <TextField
                    fullWidth
                    required
                    margin="dense"
                    label={t("email")}
                    name="email"
                    onChange={handleChange}
                    value={userInfo?.email || ""}
                    error={!userInfo?.email}
                    helperText={!userInfo?.email && (t('isRequired'))}
                />
                <TextField
                    fullWidth
                    margin="dense"
                    label={t("RPPS")}
                    name="RPPS"
                    onChange={handleChange}
                    value={userInfo?.RPPS !== null ? userInfo?.RPPS : undefined}
                />
                {InstitutionUserData?.length !== 0 &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <TextField
                            margin="dense"
                            label={t("scouting")}
                            name="scouting"
                            onChange={(e) => setScouting({ ...Scouting, amount: e.target.value })}
                            value={Scouting.amount || ""}
                        />
                        <TextField
                            id="filled-select-currency"
                            select
                            label="Currency"
                            onChange={(e) => setScouting({ ...Scouting, currency: e.target.value })}
                            value={Scouting.currency || ""}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="outlined-number"
                            margin="dense"
                            label={t("maxRecomDay")}
                            name="maxRecomDay"
                            onChange={(e) => setMaxRecommandationDay(e.target.value)}
                            value={maxRecommandationDay || ""}
                            type="number"
                        />
                    </Box>}
                <MuiPhone
                    className="form-control"
                    name="phone"
                    value={userInfo?.phone}
                    placeholder={t('Phone_Number')}
                    onChange={handlePhoneChange}
                />
                <Box sx={{ marginTop: '10px' }}>

                    {loadingUpload ? <CircularProgress /> :
                        <UploadFileIcon sx={{ cursor: 'pointer', fontSize: '38px' }} onClick={handleUploadClick} />}
                    <input
                        type="file"
                        data-testid="uploadFileIcon"
                        accept="application/pdf"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={event => handleUploadCv(userInfo.id, event.target.files)}
                    />
                    <Typography sx={{ color: 'grey' }}>{t('uploadYourCv')}</Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', gap: '10px', alignSelf: 'flex-end', marginTop: '10px' }}>
                <Button className='new-button' onClick={() => { setOpen(false); setMsg("") }} >
                    {t("cancel")}
                </Button>
                <Button className='new-button' onClick={editUser} disabled={Scouting?.amount?.length > 0 && Scouting?.currency === undefined}>
                    {t("save")}
                </Button>
            </DialogActions>

        </Dialog >
    )
}