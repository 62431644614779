import { Box, Table, TableHead, TableBody, TableCell, Typography, Radio } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useEffect, useCallback } from 'react';
import { ImeahTableRow } from '../../../utils/imeah_table_row';
import InfiniteScroll from 'react-infinite-scroll-component';
import useResponsive from '../../../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { act } from '@testing-library/react';
import { useSelector } from 'react-redux';
import Iconify from '../../Iconify';
import axios from 'axios';
import '../../../css/medicalFiles/select_speciality_or_expertise.css';

export const SelectTargetDoctor = ({ targetDoctorForShare, targetTemplateOwnerData, setTargetTemplateOwnerData,
    setChoosenTemplate, selectedSpeciality, selectedExpertise, targetDoctorInstitutionId, choosenTemplate, activeStep,
    setTargetDoctorInstitutionId, setTargetDoctorForShare, setActiveStep }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentInstitution = useSelector(state => state.currentInstitution);

    const isDesktop = useResponsive('up', 'md');

    const [TargetDoctor, setTargetDoctor] = useState([]);
    //pagination state
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const limit = 20; // Fetch 10 users at a time

    const fetchTargetDoctor = useCallback(async () => {

        const isNotValidSpeciality = (selectedSpeciality?.value === null || selectedSpeciality?.value === undefined || selectedSpeciality?.value === 'NoSpeciality');
        const isNotValidExpertise = (selectedExpertise?.value === null || selectedExpertise?.value === undefined || selectedExpertise?.value === 'NoExpertise');

        // Exit the function if I don't have specific Speciality or expertise
        if (isNotValidSpeciality && isNotValidExpertise) {
            setTargetDoctor([]);
            return;
        }

        const specialityId = isNotValidSpeciality ? 'NoSpeciality' : selectedSpeciality?.value;
        const expertiseId = isNotValidExpertise ? 'NoExpertise' : selectedExpertise?.value;

        axios.get('institutions-users/targetInstitutionsUsersForCreateMedicalFile', { params: { offset, limit, specialityId, expertiseId } })
            .then((response) => {
                act(() => {
                    setTargetDoctor(prev => (offset === 0 ? response.data : [...prev, ...response.data]));
                    // If we got less than the 'limit' number of users back, then we assume there's no more data to fetch
                    if (response.data.length < limit) {
                        setHasMore(false);
                    } else {
                        setOffset(offset + limit);
                    }
                });
            })
            .catch((err) => {
                CustomErrorLogger(err, 'Error fetching target doctors for create medical file');
                console.log(err)
            });
    }, [selectedSpeciality.value, selectedExpertise.value, offset]);


    useEffect(() => {
        if (currentInstitution) {
            fetchTargetDoctor()
        }
    }, [fetchTargetDoctor, currentInstitution]);

    const handleChangeDoctor = useCallback((choosenDoctor) => {

        if (choosenDoctor.id === targetDoctorForShare) return

        setTargetDoctorForShare(choosenDoctor.id)
        setTargetTemplateOwnerData(choosenDoctor.user.id)
        setTargetDoctorInstitutionId(choosenDoctor.institutionId)
        setActiveStep(1)
        setChoosenTemplate({
            id: '',
            name: '',
            description: '',
            additional_fields: [],
            sections: [],
        })
    }, [setActiveStep, setChoosenTemplate, setTargetDoctorForShare, setTargetDoctorInstitutionId, setTargetTemplateOwnerData, targetDoctorForShare]);

    return (
        <Box>
            {TargetDoctor.length > 0 &&
                <InfiniteScroll
                    dataLength={TargetDoctor.length}
                    next={fetchTargetDoctor}
                    hasMore={hasMore}>

                    {isDesktop ?
                        <Box
                            sx={{
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                '-ms-overflow-style': 'none', // IE and Edge
                                'scrollbar-width': 'none',   // Firefox
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <ImeahTableRow titlesRow={true}>
                                        <TableCell >{t('fullName')}</TableCell>
                                        <TableCell >{t('institution')}</TableCell>
                                        <TableCell >{t('viewProfile')}</TableCell>
                                    </ImeahTableRow>
                                </TableHead>

                                <TableBody>
                                    {TargetDoctor && TargetDoctor.map((doctor, index) =>
                                        <ImeahTableRow key={index} onClick={() => handleChangeDoctor(doctor)} >
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Radio
                                                        className='imeahRadio'
                                                        checked={targetDoctorForShare === doctor.id}
                                                        name={index.toString()}
                                                    />
                                                    <Typography className='imeah_propre_name' sx={{ fontSize: '14px' }}> {doctor.user?.firstname}  {doctor.user?.lastname}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell >{doctor.institution?.name}</TableCell>
                                            <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box
                                                    className='select_sp_exp_viewProfileBox'
                                                    data-testid="accountIcon"
                                                    onClick={() => navigate(`/dashboard/userprofile`, {
                                                        state: {
                                                            institutionUserId: doctor.id,
                                                            userId: doctor.user.id,
                                                            targetTemplateOwnerData,
                                                            targetDoctorInstitutionId,
                                                            targetDoctorForShare,
                                                            selectedSpeciality,
                                                            selectedExpertise,
                                                            choosenTemplate,
                                                            HistoryPath: "createMedicalFile"
                                                        }
                                                    })}
                                                >
                                                    <Iconify
                                                        className='select_sp_exp_accountIcon'
                                                        icon='ep:user'
                                                    />
                                                    <Typography className='select_sp_exp_viewProfile'>{t('viewProfileInCreateFile')}</Typography>
                                                </Box>
                                            </TableCell>
                                        </ImeahTableRow>
                                    )}
                                </TableBody>
                            </Table >
                        </Box> :

                        <Box className="select_sp_exp_responsive_cards_box" >
                            {TargetDoctor && TargetDoctor.map((doctor, index) => (
                                <Box className="select_sp_exp_responsive_card_details" key={doctor.id} onClick={() => handleChangeDoctor(doctor)}>
                                    <Radio
                                        className='imeahRadio'
                                        sx={{ position: 'absolute', top: '5px', right: '5px' }}
                                        checked={targetDoctorForShare === doctor.id}
                                        name={index.toString()}
                                    />
                                    <Box className="select_sp_exp_responsive_card_title_response_box">
                                        <Typography className='select_sp_exp_responsive_card_title'>{t('fullName')}</Typography>
                                        <Typography className='select_sp_exp_responsive_card_response imeah_propre_name'>{doctor.user?.firstname}  {doctor.user?.lastname}</Typography>
                                    </Box>
                                    <Box className="select_sp_exp_responsive_card_title_response_box">
                                        <Typography className='select_sp_exp_responsive_card_title'>{t('institution')}</Typography>
                                        <Typography className='select_sp_exp_responsive_card_response'>{doctor.institution?.name}</Typography>
                                    </Box>
                                    <Box className="select_sp_exp_responsive_card_title_response_box" onClick={(e) => e.stopPropagation()}>
                                        <Box
                                            className='select_sp_exp_viewProfileBox'
                                            data-testid="accountIcon"
                                            onClick={() => navigate(`/dashboard/userprofile`, {
                                                state: {
                                                    institutionUserId: doctor.id,
                                                    userId: doctor.user.id,
                                                    targetTemplateOwnerData,
                                                    targetDoctorInstitutionId,
                                                    targetDoctorForShare,
                                                    selectedSpeciality,
                                                    selectedExpertise,
                                                    choosenTemplate,
                                                    activeStep,
                                                    HistoryPath: "createMedicalFile"
                                                }
                                            })}
                                        >
                                            <Iconify
                                                className='select_sp_exp_accountIcon'
                                                icon='ep:user'
                                            />
                                            <Typography className='select_sp_exp_viewProfile'>{t('viewProfileInCreateFile')}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    }
                </InfiniteScroll>
            }
        </Box>
    )
}