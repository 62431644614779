import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import React from 'react';

const StyledActionIcon = styled('div')(({ theme }) => ({
  width: '40px',
  height: '40px',
  padding: '0px 8px',
  gap: theme.spacing(0.5),
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[400] || '#CED4DA'}`,
  background: theme.palette.background.paper || '#FFFFFF',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[100] || '#f4f6f9',
  },
  '&:focus': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
}));

const ImeahActionIcon = ({ icon, onClick, color = '#1E1E1E' }) => (
  <StyledActionIcon onClick={onClick} role="button">
    <Icon icon={icon} width='22px' height='22px' color={color} />
  </StyledActionIcon>
);

export default React.memo(ImeahActionIcon);