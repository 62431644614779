import React, { useMemo, useCallback } from 'react';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';

export const ReadOneTemplateTabs = ({ sections, setCurrentSection }) => {

    const handleChange = useCallback((event, newValue) => {
        setCurrentSection(newValue);
    }, [setCurrentSection]);

    const tabs = useMemo(() => sections?.map((section) =>
        <Tab
            key={section.id}
            label={section.value}
            value={section.id}
            sx={{ textTransform: 'none', paddingBottom: 0, paddingTop: 0, minHeight: 'fit-content' }}
        />
    ), [sections]);

    return (
        <TabList
            variant="scrollable"
            scrollButtons
            centered
            allowScrollButtonsMobile
            onChange={handleChange}
            sx={{
                margin: 0,
                padding: 0,
                minHeight: 'fit-content',
                '.MuiTabs-indicator': { backgroundColor: '#17536B' },
                '.MuiTab-root.Mui-selected': { color: '#17536B', backgroundColor: '#84E1E6', borderRadius: '5px', padding: '7px' },
            }}
        >
            {tabs}
        </TabList>
    );
};