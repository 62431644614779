import { Card, Table, Stack, LinearProgress, TextField, TableRow, Box, Button, Switch, TableHead, TableBody, TableCell, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { act } from '@testing-library/react';
import { useSelector } from 'react-redux';
import Scrollbar from '../Scrollbar';
import Page from '../Page';
import axios from 'axios';

export const EditReceiversList = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);

    const [users, setUsers] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const limit = 10;

    useEffect(() => {
        // Fetch existing receivers only once when the component mounts
        const fetchExistingReceivers = async () => {
            try {
                const response = await axios.get('users/findExistingContactUsReceiver');
                if (response.status === 200 || response.status === 201) {
                    setReceivers(response.data);
                }
            } catch (err) {
                console.log(err);
            }
        };

        if (userData) {
            fetchExistingReceivers();
        }
    }, [userData]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('users/findAllForEditContactUsReceiver', { params: { search, limit, offset } });
            if (response.status === 200 || response.status === 201) {
                act(() => {
                    setUsers((prevUsers) => (offset === 0 ? response.data : [...prevUsers, ...response.data]));
                    setHasMore(response.data.length >= limit);
                    setOffset((prevOffset) => prevOffset + limit);
                });
            }
        } catch (err) {
            console.error('Error fetching users:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userData) {
            fetchUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, search]);

    const handleChangeSearch = (searchWord) => {
        setSearch(searchWord)
        setOffset(0);
        setHasMore(true);
    };

    const handleAddUserAsReceiver = (chosenUser, isChecked) => {
        return setReceivers((prevReceivers) => {
            const userId = chosenUser.id; // Extract user ID for clarity

            // Update the receiver list based on isChecked:
            if (isChecked) {
                // Add the user if not already present
                return prevReceivers.includes(userId) ? prevReceivers : [...prevReceivers, userId];
            } else {
                // Remove the user if present
                return prevReceivers.filter((receiverId) => receiverId !== userId);
            }
        });
    };

    const handleEditReceivers = async () => {
        try {
            const response = await axios.put('users/editContactUsReceivers', { receivers });
            if (response.status === 200 || response.status === 201) {
                navigate(-1);
            }
        } catch (err) {
            console.error('Error updating receivers:', err);
        }
    }

    return (
        <Page title={t('editReceivers')}>
            <Stack className='pages-header'>
                <Box className="search-title-box">
                    <Typography className='page-title'>{t('editReceivers')}</Typography>
                    <TextField
                        className='search-input'
                        id="outlined-password-input"
                        label={t('search')}
                        type="text"
                        value={search || ""}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                    />
                </Box>
                <Box className="copy-actions">
                    <Button className="new-button" onClick={() => navigate(-1)}>{t('back')}</Button>
                    <Button className="new-button" onClick={handleEditReceivers}>{t('save')}</Button>
                </Box>
            </Stack>

            {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} /> :

                <Card>
                    <Scrollbar>
                        <InfiniteScroll
                            dataLength={users.length}
                            next={fetchUsers}
                            hasMore={hasMore}>
                            <Table>
                                <TableHead>
                                    <TableRow className='table-row'>
                                        <TableCell className='table-titles'>{t('firstname')}</TableCell>
                                        <TableCell className='table-titles'>{t('lastname')}</TableCell>
                                        <TableCell className='table-titles'>{t('email')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users && users.map((user) =>
                                        <TableRow key={user.id}>
                                            <TableCell >{user.firstname}</TableCell>
                                            <TableCell >{user.lastname}</TableCell>
                                            <TableCell >{user.email}</TableCell>
                                            <TableCell >
                                                <Switch
                                                    checked={receivers.includes(user.id)}
                                                    onChange={(e) => handleAddUserAsReceiver(user, e.target.checked)}
                                                    color="primary"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table >
                        </InfiniteScroll>
                    </Scrollbar>
                </Card>
            }
        </Page>
    );
};