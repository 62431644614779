import { Dialog, DialogActions, DialogContent, DialogTitle, Button, MenuItem, FormControl, InputLabel, Select, Box } from "@mui/material";
import { CustomErrorLogger } from "../../utils/CustomErrorLogger";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import axios from "axios";

export const LoginChooseInstitution = ({ authData, updateAuthData, storeAuthData }) => {

    const { t } = useTranslation();

    const { selectedInstitutionId, refreshToken, institutionUsers, requireSelectInstitution } = authData;

    const validationSchema = Yup.object().shape({
        selectedInstitutionId: Yup.string().trim().required(),
    });

    const handleChooseInstitution = async () => {

        const isValidSchema = validationSchema.isValidSync({ selectedInstitutionId });
        if (!isValidSchema) return;

        try {
            const { data, status } = await axios.post("/auth/refresh",
                { institutionId: selectedInstitutionId, fromChangeInstitution: true },
                { headers: { Authorization: `Bearer ${refreshToken}` } }
            );

            if (status === 200 || status === 201) {

                const { accessToken, refreshToken, requireLoginPolicies } = data;

                if (requireLoginPolicies) {
                    // Show login policies dialog
                    updateAuthData({
                        token: accessToken,
                        refreshToken: refreshToken,
                        requireLoginPolicies: true,
                        requireVerificationCode: false,
                        requireSelectInstitution: false,
                    });
                } else {
                    // All steps completed, store token in localStorage and navigate
                    storeAuthData(accessToken, refreshToken);
                }
            }
        } catch (error) {
            console.error("Error choosing institution:", error.message);
            CustomErrorLogger(error, "Error choosing institution in login select institution");
            localStorage.clear();
            window.location.href = "/login";
        }

    };

    return (
        <Box>
            <Dialog open={requireSelectInstitution}>
                <DialogTitle color={'#17536B'} variant="subtitle1">{t("chooseInstitution")}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <InputLabel id="selectInstitutionLabel">{t("institu")}</InputLabel>
                        <Select
                            labelId="selectInstitutionLabel"
                            id="selectInstitutionId"
                            name="selectInstitutionName"
                            label={t("institu")}
                            value={selectedInstitutionId}
                            onChange={(e) => updateAuthData({ selectedInstitutionId: e.target.value })}
                        >
                            {institutionUsers && institutionUsers.map((institutionUser) => (
                                <MenuItem key={institutionUser.institution?.id} value={institutionUser.institution?.id}>{institutionUser.institution?.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button className="buttonFilled" onClick={handleChooseInstitution}>{t("agree")}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
