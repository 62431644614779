import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const EditSelectListValue = ({ openEditValue, setOpenEditValue, valueToEdit, setValueToEdit, values, setValues }) => {

    const { t } = useTranslation();

    const [errMsg, setErrMsg] = useState(null);

    const handleEditValue = useCallback(() => {
        if (values.filter(value => value.id !== valueToEdit.id)
            .some(info => info.enValue === valueToEdit.enValue || (info.frValue && info.frValue === valueToEdit.frValue)) || valueToEdit.enValue === "") {
            setErrMsg("Attribut Not Accepted Or Already Exist !");
        } else {
            const index = values.findIndex(value => value.id === valueToEdit.id);
            if (index !== -1) {
                const updatedValues = [...values];
                updatedValues[index] = valueToEdit;
                setValues(updatedValues);
                setErrMsg("");
                setValueToEdit({});
                setOpenEditValue(false);
            }
        }
    }, [valueToEdit, values, setValueToEdit, setValues, setOpenEditValue]);

    const handleAddClose = () => {
        setErrMsg("");
        setValueToEdit({});
        setOpenEditValue(false);
    };

    return (
        <Dialog open={openEditValue} keepMounted aria-describedby="alert-dialog-slide-description">
            <DialogTitle className='page-title'>{t('editElement')}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    name="name"
                    label={t('Eng')}
                    type="text"
                    fullWidth
                    required
                    variant="outlined"
                    value={valueToEdit.enValue}
                    onChange={(e) => setValueToEdit({ ...valueToEdit, enValue: e.target.value })}
                    error={!valueToEdit.enValue}
                    helperText={!valueToEdit.enValue && t('isRequired')}
                />
                <TextField
                    margin="dense"
                    id="name"
                    name="name"
                    label={t('Fr')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={valueToEdit.frValue}
                    onChange={(e) => setValueToEdit({ ...valueToEdit, frValue: e.target.value })}
                />
            </DialogContent>
            <DialogActions>
                <Button className='new-button' onClick={handleAddClose}>{t('cancel')}</Button>
                <Button className='new-button' onClick={handleEditValue}>{t('save')}</Button>
            </DialogActions>
        </Dialog>
    )
}