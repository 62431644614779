import { Divider, Grid, Box, Card, Typography, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import { customInputStyles } from '../../../utils/CustomInputStyle'
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Iconify from '../../Iconify';
import '../../../css/templates/MedicalFileTemplatesFiltration.css';

export const MedicalFileTemplatesFiltration = (props) => {

    const { t } = useTranslation();
    const { emptyFiltrationObject, Specialities, selectedSpeciality, handleChangeSpeciality, handleChangeInstitution, Expertises, selectedExpretise, handleChangeExpertise, selectedStatus,
        handleChangeStatus, isAllowedReadMyOwnTemplate, isAllowedReadMyInstitutionTemplate, isAllowedReadPublicTemplate, isAllowedReadDefaultTemplate, selectedType,
        handleChangeType, setOpenFilter, selectedInstitution, currentInstitution
    } = props

    const ActionButton = ({ icon, title, onClick }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
            <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
        </Box>
    );

    return (
        <Box className='template-filtration-container'>
            <Card className='template-filtration-card' >
                <Box className='template-filtration-header-box'>
                    <Typography className='template-filtration-header-title'>{t("Filter")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={emptyFiltrationObject} />
                        <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                    </Box>                </Box>
                <Divider />
                <Grid container spacing={2} marginTop={'5px'}>

                    {(Specialities?.length > 0) &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                <InputLabel id="demo-simple-select-label-setSelectedSpeciality">{t('filterBySpeciality')} </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-setSelectedSpeciality"
                                    id="setSelectedSpeciality"
                                    label={t('filterBySpeciality')}
                                    name="setSelectedSpeciality"
                                    value={selectedSpeciality || ""}
                                    onChange={(e) => handleChangeSpeciality(e.target.value)}
                                    endAdornment={
                                        selectedSpeciality && (
                                            <InputAdornment position="start">
                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeSpeciality(null)} />
                                            </InputAdornment>
                                        )
                                    }
                                >
                                    {Specialities && Specialities.map((record) => (
                                        <MenuItem key={record.id} value={record}>{record.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    {(Expertises?.length > 0) &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                <InputLabel id="demo-simple-select-label-setSelectedExpretise">{t('filterByExpertise')} </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-setSelectedExpretise"
                                    id="setSelectedExpretise"
                                    label={t('filterByExpertise')}
                                    name="setSelectedExpretise"
                                    value={selectedExpretise || ""}
                                    onChange={(e) => handleChangeExpertise(e.target.value)}
                                    endAdornment={
                                        selectedExpretise && (
                                            <InputAdornment position="start">
                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeExpertise(null)} />
                                            </InputAdornment>
                                        )
                                    }
                                >
                                    {Expertises && Expertises.map((record) => (
                                        <MenuItem key={record.id} value={record}>{record.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth sx={{ ...customInputStyles }} >
                            <InputLabel id="demo-simple-select-label-setSelectedStatus">{t('Status')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-setSelectedStatus"
                                id="setSelectedStatus"
                                label={t('Status')}
                                name="setSelectedStatus"
                                value={selectedStatus || ""}
                                onChange={(e) => handleChangeStatus(e.target.value)}
                                endAdornment={
                                    selectedStatus && (
                                        <InputAdornment position="start">
                                            <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeStatus(null)} />
                                        </InputAdornment>
                                    )
                                }
                            >
                                <MenuItem value="true">{t('ActiveStatus')}</MenuItem>
                                <MenuItem value="false">{t('InActiveStatus')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {(isAllowedReadMyOwnTemplate || isAllowedReadMyInstitutionTemplate || isAllowedReadPublicTemplate || isAllowedReadDefaultTemplate) &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                <InputLabel id="demo-simple-select-label-type">{t('type')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-type"
                                    id="type"
                                    label={t('type')}
                                    name="type"
                                    value={selectedType || ""}
                                    onChange={(e) => handleChangeType(e.target.value)}
                                    endAdornment={
                                        selectedType && (
                                            <InputAdornment position="start">
                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeType(null)} />
                                            </InputAdornment>
                                        )
                                    }
                                >
                                    {(isAllowedReadMyOwnTemplate || isAllowedReadMyInstitutionTemplate) && <MenuItem value={'private'}> {t("private")}</MenuItem>}
                                    {isAllowedReadPublicTemplate && <MenuItem value={'public'}> {t("public")}</MenuItem>}
                                    {isAllowedReadDefaultTemplate && <MenuItem value={'default'}> {t("default")}</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth sx={{ ...customInputStyles }} >
                            <InputLabel id="demo-simple-select-label-institution">{t('institution')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-institution"
                                id="institution"
                                label={t('institution')}
                                name="institution"
                                value={selectedInstitution || ""}
                                onChange={(e) => handleChangeInstitution(e.target.value)}
                                endAdornment={
                                    selectedInstitution && (
                                        <InputAdornment position="start">
                                            <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeInstitution(null)} />
                                        </InputAdornment>
                                    )
                                }
                            >
                                {isAllowedReadMyOwnTemplate && <MenuItem value={currentInstitution.institution.id}> {currentInstitution.institution.name}</MenuItem>}
                                {(isAllowedReadMyInstitutionTemplate && currentInstitution.institution.id !== 'imeah') && <MenuItem value={'imeah'}>ehealth pro</MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Card>
        </Box>
    );
};