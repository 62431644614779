import { Button, Radio, RadioGroup, FormControlLabel, TextField, Box, LinearProgress, Stack, Typography, Alert, Grid } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { StyledFieldset } from "../../utils/styledFieldSet.js";
import { MuiMultiSelect } from '../../utils/muiMultiSelect.js';
import { EditSelectListValue } from "./editSelectListValue.js";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import Page from "../Page";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Iconify from "../Iconify.js";

export const EditList = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const currentInstitution = useSelector(state => state.currentInstitution);

  const [englishName, setEnglishName] = useState('');
  const [frenchName, setFrenchName] = useState('');
  const [valueToEdit, setValueToEdit] = useState({});
  const [englishValue, setEnglishValue] = useState('');
  const [frenchValue, setFrenchValue] = useState('');
  const [openEditValue, setOpenEditValue] = useState(false);
  const [type, setType] = useState("Select");
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const [values, setValues] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    enName: Yup.string().trim().required(t("selectListNameIsRequired")),
    frName: Yup.string().trim().optional(),
    type: Yup.string().required(),
    values: Yup.array().min(1, t("selectListValuesIsRequired")).required(),
    specialities: Yup.array().min(0).optional(),
    expertises: Yup.array().min(0).optional(),
  });

  const fetchSpecialities = async (page, search) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('speciality', { params: { offset, limit, search: search } });

      return response.data?.map(record => ({ value: record.specialityId, label: record.label, }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const fetchExpertises = async (page, search) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('expertise', { params: { offset, limit, search: search } });

      return response.data?.map(record => ({ value: record.expertiseId, label: record.label, }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const fetchOneSelectList = useCallback(async () => {
    try {
      const response = await axios.get(`select-list/findOneForEditSelectList/${id}`);
      if (response.status === 200 || response.status === 201) {

        const convertedSpeciality = response.data?.selectListSpeciality?.map((speciality) => ({
          value: speciality.speciality?.id,
          label: speciality.speciality?.specialityLabel[0]?.label
        }));
        const convertedExpertises = response.data?.selectListExpertise?.map((expertise) => ({
          value: expertise.expertise?.id,
          label: expertise.expertise?.expertiseLabel[0]?.label
        }));

        const englishLabel = response.data?.select_list_label?.find(label => label.language === 'en');
        const frenchLabel = response.data?.select_list_label?.find(label => label.language === 'fr');
        setEnglishName(englishLabel ? englishLabel.label : '');
        setFrenchName(frenchLabel ? frenchLabel.label : '');

        setType(response.data.select_type);
        setValues(response.data.select_list_values.map(value => {
          const enLabel = value?.select_list_values_label?.find(val => val.language === 'en');
          const frLabel = value?.select_list_values_label?.find(val => val.language === 'fr');
          return {
            id: value.id,
            enValue: enLabel ? enLabel.label : '', // Provide a default value if enLabel does not exist
            frValue: frLabel ? frLabel.label : ''  // Provide a default value if frLabel does not exist
          };
        }));
        setSelectedSpecialities(convertedSpeciality);
        setSelectedExpertises(convertedExpertises);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [id, setEnglishName, setFrenchName, setType, setValues, setSelectedSpecialities, setSelectedExpertises, setLoading]);

  useEffect(() => {
    if (!currentInstitution) return;
    fetchOneSelectList();
  }, [currentInstitution, fetchOneSelectList]);


  const handleEditData = useCallback(async () => {

    const bodyData = {
      type,
      enName: englishName,
      frName: frenchName,
      values,
      specialities: selectedSpecialities?.map((speciality) => speciality.value),
      expertises: selectedExpertises?.map((expertise) => expertise.value),
    };

    try {
      await validationSchema.validate(bodyData, { abortEarly: false });

      const response = await axios.patch(`select-list/${id}`, bodyData);
      if (response.status === 200 || response.status === 201) {
        navigate(-1);
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrMsg(error?.errors?.join(` ${t('and')} `));
      } else {
        setErrMsg(error.message);
      }
    }

  }, [type, englishName, frenchName, values, selectedSpecialities, selectedExpertises, validationSchema, id, navigate, t]);

  const handlePushValue = useCallback(() => {
    if (values.some(info => info.enValue === englishValue || (info.frValue && info.value === frenchValue)) || englishValue === "") {
      setErrMsg("Attribut Not Accepted Or Already Exist !");
    } else {
      setValues([...values, { id: uuidv4(), enValue: englishValue, frValue: frenchValue }]);
      setEnglishValue("");
      setFrenchValue("");
      setErrMsg("");
    }
  }, [englishValue, frenchValue, values]);

  const handleRemoveValue = useCallback((id) => {
    const AllValues = [...values];
    const index = AllValues.findIndex(value => value.id === id);
    if (index !== -1) {
      AllValues.splice(index, 1);
      setValues(AllValues);
    }
  }, [values]);

  const openEditDialog = (value) => {
    setValueToEdit({ id: value.id, enValue: value.enValue, frValue: value.frValue });
    setOpenEditValue(true);
  }

  return (
    <Page title={t("Edit_Select_list_Page_Title")}>

      <Box sx={{ display: 'flex', gap: '10px' }}>
        <ArrowBackIosIcon className='create-list-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
        <Stack className="create-list-main-page-header">
          <Box className="create-list-page-title-box">
            <Typography className='create-list-page-title'>{t("editSelectList")}</Typography>
          </Box>
        </Stack>
      </Box>

      {errMsg && <Alert className="login-errMsg" severity="error">{errMsg}</Alert>}

      {loading ?
        <LinearProgress />
        :
        <Box className="create-list-content">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="name"
                name="name"
                margin="dense"
                label={t('Eng')}
                type="text"
                variant="outlined"
                value={englishName || ""}
                onChange={(e) => setEnglishName(e.target.value)}
                error={!englishName}
                helperText={!englishName && (t('isRequired'))}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="name"
                name="name"
                margin="dense"
                label={t('Fr')}
                type="text"
                variant="outlined"
                value={frenchName || ""}
                onChange={(e) => setFrenchName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <StyledFieldset component="fieldset">
                <legend>{t('multiSelect')}</legend>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={type}
                  name="radio-buttons-group"
                  row
                  onChange={(e) => setType(e.target.value)}
                >
                  <FormControlLabel value="Select" control={<Radio />} label={t('no')} />
                  <FormControlLabel value="Multi_select" control={<Radio />} label={t('yes')} />
                </RadioGroup>
              </StyledFieldset>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MuiMultiSelect
                label={t('specialities')}
                required={false}
                searchable={true}
                value={selectedSpecialities}
                fetchFunction={fetchSpecialities}
                onChange={(selectedOptions) => setSelectedSpecialities(selectedOptions)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <MuiMultiSelect
                label={t('expertises')}
                required={false}
                searchable={true}
                value={selectedExpertises}
                fetchFunction={fetchExpertises}
                onChange={(selectedOptions) => setSelectedExpertises(selectedOptions)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="value"
                name="englishValue"
                margin="dense"
                label={t("englishElement")}
                type="text"
                value={englishValue}
                variant="outlined"
                onChange={(e) => setEnglishValue(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="value"
                name="frenchValue"
                margin="dense"
                label={t("frenchElement")}
                type="text"
                value={frenchValue}
                variant="outlined"
                onChange={(e) => setFrenchValue(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                data-testid="add-value"
                className="addValue_button"
                onClick={handlePushValue}
              >
                {t("add")}
              </Button>
            </Grid>

            {values.length > 0 &&
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                  <Grid item xs={5.5}>
                    <Typography className="create-list-values-title" variant="h6">{t('englishList')}</Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography className="create-list-values-title" variant="h6">{t('frenchList')}</Typography>
                  </Grid>
                </Grid>
                {values.map((value, index) => (
                  <Grid container key={index} spacing={2} sx={{ marginBottom: '30px' }}>
                    <Grid item xs={5.5}>
                      <Typography className="create-list-values-response">{value.enValue}</Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography className="create-list-values-response">{value.frValue ? value.frValue : 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={1} className="select_list_value_actions_box">
                      <Iconify className="select_list_value_action_edit" icon="ic:outline-edit" onClick={() => openEditDialog(value)} />
                      <Iconify className="select_list_value_action_delete" icon="ic:outline-delete" onClick={() => handleRemoveValue(value.id)} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            }
          </Grid>

          <Box sx={{ display: 'flex', gap: '10px', alignSelf: 'flex-end', marginTop: '20px' }}>
            <Button className="new-button" onClick={() => navigate(-1)}>{t("cancel")}</Button>
            <Button className="new-button" onClick={handleEditData}>{t("save")}</Button>
          </Box>

          {openEditValue &&
            <EditSelectListValue
              openEditValue={openEditValue}
              setOpenEditValue={setOpenEditValue}
              valueToEdit={valueToEdit}
              setValueToEdit={setValueToEdit}
              values={values}
              setValues={setValues}
            />
          }

        </Box>
      }

    </Page>
  );
};