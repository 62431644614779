import { createSlice } from '@reduxjs/toolkit';

const onlineStatusSlice = createSlice({
    name: 'onlineStatus',
    initialState: navigator.onLine,
    reducers: {
        setOnline: (state, action) => action.payload
    }
});

export const { setOnline } = onlineStatusSlice.actions;
export default onlineStatusSlice.reducer;