import React from 'react'
import { Box, Typography, CardMedia, List, ListItem } from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material';
import '../../css/homePage/homeEightPart.css'
import JohnMask from '../../images/Mask group john.svg'
import JaneMask from '../../images/Mask group jane.svg'
import Clinic from '../../images/medicalClinic.jpg'
import Hopital from '../../images/hopital.png'
import { useTranslation } from "react-i18next";

export const HomeEightPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeEightPart-main-box'>
            <Typography className='homeEightPart-main-typo'>ehealth pro, {t('forWho')} ?</Typography>
            <Box className='homeEightPart-cards-box'>
                <Box className='homeEightPart-card-style'>
                    <Typography className='homeEightPart-card-title'>
                        {t('etablissementSante')}
                    </Typography>
                    <CardMedia
                        className='homeEightPart-card-Mask-clinic'
                        component="img"
                        image={Clinic}
                        alt="My Image"
                    />
                    <List className='homeEightPart-card-list-box'>
                        <ListItem className='homeEightPart-card-list-item'>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('organiseMonReseau')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('acceReseauVast')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('monActiviteMedico')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('renforcerEquipeMedical')}</Typography>
                            </Box>
                        </ListItem>
                    </List>
                </Box>
                <Box className='homeEightPart-card-style'>
                    <Typography className='homeEightPart-card-title-jane'>
                        {t('CHU')}
                    </Typography>
                    <CardMedia
                        className='homeEightPart-card-Mask'
                        component="img"
                        image={Hopital}
                        alt="My Image"
                    />
                    <List className='homeEightPart-card-list-box'>
                        <ListItem className='homeEightPart-card-list-item'>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('structurerLeReseau')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('faciliterLaCoordination')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('IntégrerTéléexpertise')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('promouvoirRecherche')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('reconnaitreExpertiseDispense')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('integrerDoctorJunior')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('InternationaliserExceptionnelles')}</Typography>
                            </Box>
                        </ListItem>
                    </List>
                </Box>
                <Box className='homeEightPart-card-style'>
                    <Typography className='homeEightPart-card-title'>
                        {t('generalist')}
                    </Typography>
                    <CardMedia
                        className='homeEightPart-card-Mask'
                        component="img"
                        image={JohnMask}
                        alt="My Image"
                    />
                    <Typography className='homeEightPart-card-desc-typo'>
                        {t('permetJoindreExpert')}
                    </Typography>
                    <List className='homeEightPart-card-list-box'>
                        <ListItem className='homeEightPart-card-list-item'>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('patientsConfiance')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('sensPlusIsole')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('easilyShareMedicalRecords')}</Typography>
                            </Box>
                        </ListItem>
                    </List>
                </Box>
                <Box className='homeEightPart-card-style'>
                    <Typography className='homeEightPart-card-title-jane'>
                        {t('specialist')}
                    </Typography>
                    <CardMedia
                        className='homeEightPart-card-Mask'
                        component="img"
                        image={JaneMask}
                        alt="My Image"
                    />
                    <Typography className='homeEightPart-card-desc-typo'>
                        {t('integrerReaseauExpert')}
                    </Typography>
                    <List className='homeEightPart-card-list-box'>
                        <ListItem className='homeEightPart-card-list-item'>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('inviteRequerant')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('choisiLibererGrace')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('peuxRejoindreDistance')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('gereDisponibilite')}</Typography>
                            </Box>
                            <Box className='homeEightPart-card-list'>
                                <FiberManualRecord className='homeEightPart-card-icon-record' />
                                <Typography className='homeEightPart-card-hidden-typo'>{t('accesALexpertise')}</Typography>
                            </Box>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Box>
    )
}
