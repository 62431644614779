import { Box, Button, TextField, Alert, Typography, Stack, Grid, Autocomplete, FormControl } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setInstitutions } from "../../redux/institutionsSlice";
import { ImeahPhoneInput } from '../../utils/imeah_phone_input';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useCallback } from 'react';
import { MuiSelect } from '../../utils/muiSelect';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import Page from "../Page";
import axios from "axios";
import 'react-international-phone/style.css';
import '../../css/institutions/addInstitution.css';

export const AddInstitution = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const institutions = useSelector(state => state.institutions);

    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [type, setType] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required(t("institutionNameRequired")),
        type: Yup.string().required(t("institutionTypeRequired")),
        phone: Yup.string().trim().matches(/^\+?[0-9]+$/, t('PhoneNumberOnlyDigits')).required('PhoneNumberRequired').min(8, t('PhoneNumberMinLength')),
        address: Yup.string().trim().required(t("institutionAddressRequired")),
        city: Yup.string().trim().required(t("institutionCityRequired")),
        countryName: Yup.string().required(t("countryRequired")),
    });

    const fetchInstitutionsTypes = useCallback(async (page) => {
        try {
            const limit = 10;
            const offset = page * limit;
            const response = await axios.get('institutions-types', { params: { offset, limit } });
            return response.data?.map(record => ({ value: record?.institutionTypeId, label: record?.label }));
        } catch (error) {
            console.error('Error fetching Institutions Types:', error);
            return [];
        }
    }, []);

    const handleAdd = useCallback(async (event) => {
        event.preventDefault();

        const bodyData = {
            name: name.trim(),
            address,
            city,
            type: type?.value,
            phone,
            countryName: selectedCountry
        };

        try {
            await validationSchema.validate(bodyData, { abortEarly: false });

            setIsLoading(true);

            const response = await axios.post('institutions', bodyData);
            if (response.status === 200 || response.status === 201) {
                const newInstitution = {
                    institutionId: response.data.institution.id,
                    institutionName: response.data.institution.name,
                    roleName: response.data.role.roleLabel[0].label,
                };
                dispatch(setInstitutions([...institutions, newInstitution]));
                navigate(-1);
            }
        } catch (error) {
            if (error.name === 'ValidationError') {
                setErrorMsg(error?.errors?.join(' , '));
            } else {
                setErrorMsg(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    }, [name, address, city, type.value, phone, selectedCountry, validationSchema, dispatch, institutions, navigate]);

    const fetchCountries = async () => {
        try {
            const { status, data } = await axios.get("institutions/getCountries");
            if ((status === 200 || status === 201) && Array.isArray(data)) {
                setCountries(data.map((country) => country?.name));
            }
        } catch (error) {
            console.log("Error fetching countries:", error);
        }
    };

    return (
        <Page title={t("Create_Institution_Page_Title")} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }} >

            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                <ArrowBackIosIcon className='addInstitution-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                <Stack className="addInstitution-main-page-header">
                    <Box className="addInstitution-page-title-box">
                        <Typography className='addInstitution-page-title'>{t("add-institution")}</Typography>
                    </Box>
                </Stack>
            </Box>

            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

            <Grid container spacing={2} mt={1}>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="name"
                        name="name"
                        className='customTextField'
                        label={t("name")}
                        required
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(event) => setName(event.target.value)}
                        value={name}
                        error={!name}
                        helperText={!name && t('isRequired')}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <MuiSelect
                        required={true}
                        label={t('type')}
                        value={type}
                        onChange={(event) => setType(event.target)}
                        fetchFunction={fetchInstitutionsTypes}
                        searchable={false}
                        className='customTextField'
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <ImeahPhoneInput
                        name="phone"
                        required={true}
                        value={phone}
                        label={t('Enter_phone_number')}
                        onChange={(event) => setPhone(event)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="address"
                        name="address"
                        className='customTextField'
                        label={t("institutionAddress")}
                        required
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(event) => setAddress(event.target.value)}
                        value={address}
                        error={!address}
                        helperText={!address && t('isRequired')}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="city"
                        name="city"
                        className='customTextField'
                        label={t("institutionCity")}
                        required
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={(event) => setCity(event.target.value)}
                        value={city}
                        error={!city}
                        helperText={!city && t('isRequired')}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            required
                            id="countries"
                            name="countries"
                            options={countries}
                            onFocus={fetchCountries}
                            getOptionLabel={(option) => t(option)}
                            onChange={(event, newValue) => setSelectedCountry(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    label={t('country')}
                                    className='customTextField'
                                    variant="outlined"
                                    error={!selectedCountry}
                                    helperText={!selectedCountry && t('isRequired')}
                                />
                            )}

                        />
                    </FormControl>
                </Grid>

            </Grid>

            <Box className="addInstitution-actions-box">
                <Button className='buttonOutlined' onClick={() => navigate(-1)}>{t('cancel')}</Button>
                <LoadingButton loading={isLoading} className='buttonFilled' onClick={handleAdd}>{t('Create')}</LoadingButton>
            </Box>
        </Page>
    );
}