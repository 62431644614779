import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Select, MenuItem, TextField, FormControl, InputLabel, Alert } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { StyledFieldset } from '../../../utils/styledFieldSet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import Label from "../../Label";
import * as Yup from 'yup';
import axios from 'axios';
import React from 'react';
import '../../../css/templates/addTemplateField.css';

const useStyles = makeStyles(() => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#09C3CC !important',
    },
}));

export const AddTemplateField = ({ currentSection, template, setTemplate, openCustomTabs, setOpenCustomTabs }) => {

    const { t } = useTranslation();
    const classes = useStyles();

    const [selectlist, setSelectlist] = useState([]);

    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldType] = useState('');
    const [fieldListId, setFieldListId] = useState('');
    const [allMultiValues, setAllMultiValues] = useState([]);
    const [newValue, setNewValue] = useState('');
    const [errorDuplicationMessage, setErrorDuplicationMessage] = useState(null);

    const validationNormaleField = Yup.object().shape({
        fieldName: Yup.string().trim().required(),
        fieldType: Yup.string().trim().required(),
    });

    const validationSelectField = Yup.object().shape({
        fieldName: Yup.string().trim().required(),
        fieldType: Yup.string().trim().required(),
        fieldListId: Yup.string().trim().required(),
    });

    const validationMultiValueField = Yup.object().shape({
        fieldName: Yup.string().trim().required(),
        fieldType: Yup.string().trim().required(),
        multiValues: Yup.array().min(1),
    });

    const fetchSelectList = async () => {
        if (selectlist.length > 0 || (fieldType !== 'select' && fieldType !== 'Multi_select')) return; // Avoid duplicate fetches
        try {
            const response = await axios.get(`select-list/getSpecificSelectEdit`, {
                params: {
                    specialityId: template.speciality.id,
                    expertiseId: template.expertise.id,
                    fieldType: fieldType === 'select' ? 'Select' : 'Multi_select'
                },
            });
            if (response.status === 200 || response.status === 201) {
                setSelectlist(response.data);
            }
        } catch (error) {
            console.error("Error fetching select list:", error);
            CustomErrorLogger(error, 'Error fetching select list for add template field');
        }
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const item = Array.from(allMultiValues);
        const [reorderedItem] = item.splice(result.source.index, 1);
        item.splice(result.destination.index, 0, reorderedItem);
        setAllMultiValues(item);
    };

    const handlePushValue = () => {
        if (newValue === "" || allMultiValues.includes(newValue)) {
            setErrorDuplicationMessage(t('attributeNotAccepted'));
        } else {
            setAllMultiValues((prevValues) => [...prevValues, newValue]);
            setNewValue('');
            setErrorDuplicationMessage(null);
        }
    };

    const handleRemoveValue = (item) => {
        setAllMultiValues((prevValues) => prevValues.filter((value) => value !== item));
    };

    const handleAddField = () => {
        let validationSchema;

        if (fieldType === 'select' || fieldType === 'Multi_select') {
            validationSchema = validationSelectField;
        } else if (fieldType === 'Multi_value') {
            validationSchema = validationMultiValueField;
        } else {
            validationSchema = validationNormaleField;
        }

        if (validationSchema.isValidSync({ fieldName, fieldType, fieldListId, allMultiValues })) {
            const newField = {
                id: uuidv4(),
                label: fieldName,
                type: fieldType,
                section: currentSection,
                multiValues: (fieldType === "Multi_value") ? allMultiValues : [],
                value: (fieldType === 'list' || fieldType === 'Multi_select' || fieldType === 'Multi_value') ? [] : '',
                listId: fieldListId,
            };

            axios.patch(`medical-file-templates/addField/${template.id}`, newField)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setTemplate((prevData) => ({ ...prevData, additional_fields: response.data }));
                        setAllMultiValues([]);
                    }
                })
                .then(() => setOpenCustomTabs(false))
                .catch((err) => {
                    console.log(err);
                    CustomErrorLogger(err, 'Error adding new field to template');
                    setErrorDuplicationMessage(err.message);
                });
        }
    };

    const handleChangeFieldType = (newType) => {
        setFieldType(newType);
        setFieldListId('');
        setAllMultiValues([]);
        setSelectlist([]);
    }

    return (
        <Dialog open={openCustomTabs} keepMounted onClose={() => setOpenCustomTabs(false)}>
            <DialogTitle>{t('fillTheFormToAddYourCustomTab')}</DialogTitle>

            {errorDuplicationMessage && <Alert severity="error">{errorDuplicationMessage}</Alert>}

            <DialogContent>
                <TextField
                    required
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="label"
                    name='label'
                    value={fieldName}
                    label={t('label')}
                    type="text"
                    variant="outlined"
                    onChange={e => setFieldName(e.target.value)}
                    error={!fieldName}
                    helperText={!fieldName && t('isRequired')}
                />

                <FormControl required fullWidth className='form-control'
                    error={!fieldType}
                    helperText={!fieldType && t('isRequired')} >
                    <InputLabel id="demo-simple-type-label">{t('type')}</InputLabel>
                    <Select
                        labelId="demo-simple-type-label"
                        id="Type"
                        label={t('type')}
                        value={fieldType}
                        name='Type'
                        onChange={e => handleChangeFieldType(e.target.value)}
                    >
                        <MenuItem value={'text'}>{t('text')}</MenuItem>
                        <MenuItem value={'number'}>{t('number')}</MenuItem>
                        <MenuItem value={'date'}>{t('date')}</MenuItem>
                        <MenuItem value={'long Text'}>{t('long Text')}</MenuItem>
                        <MenuItem value={'password'}>{t('password')}</MenuItem>
                        <MenuItem value={'select'}>{t('select')}</MenuItem>
                        <MenuItem value={'Multi_select'}>{t('Multi_select')}</MenuItem>
                        <MenuItem value={'Multi_value'}>{t('Multi_value')}</MenuItem>
                        <MenuItem value={'file'}>{t('file')}</MenuItem>
                        <MenuItem value={'list'}>{t('list')}</MenuItem>
                    </Select>
                </FormControl>
                {(fieldType === 'select' || fieldType === 'Multi_select') &&
                    <FormControl required fullWidth className='form-control'
                        error={!fieldListId}
                        helperText={!fieldListId && t('isRequired')}>
                        <InputLabel id="demo-simple-select-label">{t('selectList')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="selectlist"
                            label={t('selectList')}
                            name='selectlist'
                            value={fieldListId}
                            onOpen={fetchSelectList}
                            onChange={(e) => setFieldListId(e.target.value)}
                        >
                            {selectlist && selectlist.map((list) =>
                                <MenuItem key={list.id} value={list.selectListId}>{list.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }

                {(fieldType === 'Multi_value') &&
                    <Box>
                        <Box sx={{ display: "flex", alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                            <TextField
                                id="value"
                                name="value"
                                fullWidth
                                label={t('inputNewValue')}
                                type="text"
                                value={newValue}
                                variant="outlined"
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                onChange={(e) => setNewValue(e.target.value)}
                            />
                            <AddCircleIcon
                                data-testid="add-value"
                                className="circle-add-icon"
                                onClick={handlePushValue}
                            />
                        </Box>

                        {allMultiValues.length > 0 &&
                            <StyledFieldset component="fieldset" sx={{ marginTop: '10px' }}>
                                <legend>{t('values')}</legend>
                                <Box className='addTempField-servise-legend'>
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="values" direction="horizontal">
                                            {(provided) => (
                                                <Box {...provided.droppableProps} ref={provided.innerRef} className='addTempField-servise-box'>
                                                    {allMultiValues && allMultiValues.map((item, index) => (
                                                        <Draggable key={item} draggableId={item} index={index}>
                                                            {(provided) => (
                                                                <Typography {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                                    <Label>{item}</Label>
                                                                    <CloseRoundedIcon
                                                                        onClick={() => handleRemoveValue(item)}
                                                                        className="close-icon"
                                                                    />
                                                                </Typography>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </Box>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Box>
                            </StyledFieldset>
                        }
                    </Box>
                }


            </DialogContent>
            <DialogActions>
                <Button className='new-button' fullWidth onClick={() => setOpenCustomTabs(false)}>{t('cancel')}</Button>
                <Button className='new-button' fullWidth onClick={handleAddField} disabled={fieldType === "Multi_value" && allMultiValues.length === 0}>{t('add')}</Button>
            </DialogActions>
        </Dialog >
    );
};
