/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Iconify from "../Iconify";
import Scrollbar from '../Scrollbar';
import { useSelector } from 'react-redux';
import { DeleteUser } from './deleteUser';
import { act } from '@testing-library/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HasPermission } from '../../utils/checkUserPermission';
import { customInputStyles } from "../../utils/CustomInputStyle";
// import UsersListResponsiveFiltration from './responsive-filtration/usersListResponsiveFiltration';
import { Table, TableHead, TableRow, TableBody, TableCell, Chip, Box, MenuItem, useMediaQuery, InputAdornment, FormControl, InputLabel, Select, Card, LinearProgress, Typography, Divider, Grid } from '@mui/material';

export const UsersList = ({ openFilter, setOpenFilter, search, setSearch }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userData = useSelector(state => state.userData);

    const isAllowedDeleteAllUsers = HasPermission('Delete_All_Users');

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expertises, setExpertises] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [IdUserForDelete, setIdUserForDelete] = useState("");
    const [selectedExpretise, setSelectedExpretise] = useState("");
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const [selectedInstitution, setSelectedInstitution] = useState("");

    const hasMore = useRef(true);
    const offset = useRef(0);
    const limit = 20;
    const [filter, setFilter] = useState({
        distance: '',
        institutionId: "",
        specialityId: "",
        expertiseId: "",
        search: ""
    });

    const distances = [{ value: 10, label: '10 km' }, { value: 50, label: '50 km' }, { value: 100, label: '100 km' }]

    const hasFilters = selectedInstitution || selectedSpeciality || selectedExpretise || filter.distance

    const useStyles = makeStyles({
        chip: {
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            '&:hover': {
                backgroundColor: 'lightblue',
            },
        },
    });

    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const fetchAllUsers = () => {
        const currentOffset = offset.current;
        axios.get("users/all", { params: { filter, limit, offset: currentOffset } })
            .then((response) => {
                act(() => {
                    setUsers(currentOffset === 0 ? response.data : [...users, ...response.data]);
                    setLoading(false);
                    offset.current = currentOffset + limit;
                    hasMore.current = response.data?.length === limit;
                })
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (userData.local_code) {
            setUsers([]);
            offset.current = 0;
            hasMore.current = true;
        }
    }, [userData.local_code]);

    useEffect(() => {
        if (userData) {
            fetchAllUsers()
        }
    }, [userData, filter, userData.local_code]);

    useEffect(() => {
        setFilter({ ...filter, search });
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, [search]);

    useEffect(() => {
        if (userData) {

            axios.get("users/getFiltrationArrays")
                .then((response) => {
                    act(() => {
                        setInstitutions(response.data.institutionArray)
                        setSpecialities(response.data.specialityArray)
                        setExpertises(response.data.expertiseArray)
                    })
                })
                .catch((err) => console.log(err));
        }
    }, [userData]);


    const handleChangeExpertise = (expertise) => {
        setFilter({ ...filter, expertiseId: expertise === "" ? "" : expertise.id, specialityId: "" });
        setSelectedSpeciality("");
        setSelectedExpretise(expertise);
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    };

    const handleChangeSpeciality = (speciality) => {
        setFilter({ ...filter, specialityId: speciality === "" ? "" : speciality.id, expertiseId: "" });
        setSelectedExpretise("");
        setSelectedSpeciality(speciality);
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    };

    const handleChangeInstitution = (institution) => {
        setFilter({ ...filter, institutionId: institution.id });
        setSelectedInstitution(institution);
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    };

    const handleChangeDistance = (distance) => {
        setFilter({ ...filter, distance: distance });
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    };

    const emptyFiltrationObject = () => {
        setFilter({
            institutionId: "",
            specialityId: "",
            expertiseId: "",
            search: '',
        });
        setSearch("");
        setSelectedExpretise("");
        setSelectedInstitution("");
        setSelectedSpeciality("");
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }

    const handleDeleteOpen = (UserId) => {
        setIdUserForDelete(UserId);
        setOpenDelete(true);
    };

    const FilterTag = ({ label, onDelete }) => (
        <Box className='main-page-filter-flag-item'>
            <Typography>{label}</Typography>
            <Iconify icon="mdi:close" onClick={onDelete} />
        </Box>
    );

    const ActionButton = ({ icon, title, onClick }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
            <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
        </Box>
    );

    return (
        <Box>
            {loading ?
                <LinearProgress /> :
                <Box>
                    {openFilter &&
                        <Box sx={{ marginTop: '5px', marginBottom: '5px' }}>
                            <Card sx={{ padding: '7px' }} >
                                <Box display={'flex'} justifyContent={'space-between'} color={'#17536B'} >
                                    <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>{t("Filter")}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={emptyFiltrationObject} />
                                        <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                                    </Box>
                                </Box>
                                <Divider />
                                <Grid container spacing={1} marginTop={'5px'}>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth sx={{ ...customInputStyles }} >
                                            <InputLabel id="demo-simple-select-label-institution">{t("institution")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label-institution"
                                                id="selectedExpretise"
                                                label={t("institution")}
                                                name="selectedInstitution"
                                                value={selectedInstitution || ""}
                                                onChange={(e) => handleChangeInstitution(e.target.value)}
                                                endAdornment={
                                                    selectedInstitution && (
                                                        <InputAdornment position="start">
                                                            <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeInstitution("")} />
                                                        </InputAdornment>
                                                    )
                                                }
                                            >
                                                {institutions && institutions.map((institution) => (
                                                    <MenuItem key={institution.id} value={institution}>
                                                        {institution.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {(specialities && specialities?.length > 1) &&
                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                                <InputLabel id="demo-simple-select-label-speciality">{t("speciality")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label-speciality"
                                                    id="selectedSpeciality"
                                                    label={t("speciality")}
                                                    name="selectedSpeciality"
                                                    value={selectedSpeciality || ""}
                                                    onChange={(e) => handleChangeSpeciality(e.target.value)}
                                                    endAdornment={
                                                        selectedSpeciality && (
                                                            <InputAdornment position="start">
                                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeSpeciality("")} />
                                                            </InputAdornment>
                                                        )
                                                    }
                                                >
                                                    {specialities && specialities.map((Speciality) => (
                                                        <MenuItem key={Speciality.id} value={Speciality}>{Speciality.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }

                                    {(expertises && expertises?.length > 1) &&
                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                                <InputLabel id="demo-simple-select-label-expertise">{t("expertise")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label-expertise"
                                                    id="selectedExpretise"
                                                    label={t("expertise")}
                                                    name="selectedExpretise"
                                                    value={selectedExpretise || ""}
                                                    onChange={(e) => handleChangeExpertise(e.target.value)}
                                                    endAdornment={
                                                        selectedExpretise && (
                                                            <InputAdornment position="start">
                                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeExpertise("")} />
                                                            </InputAdornment>
                                                        )
                                                    }
                                                >
                                                    {expertises && expertises.map((Expertise) => (
                                                        <MenuItem key={Expertise.id} value={Expertise}>{Expertise.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }

                                    {userData.location && <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth sx={{ ...customInputStyles }} >
                                            <InputLabel id="demo-simple-select-label-distance">{t("distance")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label-distance"
                                                id="selectedExpretise"
                                                label={t("distance")}
                                                name="selectedInstitution"
                                                value={filter.distance || ""}
                                                onChange={(e) => handleChangeDistance(e.target.value)}
                                                endAdornment={
                                                    filter.distance && (
                                                        <InputAdornment position="start">
                                                            <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeDistance("")} />
                                                        </InputAdornment>
                                                    )
                                                }
                                            >
                                                {distances && distances.map((distance, index) => (
                                                    <MenuItem key={index} value={distance.value}>
                                                        {distance.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>}
                                </Grid>
                            </Card>

                        </Box>}

                    {(!openFilter && hasFilters) &&
                        <Box className='filter-flag-box'>
                            {selectedSpeciality && <FilterTag label={selectedSpeciality?.label} onDelete={() => handleChangeSpeciality('')} />}
                            {selectedExpretise && <FilterTag label={selectedExpretise?.label} onDelete={() => handleChangeExpertise('')} />}
                            {selectedInstitution && <FilterTag label={selectedInstitution?.name} onDelete={() => handleChangeInstitution('')} />}
                            {filter.distance && <FilterTag label={filter.distance} onDelete={() => handleChangeDistance(null)} />}
                            <Typography className="clear-filter-flag" onClick={emptyFiltrationObject}>{t("clear_all_filter")}</Typography>
                        </Box>
                    }

                    <Scrollbar>
                        <InfiniteScroll
                            dataLength={users?.length}
                            next={fetchAllUsers}
                            hasMore={hasMore.current}
                            loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
                        >
                            {isDesktop &&
                                <Table>
                                    <TableHead>
                                        <TableRow className='table-row'>
                                            <TableCell className='table-titles'>{t("fullName")}</TableCell>
                                            <TableCell className='table-titles'>{t("email")}</TableCell>
                                            <TableCell className='table-titles'>{t("institu")}</TableCell>
                                            {isAllowedDeleteAllUsers && <TableCell className='table-titles'>{t("dlt")}</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {users && users.map((user, index) => (
                                            <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                                                <TableCell className="new-table-cell imeah_persone_name">{user.firstname.toLowerCase()} {user.lastname.toLowerCase()}</TableCell>
                                                <TableCell className="new-table-cell">{user.email}</TableCell>
                                                <TableCell className="new-table-cell">
                                                    {user.InstitutionUsers?.length > 0 ?
                                                        user.InstitutionUsers?.map((institutionUser, index) => (
                                                            <Chip key={index} sx={{ backgroundColor: '#17536B', color: 'white', marginBottom: '5px', marginRight: '5px' }}
                                                                className={`${classes.chip} ${isHovered ? classes.hovered : ''}`}
                                                                data-testid='chipButton'
                                                                onMouseEnter={handleMouseEnter}
                                                                onMouseLeave={handleMouseLeave}
                                                                label={`${institutionUser.institution?.name}:  ${institutionUser.role?.roleLabel[0].label}  >>`}
                                                                onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: institutionUser.id, userId: institutionUser.userId, userListTab: '1' } })}
                                                            />
                                                        ))
                                                        :
                                                        <Chip
                                                            key="guest"
                                                            label="guest"
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                            className={`${classes.chip} ${isHovered ? classes.hovered : ''}`}
                                                            sx={{ backgroundColor: '#17536B', color: 'white', marginBottom: '5px', marginRight: '5px' }}
                                                            onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: 'No userInstitutionId', userId: user.id, userListTab: '1' } })}
                                                        />
                                                    }
                                                </TableCell>
                                                {isAllowedDeleteAllUsers && <TableCell className="new-table-cell"><DeleteIcon className='new-delete-icon actions-btn' onClick={() => handleDeleteOpen(user.id)} /></TableCell>}
                                            </TableRow>
                                        ))}

                                    </TableBody>

                                </Table>
                            }

                            {!isDesktop &&
                                <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
                                    {users && users.map((user, index) => (
                                        <Grid item xs={12} sm={4} key={index}>
                                            <Box className="responsive-card-details">
                                                <Typography className='card-resp-part imeah_persone_name'>{user.firstname.toLowerCase()} {user.lastname.toLowerCase()}</Typography>
                                                {user.InstitutionUsers?.length > 0 ?
                                                    user.InstitutionUsers?.map((institutionUser, index) => (
                                                        <Chip key={index} sx={{ backgroundColor: '#C4E9EB', color: '#17536B', display: 'flex', textDecoration: 'none' }}
                                                            className={`${classes.chip} ${isHovered ? classes.hovered : ''}`}
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                            label={`${institutionUser.institution?.name}  >>`}
                                                            onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: institutionUser.id, userId: institutionUser.userId, userListTab: '1' } })}

                                                        />
                                                    ))
                                                    :
                                                    <Chip
                                                        key="guest"
                                                        label="guest"
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                        className={`${classes.chip} ${isHovered ? classes.hovered : ''}`}
                                                        sx={{ backgroundColor: '#C4E9EB', color: '#17536B', display: 'flex', textDecoration: 'none' }}
                                                        onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: 'No userInstitutionId', userId: user.id, userListTab: '1' } })}
                                                    />
                                                }
                                                <Box className='card-actions'>
                                                    {isAllowedDeleteAllUsers && <DeleteIcon className='new-delete-icon actions-btn' onClick={() => handleDeleteOpen(user.id)} />}
                                                </Box>
                                            </Box>

                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        </InfiniteScroll>

                    </Scrollbar>
                </Box>
            }

            {openDelete &&
                <DeleteUser
                    IdUserForDelete={IdUserForDelete}
                    users={users}
                    setUsers={setUsers}
                    openDelete={openDelete}
                    setOpenDelete={setOpenDelete}
                />}
        </Box>
    )
}

