/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';

export const EditMeetingDialoge = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isChangingRecurence, setIsChangingRecurence] = useState(false);

    const { OpenEditDialoge, setOpenEditDialoge, meetingReceiversCopy, meetingReceivers, meetingTitle,
        meeting, date, MainMeeting, fromHourInternational, toHourInternational, RepetitiveType,
        choosenDayOfWeek, choosenDayOfMonth, RepetitiveTypeCopy, choosenDayOfWeekCopy, choosenDayOfMonthCopy,
    } = props

    const areArraysSimilar = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        // Use a generic comparison function to handle both string and number arrays
        const compareFunction = (a, b) => (typeof a === 'string' ? a.localeCompare(b) : a - b);

        const sortedArr1 = arr1.slice().sort(compareFunction);
        const sortedArr2 = arr2.slice().sort(compareFunction);

        return sortedArr1.every((element, index) => element === sortedArr2[index]);
    };

    useEffect(() => {
        if (meeting.label === 'SUBMAIN_MEETING') {
            let newIsChangingRecurrence = false; // Initialize with false

            if (MainMeeting.MeetingsRecurrence.daily) {
                newIsChangingRecurrence = RepetitiveType !== RepetitiveTypeCopy;
            }
            if (MainMeeting.MeetingsRecurrence.weekly) {
                newIsChangingRecurrence = RepetitiveType !== RepetitiveTypeCopy || !areArraysSimilar(choosenDayOfWeek, choosenDayOfWeekCopy);
            }
            if (MainMeeting.MeetingsRecurrence.monthly) {
                newIsChangingRecurrence = RepetitiveType !== RepetitiveTypeCopy || !areArraysSimilar(choosenDayOfMonth, choosenDayOfMonthCopy);
            }
            setIsChangingRecurence(newIsChangingRecurrence);
        }
    }, []);


    const updateMeetingData = () => {

        const newReceivers = meetingReceiversCopy.filter(receiver => !meetingReceivers.some(existingReceiver => existingReceiver.institutionUserId === receiver.id));
        const removedReceivers = meetingReceivers.filter(existingReceiver => !meetingReceiversCopy.some(receiver => receiver.id === existingReceiver.institutionUserId));
        const untouchedReceivers = meetingReceivers.filter(existingReceiver => !removedReceivers.some(removedReceiver => removedReceiver.institutionUserId === existingReceiver.institutionUserId));

        const data = {
            title: meetingTitle,
            date: date,
            fromHour: fromHourInternational,
            toHour: toHourInternational,
            newReceivers,
            removedReceivers,
            untouchedReceivers
        }

        axios.patch(`meetings/${meeting.id}`, data)
            .then((response) => {
                if (response.status === 201 || response.status === 200) {
                    navigate(`/dashboard/Meetings/${meeting.id}`)
                }
            })
            .catch((err) => console.log(err));
    };

    const updateManyMeetingData = () => {

        const newReceivers = meetingReceiversCopy.filter(receiver => !meetingReceivers.some(existingReceiver => existingReceiver.institutionUserId === receiver.id));
        const removedReceivers = meetingReceivers.filter(existingReceiver => !meetingReceiversCopy.some(receiver => receiver.id === existingReceiver.institutionUserId));
        const untouchedReceivers = meetingReceivers.filter(existingReceiver => !removedReceivers.some(removedReceiver => removedReceiver.institutionUserId === existingReceiver.institutionUserId));

        const IsSameRecurenceType = RepetitiveType === RepetitiveTypeCopy;
        const newDaysOfWeek = choosenDayOfWeekCopy.filter(day => !choosenDayOfWeek.includes(day));
        const removedDaysOfWeek = choosenDayOfWeek.filter(day => !choosenDayOfWeekCopy.includes(day));
        const newDaysOfMonth = choosenDayOfMonthCopy.filter(day => !choosenDayOfMonth.includes(day));
        const removedDaysOfMonth = choosenDayOfMonth.filter(day => !choosenDayOfMonthCopy.includes(day));

        const data = {
            MainMeetingId: MainMeeting.id,
            MainMeetingDate: MainMeeting.date,
            MeetingsRecurrenceId: MainMeeting.MeetingsRecurrence.id,
            title: meetingTitle,
            date: date,
            fromHour: fromHourInternational,
            toHour: toHourInternational,
            meetingReceiversCopy,
            newReceivers,
            removedReceivers,
            UpdateFromDate: meeting.date,
            RepetitiveTypeCopy,
            choosenDayOfWeekCopy,
            choosenDayOfMonthCopy,
            IsSameRecurenceType,
            newDaysOfWeek,
            removedDaysOfWeek,
            newDaysOfMonth,
            removedDaysOfMonth,
            untouchedReceivers
        }

        axios.patch('meetings/updateManyMeeting', data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    navigate(`/dashboard/Meetings`)
                    setOpenEditDialoge(false)
                }
            })
            .catch((err) => { console.log(err) });
    }

    return (
        <Dialog open={OpenEditDialoge} keepMounted onClose={() => setOpenEditDialoge(false)}>
            <DialogTitle>{t("edit_Meeting_dialog_Title")}</DialogTitle>
            <DialogContent>
                <Typography>{t("edit_Meeting_dialog_Desc")}</Typography>
                {(meeting.label === 'SUBMAIN_MEETING' && isChangingRecurence) && <Alert severity="warning">{t("edit_Meeting_dialog_Alert")}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button className='new-button' onClick={() => setOpenEditDialoge(false)}>{t('Cancel')}</Button>
                {meeting.label === 'SEPARATE_MEETING' && <Button className='new-button' onClick={updateMeetingData}> {t('agree')}</Button>}
                {(meeting.label === 'SUBMAIN_MEETING' && !isChangingRecurence) && <Button className='new-button' onClick={updateMeetingData}> {t("Apply_one_Meeting")}</Button>}
                {meeting.label === 'SUBMAIN_MEETING' && <Button className='new-button' onClick={updateManyMeetingData}> {t("Apply_Future_Meetings")}</Button>}
            </DialogActions>
        </Dialog>
    );
};

