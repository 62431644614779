import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useCallback, useMemo } from 'react';
import { Button, Menu, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../Iconify';
import axios from 'axios';
import '../../../css/medicalFiles/AddRecommandationField.css';

export const AddRecommandationField = ({ medicalFileData, setMedicalFileData, setErrorMessage }) => {

    const { t } = useTranslation();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);

    // Handlers for opening and closing the menu
    const openMenu = useCallback((event) => setMenuAnchorEl(event.currentTarget), []);
    const closeMenu = useCallback(() => setMenuAnchorEl(null), []);

    const handleAddRecommendationField = useCallback(async (FieldType) => {
        //prepare object data for new field
        const bodyData = {
            medicalFileId: medicalFileData.id,
            label: '',
            type: FieldType,
        };

        try {
            const response = await axios.patch(`recommandation/addField/${medicalFileData?.recommandation?.id}`, bodyData);

            if (response.status === 200 || response.status === 201) {
                const newRecommendationFields = response.data;

                // Safely add editLabelStatus to the latest field
                if (newRecommendationFields.length > 0) {
                    const latestFieldIndex = newRecommendationFields?.length - 1;
                    newRecommendationFields[latestFieldIndex] = {
                        ...newRecommendationFields[latestFieldIndex],
                        editLabelStatus: true,
                    };
                }

                setMedicalFileData(prevData => ({ ...prevData, recommandation: { ...prevData.recommandation, messages: newRecommendationFields } }));
            }
        } catch (error) {
            console.error('Error adding recommandation field:', error);
            CustomErrorLogger(error, 'Error adding recommandation field');
            setErrorMessage('errorAddingRecommandationField');
        }
        finally {
            closeMenu();
        }
    }, [closeMenu, medicalFileData.id, medicalFileData?.recommandation?.id, setErrorMessage, setMedicalFileData]);

    const menu = useMemo(() => (
        <Menu
            anchorEl={menuAnchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
            PaperProps={{
                sx: {
                    width: menuAnchorEl ? menuAnchorEl.getBoundingClientRect().width : 'auto',
                    marginTop: '5px'
                },
            }}
        >
            <MenuItem key='text' value='text' onClick={() => handleAddRecommendationField('long Text')} >
                <Iconify icon='ic:round-text-fields' sx={{ marginRight: '8px', color: '#727179' }} />
                {t('text')}
            </MenuItem>
            <MenuItem key='document' value='document' onClick={() => handleAddRecommendationField('file')}>
                <Iconify icon='ic:round-insert-drive-file' sx={{ marginRight: '8px', color: '#727179' }} />
                {t('document')}
            </MenuItem>

        </Menu>
    ), [closeMenu, handleAddRecommendationField, isMenuOpen, menuAnchorEl, t]);

    return (
        <Box className='addRecommandationField_main_box'>
            <Box className='addRecommandationField_subMain_box'>
                {/* Left Divider */}
                <Box className='addRecommandationField_side_border' />

                {/* Centered Button */}
                <Button
                    className='buttonOutlined'
                    onClick={openMenu}
                    startIcon={<Iconify icon="ic:round-add" />}
                    endIcon={<Iconify icon={isMenuOpen ? 'iconamoon:arrow-down-2-light' : 'iconamoon:arrow-right-2-light'} />}
                >
                    {t('insertBlock')}
                </Button>

                {/* Right Divider */}
                <Box className='addRecommandationField_side_border' />
            </Box>

            {menu}
        </Box>
    );
};