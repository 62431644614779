import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Box, Typography, RadioGroup, FormControlLabel, Radio, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DayBox } from "./dayBox";
import React from "react";
import axios from "axios";

export const AddUserAvailability = ({ setSection, availability, setAvailability, inVaccation, setInVaccation }) => {

    const [errMsg, setErrMsg] = useState("");

    const { t } = useTranslation();
    const availabilityDialog = true

    const useStyles = makeStyles((theme) => ({
        notchedOutline: {
            borderWidth: "2px",
            borderColor: "#17536B !important",
        },
    }));
    const classes = useStyles();

    const handleCheckboxChange = (day) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            [day]: { checked: prevAvailability[day]?.checked === true ? false : true }
        }));
    };

    const AddAvailability = () => {
        const data = {
            availability,
            inVaccation: {checked: inVaccation.checked, endVaccation: inVaccation.endVaccation},
        }
        axios.post(`user-availability`, data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSection("")
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCancel = () => {
        setSection("");
        setErrMsg("");
    }

    const hasUncheckedWithoutFromOrTo = Object.values(availability).some((day) => {
        return day?.checked === true && (!day.from || !day.to);
    });

    return (
        <Dialog fullWidth open={availabilityDialog}>
            <DialogTitle className="page-title">{t('addYourAvailability')}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogContent className='education-dialog-content'>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'flex-start' }}>
                    <DayBox
                        day="monday"
                        availability={availability}
                        handleCheckboxChange={handleCheckboxChange}
                        setAvailability={setAvailability}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                    <DayBox
                        day="tuesday"
                        availability={availability}
                        handleCheckboxChange={handleCheckboxChange}
                        setAvailability={setAvailability}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                    <DayBox
                        day="wednesday"
                        availability={availability}
                        handleCheckboxChange={handleCheckboxChange}
                        setAvailability={setAvailability}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                    <DayBox
                        day="thursday"
                        availability={availability}
                        handleCheckboxChange={handleCheckboxChange}
                        setAvailability={setAvailability}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                    <DayBox
                        day="friday"
                        availability={availability}
                        handleCheckboxChange={handleCheckboxChange}
                        setAvailability={setAvailability}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                    <DayBox
                        day="saturday"
                        availability={availability}
                        handleCheckboxChange={handleCheckboxChange}
                        setAvailability={setAvailability}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                    <DayBox
                        day="sunday"
                        availability={availability}
                        handleCheckboxChange={handleCheckboxChange}
                        setAvailability={setAvailability}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                </Box>
                <Box className='add-education-current-post-box'>
                    <Typography variant="h6" className="day_typo">{t('inVaccation')} ?</Typography>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={inVaccation?.checked}
                        name="radio-buttons-group"
                        row
                        onChange={event => setInVaccation(prevState => ({ ...prevState, checked: event.target.value }))}
                    >
                        <FormControlLabel value="false" control={<Radio />} label={t('no')} />
                        <FormControlLabel value="true" control={<Radio />} label={t('yes')} />
                    </RadioGroup>
                    {inVaccation?.checked === 'true' &&
                        <Box>
                            <TextField
                                id="End_date"
                                name="End_date"
                                type="date"
                                label={t('endDate')}
                                margin="dense"
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                defaultValue={inVaccation.endVaccation}
                                onChange={event => setInVaccation(prevState => ({ ...prevState, endVaccation: event.target.value }))}
                            />
                        </Box>}
                </Box>

            </DialogContent>
            <DialogActions className="addInstitution-actions-box">
                <Button className="new-button" onClick={handleCancel}>{t('cancel')}</Button>
                <Button className="new-button" onClick={() => AddAvailability()} disabled={errMsg || (inVaccation?.checked === 'true' && inVaccation.endVaccation?.length === 0) || hasUncheckedWithoutFromOrTo}>{t('add')}</Button>
            </DialogActions>
        </Dialog>
    )
}