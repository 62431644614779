import { Box, Typography, TextField, Divider } from '@mui/material';
import ImeahActionIcon from '../../../../utils/imeah_action_icon';
import { DeleteRecommandationField } from '../deleteCustomField';
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import Iconify from '../../../Iconify';
import axios from 'axios';
import '../../../../css/medicalFiles/fieldType/other.css'

export const RecommandationTextField = ({
    recommandationId,
    canModifyRecommandation,
    info,
    debouncedHandleUpdateOneFieldData,
    medicalFileData,
    setMedicalFileData
}) => {

    const { t } = useTranslation();

    const [editLabelStatus, setEditLabelStatus] = useState(info.editLabelStatus || false);
    const [openDeleteField, setOpenDeleteField] = useState(false);
    const [label, setLabel] = useState(info.label || '');

    // Handle label editing
    const handleEditLabel = useCallback(async () => {
        try {
            if (!label) return;

            const bodyData = {
                medicalFileId: medicalFileData.id,
                fieldId: info.id,
                label,
            };
            const response = await axios.patch(`recommandation/editFieldLabel/${medicalFileData?.recommandation?.id}`, bodyData);

            if (response.status === 200 || response.status === 201) {
                setMedicalFileData(prevData => ({ ...prevData, recommandation: { ...prevData.recommandation, messages: response.data } }));
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            setEditLabelStatus(false);
        }
    }, [info.id, label, medicalFileData.id, medicalFileData?.recommandation?.id, setMedicalFileData])

    const handleCloseEditLable = useCallback(() => {
        setEditLabelStatus(false);
        setLabel(info.label);
    }, [info.label]);

    return (
        <Box key={info.id} className='otherField_container'>

            <Box className='otherField_header_container'>

                <Box className='otherField_label_container'>

                    {editLabelStatus ?
                        (
                            <Box className='otherField_edit_label_field_box'>
                                <TextField
                                    className='customTextField'
                                    type='text'
                                    id={info.id}
                                    name={info.label}
                                    placeholder={t('Title')}
                                    defaultValue={typeof label === 'string' ? label : ''}
                                    onChange={(event) => setLabel(event.target.value)}
                                    error={!label}
                                    helperText={!label && t('isRequired')}
                                />
                                {label && (
                                    <Box className='otherField_label_actions_container'>
                                        <Iconify
                                            onClick={handleEditLabel}
                                            icon="mdi:check"
                                            className='otherField_valid_label_icon'
                                        />
                                        <Divider orientation="vertical" flexItem />
                                        <Iconify
                                            onClick={handleCloseEditLable}
                                            icon="material-symbols:close-rounded"
                                            className='otherField_cancel_label_icon'
                                        />
                                    </Box>
                                )}
                            </Box>
                        ) :
                        (<Typography className='medicalFileFields_Field_label' >{info.label}</Typography>)
                    }

                    {canModifyRecommandation && !editLabelStatus &&
                        <Iconify
                            onClick={() => setEditLabelStatus(true)}
                            icon='fe:edit'
                            className='otherField_edit_label_icon'
                        />
                    }
                </Box>

                {canModifyRecommandation &&
                    <ImeahActionIcon icon="ic:round-delete" color='#727179' onClick={() => setOpenDeleteField(true)} />
                }
            </Box>

            <TextField
                className='customTextField'
                type='text'
                fullWidth
                id={info.id}
                multiline
                minRows={1}
                maxRows={Infinity}
                name={info.label}
                disabled={!canModifyRecommandation}
                defaultValue={typeof info.value === 'string' ? info.value : ''}
                onChange={(event) => debouncedHandleUpdateOneFieldData(recommandationId, info.id, event.target.value)}
            />

            {openDeleteField &&
                <DeleteRecommandationField
                    medicalFileData={medicalFileData}
                    setMedicalFileData={setMedicalFileData}
                    openDeleteField={openDeleteField}
                    setOpenDeleteField={setOpenDeleteField}
                    field={info}
                />
            }

        </Box>
    );
};