import { Box, Typography, Alert, Stack, Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MuiMultiSelect } from '../../utils/muiMultiSelect.js';
import React, { useState, useCallback } from 'react';
import { MuiSelect } from '../../utils/muiSelect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import Page from '../Page';
import axios from 'axios';
import '../../css/collaboration/addCollaboration.css';

export const AddCollaboration = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [askingInstitution, setAskingInstitution] = useState('');
  const [providingInstitution, setProvidingInstitution] = useState('');
  const [choosenSpecialities, setChoosenSpecialities] = useState([]);
  const [choosenExpertises, setChoosenExpertises] = useState([]);
  const [msg, setMsg] = useState('');

  // Validation Schema
  const validationSchema = Yup.object().shape({
    askingInstitutionId: Yup.string().required(t('askingInstitutionRequired')),
    providingInstitutionId: Yup.string().required(t('providingInstitutionRequired')),
    specialities: Yup.array(),
    expertises: Yup.array(),
  })
    .test(
      'differentInstitutions',
      t('askingAndProvidingInstitutionCannotBeSame'),
      function (values) {
        const { askingInstitutionId, providingInstitutionId } = values;
        return askingInstitutionId !== providingInstitutionId;
      }
    )
    .test(
      'specialitiesOrExpertisesRequired',
      t('specialitiesOrExpertisesRequired'),
      function (values) {
        const { specialities, expertises } = values;
        return specialities?.length > 0 || expertises?.length > 0;
      }
    );

  // Fetch Institutions Function
  const fetchInstitutions = useCallback(async (page, search) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('collaboration-institution/findAllInstitutionsForCollaboration', {
        params: {
          offset, limit, filters: {
            search: search || '',
          },
        },
      });
      return response.data?.map(record => ({ value: record.id, label: record.name }));
    } catch (error) {
      console.error('Error fetching Institutions Types:', error);
      return [];
    }
  }, []);

  // Fetch Specialities Function
  const fetchSpecialities = async (page, searchTerm) => {
    try {
      const response = await axios.get('speciality/specialitiesForCreateCollaborations', {
        params: { providingInstitutionId: providingInstitution?.value, page, search: searchTerm },
      });
      return response.data?.map(record => ({ value: record.value, label: record.label }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  // Fetch Expertises Function
  const fetchExpertises = async () => {
    try {
      const response = await axios.get('expertise/expertisesForCreateCollaboration', { params: { providingInstitutionId: providingInstitution?.value } });
      return response.data?.map(record => ({ value: record.value, label: record.label }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  // Handle collaboration form submission
  const addCollaboration = async () => {

    const bodyData = {
      askingInstitutionId: askingInstitution?.value,
      providingInstitutionId: providingInstitution?.value,
      specialities: choosenSpecialities?.map((speciality) => speciality?.value),
      expertises: choosenExpertises?.map((expertise) => expertise?.value),
    };

    try {
      await validationSchema.validate(bodyData, { abortEarly: false });

      const response = await axios.post('collaboration-institution', bodyData);
      if (response.status === 200 || response.status === 201) {
        navigate(-1);
      }
    } catch (err) {
      if (err.name === 'ValidationError') {
        setMsg(err?.errors?.join(` ${t('and')} `));
      }
      else {
        // If API call fails, set the error message
        console.error('Error adding expertise:', err);
        setMsg(err.message);
      }
    }
  };
  const handleChangeRequerent = (selectedInstitution) => {
    setAskingInstitution(selectedInstitution.target)
    setProvidingInstitution('')
    setChoosenSpecialities([])
    setChoosenExpertises([])
  }

  const handleChangeRequis = (selectedInstitution) => {
    setProvidingInstitution(selectedInstitution.target)
    setChoosenSpecialities([])
    setChoosenExpertises([])
  }

  return (
    <Page title={t("addNewCollaboration")} className="addCollaboration-select-box">
      <Box className="addCollaboration-main-page-header-box">
        <ArrowBackIosIcon className='addCollaboration-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
        <Stack className="addCollaboration-main-page-header">
          <Box className="addCollaboration-page-title-box">
            <Typography className='addCollaboration-page-title'>{t("addNewCollaboration")}</Typography>
          </Box>
        </Stack>
      </Box>

      {msg && <Alert severity="error">{msg}</Alert>}

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <MuiSelect
            label={t('requerentInstitution')}
            required={true}
            searchable={true}
            value={askingInstitution}
            fetchFunction={fetchInstitutions}
            onChange={(selectedInstitution) => handleChangeRequerent(selectedInstitution)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiSelect
            label={t('requisInstitution')}
            required={true}
            searchable={true}
            value={providingInstitution}
            fetchFunction={fetchInstitutions}
            onChange={(selectedInstitution) => handleChangeRequis(selectedInstitution)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiMultiSelect
            key={`specialities-${providingInstitution?.value}`}
            label={t('specialities')}
            required={false}
            searchable={true}
            value={choosenSpecialities}
            fetchFunction={providingInstitution?.value && fetchSpecialities}
            onChange={(selectedOptions) => setChoosenSpecialities(selectedOptions)}
          />

        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiMultiSelect
            key={`expertises-${providingInstitution?.value}`}
            label={t('expertises')}
            required={false}
            searchable={true}
            value={choosenExpertises}
            fetchFunction={providingInstitution?.value && fetchExpertises}
            onChange={(selectedOptions) => setChoosenExpertises(selectedOptions)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography className="addCollaboration-requiredNote">{t('atLeastSpecialityOrExpertiseRequired')}</Typography>
        </Grid>

      </Grid>

      <Box className="addCollaboration-actions-box">
        <Button className='new-button' onClick={() => navigate(-1)}>{t('cancel')}</Button>
        <Button className='new-button' onClick={addCollaboration}>{t('Create')}</Button>
      </Box>
    </Page>
  );
};