import { Box, Typography, TextField } from '@mui/material';

export const LongTextInList = ({ allowedActions, info, item, debouncedHandleUpdateOneListFieldData }) => {

    return (
        <Box display={'flex'} gap={'12px'} flexDirection={'column'}>

            <Typography className='medicalFileFields_listField_label' >{item.ItemLabel}</Typography>

            <TextField
                className='customTextField'
                id={item.id}
                multiline
                minRows={2}
                maxRows={Infinity}
                name={item.ItemLabel}
                type='text'
                disabled={!allowedActions.canEditPrivateMedicalFile}
                defaultValue={typeof item.ItemValue === 'string' ? item.ItemValue : ''}
                onChange={event => debouncedHandleUpdateOneListFieldData(info.id, item.id, event.target.value)}
            />

        </Box>
    );
};