import { Card, Table, Stack, LinearProgress, TableRow, TableHead, TableBody, TableCell, Typography, Button, Box } from '@mui/material';
import { clearNotifications, deleteNotification } from '../../redux/notificationsSlice';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector, useDispatch } from 'react-redux';
import { fDateTime } from '../../utils/formatTime';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import Scrollbar from '../Scrollbar';
import Page from '../Page';
import axios from 'axios';

export const ViewAllNotification = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);
    const dispatch = useDispatch()

    const reduxNotifications = useSelector(state => state.notifications);

    const [notificationsArray, setNotificationsArray] = useState([]);
    // const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const totalUnRead = reduxNotifications.length;

    useEffect(() => {
        const fetchNotifications = async () => {
            if (userData) {
                setLoading(true);
                try {
                    const response = await axios.get(`notifications`);
                    setNotificationsArray(response.data);
                } catch (error) {
                    console.error('Error fetching notifications:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchNotifications();
    }, [userData]);

    const handleReadOneNotification = async (notificationId) => {        
        const newData = [...notificationsArray]        
        try {
            const response = await axios.get(`notifications/ReadOne/${notificationId}`);
            if (response.status === 200 || response.status === 201) {
                dispatch(deleteNotification(notificationId));
                const indexInArray = newData.findIndex((x) => x.id === notificationId);                
                newData[indexInArray].NotificationReadBy = response.data.NotificationReadBy
                setNotificationsArray(newData)
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const handleMarkAllAsRead = async () => {
        try {
            const response = await axios.get(`notifications/MarkAllAsRead`);
            if (response.status === 200 || response.status === 201) {
                setNotificationsArray(response.data);
                dispatch(clearNotifications());
            }
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    return (
        <Page title={t('Notifications')} >
            <Stack className='back-title-search-header' sx={{ marginBottom: '15px' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '15px' }} >
                    <ArrowBackIosIcon className='back-btn' onClick={() => navigate(-1)} />
                    <Typography className='page-title'>{t('Notifications')}</Typography>
                </Box>
                {/* <TextField
                    className='search-input'
                    id="outlined-password-input"
                    label={t('search')}
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                /> */}
                {totalUnRead > 0 && (<Button onClick={handleMarkAllAsRead} sx={{ backgroundColor: '#34697E', color: 'white' }}>{t("Mark_all_as_read")}</Button>)}
            </Stack>

            {loading ?
                <LinearProgress />
                :
                <Card>
                    <Scrollbar>
                        <Table>
                            <TableHead>
                                <TableRow className='table-row'>
                                    <TableCell className='table-titles'> {t("Title")} </TableCell>
                                    <TableCell className='table-titles'> {t("SentBy")} </TableCell>
                                    <TableCell className='table-titles'>{t("sent_at")} </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {notificationsArray && notificationsArray
                                    // .filter((item) => search.toLowerCase() === '' ? item : item.title.toLowerCase().includes(search))
                                    .map((notification) =>
                                        <TableRow
                                            key={notification.id}
                                            sx={{ backgroundColor: notification.NotificationReadBy.includes(userData.id || currentInstitution?.id) ? 'none' : '#f2f2f2' }}
                                        >
                                            <TableCell align="left">
                                                {notification.type === 'Medical File' ?
                                                    <Typography>{`${notification.sentBy.firstname} ${notification.sentBy.lastname} `}{t(notification.title)}{` "${notification?.medical_File?.first_name} ${notification?.medical_File?.last_name}"`} {t("in")} {` "${notification.targetInstitution.name}"`}</Typography> :
                                                    <Typography>{`${notification.sentBy.firstname} ${notification.sentBy.lastname} `}{t(notification.title)}{` "${notification?.targetInstitution?.name}"`}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="left">{notification.sentBy.firstname} {notification.sentBy.lastname} </TableCell>

                                            <TableCell align="left">{fDateTime(notification.created_at, userData.local_code)} </TableCell>

                                            {notification.NotificationReadBy.includes(userData.id || currentInstitution?.id) ? null :
                                                <TableCell align="left">
                                                    <Button onClick={() => handleReadOneNotification(notification.id)} sx={{ backgroundColor: '#34697E', color: 'white' }} >{t("mark_as_read")}</Button>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
            }
        </Page>
    );
};
