import { createSlice } from '@reduxjs/toolkit';

const userPermissionsSlice = createSlice({
    name: 'userPermissions',
    initialState: [],
    reducers: {
        setUserPermissions: (state, action) => action.payload
    },
});

export const { setUserPermissions } = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;