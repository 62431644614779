
import { Box, Stepper, Step, StepConnector, Button, Typography } from '@mui/material';
import { SelectSpecialityOrExpertise } from './select_speciality_or_expertise';
import { useLocation, useNavigate } from "react-router-dom";
import { SelectTargetDoctor } from './selectTargetDoctor';
import useResponsive from '../../../hooks/useResponsive';
import { FillDataForm } from './fill_static_info_form';
import { SelectTemplate } from './select_template';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import Iconify from '../../Iconify'
import Page from '../../Page';
import '../../../css/medicalFiles/createMedicalFile.css';

const steps = [0, 1, 2];

const CustomConnector = styled(StepConnector)(() => ({
  '& .MuiStepConnector-line': {
    height: '15vh',
    borderWidth: '3px',
    borderColor: '#4caf50', // Green color for the line
  },
  '&.Mui-disabled .MuiStepConnector-line': {
    borderColor: '#bdbdbd', // Gray color for the inactive line
  },
}));

export const CreateMedicalFile = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');
  // this location data is comming when create direct medical file or visit doctor profile
  const locationData = location.state;

  const [activeStep, setActiveStep] = useState(locationData?.activeStep || 0);

  const [targetTemplateOwnerData, setTargetTemplateOwnerData] = useState(locationData?.targetTemplateOwnerData || '');
  const [targetDoctorInstitutionId, setTargetDoctorInstitutionId] = useState(locationData?.targetDoctorInstitutionId || '');
  const [targetDoctorForShare, setTargetDoctorForShare] = useState(locationData?.targetDoctorForShare || '');
  const [selectedSpeciality, setSelectedSpeciality] = useState(locationData?.selectedSpeciality || '');
  const [selectedExpertise, setSelectedExpertise] = useState(locationData?.selectedExpertise || '');
  const [choosenTemplate, setChoosenTemplate] = useState({
    id: '',
    name: '',
    description: '',
    additional_fields: [],
    sections: [],
  });

  return (
    <Page title={t("create_file")} className="createMdf-page">

      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Iconify icon='fluent:ios-arrow-24-filled' className='onefile-back-btn' onClick={() => navigate('/dashboard/medicalFiles')} />
        <Typography className="imeahTitle">{t("create_file")}</Typography>
      </Box>

      {activeStep < 3 &&
        <Box display={'flex'} flexDirection={'row'} >

          {isDesktop &&
            <Box flexBasis={'5%'}>
              <Stepper activeStep={activeStep + 1} orientation="vertical" connector={<CustomConnector />} >
                {steps.map((step) => (
                  <Step key={step}>
                    <Iconify
                      icon="prime:check-circle"
                      width="30px"
                      height="30px"
                      color={(step < activeStep + 1) ? '#2BA54A' : '#8E8E93'}
                    />
                  </Step>
                ))}
              </Stepper>
            </Box>
          }

          <Box flexBasis={isDesktop ? '95%' : '100%'}>

            <Box display={'flex'} flexDirection={'column'} gap={'20px'}>

              <SelectSpecialityOrExpertise
                selectedSpeciality={selectedSpeciality}
                selectedExpertise={selectedExpertise}
                setTargetTemplateOwnerData={setTargetTemplateOwnerData}
                setChoosenTemplate={setChoosenTemplate}
                setSelectedSpeciality={setSelectedSpeciality}
                setSelectedExpertise={setSelectedExpertise}
                setTargetDoctorInstitutionId={setTargetDoctorInstitutionId}
                setTargetDoctorForShare={setTargetDoctorForShare}
                setActiveStep={setActiveStep}
              />

              <SelectTargetDoctor
                selectedSpeciality={selectedSpeciality}
                selectedExpertise={selectedExpertise}
                targetDoctorForShare={targetDoctorForShare}
                targetTemplateOwnerData={targetTemplateOwnerData}
                targetDoctorInstitutionId={targetDoctorInstitutionId}
                choosenTemplate={choosenTemplate}
                activeStep={activeStep}
                setTargetTemplateOwnerData={setTargetTemplateOwnerData}
                setChoosenTemplate={setChoosenTemplate}
                setTargetDoctorInstitutionId={setTargetDoctorInstitutionId}
                setTargetDoctorForShare={setTargetDoctorForShare}
                setActiveStep={setActiveStep}
              />

              <SelectTemplate
                choosenTemplate={choosenTemplate}
                selectedSpeciality={selectedSpeciality}
                selectedExpertise={selectedExpertise}
                targetTemplateOwnerData={targetTemplateOwnerData}
                targetDoctorInstitutionId={targetDoctorInstitutionId}
                setChoosenTemplate={setChoosenTemplate}
                setActiveStep={setActiveStep}
              />

              <Button
                className='buttonFilled'
                variant="outlined"
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => setActiveStep(3)}
                disabled={
                  (
                    (selectedSpeciality?.value === null || selectedSpeciality?.value === undefined || selectedSpeciality?.value === 'NoSpeciality') &&
                    (selectedExpertise?.value === null || selectedExpertise?.value === undefined || selectedExpertise?.value === 'NoExpertise')
                  ) ||
                  targetDoctorForShare === '' || choosenTemplate.id === ''
                }
              >
                {t('next')}
              </Button>
            </Box>

          </Box>
        </Box>
      }

      {activeStep === 3 &&
        <FillDataForm
          selectedSpeciality={selectedSpeciality}
          selectedExpertise={selectedExpertise}
          targetDoctorForShare={targetDoctorForShare}
          choosenTemplate={choosenTemplate}
          setActiveStep={setActiveStep}
        />
      }

    </Page>
  );
}