import React from 'react'
import { Box, Typography, CardMedia } from '@mui/material'
import '../../css/homePage/homeFirstPage.css'
import FirstImage from '../../images/illustration.svg'
import Logo from '../../images/logo_e_health_pro.svg'
import { useTranslation } from "react-i18next";

export const HomeFirstPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeFirstPart-main-box'>
            {/* <Box className='homeFirstPart-first-typography-box'>
                <Typography className='homeFirstPart-first-typography-text-one'>
                    {t('managementOf')}
                </Typography>
                <Typography className='homeFirstPart-first-typography-text-two'>
                    {t('highLevelHomePage')}
                </Typography>
                <Typography className='homeFirstPart-first-typography-text-two'>
                    {t('ehealthNetwork')}
                </Typography>
            </Box> */}
            <Box className="home-imeah-firstPage-typo-img-box">
                <Box className="home-imeah-firstPage-logo-typo-box">
                    <CardMedia
                        className="home-imeah-firstPage-logo"
                        component="img"
                        image={Logo}
                        alt="My Image"
                    />
                    <Typography className='homeFirstPart-first-typography-text-one'>
                        {t('Your_medical_experts_network')}
                    </Typography>

                    <Typography className="home-imeah-firstPage-typo-part">
                        {t('teleexpertiseNowEasy')}
                    </Typography>
                </Box>
                <CardMedia
                    className="home-imeah-firstPage-firstImg"
                    component="img"
                    image={FirstImage}
                    alt="My Image"
                />
            </Box>
        </Box>
    )
}
