/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Stack, CircularProgress, Box, Button, Card, CardContent, Grid } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { HasPermission } from "../../utils/checkUserPermission";
import { CloseMeetingDialoge } from "./closeMeetingDialoge";
import { fDate } from "../../utils/formatTime";
import { DeleteMeeting } from "./deleteMeeting";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { isToday, parseISO } from 'date-fns';
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import '../../css/meetings/Meeting.css';
import Label from "../Label";
import Page from "../Page";
import axios from 'axios';

export const MeetingDetails = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const isAllowedManageMeetings = HasPermission('manage_my_meeting');
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);

    const { id } = useParams()
    const [meeting, setMeeting] = useState([])
    //fetch main meeting to get MeetingsRecurrence in case the meeting is sub main meeting
    const [MainMeeting, setMainMeeting] = useState([])
    const [fromHourLocally, setFromHourLocally] = useState("");
    const [toHourLocally, setToHourLocally] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [openCloseMeeting, setOpenCloseMeeting] = useState(false);
    const [loading, setLoading] = useState(true);

    function convertUtcToLocalTime(utcTime) {
        const [utcHours, utcMinutes] = utcTime.split(':').map(Number);
        const utcDate = new Date();
        utcDate.setUTCHours(utcHours);
        utcDate.setUTCMinutes(utcMinutes);

        // Convert UTC time to local time using the browser's time zone
        return utcDate.toLocaleTimeString([], {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    }

    useEffect(() => {
        if (currentInstitution) {
            axios.get(`meetings/${id}`).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    if (response.data.label === 'SUBMAIN_MEETING') {
                        FetchMainMeeting(response.data.subMeetingFor)
                    }
                    setMeeting(response.data)
                    const localFromTime = convertUtcToLocalTime(response.data.fromHour);
                    const localToTime = convertUtcToLocalTime(response.data.toHour);
                    setFromHourLocally(localFromTime)
                    setToHourLocally(localToTime)
                    setLoading(false)
                }
            })
                .catch((err) => console.log(err));
        }
    }, [id, currentInstitution]);

    const FetchMainMeeting = (MainMeetingId) => {
        axios.get(`meetings/${MainMeetingId}`)
            .then((response) => {
                if (response.status === 201 || response.status === 200) {
                    setMainMeeting(response.data)
                }
            })
            .catch((err) => console.log(err));
    }

    const updateRequest = (meeting, receiverRecordId, newStatus) => {
        const data = {
            status: newStatus,
            meetingID: meeting.id,
            meetingRequesterID: [meeting.requesterUser.user.id],
            requesterInstitutionName: meeting.requesterUser.institution.name
        }

        axios.patch(`meeting-receivers/${receiverRecordId}`, data)
            .then((response) => {
                if (response.status === 201 || response.status === 200) {
                    const updatedReceivers = [...meeting.receivers];
                    const index = updatedReceivers.findIndex((x) => x.id === receiverRecordId);
                    updatedReceivers[index].status = response.data.status;
                    setMeeting(prevMeeting => ({ ...prevMeeting, receivers: updatedReceivers }));
                }
            })
            .catch((err) => console.log(err));
    };

    const handleOpenNewTab = (meeting) => {
        // Store data in session storage
        sessionStorage.setItem('MeetingId', meeting.id);
        sessionStorage.setItem('MeetingTitle', meeting.title.trim());
        sessionStorage.setItem('MeetingUserName', `${userData.firstname} ${userData.lastname}`);

        // Open new tab
        window.open('/live', '_blank');
    };

    return (
        <Page title={t("Meeting_Details_Page_Title")}>
            {loading ?
                <Box className='circleLoading'>
                    <CircularProgress size="5rem" />
                </Box> :
                <Box>
                    <Stack className='pages-header'>
                        <Box className='onefile-header-title'>
                            <ArrowBackIosIcon className='new-delete-icon actions-btn' onClick={() => navigate('/dashboard/Meetings')} />
                            <Typography className='onefile_title'>{t("Meeting_details")}</Typography>
                        </Box>
                        {(isAllowedManageMeetings && meeting.requesterUserId === currentInstitution.id && meeting.status === 'Active') &&
                            <Box display={'flex'} gap={'5px'}>
                                <Button className='new-button' onClick={() => navigate(`/dashboard/Meetings/edit/${meeting.id}`)}>{t('Edit')}</Button>
                                <Button className='new-button' onClick={() => setOpenCloseMeeting(true)}>{t('Cancel')}</Button>
                            </Box>
                        }
                    </Stack>

                    <Card>
                        <CardContent>
                            <Typography variant="h5">{meeting.title}</Typography>
                            <Grid container spacing={2} marginTop={2}>
                                <Grid item xs={6}>
                                    <Typography fontWeight={800}>{t("requester")}:</Typography>
                                    <Typography color="textSecondary">{meeting.requesterUser?.user?.firstname} {meeting.requesterUser?.user?.lastname}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontWeight={800}>{t("Status")}</Typography>
                                    <Label
                                        variant="ghost"
                                        sx={{
                                            borderRadius: '4px',
                                            padding: '2px',
                                            color: 'black',
                                            backgroundColor: meeting.status === 'Active' ? '#3ce641' : '  #fa464f'
                                        }}
                                    >
                                        {meeting.status}
                                    </Label>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontWeight={800}>{t("date")}</Typography>
                                    {meeting.label === 'SEPARATE_MEETING' ?
                                        <Typography color="textSecondary">{fDate(meeting.date, userData.local_code)}</Typography> :
                                        <Box>
                                            <Typography color="textSecondary">{fDate(meeting.date, userData.local_code)}</Typography>
                                            <Typography color="textSecondary">{t('Repetitive')} {t(MainMeeting?.MeetingsRecurrence?.type)}</Typography>
                                            {MainMeeting?.MeetingsRecurrence?.weekly && <Typography color="textSecondary" >  {MainMeeting?.MeetingsRecurrence?.weekdays.join(', ')}</Typography>}
                                            {MainMeeting?.MeetingsRecurrence?.monthly && <Typography color="textSecondary" >  {MainMeeting?.MeetingsRecurrence?.monthdays.join(', ')}</Typography>}
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontWeight={800}>{t("time")}</Typography>
                                    <Typography color="textSecondary">{t("from")} {fromHourLocally} {t("to")} {toHourLocally}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontWeight={800}>{t("receiver")}</Typography>
                                    <Typography color="textSecondary">
                                        {meeting.receivers && meeting.receivers.map((oneReceiver) => (
                                            <Box key={oneReceiver.id} className='MeetingReceiverBox'>
                                                <Typography>{oneReceiver.institutionUser.user.firstname} {oneReceiver.institutionUser.user.lastname}</Typography>
                                                <Label
                                                    variant="ghost"
                                                    sx={{
                                                        borderRadius: '4px',
                                                        padding: '2px',
                                                        color: 'black',
                                                        backgroundColor:
                                                            oneReceiver.status === 'pending' ? '#f7eb5e' : oneReceiver.status === 'accepted' ? '#3ce641' : oneReceiver.status === 'refused' ? '  #fa464f' : 'd9d6b0',
                                                    }}
                                                >
                                                    {t(oneReceiver.status)}
                                                </Label>
                                            </Box>
                                        ))
                                        }
                                    </Typography>
                                </Grid>

                                {meeting.status === 'Active' &&
                                    <Grid item xs={6}>
                                        {meeting.receivers && meeting.receivers.map((oneReceiver) => (
                                            <Box key={oneReceiver.id} className='MeetingReceiverBox'>
                                                {oneReceiver.status === 'pending' && oneReceiver.institutionUserId === currentInstitution.id && (
                                                    <Typography className='MeetingReceiverBox'>
                                                        <HighlightOffIcon onClick={() => updateRequest(meeting, oneReceiver.id, 'refused')} className='new-delete-icon actions-btn' />
                                                        <CheckCircleOutlineIcon onClick={() => updateRequest(meeting, oneReceiver.id, 'accepted')} className='new-delete-icon actions-btn' />
                                                    </Typography>

                                                )}
                                                {oneReceiver.status === "accepted" && oneReceiver.institutionUserId === currentInstitution.id && isToday(parseISO(meeting.date)) &&
                                                    <Button className='new-button' onClick={() => handleOpenNewTab(meeting)}>{t("Join")}</Button>
                                                }
                                            </Box>
                                        ))}
                                        {(meeting.requesterUserId === currentInstitution.id && isToday(parseISO(meeting.date))) &&
                                            <Button className='new-button' onClick={() => handleOpenNewTab(meeting)}>{t("Join")}</Button>
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            }

            {openDelete &&
                <DeleteMeeting
                    id={id}
                    setOpenDelete={setOpenDelete}
                    openDelete={openDelete}
                />
            }

            {openCloseMeeting &&
                <CloseMeetingDialoge
                    openCloseMeeting={openCloseMeeting}
                    setOpenCloseMeeting={setOpenCloseMeeting}
                    meeting={meeting}
                    MainMeeting={MainMeeting}
                    setMeeting={setMeeting}
                />
            }

        </Page >
    );
}