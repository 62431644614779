import { createSlice } from '@reduxjs/toolkit';

const CurrentInstitutionSlice = createSlice({
    name: 'CurrentInstitution',
    initialState: {},
    reducers: {
        setCurrentInstitution: (state, action) => action.payload
    },
});

export const { setCurrentInstitution } = CurrentInstitutionSlice.actions;

export default CurrentInstitutionSlice.reducer;