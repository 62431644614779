/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Table, Stack, LinearProgress, TableRow, TableHead, TableBody, TableCell, Typography, useMediaQuery, Grid, Divider } from '@mui/material';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HasPermission } from "../utils/checkUserPermission";
import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import Scrollbar from '../components/Scrollbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { act } from '@testing-library/react';
import { fDate } from "../utils/formatTime";
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import axios from 'axios';
import '../css/policies/policies.css';

export const Policies = () => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userData = useSelector(state => state.userData);
    //check user permissions
    const isAllowedManageLoginPolicies = HasPermission('Manage_loginPolicies');

    const [loading, setLoading] = useState(true);
    const [loginPolicies, setLoginPolicies] = useState([]);
    //pagination state
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 20;

    const fetchLoginPolicies = () => {
        const currentOffset = offset.current;
        axios.get('policies', { params: { offset: currentOffset, limit } })
            .then((response) => {
                act(() => {
                    setLoginPolicies(currentOffset === 0 ? response.data : [...loginPolicies, ...response.data]);
                    setLoading(false);
                    offset.current = currentOffset + limit;
                    hasMore.current = response.data.length === limit;
                });
            })
            .catch((err) => {
                console.log(err, 'Error fetching login policies in policies main page');
                CustomErrorLogger(err, 'Error fetching login policies in policies main page');
            });
    }

    useEffect(() => {
        if (userData) {
            fetchLoginPolicies()
        }
    }, [userData]);

    return (
        <Page title={t("policies")}>
            <Stack className="policies-main-page-header">
                <Box className="policies-page-title-box">
                    <Iconify className="policies-page-title-icon" icon="carbon:document-tasks" />
                    <Typography className='policies-page-title'>{t("policies")}</Typography>
                </Box>

                {isAllowedManageLoginPolicies &&
                    <Box className='policies-page-header-button' onClick={() => { navigate('addPolicy') }}>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Iconify icon="eva:plus-fill" className="plus-icon" />
                        <Typography>{t('Create')}</Typography>
                    </Box>
                }

            </Stack>

            {loading ? <LinearProgress /> :
                <Scrollbar>
                    <InfiniteScroll
                        dataLength={loginPolicies.length}
                        next={fetchLoginPolicies}
                        loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
                    >
                        {isDesktop &&
                            <Table>
                                <TableHead>
                                    <TableRow className='table-row'>
                                        <TableCell className='table-titles'>{t('label')}</TableCell>
                                        <TableCell className='table-titles'>{t('policyType')}</TableCell>
                                        <TableCell className='table-titles'>{t('country')}</TableCell>
                                        <TableCell className='table-titles'>{t('language')}</TableCell>
                                        <TableCell className='table-titles'>{t('createdAt')}</TableCell>
                                        <TableCell className='table-titles' align="right">{t('Edit')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loginPolicies && loginPolicies.map((policy, index) => {
                                        const { id, label, type, countryName, language, isPublic, created_at } = policy;
                                        return (
                                            <TableRow key={id} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                                                <TableCell align="left" className='new-table-cell'>{label}</TableCell>
                                                <TableCell align="left" className='new-table-cell'>{t(type)}</TableCell>
                                                <TableCell align="left" className='new-table-cell'>{!isPublic ? countryName : 'none'}</TableCell>
                                                <TableCell align="left" className='new-table-cell'>{t(language)}</TableCell>
                                                <TableCell align="left" className='new-table-cell'>{fDate(created_at, userData.local_code)}</TableCell>
                                                <TableCell align="right" className='new-table-cell'> {isAllowedManageLoginPolicies && <EditIcon onClick={() => navigate(`editPolicy/${policy.id}`, { state: policy })} className='new-delete-icon actions-btn' data-testid="editIcon" />}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        }
                        {!isDesktop &&
                            <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
                                {loginPolicies && loginPolicies.map((policy, index) => (
                                    <Grid item xs={12} sm={4} key={index}>
                                        <Box className="responsive-card-details">
                                            <Typography className='card-resp-part'>{policy.title}</Typography>
                                            <Typography className='card-resp-part'>{t(policy.type)}</Typography>
                                            <Typography className='card-resp-part'>{policy.type === 'specific' ? policy.countryName : 'none'}</Typography>
                                            <Typography className='card-resp-part'>{t(policy.language)}</Typography>
                                            <Box className='card-actions'>
                                                {isAllowedManageLoginPolicies && <EditIcon onClick={() => navigate(`editPolicy/${policy.id}`, { state: policy })} className='new-delete-icon actions-btn' data-testid="editIcon" />}
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        }
                    </InfiniteScroll>
                </Scrollbar>
            }
        </Page>
    );
};
