/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Stack, CircularProgress, Box, Button, Card, CardContent, FormControl, RadioGroup, Radio, Divider, Grid, FormControlLabel, Alert, TextField, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemAvatar, Avatar, Checkbox, ListItemText } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { EditMeetingDialoge } from "./EditMeetingDialoge";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import timezone from 'dayjs/plugin/timezone';
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import '../../css/meetings/Meeting.css';
import utc from 'dayjs-plugin-utc';
import Page from "../Page";
import axios from 'axios';
import dayjs from 'dayjs';
import * as Yup from 'yup';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

export const EditMeeting = () => {

    const currentInstitution = useSelector(state => state.currentInstitution);

    const navigate = useNavigate();
    const { t } = useTranslation();
    dayjs.extend(timezone);
    dayjs.extend(utc);
    const daysOfWeek = [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
    ];

    const { id } = useParams()
    const [meeting, setMeeting] = useState([])
    //fetch main meeting to get MeetingsRecurrence in case the meeting is sub main meeting
    const [MainMeeting, setMainMeeting] = useState([])

    const [meetingReceivers, setMeetingReceivers] = useState([])
    const [meetingReceiversCopy, setmeetingReceiversCopy] = useState([])
    const [institutionUsers, setInstitutionUsers] = useState([]);
    const [meetingTitle, setMeetingTitle] = useState("");
    const [search, setSearch] = useState("");
    const [date, setdate] = useState("");
    const [fromHourLocally, setFromHourLocally] = useState(null);
    const [toHourLocally, setToHourLocally] = useState(null);
    const [fromHourInternational, setFromHourInternational] = useState("");
    const [toHourInternational, setToHourInternational] = useState("");
    const [error, setError] = useState('');
    const [validTime, setValidTime] = useState(false);
    const [loading, setLoading] = useState(true);
    const [OpenEditDialoge, setOpenEditDialoge] = useState(false);
    const [RepetitiveType, setRepetitiveType] = useState("");
    const [choosenDayOfWeek, setChoosenDayOfWeek] = useState([]);
    const [choosenDayOfMonth, setChoosenDayOfMonth] = useState([]);
    const [RepetitiveTypeCopy, setRepetitiveTypeCopy] = useState("");
    const [choosenDayOfWeekCopy, setChoosenDayOfWeekCopy] = useState([]);
    const [choosenDayOfMonthCopy, setChoosenDayOfMonthCopy] = useState([]);

    //yup validation for field title
    const validationTitle = Yup.object().shape({
        meetingTitle: Yup.string().trim().required(),
    });

    //check state validation for field title
    let ValidkMeetindTitle = validationTitle.isValidSync({ meetingTitle });

    function convertUtcToLocalTime(utcTime) {
        if (!utcTime) {
            return null; // return null for a Date object
        }
        const [utcHours, utcMinutes] = utcTime.split(':').map(Number);
        const utcDate = new Date();
        utcDate.setUTCHours(utcHours);
        utcDate.setUTCMinutes(utcMinutes);

        return utcDate; // return Date object
    }

    useEffect(() => {
        if (currentInstitution) {
            axios.get(`meetings/${id}`).then((response) => {
                if (response.status === 201 || response.status === 200) {
                    const localFromTime = convertUtcToLocalTime(response.data.fromHour);
                    const localToTime = convertUtcToLocalTime(response.data.toHour);
                    if (response.data.label === 'SUBMAIN_MEETING') {
                        FetchMainMeeting(response.data.subMeetingFor)
                    }
                    setMeeting(response.data)
                    setMeetingTitle(response.data.title)
                    setFromHourInternational(response.data.fromHour)
                    setToHourInternational(response.data.toHour)
                    setFromHourLocally(localFromTime)
                    setToHourLocally(localToTime)
                    setdate(dayjs(response.data.date))
                    setMeetingReceivers(response.data.receivers)
                    const NeededReceiverData = response.data.receivers.map((receiver) => ({
                        id: receiver.institutionUser.id,
                        institutionName: receiver.institutionUser.institution.name,
                        institutionId: receiver.institutionUser.institutionId,
                        userId: receiver.institutionUser.userId,
                    }));
                    setmeetingReceiversCopy(NeededReceiverData)

                    setLoading(false)
                }
            })
                .catch((err) => console.log(err));
        }
    }, [id, currentInstitution]);

    const FetchMainMeeting = (MainMeetingId) => {
        axios.get(`meetings/${MainMeetingId}`).then((response) => {
            if (response.status === 201 || response.status === 200) {
                setMainMeeting(response.data)
                setRepetitiveType(response.data.MeetingsRecurrence.type)
                setChoosenDayOfWeek(response.data.MeetingsRecurrence.weekdays)
                setChoosenDayOfMonth(response.data.MeetingsRecurrence.monthdays)
                setRepetitiveTypeCopy(response.data.MeetingsRecurrence.type)
                setChoosenDayOfWeekCopy(response.data.MeetingsRecurrence.weekdays)
                setChoosenDayOfMonthCopy(response.data.MeetingsRecurrence.monthdays)
            }
        })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (currentInstitution) {
            axios.get(`institutions-users/forVideoCall`)
                .then((response) => setInstitutionUsers(response.data))
                .catch((err) => console.log(err));
        }
    }, [currentInstitution]);

    useEffect(() => {

        const fromDate = dayjs.utc(`1970-01-01T${fromHourInternational}`);
        const toDate = dayjs.utc(`1970-01-01T${toHourInternational}`);

        if (toDate.isBefore(fromDate) || toDate.isSame(fromDate)) {
            setValidTime(false)
        }
        else {
            setValidTime(true);
        }

    }, [fromHourInternational, toHourInternational]);

    const toggleSelectUser = (institutionUser) => {
        const selectedinstitutionUserId = institutionUser.id;
        const institutionUserData = {
            id: institutionUser.id,
            institutionId: institutionUser.institutionId,
            institutionName: institutionUser.institution.name,
            userId: institutionUser.userId,
        };

        setmeetingReceiversCopy((prevSelectedUsers) => {
            const isExistingInstitutionUser = prevSelectedUsers.some((existingInstitutionUser) => existingInstitutionUser.id === selectedinstitutionUserId);

            if (isExistingInstitutionUser) {
                return prevSelectedUsers.filter((existingInstitutionUser) => existingInstitutionUser.id !== selectedinstitutionUserId);
            } else {
                return [...prevSelectedUsers, institutionUserData];
            }
        });
    };

    const changeRepetitiveType = (newType) => {
        setRepetitiveTypeCopy(newType)
        setChoosenDayOfWeekCopy([])
        setChoosenDayOfMonthCopy([])
    }

    const toggleSelectWeekDay = (day) => {

        setChoosenDayOfWeekCopy((prevSelectedWeekDays) => {
            const isExistingDay = choosenDayOfWeekCopy.includes(day)
            if (isExistingDay) {
                return choosenDayOfWeekCopy.filter((Arrayday) => Arrayday !== day);
            } else {
                return [...prevSelectedWeekDays, day];
            }
        });

    };

    const toggleSelectMonthDay = (day) => {

        setChoosenDayOfMonthCopy((prevSelectedMonthkDays) => {
            const isExistingDay = choosenDayOfMonthCopy.includes(day)
            if (isExistingDay) {
                return choosenDayOfMonthCopy.filter((Arrayday) => Arrayday !== day);
            } else {
                return [...prevSelectedMonthkDays, day];
            }
        });

    };

    const handleOpenEditDialoge = () => {
        // check if end date < start date

        if (
            !ValidkMeetindTitle ||
            !validTime ||
            fromHourInternational === "Invalid Date" ||
            toHourInternational === "Invalid Date" ||
            (RepetitiveTypeCopy === 'EVERY_WEEK' && choosenDayOfWeekCopy.length === 0) ||
            (RepetitiveTypeCopy === 'EVERY_MONTH' && choosenDayOfMonthCopy.length === 0)
        ) {
            setError("videoRequestDateError");
            return;
        }
        else {
            setOpenEditDialoge(true)
        }
    }

    const handleChangeFromHour = (fromHour) => {
        const internationalFromTime = dayjs(fromHour).utc().format('HH:mm');
        setFromHourInternational(internationalFromTime)
    }

    const handleChangeToHour = (toHour) => {
        const internationalToTime = dayjs(toHour).utc().format('HH:mm');
        setToHourInternational(internationalToTime)
    }

    return (
        <Page title={t("Edit_Meeting_Page_Title")}>
            {loading ?
                <Box className='circleLoading'>
                    <CircularProgress size="5rem" />
                </Box> :
                <Box>
                    <Stack className='pages-header'>
                        <Box className='onefile-header-title'>
                            <ArrowBackIosIcon className='new-delete-icon actions-btn' onClick={() => navigate(-1)} />
                            <Typography className='onefile_title'>{t("edit_meeting")}</Typography>
                        </Box>
                        <Box display={'flex'} gap={'5px'}>
                            <Button className='new-button' onClick={() => navigate(-1)} >{t('cancel')}</Button>
                            <Button className="new-button" onClick={() => handleOpenEditDialoge()}>{t('agree')}</Button>
                        </Box>
                    </Stack>

                    <Card>
                        <CardContent>

                            {error && <Alert sx={{ marginTop: '15px' }} severity="error">{t(error)}</Alert>}

                            <TextField
                                margin="dense"
                                id="meetingTitle"
                                name="meetingTitle"
                                label={t("Title")}
                                value={meetingTitle}
                                type="text"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setMeetingTitle(e.target.value)}
                            />

                            {meeting.type === "Meeting" &&
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        sx={{ width: '100%', marginTop: '10px' }}
                                        label={t("date")}
                                        value={date}
                                        disablePast
                                        onChange={setdate}
                                    />
                                </LocalizationProvider>
                            }

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label={t("from")}
                                    sx={{ width: '100%', marginTop: '10px' }}
                                    value={dayjs(fromHourLocally)}
                                    onChange={(fromHour) => handleChangeFromHour(fromHour)}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label={t("to")}
                                    sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                                    value={dayjs(toHourLocally)}
                                    onChange={(toHour) => handleChangeToHour(toHour)}

                                />
                            </LocalizationProvider>

                            {meeting.type === 'Repetitive_Meeting' &&
                                <FormControl sx={{ marginTop: "10px" }}>
                                    <RadioGroup row value={RepetitiveTypeCopy} onChange={(e) => changeRepetitiveType(e.target.value)}>
                                        <FormControlLabel value="EVERYDAY" control={<Radio />} label={t("EVERYDAY")} />
                                        <FormControlLabel value="EVERY_WEEK" control={<Radio />} label={t("EVERY_WEEK")} />
                                        <FormControlLabel value="EVERY_MONTH" control={<Radio />} label={t("EVERY_MONTH")} />
                                    </RadioGroup>
                                </FormControl>
                            }

                            {(meeting.type === 'Repetitive_Meeting' && RepetitiveTypeCopy === "EVERY_WEEK") && (
                                <Box sx={{ marginTop: "10px" }}>
                                    <Divider />
                                    <Grid container spacing={2} >
                                        {daysOfWeek.map((day) => (
                                            <Grid key={day} item xs={6}  >
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Checkbox
                                                        value={day}
                                                        checked={choosenDayOfWeekCopy.includes(day)}
                                                        onChange={() => toggleSelectWeekDay(day)}
                                                    />
                                                    <Typography>{t(day.toLowerCase())}</Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Divider />
                                </Box>
                            )}

                            {(meeting.type === 'Repetitive_Meeting' && RepetitiveTypeCopy === "EVERY_MONTH") && (
                                <Box>
                                    <Divider />
                                    <Grid container spacing={2} >
                                        {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
                                            <Grid item xs={3} key={day}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Checkbox
                                                        value={day}
                                                        checked={choosenDayOfMonthCopy.includes(day)}
                                                        onChange={() => toggleSelectMonthDay(day)}
                                                    />
                                                    <Typography>{day}</Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Divider />
                                </Box>
                            )}

                            <Accordion sx={{ marginTop: '10px' }}>
                                <AccordionSummary className="editMeetingAddReceiver" expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                                    <Typography fontWeight={800}>{t("receiver")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField
                                        sx={{ marginTop: '7px' }}
                                        variant="filled"
                                        fullWidth
                                        id="videoCallSearch"
                                        label={t('search')}
                                        type="text"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />

                                    {institutionUsers && institutionUsers
                                        .filter((institutionUser) => {
                                            const fullName = `${institutionUser.user?.firstname} ${institutionUser.user?.lastname}`.toLowerCase();
                                            return fullName.includes(search.toLowerCase());
                                        })
                                        .map((institutionUser) => (
                                            <List key={institutionUsers.id} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar src={institutionUser.user?.profile?.image} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={`${institutionUser.user?.firstname} ${institutionUser.user?.lastname}`} secondary={institutionUser.institution?.name} />
                                                    <Checkbox
                                                        value={institutionUser.id}
                                                        checked={meetingReceiversCopy.some((existingInstitutionUser) => existingInstitutionUser.id === institutionUser.id)}
                                                        onChange={() => toggleSelectUser(institutionUser)}

                                                    />
                                                </ListItem>
                                            </List>
                                        ))}
                                </AccordionDetails>
                            </Accordion>


                        </CardContent>
                    </Card>
                </Box>
            }

            {OpenEditDialoge &&
                <EditMeetingDialoge
                    OpenEditDialoge={OpenEditDialoge}
                    setOpenEditDialoge={setOpenEditDialoge}
                    meetingReceiversCopy={meetingReceiversCopy}
                    meetingReceivers={meetingReceivers}
                    meetingTitle={meetingTitle}
                    date={date}
                    fromHourInternational={fromHourInternational}
                    toHourInternational={toHourInternational}
                    MainMeeting={MainMeeting}
                    meeting={meeting}
                    RepetitiveType={RepetitiveType}
                    choosenDayOfWeek={choosenDayOfWeek}
                    choosenDayOfMonth={choosenDayOfMonth}
                    RepetitiveTypeCopy={RepetitiveTypeCopy}
                    choosenDayOfWeekCopy={choosenDayOfWeekCopy}
                    choosenDayOfMonthCopy={choosenDayOfMonthCopy}
                />
            }

        </Page >
    );
}