import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export const FileNotFound = ({ isFileNotFoundDialogOpen, setFileNotFoundDialogOpen }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Dialog open={isFileNotFoundDialogOpen} keepMounted onClose={() => setFileNotFoundDialogOpen(false)}>
            <DialogTitle className="imeahSubTitle">{t('File_not_found_dialog_title')}</DialogTitle>
            <DialogContent className="imeahCaption">{t('File_not_found_dialog_content')}</DialogContent>
            <DialogActions>
                <Button fullWidth className='buttonFilled' onClick={() => navigate('/dashboard/medicalFiles')}>{t('ok')}</Button>
            </DialogActions>
        </Dialog>
    );
};