import { Dialog, DialogActions, DialogTitle, Button, Alert, Box } from "@mui/material";
import React, { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import axios from "axios";

export const DeleteUser = ({ IdUserForDelete, users, setUsers, openDelete, setOpenDelete }) => {

    const { t } = useTranslation();
    const [msg, setMsg] = useState('');
    const currentInstitution = useSelector(state => state.currentInstitution);

    const deleteUser = useCallback(() => {
        if (currentInstitution) {
            axios.delete(`users/${IdUserForDelete}`)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        const newData = users.filter((item) => item.id !== IdUserForDelete);
                        setUsers(newData);
                        setOpenDelete(false);
                    }
                })
                .catch((err) => {
                    console.error(err.message);
                    setMsg(err.message);
                });
        }
    }, [currentInstitution, IdUserForDelete, users, setUsers, setOpenDelete]);

    return (
        <Dialog open={openDelete} keepMounted>
            {msg ? <DialogTitle>{t("canNotRemove")}</DialogTitle> : <DialogTitle>{t("sureDeleteUser")}</DialogTitle>}
            {msg && <Alert severity="error">{msg}</Alert>}
            <DialogActions>
                {msg.length > 0 ?
                    <Button className="new-button" onClick={() => setOpenDelete(false)}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button className="new-button" onClick={() => setOpenDelete(false)}>{t("cancel")}</Button>
                        <Button className="new-button" onClick={deleteUser}>{t("dlt")}</Button>
                    </Box>
                }
            </DialogActions>
        </Dialog >
    )
}