import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useEffect, useCallback } from 'react';
import { CurrentPolicy } from '../../LoginForm/currentPolicy';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const MedicalFileByEmail = () => {

    const navigate = useNavigate();
    const { id1, id2 } = useParams();

    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);

    const [loading, setLoading] = useState(true);
    const [authData, setAuthData] = useState({
        token: localStorage.getItem('token') || "",
        refreshToken: localStorage.getItem('refreshToken') || "",
        selectedInstitutionId: "",
        requireVerificationCode: false,
        requireLoginPolicies: false,
        requireSelectInstitution: false,
    });

    const updateAuthData = useCallback((newData) => {
        setAuthData((prev) => ({ ...prev, ...newData }));
    }, []);

    const storeAuthData = useCallback((token, refreshToken) => {
        try {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            window.dispatchEvent(new Event("storage"));
            navigate(`/dashboard/medicalFiles/${id2}`);
        } catch (error) {
            CustomErrorLogger(error, 'Error storing auth data in medical file by email');
            console.error("Storage Error:", error.message);
        }
    }, [id2, navigate]);

    const handleChangeInstitution = useCallback(async (institutionId) => {
        if (!institutionId) return;
        try {
            const { data, status } = await axios.post("/auth/refresh",
                { institutionId, fromChangeInstitution: true },
                { headers: { Authorization: `Bearer ${authData.refreshToken}` } }
            );

            if (status === 200 || status === 201) {

                const { accessToken, refreshToken, requireLoginPolicies } = data;

                if (requireLoginPolicies) {
                    // Show login policies dialog
                    updateAuthData({
                        token: accessToken,
                        refreshToken: refreshToken,
                        requireLoginPolicies: true,
                        selectedInstitutionId: institutionId,
                    });
                } else {
                    // All steps completed, store token in localStorage and navigate
                    storeAuthData(accessToken, refreshToken);
                }
            }

        } catch (err) {
            console.error('Error changing institution:', err);
            CustomErrorLogger(err, 'Error changing institution in medical file by email');
            navigate('/login');
        } finally {
            setLoading(false);
        }
    }, [authData.refreshToken, navigate, storeAuthData, updateAuthData]);

    const getInstitutionUser = useCallback(async () => {
        if (!userData.id) return;
        await axios.get(`/institutions-users/${id1}`)
            .then((response) => {
                if (userData.id !== response?.data?.data?.userId) {
                    navigate('/login');
                    return
                }
                if (currentInstitution?.institution?.id === response?.data?.data?.institutionId && currentInstitution.id === response?.data?.data?.id) {
                    navigate(`/dashboard/medicalFiles/${id2}`);
                    return
                }
                handleChangeInstitution(response?.data?.data?.institutionId)
            })
    }, [id1, id2, currentInstitution?.institution?.id, currentInstitution?.id, userData.id, navigate, handleChangeInstitution])

    useEffect(() => {
        getInstitutionUser();
    }, [getInstitutionUser]);

    return (
        <Box className='edit-temp-loading'>
            {loading ? (
                <CircularProgress size="5rem" />
            ) : (
                authData.requireLoginPolicies && (
                    <CurrentPolicy
                        key="directFile-login-current-policy"
                        authData={authData}
                        updateAuthData={updateAuthData}
                        storeAuthData={storeAuthData}
                    />
                )
            )}
        </Box>
    )
}