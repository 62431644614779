import { Table, TableHead, TableRow, TableBody, TableCell, Box, MenuItem, FormControl, InputLabel, Select, LinearProgress, InputAdornment, Typography, Card, Grid, Divider, useMediaQuery } from '@mui/material';
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { customInputStyles } from '../../utils/CustomInputStyle';
import InfiniteScroll from 'react-infinite-scroll-component';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { act } from '@testing-library/react';
import { useSelector } from 'react-redux';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import axios from "axios";

const FilterTag = React.memo(({ label, onDelete }) => (
    <Box className='main-page-filter-flag-item'>
        <Typography>{label}</Typography>
        <Iconify icon="mdi:close" onClick={onDelete} />
    </Box>
));

const ActionButton = React.memo(({ icon, title, onClick }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
        <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
    </Box>
));

export const InstitutionUsersCredentiels = ({ openFilter, setOpenFilter, search, setSearch }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userData = useSelector(state => state.userData);

    const [loading, setLoading] = useState(true);
    const [institutionUsers, setInstitutionUsers] = useState([]);
    const [selectedExpretise, setSelectedExpretise] = useState("");
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const [expertisesForUsrsList, setExpertisesForUsersList] = useState([]);
    const [specialitiesForUsersList, setSpecialitiesForUsersList] = useState([]);

    const hasMore = useRef(true);
    const offset = useRef(0);
    const limit = 20;
    const [filter, setFilter] = useState({
        institutionId: "",
        specialityId: "",
        expertiseId: "",
        search,
    });

    const hasFilters = useMemo(() => (
        selectedSpeciality || selectedExpretise
    ), [selectedSpeciality, selectedExpretise]);

    const fetchInstitutionUsers = useCallback(() => {
        const currentOffset = offset.current;
        axios.get(`institutions-users/findAll`, { params: { filter, limit, offset: currentOffset } })
            .then((response) => {
                act(() => {
                    setLoading(false);
                    setInstitutionUsers(prev => currentOffset === 0 ? response.data : [...prev, ...response.data]);
                    offset.current = currentOffset + limit;
                    hasMore.current = response.data.length === limit;
                });
            })
            .catch((err) => { console.log(err); });
    }, [filter]);

    const fetchFiltrationArrays = useCallback(() => {
        axios.get('institutions-users/getFiltrationArrays')
            .then((response) => {
                setSpecialitiesForUsersList(response.data.specialityArray);
                setExpertisesForUsersList(response.data.expertiseArray);
            })
            .catch((err) => {
                console.error('Error fetching filtration arrays:', err);
            });
    }, []);

    // Trigger filter reset when user data changes
    useEffect(() => {
        if (userData.local_code) {
            setInstitutionUsers([]);
            offset.current = 0;
            hasMore.current = true;
        }
    }, [userData.local_code]);

    useEffect(() => {
        fetchInstitutionUsers();
    }, [filter, userData.local_code, fetchInstitutionUsers]);

    useEffect(() => {
        fetchFiltrationArrays();
    }, [fetchFiltrationArrays, userData.local_code]);

    const handleChangeExpertise = useCallback((expertise) => {
        setSelectedSpeciality("");
        setSelectedExpretise(expertise);
        setFilter((prev) => ({ ...prev, expertiseId: expertise === "" ? "" : expertise.id, specialityId: "" }));
        setInstitutionUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const handleChangeSpeciality = useCallback((speciality) => {
        setSelectedExpretise("");
        setSelectedSpeciality(speciality);
        setFilter((prev) => ({ ...prev, specialityId: speciality === "" ? "" : speciality.id, expertiseId: "" }));
        setInstitutionUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const emptyFiltrationObject = useCallback(() => {
        setFilter({
            specialityId: "",
            expertiseId: "",
            search: ""
        });
        setSearch("");
        setSelectedExpretise("");
        setSelectedSpeciality("");
        setInstitutionUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, [setSearch]);

    useEffect(() => {
        setFilter((prev) => ({ ...prev, search }));
        setInstitutionUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, [search]);

    return (
        <Box>
            {loading ?
                <LinearProgress /> :
                <Box>
                    {openFilter &&
                        <Box sx={{ marginTop: '5px', marginBottom: '5px' }}>
                            <Card sx={{ padding: '7px' }} >
                                <Box display={'flex'} justifyContent={'space-between'} color={'#17536B'} >
                                    <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>{t("Filter")}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={emptyFiltrationObject} />
                                        <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                                    </Box>
                                </Box>
                                <Divider />
                                <Grid container spacing={1} marginTop={'5px'}>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth sx={{ ...customInputStyles }} >
                                            <InputLabel id="demo-simple-select-label-speciality">{t("speciality")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label-speciality"
                                                id="selectedSpeciality"
                                                label={t("speciality")}
                                                name="selectedSpeciality"
                                                value={selectedSpeciality}
                                                onChange={(e) => handleChangeSpeciality(e.target.value)}
                                                endAdornment={
                                                    selectedSpeciality && (
                                                        <InputAdornment position="start">
                                                            <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeSpeciality("")} />
                                                        </InputAdornment>
                                                    )
                                                }
                                            >
                                                {specialitiesForUsersList && specialitiesForUsersList.map((Speciality) => (
                                                    <MenuItem key={Speciality.id} value={Speciality}>{Speciality.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth sx={{ ...customInputStyles }} >
                                            <InputLabel id="demo-simple-select-label-expertise">{t("expertise")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label-expertise"
                                                id="selectedExpretise"
                                                label={t("expertise")}
                                                name="selectedExpretise"
                                                value={selectedExpretise}
                                                onChange={(e) => handleChangeExpertise(e.target.value)}
                                                endAdornment={
                                                    selectedExpretise && (
                                                        <InputAdornment position="start">
                                                            <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeExpertise("")} />
                                                        </InputAdornment>
                                                    )
                                                }
                                            >
                                                {expertisesForUsrsList && expertisesForUsrsList.map((expertise) => (
                                                    <MenuItem key={expertise.id} value={expertise}>{expertise.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>}

                    {(!openFilter && hasFilters) &&
                        <Box className='filter-flag-box'>
                            {selectedSpeciality && <FilterTag label={selectedSpeciality?.label} onDelete={() => handleChangeSpeciality('')} />}
                            {selectedExpretise && <FilterTag label={selectedExpretise?.label} onDelete={() => handleChangeExpertise('')} />}
                            <Typography className="clear-filter-flag" onClick={emptyFiltrationObject}>{t("clear_all_filter")}</Typography>
                        </Box>
                    }

                    <Scrollbar>
                        <InfiniteScroll
                            dataLength={institutionUsers.length}
                            next={fetchInstitutionUsers}
                            hasMore={hasMore.current}
                            loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}>
                            {isDesktop &&
                                <Table>
                                    <TableHead>
                                        <TableRow className='table-row'>
                                            <TableCell className='table-titles'>{t('fullName')}</TableCell>
                                            <TableCell className='table-titles'>{t('email')}</TableCell>
                                            <TableCell className='table-titles'>{t('role')}</TableCell>
                                            <TableCell className='table-titles'></TableCell>
                                            <TableCell className='table-titles'></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {institutionUsers && institutionUsers.map((institutionUser, index) => (
                                            <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                                                <TableCell className="new-table-cell imeah_persone_name">
                                                    {institutionUser.user?.firstname.toLowerCase()} {institutionUser.user?.lastname.toLowerCase()}
                                                </TableCell>
                                                <TableCell className="new-table-cell">{institutionUser.user?.email}</TableCell>
                                                <TableCell className="new-table-cell">{t(institutionUser.role?.roleLabel[0]?.label)}</TableCell>
                                                <TableCell className="new-table-cell">
                                                    <AccountBoxIcon className='new-delete-icon actions-btn' onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: institutionUser.id, userId: institutionUser.user.id } })} />
                                                </TableCell>
                                                <TableCell className="new-table-cell">
                                                    <EditIcon className='new-delete-icon actions-btn'
                                                        onClick={() => navigate('/dashboard/credentielsSet/manageUserCredentiels', {
                                                            state: {
                                                                institutionId: institutionUser?.institution?.id,
                                                                institutionUserId: institutionUser?.id
                                                            }
                                                        })}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            }

                            {!isDesktop &&
                                <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
                                    {institutionUsers && institutionUsers.map((institutionUser, index) => (
                                        <Grid item xs={12} sm={4} key={index}  >
                                            <Box key={index} className="responsive-card-details">
                                                <Typography className='card-resp-part imeah_persone_name'>{institutionUser?.user?.firstname.toLowerCase()} {institutionUser?.user?.lastname.toLowerCase()}</Typography>
                                                <Typography className='card-resp-part'>{t(institutionUser?.role?.label_key)}</Typography>
                                                <Box className='card-actions'>
                                                    <AccountBoxIcon className='new-delete-icon actions-btn' onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: institutionUser.id, userId: institutionUser.user.id } })} />
                                                    <EditIcon className='new-delete-icon actions-btn'
                                                        onClick={() => navigate('/dashboard/credentielsSet/manageUserCredentiels', {
                                                            state: {
                                                                institutionId: institutionUser?.institution?.id,
                                                                institutionUserId: institutionUser?.id
                                                            }
                                                        })}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            }

                        </InfiniteScroll>
                    </Scrollbar>
                </Box>
            }
        </Box>

    );
};