import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Switch, FormControlLabel, Card } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";
import React from 'react';

export const UserProfileCredentials = ({ rolePermissins, customPermissions }) => {

    const { t } = useTranslation();

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%', padding: '2%', marginTop: '25px' }}>
            {rolePermissins?.length > 0 &&
                <Box>
                    <Typography sx={{ color: '#34697E', fontSize: '22px', fontWeight: 'bold' }}>{t('rolePermissions')}</Typography>

                    {rolePermissins && rolePermissins.map(entity => (
                        entity.permissions?.length > 0 && (
                            <Accordion key={entity.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0, margin: 0 }}>
                                    <Box>
                                        <Typography variant="h6" component="div">{entity.entityName}</Typography>
                                        <Typography variant="caption" component="div" color={"#949494"}>{entity.entityDescription}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                                    {entity?.permissions?.map(permission => (
                                        <Box key={permission.id} marginTop={2} marginBottom={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Box>
                                                <Typography variant="body2" component="div">{permission?.PermissionName[0]?.label}</Typography>
                                                <Typography variant="caption" component="div" color={"#949494"}>{permission?.PermissionDescription[0]?.label}</Typography>
                                            </Box>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        disabled={true}
                                                        checked={true}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        )))}
                </Box>
            }

            {customPermissions?.length > 0 &&
                <Box>
                    <Typography sx={{ color: '#34697E', fontSize: '22px', fontWeight: 'bold' }}>{t('customPermissions')}</Typography>

                    {customPermissions && customPermissions.map(entity => (
                        entity?.permissions?.length > 0 && (
                            <Accordion key={entity.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0, margin: 0 }}>
                                    <Box>
                                        <Typography variant="h6" component="div">{entity.entityName}</Typography>
                                        <Typography variant="caption" component="div" color={"#949494"}>{entity.entityDescription}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0, margin: 0 }}>

                                    {entity?.permissions?.map(permission => (
                                        <Box key={permission.id} marginTop={2} marginBottom={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Box>
                                                <Typography variant="body2" component="div">{permission?.PermissionName[0]?.label}</Typography>
                                                <Typography variant="caption" component="div" color={"#949494"}>{permission?.PermissionDescription[0]?.label}</Typography>
                                            </Box>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        disabled={true}
                                                        checked={true}
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        )))}
                </Box>
            }
        </Card>
    );
}