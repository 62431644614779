import { Button, Dialog, DialogContent, DialogActions, Typography, Checkbox, Box, CircularProgress, Menu, MenuItem } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { CustomErrorLogger } from '../../utils/CustomErrorLogger';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18next from "i18next";
import axios from 'axios';
import './currentPolicy.css';

// Component to display and handle the current policy agreement
export const CurrentPolicy = ({ authData, updateAuthData, storeAuthData, isInternal = false }) => {

    const { t } = useTranslation();

    // Extract authentication-related data from props
    const { selectedInstitutionId, token, refreshToken, requireLoginPolicies } = authData;
    // Get current institution ID from Redux store
    const currentInstitutionId = useSelector(state => state?.currentInstitution?.institution?.id || null);
    // State variables for language selection, policy data, loading state, etc.
    const [selectedLanguage, setSelectedLanguage] = useState(i18next.language || 'en');
    const [currentPolicy, setCurrentPolicy] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [policyChecked, setPolicyChecked] = useState(false);
    const [loading, setLoading] = useState(true);

    // Fetch the current policy from the backend
    const fetchPolicy = useCallback(async () => {
        try {
            const { data, status } = await axios.get('policies/currentPolicy', {
                params: {
                    language: selectedLanguage,
                    type: 'generalAccessPolicy',
                    institutionId: selectedInstitutionId
                }
            });
            if (status === 200 || status === 201) {
                setLanguages(data?.availableLanguages || []);
                setCurrentPolicy(data?.policyWithSameVersionAndPecificLanguage || {});
            }
        } catch (error) {
            console.error('Error fetching policy:', error);
            CustomErrorLogger(error, 'Error fetching policy');
        } finally {
            setLoading(false);
        }
    }, [selectedLanguage, selectedInstitutionId]);

    // Fetch policy data whenever selected language or institution ID changes
    useEffect(() => {
        fetchPolicy();
    }, [fetchPolicy]);

    // Handle the agreement action when the user accepts the policy
    const handleAgree = useCallback(async () => {
        if (!currentPolicy) return;
        try {
            const { status } = await axios.post('auth/acceptLoginPolicies',
                { loginPolicyId: currentPolicy.id },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (status === 200 || status === 201) {
                updateAuthData({
                    requireLoginPolicies: false,
                    requireVerificationCode: false,
                    requireSelectInstitution: false,
                });
                storeAuthData(token, refreshToken);
            }
        } catch (error) {
            console.error('Error accepting policy:', error);
            CustomErrorLogger(error, 'Error accepting policy');
        }
    }, [currentPolicy, refreshToken, storeAuthData, token, updateAuthData]);

    // Refresh the token if the user disagrees and wants to stay in the same institution
    const refreshTokenHandler = useCallback(async () => {
        if (!currentInstitutionId) return;

        try {
            const { data, status } = await axios.post("/auth/refresh",
                { institutionId: currentInstitutionId, fromChangeInstitution: true },
                { headers: { Authorization: `Bearer ${refreshToken}` } }
            );

            if (status === 200 || status === 201) {

                const { accessToken, refreshToken } = data;
                // All steps completed, store token in localStorage and navigate
                storeAuthData(accessToken, refreshToken);
            }
        } catch (error) {
            console.error("Error refreching the token when disagree policy :", error.message);
            CustomErrorLogger(error, "Error refreching the token when disagree policy");
            localStorage.clear();
            window.location.href = "/login";
        }

    }, [currentInstitutionId, refreshToken, storeAuthData]);

    // Memoized language selection menu
    const languageMenu = useMemo(() => {
        if (languages.length <= 1) return null;
        return (
            <PopupState variant="popover" popupId="language-menu">
                {(popupState) => (
                    <>
                        <LanguageIcon
                            className="home-navbar-login"
                            sx={{ alignSelf: 'flex-end', cursor: 'pointer' }}
                            {...bindTrigger(popupState)}
                        />
                        <Menu {...bindMenu(popupState)}>
                            {languages?.map((language) => (
                                <MenuItem
                                    key={language}
                                    onClick={() => setSelectedLanguage(language)}
                                >
                                    {t(language)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )}
            </PopupState>
        );
    }, [languages, t]);

    return (
        <Dialog fullWidth open={requireLoginPolicies} className="policy-dialog">
            {loading ? (
                <DialogContent>
                    {/* Show loading spinner while fetching policy data */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            ) : (
                <DialogContent>
                    {/* Language selection menu */}
                    {languageMenu}

                    {/* Display policy content */}
                    {currentPolicy?.description?.map((desc, index) => (
                        <Box key={index} className="policy-section">
                            <Typography variant="h6" className="subTitle" sx={{ whiteSpace: 'pre-wrap' }}>{desc?.descTitle}</Typography>
                            <Typography sx={{ whiteSpace: 'pre-wrap' }}>{desc?.descSubTitle}</Typography>
                        </Box>
                    ))}

                    {/* Checkbox to agree to policy */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox className='imeahCheckbox' checked={policyChecked} onChange={() => setPolicyChecked(!policyChecked)} />
                        <Typography className="policy-text">{t('agreeToPolicy')}</Typography>
                    </Box>
                </DialogContent>
            )}

            {/* Dialog actions: Cancel (if internal) and Agree button */}
            <DialogActions className="dialog-actions">
                {isInternal && (<Button className='buttonOutlined' onClick={refreshTokenHandler}>{t('cancel')}</Button>)}
                <Button className="buttonFilled" disabled={!policyChecked || loading} onClick={handleAgree}>{t('agree')} </Button>
            </DialogActions>
        </Dialog>
    );
};