/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Stack, Button, TextField, Typography, FormControl, FormHelperText, RadioGroup, FormControlLabel, Radio, Tooltip, InputLabel, Select, MenuItem, Checkbox, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import { CustomErrorLogger } from '../../utils/CustomErrorLogger';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { HasPermission } from '../../utils/checkUserPermission';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState, useEffect, useRef } from 'react';
import { MuiSelect } from '../../utils/muiSelect';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Page from "../Page";
import axios from 'axios';
import Iconify from '../Iconify';
import '../../css/templates/createTemplate.css';

export const ImportTemplate = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);

    const hasGridBeenRendered = useRef(false);

    //user role check 
    const { specialities, institutionUserExpertise } = currentInstitution || {};

    const isSpecialist = Boolean(specialities?.id && specialities?.id !== 'NoSpeciality' && specialities?.id != null);
    const isExpert = Array.isArray(institutionUserExpertise) && institutionUserExpertise?.length > 0;

    //check user permissions
    const isAllowedCreateDefaultTemplate = HasPermission('create_default_template');
    const isAllowedCreatePublicTemplateWithMySpecialityOrExpertise = HasPermission('create_public_template_with_MySpecialityOrExpertise');
    const isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise = HasPermission('create_public_template_with_customSpecialityOrExpertise');
    const isAllowedCreateTemplateForMyInstitutionUsers = HasPermission('create_private_template_ForMyInstitutionUser');
    const isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise = HasPermission('create_private_template_with_MySpecialityOrExpertise');
    const isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise = HasPermission('create_private_template_with_customSpecialityOrExpertise');
    //initialise pricipal states
    const [templateName, setTemplateName] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [templateType, setTemplateType] = useState('');
    const [templateSpeciality, setTemplateSpeciality] = useState('NoSpeciality');
    const [templateExpertise, setTemplateExpertise] = useState('NoExpertise');
    const [zipFile, setZipFile] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    //this state is for expert that have more than 1 expertise and no need to fetch data using custom select
    const [expertiseOptions, setExpertiseOptions] = useState([]);
    //initialise secondary states
    const [targetDoctor, setTargetDoctor] = useState([]);
    const [owners, setOwners] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedExpertise, setSelectedExpertise] = useState('');

    //pagination state
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const limit = 10; // Fetch 10 users at a time

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required(t("templateNameRequired")),
        description: Yup.string().trim().required(t("templateDescriptionRequired")),
        specialityId: Yup.string().trim()
            .when('Type', {
                is: 'default',
                then: (schema) => schema.oneOf(['NoSpeciality'], t("specialityMustBeNoSpecialityForDefaultType")),
                otherwise: (schema) => schema.required(),
            }),
        expertiseId: Yup.string()
            .trim()
            .when('Type', {
                is: 'default',
                then: (schema) => schema.oneOf(['NoExpertise'], t("expertiseMustBeNoExpertiseForDefaultType")),
                otherwise: (schema) => schema.required(),
            }),
        templateOwner: Yup.array().min(1, t("templateOwnerRequired")),
        Type: Yup.string()
            .oneOf(['default', 'public', 'private'], t("invalidTemplateType"))
            .required(t("templateTypeRequired")),
    }).test(
        'non-default-speciality-or-expertise',
        t('specialityOrExpertiseRequired'),
        (values) => {
            const { Type, specialityId, expertiseId } = values;

            if (Type !== 'default') {
                const isSpecialityFilled = specialityId !== 'NoSpeciality';
                const isExpertiseFilled = expertiseId !== 'NoExpertise';

                // One of them must be filled, and the other must remain as "NoSpeciality" or "NoExpertise"
                return (
                    (isSpecialityFilled && expertiseId === 'NoExpertise') ||
                    (isExpertiseFilled && specialityId === 'NoSpeciality')
                );
            }

            return true; // Skip the test for default type
        }
    );

    const fetchTargetDoctors = async () => {
        try {
            if ((templateSpeciality === 'NoSpeciality' && templateExpertise === 'NoExpertise') || !currentInstitution || templateType !== 'private_Other_User') return;

            const response = await axios.get('institutions-users/getTargetDoctorToCopyTemplate', { params: { offset, limit, specialityId: templateSpeciality, expertiseId: templateExpertise } });

            if (response.status === 200 || response.status === 201) {
                setTargetDoctor((prevTargetDoctor) => offset === 0 ? response.data : [...prevTargetDoctor, ...response.data]);
                setHasMore(response.data.length >= limit);
                setOffset(offset + limit);
            }
        } catch (error) {
            console.error('Error fetching target doctors:', error);
            CustomErrorLogger(error, 'Error fetching target doctors for import template');
        }
    }

    useEffect(() => {
        fetchTargetDoctors()
    }, [currentInstitution, templateSpeciality, templateExpertise]);

    const fetchAllSpecialities = async (page, search) => {
        try {
            const limit = 10;
            const offset = page * limit;
            const response = await axios.get('speciality', { params: { offset, limit, search: search } });
            return response.data.map(record => ({ value: record.specialityId, label: record.label }));
        } catch (error) {
            console.error('Error fetching specialities:', error);
            CustomErrorLogger(error, 'Error fetching all specialities for import template');
            return [];
        }
    };

    const fetchAllExpertises = async (page, search) => {
        try {
            const limit = 10;
            const offset = page * limit;
            const response = await axios.get('expertise', { params: { offset, limit, search: search } });
            return response.data.map(record => ({ value: record.expertiseId, label: record.label }));

        } catch (error) {
            console.error('Error fetching expertises:', error);
            CustomErrorLogger(error, 'Error fetching all expertises for import template');
            return [];
        }
    };

    const fetchInstitutionSpecialities = async () => {
        try {
            const response = await axios.get('speciality/specialitiesForManageTemplates', { params: { institutionId: currentInstitution.institution.id } });
            return response.data.map(record => ({ value: record.value, label: record.label }));
        } catch (err) {
            console.log(err);
            CustomErrorLogger(err, 'Error fetching institution specialities for import template');
            return [];
        }
    };

    const fetchInstitutionExpertises = async () => {
        try {
            const response = await axios.get('expertise/expertisesForManageTemplates', { params: { institutionId: currentInstitution.institution.id } });
            return response.data.map(record => ({ value: record.value, label: record.label }));
        } catch (err) {
            console.log(err);
            CustomErrorLogger(err, 'Error fetching institution expertises for import template');
            return [];
        }
    };

    const fetchExpertisesForExpert = async () => {
        try {
            const options = currentInstitution.institutionUserExpertise?.map(institutionUserExp => ({ value: institutionUserExp.expertise?.id, label: institutionUserExp.expertise?.expertiseLabel[0]?.label }));
            setExpertiseOptions(options)
        } catch (error) {
            console.error('Error fetching expertises for expert:', error);
            CustomErrorLogger(error, 'Error fetching expertises for expert for import template');
            setExpertiseOptions([])
        }
    };

    const handleChangeType = (Type) => {
        setTemplateType(Type);
        setTemplateSpeciality("NoSpeciality");
        setTemplateExpertise("NoExpertise");
        setSelectedSpeciality("");
        setSelectedExpertise("");
        setTargetDoctor([]);
        setOwners([]);
        setOffset(0);
        setHasMore(true);
        hasGridBeenRendered.current = false;
    }

    const handleChangeExpertise = (selectedExpertise) => {
        setTemplateSpeciality("NoSpeciality")
        setSelectedSpeciality("")
        setTemplateExpertise(selectedExpertise.value)
        setSelectedExpertise(selectedExpertise)
        setOwners([])
        setOffset(0);
        setHasMore(true);
    }

    const handleChangeSpeciality = (selectedSpeciality) => {
        setTemplateExpertise("NoExpertise");
        setSelectedExpertise("");
        setTemplateSpeciality(selectedSpeciality.value);
        setSelectedSpeciality(selectedSpeciality);
        setOwners([]);
        setOffset(0);
        setHasMore(true);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setZipFile(file);
    };

    const determineSpecialityAndExpertise = () => {
        let speciality = 'NoSpeciality';
        let expertise = 'NoExpertise';

        if (currentInstitution && isSpecialist &&
            ((isAllowedCreatePublicTemplateWithMySpecialityOrExpertise && !isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise && templateType === 'public') ||
                (isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise && !isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise && templateType === 'private_Personal'))) {

            speciality = currentInstitution.specialities.id
            expertise = "NoExpertise"
        }

        else if (currentInstitution && isExpert && currentInstitution.institutionUserExpertise.length <= 1 &&
            ((isAllowedCreatePublicTemplateWithMySpecialityOrExpertise && !isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise && templateType === 'public') ||
                (isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise && !isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise && templateType === 'private_Personal'))) {

            speciality = "NoSpeciality"
            expertise = currentInstitution.institutionUserExpertise[0].expertise.id
        }

        else if (currentInstitution && isExpert && currentInstitution.institutionUserExpertise.length > 1 &&
            ((isAllowedCreatePublicTemplateWithMySpecialityOrExpertise && !isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise && templateType === 'public') ||
                (isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise && !isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise && templateType === 'private_Personal'))) {

            speciality = "NoSpeciality"
            expertise = templateExpertise
        }

        else if (
            (isAllowedCreateDefaultTemplate && templateType === 'default') ||
            (isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise && templateType === 'public') ||
            (isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise && templateType === 'private_Personal') ||
            (isAllowedCreateTemplateForMyInstitutionUsers && templateType === 'private_Other_User')
        ) {
            speciality = templateSpeciality
            expertise = templateExpertise
        }

        return { speciality, expertise };
    };

    const handleImportTemplate = async () => {

        if (!isAllowedCreateDefaultTemplate &&
            !isAllowedCreatePublicTemplateWithMySpecialityOrExpertise &&
            !isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise &&
            !isAllowedCreateTemplateForMyInstitutionUsers &&
            !isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise &&
            !isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise) {
            return
        }

        // Validate the ZIP file
        if (!zipFile || !zipFile.name.endsWith('.zip')) {
            setErrorMsg(t('invalidZipFile'));
            return;
        }

        if (zipFile.size > 100 * 1024 * 1024) { // 100 MB limit
            setErrorMsg(t('invalidZipFileSizeTooLarge'));
            return;
        }

        // Determine templateOwner based on permissions
        const templateOwner = (isAllowedCreateTemplateForMyInstitutionUsers && templateType === 'private_Other_User') ? owners : [userData.id];

        // Determine speciality and expertise
        const { speciality, expertise } = determineSpecialityAndExpertise();

        // Determine Type
        const Type = templateType === "private_Personal" || templateType === "private_Other_User" ? "private" : templateType;

        const plainObject = {
            name: templateName,
            description: templateDescription,
            Type,
            templateOwner,
            specialityId: speciality,
            expertiseId: expertise,
        };

        try {
            await validationSchema.validate(plainObject, { abortEarly: false });

            // Prepare FormData for submission
            const bodyData = new FormData();
            bodyData.append('name', templateName);
            bodyData.append('description', templateDescription);
            bodyData.append('Type', Type);
            bodyData.append('templateOwner', JSON.stringify(templateOwner)); // Convert array to JSON string
            bodyData.append('specialityId', speciality);
            bodyData.append('expertiseId', expertise);
            bodyData.append('file', zipFile);

            // Submit the data
            const response = await axios.post(`medical-file-templates/importTemplate`, bodyData);
            if (response.status === 200 || response.status === 201) {
                navigate(`/dashboard/medicalFilesTemplates/details/${response.data}`)
            }
        } catch (error) {
            if (error.name === 'ValidationError') {
                setErrorMsg(error?.errors?.join(` ${t('and')} `));
            } else {
                setErrorMsg(t('invalidJsonFile'));
                console.error('Invalid JSON file:', error);
                CustomErrorLogger(error, 'Error importing template');
            }
        }

    }

    const handleAddDoctorAsOwner = (choosenDoctor, isChecked) => {

        setOwners((prevOwners) => {
            // Check if the doctor already exists in owners
            const existingIndex = prevOwners.findIndex((ownerId) => ownerId === choosenDoctor.user.id);

            if (isChecked) {
                // Add the doctor as an owner if it doesn't exist
                if (existingIndex === -1) {
                    return [...prevOwners, choosenDoctor.user?.id];
                }
            } else {
                // Remove the doctor from owners if it exists
                if (existingIndex !== -1) {
                    const updatedMembers = [...prevOwners];
                    updatedMembers.splice(existingIndex, 1);
                    return updatedMembers;
                }
            }

            return prevOwners; // Return previous state if no changes
        });
    };

    return (
        <Page title={t("importTemplate")}>
            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                <ArrowBackIosIcon className='createTemp-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                <Stack className="createTemp-main-page-header">
                    <Box className="createTemp-page-title-box">
                        <Typography className='createTemp-page-title'>{t("importTemplate")}</Typography>
                    </Box>
                </Stack>
            </Box>

            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

            <Box className='create-template-box'>
                <TextField
                    id="name"
                    name='name'
                    label={t('name')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    margin='dense'
                    required
                    inputProps={{ maxLength: 80 }}
                    onChange={(e) => setTemplateName(e.target.value)}
                    error={!templateName}
                    helperText={!templateName && (t('isRequired'))}
                />

                <TextField
                    id="description"
                    name='description'
                    label={t('description')}
                    multiline
                    rows={4}
                    type="text"
                    margin='dense'
                    fullWidth
                    variant="outlined"
                    required
                    onChange={(e) => setTemplateDescription(e.target.value)}
                    error={!templateDescription}
                    helperText={!templateDescription && (t('isRequired'))}
                />

                <FormControl
                    required
                    fullWidth
                    margin='dense'
                    error={!templateType}
                    helperText={!templateType && (t('isRequired'))}
                >
                    <RadioGroup
                        row
                        name="templateTypeRadioGroup"
                        value={templateType}
                        onChange={event => handleChangeType(event.target.value)}
                    >
                        {(isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise || isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise) &&
                            <Tooltip title={t('private_personal_description')}>
                                <FormControlLabel value="private_Personal" control={<Radio />} label={t('private_personal')} />
                            </Tooltip>
                        }

                        {isAllowedCreateTemplateForMyInstitutionUsers &&
                            <Tooltip title={t('private_other_user_description')}>
                                <FormControlLabel value="private_Other_User" control={<Radio />} label={t('private_other_user')} />
                            </Tooltip>
                        }

                        {(isAllowedCreatePublicTemplateWithMySpecialityOrExpertise || isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise) &&
                            <Tooltip title={t('public_description')}>
                                <FormControlLabel value="public" control={<Radio />} label={t('public')} />
                            </Tooltip>
                        }

                        {isAllowedCreateDefaultTemplate &&
                            <Tooltip title={t('default_description')}>
                                <FormControlLabel value="default" control={<Radio />} label={t('default')} />
                            </Tooltip>
                        }
                    </RadioGroup>
                    {!templateType && <FormHelperText style={{ color: 'rgb(252, 72, 72)', marginLeft: '14px' }}>{t('isRequired')}</FormHelperText>}
                </FormControl>

                {((isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise && templateType === 'public') || (isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise && templateType === 'private_Personal')) &&
                    (hasGridBeenRendered.current = true) &&
                    <MuiSelect
                        label={t('speciality')}
                        required={false}
                        searchable={true}
                        value={selectedSpeciality}
                        fetchFunction={fetchAllSpecialities}
                        onChange={(selectedSpeciality) => handleChangeSpeciality(selectedSpeciality.target)}
                    />
                }

                {((isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise && templateType === 'public') || (isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise && templateType === 'private_Personal')) &&
                    (hasGridBeenRendered.current = true) &&
                    <MuiSelect
                        label={t('expertise')}
                        required={false}
                        searchable={true}
                        value={selectedExpertise}
                        fetchFunction={fetchAllExpertises}
                        onChange={(selectedExpertise) => handleChangeExpertise(selectedExpertise.target)}
                    />
                }

                {(isAllowedCreateTemplateForMyInstitutionUsers && templateType === 'private_Other_User') &&
                    (hasGridBeenRendered.current = true) &&
                    <MuiSelect
                        label={t('speciality')}
                        required={false}
                        searchable={true}
                        value={selectedSpeciality}
                        fetchFunction={fetchInstitutionSpecialities}
                        onChange={(selectedSpeciality) => handleChangeSpeciality(selectedSpeciality.target)}
                    />
                }

                {(isAllowedCreateTemplateForMyInstitutionUsers && templateType === 'private_Other_User') &&
                    (hasGridBeenRendered.current = true) &&
                    <MuiSelect
                        label={t('expertise')}
                        required={false}
                        searchable={true}
                        value={selectedExpertise}
                        fetchFunction={fetchInstitutionExpertises}
                        onChange={(selectedExpertise) => handleChangeExpertise(selectedExpertise.target)}
                    />
                }

                {(((isAllowedCreatePublicTemplateWithMySpecialityOrExpertise && templateType === 'public' && !isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise) ||
                    (isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise && templateType === 'private_Personal' && !isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise)) &&
                    institutionUserExpertise.length > 1
                ) &&
                    (hasGridBeenRendered.current = true) &&
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t('expertise')}</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            label={t('expertise')}
                            value={templateExpertise}
                            onOpen={fetchExpertisesForExpert}
                            onChange={(e) => setTemplateExpertise(e.target.value)}
                        >
                            {expertiseOptions?.map((expertiseOption) => (
                                <MenuItem key={expertiseOption.value} value={expertiseOption.value}>{expertiseOption.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

                {hasGridBeenRendered.current &&
                    <Typography className="createTemp-requiredNote">{t('atLeastSpecialityOrExpertiseRequired')}</Typography>
                }

                <Button
                    variant="contained"
                    sx={{
                        color: 'white',
                        backgroundColor: '#17536B',
                        '&:hover': {
                            backgroundColor: '#17536B',
                        },
                    }}
                    startIcon={<Iconify icon="material-symbols:attach-file" />}
                    fullWidth
                    onClick={() => document.getElementById('attchTemplate').click()}>{t('attach_zip')}
                </Button>

                {zipFile && <Typography >{t('zipFileSelected')}: {zipFile.name}</Typography>}

                {(isAllowedCreateTemplateForMyInstitutionUsers && templateType === 'private_Other_User' && targetDoctor.length > 0) &&
                    <InfiniteScroll
                        dataLength={targetDoctor.length}
                        next={fetchTargetDoctors}
                        hasMore={hasMore}>
                        <Table>
                            <TableHead>
                                <TableRow className='table-row'>
                                    <TableCell className='table-titles'>{t('doctor')}</TableCell>
                                    <TableCell className='table-titles'>{t('firstname')}</TableCell>
                                    <TableCell className='table-titles'>{t('lastname')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {targetDoctor && targetDoctor.map((doctor) =>
                                    <TableRow key={doctor.id}>
                                        <TableCell >
                                            <Checkbox
                                                checked={owners.some((ownerId) => ownerId === doctor.user?.id)}
                                                onChange={(e) => handleAddDoctorAsOwner(doctor, e.target.checked)}
                                            />
                                        </TableCell>
                                        <TableCell className='imeah_propre_name'>{doctor.user?.firstname}</TableCell>
                                        <TableCell className='imeah_propre_name'>{doctor.user?.lastname}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table >
                    </InfiniteScroll>
                }

                <Box className="createTemp-actions-box">
                    <Button className='new-button' fullWidth onClick={() => navigate(-1)}>{t('cancel')}</Button>
                    <Button className='new-button' fullWidth onClick={handleImportTemplate}>{t('importMDF')}</Button>
                </Box>
                <input
                    style={{ display: 'none' }}
                    type='file'
                    id='attchTemplate'
                    name='value'
                    accept=".zip" // Restrict to ZIP files
                    onChange={handleFileChange}
                />
            </Box>
        </Page>

    )
}