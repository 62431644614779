import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { Card, Box, Typography, Radio } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import '../../../css/medicalFiles/select_template.css';

export const SelectTemplate = ({ choosenTemplate, selectedSpeciality, selectedExpertise, targetTemplateOwnerData,
    setChoosenTemplate, targetDoctorInstitutionId, setActiveStep }) => {

    const currentInstitution = useSelector(state => state.currentInstitution);

    const [templates, setTemplates] = useState([]);

    // Fetch templates from API
    const fetchTemplates = useCallback(async () => {

        const isNotValidSpeciality = (selectedSpeciality?.value === null || selectedSpeciality?.value === undefined || selectedSpeciality?.value === 'NoSpeciality');
        const isNotValidExpertise = (selectedExpertise?.value === null || selectedExpertise?.value === undefined || selectedExpertise?.value === 'NoExpertise');

        if (!currentInstitution || !targetTemplateOwnerData || !targetDoctorInstitutionId || (isNotValidSpeciality && isNotValidExpertise)) {
            setTemplates([]);
            return
        };

        try {
            const response = await axios.get('medical-file-templates/targetTemplateForCreateMedicalFile', {
                params: {
                    SpecialityId: selectedSpeciality?.value || "NoSpeciality",
                    expertiseId: selectedExpertise?.value || "NoExpertise",
                    targetTemplateOwnerId: targetTemplateOwnerData,
                    targetDoctorInstitutionId
                }
            });
            if (response.status === 200 || response.status === 201) {
                setTemplates(response.data);
                if (response.data.length === 1) {
                    setChoosenTemplate(response.data[0]);
                    setActiveStep(2);
                }
            }

        } catch (error) {
            console.error('Error fetching templates:', error);
            CustomErrorLogger(error, 'Error fetching templates for create medical file');
        }
    }, [currentInstitution, targetTemplateOwnerData, selectedSpeciality.value, selectedExpertise.value, targetDoctorInstitutionId, setChoosenTemplate, setActiveStep]);

    // Fetch templates on dependency change
    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    // Handle template selection
    const handleChangeTemplate = useCallback((template) => {
        setChoosenTemplate(template);
        setActiveStep(2);
    }, [setActiveStep, setChoosenTemplate]);

    // Render templates
    const renderedTemplates = useMemo(() => {
        return templates.map((template, index) => (
            <Card
                key={index}
                onClick={() => handleChangeTemplate(template)}
                className='select-temp-card'
                sx={{
                    backgroundColor: choosenTemplate.id === template.id ? '#e5f4f9' : '#ffffff',
                    border: '1px solid ' + (choosenTemplate.id === template.id ? '#83CBE7' : '#D9D9D9'),
                }}
            >
                <Radio
                    className='imeahRadio'
                    checked={choosenTemplate.id === template.id}
                    name={index.toString()}
                />
                <Typography className="imeahSubTitle" sx={{ alignSelf: 'center', textAlign: "center" }}>{template.name}</Typography>
                <Typography className="imeahCaption" sx={{ alignSelf: 'center', textAlign: "center" }}>{template.description}</Typography>
            </Card>
        ));
    }, [templates, choosenTemplate.id, handleChangeTemplate]);

    return (
        <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={'15px'}>
            {templates.length > 1 ? renderedTemplates : null}
        </Box>
    )
}