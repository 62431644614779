import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { MuiSelect } from '../../utils/muiSelect';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export const TransferOneFile = ({ medicalFileData, dialogTransfer, setDialogTransfer }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [newDoctorOwnerId, setNewDoctorOwnerId] = useState('');
    const [newDoctorOwnerName, setNewDoctorOwnerName] = useState('');

    const fetchTargetDoctors = useCallback(async (page, search) => {

        //manage case you dont choose current owner with empty options
        if (!medicalFileData || Object.keys(medicalFileData).length === 0) {
            return [];
        }

        try {
            const limit = 10;
            const offset = page * limit;

            // Extract the values if a matching object is found
            const InstitutioUserId = medicalFileData?.doctorId
            const specialityId = medicalFileData?.doctor?.specialityId;
            const roleId = medicalFileData?.doctor?.roleId;
            const expertises = medicalFileData?.doctor?.institutionUserExpertise.map(expertise => expertise.expertise.id);

            const response = await axios.get('institutions-users/getTargetDoctorToTransferMedicalFileWith', { params: { offset, limit, search, currentOwnerId: InstitutioUserId, roleId, specialityId, expertises } });
            return response.data.map(record => ({ value: record.id, label: record.user.firstname + ' ' + record.user.lastname }));
        } catch (error) {
            console.error('Error fetching target doctors:', error);
            return [];
        }
    }, [medicalFileData]);

    const handleChangeNewOwner = useCallback((newOwnerData) => {
        setNewDoctorOwnerName(newOwnerData)
        setNewDoctorOwnerId(newOwnerData.value)
    }, []);

    const transferOneFile = async () => {
        try {
            // Early return if newDoctorOwnerId is empty
            if (newDoctorOwnerId === '') return;
            //prepare data to send it
            const data = { doctorId: newDoctorOwnerId };

            const response = await axios.patch(`medical-file/updateOneFileOwner/${medicalFileData.id}`, data);
            if (response.status === 200 || response.status === 201) {
                navigate('/dashboard/medicalFiles', { state: { Tab: '1' } });
                setDialogTransfer(false);
            }
        } catch (error) {
            console.error('Error transferring file:', error);
        }
    };

    return (
        <Dialog open={dialogTransfer} fullWidth>
            <DialogTitle>{t('transfer')}</DialogTitle>
            <DialogContent>
                <MuiSelect
                    className="form-control"
                    label={t('newOwner')}
                    required={true}
                    searchable={true}
                    value={newDoctorOwnerName}
                    fetchFunction={fetchTargetDoctors}
                    onChange={(selectedInstitution) => handleChangeNewOwner(selectedInstitution.target)}
                />
            </DialogContent>
            <DialogActions>
                <Button fullWidth onClick={() => setDialogTransfer(false)} className='buttonOutlined'>{t('cancel')}</Button>
                <Button fullWidth onClick={transferOneFile} className='buttonFilled' disabled={newDoctorOwnerId === ''}>{t('transfer')}</Button>
            </DialogActions>
        </Dialog>
    );
};