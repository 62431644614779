import { Dialog, DialogActions, DialogTitle, Button, Alert, Box } from "@mui/material";
import React, { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const DeleteInstitution = ({ openDelete, setOpenDelete, id }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [errorMsg, setErrorMsg] = useState(null);

    const handleDelete = useCallback(async () => {
        try {
            const response = await axios.delete(`institutions/${id}`);
            if (response.status === 200 || response.status === 201) {
                setOpenDelete(false);
                navigate('/dashboard/hospitals');
            }
        } catch (error) {
            setErrorMsg(error.message);
        }
    }, [id, navigate, setOpenDelete]);

    const handleDeleteClose = useCallback(() => {
        setErrorMsg(null)
        setOpenDelete(false);
    }, [setOpenDelete]);

    return (
        <Dialog open={openDelete} keepMounted aria-describedby="alert-dialog-slide-description">
            {errorMsg ?
                <DialogTitle>{t("canNotRemove")}</DialogTitle> :
                <DialogTitle>{t('sureDeleteInstitution')}</DialogTitle>
            }
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <DialogActions>
                {errorMsg ?
                    <Button className="new-button" onClick={handleDeleteClose}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button className='new-button' onClick={handleDeleteClose}>{t('cancel')}</Button>
                        <Button className='new-button' onClick={handleDelete}>{t('dlt')}</Button>
                    </Box>
                }
            </DialogActions>
        </Dialog>
    )
}