import { Button, Dialog, Menu, MenuItem, Box, CircularProgress, DialogContent, DialogActions, Typography, Checkbox } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../Iconify';
import axios from 'axios';

const ShareFile = ({ data, setdata, openShare, setOpenShare }) => {

    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    const [currentPolicy, setCurrentPolicy] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [policyChecked, setPolicyChecked] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(userData.local_code);
    const [loading, setLoading] = useState(true);

    const fetchPolicy = useCallback(async () => {
        try {
            const response = await axios.get('policies/currentPolicy', {
                params: {
                    language: selectedLanguage,
                    type: 'medicalFileSharingPolicy',
                    institutionId: data.institutionId,
                },
            });
            if (response.status === 200 || response.status === 201) {
                setLanguages(response.data.availableLanguages);
                setCurrentPolicy(response.data.policyWithSameVersionAndPecificLanguage);
            }
        } catch (error) {
            console.error('Error fetching policy:', error);
        } finally {
            setLoading(false);
        }
    }, [selectedLanguage, data.institutionId]);

    useEffect(() => {
        fetchPolicy();
    }, [fetchPolicy]);

    const handleShare = useCallback(async () => {
        try {
            const response = await axios.patch(`medical-file/ShareMedicalFile/${data.id}`, { policyId: currentPolicy.id });
            if (response.status === 200 || response.status === 201) {
                const { shareStatus, status, newLog } = response.data;
                setdata(prevData => ({
                    ...prevData,
                    shareStatus,
                    status,
                    medical_Files_logs: [...prevData.medical_Files_logs, newLog],
                }));
                setOpenShare(false);
            }
        } catch (error) {
            console.error('Error sharing medical file:', error);
        }
    }, [data.id, currentPolicy.id, setdata, setOpenShare]);

    const languageMenu = useMemo(() => {
        return (
            languages?.length > 1 && (
                <PopupState variant="popover" popupId="shareFileLanguagepopup">
                    {(popupState) => (
                        <>
                            <LanguageIcon
                                className="home-navbar-login"
                                sx={{ alignSelf: 'flex-end' }}
                                {...bindTrigger(popupState)}
                            />
                            <Menu {...bindMenu(popupState)}>
                                {languages.map((language, index) => (
                                    <MenuItem
                                        onClick={() => setSelectedLanguage(language)}
                                        key={index}
                                        value={language}
                                    >
                                        {t(language)}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </PopupState>
            )
        );
    }, [languages, t]);

    return (
        <Dialog open={openShare} keepMounted >
            {loading ? (
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            ) : (
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    {languageMenu}

                    <Iconify icon='material-symbols:send' sx={{ width: '50px', height: '50px', color: '#727179', alignSelf: 'center' }} />
                    <Typography className="imeahSubTitle" textAlign={'center'}>{t("send_file_to_specialist")}</Typography>
                    <Typography className="imeahCaption" textAlign={'center'}>{t("shareFileDialogSubtitle_1")}</Typography>
                    <Typography className="imeahCaption" textAlign={'center'}>{t("shareFileDialogSubtitle_2")}</Typography>

                    {currentPolicy.description.map((desc, index) => (
                        <Box key={index}>
                            <Typography variant='subtitle1'>{desc.descTitle}</Typography>
                            <Typography variant='body2' >{desc.descSubTitle}</Typography>
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            className="imeahCheckbox"
                            checked={policyChecked}
                            onChange={() => setPolicyChecked(!policyChecked)}
                        />
                        <Typography className="policy-text">{t('agreeToPolicy')}</Typography>
                    </Box>
                </DialogContent>
            )}
            <DialogActions>
                <Button className='buttonOutlined' onClick={() => setOpenShare(false)}>{t('Cancel')}</Button>
                <Button className="buttonFilled" disabled={loading || (currentPolicy ? !policyChecked : false)} onClick={handleShare}>{t('agree')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(ShareFile);  