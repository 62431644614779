import { Stack, InputBase, Typography, Box, useMediaQuery, Divider, InputAdornment, MenuItem, IconButton, Menu } from "@mui/material";
import { InstitutionUsersCredentiels } from "../components/Permissions/InstitutionUsersCredentiels";
import { UsersCredentiels } from "../components/Permissions/usersCredentiels";
import { HasPermission } from "../utils/checkUserPermission";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import useDebounce from "../hooks/useDebounce";
import Iconify from "../components/Iconify";
import React, { useState } from "react";
import Page from "../components/Page";
import '../css/permissions/UserPermissions.css';

export const UserPermissions = () => {

    const { t } = useTranslation();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);

    //check user permissions
    const isAllowedManageAllUsersCredentials = HasPermission('ManageCustomUserPermission');
    const isAllowedManageInstitutionUsersCredentials = HasPermission('ManageCustomInstitutionUserPermission');

    const [openFilter, setOpenFilter] = useState(false);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 1000);

    const handleOpenActionMenu = (event) => setAnchorEl(event.currentTarget);
    const handleCloseActionMenu = () => setAnchorEl(null);

    return (
        <Page title={t("userPermissions")}>
            <Stack className="userPermission-main-page-header">
                <Box className="userPermission-page-title-box">
                    <Box className="userPermission-page-title-icon-box">
                        <Iconify className="userPermission-page-title-icon" icon="icon-park-outline:permissions" />
                        <Typography className='userPermission-page-title'>{t("userPermissions")}</Typography>
                    </Box>
                    <InputBase
                        className='userPermission-page-search-input'
                        id="outlined-password-input"
                        label={t('search')}
                        type="text"
                        placeholder={t('search')}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        endAdornment={
                            search && (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => setSearch("")} />
                                </InputAdornment>
                            )
                        }
                    />
                </Box>

                {isDesktop && <Box className="userPermission-page-action-box">
                    <Box className='userPermission-page-header-button' onClick={() => setOpenFilter(true)}>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Iconify icon="mdi:filter" className="plus-icon" />
                        <Typography className="new-button-title">{t('actionFilter')}</Typography>
                    </Box>
                </Box>
                }

                {!isDesktop &&
                    <IconButton
                        className="main-page-threeDot-icon"
                        id="userPageActionMenuIcom"
                        aria-controls={openActionMenu ? 'long-menu' : undefined}
                        aria-expanded={openActionMenu ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleOpenActionMenu}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                }

                <Menu
                    id="collaborationePageActionMenu"
                    MenuListProps="collaborationPageActionMenuIcom"
                    open={openActionMenu}
                    anchorEl={anchorEl}
                    onClose={handleCloseActionMenu}
                    onClick={handleCloseActionMenu}
                    slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
                >
                    < MenuItem className='main-page-header-button' onClick={() => setOpenFilter(true)}>
                        <Iconify icon="mdi:filter" className="main-page-threeDot-icon" />
                        <Typography>{t('actionFilter')} </Typography>
                    </MenuItem>
                </Menu>

            </Stack>

            {isAllowedManageAllUsersCredentials &&
                <UsersCredentiels
                    search={debouncedSearch}
                    setSearch={setSearch}
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                />
            }

            {isAllowedManageInstitutionUsersCredentials && !isAllowedManageAllUsersCredentials &&
                <InstitutionUsersCredentiels
                    search={debouncedSearch}
                    setSearch={setSearch}
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                />
            }

        </Page >
    );
}