/* eslint-disable react-hooks/exhaustive-deps */
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControlLabel, Checkbox, Grid, Box } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useTranslation } from "react-i18next";
import timezone from 'dayjs/plugin/timezone';
import React, { useEffect } from 'react';
import utc from 'dayjs-plugin-utc';
import dayjs from 'dayjs';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

export const DayBox = ({ day, availability, handleCheckboxChange, setAvailability, setErrMsg }) => {

    const { t } = useTranslation();

    function convertUtcToLocalTime(utcTime) {
        if (!utcTime) {
            return null; // return null for a Date object
        }
        const [utcHours, utcMinutes] = utcTime.split(':').map(Number);
        const utcDate = new Date();
        utcDate.setUTCHours(utcHours);
        utcDate.setUTCMinutes(utcMinutes);

        return utcDate; // return Date object
    }

    useEffect(() => {

        let allFromBeforeTo = true;

        for (const day in availability) {
            if (availability[day]) {
                const fromDate = dayjs.utc(`1970-01-01T${availability[day].from}`);
                const toDate = dayjs.utc(`1970-01-01T${availability[day].to}`);

                if (toDate.isBefore(fromDate) || toDate.isSame(fromDate)) {
                    setErrMsg(`${t('errToAfterFrom')} ${day}`);
                    allFromBeforeTo = false;
                }
            }
        }
        if (allFromBeforeTo) {
            setErrMsg("");
        }
    }, [availability]);

    const handleChangeFromHour = (fromHour) => {
        const internationalFromTime = dayjs(fromHour).utc().format('HH:mm');
        setAvailability((prevAvailability) => ({
            ...prevAvailability,
            [day]: {
                ...prevAvailability[day],
                from: internationalFromTime,
            },
        }));
    }

    const handleChangeToHour = (toHour) => {
        const internationalToTime = dayjs(toHour).utc().format('HH:mm');
        setAvailability((prevAvailability) => ({
            ...prevAvailability,
            [day]: {
                ...prevAvailability[day],
                to: internationalToTime,
            },
        }));
    }

    return (
        <Box spacing={1} alignItems="center" className="dayBox_mainBox">

            <FormControlLabel
                className="day_check_box"
                style={{ marginLeft: '0px', marginRight: '5px' }}
                control={
                    <Checkbox
                        checked={availability[day]?.checked === true}
                        onChange={() => handleCheckboxChange(day)}
                    />
                }
                label={t(day)}
                labelPlacement="start"
                classes={{ label: 'day_typo' }}
            />

            {availability[day]?.checked === true && (
                <Grid item container xs={8} spacing={2} className="moveUnder">
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label={t("from")}
                                sx={{ width: '100%', marginTop: '10px' }}
                                value={dayjs(convertUtcToLocalTime(availability[day].from))}
                                onChange={(fromHour) => handleChangeFromHour(fromHour)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                label={t("to")}
                                sx={{ width: '100%', marginTop: '10px' }}
                                value={dayjs(convertUtcToLocalTime(availability[day].to))}
                                onChange={(toHour) => handleChangeToHour(toHour)}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}