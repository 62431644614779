import { Button, Dialog, DialogTitle, DialogActions, Box, Alert } from '@mui/material';
import { CustomErrorLogger } from '../../utils/CustomErrorLogger';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export const DeleteTemplate = ({ id, openDelete, setOpenDelete }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [errMsg, setErrMsg] = useState(null);

    const handleDelete = useCallback(async () => {
        try {
            const response = await axios.delete(`medical-file-templates/${id}`);
            if (response.status === 200 || response.status === 201) {
                setOpenDelete(false);
                navigate('/dashboard/medicalFilesTemplates');
            }
        } catch (error) {
            console.error('Error deleting template:', error);
            CustomErrorLogger(error, 'Error deleting template');
            setErrMsg(error.message);
        }
    }, [id, navigate, setOpenDelete]);

    return (
        <Dialog
            open={openDelete}
            keepMounted
            onClose={() => setOpenDelete(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            {errMsg ? <DialogTitle >{t("canNotRemove")}</DialogTitle> : <DialogTitle>{t('sureDeleteTemplate')}</DialogTitle>}
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogActions>
                {errMsg ?
                    <Button className="new-button" fullWidth onClick={() => setOpenDelete(false)}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button className="new-button" fullWidth onClick={() => setOpenDelete(false)}>{t('cancel')}</Button>
                        <Button className="new-button" fullWidth onClick={handleDelete}>{t('dlt')}</Button>
                    </Box>}
            </DialogActions>
        </Dialog>
    );
};
