import { Box, Table, TableHead, TableRow, TableBody, TableCell, Chip, Typography, useMediaQuery, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { useEffect, useCallback, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { act } from '@testing-library/react';
import { useSelector } from 'react-redux';
import Scrollbar from '../Scrollbar';
import axios from 'axios';

export const AcceptUsers = () => {

  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const userData = useSelector(state => state.userData);

  const [institutionRequests, setInstitutionRequests] = useState([]);

  const fetchInstitutionRequests = useCallback(async () => {
    if (userData) {
      try {
        const response = await axios.get('join-institution-requests/institutionRequests');
        if (response.status === 200 || response.status === 201) {
          act(() => {
            setInstitutionRequests(response.data);
          })
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [userData]);

  useEffect(() => {
    fetchInstitutionRequests();
  }, [fetchInstitutionRequests]);

  const handleAcceptUser = useCallback((requestId) => {
    axios.patch(`join-institution-requests/acceptRequest/${requestId}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setInstitutionRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
        }
      })
      .catch((err) => console.error(err));
  }, [])

  const handleIgnoreUser = useCallback((requestId) => {
    axios.patch(`join-institution-requests/hideRequest/${requestId}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setInstitutionRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
        }
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Box>
      <Scrollbar>
        {isDesktop &&
          <Table>
            <TableHead>
              <TableRow className='table-row'>
                <TableCell className='table-titles'>{t('fullName')}</TableCell>
                <TableCell className='table-titles'>{t('email')}</TableCell>
                <TableCell className='table-titles'>{t('role')}</TableCell>
                <TableCell className='table-titles'>{t('specialityExpertise')}</TableCell>
                <TableCell className='table-titles'>{t('refuse')}</TableCell>
                <TableCell className='table-titles'>{t('accept')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {institutionRequests && institutionRequests.map((request, index) => (
                <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                  <TableCell align="left" className="new-table-cell">{request.firstname} {request.lastname}</TableCell>
                  <TableCell align="left" className="new-table-cell">{request.email}</TableCell>
                  <TableCell align="left" className="new-table-cell">{request.role?.roleLabel[0].label}</TableCell>
                  {(request.role.canHaveSpeciality && request.specialityId !== "NoSpeciality") &&
                    <TableCell align="left" className="new-table-cell">
                      <Chip label={request.specialityLabel} className='chip' />
                    </TableCell>
                  }
                  {(request.role.canHaveExpertise && request.expertises.length > 0) &&
                    <TableCell align="left" className="new-table-cell">
                      {request.expertises && request.expertises.map((expertise) => (
                        <Chip key={expertise.id} label={expertise.expertise.expertiseLabel[0].label} className='chip' />
                      ))}
                    </TableCell>
                  }
                  {(request.specialityId === "NoSpeciality" && request.expertises.length === 0) &&
                    <TableCell align="left" className="new-table-cell"><Chip label={t('none')} className='chip' /></TableCell>
                  }
                  <TableCell className="new-table-cell"><HighlightOffIcon onClick={() => handleIgnoreUser(request.id)} className='new-delete-icon actions-btn' data-testid='refuseButton' /></TableCell>
                  <TableCell className="new-table-cell"><CheckCircleOutlineIcon onClick={() => handleAcceptUser(request.id)} className='new-delete-icon actions-btn' data-testid='acceptButton' /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
        {!isDesktop &&
          <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
            {institutionRequests && institutionRequests.map((request, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box className="responsive-card-details">
                  <Typography className='card-resp-part'>{request.firstname} {request.lastname}</Typography>
                  <Typography className='card-resp-part'>{request.role?.roleLabel[0].label}</Typography>
                  <Box className='card-actions'>
                    <HighlightOffIcon onClick={() => handleIgnoreUser(request.id)} className='new-delete-icon actions-btn' />
                    <CheckCircleOutlineIcon onClick={() => handleAcceptUser(request.id)} className='new-delete-icon actions-btn' />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        }
      </Scrollbar>
    </Box>
  );
};