/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Box, Stack, Divider, InputBase, IconButton, Menu, MenuItem, useMediaQuery, InputAdornment } from "@mui/material";
import { MedicalFileTemplatesFiltration } from "../components/medicalFileTemplates/read/medicalFileTemplatesFiltration";
import { MedicalFileTemplatesList } from "../components/medicalFileTemplates/read/medicalFileTemplate";
import { useState, useCallback, useEffect, useRef } from "react";
import { CustomErrorLogger } from "../utils/CustomErrorLogger";
import { HasPermission } from "../utils/checkUserPermission";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import { act } from '@testing-library/react';
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import Page from "../components/Page";
import axios from 'axios';
import '../css/templates/templates.css';

export const Templates = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const currentInstitution = useSelector(state => state.currentInstitution);
    const userData = useSelector(state => state.userData);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    //user role check 
    const { specialities, institutionUserExpertise } = currentInstitution || {};

    const isSpecialist = Boolean(specialities?.id && specialities?.id !== 'NoSpeciality' && specialities?.id != null);
    const isExpert = Array.isArray(institutionUserExpertise) && institutionUserExpertise?.length > 0;

    //check user permissions
    const isAllowedImportTemplate = HasPermission('import_export_template');
    const isAllowedCreateDefaultTemplate = HasPermission('create_default_template');
    const isAllowedCreatePublicTemplateWithMySpecialityOrExpertise = HasPermission('create_public_template_with_MySpecialityOrExpertise');
    const isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise = HasPermission('create_public_template_with_customSpecialityOrExpertise');
    const isAllowedCreateTemplateForMyInstitutionUsers = HasPermission('create_private_template_ForMyInstitutionUser');
    const isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise = HasPermission('create_private_template_with_MySpecialityOrExpertise');
    const isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise = HasPermission('create_private_template_with_customSpecialityOrExpertise');
    const isAllowedReadDefaultTemplate = HasPermission('read_default_template');
    const isAllowedReadPublicTemplate = HasPermission('read_public_template');
    const isAllowedReadMyInstitutionTemplate = HasPermission('read_myInstitution_template');
    const isAllowedReadMyOwnTemplate = HasPermission('read_myOwn_template');

    const [templates, setTemplates] = useState([]);
    const [Expertises, setExpertises] = useState([]);
    const [Specialities, setSpecialities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);
    const [openFilter, setOpenFilter] = useState(false);
    //filtration states
    const [search, setSearch] = useState(null);
    const debouncedSearch = useDebounce(search, 1000);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);
    const [selectedExpretise, setSelectedExpretise] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    //pagination state
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 20;

    const hasFilters = selectedType || selectedInstitution || selectedSpeciality || selectedExpretise || selectedStatus;

    const fetchTemplates = useCallback(async () => {
        const filters = {
            search: debouncedSearch,
            selectedSpeciality: selectedSpeciality?.id,
            selectedExpretise: selectedExpretise?.id,
            selectedStatus,
            selectedType,
            selectedInstitution
        };
        try {
            const currentOffset = offset.current;
            const response = await axios.get('medical-file-templates', { params: { offset: currentOffset, limit, filters } });
            act(() => {
                setTemplates((prevTemplates) => currentOffset === 0 ? response.data : [...prevTemplates, ...response.data]);
                setLoading(false);
                offset.current = currentOffset + limit;
                hasMore.current = response.data.length === limit;
            });
        } catch (error) {
            console.error('Error fetching templates:', error);
            CustomErrorLogger(error, 'Error fetching templates in templates main page');
        }
    }, [debouncedSearch, selectedSpeciality, selectedExpretise, selectedStatus, selectedType, selectedInstitution, limit]);

    const fetchFiltrationArrays = useCallback(async () => {
        try {
            const response = await axios.get('medical-file-templates/getFiltrationArrays');
            act(() => {
                setSpecialities(response.data.ParsedSpecialityArray);
                setExpertises(response.data.ParsedExpertiseArray);
            })
        } catch (error) {
            console.error('Error fetching filtration arrays:', error);
            CustomErrorLogger(error, 'Error fetching filtration arrays in templates main page');
        }
    }, [userData.local_code])

    useEffect(() => {
        if (!currentInstitution) return; // Early return for unauthenticated users
        setTemplates([]);
        offset.current = 0;
        hasMore.current = true;
        fetchTemplates();
    }, [currentInstitution, fetchTemplates, userData.local_code]);

    useEffect(() => {
        if (!currentInstitution) return; // Early return for unauthenticated users or default tab
        fetchFiltrationArrays();
    }, [currentInstitution, fetchFiltrationArrays]);

    const handleChangeSearch = (searchWord) => {
        offset.current = 0;
        hasMore.current = true;
        setSearch(searchWord);
    };

    const handleOpenActionMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeExpertise = useCallback(expertise => {
        setSelectedExpretise(expertise);
        setSelectedSpeciality(null);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const handleChangeSpeciality = useCallback(speciality => {
        setSelectedSpeciality(speciality);
        setSelectedExpretise(null);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const handleChangeStatus = useCallback(status => {
        setSelectedStatus(status);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const handleChangeType = useCallback(newType => {
        setSelectedType(newType);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const handleChangeInstitution = useCallback(newInstitution => {
        setSelectedInstitution(newInstitution);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const emptyFiltrationObject = useCallback(() => {
        setSearch(null);
        setSelectedSpeciality(null);
        setSelectedExpretise(null);
        setSelectedStatus(null);
        setSelectedType(null);
        setSelectedInstitution(null);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const FilterTag = ({ label, onDelete }) => (
        <Box className='template-page-filter-flag-item'>
            <Typography>{t(label)}</Typography>
            <Iconify icon="mdi:close" onClick={onDelete} />
        </Box>
    );

    const ActionButton = ({ icon, title, onClick }) => (
        <Box className='template-page-header-button' onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} className="template-page-title-icon" />
            <Typography>{title}</Typography>
        </Box>
    );

    const ActionMenuButton = ({ icon, title, onClick }) => (
        <MenuItem className='template-page-header-button' onClick={onClick}>
            <Iconify icon={icon} className="template-page-title-icon" />
            <Typography>{title}</Typography>
        </MenuItem>
    );

    return (
        <Page title={t("sideBar-Medical Files Templates")}>
            <Stack className="template-page-header">
                <Box className="template-page-title-box">
                    <Box className="template-page-title-icon-box">
                        <Iconify className="template-page-title-icon" icon="heroicons-solid:template" />
                        <Typography className='template-page-title'>{t("sideBar-Medical Files Templates")}</Typography>
                    </Box>
                    <InputBase
                        className='template-page-search-input'
                        id="template-search-input"
                        label={t('search')}
                        type="text"
                        placeholder={t('search')}
                        value={search || ""}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        endAdornment={
                            search && (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                                </InputAdornment>
                            )
                        }
                    />
                </Box>

                {isDesktop &&
                    <Box className="template-page-action-box">
                        <ActionButton icon="mdi:filter" title={t('actionFilter')} onClick={() => setOpenFilter(true)} />

                        {(isAllowedCreateDefaultTemplate ||
                            isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise ||
                            (isAllowedCreatePublicTemplateWithMySpecialityOrExpertise && (isSpecialist || isExpert)) ||
                            isAllowedCreateTemplateForMyInstitutionUsers ||
                            isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise ||
                            (isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise && (isSpecialist || isExpert))
                        )
                            &&
                            <ActionButton icon="eva:plus-fill" title={t('addNewTemplate')} onClick={() => navigate(`createTemplate`)} />
                        }

                        {isAllowedImportTemplate &&
                            <ActionButton icon="prime:file-import" title={t('importTemplate')} onClick={() => navigate(`importTemplate`)} />
                        }
                    </Box>
                }

                {!isDesktop &&
                    <Box className="template-page-action-box">
                        <IconButton
                            className="template-page-title-icon"
                            id="templatePageActionMenuIcom"
                            aria-controls={openActionMenu ? 'long-menu' : undefined}
                            aria-expanded={openActionMenu ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleOpenActionMenu}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </Box>
                }

                <Menu
                    id="templatePageActionMenuIcom"
                    MenuListProps="templatePageActionMenuIcom"
                    open={openActionMenu}
                    anchorEl={anchorEl}
                    onClose={handleCloseActionMenu}
                    onClick={handleCloseActionMenu}
                    slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
                >
                    <ActionMenuButton icon="mdi:filter" title={t('actionFilter')} onClick={() => setOpenFilter(true)} />

                    {(isAllowedCreateDefaultTemplate ||
                        isAllowedCreatePublicTemplateWithCustomSpecialityOrExpertise ||
                        (isAllowedCreatePublicTemplateWithMySpecialityOrExpertise && (isSpecialist || isExpert)) ||
                        isAllowedCreateTemplateForMyInstitutionUsers ||
                        isAllowedCreatePrivateTemplateWithCustomSpecialityOrExpertise ||
                        (isAllowedCreatePrivateTemplateWithMySpecialityOrExpertise && (isSpecialist || isExpert))
                    )
                        &&
                        <ActionMenuButton icon="eva:plus-fill" title={t('addNewTemplate')} onClick={() => navigate(`createTemplate`)} />
                    }

                    {isAllowedImportTemplate &&
                        <ActionMenuButton icon="prime:file-import" title={t('importTemplate')} onClick={() => navigate(`importTemplate`)} />
                    }
                </Menu>

            </Stack>

            {openFilter &&
                <MedicalFileTemplatesFiltration
                    currentInstitution={currentInstitution}
                    emptyFiltrationObject={emptyFiltrationObject}
                    Specialities={Specialities}
                    selectedSpeciality={selectedSpeciality}
                    handleChangeSpeciality={handleChangeSpeciality}
                    Expertises={Expertises}
                    selectedExpretise={selectedExpretise}
                    handleChangeExpertise={handleChangeExpertise}
                    selectedStatus={selectedStatus}
                    handleChangeStatus={handleChangeStatus}
                    isAllowedReadMyOwnTemplate={isAllowedReadMyOwnTemplate}
                    isAllowedReadMyInstitutionTemplate={isAllowedReadMyInstitutionTemplate}
                    isAllowedReadPublicTemplate={isAllowedReadPublicTemplate}
                    isAllowedReadDefaultTemplate={isAllowedReadDefaultTemplate}
                    selectedType={selectedType}
                    handleChangeType={handleChangeType}
                    selectedInstitution={selectedInstitution}
                    handleChangeInstitution={handleChangeInstitution}
                    setOpenFilter={setOpenFilter}
                />
            }

            {(!openFilter && hasFilters) &&
                <Box className='template-page-filter-flag-box '>
                    {selectedSpeciality && <FilterTag label={selectedSpeciality?.label} onDelete={() => handleChangeSpeciality(null)} />}
                    {selectedExpretise && <FilterTag label={selectedExpretise?.label} onDelete={() => handleChangeExpertise(null)} />}
                    {selectedType && <FilterTag label={selectedType} onDelete={() => handleChangeType(null)} />}
                    {selectedInstitution && <FilterTag label={selectedInstitution === 'imeah' ? 'ehealth pro' : currentInstitution.institution.name} onDelete={() => handleChangeInstitution(null)} />}
                    {selectedStatus && <FilterTag label={selectedStatus === "true" ? t('ActiveStatus') : t('InActiveStatus')} onDelete={() => handleChangeStatus(null)} />}
                    <Typography className="template-page-clear-filter-flag" onClick={emptyFiltrationObject}>{t("clear_all_filter")}</Typography>
                </Box>
            }

            <MedicalFileTemplatesList
                loading={loading}
                templates={templates}
                fetchTemplates={fetchTemplates}
                hasMore={hasMore.current}
            />

        </Page>
    );
}