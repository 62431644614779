import { Typography, Box, Accordion, AccordionSummary, Tooltip, AccordionDetails, Switch, Button, Stack } from '@mui/material';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ReviewPermissions } from './permission/reviewPermissions.js';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Page from '../Page.js';
import axios from 'axios';

export const ManageUserCredentiels = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useSelector(state => state.userData);
    const { institutionId, institutionUserId } = location.state;

    const [allPermissions, setAllPermissions] = useState([]);
    const [institutionUserData, setInstitutionUserData] = useState({});
    const [rolePermissionsArray, setRolePermissionsArray] = useState([]);
    const [customPermissionsArray, setCustomPermissionsArray] = useState(new Set());
    const [permissionMap, setPermissionMap] = useState(new Map());
    const [openReviewPermission, setOpenReviewPermission] = useState(false);

    // Fetch user data on mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`institutions-users/findOneToManageInstitutionUserCredentials/${institutionUserId}`);
                if (response.status === 200 || response.status === 201) {
                    const { institutionUserData, rolePermissionsArray, CustomPermissionsArray } = response.data;
                    setInstitutionUserData(institutionUserData);
                    setRolePermissionsArray(rolePermissionsArray);
                    setCustomPermissionsArray(new Set(CustomPermissionsArray));
                }
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        };

        fetchUserData();
    }, [institutionUserId]);

    // Fetch permissions
    const fetchPermissions = useCallback(async () => {
        try {
            const response = await axios.get(`permission/forCustomUser`, { params: { targetUserInstitutionId: institutionId } });
            if (response.status === 200 || response.status === 201) {
                setAllPermissions(response.data);
                setPermissionMap(buildPermissionMap(response.data));

            }
        } catch (error) {
            console.error(error);
        }
    }, [institutionId]);

    // Create a flat lookup map for all permissions
    const buildPermissionMap = (permissionsData) => {
        const permissionMap = new Map();

        permissionsData.forEach((entity) => {
            entity.permissions.forEach((permission) => {
                permissionMap.set(permission.id, permission);
            });
        });

        return permissionMap;
    };

    // Combine customPermissionsArray and rolePermissionsArray, then get full permission objects
    const permissionToReview = useMemo(() => {
        const combinedIds = new Set([...Array.from(customPermissionsArray), ...rolePermissionsArray]);
        return Array.from(combinedIds).map(permissionId => permissionMap.get(permissionId)).filter(Boolean);
    }, [customPermissionsArray, rolePermissionsArray, permissionMap]);

    // Fetch permissions once on component mount
    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions, userData.local_code]);

    // Helper function to get all dependencies recursively
    const getAllDependencies = useCallback((dependencyId, visited = new Set()) => {

        if (visited.has(dependencyId)) return []; // Prevent cycle
        visited.add(dependencyId);

        const result = new Set([dependencyId]);
        const dependency = permissionMap.get(dependencyId);

        if (dependency && dependency?.childDependencies) {
            dependency?.childDependencies?.forEach((parent) => {
                const parentId = parent.parentPermissionId;
                if (parentId) {
                    const nestedDependencies = getAllDependencies(parentId, visited);
                    nestedDependencies.forEach(dep => result.add(dep));
                }
            });
        }

        return Array.from(result);
    }, [permissionMap]);

    const getReverseDependencies = useCallback((dependencyId, visited = new Set()) => {

        if (visited.has(dependencyId)) return []; // Prevent cycle
        visited.add(dependencyId);

        const result = new Set([dependencyId]);
        const dependency = permissionMap.get(dependencyId);

        if (dependency && dependency?.parentDependencies) {
            dependency?.parentDependencies?.forEach((child) => {
                const childId = child.childPermissionId;
                if (childId) {
                    const nestedDependencies = getReverseDependencies(childId, visited);
                    nestedDependencies.forEach(dep => result.add(dep));
                }
            });
        }

        return Array.from(result);
    }, [permissionMap]);

    const handleSwitchChange = useCallback((permission, checked) => {

        setCustomPermissionsArray((prevIds) => {

            const newIds = new Set(prevIds);

            if (newIds.has(permission.id) && !checked) {
                // Remove the current permission and uncheck its children
                const reverceDependencies = getReverseDependencies(permission.id);
                reverceDependencies.forEach((depId) => newIds.delete(depId));
                newIds.delete(permission.id);
            } else {
                // Add the current permission and check its parents
                newIds.add(permission.id);
                const dependencies = getAllDependencies(permission.id);
                dependencies.forEach((depId) => newIds.add(depId));
            }

            return newIds;
        });
    }, [getAllDependencies, getReverseDependencies]);

    // Handle permission update
    const editPermissions = async () => {
        try {

            const bodyData = {
                customPermissionsArray: Array.from(customPermissionsArray),
            };

            await axios.patch(`custom-permission/${institutionUserId}`, bodyData);
            navigate(-1);
        } catch (error) {
            console.error("Failed to update permissions:", error);
        }
    };

    // Function to generate tooltip content
    const generateTooltipContent = (permission, t) => {
        return (
            <Box>
                {permission?.PermissionDescription[0]?.label && (
                    <Typography variant="caption" color="textSecondary">{permission.PermissionDescription[0].label}</Typography>
                )}

                {permission.parentDependencies?.length > 0 && (
                    <Typography variant="caption" component="div" color={"#FFA500"}>
                        {t('parentPermissions')}: {permission.parentDependencies.map(parent => parent.childPermission?.PermissionName[0]?.label)?.join(' or ')}
                    </Typography>
                )}
                {permission.childDependencies?.length > 0 && (
                    <Typography variant="caption" component="div" color={"#E53935"}>
                        {t('childPermissions')}: {permission.childDependencies.map(parent => parent.parentPermission?.PermissionName[0]?.label)?.join(' or ')}
                    </Typography>
                )}

                {permission.onlyForEhealthPro && (<Typography variant="caption" color="textSecondary">{t('onlyEhealthProUsers')}</Typography>)}

            </Box>
        );
    };

    // Memoize permission list for rendering
    const permissionsList = useMemo(() => {
        return allPermissions?.map(entity => (
            entity.permissions?.length > 0 && (
                <Accordion key={entity.id} sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', '&:before': { display: 'none' } }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ margin: '0 0 10px 0', padding: 0, backgroundColor: '#ebeef4', borderRadius: '5px', display: 'flex', flexDirection: 'row-reverse', gap: '5px' }}
                    >
                        <Tooltip title={entity.entityDescription} arrow>
                            <Typography variant="h6" component="div">{entity.entityName}</Typography>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails sx={{ margin: 0, padding: 0 }}>
                        {entity.permissions.map(permission => (
                            <Box key={permission.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
                                <Tooltip title={generateTooltipContent(permission, t)} arrow>
                                    <Typography>{permission?.PermissionName[0]?.label}</Typography>
                                </Tooltip>
                                <Switch
                                    disabled={rolePermissionsArray.includes(permission.id)}
                                    checked={rolePermissionsArray.includes(permission.id) || customPermissionsArray.has(permission.id)}
                                    onChange={(e) => handleSwitchChange(permission, e.target.checked)}
                                    color="primary"
                                />
                            </Box>
                        ))}
                    </AccordionDetails>
                </Accordion>
            )
        ));
    }, [allPermissions, customPermissionsArray, handleSwitchChange, rolePermissionsArray, t]);

    return (
        <Page title={t("Edit_CustomPermission_Page_Title")}>

            <Stack marginBottom={2}>
                <Box display={'flex'} justifyContent={'space-between'} marginBottom={2}>
                    <Box display={'flex'}  >
                        <Typography fontWeight={700}>{t('fullName')} : </Typography>
                        <Typography className='imeah_propre_name'>{institutionUserData?.firstname} {institutionUserData?.lastname}</Typography>
                    </Box>
                    <Box display={'flex'}>
                        <Typography fontWeight={700}>{t('institution')} : </Typography>
                        <Typography>{institutionUserData?.institutionName}</Typography>
                    </Box>
                    <Box display={'flex'}>
                        <Typography fontWeight={700}>{t('role')} : </Typography>
                        <Typography>{institutionUserData?.roleName}</Typography>
                    </Box>
                </Box>
            </Stack>

            {permissionsList}

            <Box sx={{ display: 'flex', gap: '5px', position: 'fixed', bottom: '10px', right: '15px', zIndex: '1' }}>
                <Button className='new-button' onClick={() => navigate(-1)}>{t("cancel")}</Button>
                <Button className='new-button' disabled={permissionToReview?.length <= 0} onClick={() => setOpenReviewPermission(true)}>{t("reviewPermission")}</Button>
                <Button className='new-button' onClick={editPermissions}>{t("save")}</Button>
            </Box>

            {openReviewPermission &&
                <ReviewPermissions
                    openReviewPermission={openReviewPermission}
                    setOpenReviewPermission={setOpenReviewPermission}
                    permissions={permissionToReview}
                />
            }

        </Page>
    )
}