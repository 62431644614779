import { Typography, IconButton, InputAdornment, TextField, Box, Alert } from "@mui/material";
import { CustomErrorLogger } from "../../utils/CustomErrorLogger";
import { LoginChooseInstitution } from "./loginChooseInstitution";
import { LanguageSwitcher } from "../NoLoginLanguageSwitcher";
import ImeahLogo from "../../images/logo_e_health_pro.svg";
import { useFormik, Form, FormikProvider } from "formik";
// import { Link as RouterLink } from "react-router-dom";
import React, { useState, useCallback } from "react";
import { CurrentPolicy } from "./currentPolicy";
import loginImage from "../../images/login.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { MFACode } from "./MFACode";
import Iconify from "../Iconify";
import i18next from "i18next";
import Page from "../Page";
import * as Yup from "yup";
import axios from "axios";
import "./newLoginForm.css";

export const NewLoginForm = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [authData, setAuthData] = useState({
    token: "",
    refreshToken: "",
    institutionUsers: [],
    selectedInstitutionId: "",
    requireVerificationCode: false,
    requireBrowserTrust: false,
    requireSelectInstitution: false,
    requireLoginPolicies: false,
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [language, setLanguage] = useState(i18next.language || 'en');

  const handleLangUpdate = useCallback((newLanguage) => setLanguage(newLanguage), []);

  const updateAuthData = useCallback((newData) => {
    setAuthData((prev) => ({ ...prev, ...newData }));
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      hpassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t("validEml")).required(t("emailReq")),
      hpassword: Yup.string().required(t("pwdReq")),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async () => {

      try {
        setLoading(true);
        setErrorMessage(null);

        const bodyData = {
          email: values.email || "",
          hpassword: values.hpassword || "",
          language: language || "en",
        };

        const { data, status } = await axios.post("auth/signin", bodyData, { withCredentials: true });

        if (status === 200 || status === 201) {
          const { access_token, refresh_token, userData, requireLoginPolicies, requireVerificationCode, requireBrowserTrust } = data;

          const requireSelectInstitution = userData?.InstitutionUsers?.length > 1 || false;

          updateAuthData({
            token: access_token || "",
            refreshToken: refresh_token || "",
            institutionUsers: userData?.InstitutionUsers || [],
            selectedInstitutionId: userData?.InstitutionUsers[0]?.institution?.id || "",
            requireVerificationCode: requireVerificationCode || false,
            requireLoginPolicies: requireLoginPolicies || false,
            requireSelectInstitution,
            requireBrowserTrust: requireBrowserTrust || false,
          });

          if (!requireVerificationCode && !requireLoginPolicies && !requireSelectInstitution) {
            storeAuthData(access_token, refresh_token);
          }
        }
      } catch (error) {
        console.error("Login Error:", error.message);
        setErrorMessage(error.message);
        CustomErrorLogger(error, "Login Error");
      } finally {
        setLoading(false);
      }
    }
  });

  const storeAuthData = useCallback((token, refreshToken) => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      window.dispatchEvent(new Event("storage"));
      navigate("/dashboard/app");
    } catch (error) {
      console.error("Storage Error:", error.message);
      setErrorMessage(error.message);
      CustomErrorLogger(error, "login storage auth data error");
    }
  }, [navigate]);

  const { errors, touched, values, handleBlur, handleSubmit } = formik;

  const renderAdditionalComponent = () => {
    if (authData.requireVerificationCode || authData.requireBrowserTrust) {
      return (
        <MFACode
          email={values.email || ""}
          authData={authData}
          updateAuthData={updateAuthData}
          storeAuthData={storeAuthData}
        />
      );
    } else if (authData.requireSelectInstitution) {
      return (
        <LoginChooseInstitution
          authData={authData}
          updateAuthData={updateAuthData}
          storeAuthData={storeAuthData}
        />
      );
    } else if (authData.requireLoginPolicies) {
      return (
        <CurrentPolicy
          authData={authData}
          updateAuthData={updateAuthData}
          storeAuthData={storeAuthData}
        />
      );
    }
    return null;
  };

  return (
    <Page title={t("loginn")} className="login-page">

      <Box className="login-Box">

        <Box display='flex' justifyContent='space-between' marginBottom='35px'>
          <Box sx={{ width: '200px' }} component="img" src={ImeahLogo} alt="Imeah Logo" />
          <LanguageSwitcher handleLangUpdate={handleLangUpdate} />
        </Box>

        <Box className='loginForm_image_box'>

          <Box className='loginForm_box'>
            <Typography className="login_title">{t("loginn")}</Typography>
            <Box className="login-visit-web">
              <Typography className="login_newHere">{t('newHere')}</Typography>
              <Typography className="login_visitWeb" onClick={() => navigate('/home')}>{t('visit-web')}</Typography>
            </Box>

            {errorMessage && <Alert sx={{ marginBottom: '25px' }} severity="error">{errorMessage}</Alert>}

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit} className="login-submitForm">
                <TextField
                  className="customTextField"
                  id="email"
                  name="email"
                  fullWidth
                  autoComplete="username"
                  defaultValue={values.email || ""}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  label={t("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={(touched.email && errors.email)}
                />

                <TextField
                  className="customTextField"
                  id="hpassword"
                  name="hpassword"
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label={t("password")}
                  defaultValue={values.hpassword || ""}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                          <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.hpassword && errors.hpassword)}
                  helperText={(touched.hpassword && errors.hpassword)}
                />

                <Box className='secondaryActionBox'>
                  {/* <Box className='registerLinkBox'>
                    <Typography className="registerLinkLabel">{t("noAccount")}</Typography>
                    <Link className="registerLink" component={RouterLink} to="/register">{t("getStartedd")}</Link>
                  </Box> */}
                  <Typography className="login_visitWeb" onClick={() => navigate('/forgotPassword')}>{t("forgotPassword")}</Typography>
                </Box>

                <LoadingButton className="login-loginButton" loading={loading} type="submit" >{t("loginn")}</LoadingButton>
              </Form>
            </FormikProvider>

          </Box>

          <Box className='image_box'>
            <img src={loginImage} alt="Leaderboard" />
          </Box>

        </Box>
      </Box>

      <Box id="additional-component-container">
        {renderAdditionalComponent()}
      </Box>

    </Page>
  );
}