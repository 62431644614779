import axios from "axios";
import jwt_decode from "jwt-decode";

let isRefreshing = false;  // Flag to check if a refresh operation is in progress
let refreshQueue = [];     // Queue to hold failed requests during token refresh

// Function to process the queued requests after the token is refreshed
const processQueue = (error, token = null) => {
  refreshQueue.forEach((promise) => {
    if (token) {
      promise.resolve(token);  // Resolve each request with the new token
    } else {
      promise.reject(error);   // Reject the request if token refresh failed
    }
  });
  refreshQueue = [];  // Clear the queue after processing
};

// Axios interceptor for handling 401 Unauthorized responses
axios.interceptors.response.use(
  (response) => response,  // Let successful responses pass through
  async (error) => {
    const originalRequest = error.config; // Get the original request that failed

    // Check if the error is 401 and the request hasn't been retried yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('refreshToken')}`;
      originalRequest._retry = true;  // Mark the request as retryable

      if (!isRefreshing) {
        isRefreshing = true;  // Start the token refresh process

        // Extract the institutionId from the current access token (if available)
        let institutionId = null;
        const currentToken = localStorage.getItem('token');
        if (currentToken) {
          try {
            const decodedToken = jwt_decode(currentToken);

            institutionId = decodedToken?.payLoad?.institutionId || null;
          } catch (decodeError) {
            console.error("Error decoding token:", decodeError);
          }
        }

        try {
          // Call the API to refresh the token using the refresh token
          const response = await axios.post("/auth/refresh", { institutionId });

          if (response?.status === 200 || response?.status === 201) {
            const newAccessToken = response.data.accessToken;  // Extract new access token
            const newRefreshToken = response.data.refreshToken;  // Extract new refresh token

            // Update tokens in local storage
            localStorage.setItem("token", newAccessToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            // Notify other tabs or components about token changes
            window.dispatchEvent(new Event("storage"));

            // Update the Authorization header globally with the new access token
            axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

            // Process all the requests in the queue with the new token
            processQueue(null, newAccessToken);

            // Check if the original request URL includes `auth/signout`
            if (originalRequest.url.includes('auth/signout')) {
              // Modify the request body with the new refresh token
              originalRequest.data = { refreshToken: response.data.refreshToken };
            }

            // Update the Authorization header for the original request
            originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

            // Retry the original request that failed due to 401 with the new token
            return axios(originalRequest);
          }
        } catch (refreshError) {
          // If refreshing the token fails, reject all queued requests
          processQueue(refreshError, null);
          // Clear local storage and redirect to login page
          localStorage.clear();
          window.location.href = "/login";
        } finally {
          // End the token refresh process
          isRefreshing = false;
        }
      } else {
        // If a refresh operation is already in progress, queue the failed request
        return new Promise((resolve, reject) => {
          refreshQueue.push({
            resolve: (token) => {
              // Retry the original request with the new token
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              resolve(axios(originalRequest));
            },
            reject: (err) => reject(err),
          });
        });
      }
    }

    // If the error is not 401 or if token refresh is handled, return the error
    return Promise.reject(error.response?.data || error.message);
  }
);