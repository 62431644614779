/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Typography, Select, MenuItem } from '@mui/material';
import { HasPermission } from "../../utils/checkUserPermission";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import axios from 'axios';
import '../../css/dashboard/dashboardApp.css'

export const MedicalFileTemplatesStatistics = () => {

    const { t } = useTranslation();
    const currentInstitution = useSelector(state => state.currentInstitution);

    const isAllowedReadMyInstitutionTemplate = HasPermission('read_myInstitution_template');
    const isAllowedReadMyOwnTemplate = HasPermission('read_myOwn_template');

    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [targetTemplateFilter, setTargetTemplateFilter] = useState('');

    const handleFetchMedicalFileTemplatesTypeCounts = async () => {
        try {
            const response = await axios.get(`dashboard/medicalFileTemplatesStatusCounts`, { params: { targetTemplateFilter } });
            if (response.status === 200 || response.status === 201) {
                setSeries(response.data.series)
                setLabels(response.data.labels)
            }
        } catch (error) {
            console.error('Error Fetch Medical File Templates Type Counts:', error.message);
        }
    };

    useEffect(() => {
        setTargetTemplateFilter(isAllowedReadMyInstitutionTemplate ? 'institution' : 'mine')
    }, [currentInstitution]);

    useEffect(() => {
        if (!currentInstitution || !targetTemplateFilter) { return }; // Early return for unauthenticated users
        handleFetchMedicalFileTemplatesTypeCounts();
    }, [currentInstitution, targetTemplateFilter]);

    const chartOptions = {
        chart: {
            width: '100%',
            type: 'pie',
        },
        labels: labels,
        dataLabels: {
            enabled: false // Disable data % on chart
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <Card className='dashboard-pie-chart-box'>
            <Typography variant="h6"> {t('Medical_File_template_Statistics')}</Typography>

            <Select
                fullWidth
                id="setTargetFilesFilter"
                name="setTargetFilesFilter"
                value={targetTemplateFilter}
                onChange={(e) => setTargetTemplateFilter(e.target.value)}
            >
                {isAllowedReadMyInstitutionTemplate && <MenuItem value="institution">{t('Institution_Templates')}</MenuItem>}
                {isAllowedReadMyOwnTemplate && <MenuItem value="mine">{t('myTemplates')}</MenuItem>}
            </Select>

            {series.length > 0 && <Chart options={chartOptions} series={series} type="pie" />}
        </Card>
    );
};