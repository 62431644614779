import ImeahActionIcon from '../../../../../utils/imeah_action_icon';
import { DeleteRecommandationField } from '../../deleteCustomField';
import { Box, Typography, Divider, TextField } from '@mui/material';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../Iconify';
import DocumentBox from './DocumentBox';
import UploadArea from './uploadArea';
import { saveAs } from 'file-saver';
import axios from 'axios';
import '../../../../../css/medicalFiles/fieldType/file.css';

export const RecommandationFileField = ({
    recommandationId,
    info,
    data,
    setDocuments,
    Documents,
    debouncedHandleUpdateOneFieldData,
    canModifyRecommandation,
    medicalFileData,
    setMedicalFileData
}) => {

    const { t } = useTranslation();

    const [editLabelStatus, setEditLabelStatus] = useState(info.editLabelStatus || false);
    const [openDeleteField, setOpenDeleteField] = useState(false);
    const [label, setLabel] = useState(info.label || '');

    const handleEditLabel = useCallback(async () => {
        try {
            if (!label) return;

            const bodyData = {
                medicalFileId: medicalFileData.id,
                fieldId: info.id,
                label,
            };

            const response = await axios.patch(`recommandation/editFieldLabel/${medicalFileData?.recommandation?.id}`, bodyData);

            if (response.status === 200 || response.status === 201) {
                setMedicalFileData(prevData => ({ ...prevData, recommandation: { ...prevData.recommandation, messages: response.data } }));
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            setEditLabelStatus(false);
        }
    }, [info.id, label, medicalFileData.id, medicalFileData?.recommandation?.id, setMedicalFileData])

    const handleCloseEditLable = useCallback(() => {
        setEditLabelStatus(false);
        setLabel(info.label);
    }, [info.label]);

    const handleDeleteDocument = useCallback((fileDocument) => {
        const data = { isNeedFileLogs: false };

        axios.delete(`file-documents/${fileDocument.id}`, data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setDocuments((prevDoc) => prevDoc.filter(doc => doc.documentId !== fileDocument.documentId));
            }
        })
            .catch((err) => console.log(err));
    }, [setDocuments]);

    const handleDownload = useCallback(async (document) => {
        try {
            const response = await axios.get(`/documents/${document.id}`, { responseType: 'blob' });
            if (response.status === 200 || response.status === 201) {
                //  Create a Blob object from the response data.
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                //Save the Blob as a file with the given filename.
                saveAs(blob, document.fileName);
            }
        } catch (error) {
            console.error('Error downloading document:', error);
        }
    }, []);

    const filteredDocuments = useMemo(() => {
        return Documents ? Documents?.filter(doc => doc.fieldId === info.id) : [];
    }, [Documents, info.id]);

    return (
        <Box key={info.id} className='fieldType_container'>

            <Box className='fieldType_header_container'>

                <Box className='fieldType_label_container'>

                    {editLabelStatus ?
                        (
                            <Box className='fieldType_edit_label_field_box'>
                                <TextField
                                    className='customTextField'
                                    type='text'
                                    id={`labelfield-${info.id}`}
                                    placeholder={t('Title')}
                                    defaultValue={typeof label === 'string' ? label : ''}
                                    onChange={(event) => setLabel(event.target.value)}
                                    error={!label}
                                    helperText={!label && t('isRequired')}
                                />
                                {label && (
                                    <Box className='fieldType_label_actions_container'>
                                        <Iconify
                                            onClick={handleEditLabel}
                                            icon="mdi:check"
                                            className='fieldType_valid_label_icon'
                                        />
                                        <Divider orientation="vertical" flexItem />
                                        <Iconify
                                            onClick={handleCloseEditLable}
                                            icon="material-symbols:close-rounded"
                                            className='fieldType_cancel_label_icon'
                                        />
                                    </Box>
                                )}
                            </Box>
                        ) :
                        (<Typography className='medicalFileFields_Field_label' >{info.label}</Typography>)
                    }

                    {canModifyRecommandation && !editLabelStatus &&
                        <Iconify
                            onClick={() => setEditLabelStatus(true)}
                            icon='fe:edit'
                            className='fieldType_edit_label_icon'
                        />
                    }
                </Box>

                {canModifyRecommandation &&
                    <ImeahActionIcon icon="ic:round-delete" color='#727179' onClick={() => setOpenDeleteField(true)} />
                }
            </Box>

            {(info.value || canModifyRecommandation) &&
                <TextField
                    className='customTextField'
                    fullWidth
                    type='text'
                    id={`document_description-${info.id}`}
                    multiline
                    disabled={!canModifyRecommandation}
                    minRows={2}
                    maxRows={Infinity}
                    name='value'
                    placeholder={t('document_description')}
                    defaultValue={typeof info.value === 'string' ? info.value : ''}
                    onChange={event => debouncedHandleUpdateOneFieldData(recommandationId, info.id, event.target.value)}
                />
            }

            {filteredDocuments?.map((fileDocument) => (
                <Box key={fileDocument.id}>
                    <DocumentBox
                        fileDocument={fileDocument}
                        handleDownload={handleDownload}
                        handleDeleteDocument={handleDeleteDocument}
                        canModifyRecommandation={canModifyRecommandation}
                    />
                </Box>
            ))}

            {canModifyRecommandation &&
                <UploadArea
                    info={info}
                    data={data}
                    setDocuments={setDocuments}
                />
            }

            {openDeleteField &&
                <DeleteRecommandationField
                    medicalFileData={medicalFileData}
                    setMedicalFileData={setMedicalFileData}
                    openDeleteField={openDeleteField}
                    setOpenDeleteField={setOpenDeleteField}
                    field={info}
                />
            }

        </Box>
    );
};