import { Box, Stack, Typography, Accordion, AccordionDetails, Tooltip, Button, Alert, Switch, AccordionSummary, TextField, FormControlLabel } from "@mui/material";
import { ReviewPermissions } from "../Permissions/permission/reviewPermissions";
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Iconify from "../Iconify";
import * as Yup from 'yup';
import Page from "../Page";
import axios from 'axios';

export const AddRole = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);

    const [englishName, setEnglishName] = useState('');
    const [frenchName, setFrenchName] = useState('');
    const [canHaveSpeciality, setCanHaveSpeciality] = useState(false);
    const [canHaveExpertise, setCanHaveExpertise] = useState(false);
    const [allPermissions, setAllPermissions] = useState([]);
    const [selectedPermissionIds, setSelectedPermissionIds] = useState(new Set());
    const [permissionMap, setPermissionMap] = useState(new Map());
    const [errMsg, setErrMsg] = useState(null);
    const [openReviewPermission, setOpenReviewPermission] = useState(false);

    const validationSchema = Yup.object().shape({
        enLabel: Yup.string().trim().required(t('roleLableRequired')),
        frLabel: Yup.string().trim().optional(),
        canHaveSpeciality: Yup.boolean().optional(),
        canHaveExpertise: Yup.boolean().optional(),
        canBeDelete: Yup.boolean().optional(),
        permissions: Yup.array().min(1, t('rolePermissionsRequired')),
    });

    // Fetch permissions once on component mount
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await axios.get('permission');
                if (response.status === 200 || response.status === 201) {
                    setAllPermissions(response.data);
                    setPermissionMap(buildPermissionMap(response.data));
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchPermissions();
    }, [userData.local_code]);

    // Create a flat lookup map for all permissions
    const buildPermissionMap = (permissionsData) => {
        const permissionMap = new Map();

        permissionsData.forEach((entity) => {
            entity.permissions.forEach((permission) => {
                permissionMap.set(permission.id, permission);
            });
        });

        return permissionMap;
    };

    const permissionToReview = useMemo(() => {
        return Array.from(selectedPermissionIds).map(permissionId => permissionMap.get(permissionId)).filter(Boolean);
    }, [permissionMap, selectedPermissionIds]);

    // Helper function to get all dependencies recursively
    const getAllDependencies = useCallback((dependencyId, visited = new Set()) => {

        if (visited.has(dependencyId)) return []; // Prevent cycle
        visited.add(dependencyId);

        const result = new Set([dependencyId]);
        const dependency = permissionMap.get(dependencyId);

        if (dependency && dependency?.childDependencies) {
            dependency?.childDependencies?.forEach((parent) => {
                const parentId = parent.parentPermissionId;
                if (parentId) {
                    const nestedDependencies = getAllDependencies(parentId, visited);
                    nestedDependencies.forEach(dep => result.add(dep));
                }
            });
        }

        return Array.from(result);
    }, [permissionMap]);

    const getReverseDependencies = useCallback((dependencyId, visited = new Set()) => {

        if (visited.has(dependencyId)) return []; // Prevent cycle
        visited.add(dependencyId);

        const result = new Set([dependencyId]);
        const dependency = permissionMap.get(dependencyId);

        if (dependency && dependency?.parentDependencies) {
            dependency?.parentDependencies?.forEach((child) => {
                const childId = child.childPermissionId;
                if (childId) {
                    const nestedDependencies = getReverseDependencies(childId, visited);
                    nestedDependencies.forEach(dep => result.add(dep));
                }
            });
        }

        return Array.from(result);
    }, [permissionMap]);

    const handleSwitchChange = useCallback((permission, checked) => {

        setSelectedPermissionIds((prevIds) => {

            const newIds = new Set(prevIds);

            if (newIds.has(permission.id) && !checked) {
                // Remove the current permission and uncheck its children
                const reverceDependencies = getReverseDependencies(permission.id);
                reverceDependencies.forEach((depId) => newIds.delete(depId));
                newIds.delete(permission.id);
            } else {
                // Add the current permission and check its parents
                newIds.add(permission.id);
                const dependencies = getAllDependencies(permission.id);
                dependencies.forEach((depId) => newIds.add(depId));
            }

            return newIds;
        });
    }, [getAllDependencies, getReverseDependencies]);

    const handleAddRole = async () => {
        try {
            const bodyData = {
                enLabel: englishName.trim(),
                frLabel: frenchName.trim(),
                canHaveSpeciality,
                canHaveExpertise,
                canBeDelete: true,
                permissions: Array.from(selectedPermissionIds),
            };

            await validationSchema.validate(bodyData, { abortEarly: false });

            const response = await axios.post(`roles`, bodyData);
            if (response.status === 200 || response.status === 201) {
                navigate(-1);
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                // If validation fails, set the error message
                setErrMsg(err?.errors?.join(` ${t('and')} `));
            } else {
                // If API call fails, set the error message
                console.error('Error adding role:', err);
                setErrMsg(err.message);
            }
        }
    };

    // Function to generate tooltip content
    const generateTooltipContent = (permission, t) => {
        return (
            <Box>
                {permission?.PermissionDescription[0]?.label && (
                    <Typography variant="caption" color="textSecondary">{permission.PermissionDescription[0].label}</Typography>
                )}
                {permission.parentDependencies?.length > 0 && (
                    <Typography variant="caption" component="div" color={"#FFA500"}>
                        {t('parentPermissions')}: {permission.parentDependencies.map(parent => parent?.childPermission?.PermissionName[0]?.label)?.join(' or ')}
                    </Typography>
                )}
                {permission.childDependencies?.length > 0 && (
                    <Typography variant="caption" component="div" color={"#E53935"}>
                        {t('childPermissions')}: {permission.childDependencies.map(parent => parent?.parentPermission?.PermissionName[0]?.label)?.join(' and ')}
                    </Typography>
                )}
            </Box>
        );
    };

    // Memoize permission list for rendering
    const permissionsList = useMemo(() => {
        return allPermissions?.map(entity => (
            entity.permissions?.length > 0 && (
                <Accordion key={entity.id} sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', '&:before': { display: 'none' } }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ margin: '0 0 10px 0', padding: 0, backgroundColor: '#ebeef4', borderRadius: '5px', display: 'flex', flexDirection: 'row-reverse', gap: '5px' }}
                    >
                        <Tooltip title={entity.entityDescription} arrow>
                            <Typography variant="h6" component="div">{entity.entityName}</Typography>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails sx={{ margin: 0, padding: 0 }}>
                        {entity.permissions.map(permission => (
                            <Box key={permission.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
                                <Tooltip title={generateTooltipContent(permission, t)} arrow>
                                    <Typography>{permission?.PermissionName[0]?.label}</Typography>
                                </Tooltip>
                                <Switch
                                    checked={selectedPermissionIds.has(permission.id)}
                                    onChange={(e) => handleSwitchChange(permission, e.target.checked)}
                                    color="primary"
                                />
                            </Box>
                        ))}
                    </AccordionDetails>
                </Accordion>
            )
        ));
    }, [allPermissions, handleSwitchChange, selectedPermissionIds, t]);

    return (
        <Page title={t("AddNewRole")}>

            <Stack className="role-main-page-header">
                <Box className="role-page-title-box">
                    <Box className="role-page-title-icon-box">
                        <Iconify className="role-page-title-icon" icon="material-symbols:workspace-premium" />
                        <Typography className='role-page-title'>{t("AddNewRole")}</Typography>
                    </Box>
                </Box>
            </Stack>

            {errMsg && <Alert severity="error">{errMsg}</Alert>}

            <TextField
                margin="dense"
                id="name"
                name="name"
                label={t('Eng')}
                type="text"
                fullWidth
                required
                variant="outlined"
                value={englishName}
                onChange={(e) => setEnglishName(e.target.value)}
                error={!Yup.reach(validationSchema, 'enLabel').isValidSync(englishName)}
                helperText={!Yup.reach(validationSchema, 'enLabel').isValidSync(englishName) && t('isRequired')}
            />
            <TextField
                margin="dense"
                id="name"
                name="name"
                label={t('Fr')}
                type="text"
                fullWidth
                variant="outlined"
                value={frenchName}
                onChange={(e) => setFrenchName(e.target.value)}
            />

            <FormControlLabel
                control={<Switch checked={canHaveSpeciality} onChange={() => setCanHaveSpeciality(!canHaveSpeciality)} />}
                label={t('canHaveSpeciality')}
            />
            <FormControlLabel
                control={<Switch checked={canHaveExpertise} onChange={() => setCanHaveExpertise(!canHaveExpertise)} />}
                label={t('canHaveExpertise')}
            />

            <Typography sx={{ marginBottom: '15px', marginTop: '15px', fontWeight: '700', color: '#17536B' }}>{t("manageRolePermissions")} :</Typography>

            {permissionsList}

            <Box sx={{ display: 'flex', gap: '5px', position: 'fixed', bottom: '10px', right: '15px', zIndex: '1' }}>
                <Button onClick={() => navigate(-1)} className='new-button'>{t('cancel')}</Button>
                <Button className='new-button' disabled={permissionToReview?.length <= 0} onClick={() => setOpenReviewPermission(true)}>{t("reviewPermission")}</Button>
                <Button className='new-button' onClick={handleAddRole}>{t('Create')}</Button>
            </Box>

            {openReviewPermission &&
                <ReviewPermissions
                    openReviewPermission={openReviewPermission}
                    setOpenReviewPermission={setOpenReviewPermission}
                    permissions={permissionToReview}
                />
            }

        </Page>
    )
}