import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import axios from 'axios';

export const RemoveSection = ({ sectionForRemove, template, setTemplate, showRemoveSection, setShowRemoveSection, setCurrentSection }) => {

    const { t } = useTranslation();

    const handleRemoveSection = useCallback(async () => {
        try {
            const data = {
                sectionForRemove
            };

            const response = await axios.patch(`medical-file-templates/removesection/${template.id}`, data);

            if (response.status === 200 || response.status === 201) {
                setTemplate((prevData) => ({ ...prevData, sections: response.data.sections, additional_fields: response.data.additional_fields }));
                setCurrentSection('');
                setShowRemoveSection(false);
            }
        } catch (err) {
            console.log(err, 'Error removing section from template');
            CustomErrorLogger(err, 'Error removing section from template');
        }
    }, [sectionForRemove, template.id, setTemplate, setCurrentSection, setShowRemoveSection]);

    return (
        <Dialog open={showRemoveSection} keepMounted onClose={() => setShowRemoveSection(false)}>
            <DialogTitle>{t('byDeleteThisSectionRelatedDataWillBeDeleted')}</DialogTitle>
            <DialogActions>
                <Button className='new-button' fullWidth onClick={() => setShowRemoveSection(false)}>{t('cancel')}</Button>
                <Button className='new-button' fullWidth onClick={handleRemoveSection}>{t('dlt')}</Button>
            </DialogActions>
        </Dialog>
    );
};