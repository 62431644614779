export const versionLogsData = [
    {
        date: "2025-03-19",
        version: "1.12.11",
        features: [
            "TN-1279 - Apply a new concept and design to the medical files list.",
            "TN-1280 - Improve the selected language behavior when a default language is set.",
            "TN-1281 - Apply a new approach to resolve the white page issue related to React DOM rendering.",
            "TN-1282 - Redesign the login page.",
            "TN-1283 - Redesign the Forgot Password page.",
            "TN-1284 - Redesign the Create Password page.",
        ]
    },
    {
        date: "2025-03-10",
        version: "1.12.10",
        features: [
            "TN-1266 - Added verification mechanism for detecting and validating new browser logins.",
        ]
    },
    {
        date: "2025-02-28",
        version: "1.12.9",
        features: [
            "TN-1267 - Improved Login Function to Resolve Connection Issues.",
        ]
    },
    {
        date: "2025-02-25",
        version: "1.12.8",
        features: [
            "TN-1264 - Display an alert when auto-save fails in the Medical File changes.",
            "TN-1263 - Improved error messaging for blocked users.",
            "TN-1261 - Enhanced navigation in Medical File tabs by adding an arrow indicator.",
            "TN-1265 - Optimized the display of short and long text, particularly in read mode, within the Medical File."
        ]
    },
    {
        date: "2025-02-18",
        version: "1.12.7",
        features: [
            "TN-1259 - Implemented title management for all newly added fields in the Medical File and Expertise section.",
            "TN-1260 - Standardized field names in Medical Files and Medical File Templates to align with medical terminology."
        ]
    },
    {
        date: "2025-02-13",
        version: "1.12.6",
        features: [
            "TN-1257 - Ensured the selected medical file tab remains fixed upon page reload.",
            "TN-1256 - Updated the Medical File Complementary Information field to support long text instead of short text.",
            "TN-1258 - Fixed an issue where the Medical File field group title was not displaying correctly."
        ]
    },
    {
        date: "2025-02-11",
        version: "1.12.5",
        features: [
            "TN-1254 - Resolved conflicts in the select list values.",
            "TN-1252 - Enhanced document upload functionality when editing a field label.",
            "TN-1255 - Ensured the 'Add Field' title remains empty when creating a new field in the Medical File.",
            "TN-1253 - Fixed an issue with template selection when creating a Medical File.",
        ]
    },
    {
        date: "2025-02-04",
        version: "1.12.4",
        features: [
            "TN-1240 - Resolve the issue related to editing the Institution Type.",
            "TN-1242 - Enable automatic scrolling to the bottom when navigating to chat tabs in medicale file.",
            "TN-1245 - Implement the new design for the Duplicate and import Medical File page.",
            "TN-1251 - Add filtering options to the medical files list for improved search and organization.",
        ]
    },
    {
        date: "2025-01-26",
        version: "1.12.3",
        features: [
            "TN-1225 - Redesigned the patient information section for the Medical File to enhance clarity and accessibility.",
            "TN-1226 - Added an edit button to allow modifications of patient information within the Medical File.",
            "TN-1228 - Applied the updated application background color to be 'white' .",
            "TN-1229 - Updated the design of the 'Send Recommendation' button to align with the latest design guidelines.",
            "TN-1230 - Redesigned the confirmation dialog for field deletion to improve user experience.",
            "TN-1231 - Updated the design of the confirmation dialog for sending recommendations for better visual consistency.",
            "TN-1232 - Added functionality to manage the edit button for patient photo updates.",
            "TN-1234 - Reordered fields in the 'Show More' section of the patient details to prioritize consultant, specialty, and sex fields over phone number and address.",
            "TN-1236 - Improved the readability of the Medical File by hiding empty fields in view-only mode.",
            "TN-1237 - Removed redundant text ('This field cannot be edited') from view-only mode in the Medical File."
        ]
    },
    {
        date: "2025-01-21",
        version: "1.12.2",
        features: [
            "TN-1206 - Apply design enhancements to the Expertise field.",
            "TN-1210 - Apply design enhancements to the Medical File field.",
            "TN-1215 - Improve translations for better clarity and accuracy.",
            "TN-1216 - Hide the Medical File History section.",

        ]
    },
    {
        date: "2025-01-06",
        version: "1.12.1",
        features: [
            "TN-1187 - Resolve the issue with creating policies.",
        ]
    },
    {
        date: "2024-12-23",
        version: "1.12.0",
        features: [
            "TN-1134 - Removed 'View', 'Edit', and 'Delete' actions from list views; now only the 'View' action is visible, with 'Edit' and 'Delete' accessible within the 'View' screen.",
            "TN-1151 - Implemented alphabetical sorting of lists in ascending order.",
            "TN-1163 - Applied new design for the 'Create Medical File' page and stepper, improving usability and aesthetics.",
            "TN-1164 - Updated the design of the 'Medical File List' page to align with the new UI standards.",
            "TN-1165 - Redesigned the 'Medical File Details Action Buttons' section for a more intuitive user experience.",
        ]
    },
    {
        date: "2024-12-10",
        version: "1.11.3",
        features: [
            "TN-1128 - Fix translation bug for institution type in English mode.",
            "TN-1129 - Manage the Countries List based on a local table for both Institution Country and Policy Country.",
            "TN-1132 - Make the phone number optional for both institutions and users.",
            "TN-1133 - Enable the ability to modify or delete all institutions within the institution list where I have the appropriate permissions.",
            "TN-1137 - Remove the following dashboard widgets: Specialties Number, Expertise Number, and Select List Number.",
            "TN-1140 - Create a new dashboard widget to display the number of my medical files categorized by Specialties and Expertises within the same widget, linked to the 'Read My files', 'Read Institution Files', 'Read My Medical Files' permissions.",
            "TN-1142 - Sort the display of medical files by 'Change Date' with the most recently modified files appearing first.",
            "TN-1143 - Resolve the issue with selecting a specialty when inviting a user.",
            "TN-1144 - Allow users to assign the 'Coordinator' role when inviting a new user.",
            "TN-1145 - Improve the document upload and download process by implementing best practices for efficiency, reliability, and user experience.",
            "TN-1147 - Fix bug causing multiple email invitations to be sent.",
        ]
    },
    {
        date: "2024-12-03",
        version: "1.11.2",
        features: [
            "TN-1125 - Resolve the issue with reading a specific role.",
            "TN-1126 - Correct the translation for the email regarding sharing medical files. "
        ]
    },
    {
        date: "2024-11-25",
        version: "1.11.0",
        features: [
            "TN-1047 - When inviting a user, we only require their email address. There's no need to ask for their first name, last name, or phone number.",
            "TN-1092 - All simple lists are sorted alphabetically (case insensitive).",
            "TN-1097 - Fix Arabic Text problem in Medical Files PDF Export.",
            "TN-1111 - Fix bug of edit role on mobile mode.",
            "TN-1112 - Improve credentials management.",
        ]
    },
    {
        date: "2024-11-19",
        version: "1.10.0",
        features: [
            "TN-133 - Implemented multilingual support for roles, enabling translations into English and French.",
            "TN-1045 - Introduced institution-specific roles, ensuring roles are isolated and not visible across institutions.",
            "TN-1072 - Enhanced email link behavior for unauthenticated users, redirecting them to the login page instead of the website homepage.",
            "TN-1074 - Added multilingual support for institution types, allowing translations into English and French.",
            "TN-1066 - Improved the user experience for the Change Institution popover, especially when a user is added to a new institution.",
            "TN-1075- Established English as the default language fallback when translations are unavailable.",
            "TN-1070 - Enabled translation of select list name and options into English and French for improved localization.",
            "TN-1089 - Redesigned the Credentials Management page with a more intuitive layout and added multilingual support for credential labels.",
        ]
    },
    {
        date: "2024-10-25",
        version: "1.9.10",
        features: [
            "TN-1065 - Resolved naming roles issue affecting create/update actions."
        ]
    },
    {
        date: "2024-10-25",
        version: "1.9.9",
        features: [
            "TN-1063 - Fixed an issue with copying medical file templates, ensuring proper handling of tabs and fields."
        ]
    },
    {
        date: "2024-10-25",
        version: "1.9.8",
        features: [
            "TN-1062 - Disabled user request component to prevent direct requests to institutions.",
            "TN-1061 - Resolved naming template issue affecting create/copy/update actions.",
            "TN-1060 - Completed log implementation across all services for improved traceability."
        ]
    },
    {
        date: "2024-10-23",
        version: "1.9.7",
        features: [
            "TN-856 - Translate expertise and specialties.",
            "TN-1006 - Improve date format handling: English (MM/DD/YYYY) vs. French (DD/MM/YYYY).",
            "TN-1053 - Fix text issues on role screen.",
            "TN-1058 - Implement logging service with 10-day log rotation for enhanced traceability and performance.",
            "TN-1057 - Add translated page titles for all platform pages.",
            "TN-1056 - Fix bug related to creating/copying/importing medical file templates.",
            "TN-1050 - Restrict CRUD operations for specialties and expertise to ehealth-pro users.",
            "TN-1044 - Update medical file redirection concept."
        ]
    },
    {
        date: "2024-10-14",
        version: "1.9.6",
        features: [
            "TN-1042 - Resolve the bug related to creating a medical file (specialty and expertise selection).",
            "TN-1043 - Optimize data fetching when the platform initializes.",
        ]
    },
    {
        date: "2024-10-14",
        version: "1.9.5",
        features: [
            "TN-1036 - In the context of creating a collaboration, when the hub institution is changed, the associated specialties and expertises should be automatically reset to ensure relevance and accuracy. ",
            "TN-1037 - Resolve issues with text translations.",
            "TN-1038 - Sort the list of countries in alphabetical order. ",
            "TN-1039 - Enhance the password input field by adding an eye icon to toggle the visibility of the password.",
            "TN-1040 - Improve the functionality for updating account profile data.",
            "TN-1041 - Display the CRUD functionality for institution type credentials exclusively for ehealth-pro users. ",
        ]
    },
    {
        date: "2024-10-10",
        version: "1.9.4",
        features: [
            "TN-1032 - Enhance the document display functionality within medical files for improved efficiency and user experience. ",
        ]
    },
    {
        date: "2024-10-09",
        version: "1.9.3",
        features: [
            "TN-1033 - Fix bug of fetching specialities and expertises when create medical file. ",
            "TN-1034 - Replace Login error message to “Invalid login or password !” (EN) - “Login ou mot de passe invalides !” (FR). ",
        ]
    },
    {
        date: "2024-10-03",
        version: "1.9.2",
        features: [
            "TN-1031 - Fix bug displaying institution users list on mobile mode. ",
        ]
    },
    {
        date: "2024-09-03",
        version: "1.9.1",
        features: [
            "TN-981 - The French action 'Filtre' should be corrected to 'Filtrer' to align with the English version 'Filter.'",
            "TN-1003 - The French action 'Transfer' should be translated to 'Transférer' to ensure correct usage.",
            "TN-1004 - The text in the sidebar should be presented in lowercase for a more consistent and modern look.",
            "TN-1005 - The French spelling of 'Adresse' should be corrected to ensure accuracy in the medical file edit page.",
            "TN-1008 - The notification system in the medical file has been improved to provide a better user experience.",
            "TN-1009 - order by alpha all the drop down list in the platform to improve user experience.",
            "TN-1010 - The 'Hide' button has been removed from pending invitations in the invited users list page. It now remains visible only for refused and accepted invitations.",
            "TN-1012 - Policies created by the superAdmin are now automatically connected to the medical file when sharing or accessing files.",
            "TN-1024 - Same account can be open from several devices"
        ]
    },
    {
        date: "2024-08-06",
        version: "1.9.0",
        features: [
            "TN-945 - Prevent blocking create or rename actions if the name already exists for Tabs, Fields, Field Groups, and Complementary Information fields.",
            "TN-955 - Translate messages for user invitations.",
            "TN-972 - Apply policy to users based on Institution Country instead of User Country.",
            "TN-949 - Optimize data filtration in medical files and medical file templates.",
            "TN-950 - Enhance filtering fields and values on the Medical File List page.",
            "TN-971 - Improve styling of 'Tabs' in Medical File Templates and Medical Files.",
            "TN-975 - Align Institution name and User role name to the left in the top bar.",
            "TN-966 - Remove spaces in '(_' and '_)' for Institution in the Medical File List.",
            "TN-970 - Update invitation expiration and error message text.",
            "TN-951 - Update policy management system.",
            "TN-946 - Add 'Draft' or 'Validated' status to fields in the Complementary Information section.",
            "TN-967 - Increase space between 'X' (Clear filter) and 'V' (Select values) icons in filter fields for better mobile usability.",
            "TN-965 - Replace mandatory field required messages across all modules.",
            "TN-973 - Make the Country field mandatory for Institutions.",
            "TN-974 - Replace 'etablissement' with 'institution' in the French language across the platform."
        ]
    },
    {
        date: "2024-08-07",
        version: "1.8.2",
        features: [
            "TN-970: Change invitation expiration / error message text",
            "TN-977: Improve email notification text for unshare medical file",
            "TN-980: Redesign reset filters action with an icon and text:  `Reset filters` (EN) / `Re-initialiser` (FR)",
            "TN-981: Change French text from `Filtre` to `Filtrer`",
            "TN-982: Redesign close filters action to be near `Reset filters` with icon and text: `Hide filters` (EN) / `Cacher filtres` (FR)",
            "TN-983: Change filters zone title from `Filtre` to `Filters` (EN) / `Filtres` (FR)",
            "TN-984: Change and normalize filter names in medical file list",
            "TN-985: Rename `HospitalsTypes` module to `institutionsTypes` and translate to `Institutions types` (EN) / `Types d'établissements` (FR)",
            "TN-986: Reorder medical file fields with Birthdate, Creation Date, Last modification date as top 3",
            "TN-988: Change forget password error message",
        ]
    },
    {
        date: "2024-07-30",
        version: "1.8.1",
        features: [
            "Optimized page action naming.",
            "Added a close icon to clear filter data.",
            "Aligned table titles with the rest of the platform.",
            "Improved action title in medical file email notifications.",
            "Translated data related to medical files.",
            "Added country field for institutions.",
            "Applied a new concept to secure invitation links.",
            "Applied a new concept to secure forgot password links.",
            "Optimized the display of expertises and specialities on the collaboration read page.",
            "Fixed background issue related to displaying PDF documents in medical files."
        ]
    },
    {
        date: "2024-07-23",
        version: "1.8.0",
        features: [
            "Added a link in medical file status change email notifications.",
            "Optimized template filtering by type and owner.",
            "Redesigned forms across the platform.",
            "Introduced a 'Read One Institution' page.",
            "Added a clear icon for the search input field.",
            "Enhanced the forgot password process to be more secure and user-friendly.",
            "Enabled filtering by specialty and expertise on the collaboration page.",
            "Improved naming conventions for some credentials."
        ]
    },
    {
        date: "2024-07-16",
        version: "1.7.9",
        features: [
            "Applied new design for medical file templates.",
            "Applied new design for select lists page.",
            "Applied new design for users page.",
            "Applied new design for network management page.",
            "Applied new design for credential set page.",
            "Optimized permission titles in groups and profiles.",
            "Added email notification for changing medical file status.",
            "Improved scrolling in medical file chat.",
            "Enhanced translations"
        ]
    },
    {
        date: "2024-07-07",
        version: "1.7.8",
        features: [
            "Fixed bug related to creating new institutions.",
            "Fixed bug related to creating medical files.",
            "Applied new design for institutions page.",
            "Improved sorting data in the medical file list.",
            "Added success message when inviting a new user.",
            "Added expiration date for invitations.",
            "Enhanced translations.",
            "Applied new concept for accepting invitations."
        ]
    },
    {
        date: "2024-07-02",
        version: "1.7.7",
        features: [
            "Improved invitation email content and format for better clarity and user engagement.",
            "Redesigned collaboration details page.",
            "Improved select specialty and expertise process when creating, copying, or importing templates.",
            "Optimized navigation between read mode and edit mode in the medical file template.",
            "Added a link to visit the website on the login page.",
            "Moved import template action from read mode to the main action box in the medical file template.",
            "Enhanced translation syntax for better clarity and understanding across different languages.",
            "Applied smart date formatting for medical files.",
            "Optimized three-dot action button functionality."
        ]
    },
    {
        date: "2024-06-25",
        version: "1.7.6",
        features: [
            "Enhanced the leave edit mode for the medical file field by adding a check icon.",
            "Added a star icon for mandatory fields.",
            "Fixed a bug related to transferring medical files.",
            "Translation Improvements: Enhanced translation syntax for better clarity and understanding across different languages.",
            "Improved the design and functionality of all delete popup dialogs.",
            "Added a search filter to the county list in login policies.",
            "Changed the format of the validation code to only include numbers.",
            "Optimized the order of permissions in medical files.",
            "Improved data display in the collaboration list based on user permissions.",
            "Made the phone number optional in the medical file.",
            "Optimized the doctor selection process in the copy medical file feature.",
            "Moved the import medical file action button to the medical file details page.",
            "Added an action menu with three dots for mobile mode.",
            "Enhanced the search functionality in the medical files and institutions list.",
            "Fixed a bug related to the institutions list.",
            "Fixed a bug related to creating institutions."
        ]
    },
    {
        date: "2024-06-19",
        version: "1.7.5",
        features: [
            "Improved Invitation Email: Enhanced the content and format of invitation emails for better clarity and user engagement.",
            "Icons for Group Field Items in Medical File: Added icons to various group field items within medical files for better visual representation.",
            "Specialty and Expertise Selection: Enhanced the selection process for specialties and expertise when creating or editing collaborations, based on existing doctor data.",
            "Resolved an issue related to creating direct files from the users list",
            "User Profile Optimization: Optimized the user profile editing process, allowing for nullable fields for specialties or expertise.",
            "Medical File Template Tabs: Improved the tabs bar in medical file templates for better navigation and user experience.",
            "Mobile Mode Enhancements: Added the display of specialty or expertise in the user list when accessed via mobile devices.",
            "Translation Improvements: Enhanced translation syntax for improved clarity and understanding across different languages.",
        ]
    },
    {
        date: "2024-06-09",
        version: "1.7.4",
        features: [
            "Enhanced user invitation and role modification functionality.",
            "Implemented new design for medical file history.",
            "Redesigned medical file chat interface.",
            "Updated design for medical file tabs and expertise section.",
            "Improved design for collaboration features."
        ]
    },
    {
        date: "2024-06-05",
        version: "1.7.3",
        features: [
            "Introduced new design for creating medical files.",
            "Applied new design for user invitation and request management.",
            "Redesigned patient personal information section in medical files."
        ]
    },
    {
        date: "2024-05-31",
        version: "1.7.2",
        features: [
            "Enhanced Translation Sentences for Improved User Experience",
            "Display User Role in Institution List",
            "Added Thank You Alert After Feedback Submission"
        ]
    },
    {
        date: "2024-05-28",
        version: "1.7.1",
        features: [
            "New Design for Login and Invitation Pages",
            "Account Unblocking via Password Change",
        ]
    },
    {
        date: "2024-05-23",
        version: "1.7.0",
        features: [
            "Introduced a new display method for institutions",
            "Added a filter by distance (Km) for the user list",
            "Enhanced the invitation and request system",
            "Added a button for users to leave their current institution",
            "Moved invitations and requests to the user profile page"
        ]
    },
    {
        date: "2024-05-16",
        version: "1.6.9",
        features: [
            "Fixed a bug related to log-in policies",
            "Implemented a new concept for user invitations to the platform",
            "Reactivated the feedback system",
            "Added automatic permission checks within permission groups",
            "Added filtering options to the statistics dashboard",
            "Resolved a security issue"
        ]
    },
    {
        date: "2024-05-10",
        version: "1.6.8",
        features: [
            "Added import medical file feature",
            "Optimized select list functionality"
        ]
    },
    {
        date: "2024-05-09",
        version: "1.6.7",
        features: [
            "Enhanced Video Call Experience for Improved Communication and Engagement",
            "Resolved Bug related to speciality and expertise selection in Medical File Creation Functionality",
            "Addressed Notification Issues for Medical File Updates"
        ]
    },
    {
        date: "2024-05-02",
        version: "1.6.6",
        features: [
            "Introduce new display mode for medical file documents.",
            "Fix bug related to medical file notification.",
            "Optimize selection of speciality and expertise when creating a medical file.",
            "Enable users to export their Medical Files , including attached documents."
        ]
    },
    {
        date: "2024-04-26",
        version: "1.6.5",
        features: [
            "Apply auto check for related permissions in permission groups.",
            "Optimize sentences for better understanding.",
            "Add new module for managing institution types.",
            "Improve role management for specializations and expertise.",
            "Upgrade video call system for better quality and reliability."
        ]
    },
    {
        date: "2024-04-18",
        version: "1.6.4",
        features: [
            "New users added will wait for acceptance by the user.",
            "Redesign filtering in the medical file template bar.",
            "Optimize import/export templates with selectable lists.",
            "Optimize sentences for better understanding.",
            "Introduce new role for managing specializations and expertise.",
            "Upgrade video call system for better quality and reliability."
        ]
    },
    {
        date: "2024-04-05",
        version: "1.6.3",
        features: [
            "Optimize medical file template creation process to enhance efficiency.",
            "Introduce new role levels for specialists and experts to streamline access and permissions.",
            "Enhance search filters for sensitive data to bolster security and privacy",
            "Implement robust management for importing and exporting medical file templates.",
            "Upgrade video call system for better quality and reliability"
        ]
    },
    {
        date: "2024-03-26",
        version: "1.6.2",
        features: [
            "Optimize dashboard data presentation for improved usability and insights",
            "Enhance performance and resolve bugs in the medical file handling to ensure smooth operation"
        ]
    },
    {
        date: "2024-03-22",
        version: "1.6.1",
        features: [
            "Fix copying documents when copying medical files.",
            "Create dashboard for medical file status.",
            "Create dashboard for medical file template types."
        ]
    },
    {
        date: "2024-03-15",
        version: "1.6.0",
        features: [
            "Implement server improvements.",
            "Add multi-login policy based on countries.",
            "Add translatable login policies."
        ]
    },
    {
        date: "2024-03-04",
        version: "1.5.9",
        features: [
            "Fix notification bugs.",
            "Fix medical file filtration bug.",
            "Improve page titles and descriptions.",
            "Improve medical file chat system."
        ]
    },
    {
        date: "2024-02-22",
        version: "1.5.8",
        features: [
            "Improve platform data security.",
            "Include strong password strategy."
        ]
    },
    {
        date: "2024-02-20",
        version: "1.5.7",
        features: [
            "Addressed various stability and performance issues across the platform.",
            "Resolved multiple bugs related to user interface inconsistencies.",
            "Implemented backend optimizations for improved performance"
        ],
    },
    {
        date: "2024-02-15",
        version: "1.5.6",
        features: [
            "Users can conveniently set their availability within their institution.",
            "Users have the ability to upload their CV document to enhance their profile.",
            "Users can pinpoint and set their location on a map for precise identification.",
            "Doctors can easily specify their consultation fees for services offered.",
            "Doctors have the flexibility to define the duration for responding to user requests.",
            "All platform activities are tied to specific credentials; users can perform actions based on their designated credentials.",
        ],
    },
    {
        date: "2024-02-12",
        version: "1.5.5",
        features: [
            "Improved the navigation flow from notification to destination, reducing unnecessary steps.",
            "Develop a two-factor authentication (2FA) system specifically tailored for institutions.",
            "Add an account blocking mechanism and its purpose to prevent unauthorized access."
        ],
    }
]