/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table, FormControl, Select, InputLabel, MenuItem, TableHead, TableRow, Divider, TableBody, useMediaQuery, InputAdornment, TableCell,
  LinearProgress, Typography, Stack, Card, Box, Grid, IconButton, Menu
} from '@mui/material';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import { customInputStyles } from '../utils/CustomInputStyle';
import { HasPermission } from "../utils/checkUserPermission";
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState, useEffect, useRef } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../components/Scrollbar';
import { useTheme } from '@mui/material/styles';
import { fDateTime } from '../utils/formatTime';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { act } from '@testing-library/react';
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import axios from 'axios';
import '../css/collaboration/collaborationInstitution.css';

export default function CollaborationInstitution() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [anchorEl, setAnchorEl] = useState(null);
  const openActionMenu = Boolean(anchorEl);
  const userData = useSelector(state => state.userData);
  //check user permissions
  const isAllowedCreateAllCollaboration = HasPermission('Create_All_Institutoins_Collaborations');
  const isAllowedCreateRelatedInstituionsCollaboration = HasPermission('Create_Institution_Collaborations');

  const [institutionsForFilter, setInstitutionsForFilter] = useState([]);
  const [collaborationData, setCollaborationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState("");
  //filtration states
  const [filter, setFilter] = useState("");
  //pagination state
  const hasMore = useRef(null);
  const offset = useRef(0);
  const limit = 20;

  useEffect(() => {
    if (userData) {
      getCollaborationData()
    }
  }, [userData, filter]);

  useEffect(() => {
    if (userData) {
      axios.get("collaboration-institution/getFiltrationArrays")
        .then((response) => {
          act(() => {
            setInstitutionsForFilter(response.data.institutionArray)
          })
        })
        .catch((err) => {
          console.log(err, 'Error fetching collaboration filtration data in collaboration main page');
          CustomErrorLogger(err, 'Error fetching collaboration filtration data in collaboration main page');
        });
    }
  }, [userData]);

  const getCollaborationData = async () => {
    try {
      const currentOffset = offset.current;

      const { data, status } = await axios.get('collaboration-institution', { params: { filter, limit, offset: currentOffset } });
      if (status === 200 || status === 201) {
        setCollaborationData(prevData => currentOffset === 0 ? data : [...prevData, ...data]);

        offset.current = currentOffset + limit;
        hasMore.current = data.length <= limit;
      }
    } catch (error) {
      console.error('Error fetching collaboration data:', error);
      CustomErrorLogger(error, 'Error fetching collaboration data in collaboration main page');
    }
    finally {
      setLoading(false)
    }
  };

  const handleChangeInstitution = (institution) => {
    setSelectedInstitution(institution);
    setFilter(institution.id);
    offset.current = 0;
    hasMore.current = true;
  };

  const emptyFiltrationObject = () => {
    offset.current = 0;
    hasMore.current = true;
    setFilter('')
    setSelectedInstitution('')
  }

  const FilterTag = ({ label, onDelete }) => (
    <Box className='main-page-filter-flag-item'>
      <Typography>{label}</Typography>
      <Iconify icon="mdi:close" onClick={onDelete} />
    </Box>
  );

  const handleOpenActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  const ActionButton = ({ icon, title, onClick }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
      <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
    </Box>
  );


  return (
    <Page title={t("sideBar-Network")}>
      <Box>
        <Stack className="collaboration-main-page-header">
          <Box className="collaboration-page-title-box">
            <Iconify className="collaboration-page-title-icon" icon="material-symbols:link" />
            <Typography className='collaboration-page-title'>{t("sideBar-Network")}</Typography>
          </Box>

          {isDesktop && <Box className="collaboration-page-action-box">
            <Box className='collaboration-page-header-button' onClick={() => { setOpenFilter(true) }}>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Iconify icon="mdi:filter" className="plus-icon" />
              <Typography className="new-button-title">{t('actionFilter')}</Typography>
            </Box>

            {(isAllowedCreateAllCollaboration || isAllowedCreateRelatedInstituionsCollaboration) &&
              <Box className='collaboration-page-header-button' onClick={() => navigate('add')}>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Iconify icon="eva:plus-fill" className="plus-icon" />
                <Typography className="collaboration-button-title">{t('Create')}</Typography>
              </Box>
            }
          </Box>}

          {!isDesktop &&
            <IconButton
              className="main-page-threeDot-icon"
              id="userPageActionMenuIcom"
              aria-controls={openActionMenu ? 'long-menu' : undefined}
              aria-expanded={openActionMenu ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOpenActionMenu}
            >
              <MoreHorizIcon />
            </IconButton>
          }

          <Menu
            id="collaborationePageActionMenu"
            MenuListProps="collaborationPageActionMenuIcom"
            open={openActionMenu}
            anchorEl={anchorEl}
            onClose={handleCloseActionMenu}
            onClick={handleCloseActionMenu}
            slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
          >
            {(isAllowedCreateAllCollaboration || isAllowedCreateRelatedInstituionsCollaboration) &&
              <MenuItem className='main-page-header-button' onClick={() => navigate('add')}>
                <Iconify icon="eva:plus-fill" className="main-page-threeDot-icon" />
                <Typography>{t('Create')}</Typography>
              </MenuItem>}
            < MenuItem className='main-page-header-button' onClick={() => { setOpenFilter(true) }}>
              <Iconify icon="mdi:filter" className="main-page-threeDot-icon" />
              <Typography>{t('actionFilter')} </Typography>
            </MenuItem>
          </Menu>

        </Stack>
        {loading ?
          <LinearProgress />
          :
          <Box>
            {openFilter &&
              <Box sx={{ marginTop: '15px', marginBottom: '15px' }}>
                <Card sx={{ padding: '7px' }} >
                  <Box display={'flex'} justifyContent={'space-between'} color={'#17536B'} >
                    <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>{t("Filter")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={emptyFiltrationObject} />
                      <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                    </Box>                  </Box>
                  <Divider />
                  <Grid container spacing={2} marginTop={'5px'}>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth sx={{ ...customInputStyles }} >
                        <InputLabel id="demo-simple-select-label">{t("institution")}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="selectedExpretise"
                          label={t("institution")}
                          name="selectedInstitution"
                          value={selectedInstitution || ""}
                          onChange={(e) => handleChangeInstitution(e.target.value)}
                          endAdornment={
                            selectedInstitution && (
                              <InputAdornment position="start">
                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeInstitution('')} />
                              </InputAdornment>
                            )
                          }
                        >
                          {institutionsForFilter && institutionsForFilter.map((institution, index) => (
                            <MenuItem key={index} value={institution}>{institution.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Card>
              </Box>}

            {(!openFilter && selectedInstitution) &&
              <Box className='mdf-page-filter-flag-box '>
                {selectedInstitution && <FilterTag label={selectedInstitution?.name} onDelete={() => handleChangeInstitution('')} />}
                <Typography className="mdf-page-clear-filter-flag" onClick={emptyFiltrationObject}>{t("clear_all_filter")}</Typography>
              </Box>
            }

            <Scrollbar>
              <InfiniteScroll
                dataLength={collaborationData && collaborationData.length}
                next={getCollaborationData}
                hasMore={hasMore.current}
                loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
              >
                {isDesktop &&
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className='collaboration-table-titles'>{t('requerentInstitution')}</TableCell>
                        <TableCell className='collaboration-table-titles'>{t('requisInstitution')}</TableCell>
                        <TableCell className='collaboration-table-titles'>{t('createdAt')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {collaborationData && collaborationData.map((collaboration, index) => {
                        return (
                          <TableRow key={collaboration.id} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }} className='collaboration-TableRow' onClick={() => navigate(`details/${collaboration.id}`)}>
                            <TableCell className='new-table-cell' align="left">{collaboration.askingInstitution?.name}</TableCell>
                            <TableCell className='new-table-cell' align="left">{collaboration.providingInstitution?.name}</TableCell>
                            <TableCell className='new-table-cell' align="left">{fDateTime(collaboration.created_at, userData.local_code)}</TableCell>
                          </TableRow>
                        )

                      })}
                    </TableBody>
                  </Table>
                }

                {!isDesktop &&
                  <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
                    {collaborationData && collaborationData.map((collaboration) => {
                      return (
                        <Grid item xs={12} sm={4} key={collaboration.id} >
                          <Box className="collaboration-responsive-card-details collaboration-TableRow" onClick={() => navigate(`details/${collaboration.id}`)}>
                            <Typography className='collaboration-card-resp-part'>{collaboration.askingInstitution?.name}</Typography>
                            <Typography className='collaboration-card-resp-part'>{collaboration.providingInstitution?.name}</Typography>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                }

              </InfiniteScroll>
            </Scrollbar>

          </Box>
        }
      </Box>

    </Page>
  );
}
