import { Card, Typography } from '@mui/material';
import Iconify from '../Iconify';

export const AppWidgetSummary = (props) => {

    const { title, total, icon } = props;

    return (
        <Card sx={{ border: '1px solid #eceff1', borderRadius: 2, textAlign: 'center', boxShadow: 0, padding: '10px', bgcolor: "#eceff1" }}>
            <Iconify icon={icon} width={30} height={30} />
            <Typography variant="h3">{total}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>{title}</Typography>
        </Card>
    );
}
