import React from 'react'
import { Box, Typography, CardMedia, Link } from '@mui/material'
import Logo from '../../images/logo_e_health_pro.svg'
import ImeahShort from '../../images/imeahSortcut2.svg'
import linkedInIcon from '../../images/logo linkedin.svg'
import '../../css/homePage/footer.css'
import { useTranslation } from "react-i18next";

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box className='footer-main-box'>
            <Box className="footer-sub-container">
                <Box className="footer-image-container">
                    <CardMedia
                        className="footer-logo"
                        component="img"
                        image={Logo}
                        alt="IMEAH"
                    />
                </Box>
                <Box className="footer-link-list">
                    <Link href={`#` + t('report')} className='footer-link'>{t('report')}</Link>
                    <Link href={`#` + t('networkManagement')} className='footer-link'>{t('networkManagement')}</Link>
                    <Link href={`#` + t('training')} className='footer-link'>{t('training')}</Link>
                    <Link href={`#` + t('library')} className='footer-link'>{t('library')}</Link>
                    <Link href={`#` + t('forWho')} className='footer-link'>{t('forWho')}</Link>
                    <Link href={`#` + t('responsibleMedicine')} className='footer-link'>{t('responsibleMedicine')}</Link>
                    <Link href={`#` + t('whoWeAre')} className='footer-link'>{t('whoWeAre')}</Link>
                    <Link href={`#` + t('contactUs')} className='footer-link'>{t('contactUs')}</Link>
                </Box>
                <Box className='footer-right-container'>
                    <CardMedia
                        className="linkedInIcon-logo"
                        component="img"
                        image={linkedInIcon}
                        alt="IMEAH"
                    />
                    <Typography className='footer-text'>Mentions légales</Typography>
                    <Typography className='footer-text'>Designed by PALO IT</Typography>
                    <CardMedia
                        className="ImeahShort-logo"
                        component="img"
                        image={ImeahShort}
                        alt="IMEAH"
                    />
                </Box>
            </Box>
        </Box>
    )
}
