import { Box, Table, Stack, Grid, LinearProgress, InputBase, TableRow, TableHead, TableBody, TableCell, Typography, useMediaQuery, InputAdornment } from '@mui/material';
import { DeleteExpertise } from '../components/Expertises/deleteExpertise';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { EditExpertise } from '../components/Expertises/editExpertise';
import { AddExpertise } from '../components/Expertises/addExpertise';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HasPermission } from "../utils/checkUserPermission";
import { StyledTableRow } from '../utils/StyledTableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useDebounce from '../hooks/useDebounce';
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import axios from 'axios';
import '../css/Expertises.css'

export const Expertises = () => {

  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const userData = useSelector(state => state.userData);
  //check user permissions
  const isAllowedCreateExpertise = HasPermission('Create_Expertise');
  const isAllowedEditExpertise = HasPermission('Edit_Expertise');
  const isAllowedDeleteExpertise = HasPermission('Delete_Expertise');

  const [expertises, setExpertises] = useState([]);
  const [expertiseLabel, setExpertiseLabel] = useState('');
  const [id, setId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  //filtration states
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);
  //pagination state
  const hasMore = useRef(null);
  const offset = useRef(0);
  const limit = 20;

  const fetchExpertises = useCallback(async () => {
    try {
      const currentOffset = offset.current;
      const response = await axios.get('expertise', { params: { offset: currentOffset, limit, search: debouncedSearch } });
      setExpertises((prevExpertises) => (currentOffset === 0 ? response.data : [...prevExpertises, ...response.data]));
      setLoading(false);
      offset.current = currentOffset + limit;
      hasMore.current = response.data.length === limit;
    } catch (err) {
      console.error(err, 'Error fetching expertises in expertises main page');
      CustomErrorLogger(err, 'Error fetching expertises in expertises main page');
      setLoading(false);
    }
  }, [limit, debouncedSearch]);

  useEffect(() => {
    setExpertises([]);
    offset.current = 0;
    hasMore.current = true;
    fetchExpertises()
  }, [userData.local_code, fetchExpertises]);

  const handleChangeSearch = (searchWord) => {
    offset.current = 0;
    hasMore.current = true;
    setSearch(searchWord);
  };

  const handleDeleteOpen = useCallback((id) => {
    setOpenDelete(true);
    setId(id);
  }, []);

  const handleEditOpen = useCallback((row) => {
    setOpenEdit(true);
    setId(row.expertiseId);
    // setExpertiseLabel(row.label);
  }, []);

  return (
    <Page title={t("expertises")}>

      <Stack className="expertises-main-page-header">
        <Box className="expertises-page-title-box">
          <Box className="expertises-page-title-icon-box">
            <Iconify className="expertises-page-title-icon" icon="eva:file-text-fill" />
            <Typography className='expertises-page-title'>{t("expertises")}</Typography>
          </Box>
          <InputBase
            className='expertises-page-search-input'
            id="outlined-password-input"
            label={t('search')}
            type="text"
            placeholder={t('search')}
            value={search || ""}
            onChange={(e) => handleChangeSearch(e.target.value)}
            endAdornment={
              search && (
                <InputAdornment position="end">
                  <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                </InputAdornment>
              )
            }
          />
        </Box>

        <Box className="expertises-page-action-box">
          {isAllowedCreateExpertise &&
            <Box className='expertises-page-header-button' onClick={() => setOpenAdd(true)}>
              <Iconify icon="eva:plus-fill" className="plus-icon" />
              <Typography className="new-button-title">{t('Create')}</Typography>
            </Box>
          }
        </Box>
      </Stack>

      {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} /> :
        <Box>
          <InfiniteScroll
            dataLength={expertises.length}
            next={fetchExpertises}
            hasMore={hasMore.current}
            loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
          >

            {isDesktop &&
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='expertises-list-table-titles'>{t('name')}</TableCell>
                    {isAllowedEditExpertise && <TableCell className='expertises-list-table-titles' >{t('Edit')}</TableCell>}
                    {isAllowedDeleteExpertise && <TableCell className='expertises-list-table-titles'>{t('dlt')}</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {expertises && expertises.map((expertise) => {
                    return (
                      <StyledTableRow key={expertise.id}>
                        <TableCell width={'90%'} className="new-table-cell">{expertise?.label}</TableCell>
                        {isAllowedEditExpertise && <TableCell align='center' className="new-table-cell"><EditIcon onClick={() => handleEditOpen(expertise)} className='expertises-actions-btn' data-testid="editIcon" /></TableCell>}
                        {isAllowedDeleteExpertise && <TableCell align='center' className="new-table-cell"><DeleteIcon onClick={() => handleDeleteOpen(expertise.expertiseId)} className='expertises-actions-btn' data-testid="deleteIcon" /></TableCell>}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            }

            {!isDesktop &&
              <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'}>
                {expertises && expertises.map((expertise) => (
                  <Grid item xs={12} sm={4} key={expertise.id}>
                    <Box className="expertises-list-responsive-card-details">
                      <Typography className='expertises-list-responsive-card-data'>{expertise?.label}</Typography>
                      <Box className='expertises-card-actions'>
                        {isAllowedEditExpertise && <EditIcon className='expertises-actions-btn' onClick={() => handleEditOpen(expertise)} data-testid="editIcon" />}
                        {isAllowedDeleteExpertise && <DeleteIcon onClick={() => handleDeleteOpen(expertise.expertiseId)} className='expertises-actions-btn' data-testid="deleteIcon" />}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            }
          </InfiniteScroll>
        </Box>
      }

      {openAdd &&
        <AddExpertise
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          setExpertises={setExpertises}
        />
      }

      {openEdit &&
        <EditExpertise
          id={id}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          setExpertiseLabel={setExpertiseLabel}
          expertiseLabel={expertiseLabel}
          setExpertises={setExpertises}
        />
      }

      {openDelete &&
        <DeleteExpertise
          id={id}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          setExpertises={setExpertises}
        />
      }

    </Page>
  );
};