import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Avatar, Badge, useMediaQuery } from '@mui/material';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { fDate } from '../../../utils/formatTime';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../../Iconify';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import '../../../css/medicalFiles/staticData.css';

export const StaticData = ({ medicalFileData, setMedicalFileData, setEditStaticData, allowedActions }) => {

    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    const [expanded, setExpanded] = useState(false);
    const [patientUrl, setPatientUrl] = useState('');

    const hasPatientDocument = useMemo(() => Boolean(medicalFileData.PatientDocument?.image), [medicalFileData.PatientDocument]);
    const isMobile = useMediaQuery('(max-width:950px)');

    const toggleExpanded = useCallback(() => setExpanded(prev => !prev), []);

    const handleEditStaticDataClick = useCallback(() => {
        setEditStaticData(true);
    }, [setEditStaticData]);

    useEffect(() => {
        if (medicalFileData.PatientDocument?.fileKey) {
            const fetchDocument = async () => {
                try {
                    const response = await axios.get(`/documents/fetch_Document?fileKey=${encodeURIComponent(medicalFileData.PatientDocument?.fileKey)}`, { responseType: 'blob' });
                    const src = URL.createObjectURL(response.data);
                    setPatientUrl(src);
                } catch (error) {
                    console.error('Error fetching document:', error);
                    CustomErrorLogger(error, 'Error fetching document in static data');
                }
            };

            fetchDocument();
        }
    }, [medicalFileData.PatientDocument?.fileKey]);

    const handleUploadPatientDocument = useCallback(async (imageInformation) => {

        if (!imageInformation.type?.startsWith('image/')) return;

        const imageId = `${uuidv4()}-${imageInformation.name}`;
        const fileData = new FormData();
        fileData.append('id', imageId);
        fileData.append('medicalFileId', medicalFileData.id);
        fileData.append('fileKey', imageId);
        fileData.append('file', imageInformation)

        try {
            const response = await axios.post(`patient-document`, fileData);
            if (response.status === 200 || response.status === 201) {
                setMedicalFileData(prevData => ({ ...prevData, PatientDocument: response.data }));
            }
        } catch (error) {
            console.error('Error upload patient document:', error.message);
            CustomErrorLogger(error, 'Error uploading patient document');
        }
    }, [medicalFileData.id, setMedicalFileData]);

    const handleDeletePatientDocument = useCallback(async (documentId) => {
        try {
            const response = await axios.delete(`/patient-document/${documentId}`);
            if (response.status === 200 || response.status === 201) {
                setMedicalFileData(prevData => ({ ...prevData, PatientDocument: null }));
                setPatientUrl('');
            }
        } catch (error) {
            console.error('Error delete patient document:', error.message);
            CustomErrorLogger(error, 'Error deleting patient document');
        }
    }, [setMedicalFileData, setPatientUrl]);

    return (
        <Accordion expanded={expanded} className='staticData_accordion'>

            <AccordionSummary sx={{ padding: 0 }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>

                    {allowedActions.canEditPrivateMedicalFile && isMobile &&
                        <Box className="staticData_item_box" onClick={handleEditStaticDataClick}>
                            <Iconify
                                icon='fe:edit'
                                className='staticData_edit_icon'
                            />
                            <Typography className="staticData_text_Button">{t('edit_Patient_Info')}</Typography>
                        </Box>
                    }

                    <Box className='staticData_accordion_summary_box'>

                        <Box sx={{ display: "flex", gap: '18px' }}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: hasPatientDocument ? 'top' : 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    allowedActions.canEditPrivateMedicalFile ? (
                                        hasPatientDocument ? (
                                            <Iconify
                                                icon='material-symbols:close-rounded'
                                                onClick={() => handleDeletePatientDocument(medicalFileData.PatientDocument?.id)}
                                                className="staticData_delete_icon"
                                            />
                                        ) : (
                                            <Iconify
                                                icon='ant-design:camera-filled'
                                                onClick={() => document.getElementById('addPatientDocument').click()}
                                                className="staticData_upload_icon"
                                            />
                                        )
                                    ) : null
                                }
                            >
                                <Avatar className="staticData_patient_avatar" src={patientUrl} />
                            </Badge>

                            <input
                                id="addPatientDocument"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(event) => handleUploadPatientDocument(event.target?.files[0])} />


                            <Box className="staticData_first_section">

                                <Box className="staticData_item_box">
                                    <Typography className="staticData_label">{t('ID')}:</Typography>
                                    <Typography className="staticData_value">{medicalFileData?.file_number}</Typography>
                                </Box>

                                <Box className="staticData_item_box">
                                    <Typography className="staticData_label">{t('requis')}:</Typography>
                                    <Typography className="staticData_value imeah_persone_name">{medicalFileData?.targetDoctorForShare?.user?.firstname.toLowerCase()} {medicalFileData?.targetDoctorForShare?.user?.lastname.toLowerCase()}</Typography>
                                </Box>

                                <Box className="staticData_item_box">
                                    <Typography
                                        className="staticData_value"
                                        sx={{
                                            padding: 'var(--Space-Extra-Small, 8px) 48px var(--Space-Extra-Small, 8px) 24px',
                                            borderRadius: '12px',
                                            backgroundColor: medicalFileData.status === 'Waiting' ? '#FFE67866' : medicalFileData.status === 'Answered' ? '#88FF5166' : medicalFileData.status === 'Shared' ? '#ABBFF066' : '#B3B2B966',
                                            border: medicalFileData.status === 'Waiting' ? '1px solid #C2A41D' : medicalFileData.status === 'Answered' ? '1px solid #4FA826' : medicalFileData.status === 'Shared' ? '1px solid #264BA8' : '1px solid #727179',
                                            color: medicalFileData.status === 'Waiting' ? '#C2A41D' : medicalFileData.status === 'Answered' ? '#4FA826' : medicalFileData.status === 'Shared' ? '#264BA8' : '#727179',
                                        }}
                                    >
                                        {t(medicalFileData.status)}
                                    </Typography>
                                </Box>

                            </Box>

                        </Box>

                        <Box className="staticData_second_section">
                            <Box className="staticData_item_box">
                                <Typography className="staticData_label">{t('age')}:</Typography>
                                <Typography className="staticData_value">{medicalFileData.age} {t(medicalFileData.ageUnit)}</Typography>
                            </Box>
                            <Box className="staticData_item_box">
                                <Typography className="staticData_label">{t('updatedAt')}:</Typography>
                                <Typography className="staticData_value">{fDate(medicalFileData.updated_at, userData.local_code)}</Typography>
                            </Box>
                        </Box>

                        {allowedActions.canEditPrivateMedicalFile && !isMobile &&
                            <Box className="staticData_item_box" onClick={handleEditStaticDataClick}>
                                <Iconify
                                    icon='fe:edit'
                                    className='staticData_edit_icon'
                                />
                                <Typography className="staticData_text_Button">{t('edit_Patient_Info')}</Typography>
                            </Box>
                        }

                    </Box>

                    <Box className="staticData_item_box" onClick={toggleExpanded}>
                        <Typography className="staticData_text_Button" >{t('view_Additional_Details')}</Typography>
                        <Iconify
                            className='staticData_edit_icon'
                            icon={expanded ? 'iconamoon:arrow-down-2-light' : 'iconamoon:arrow-right-2-light'}
                        />
                    </Box>

                </Box>

            </AccordionSummary>

            <AccordionDetails className='staticData_accordion_details'>

                {medicalFileData.speciality.id !== "NoSpeciality" ?
                    <Box className="staticData_item_box">
                        <Typography className="staticData_label">{t('Speciality')}:</Typography>
                        <Typography className="staticData_value">{medicalFileData.speciality?.specialityLabel[0].label}</Typography>
                    </Box> :
                    <Box className="staticData_item_box">
                        <Typography className="staticData_label">{t('expertise')}:</Typography>
                        <Typography className="staticData_value">{medicalFileData.expertise?.expertiseLabel[0].label}</Typography>
                    </Box>
                }

                <Box className="staticData_item_box">
                    <Typography className="staticData_label">{t('gender')}:</Typography>
                    <Typography className="staticData_value">{t(medicalFileData.gender)}</Typography>
                </Box>

                <Box className="staticData_item_box">
                    <Typography className="staticData_label">{t('birthdate')}:</Typography>
                    <Typography className="staticData_value">{fDate(medicalFileData.birthDate, userData.local_code)}</Typography>
                </Box>

                <Box className="staticData_item_box">
                    <Typography className="staticData_label">{t('Birth_Place')}:</Typography>
                    <Typography className="staticData_value">{medicalFileData.BirthPlace}</Typography>
                </Box>

                <Box className="staticData_item_box">
                    <Typography className="staticData_label">{t('Address')}:</Typography>
                    <Typography className="staticData_value">{medicalFileData.address}</Typography>
                </Box>

                {medicalFileData.patient_Email &&
                    <Box className="staticData_item_box">
                        <Typography className="staticData_label">{t('patient_Email')}:</Typography>
                        <Typography className="staticData_value">{medicalFileData.patient_Email}</Typography>
                    </Box>
                }

                {medicalFileData.Phone_number &&
                    <Box className="staticData_item_box">
                        <Typography className="staticData_label">{t('Phone_Number')}:</Typography>
                        <Typography className="staticData_value">{medicalFileData.Phone_number}</Typography>
                    </Box>
                }

                {medicalFileData.Social_Security_Number &&
                    <Box className="staticData_item_box">
                        <Typography className="staticData_label">{t('Social_Security_Number')}:</Typography>
                        <Typography className="staticData_value">{medicalFileData.Social_Security_Number}</Typography>
                    </Box>
                }

                <Box className="staticData_item_box">
                    <Typography className="staticData_label">{t('templateName')}:</Typography>
                    <Typography className="staticData_value">{medicalFileData.usedTemplate}</Typography>
                </Box>

                <Box className="staticData_item_box">
                    <Typography className="staticData_label">{t('createdAt')}:</Typography>
                    <Typography className="staticData_value">{fDate(medicalFileData.created_at, userData.local_code)}</Typography>
                </Box>

            </AccordionDetails>

        </Accordion>
    );
};