import React from 'react'
import { Box, Typography, CardMedia } from '@mui/material'
import ImeahShort from '../../images/Frame 729.svg'
import '../../css/homePage/HomeElevenPart.css'
import { useTranslation } from "react-i18next";

export const HomeElevenPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeElevenPart-main-box'>
            <Box className="homeElevenPart-left-box">
                <Typography className="homeElevenPart-Qui-sommes-nous">{t("whoWeAre")}</Typography>
                <Typography className="homeElevenPart-Desc-text">{t("imeahCompanySpecialisingTelemedicine")}</Typography>
            </Box>
            <Box className="homeElevenPart-image-container">
                <CardMedia
                    className="homeElevenPart-image"
                    component="img"
                    image={ImeahShort}
                    alt="IMEAH"
                />
            </Box>

        </Box>
    )
}
