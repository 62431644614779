/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography, Stack, Box, Table, TableHead, TableCell, TableBody, Checkbox, TableRow } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { HasPermission } from '../../../utils/checkUserPermission';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { act } from '@testing-library/react';
import { useSelector } from 'react-redux';
import Page from "../../Page";
import * as Yup from 'yup';
import axios from 'axios';
import '../../../css/templates/editTemplateOwner.css';

export const EditTemplateOwner = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentInstitution = useSelector(state => state.currentInstitution);

    const isAllowedChangeInstitutionTemplateOwner = HasPermission('change_institution_template_owner');
    const template = useLocation().state?.data;

    const [targetDoctor, setTargetDoctor] = useState([]);
    const [owners, setOwners] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 20;

    const validationTemplateOwner = Yup.object().shape({
        owners: Yup.array().of(Yup.string().required()).min(1)
    });

    const checkValidation = validationTemplateOwner.isValidSync({
        owners: owners,
    })

    const fetchTargetDoctors = async () => {

        if (!currentInstitution) return; // Early return for unauthenticated users      

        try {
            const response = await axios.get('institutions-users/getTargetDoctorToChangeTemplateOwners', { params: { offset, limit, specialityId: template.specialityId, expertiseId: template.expertise?.id } });
            if (response.status === 200 || response.status === 201) {
                act(() => {
                    setTargetDoctor((prevTargetDoctor) => offset === 0 ? response.data : [...prevTargetDoctor, ...response.data]);
                    setHasMore(response.data.length >= limit);
                    setOffset(offset + limit);
                })
            }
        } catch (error) {
            console.error('Error fetching target doctors:', error);
            CustomErrorLogger(error, 'Error fetching target doctors to change template owners');
        }
    }

    useEffect(() => {
        const ownerIds = template?.template_owners?.map((data) => data.user?.id);
        setOwners(ownerIds);
        // setOwners(template.template_owners);
        fetchTargetDoctors()

    }, [currentInstitution, template]);

    const handleUpdateTemplateOwner = async () => {
        try {
            const isSameInstitution = template.institutionId === currentInstitution.institution.id;

            if (checkValidation && isAllowedChangeInstitutionTemplateOwner && isSameInstitution) {
                const response = await axios.patch(`medical-file-templates/changeTemplateOwner/${template.id}`, { newOwners: owners });
                if (response.status === 200 || response.status === 201) {
                    navigate(`/dashboard/medicalFilesTemplates/edit/${template.id}`);
                }
            }
        } catch (error) {
            console.error('Error updating template owner:', error);
            CustomErrorLogger(error, 'Error updating template owner');
        }
    };

    const handleAddDoctorAsOwner = (choosenDoctor, isChecked) => {

        setOwners((prevOwners) => {
            // Check if the doctor already exists in owners
            const existingIndex = prevOwners.findIndex((ownerId) => ownerId === choosenDoctor.user.id);

            if (isChecked) {
                // Add the doctor as an owner if it doesn't exist
                if (existingIndex === -1) {
                    return [...prevOwners, choosenDoctor.user?.id];
                }
            } else {
                // Remove the doctor from owners if it exists
                if (existingIndex !== -1) {
                    const updatedMembers = [...prevOwners];
                    updatedMembers.splice(existingIndex, 1);
                    return updatedMembers;
                }
            }

            return prevOwners; // Return previous state if no changes
        });
    };

    return (
        <Page title={t("Edit_Template_Page_Title")}>

            <Box sx={{ display: 'flex', gap: '10px' }}>
                <ArrowBackIosIcon className='editTempowner-back-btn' onClick={() => navigate(`/dashboard/medicalFilesTemplates/edit/${template.id}`)} data-testid="arrowBack" />
                <Stack className="editTempowner-main-page-header">
                    <Box className="editTempowner-page-title-box">
                        <Typography className='editTempowner-page-title'>{t('Modify_template_Owner_for_amaizing_experience')}</Typography>
                    </Box>
                </Stack>
            </Box>

            <Box className='editTempowner-info-box'>
                <Box className='editTempowner-field-info'>
                    <Typography className='editTempowner-field-label'>{t('name')} :</Typography>
                    <Typography className='editTempowner-field-response'> {template.name}</Typography>
                </Box>
                <Box className='editTempowner-field-info'>
                    <Typography className='editTempowner-field-label'>{t('description')} :</Typography>
                    <Typography className='editTempowner-field-response'> {template.description}</Typography>
                </Box>
            </Box>

            {(targetDoctor?.length > 0) &&
                <InfiniteScroll
                    dataLength={targetDoctor.length}
                    next={fetchTargetDoctors}
                    hasMore={hasMore}>
                    <Table>
                        <TableHead>
                            <TableRow className='table-row'>
                                <TableCell className='table-titles'>{t('doctor')}</TableCell>
                                <TableCell className='table-titles'>{t('firstname')}</TableCell>
                                <TableCell className='table-titles'>{t('lastname')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {targetDoctor && targetDoctor.map((doctor) =>
                                <TableRow key={doctor.id}>
                                    <TableCell>
                                        <Checkbox
                                            checked={owners.some((ownerId) => ownerId === doctor.user.id)}
                                            onChange={(e) => handleAddDoctorAsOwner(doctor, e.target.checked)}
                                        />
                                    </TableCell>
                                    <TableCell >{doctor.user?.firstname}</TableCell>
                                    <TableCell >{doctor.user?.lastname}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table >
                </InfiniteScroll>
            }

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className='new-button' fullWidth onClick={handleUpdateTemplateOwner}>{t('save')}</Button>
            </Box>

        </Page>
    );
};