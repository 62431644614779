import { Stack, Typography, Box, InputBase, useMediaQuery, IconButton, Menu, MenuItem, Divider, InputAdornment } from "@mui/material";
import { InstitutionsFiltration } from "../components/Institution/InstitutionsFiltration";
import { ReadInstitutions } from "../components/Institution/readInstitutions";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { CustomErrorLogger } from "../utils/CustomErrorLogger";
import { HasPermission } from "../utils/checkUserPermission";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import { useTranslation } from "react-i18next";
import Iconify from "../components/Iconify";
import { useSelector } from 'react-redux';
import Page from "../components/Page";
import axios from 'axios';
import '../css/institutions/intitutionMainPage.css';

export default function Institutions() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userData = useSelector(state => state.userData);

    //check user permissions
    const isAllowedCreateInstitutions = HasPermission('Create_Institution');

    const [institutions, setInstitutions] = useState([]);
    const [institutionTypes, setInstitutionTypes] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);
    const [openFilter, setOpenFilter] = useState(false);
    const [loading, setLoading] = useState(true);
    //filtration states
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 1000);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedActivityStatus, setSelectedActivityStatus] = useState(null);
    //pagination state 
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 20;

    const hasFilters = selectedType || selectedActivityStatus

    const fetchInstitutions = useCallback(async () => {
        try {
            const filters = {
                search: debouncedSearch,
                selectedType: selectedType,
                selectedActivityStatus: selectedActivityStatus,
            };
            const currentOffset = offset.current;
            const response = await axios.get('institutions', { params: { offset: currentOffset, limit, filters } });
            if (response.status === 200 || response.status === 201) {
                setInstitutions(prevInstitutions => currentOffset === 0 ? response.data : [...prevInstitutions, ...response.data]);
                setLoading(false);
                offset.current = currentOffset + limit;
                hasMore.current = response.data.length === limit;
            }
        } catch (err) {
            console.error(err, 'Error fetching institutions in institutions main page');
            CustomErrorLogger(err, 'Error fetching institutions in institutions main page');
        } finally {
            setLoading(false);
        }
    }, [debouncedSearch, selectedActivityStatus, selectedType]);

    useEffect(() => {
        if (userData.local_code) {
            setInstitutions([]);
            offset.current = 0;
            hasMore.current = true;
        }
    }, [userData.local_code]);

    useEffect(() => {
        if (userData) {
            fetchInstitutions();
        }
    }, [userData, fetchInstitutions]);

    const fetchInstitutionTypesFilters = useCallback(async () => {
        try {
            const response = await axios.get('institutions/getFiltrationArrays');
            if (response.status === 200 || response.status === 201) {
                setInstitutionTypes(response.data);
            }
        } catch (err) {
            console.error(err, 'Error fetching institution types filters in institutions main page');
            CustomErrorLogger(err, 'Error fetching institution types filters in institutions main page');
        }
    }, []);

    useEffect(() => {
        if (userData) {
            fetchInstitutionTypesFilters();
        }
    }, [userData, fetchInstitutionTypesFilters]);

    const handleChangeFilterStatus = useCallback(newStatus => {
        setSelectedActivityStatus(newStatus);
        offset.current = 0
        hasMore.current = true;
    }, []);

    const handleChangeFilterType = useCallback(newType => {
        setSelectedType(newType);
        offset.current = 0
        hasMore.current = true;
    }, []);

    const emptyFiltrationObject = useCallback(() => {
        setSelectedType(null);
        setSelectedActivityStatus(null);
        setSearch(null);
        offset.current = 0
        hasMore.current = true;
    }, []);

    const handleOpenActionMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeSearch = (searchWord) => {
        offset.current = 0
        hasMore.current = true;
        setSearch(searchWord);
    };

    const FilterTag = ({ label, onDelete }) => (
        <Box className='main-page-filter-flag-item'>
            <Typography>{label}</Typography>
            <Iconify icon="mdi:close" onClick={onDelete} />
        </Box>
    );

    return (
        <Page title={t("sideBar-hospitals")}>

            <Stack className="instututions-page-header">
                <Box className="instututions-page-title-box">
                    <Box className="instututions-page-title-icon-box">
                        <Iconify className="instututions-page-title-icon" icon="icon-park-solid:hospital-four" />
                        <Typography className='instututions-page-title'>{t("sideBar-hospitals")}</Typography>
                    </Box>
                    <InputBase
                        className='instututions-page-search-input'
                        id="outlined-password-input"
                        label={t('search')}
                        placeholder={t('search')}
                        type="text"
                        value={search || ""}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        endAdornment={
                            search && (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                                </InputAdornment>
                            )
                        }
                    />
                </Box>

                <Box className="instututions-page-action-box">

                    {isDesktop &&
                        < Box className='main-page-header-button' onClick={() => setOpenFilter(true)}>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Iconify icon="mdi:filter" className="new-page-title-icon" />
                            <Typography>{t('actionFilter')} </Typography>
                        </Box>
                    }

                    {isDesktop && isAllowedCreateInstitutions &&
                        <Box className='instututions-page-header-button' onClick={() => navigate('add')}>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Iconify icon="eva:plus-fill" className="instututions-page-title-icon" />
                            <Typography>{t('Create')}</Typography>
                        </Box>
                    }

                    {!isDesktop &&
                        <IconButton
                            className="new-page-title-icon"
                            id="medicalFilePageActionMenuIcom"
                            aria-controls={openActionMenu ? 'long-menu' : undefined}
                            aria-expanded={openActionMenu ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleOpenActionMenu}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    }
                </Box>

                <Menu
                    id="medicalFilePageActionMenu"
                    MenuListProps="medicalFilePageActionMenuIcom"
                    open={openActionMenu}
                    anchorEl={anchorEl}
                    onClose={handleCloseActionMenu}
                    onClick={handleCloseActionMenu}
                    slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
                >
                    {isAllowedCreateInstitutions && <MenuItem className='instututions-page-header-button' onClick={() => navigate('add')}>
                        <Iconify icon="eva:plus-fill" className="instututions-page-title-icon" />
                        <Typography>{t('Create')}</Typography>
                    </MenuItem>}
                    < MenuItem className='main-page-header-button' onClick={() => setOpenFilter(true)}>
                        <Iconify icon="mdi:filter" className="new-page-title-icon" />
                        <Typography>{t('actionFilter')} </Typography>
                    </MenuItem>
                </Menu>
            </Stack>

            {openFilter &&
                <InstitutionsFiltration
                    emptyFiltrationObject={emptyFiltrationObject}
                    institutionTypes={institutionTypes}
                    selectedType={selectedType}
                    selectedActivityStatus={selectedActivityStatus}
                    // filters={filters}
                    handleChangeFilterType={handleChangeFilterType}
                    handleChangeFilterStatus={handleChangeFilterStatus}
                    setOpenFilter={setOpenFilter}
                />
            }

            {(!openFilter && hasFilters) &&
                <Box className='filter-flag-box'>
                    {selectedType && <FilterTag label={selectedType.type} onDelete={() => handleChangeFilterType(null)} />}
                    {selectedActivityStatus && <FilterTag label={selectedActivityStatus === "true" ? t('ActiveInstitution') : t('inActiveInstitution')} onDelete={() => handleChangeFilterStatus(null)} />}
                    <Typography className="clear-filter-flag" onClick={emptyFiltrationObject}>{t("clear_all_filter")}</Typography>
                </Box>
            }

            <ReadInstitutions
                loading={loading}
                institutions={institutions}
                fetchInstitutions={fetchInstitutions}
                hasMore={hasMore.current}
            />

        </Page >
    );
}