import { Dialog, DialogActions, Box, DialogContent, DialogTitle, Button, Typography, Alert } from "@mui/material";
import { CustomErrorLogger } from '../../utils/CustomErrorLogger';
import { LanguageSwitcher } from '../NoLoginLanguageSwitcher';
import logo from '../../images/logo_e_health_pro.svg';
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import { OTP } from '../../utils/codeOTP';
import i18next from "i18next";
import * as Yup from 'yup';
import axios from "axios";

export const MFACode = ({ email, authData, updateAuthData, storeAuthData }) => {

    const { t } = useTranslation();

    const { token, refreshToken, requireVerificationCode, requireBrowserTrust, requireLoginPolicies, requireSelectInstitution } = authData;
    const [language, setLanguage] = useState(i18next.language || 'en');
    const [errorMessage, setErrorMessage] = useState(null);
    const [code, setCode] = useState("");

    const handleLangUpdate = (newLanguage) => setLanguage(newLanguage);

    const validationSchema = Yup.object().shape({
        email: Yup.string().trim().required(),
        code: Yup.string().trim().required(),
    });

    const getApiEndpoint = useCallback(() => requireBrowserTrust ? "email-validation/verifyCode/trusted-browser" : "email-validation/verifyCode", [requireBrowserTrust]);

    const sendVerificationCode = useCallback(async () => {

        const isValidSchema = validationSchema.isValidSync({ email, code });
        if (!isValidSchema) {
            setErrorMessage(t("error_empty_code"));
            return;
        };

        try {
            const endpoint = getApiEndpoint();
            const response = await axios.post(endpoint, { email, code, language }, { withCredentials: true });
            if (response.status === 200 || response.status === 201) {

                if (requireSelectInstitution) {
                    // Show institution selection dialog
                    updateAuthData({ requireSelectInstitution: true, requireVerificationCode: false, requireBrowserTrust: false });
                } else if (requireLoginPolicies) {
                    // Show login policies dialog
                    updateAuthData({ requireLoginPolicies: true, requireVerificationCode: false, requireBrowserTrust: false });
                } else {
                    // All steps completed, store token in localStorage and navigate
                    storeAuthData(token, refreshToken);
                }
            }
        } catch (error) {
            console.error("Error validating code:", error.message);
            setErrorMessage(error.message || t("error_invalid_code"));
            CustomErrorLogger(error, "Error validating code");
        }
    }, [validationSchema, email, code, t, getApiEndpoint, language, requireSelectInstitution, requireLoginPolicies, updateAuthData, storeAuthData, token, refreshToken]);

    return (
        <Dialog open={requireVerificationCode || requireBrowserTrust}>
            <DialogTitle display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                <Box sx={{ width: '150px' }} component="img" src={logo} alt="Imeah Logo" />
                <LanguageSwitcher handleLangUpdate={handleLangUpdate} />
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }} >

                <Typography color={'#17536B'} variant="subtitle1">{t('email_validation_dialog_title')}</Typography>
                <Typography color={'#949494'} variant='subtitle2'>{t('email_validation_dialog_subtittle')}</Typography>

                {errorMessage && <Alert className="login-errMsg" severity="error">{errorMessage}</Alert>}

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <OTP separator={<span>-</span>} value={code} onChange={(newOtp) => setCode(newOtp)} length={6} />
                </Box>
                {/* MFA Hint Message */}
                {authData?.institutionUsers?.length > 1 &&
                    <Typography color={'#949494'} variant='subtitle2'>{t('mfa_hint_message')}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button className="buttonFilled" fullWidth onClick={sendVerificationCode}>{t("validate")}</Button>
            </DialogActions>
        </Dialog>
    )
}