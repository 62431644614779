import { LanguageSwitcher } from "../components/NoLoginLanguageSwitcher";
import emailSentImage from '../images/emailSent.svg';
import logo from '../images/logo_e_health_pro.svg';
import '../css/User-css/emailSuccessfullySent.css';
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";
import React from "react";

export const EmailSuccessfullySent = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Page title={t("Forgot_Password_Page_Title")} className='FG-container-pass'>
            <Box className='emailSent_main_box' >

                <Box display='flex' justifyContent='space-between'>
                    <Box sx={{ width: '200px' }} component="img" src={logo} alt="imeah logo" />
                    <LanguageSwitcher/>
                </Box>

                <Box className="emailSent_image" component="img" src={emailSentImage} alt="email sent image" />

                <Box className='emailSent_title_typo_box'>
                    <Typography className="emailSent_title">{t("checkYourEmail")}</Typography>
                    <Typography className="emailSent__typo">{t("weSentPasswordRecovers")}</Typography>
                </Box>

                <Box>
                    <Typography className="emailSent__typo">{t("notReceiveEmail")}</Typography>
                    <Box className="emailSent__tryAgain_box">
                        <Typography className="emailSent__typo">{t("or")}</Typography>
                        <Typography className="emailSent__tryAgain" onClick={() => navigate('/forgotPassword')}>{t("tryAnotherEmail")}</Typography>
                    </Box>
                </Box>

            </Box>
        </Page>
    );
}