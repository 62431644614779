import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: [],
  reducers: {
    setNotifications: (state, action) => {
      return action.payload;
    },
    addNotification: (state, action) => {
      state.unshift(action.payload);
    },
    deleteNotification: (state, action) => {
      const notificationIndex = state.findIndex(notification => notification.id === action.payload);
      if (notificationIndex !== -1) {
        state.splice(notificationIndex, 1);
      }
    },
    clearNotifications: (state) => {
      state.splice(0, state.length);
    },
    deleteNotificationsByPath: (state, action) => {
      const path = action.payload;
      return state.filter(notification => notification.redirectPath !== path);
    }
  },
});

export const { setNotifications, addNotification, deleteNotification, clearNotifications, deleteNotificationsByPath } = notificationsSlice.actions;

export default notificationsSlice.reducer;