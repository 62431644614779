import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from "react-international-phone";
import { InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';

export const ImeahPhoneInput = ({ value, onChange, required, ...restProps }) => {

    const { t } = useTranslation();

    const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
        value,
        defaultCountry: "fr",
        countries: defaultCountries,
        onChange: (data) => {
            if (data.phone !== `+${data.country.dialCode}`) {
                onChange(data.phone);
            }
        }
    });

    const [rawValue, setRawValue] = useState(phone);

    const handleInputChange = useCallback((event) => {
        const newValue = event.target.value;
        setRawValue(newValue);
        handlePhoneValueChange(event);
    }, [handlePhoneValueChange]);

    const handleCountryChange = useCallback((event) => {
        const newCountryIso2 = event.target.value;
        setCountry(newCountryIso2);

        const newCountry = defaultCountries.find(([name, iso2, dialCode]) => iso2 === newCountryIso2);
        if (newCountry) {
            setRawValue(`+${newCountry[2]}`);
        }
    }, [setCountry]);

    const countryOptions = useMemo(() => {
        return defaultCountries.map((defaultCounty) => {
            const parsedCountry = parseCountry(defaultCounty);
            return (
                <MenuItem key={parsedCountry.iso2} value={parsedCountry.iso2} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <FlagImage iso2={parsedCountry.iso2} />
                    <Typography>{parsedCountry.name}</Typography>
                    <Typography color="gray">+{parsedCountry.dialCode}</Typography>
                </MenuItem>
            );
        });
    }, []);

    return (
        <TextField
            inputRef={inputRef}
            fullWidth
            className="customTextField"
            required={required}
            label={t("Phone_Number")}
            value={rawValue}
            onChange={handleInputChange}
            type="tel"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Select
                            MenuProps={{
                                style: {
                                    height: "300px",
                                    width: "360px",
                                    top: "10px",
                                    left: "-34px"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                }
                            }}
                            sx={{
                                width: "max-content",
                                fieldset: {
                                    display: "none"
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "none"
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: "block"
                                    }
                                },
                                ".MuiSelect-select": {
                                    padding: "8px",
                                    paddingRight: "24px !important"
                                },
                                svg: {
                                    right: 0
                                }
                            }}
                            value={country.iso2}
                            onChange={handleCountryChange}
                            renderValue={(value) => (
                                <FlagImage iso2={value} />
                            )}
                        >
                            {countryOptions}
                        </Select>
                    </InputAdornment>
                )
            }}
            error={rawValue === `+${country.dialCode}` && required}
            helperText={(rawValue === `+${country.dialCode}` && required) && (t('isRequired'))}
            {...restProps}
        />
    );
};