import { Box, TextField, Stack, Typography, Button, Snackbar, Alert, Grid } from "@mui/material";
import { HasPermission } from "../../utils/checkUserPermission";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MuiMultiSelect } from "../../utils/muiMultiSelect";
import { MuiSelect } from "../../utils/muiSelect";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import 'react-international-phone/style.css';
import '../../css/User-css/addNewUser.css';
import { useSelector } from 'react-redux';
import Page from "../../components/Page";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";


export const AddNewUser = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentInstitution = useSelector(state => state.currentInstitution);
  const isAllowedCreateAllUsers = HasPermission('Create_All_User');

  const [email, setEmail] = useState("");
  const [oneInstitution, setOneInstitution] = useState({ label: "", value: "" });
  const [oneRole, setOneRole] = useState({ value: "", label: "", canHaveSpeciality: false, canHaveExpertise: false, });
  const [choosenExpertises, setChoosenExpertises] = useState([]);
  const [choosenSpecialities, setChoosenSpecialities] = useState('');
  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email(t("validEml")).required(t("emailReq")),
    institutionId: Yup.string().required(),
    roleId: Yup.string().required(),
  });

  const handleInviteUser = async () => {
    try {
      setLoading(true);
      let formattedExpertises = [];
      const TargetIstitutionId = isAllowedCreateAllUsers ? oneInstitution.value : currentInstitution.institution.id;
      const targetInstitutionName = isAllowedCreateAllUsers ? oneInstitution.label : currentInstitution.institution.name;

      if (Array.isArray(choosenExpertises) && choosenExpertises.length > 0) {
        // Convert expertises if present
        formattedExpertises = choosenExpertises.map(expertise => ({ id: expertise.value, label: expertise.label }));
      }

      const dto = {
        email: email,
        institutionId: TargetIstitutionId,
        roleId: oneRole.value,
        specialityId: choosenSpecialities.value || 'NoSpeciality',
        expertises: formattedExpertises,
        institutionName: targetInstitutionName,
      };

      const checkValidation = validationSchema.isValidSync({
        email: dto.email,
        institutionId: dto.institutionId,
        roleId: dto.roleId,
      })

      if (checkValidation) {
        const response = await axios.post("invite-new-users", dto);
        if (response.status === 200 || response.status === 201) {
          setOpenSnackbar(true);
          setLoading(false);
        }
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      setErrMsg(error.message);
      setLoading(false);
    }
  }

  const fetchRoles = async (page, search) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('roles', { params: { offset, limit, search, institutionId: oneInstitution.value } });
      return response.data.map(record => ({ value: record.roleId, label: record.label, canHaveSpeciality: record.role.canHaveSpeciality, canHaveExpertise: record.role.canHaveExpertise, }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchInstitutions = async (page, search) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('institutions/findInstitutionsForCreateUser', {
        params: {
          offset, limit, filters: {
            search: search || '',
          },
        }
      });
      return response.data.map(record => ({ value: record.id, label: record.name }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const fetchSpecialities = async (page, search) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('speciality', { params: { offset, limit, search: search } });

      return response.data.map(record => ({ value: record.specialityId, label: record.label, }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const fetchExpertises = async (page, search) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('expertise', { params: { offset, limit, search: search } });
      return response.data.map(record => ({ value: record.expertiseId, label: record.label, }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleChangeInstitution = (selectedInstitution) => {
    setOneInstitution(selectedInstitution.target);
    setOneRole({ value: "", label: "", canHaveSpeciality: false, canHaveExpertise: false, });
    setChoosenSpecialities('');
    setChoosenExpertises([]);
  }

  const handleChangeRole = (selectedRole) => {
    setOneRole(selectedRole.target);
    setChoosenSpecialities('');
    setChoosenExpertises([]);
  }

  const handleSpecialityChange = (selectedOptions) => {
    setChoosenSpecialities(selectedOptions.target);
    setChoosenExpertises([]);
  };

  const handleExpertiseChange = (selectedOptions) => {
    setChoosenExpertises(selectedOptions);
    setChoosenSpecialities('');
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    navigate(-1);
  };

  return (
    <Page title={t("Invite_user_Page_Title")} sx={{ display: 'flex', flexDirection: 'column' }}>

      <Box sx={{ display: 'flex', gap: '10px' }}>
        <ArrowBackIosIcon className='addNewUser-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
        <Stack className="addNewUser-main-page-header">
          <Box className="addNewUser-page-title-box">
            <Typography className='addNewUser-page-title'>{t("new-users")}</Typography>
          </Box>
        </Stack>
      </Box>

      <Typography className="error-message">{errMsg}</Typography>

      <Grid container spacing={2}>

        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            id="email"
            name="email"
            type="email"
            label={t("email")}
            required
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!Yup.reach(validationSchema, 'email').isValidSync(email)}
            helperText={!Yup.reach(validationSchema, 'email').isValidSync(email) && t('isRequired')}
          />
        </Grid>

        {isAllowedCreateAllUsers &&
          <Grid item xs={12} sm={6}>
            <MuiSelect
              label={t('institu')}
              required={true}
              searchable={true}
              value={oneInstitution}
              fetchFunction={fetchInstitutions}
              onChange={(selectedInstitution) => handleChangeInstitution(selectedInstitution)}
            />
          </Grid>}

        <Grid item xs={12} sm={6}>
          <MuiSelect
            key={`roleKey-${oneInstitution.value}`}
            label={t('role')}
            required={true}
            searchable={true}
            value={oneRole}
            fetchFunction={fetchRoles}
            onChange={(selectedRole) => handleChangeRole(selectedRole)}
          />
        </Grid>
        {oneRole.canHaveSpeciality &&
          <Grid item xs={12} sm={6}>
            <MuiSelect
              key={`specialities`}
              label={t('specialities')}
              required={false}
              searchable={true}
              value={choosenSpecialities}
              fetchFunction={fetchSpecialities}
              onChange={handleSpecialityChange}
            />
          </Grid>}

        {oneRole.canHaveExpertise &&
          <Grid item xs={12} sm={6}>
            <MuiMultiSelect
              key={`expertises`}
              label={t('expertises')}
              required={false}
              searchable={true}
              value={choosenExpertises}
              fetchFunction={fetchExpertises}
              onChange={handleExpertiseChange}
            />
          </Grid>
        }

      </Grid>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {t('snackBarInvitationTitle')}
          { email + " " + t('snackBarInvitationContent') + " " + oneInstitution?.label + " " + t('as') + " " + oneRole?.label}
        </Alert>
      </Snackbar>

      <Box sx={{ display: 'flex', gap: '10px', alignSelf: 'flex-end', marginTop: '10px' }}>
        <Button onClick={() => navigate(-1)} variant="contained" className="new-button"  >{t("pageCancel")}</Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleInviteUser} className="new-button" >{t("invite")}</LoadingButton>
      </Box>

    </Page>
  );
}