import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';

export const EditSectionLabel = ({ template, setTemplate, openEditSectionLabel, setOpenEditSectionLabel, sectionForEdit }) => {

    const { t } = useTranslation();

    const [label, setLabel] = useState(sectionForEdit?.value);
    const [errorDuplicationMessage, setErrorDuplicationMessage] = useState('');

    const validationLabel = Yup.object().shape({
        label: Yup.string().trim().required(),
    });

    const checkValidation = validationLabel.isValidSync({ label });

    const handleEditLabel = async () => {
        if (checkValidation) {
            try {
                const data = {
                    label,
                    sectionForEdit
                };

                const response = await axios.patch(`medical-file-templates/editSectionLabel/${template.id}`, data);

                if (response.status === 200 || response.status === 201) {
                    setTemplate((prevData) => ({ ...prevData, sections: response.data }));
                    setOpenEditSectionLabel(false);
                }
            } catch (err) {
                setErrorDuplicationMessage(err.message);
                console.error(err, 'Error editing section label in template');
                CustomErrorLogger(err, 'Error editing section label in template');
            }
        }
    };

    return (
        <Dialog open={openEditSectionLabel} keepMounted onClose={() => setOpenEditSectionLabel(false)}>
            <DialogTitle>{t('Change_Section_Title')}</DialogTitle>

            {errorDuplicationMessage && <Alert severity="error">{errorDuplicationMessage}</Alert>}

            <DialogContent>
                <TextField
                    fullWidth
                    autoFocus
                    required
                    margin="dense"
                    id="label"
                    name='label'
                    label={t('label')}
                    type="text"
                    value={label}
                    variant="outlined"
                    onChange={e => setLabel(e.target.value)}
                    error={!label}
                    helperText={!label && t('isRequired')}
                />
            </DialogContent>
            <DialogActions>
                <Button className='new-button' fullWidth onClick={() => setOpenEditSectionLabel(false)}>{t('cancel')}</Button>
                <Button className='new-button' fullWidth onClick={handleEditLabel} disabled={!checkValidation}>{t('save')}</Button>
            </DialogActions>
        </Dialog>

    );
};
