import { Button, Box, Select, MenuItem, TextField, FormControl, InputLabel, Typography, Alert } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { StyledFieldset } from '../../../utils/styledFieldSet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Label from "../../Label";
import * as Yup from 'yup';
import axios from 'axios';
import '../../../css/templates/addTemplateListField.css';

const useStyles = makeStyles(() => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#09C3CC !important',
    },
}));

export const AddTemplateListField = ({ fieldId, template, setTemplate }) => {

    const { t } = useTranslation();
    const classes = useStyles();

    const [selectlist, setSelectlist] = useState([]);

    const [listItemLabel, setListItemLabel] = useState('');
    const [listItemType, setListItemType] = useState('');
    const [listItemListId, setListItemListId] = useState('');
    const [allMultiValues, setAllMultiValues] = useState([]);
    const [newValue, setNewValue] = useState('');
    const [errorDuplicationMessage, setErrorDuplicationMessage] = useState(null);

    const validationNormaleListItem = Yup.object().shape({
        Label: Yup.string().trim().required(),
        Type: Yup.string().trim().required(),
    });

    const validationSelectListItem = Yup.object().shape({
        Label: Yup.string().trim().required(),
        Type: Yup.string().trim().required(),
        ListId: Yup.string().trim().required(),
    });

    const validationListMultiValueItem = Yup.object().shape({
        Label: Yup.string().trim().required(),
        Type: Yup.string().trim().required(),
        multiValues: Yup.array().min(1),
    });

    const fetchSelectList = async () => {
        if (selectlist.length > 0 || (listItemType !== 'select' && listItemType !== 'Multi_select')) return; // Avoid duplicate fetches
        try {
            const response = await axios.get(`select-list/getSpecificSelectEdit`, {
                params: {
                    specialityId: template.speciality.id,
                    expertiseId: template.expertise.id,
                    fieldType: listItemType === 'select' ? 'Select' : 'Multi_select'
                },
            });
            if (response.status === 200 || response.status === 201) {
                setSelectlist(response.data);
            }
        } catch (error) {
            console.error("Error fetching select list:", error);
            CustomErrorLogger(error, "Error fetching select list for add template list field");
        }
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const item = Array.from(allMultiValues);
        const [reorderedItem] = item.splice(result.source.index, 1);
        item.splice(result.destination.index, 0, reorderedItem);
        setAllMultiValues(item);
    };

    const handlePushValue = () => {
        if (!newValue || allMultiValues.includes(newValue)) {
            setErrorDuplicationMessage(t('attributeNotAccepted'));
        }
        else {
            setAllMultiValues((prevValues) => [...prevValues, newValue]);
            setNewValue("");
            setErrorDuplicationMessage(null);
        }
    };

    const handleRemoveValue = (item) => {
        setAllMultiValues((prevValues) => prevValues.filter((value) => value !== item));
    };

    const handleAddOneFieldInList = async (fieldId) => {
        let validationSchema;

        if (listItemType === 'select' || listItemType === 'Multi_select') {
            validationSchema = validationSelectListItem;
        } else if (listItemType === 'Multi_value') {
            validationSchema = validationListMultiValueItem;
        } else {
            validationSchema = validationNormaleListItem;
        }

        if (validationSchema.isValidSync({ Label: listItemLabel, Type: listItemType, ListId: listItemListId, multiValues: allMultiValues })) {
            try {
                const newListField = {
                    id: uuidv4(),
                    ItemLabel: listItemLabel,
                    ItemType: listItemType,
                    ItemValue: (listItemType === 'Multi_select' || listItemType === 'Multi_value') ? [] : '',
                    listItemListId
                };

                if (listItemType === 'Multi_value') {
                    newListField.multiValues = allMultiValues;
                }

                const data = { newListField, fieldId };

                const response = await axios.patch(`medical-file-templates/addListField/${template.id}`, data);

                if (response.status === 200 || response.status === 201) {
                    setTemplate((prevData) => ({ ...prevData, additional_fields: response.data }));
                    setListItemLabel('');
                    setListItemType('');
                    setListItemListId('');
                    setErrorDuplicationMessage(null);
                    setAllMultiValues([]);
                }
            } catch (err) {
                console.log(err);
                CustomErrorLogger(err, 'Error adding new field to template');
                setErrorDuplicationMessage(err.message);
            }
        }
    };

    const handleChangeFieldType = (newType) => {
        setListItemType(newType);
        setListItemListId('');
        setAllMultiValues([]);
        setSelectlist([]);
    }

    return (
        <Box  >
            {errorDuplicationMessage && <Alert severity="error">{errorDuplicationMessage}</Alert>}

            <TextField
                fullWidth
                required
                margin="dense"
                id="ItemLabel"
                name='ItemLabel'
                label={t('itemLabel')}
                type="text"
                value={listItemLabel}
                variant="outlined"
                onChange={(e) => setListItemLabel(e.target.value)}
                error={!listItemLabel}
                helperText={!listItemLabel && t('isRequired')}
            />

            <FormControl required fullWidth className='form-control'
                error={!listItemType}
                helperText={!listItemType && t('isRequired')}>
                <InputLabel id="demo-simple-select-label-type">{t('itemType')}</InputLabel>
                <Select
                    labelId="demo-simple-select-label-type"
                    id="ItemType"
                    label={t('itemType')}
                    name='ItemType'
                    value={listItemType}
                    onChange={(e) => handleChangeFieldType(e.target.value)}
                >
                    <MenuItem value={'text'}>{t('text')}</MenuItem>
                    <MenuItem value={'number'}>{t('number')}</MenuItem>
                    <MenuItem value={'date'}>{t('date')}</MenuItem>
                    <MenuItem value={'long Text'}>{t('long Text')}</MenuItem>
                    <MenuItem value={'password'}>{t('password')}</MenuItem>
                    <MenuItem value={'select'}>{t('select')}</MenuItem>
                    <MenuItem value={'Multi_select'}>{t('Multi_select')}</MenuItem>
                    <MenuItem value={'Multi_value'}>{t('Multi_value')}</MenuItem>
                    <MenuItem value={'file'}>{t('file')}</MenuItem>
                </Select>
            </FormControl>
            {(listItemType === 'select' || listItemType === 'Multi_select') &&
                <FormControl required fullWidth className='form-control'
                    error={!listItemListId}
                    helperText={!listItemListId && t('isRequired')} >
                    <InputLabel id="demo-simple-select-label">{t('selectList')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="selectlist"
                        label={t('selectList')}
                        name='selectlist'
                        onOpen={fetchSelectList}
                        value={listItemListId}
                        onChange={(e) => setListItemListId(e.target.value)}
                    >
                        {selectlist && selectlist.map((list) =>
                            <MenuItem key={list.id} value={list.selectListId}>{list.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {(listItemType === 'Multi_value') &&
                <Box>
                    <Box sx={{ display: "flex", alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        <TextField
                            id="value"
                            name="value"
                            fullWidth
                            label={t('inputNewValue')}
                            type="text"
                            value={newValue}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            onChange={(e) => setNewValue(e.target.value)}
                        />
                        <AddCircleIcon
                            data-testid="add-value"
                            className="circle-add-icon"
                            onClick={handlePushValue}
                        />
                    </Box>

                    {allMultiValues.length > 0 &&
                        <StyledFieldset component="fieldset" sx={{ marginTop: '10px' }}>
                            <legend>{t('values')}</legend>
                            <Box className='addTempField-servise-legend'>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="values" direction="horizontal">
                                        {(provided) => (
                                            <Box {...provided.droppableProps} ref={provided.innerRef} className='addTempField-servise-box'>
                                                {allMultiValues && allMultiValues.map((item, index) => (
                                                    <Draggable key={item} draggableId={item} index={index}>
                                                        {(provided) => (
                                                            <Typography {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                                <Label>{item}</Label>
                                                                <CloseRoundedIcon
                                                                    onClick={() => handleRemoveValue(item)}
                                                                    className="close-icon"
                                                                />
                                                            </Typography>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Box>
                        </StyledFieldset>
                    }
                </Box>
            }

            <Button className='add-templatelistfield-btn' onClick={() => handleAddOneFieldInList(fieldId)} data-testid="addField">{t('add')}</Button>
        </Box>
    )
}