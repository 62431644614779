import { useRef, useCallback } from 'react';

// Debounce function implementation
function debounce(fn, delay) {
  let timerId;

  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

// useDebounceFunction hook
export function useDebounceFunction(callback, delay) {
  // Create a ref to store the debounced version of the callback
  const debouncedCallback = useRef(debounce(callback, delay)).current;

  // Return the debounced callback wrapped in useCallback
  return useCallback((...args) => {
    debouncedCallback(...args);
  }, [debouncedCallback]);
}