import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Iconify = React.memo(({ icon, sx = {}, ...other }) => {
  return <Box component={Icon} icon={icon} sx={sx} {...other} />;
});

Iconify.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.element,  // If the icon is a React element
    PropTypes.string    // If the icon is a string (which is the usual case with Iconify)
  ]).isRequired,
  sx: PropTypes.object,
};

// Default props are already handled by the inline default in destructuring
export default Iconify;