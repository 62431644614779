import { Box, Typography, Card } from '@mui/material'
import React from 'react'
import { useState } from "react";
import '../../../../../css/CV/educationCv.css'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import { fDate } from '../../../../../utils/formatTime'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { EditWork } from './editWork';
import { DeleteWork } from './deleteWork';
import { useSelector } from 'react-redux';

export const WorkCV = (props) => {
  const { t } = useTranslation();
  const userData = useSelector(state => state.userData);

  const { setSection, work, setWork, userCvId } = props
  const [editWorkData, setEditWorkData] = useState([]);
  const [dataIndex, setDataIndex] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleEditOpen = (edu, index) => {
    setOpenEdit(true)
    setDataIndex(index)
    setEditWorkData(edu)
  }

  const handleDeleteOpen = (index) => {
    setOpenDelete(true)
    setDataIndex(index)
  }

  const convertNewLineToBreak = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Box className="userCv-main-box">
      {work?.length > 0 ?
        <Card className="userCv-main-card">
          <Typography className="userCv-main-typo">{t('work')}</Typography>
          <Box className="userCv-data-box">
            {work.map((edu, index) => (
              <Box key={index} className="userCv-box-border-bottom">
                <Typography className="userCv-typo-uni">{edu.Company}</Typography>
                <Typography className="userCv-typo-degree">{edu.Post}</Typography>
                <Typography className="userCv-typo-date">{fDate(edu.Start_date, userData.local_code)} / {edu.Current === 'no' ? fDate(edu.End_date, userData.local_code) : t('currentWork')}</Typography>
                <Typography className="userCv-typo-desc">{convertNewLineToBreak(edu.Description)}</Typography>
                {userData.id === userCvId ?
                  <Box className="userCv-actions-box">
                    <HighlightOffIcon onClick={() => handleDeleteOpen(index)} className="delete-icon" data-testid="deleteEducation"/>
                    <EditIcon className="account-icon" label={t('Edit')} onClick={() => handleEditOpen(edu, index)} data-testid="editEducation"/>
                  </Box>
                  : null}
              </Box>
            ))}
            {userData.id === userCvId ?
              <AddIcon className="userCv-actions-add-icon" onClick={() => { setSection("Work") }} />
              : null}
          </Box>
        </Card> : null}
      {openEdit ? <EditWork editWorkData={editWorkData} openEdit={openEdit} setOpenEdit={setOpenEdit} dataIndex={dataIndex} work={work} setWork={setWork} /> : null}
      {openDelete ? <DeleteWork openDelete={openDelete} setOpenDelete={setOpenDelete} dataIndex={dataIndex} work={work} setWork={setWork}  /> : null}

    </Box>
  )
}