import { Box, CircularProgress, IconButton, Button, Typography, InputBase, Alert, Divider, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DeleteTemplateFieldInList } from './deleteTemplateCustomTabInList';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { EditTemplateListLabel } from './editFieldLabelTemplateInList';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { HasPermission } from '../../../utils/checkUserPermission';
import { EditFieldLabelTemplate } from './editFieldLabelTemplate';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DeleteTemplateField } from './deleteTemplateCustomTab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AddTemplateListField } from './addTemplateListField';
import { useNavigate, useParams } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CanManageTemplate } from '../CanManageTemplate';
import { AddTemplateField } from './addTemplateField';
import { EditSectionLabel } from './editSectionLabel';
import { EditTemplateTabs } from './editTemplateTabs';
import EditIcon from '@mui/icons-material/Edit';
import { TabContext, TabPanel } from '@mui/lab';
import { RemoveSection } from './removeSection';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddSection } from './addSection';
import Iconify from '../../Iconify';
import Label from '../../Label';
import Page from "../../Page";
import * as Yup from 'yup';
import axios from 'axios';
import '../../../css/templates/editTemplate.css';

export const EditTemplate = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);
    const filteredPermissions = useSelector(state => state.userPermissions);
    //check user permissions    
    const isAllowedChangeInstitutionTemplateOwner = HasPermission('change_institution_template_owner');

    const { id } = useParams()
    const [template, setTemplate] = useState([]);
    const [currentSection, setCurrentSection] = useState('');
    const [sectionForRemove, setSectionForRemove] = useState('');
    const [fieldToEditLabel, setFieldToEditLabel] = useState('');
    const [FieldId, setFieldId] = useState('');
    const [listFieldId, setlistFieldId] = useState('');
    const [sectionForEdit, setSectionForEdit] = useState('');
    const [loading, setLoading] = useState(true);
    const [openCustomTabs, setOpenCustomTabs] = useState(false);
    const [showAddSection, setShowAddSection] = useState(false);
    const [showRemoveSection, setShowRemoveSection] = useState(false);
    const [editLabelDialogStatus, setEditLabelDialogStatus] = useState(false);
    const [editFieldLabelInList, setEditFieldLabelInList] = useState(false);
    const [openDeleteField, setOpenDeleteField] = useState(false);
    const [openDeleteFieldInList, setOpenDeleteFieldInList] = useState(false);
    const [openEditSectionLabel, setOpenEditSectionLabel] = useState(false);
    const [error, setError] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required(),
        description: Yup.string().trim().required(),
    });

    const checkValidation = validationSchema.isValidSync({
        name: template.name,
        description: template.description
    });

    const fetchTemplateForEdit = useCallback(async () => {

        if (!currentInstitution) return;

        try {
            const response = await axios.get(`medical-file-templates/${id}`);
            if (response.status === 200 || response.status === 201) {

                const { specialityId, expertiseID, static_fields, sections } = response.data;

                if (CanManageTemplate(response.data, { filteredPermissions, institutionId: currentInstitution.institution.id, id: userData.id })) {
                    const updatedTemplate = response.data;

                    if (specialityId === "NoSpeciality") {
                        updatedTemplate.static_fields = static_fields.filter(field => field.label !== "idSpeciality");
                    }

                    if (expertiseID === "NoExpertise") {
                        updatedTemplate.static_fields = static_fields.filter(field => field.label !== "expertise");
                    }
                    if (Array.isArray(sections) && sections.length > 0) {
                        setCurrentSection(sections[0].id)
                    }
                    setTemplate(updatedTemplate);
                    setLoading(false);
                }
            }
        } catch (err) {
            console.error(err, 'Error fetching template for edit');
            CustomErrorLogger(err, 'Error fetching template for edit');
        }
    }, [filteredPermissions, currentInstitution, userData, id]);

    useEffect(() => {
        fetchTemplateForEdit();
    }, [fetchTemplateForEdit]);

    const updateTemplate = useCallback(async () => {
        if (!checkValidation) return;
        try {
            await axios.patch(`medical-file-templates/${id}`, { name: template.name, description: template.description });
            setError(null);
        } catch (error) {
            console.log(error);
            CustomErrorLogger(error, 'Error updating template');
            setError(error.message);
        }
    }, [checkValidation, template.name, template.description, id]);

    useEffect(() => {
        const handler = setTimeout(() => {
            updateTemplate()
        }, 1500); // 1.5 seconds delay

        return () => {
            clearTimeout(handler); // Cleanup the timeout if the inputValue changes or component unmounts
        };
    }, [updateTemplate]);

    const handleChange = useCallback((event) => {
        const { name, value } = event.target;
        setTemplate((prevTemplate) => ({ ...prevTemplate, [name]: value }));
    }, []);

    const handleCustomTabsOpen = useCallback(() => {
        setOpenCustomTabs(true)
    }, []);

    const handleEditLabelStatusOpen = useCallback((row) => {
        setEditLabelDialogStatus(true)
        setFieldToEditLabel(row)
    }, []);

    const handleEditSectionOpen = useCallback((section) => {
        setOpenEditSectionLabel(true)
        setSectionForEdit(section)
    }, []);

    const handleShowRemoveSectionOpen = useCallback((section) => {
        setShowRemoveSection(true)
        setSectionForRemove(section)
    }, []);

    const handleEditListLabelOpen = useCallback((row, fieldId) => {
        setFieldToEditLabel(row)
        setFieldId(fieldId)
        setEditFieldLabelInList(true)
    }, []);

    const handleShowAddSectionOpen = useCallback(() => {
        setShowAddSection(true)
    }, []);

    const handleDeleteFieldOpen = useCallback((id) => {
        setFieldId(id)
        setOpenDeleteField(true)
    }, []);

    const handleDeleteListFieldOpen = useCallback((itemId, fieldId) => {
        setFieldId(fieldId)
        setlistFieldId(itemId)
        setOpenDeleteFieldInList(true)
    }, []);

    const handleChangeArraySorting = (result) => {
        const { source, destination } = result;

        if (!destination || source.index === destination.index) { return }

        const newAdditional_fields = [...template.additional_fields.filter((row) => row.section !== currentSection)];
        const specifique = [...template.additional_fields.filter((row) => row.section === currentSection)]
        const [removedRow] = specifique.splice(source.index, 1);
        specifique.splice(destination.index, 0, removedRow);
        const mergedArray = newAdditional_fields.concat(specifique);
        setTemplate((prevData) => ({ ...prevData, additional_fields: mergedArray }));

        const data = { newAdditionalFields: mergedArray };
        axios.patch(`medical-file-templates/changeFieldSorting/${template.id}`, data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setTemplate((prevData) => ({ ...prevData, additional_fields: response.data }));
                }
            })
            .catch((err) => {
                console.log(err, 'Error changing field sorting');
                CustomErrorLogger(err, 'Error changing field sorting');
            });
    }

    const renderOwners = useMemo(() => {
        return (owners) => {
            if (owners && owners.length > 0) {
                return owners?.map((data, index) => (
                    <Label sx={{ color: 'black' }} key={index}>{data.user?.firstname} {data.user?.lastname}</Label>
                ));
            }
            return <Typography>{t('has_no_owner')}</Typography>
        };
    }, [t]);

    const ActionButton = ({ icon, title, onClick, disabled }) => (
        <Box className='readTemp-btn-container' onClick={disabled ? () => { } : onClick} disabled={true}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} className="readTemp-headerBtn-icon" />
            <Typography className="readTemp-btn-title">{title}</Typography>
        </Box>
    );

    const ActionMenuButton = ({ icon, title, onClick, disabled }) => (
        <MenuItem className='readTemp-btn-container' onClick={disabled ? () => { } : onClick}>
            <Iconify icon={icon} className="readTemp-headerBtn-icon" />
            <Typography className="readTemp-btn-title">{title}</Typography>
        </MenuItem>
    );

    const handleOpenActionMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Page title={t("Edit_Template_Page_Title")}>

            <Box className='readTemp-top-bar'>
                < ArrowBackIosIcon className='readTemp-back-btn' onClick={() => navigate(`/dashboard/medicalFilesTemplates/details/${template.id}`)} data-testid="backIcon" />

                {isDesktop &&
                    <Box className='readTemp-action-button-box'>

                        <ActionButton icon="hugeicons:document-validation" title={t('Valid_and_go_back')} disabled={!checkValidation} onClick={() => navigate(`/dashboard/medicalFilesTemplates/details/${template.id}`)} />

                        {(isAllowedChangeInstitutionTemplateOwner && currentInstitution.institution.id === template.institutionId) &&
                            <ActionButton icon="la:user-edit" onClick={() => navigate(`/dashboard/medicalFilesTemplates/EditOwner/${template.id}`, { state: { data: template } })} title={t('Edit_Owners')} />
                        }

                    </Box>
                }

                {!isDesktop &&
                    <Box className='readTemp-action-button-box'>

                        <IconButton
                            className="mdf-page-title-icon"
                            id="medicalFilePageActionMenuIcom"
                            aria-controls={openActionMenu ? 'long-menu' : undefined}
                            aria-expanded={openActionMenu ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleOpenActionMenu}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </Box>
                }

                <Menu
                    id="templatePageActionMenu"
                    MenuListProps="templatePageActionMenuIcom"
                    open={openActionMenu}
                    anchorEl={anchorEl}
                    onClose={handleCloseActionMenu}
                    slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
                >

                    <ActionMenuButton icon="hugeicons:document-validation" title={t('Valid_and_go_back')} disabled={!checkValidation} onClick={() => navigate(`/dashboard/medicalFilesTemplates/details/${template.id}`)} />

                    {(isAllowedChangeInstitutionTemplateOwner && currentInstitution.institution.id === template.institutionId) &&
                        <ActionMenuButton icon="la:user-edit" onClick={() => navigate(`/dashboard/medicalFilesTemplates/EditOwner/${template.id}`, { state: { data: template } })} title={t('Edit_Owners')} />
                    }

                </Menu>
            </Box>

            {loading ?
                <Box className='edit-temp-loading'><CircularProgress size="5rem" /></Box> :
                <Box>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Box className='read-onetemp-info-box'>
                        <Box className='read-onetemp-field-info'>
                            <Typography className='edit-temp-field-label'>{t('name')} :</Typography>
                            <InputBase
                                className='edit-temp-field-input'
                                margin="dense"
                                placeholder='name'
                                data-testid="templateName"
                                id="name"
                                name='name'
                                defaultValue={template.name}
                                type="text"
                                required
                                variant="outlined"
                                inputProps={{ maxLength: 80 }}
                                onChange={handleChange}
                            />
                        </Box>
                        <Box className='read-onetemp-field-info'>
                            <Typography className='edit-temp-field-label'>{t('description')} :</Typography>
                            <InputBase
                                className='edit-temp-field-input'
                                margin="dense"
                                id="description"
                                name='description'
                                placeholder='description'
                                defaultValue={template.description}
                                required
                                multiline
                                rows={4}
                                type="text"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box className='read-onetemp-field-info'>
                            <Typography className='edit-temp-field-label'>{t('Template_Owner/s')} :</Typography>
                            {renderOwners(template.template_owners)}
                        </Box>

                    </Box>

                    <TabContext value={currentSection}  >

                        <EditTemplateTabs
                            template={template}
                            setTemplate={setTemplate}
                            currentSection={currentSection}
                            setCurrentSection={setCurrentSection}
                            handleShowRemoveSectionOpen={handleShowRemoveSectionOpen}
                            handleEditSectionOpen={handleEditSectionOpen}
                            handleShowAddSectionOpen={handleShowAddSectionOpen}
                        />

                        <TabPanel value={currentSection} sx={{ padding: 1 }}>
                            {currentSection !== '' &&
                                <Button onClick={handleCustomTabsOpen} className='edit-temp-add-custom-field-btn' endIcon={<AddCircleIcon />}>{t('addCustomField')}</Button>
                            }
                            <DragDropContext onDragEnd={handleChangeArraySorting}>
                                <Droppable droppableId="additional_fields" >
                                    {(provided) => (
                                        <Box {...provided.droppableProps} ref={provided.innerRef} >

                                            {template.additional_fields && template.additional_fields.filter((item) => item.section === currentSection).map((info, index) => (
                                                <Draggable key={info.id} draggableId={info.id} index={index}>
                                                    {(provided) => (
                                                        <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                                                            <Box className='edit-temp-customfield-box' key={info.id}>
                                                                {info.type !== 'list' &&
                                                                    <Box className='read-onetemp-for-nolist-label-type'>
                                                                        <Typography className='read-onetemp-for-list-label'>{info.label} :</Typography>
                                                                        <Typography className='read-onetemp-for-list-type'>{t(info.type)} </Typography>
                                                                    </Box>
                                                                }
                                                                {info.type === 'list' &&
                                                                    <Box className='read-onetemp-for-list-box'>
                                                                        <Typography >{info.label}</Typography>

                                                                        <AddTemplateListField
                                                                            template={template}
                                                                            setTemplate={setTemplate}
                                                                            fieldId={info.id}
                                                                        />

                                                                        {info.value?.map((listItem, index) => (
                                                                            <Box key={index} className='edit-onetemp-for-list-label-type-actions'>
                                                                                <Box className='edit-onetemp-for-list-label-type'>
                                                                                    <Typography className='read-onetemp-for-list-label'>{listItem.ItemLabel} :</Typography>
                                                                                    <Typography className='read-onetemp-for-list-type'>{t(listItem.ItemType)} </Typography>
                                                                                </Box>

                                                                                <Box className='edit-onetemp-for-list-actions'>
                                                                                    <HighlightOffIcon onClick={() => handleDeleteListFieldOpen(listItem.id, info.id)} className='delete-icon' data-testid="removeListCustomField" />
                                                                                    <EditIcon label={t('Edit')} onClick={() => handleEditListLabelOpen(listItem, info.id)} className='account-icon' data-testid="editListCustomField" />
                                                                                </Box>


                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                }
                                                                <Box className='edit-onetemp-for-list-actions'>
                                                                    <HighlightOffIcon className='delete-icon' onClick={() => handleDeleteFieldOpen(info.id)} data-testid="removeNormalCustomField" />
                                                                    <EditIcon label={t('edit')} onClick={() => handleEditLabelStatusOpen(info)} className='account-icon' data-testid="editNormalCustomField" />
                                                                </Box>


                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Box>

                                    )}
                                </Droppable>
                            </DragDropContext>

                        </TabPanel>
                    </TabContext>
                </Box>
            }

            {openCustomTabs &&
                <AddTemplateField
                    template={template}
                    setTemplate={setTemplate}
                    openCustomTabs={openCustomTabs}
                    setOpenCustomTabs={setOpenCustomTabs}
                    currentSection={currentSection}
                />
            }

            {showAddSection &&
                <AddSection
                    template={template}
                    setTemplate={setTemplate}
                    showAddSection={showAddSection}
                    setShowAddSection={setShowAddSection}
                />
            }

            {showRemoveSection &&
                <RemoveSection
                    template={template}
                    setTemplate={setTemplate}
                    showRemoveSection={showRemoveSection}
                    setShowRemoveSection={setShowRemoveSection}
                    sectionForRemove={sectionForRemove}
                    setCurrentSection={setCurrentSection}
                />
            }

            {editLabelDialogStatus &&
                <EditFieldLabelTemplate
                    template={template}
                    setTemplate={setTemplate}
                    editLabelDialogStatus={editLabelDialogStatus}
                    setEditLabelDialogStatus={setEditLabelDialogStatus}
                    fieldToEditLabel={fieldToEditLabel}
                />
            }

            {editFieldLabelInList &&
                <EditTemplateListLabel
                    template={template}
                    setTemplate={setTemplate}
                    editFieldLabelInList={editFieldLabelInList}
                    setEditFieldLabelInList={setEditFieldLabelInList}
                    fieldToEditLabel={fieldToEditLabel}
                    FieldId={FieldId}
                />
            }

            {openDeleteField &&
                <DeleteTemplateField
                    template={template}
                    setTemplate={setTemplate}
                    openDeleteField={openDeleteField}
                    setOpenDeleteField={setOpenDeleteField}
                    FieldId={FieldId}
                />
            }

            {openDeleteFieldInList &&
                <DeleteTemplateFieldInList
                    template={template}
                    setTemplate={setTemplate}
                    openDeleteFieldInList={openDeleteFieldInList}
                    setOpenDeleteFieldInList={setOpenDeleteFieldInList}
                    FieldId={FieldId}
                    listFieldId={listFieldId}
                />
            }

            {openEditSectionLabel &&
                <EditSectionLabel
                    template={template}
                    setTemplate={setTemplate}
                    openEditSectionLabel={openEditSectionLabel}
                    setOpenEditSectionLabel={setOpenEditSectionLabel}
                    sectionForEdit={sectionForEdit}
                />
            }
        </Page >
    );
}