import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import i18next from 'i18next';

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // Detect the language of the user
  .use(HttpApi)
  .init({
    fallbackLng: 'en', // use en if detected lng is not available
    supportedLngs: ['fr', 'en'], // allow en and fr
    load: 'languageOnly', // Only load the language part of the locale
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'], // Detect the language from the cookie, htmlTag, localStorage, path, or subdomain
      caches: ['cookie', 'localStorage'], // Cache the language detected
    },
    backend: { loadPath: '/translation/{{lng}}.json' }, // Path to the translation files
    react: {
      useSuspense: true, // Use Suspense for handling the loading of translations
    },
  });

export default i18next;