import { MultiSelectInList } from '../components/medicalFile/listFieldTypes/multiSelectInList';
import { MultiValueInList } from '../components/medicalFile/listFieldTypes/multiValueInList';
import { FileInList } from '../components/medicalFile/listFieldTypes/fileInList/fileInList';
import { FieldSelectInList } from '../components/medicalFile/listFieldTypes/selectInList';
import { LongTextInList } from '../components/medicalFile/listFieldTypes/longTextInList';
import { PasswordInList } from '../components/medicalFile/listFieldTypes/passwordInList';
import { NumberInList } from '../components/medicalFile/listFieldTypes/numberInList';
import { TextInList } from '../components/medicalFile/listFieldTypes/textInList';
import { DateInList } from '../components/medicalFile/listFieldTypes/dateInList';

export const ItemSwitch = (item) => {
    switch (item.ItemType) {
        case 'text':
            return TextInList
        case 'number':
            return NumberInList;
        case 'date':
            return DateInList;
        case 'long Text':
            return LongTextInList;
        case 'password':
            return PasswordInList;
        case 'select':
            return FieldSelectInList;
        case 'Multi_select':
            return MultiSelectInList;
        case 'Multi_value':
            return MultiValueInList;
        case 'file':
            return FileInList;
        default:
            return null;
    }
}