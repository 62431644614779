import { Button, Dialog, Typography, DialogContent, DialogActions } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import Iconify from '../../Iconify';
import axios from 'axios';

export const DeleteRecommandationField = ({ medicalFileData, setMedicalFileData, openDeleteField, setOpenDeleteField, field }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    // Close dialog handler
    const handleCloseDialog = useCallback(() => {
        setOpenDeleteField(false);
    }, [setOpenDeleteField]);

    // Remove a field from the recommendation
    const handleRemoveField = useCallback(async () => {

        const bodyData = {
            medicalFileId: medicalFileData.id,
            FieldId: field.id,
            FieldType: field.type,
        };

        setLoading(true);

        try {
            const response = await axios.patch(`recommandation/removeField/${medicalFileData?.recommandation?.id}`, bodyData);
            if (response.status === 200 || response.status === 201) {
                setMedicalFileData(prevData => ({ ...prevData, recommandation: { ...prevData.recommandation, messages: response.data } }));
            }
        } catch (error) {
            console.error('Error removing recommendation field:', error);
            CustomErrorLogger(error, 'Error removing recommendation field');
        }
        finally {
            setLoading(false);
            handleCloseDialog();
        }
    }, [field.id, field.type, handleCloseDialog, medicalFileData.id, medicalFileData?.recommandation?.id, setMedicalFileData]);

    // Memoized dialog props to prevent unnecessary re-renders
    const dialogProps = useMemo(() => ({
        open: openDeleteField,
        keepMounted: true,
        onClose: handleCloseDialog,
    }), [openDeleteField, handleCloseDialog]);

    return (
        <Dialog {...dialogProps} >
            <DialogContent className='imeah_dialog_content'>
                <Iconify icon='ic:round-delete' className='imeah_dialog_icon' />
                <Typography className='imeah_dialog_title'>{t('sureDeleteField')}</Typography>
                <Typography className='imeah_dialog_subtitle'>{t('deleteFieldDialogDescription')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth className='buttonOutlined' onClick={handleCloseDialog}>{t('cancel')}</Button>
                <LoadingButton fullWidth className='buttonFilled' loading={loading} onClick={handleRemoveField}>{t('dlt')}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
