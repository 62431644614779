import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { HasPermission } from '../../../utils/checkUserPermission';
import { ImeahSelect } from '../../../utils/imeah_select';
import { Grid, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import axios from 'axios';

export const SelectSpecialityOrExpertise = ({ setTargetTemplateOwnerData, setChoosenTemplate, selectedSpeciality,
    setSelectedSpeciality, selectedExpertise, setSelectedExpertise, setTargetDoctorInstitutionId,
    setTargetDoctorForShare, setActiveStep }) => {

    const { t } = useTranslation();

    //check user permissions
    const isAllowedcreateMedicalFileForSpecialist = HasPermission('create_Medical_File_for_specialist');
    const isAllowedcreateMedicalFileForExpert = HasPermission('create_Medical_File_for_expert');

    const fetchSpecialities = useCallback(async () => {
        try {
            const response = await axios.get('speciality/specialitiesForCreateMedicalFile');
            return response.data?.map(record => ({ value: record?.value, label: record?.label }));
        } catch (err) {
            console.log(err);
            CustomErrorLogger(err, 'Error fetching specialities for create medical file');
            return [];
        }
    }, []);

    const fetchExpertises = useCallback(async () => {
        try {
            const response = await axios.get('expertise/expertisesForCreateMedicalFile');
            return response.data?.map(record => ({ value: record?.value, label: record?.label }));
        } catch (err) {
            console.log(err);
            CustomErrorLogger(err, 'Error fetching expertises for create medical file');
            return [];
        }
    }, []);

    const handleChangeSpeciality = useCallback((selectedSpeciality) => {
        setSelectedSpeciality(selectedSpeciality)
        setSelectedExpertise('')
        setTargetDoctorInstitutionId('')
        setTargetTemplateOwnerData('')
        setTargetDoctorForShare('')
        setActiveStep(0)
        setChoosenTemplate({
            id: '',
            name: '',
            description: '',
            additional_fields: [],
            sections: [],
        })
    }, [setSelectedSpeciality, setSelectedExpertise, setTargetDoctorInstitutionId, setTargetTemplateOwnerData, setTargetDoctorForShare, setActiveStep, setChoosenTemplate]);

    const handleChangeExpertise = useCallback((selectedExpertise) => {
        setSelectedExpertise(selectedExpertise)
        setSelectedSpeciality('')
        setTargetTemplateOwnerData('')
        setTargetDoctorInstitutionId('')
        setTargetDoctorForShare('')
        setActiveStep(0)
        setChoosenTemplate({
            id: '',
            name: '',
            description: '',
            additional_fields: [],
            sections: [],
        })
    }, [setSelectedExpertise, setSelectedSpeciality, setTargetTemplateOwnerData, setTargetDoctorInstitutionId, setTargetDoctorForShare, setActiveStep, setChoosenTemplate]);

    return (
        <Box >
            <Grid container spacing={3}>
                {isAllowedcreateMedicalFileForSpecialist &&
                    <Grid item xs={12} sm={6}>
                        <ImeahSelect
                            label={t('speciality')}
                            required={false}
                            searchable={true}
                            value={selectedSpeciality}
                            fetchFunction={fetchSpecialities}
                            onChange={(selectedSpeciality) => handleChangeSpeciality(selectedSpeciality.target)}
                        />
                    </Grid>
                }

                {isAllowedcreateMedicalFileForExpert &&
                    <Grid item xs={12} sm={6}>
                        <ImeahSelect
                            label={t('expertise')}
                            required={false}
                            searchable={true}
                            value={selectedExpertise}
                            fetchFunction={fetchExpertises}
                            onChange={(selectedExpertise) => handleChangeExpertise(selectedExpertise.target)}
                        />
                    </Grid>
                }
            </Grid>

            {!selectedSpeciality?.value && !selectedExpertise?.value && (
                <Typography className="imeahCaption">
                    {t('atLeastSpecialityOrExpertiseRequired')}
                </Typography>
            )}

        </Box>
    )
}