import { Input as BaseInput } from '@mui/base/Input';
import React, { useRef, useCallback } from 'react';
import { Box, styled } from '@mui/system';
import PropTypes from 'prop-types';

const blue = {
    200: '#80BFFF',
    400: '#3399FF',
    600: '#0072E5',
};

const grey = {
    200: '#DAE2ED',
    300: '#C7D0DD',
    700: '#434D5B',
    900: '#1C2025',
};

const InputElement = styled('input')(
    ({ theme }) => `
  width: 40px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 0px;
  border-radius: 8px;
  text-align: center;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

export const OTP = ({ separator, length, value, onChange }) => {

    const inputRefs = useRef([]);

    const focusAndSelectInput = useCallback((index) => {
        const input = inputRefs.current[index];
        if (input) {
            input.focus();
            input.select();
        }
    }, []);

    const handleKeyDown = useCallback((event, currentIndex) => {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case ' ':
                event.preventDefault();
                break;
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > 0) focusAndSelectInput(currentIndex - 1);
                break;
            case 'ArrowRight':
                event.preventDefault();
                if (currentIndex < length - 1) focusAndSelectInput(currentIndex + 1);
                break;
            case 'Delete':
            case 'Backspace':
                event.preventDefault();
                onChange((prev) => {
                    const otpArray = prev.split('');
                    otpArray.splice(currentIndex, 1, '');
                    return otpArray.join('');
                });
                if (event.key === 'Backspace' && currentIndex > 0) {
                    focusAndSelectInput(currentIndex - 1);
                }
                break;
            default:
                break;
        }
    }, [length, focusAndSelectInput, onChange]);

    const handleChange = useCallback((event, currentIndex) => {
        const currentValue = event.target.value;
        onChange((prev) => {
            const otpArray = prev.split('');
            otpArray[currentIndex] = currentValue.slice(-1);
            return otpArray.join('');
        });
        if (currentValue && currentIndex < length - 1) {
            focusAndSelectInput(currentIndex + 1);
        }
    }, [length, focusAndSelectInput, onChange]);

    const handlePaste = useCallback((event) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text/plain').slice(0, length);
        onChange(pastedText.padEnd(length, ' '));
    }, [length, onChange]);

    return (
        <Box sx={{ display: 'flex',  alignItems: 'center' }}>
            {Array.from({ length }).map((_, index) => (
                <React.Fragment key={index}>
                    <BaseInput
                        slots={{ input: InputElement }}
                        aria-label={`Digit ${index + 1} of OTP`}
                        slotProps={{
                            input: {
                                ref: (ele) => (inputRefs.current[index] = ele),
                                onKeyDown: (event) => handleKeyDown(event, index),
                                onChange: (event) => handleChange(event, index),
                                onPaste: handlePaste,
                                value: value[index] || '',
                            },
                        }}
                    />
                    {index < length - 1 && separator}
                </React.Fragment>
            ))}
        </Box>
    );
}

OTP.propTypes = {
    length: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    separator: PropTypes.node,
    value: PropTypes.string.isRequired,
};