import React from 'react'
import { Box, Typography, CardMedia } from '@mui/material'
import '../../css/homePage/homeNinePart.css'
import administrators from '../../images/administrators.svg'
import { useTranslation } from "react-i18next";

export const HomeNinePart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeNinePart-main-box'>
            <Box className="homeNinePart-typo-box">
                <Typography className="homeNinePart-typo-ehealth">ehealth pro</Typography>
                <Typography className="homeNinePart-typo-aussi">{t('towardsResponsibleMedicine')}</Typography>
                <Typography className="homeNinePart-typo-dr-junior">{t('promotingGreenMedicine')}</Typography>
            </Box>
            <CardMedia
                className="homeNinePart-image"
                component="img"
                image={administrators}
                alt="My Image"
            />
        </Box>
    )
}