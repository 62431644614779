import { Button, Dialog, DialogContent, DialogActions, Alert, Typography } from '@mui/material';
import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Iconify from '../Iconify';
import axios from 'axios';

export const DeleteOne = ({offset, id, files, setFiles, setOpenDelete, openDelete, setMedicalFilesTotalCount, setMedicalFilesFilteredCount }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const handledelete = async () => {
        setLoading(true);
        setErrMsg(null);
        try {
            const response = await axios.delete(`medical-file/${id}`);
            if (response.status === 200 || response.status === 201) {
                if (files && setFiles) {
                    // Filter out the deleted file from the list
                    const newFiles = files.filter((file) => file.id !== id);
    
                    // Adjust the offset
                    const adjustedOffset = Math.max(offset - 10, 0) || 0;
    
                    // Create a new array to hold the updated files
                    const updatedFiles = [];
    
                    // Update the fetched records in their correct positions
                    newFiles.forEach((file, index) => {
                        updatedFiles[adjustedOffset + index] = file;
                    });
    
                    // Update the state with the new files array
                    setFiles(updatedFiles);
                    setMedicalFilesTotalCount((prev) => prev - 1);
                    setMedicalFilesFilteredCount((prev) => prev - 1);
                }
                // Navigate back to the medical files dashboard
                navigate('/dashboard/medicalFiles', { state: { Tab: '1' } });
                setOpenDelete(false);
            }
        } catch (error) {
            console.error('Error deleting medical file:', error);
            setErrMsg(error.message);
        }
        finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = useCallback(() => {
        setErrMsg(null);
        setOpenDelete(false);
    }, [setOpenDelete]);

    // Memoized dialog props to prevent unnecessary re-renders
    const dialogProps = useMemo(() => ({
        open: openDelete,
        keepMounted: true,
        onClose: handleCloseDialog,
    }), [openDelete, handleCloseDialog]);

    return (
        <Dialog {...dialogProps} >
            <DialogContent className='imeah_dialog_content'>
                <Iconify icon='ic:round-delete' className='imeah_dialog_icon' />
                <Typography className='imeah_dialog_title'>{errMsg ? t("canNotRemove") : t('deleteMedicalFileDialogTitle')}</Typography>
                {errMsg && <Alert severity="error">{errMsg}</Alert>}
            </DialogContent>
            {errMsg ?
                <DialogActions>
                    <Button fullWidth className='buttonOutlined' onClick={handleCloseDialog}>{t("ok")}</Button>
                </DialogActions>
                :
                <DialogActions>
                    <Button fullWidth className='buttonOutlined' onClick={handleCloseDialog}>{t('cancel')}</Button>
                    <LoadingButton fullWidth loading={loading} className='buttonFilled' onClick={handledelete}>{t('dlt')}</LoadingButton>
                </DialogActions>
            }
        </Dialog>
    );
};