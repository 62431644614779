import React from 'react'
import { Box, Typography, CardMedia } from '@mui/material'
import '../../css/homePage/homeFourthPart.css'
import Illustration from '../../images/illustration 1.svg'
import { useTranslation } from "react-i18next";

export const HomeFourthPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeFourthPart-main-box'>
            <Box className='homeFourthPart-avec-ehealth-box'>
                <Typography className='homeFourthPart-avec-typo'>
                    {t('with')}
                </Typography>
                <Typography className='homeFourthPart-ehealth-typo'>
                    ehealth pro
                </Typography>
            </Box>
            <Typography className='homeFourthPart-benifit-typo'>{t('benifitFromSimpleManagement')}</Typography>
            <CardMedia
                className="homeFourthPart-image"
                component="img"
                image={Illustration}
                alt="My Image"
            />
        </Box>
    )
}
