import { InfoSwitch } from '../../../utils/infoSwitch';
import { ItemSwitch } from '../../../utils/itemSwitch';
import React, { useMemo, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../../css/medicalFiles/MedicalFileFields.css';

const MedicalFileFields = ({
    data,
    setdata,
    currentSection,
    Documents,
    setDocuments,
    allowedActions,
    handleDeleteFieldOpen,
    currentInstitution,
    handleRemoveRedDotReceiver,
    handleUpdateOneFieldData,
    debouncedHandleUpdateOneFieldData,
    handleUpdateOneListFieldData,
    debouncedHandleUpdateOneListFieldData,
}) => {

    const { t } = useTranslation();

    // Filter additional information for the current section
    const filteredInformation = useMemo(() =>
        data?.additional_information?.filter((item) => item.section === currentSection) || [],
        [data?.additional_information, currentSection]
    );

    // Determine if a field is enabled for editing
    const isFieldEnabled = useCallback((info) => (

        (currentSection === 'Complementary_Information123' &&
            (allowedActions.canEditPrivateMedicalFile || allowedActions.canEditSharedMedicalFile) &&
            (info.createdBy === currentInstitution.id || info?.createdBy === undefined)) ||
        (currentSection !== 'Complementary_Information123' && allowedActions.canEditPrivateMedicalFile)

    ), [currentSection, allowedActions.canEditPrivateMedicalFile, allowedActions.canEditSharedMedicalFile, currentInstitution.id]);

    // Helper to check if a list item is non-empty
    const isNonEmptyItem = useCallback((item) => {
        const enabledField = allowedActions?.canEditPrivateMedicalFile;
        const isEmptyField = !item.ItemValue || (Array.isArray(item?.ItemValue) && item?.ItemValue?.length === 0);
        const hasDocument = Documents?.some((doc) => doc?.fieldId === item.id);

        return (
            enabledField ||
            (item.ItemType !== 'file' && !isEmptyField) ||
            (item.ItemType === 'file' && (item.ItemValue || hasDocument))
        );
    }, [allowedActions, Documents]);

    // Check if there are no fields in complemantary info section and display a placeholder message
    if (!filteredInformation.length && currentSection === 'Complementary_Information123') {
        return (
            <Box className='medicalFileFields_info_container'>
                <Typography className=' imeahSubTitle'>{t("complementaryInformationSectionTitle")}</Typography>
            </Box>
        );
    }

    return (
        <Box className='medicalFileFields_info_container'>
            {filteredInformation?.map((info, index) => {

                const enabledField = isFieldEnabled(info);
                const isEmptyField = !info.value || (Array.isArray(info.value) && info.value.length === 0);
                const hasDocument = Documents?.some(doc => doc.fieldId === info.id);

                // Skip rendering if the field should not be displayed
                if (
                    (info.type !== 'file' && !enabledField && isEmptyField) ||
                    (info.type === 'file' && !enabledField && isEmptyField && !hasDocument)
                ) {
                    return null;
                }

                // Render fields based on type
                if (info.type !== 'list') {
                    const FieldToRender = InfoSwitch(info);
                    return (
                        <Box key={info.id}>
                            <FieldToRender
                                key={info.id}
                                info={info}
                                data={data}
                                setdata={setdata}
                                currentSection={currentSection}
                                Documents={Documents}
                                setDocuments={setDocuments}
                                allowedActions={allowedActions}
                                handleDeleteFieldOpen={handleDeleteFieldOpen}
                                currentInstitution={currentInstitution}
                                handleRemoveRedDotReceiver={handleRemoveRedDotReceiver}
                                handleUpdateOneFieldData={handleUpdateOneFieldData}
                                debouncedHandleUpdateOneFieldData={debouncedHandleUpdateOneFieldData}
                            />
                            {/* {index < filteredInformation.length - 1 && <Divider className='medicalFileFields_field_divider' />} */}
                        </Box>
                    );
                }

                // Render list fields
                if (info.type === 'list' && Array.isArray(info.value) && info?.value?.length > 0) {

                    const nonEmptyItems = info.value.filter(isNonEmptyItem);

                    if (nonEmptyItems.length === 0) return null;

                    return (
                        <Box key={info.id}>
                            <Typography mb={'12px'} className='medicalFileFields_Field_label'>{info.label}</Typography>
                            <Box className='medicalFileFields_listField_container'>
                                {nonEmptyItems?.map((item) => {
                                    const ListItemToRender = ItemSwitch(item);
                                    return (
                                        <Box key={item.id}>
                                            <ListItemToRender
                                                key={item.id}
                                                info={info}
                                                item={item}
                                                data={data}
                                                setdata={setdata}
                                                currentSection={currentSection}
                                                Documents={Documents}
                                                setDocuments={setDocuments}
                                                allowedActions={allowedActions}
                                                handleUpdateOneListFieldData={handleUpdateOneListFieldData}
                                                debouncedHandleUpdateOneListFieldData={debouncedHandleUpdateOneListFieldData}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                            {/* Add a divider between fields, but not after the last field */}
                            {/* {index < filteredInformation.length - 1 && <Divider className="field-divider" />} */}
                        </Box>
                    );
                }

                return null;
            })}
        </Box>
    );
};

export default React.memo(MedicalFileFields);