import { Dialog, DialogContent, DialogTitle, Button, Typography, Box, TextField, RadioGroup, FormControlLabel, Radio, Alert } from '@mui/material'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import React from 'react'
import { useTranslation } from "react-i18next";
import '../../../../../css/CV/education.css'
import axios from 'axios';
import { useSelector } from 'react-redux';

export const AddWork = (props) => {
    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);
    const educationDialog = true
    const { setSection, work, setUserCvId } = props
    const [errMsg, setErrMsg] = useState("");

    const [newWork, setNewWork] = useState({
        Company: "",
        Post: "",
        Current: "no",
        Start_date: "",
        End_date: "",
        Description: ""
    });

    const useStyles = makeStyles((theme) => ({
        notchedOutline: {
            borderWidth: "2px",
            borderColor: "#17536B !important",
        },
    }));
    const classes = useStyles();

    const handleChangeInput = (id, event) => {
        const updatedWork = { ...newWork };
        updatedWork[id] = event.target.value;

        if (id === "End_date" || id === "Start_date") {
            const startDate = new Date(updatedWork.Start_date);
            const endDate = new Date(updatedWork.End_date);

            // Compare only the year, month, and day parts of the dates
            const isSameDay = startDate.getUTCFullYear() === endDate.getUTCFullYear() &&
                startDate.getUTCMonth() === endDate.getUTCMonth() &&
                startDate.getUTCDate() === endDate.getUTCDate();

            if (endDate < startDate || isSameDay) {
                setErrMsg(`${t('errToDateAfterFromDate')}`);
                updatedWork.End_date = "";
            } else {
                setErrMsg("");
            }
        }

        setNewWork(updatedWork);
    };

    const addWork = () => {
        work.push(newWork)
        const data = {
            workExperience: work,
            userId: userData.id
        }
        axios.post(`user-cv`, data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSection("")
                    setUserCvId(userData.id)
                }
            })
    }


    return (
        <Dialog fullWidth open={educationDialog} >
            <DialogTitle className="page-title">{t('addWork')}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogContent className='education-dialog-content'>
                <Box>
                    <Typography className='education-dialog-typo'>{t('company')}*</Typography>
                    <TextField
                        id="Company"
                        name="Company"
                        type="text"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        fullWidth
                        onChange={event => handleChangeInput("Company", event)}
                    />
                </Box>
                <Box>
                    <Typography className='education-dialog-typo'>{t('post')}*</Typography>
                    <TextField
                        id="Post"
                        name="Post"
                        type="text"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        fullWidth
                        onChange={event => handleChangeInput("Post", event)}
                    />
                </Box>
                <Box className='add-education-current-post-box'>
                    <Typography className='education-dialog-typo'>{t('currentWork')} ?</Typography>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={newWork.Current}
                        name="radio-buttons-group"
                        row
                        onChange={event => handleChangeInput("Current", event)}
                    >
                        <FormControlLabel value="no" control={<Radio />} label={t('no')} />
                        <FormControlLabel value="yes" control={<Radio />} label={t('yes')} />
                    </RadioGroup>
                </Box>
                <Box className="education-date-box">
                    <Box className="education-date-field">
                        <TextField
                            sx={{ marginTop: '8px' }}
                            id="Start_date"
                            name="Start_date"
                            type="date"
                            label={t('startDate')}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            defaultValue=""
                            variant="outlined"
                            onChange={event => handleChangeInput("Start_date", event)}
                        />
                    </Box>
                    {newWork.Current === "no" ?
                        <Box className="education-date-field">
                            <TextField
                                id="End_date"
                                name="End_date"
                                type="date"
                                label={t('endDate')}
                                margin="dense"
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                defaultValue=""
                                variant="outlined"
                                onChange={event => handleChangeInput("End_date", event)}
                            />
                        </Box> : null}
                </Box>
                <Box>
                    <Typography className='education-dialog-typo'>{t('description')}*</Typography>
                    <TextField
                        id="Description"
                        name="Description"
                        multiline
                        rows={4}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        fullWidth
                        onChange={event => handleChangeInput("Description", event)}
                    />
                </Box>

            </DialogContent>
            <DialogActions className="addInstitution-actions-box">
                <Button className="new-button" onClick={() => { setSection("") }}>{t('cancel')}</Button>
                <Button className="new-button" onClick={addWork} disabled={newWork.Company === "" || newWork.Post === "" || newWork.Start_date === "" || (newWork.End_date === "" && newWork.Current === 'no') || newWork.Description === ""}>{t('add')}</Button>
            </DialogActions>
        </Dialog>
    )
}