import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Grid, LinearProgress, useMediaQuery } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StyledTableRow } from '../../../utils/StyledTableRow';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../Scrollbar';
import Label from '../../Label';
import Page from '../../Page';
import React from 'react';
import '../../../css/templates/MedicalFileTemplatesList.css';

export const MedicalFileTemplatesList = ({ loading, templates, fetchTemplates, hasMore }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Page title={t("sideBar-Medical Files Templates")}>

      {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} /> :

        <Scrollbar>
          <InfiniteScroll
            dataLength={templates.length}
            next={fetchTemplates}
            hasMore={hasMore}
            loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
          >

            {isDesktop &&
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='template-list-table-titles'>{t('templateName')}</TableCell>
                    <TableCell className='template-list-table-titles'>{t('specialityExpertise')}</TableCell>
                    <TableCell className='template-list-table-titles'>{t('institution')}</TableCell>
                    <TableCell className='template-list-table-titles'>{t('type')}</TableCell>
                    <TableCell className='template-list-table-titles'>{t('Status')}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {templates && templates.map((template) => (
                    <StyledTableRow key={template.id} onClick={() => navigate(`details/${template.id}`)}>

                      <TableCell>{template.name}</TableCell>
                      <TableCell>
                        {template.specialityId !== "NoSpeciality" ? template.speciality?.specialityLabel[0].label : template.expertiseID !== "NoExpertise" ? template.expertise?.expertiseLabel[0].label : t('none')}
                      </TableCell>
                      <TableCell>{template.institution?.name}</TableCell>
                      <TableCell>{t(template.type)}</TableCell>
                      <TableCell>
                        {template.is_active ?
                          <Label sx={{ backgroundColor: '#3ce641', color: 'black' }}  > {t('ActiveStatus')}</Label> :
                          <Label sx={{ backgroundColor: 'orange', color: 'black' }} > {t('InActiveStatus')}</Label>
                        }
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            }

            {!isDesktop &&
              <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'}>
                {templates && templates.map((template) => (
                  <Grid item xs={12} sm={4} key={template.id} onClick={() => navigate(`details/${template.id}`)}>
                    <Box className="template-list-responsive-card-details">
                      <Typography className='template-list-responsive-card-data'>{template.name}</Typography>
                      <Typography className='template-list-responsive-card-data'>{template.institution?.name}</Typography>
                      <Typography className='template-list-responsive-card-data'>{t(template.type)}</Typography>
                      <Typography className='template-list-responsive-card-data'>
                        {template.is_active ?
                          <Label sx={{ backgroundColor: '#3ce641', color: 'black' }}> {t('ActiveStatus')}</Label> :
                          <Label sx={{ backgroundColor: 'orange', color: 'black' }}> {t('InActiveStatus')}</Label>
                        }
                      </Typography>

                    </Box>
                  </Grid>
                ))}
              </Grid>
            }
          </InfiniteScroll >
        </Scrollbar >
      }

    </Page >
  );
}