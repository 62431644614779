import { Card, CardContent, Typography, TextField, Box, Button, IconButton, InputAdornment, CircularProgress, Alert } from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { LanguageSwitcher } from '../components/NoLoginLanguageSwitcher';
import { PasswordStrengthMeter } from '../utils/PasswordStrengthMeter';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import { ImeahPhoneInput } from '../utils/imeah_phone_input';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../images/logo_e_health_pro.svg';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';
import { LoadingButton } from '@mui/lab';
import Page from "../components/Page";
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import '../css/User-css/newUserPassword.css';

export const NewUserPassword = () => {

  const { accessCodeId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [language, setLanguage] = useState(navigator.language.startsWith('en') ? 'en' : 'fr');
  const [existingUser, setExistingUser] = useState(false);
  const [pageIsLoading, setPageisLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [invitationData, setInvitationData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setPageisLoading(true);
      try {
        const [userExistenceResponse, invitationResponse] = await Promise.all([
          axios.get(`invitation-access-code/checkUserExistence/${accessCodeId}`),
          axios.get(`invitation-access-code/findInvitationViaAccessCode/${accessCodeId}`, { params: { local_code: language } }),
        ]);

        if (userExistenceResponse.status === 200 || userExistenceResponse.status === 201) {
          setExistingUser(userExistenceResponse.data);
        }

        if (invitationResponse.status === 200 || invitationResponse.status === 201) {
          setInvitationData(invitationResponse.data);
        }
      } catch (err) {
        setError(err.message);
        CustomErrorLogger(err, 'Error fetching access code data for new user password');
      } finally {
        setPageisLoading(false);
      }
    };

    fetchData();
  }, [accessCodeId, language]);

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      firstname: Yup.string().trim().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, t('firstNameOnlyLetters')).required(t('firstNameIsRequired')),
      lastname: Yup.string().trim().matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, t('lastNameOnlyLetters')).required(t('lastNameIsRequired')),
      phone: Yup.string().trim().matches(/^\+?[0-9]+$/, t('PhoneNumberOnlyDigits')).optional(),
      password: Yup.string()
        .trim()
        .min(12, t('passwordMinLength'))
        .matches(/[a-z]/, t('passwordLowercase'))
        .matches(/[A-Z]/, t('passwordUppercase'))
        .matches(/[0-9]/, t('passwordNumber'))
        .matches(/[!@#$%^&*.]/, t('passwordSpecialChar'))
        .required(t('passwordRequired')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], t('passwordsMustMatch'))
        .required(t('confirmPasswordRequired')),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async () => {
      handleAcceptInvitation();
    },
  });

  const { handleSubmit, values, setFieldValue, handleChange, handleBlur, touched, errors, isValid } = formik;

  const handleLangUpdate = useCallback((langData) => setLanguage(langData), []);

  const handleAcceptInvitation = useCallback(() => {

    const bodyData = {
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.phone,
      password: values.password,
      local_code: language,
    }

    setButtonLoading(true);
    axios.patch(`invitation-access-code/AcceptInvitationViaEmail/${accessCodeId}`, bodyData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          navigate('/login');
        }
      })
      .catch((error) => {
        setError(error.message);
        CustomErrorLogger(error, 'Error accepting invitation for new user by email');
      })
      .finally(() => {
        setButtonLoading(false);
      });
  }, [values.firstname, values.lastname, values.phone, values.password, language, accessCodeId, navigate]);

  const refuseInvitation = async () => {
    try {
      const response = await axios.patch(`invitation-access-code/refuseInvitationViaEmail/${accessCodeId}`, { local_code: language });
      if (response.status === 200 || response.status === 201) {
        window.close();
        alert(t('InvitationRefusedAlertMessage'));
      }
    } catch (error) {
      CustomErrorLogger(error, 'Error refusing invitation for new user by email');
      setError(error.message);
    }
  };

  const handleCardHeader = useMemo(() => (
    <Box display='flex' justifyContent='space-between'>
      <Box sx={{ width: '200px' }} component="img" src={logo} alt="Imeah Logo" />
      <LanguageSwitcher handleLangUpdate={handleLangUpdate} />
    </Box>
  ), [handleLangUpdate]);

  return (
    <Page title={t("Accept_Invitation_Page_title")} className='NUP_container_pass'>

      {pageIsLoading ?
        <CircularProgress color="inherit" size="5rem" /> :
        <Card className='NUP_card_pass' >
          <CardContent>

            {existingUser &&
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                {handleCardHeader}
                <Typography color={'#17536B'} variant='subtitle1'>{t('You_are_invited_to_join')} {invitationData.invitation?.institution?.name} {t('as')} {invitationData.invitation.role?.roleLabel[0]?.label}</Typography>
                <Typography color={'#949494'} variant="subtitle2">{t("invitationExistingUserDialogContent")}</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
                  <Button className='buttonOutlined' onClick={refuseInvitation}>{t('Refuse_Invitation')}</Button>
                  <LoadingButton onClick={handleAcceptInvitation} loading={buttonLoading} fullWidth className='buttonFilled'>{t('Accept_Invitation')}</LoadingButton>
                </Box>
              </Box>
            }

            {!existingUser &&
              <form onSubmit={handleSubmit} className="NUP_form">
                {handleCardHeader}
                <Typography color={'#17536B'} variant="subtitle1">{t('setPersonalData')}</Typography>
                <Typography color={'#949494'} variant='subtitle2'>{t('creatOwnPassSubtitle')}</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <TextField
                  margin="dense"
                  id="firstname"
                  name="firstname"
                  className='customTextField'
                  label={t("firstname")}
                  type="text"
                  fullWidth
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstname && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                />
                <TextField
                  margin="dense"
                  id="lastname"
                  name="lastname"
                  className='customTextField'
                  label={t("lastname")}
                  type="text"
                  fullWidth
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastname && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                />
                <ImeahPhoneInput
                  required={false}
                  name="phone"
                  value={values.phone}
                  label={t('Enter_phone_number') + ' (' + t('optional') + ')'}
                  onChange={(value) => setFieldValue('phone', value)}
                />
                <TextField
                  id="password"
                  name="password"
                  label={t('password')}
                  type={showNewPassword ? "text" : "password"}
                  className='customTextField'
                  fullWidth
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowNewPassword((show) => !show)} edge="end">
                          <Iconify
                            icon={
                              showNewPassword
                                ? "eva:eye-fill"
                                : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <PasswordStrengthMeter newPassword={values.password} />

                <TextField
                  id="ForgotPassConfirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  label={t('confirmPassword')}
                  className="customTextField"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword((show) => !show)} edge="end">
                          <Iconify
                            icon={
                              showConfirmPassword
                                ? "eva:eye-fill"
                                : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton fullWidth loading={buttonLoading} className='NUP_button_valider' type="submit" disabled={!isValid || buttonLoading} >{t("Create")}</LoadingButton>
              </form>
            }
          </CardContent>
        </Card>
      }
    </Page>
  );
}