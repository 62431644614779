import { Box, Typography, CircularProgress, IconButton, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import Iconify from '../../Iconify';
import axios from 'axios';
import '../../../css/medicalFiles/fieldType/fieldSelectInList.css'

const filter = createFilterOptions();

export const FieldSelectInList = ({ allowedActions, info, item, handleUpdateOneListFieldData }) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false); // Open the dropdown
    const [options, setOptions] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [value, setValue] = useState(item.ItemValue || '');
    const [inputValue, setInputValue] = useState('');

    // Fetch options from the API
    const fetchOneSelectList = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await axios.get(`select-list/${item.listItemListId}`);
            const options = response?.data?.select_list_values?.map(value => value?.select_list_values_label[0]?.label) || [];
            setOptions(Array.isArray(options) ? options : []);
        } catch (err) {
            console.log(err);
        } finally {
            setIsFetching(false);
        }
    }, [item.listItemListId]);

    const handleRemove = () => {
        setValue('');
        handleUpdateOneListFieldData(info.id, item.id, '')
    }

    const handleAutocompleteChange = (event, newValue) => {
        let updatedValue;

        if (newValue && newValue.inputValue) {
            updatedValue = newValue.inputValue;
        } else {
            updatedValue = newValue;
        }

        setValue(updatedValue);
        handleUpdateOneListFieldData(info.id, item.id, updatedValue);
        setInputValue('');
    };

    const customFilterOptions = (options = [], params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        if (inputValue && !options?.includes(inputValue)) {
            filtered.push({
                inputValue,
                title: `${t('add')} "${inputValue}"`,
            });
        }
        return filtered;
    };

    // const renderOption = useCallback((props, option) => (
    //     <li {...props} style={{ color: option.inputValue ? '#16536B' : 'inherit' }}>
    //         <Typography>{option.title || option}</Typography>
    //     </li>
    // ), []);

    const renderOption = useCallback((props, option, { index }) => {
        if (index === 0) {
            return (
                <>
                    {/* Helper text as a non-interactive list item */}
                    <li>
                        <Box className='fieldSelect_helper_text_box'>
                            <Iconify icon="akar-icons:info" />
                            <Typography className="imeahCaption"> {t("currentOptionAvailableAreOnlyEnglish")}</Typography>
                        </Box>
                    </li>
                    {/* Render the actual option */}
                    <li {...props} style={{ color: option.inputValue ? '#16536B' : 'inherit' }}>
                        <Typography>{option.title || option}</Typography>
                    </li>
                </>
            );
        }

        // Default rendering for other options
        return (
            <li {...props} style={{ color: option.inputValue ? '#16536B' : 'inherit' }}>
                <Typography>{option.title || option}</Typography>
            </li>
        );
    }, [t]);

    const renderInput = useCallback((params) => (
        <TextField
            {...params}
            className='customTextField'
            placeholder={t('selectOptionPlaceHolder')}
            InputProps={{
                ...params.InputProps,
                style: {
                    ...params.InputProps.style,
                    opacity: params.disabled ? 0.5 : 1,
                },
                endAdornment: (
                    isFetching ?
                        <CircularProgress size={20} /> :
                        <Iconify
                            onClick={() => setOpen(prev => !prev)}
                            icon='iconamoon:arrow-down-2-light'
                            className='fieldSelect_dropdown_icon'
                        />
                ),
            }}
        />
    ), [t, isFetching]);

    return (
        <Box className='fieldInSelect_container'>

            <Typography className='medicalFileFields_listField_label'>{item.ItemLabel}</Typography>

            {allowedActions?.canEditPrivateMedicalFile &&
                <Autocomplete
                    freeSolo
                    id={item.id}
                    open={open} // controlled open state
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={null}
                    disabled={!allowedActions?.canEditPrivateMedicalFile}
                    inputValue={inputValue}
                    onChange={handleAutocompleteChange}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    filterOptions={customFilterOptions}
                    onFocus={fetchOneSelectList}
                    options={options}
                    getOptionDisabled={(option) => value === option}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') return option;// Value selected with enter, right from the input
                        if (option.inputValue) return option.inputValue; // Add "abc" option created dynamically
                        return option; // Regular option
                    }}
                    renderOption={renderOption}
                    renderInput={renderInput}
                />
            }

            {value && (
                <Box className="imeah_ships_box">
                    <Typography variant="body1">{value}</Typography>
                    {allowedActions?.canEditPrivateMedicalFile &&
                        <IconButton onClick={handleRemove} className="imeah_ships_button">
                            <CloseIcon className="imeah_ships_icon" />
                        </IconButton>
                    }
                </Box>
            )}
        </Box>
    );
};