import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';

export const AddExpertise = ({ openAdd, setOpenAdd, setExpertises }) => {

    const { t } = useTranslation();

    const [englishName, setEnglishName] = useState('');
    const [frenchName, setFrenchName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const validationSchema = Yup.object().shape({
        englishName: Yup.string().trim().required(t('expertiseLableRequired')),
    });

    const handleAddClose = () => {
        setEnglishName('');
        setFrenchName('');
        setOpenAdd(false);
        setErrMsg(null)
    };

    const handleAddExpertise = async () => {

        setIsLoading(true);
        try {
            await validationSchema.validate({ englishName }, { abortEarly: false });

            const bodyData = {
                enLanguage: englishName.trim(),
                frLanguage: frenchName.trim(),
            };

            const response = await axios.post(`expertise`, bodyData);

            if (response.status === 200 || response.status === 201) {
                setExpertises((prevExpertises) => [...prevExpertises, response.data.expertiseLabel[0]]);
                setOpenAdd(false);
                setEnglishName('');
                setFrenchName('');
                setErrMsg('');
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                // If validation fails, set the error message
                setErrMsg(err?.errors?.join(` ${t('and')} `));
            } else {
                // If API call fails, set the error message
                console.error('Error adding expertise:', err);
                setErrMsg(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={openAdd} keepMounted aria-describedby="alert-dialog-slide-description">
            <DialogTitle className='page-title'>{t('AddNewExpertise')}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogContent>
                <Box id="alert-dialog-slide-description">
                    <TextField
                        margin="dense"
                        id="name"
                        name="name"
                        label={t('Eng')}
                        type="text"
                        fullWidth
                        required
                        variant="outlined"
                        value={englishName}
                        onChange={(e) => setEnglishName(e.target.value)}
                        error={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName)}
                        helperText={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName) && t('isRequired')}
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        name="name"
                        label={t('Fr')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={frenchName}
                        onChange={(e) => setFrenchName(e.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className='new-button' onClick={handleAddClose}>{t('cancel')}</Button>
                <LoadingButton loading={isLoading} className='new-button' onClick={handleAddExpertise}>{t('Create')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}