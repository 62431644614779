import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// Import route configurations
import { dashboardRoutes, publicRoutes } from './routesConfig';

// Lazy load layouts and error pages for code splitting
const LogoOnlyLayout = lazy(() => import('./layouts/LogoOnlyLayout'));
const DashboardLayout = lazy(() => import('./layouts/dashboard'));
const NotFound = lazy(() => import('./pages/Page404'));

// Simple PrivateRoute component for protecting authenticated routes.
const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  const isAuthenticated = !!token;
  return isAuthenticated ? children : <Navigate to="/home" />;
}

export default function Router() {

  const routes = [
    {
      path: '/dashboard',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: dashboardRoutes,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: publicRoutes,
    },
    {
      path: '*',
      element: <NotFound replace />,
    },
  ];

  return useRoutes(routes);
}