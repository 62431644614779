import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { useTranslation } from 'react-i18next';
import React from 'react';
import axios from 'axios';

export const DeleteTemplateField = ({ template, setTemplate, openDeleteField, setOpenDeleteField, FieldId }) => {

    const { t } = useTranslation();

    const handleRemoveFields = async () => {
        try {
            const data = { FieldId };
            const response = await axios.patch(`medical-file-templates/removeField/${template.id}`, data);

            if (response.status === 200 || response.status === 201) {
                setTemplate((prevData) => ({ ...prevData, additional_fields: response.data }));
                setOpenDeleteField(false);
            }
        } catch (err) {
            console.log(err, 'Error removing field from template');
            CustomErrorLogger(err, 'Error removing field from template');
        }
    };

    return (
        <Dialog open={openDeleteField} keepMounted onClose={() => setOpenDeleteField(false)}>
            <DialogTitle>{t('sureDeleteField')}</DialogTitle>
            <DialogActions>
                <Button className='new-button' fullWidth onClick={() => setOpenDeleteField(false)}>{t('cancel')}</Button>
                <Button className='new-button' fullWidth onClick={() => handleRemoveFields()}>{t('dlt')}</Button>
            </DialogActions>
        </Dialog>
    );
};
