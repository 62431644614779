import { Card, Table, Stack, LinearProgress, InputBase, Box, Divider, TableRow, TableHead, TableBody, TableCell, Typography, InputAdornment } from '@mui/material';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import { HasPermission } from "../utils/checkUserPermission";
import InfiniteScroll from 'react-infinite-scroll-component';
import { StyledTableRow } from '../utils/StyledTableRow';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../components/Scrollbar';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';
import { fDate } from '../utils/formatTime';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import axios from 'axios';
import "../css/Feedbacks.css";

export const Feedbacks = () => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const userData = useSelector(state => state.userData);
    //check user permissions
    const isAllowedEditContactReceiver = HasPermission('edit_feedback_receivers');

    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    //filtration states
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 1000);
    //pagination state
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 20;

    const fetchFeedbacks = useCallback(async () => {
        try {
            const currentOffset = offset.current;
            const response = await axios.get('contact-us', { params: { offset: currentOffset, limit, search: debouncedSearch } });
            setFeedbacks((prevFeedbacks) => currentOffset === 0 ? response.data : [...prevFeedbacks, ...response.data]);
            setLoading(false);
            offset.current = currentOffset + limit;
            hasMore.current = response.data.length === limit;
        } catch (err) {
            console.error(err, 'Error fetching feedbacks');
            CustomErrorLogger(err, 'Error fetching feedbacks in feedbacks main page');
            setLoading(false);
        }
    }, [limit, debouncedSearch]);

    useEffect(() => {
        fetchFeedbacks()
    }, [fetchFeedbacks]);

    const handleChangeSearch = (searchWord) => {
        offset.current = 0;
        hasMore.current = true;
        setSearch(searchWord);
    };

    const ActionButton = ({ icon, title, onClick }) => (
        <Box className='feedbacks-page-header-button' onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} className="feedbacks-page-title-icon" />
            <Typography>{title}</Typography>
        </Box>
    );

    return (
        <Page title={t("Feedbacks")} >
            <Stack className="feedbacks-page-header">
                <Box className="feedbacks-page-title-box">
                    <Box className="feedbacks-page-title-icon-box">
                        <Iconify className="feedbacks-page-title-icon" icon="bx:bx-message" />
                        <Typography className='feedbacks-page-title'>{t("Feedbacks")}</Typography>
                    </Box>
                    <InputBase
                        className='feedbacks-page-search-input'
                        id="outlined-password-input"
                        label={t('search')}
                        type="text"
                        placeholder={t('search')}
                        value={search || ""}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        endAdornment={
                            search && (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                                </InputAdornment>
                            )
                        }
                    />
                </Box>

                <Box className="feedbacks-page-action-box">
                    {isAllowedEditContactReceiver &&
                        <ActionButton icon="material-symbols:edit" title={t('editReceivers')} onClick={() => navigate('editReceiversList')} />
                    }
                </Box>

            </Stack>

            {loading ? <LinearProgress /> :
                <Card>
                    <Scrollbar>
                        <InfiniteScroll
                            dataLength={feedbacks.length}
                            next={fetchFeedbacks}
                            hasMore={hasMore.current}
                            loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'15%'} className='feedbackstable-titles'>{t('name')}</TableCell>
                                        <TableCell width={'15%'} className='feedbackstable-titles'>{t('email')}</TableCell>
                                        <TableCell width={'15%'} className='feedbackstable-titles'>{t('profession')}</TableCell>
                                        <TableCell width={'15%'} className='feedbackstable-titles'>{t('Institution_Organization')}</TableCell>
                                        <TableCell className='feedbackstable-titles'>{t('message')}</TableCell>
                                        <TableCell width={'15%'} className='feedbackstable-titles'>{t('createdAt')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {feedbacks && feedbacks.map((feedback) =>
                                        <StyledTableRow key={feedback.id}>
                                            <TableCell>{feedback.firstname} {feedback.lastname}</TableCell>
                                            <TableCell>{feedback.email}</TableCell>
                                            <TableCell>{feedback.profession}</TableCell>
                                            <TableCell>{feedback.organization}</TableCell>
                                            <TableCell>{feedback.message}</TableCell>
                                            <TableCell>{fDate(feedback.created_at, userData.local_code)}</TableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </Scrollbar>
                </Card>
            }
        </Page>
    );
};