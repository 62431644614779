/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Typography, Select, MenuItem } from '@mui/material';
import { HasPermission } from "../../utils/checkUserPermission";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import axios from 'axios';
import '../../css/dashboard/dashboardApp.css'

export const MedicalFileStatistics = () => {

    const { t } = useTranslation();
    const currentInstitution = useSelector(state => state.currentInstitution);

    const isAllowedReadAllMedicalFile = HasPermission('read_All_Medical_File');
    const isAllowedReadInstitutionMedicalFile = HasPermission('read_institution_Medical_File');
    const isAllowedReadMyMedicalFile = HasPermission('read_my_Medical_File');

    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [colors, setColors] = useState([]);
    const [targetFilesFilter, setTargetFilesFilter] = useState('');

    const handleFetchMedicalFileStatusCounts = async () => {
        try {
            const response = await axios.get(`dashboard/medicalFileStatusCounts`, { params: { targetFilesFilter } });
            if (response.status === 200 || response.status === 201) {
                const { series, labels, colors } = response.data;
                setSeries(series);
                setLabels(labels);
                setColors(colors);
            }
        } catch (error) {
            console.error('Error Fetch Medical File Status Counts :', error.message);
        }
    };

    useEffect(() => {
        setTargetFilesFilter(isAllowedReadAllMedicalFile ? 'all' : isAllowedReadInstitutionMedicalFile ? 'institution' : 'mine')
    }, [currentInstitution]);

    useEffect(() => {
        if (!currentInstitution || !targetFilesFilter) { return }; // Early return for unauthenticated users
        handleFetchMedicalFileStatusCounts();
    }, [currentInstitution, targetFilesFilter]);

    const chartOptions = {
        chart: {
            width: '100%',
            type: 'pie',
        },
        labels: labels,
        colors: colors,
        dataLabels: {
            enabled: false // Disable data % on chart
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <Card className='dashboard-pie-chart-box' key='ert'>
            <Typography variant="h6"> {t('Medical_File_Statistics_By_Status')}</Typography>

            <Select
                fullWidth
                id="setTargetFilesFilter"
                name="setTargetFilesFilter"
                value={targetFilesFilter}
                onChange={(e) => setTargetFilesFilter(e.target.value)}
            >
                {isAllowedReadAllMedicalFile && <MenuItem value="all">{t('All_Files')}</MenuItem>}
                {isAllowedReadInstitutionMedicalFile && <MenuItem value="institution">{t('my_institution_Files')}</MenuItem>}
                {isAllowedReadMyMedicalFile && <MenuItem value="mine">{t('my_files')}</MenuItem>}
            </Select>

            {series.length > 0 && <Chart options={chartOptions} series={series} type="pie" />}

        </Card>
    );
};