import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export const CloseMeetingDialoge = ({ meeting, setMeeting, MainMeeting, openCloseMeeting, setOpenCloseMeeting }) => {

    const { t } = useTranslation();

    const handleCloseMeeting = async () => {

        axios.patch(`meetings/closeOneMeeting/${meeting.id}`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setMeeting(prevMeeting => ({ ...prevMeeting, status: "InActive" }));
                    setOpenCloseMeeting(false)
                }
            })
            .catch((err) => { console.log(err) });
    }

    const hangleCloseShain = async () => {

        const bodyData = { closeFromDate: meeting.date };

        axios.patch(`meetings/closeManyMeeting/${MainMeeting.id}`, bodyData)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setMeeting(prevMeeting => ({ ...prevMeeting, status: "InActive" }));
                    setOpenCloseMeeting(false)
                }
            })
            .catch((err) => { console.log(err) });
    }

    return (
        <Dialog open={openCloseMeeting} keepMounted onClose={() => setOpenCloseMeeting(false)}>
            <DialogTitle>{t("Close_Meeting_dialog_Title")}</DialogTitle>
            <DialogContent>{meeting.type === 'Repetitive_Meeting' ? t("Close_Meeting_dialog_Desc") : t("Close_Meeting_dialog_Desc2")}</DialogContent>
            <DialogActions>
                <Button fullWidth className='new-button' onClick={() => setOpenCloseMeeting(false)}>{t('Cancel')}</Button>
                <Button fullWidth className='new-button' onClick={handleCloseMeeting}>{meeting.type === 'Repetitive_Meeting' ? t("Apply_one_Meeting") : t('agree')} </Button>
                {meeting.type === 'Repetitive_Meeting' && <Button fullWidth className='new-button' onClick={hangleCloseShain}>{t("Apply_Future_Meetings")}</Button>}
            </DialogActions>
        </Dialog>
    );
};

