import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useMemo, useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import axios from 'axios';

export const EditTemplateTabs = ({ template, setTemplate, currentSection, setCurrentSection, handleShowRemoveSectionOpen, handleEditSectionOpen, handleShowAddSectionOpen }) => {

    const handleChangeSectionSorting = useCallback((result) => {
        const { source, destination } = result;

        if (!destination || source.index === destination.index) return;

        const newSections = Array.from(template.sections);
        const [removed] = newSections.splice(result.source.index, 1);
        newSections.splice(result.destination.index, 0, removed);

        setTemplate((prevData) => ({ ...prevData, sections: newSections }));

        const bodyData = { newSections };

        axios.patch(`medical-file-templates/changeSectionSorting/${template.id}`, bodyData)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setTemplate((prevData) => ({ ...prevData, sections: response.data }));
                }
            })
            .catch((err) => {
                console.log(err, 'Error changing section sorting in template');
                CustomErrorLogger(err, 'Error changing section sorting in template');
            });

    }, [template.sections, template.id, setTemplate]);


    const tabs = useMemo(() => template.sections.map((section, index) => (
        <Draggable
            key={section.id}
            draggableId={`id-${section.id}`}
            index={index}
            disableInteractiveElementBlocking={true}
        >
            {provided => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='edit-temp-sections-part'
                    sx={{
                        borderBottom: currentSection === section.id ? '2px solid #17536B' : '',
                        backgroundColor: currentSection === section.id ? '#84E1E6' : '',
                        borderRadius: currentSection === section.id ? '5px' : '0px',
                    }}
                >
                    <Tab
                        label={section.value}
                        onClick={() => setCurrentSection(section.id)}
                        sx={{ textTransform: 'none', fontWeight: 'bold', color: '#17536B', minHeight: 'fit-content', }}
                    />

                    <HighlightOffIcon
                        className='edit-temp-sections-remove-icon'
                        onClick={() => handleShowRemoveSectionOpen(section.id)}
                    />

                    <EditIcon
                        className='edit-temp-sections-edit-icon'
                        onClick={() => handleEditSectionOpen(section)}
                    />
                </Box>
            )}
        </Draggable>

    )), [template.sections, currentSection, setCurrentSection, handleShowRemoveSectionOpen, handleEditSectionOpen]);

    return (
        <Box className='edit-onetemp-sections-box'>
            <DragDropContext onDragEnd={handleChangeSectionSorting}>
                <Droppable droppableId="sections" direction="horizontal">
                    {(provided) => (
                        <TabList
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            value={currentSection}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            sx={{ minHeight: 'fit-content', }}
                        >
                            {tabs}
                            {provided.placeholder}
                        </TabList>
                    )}
                </Droppable>
            </DragDropContext>
            <Box onClick={handleShowAddSectionOpen} className='edit-temp-sections-add-icon'>
                <AddCircleIcon data-testid="addSection" />
            </Box>
        </Box>
    );
};