import { Box, Typography, Stack, Divider, CircularProgress, Accordion, AccordionSummary, AccordionDetails, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DeleteCollaboration } from './DeleteCollaboration';
import { useNavigate, useParams } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import '../../css/collaboration/collaborationInfo.css';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../Iconify';
import Page from '../Page';
import axios from 'axios';

export const CollaborationInfo = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams();
    const userData = useSelector(state => state.userData);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);

    const [choosenSpecialities, setChoosenSpecialities] = useState([]);
    const [choosenExpertises, setChoosenExpertises] = useState([]);
    const [askingInstitution, setAskingInstitution] = useState('');
    const [providingInstitution, setProvidingInstitution] = useState('');
    const [collaborationData, setCollaborationData] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [canEditCollaboration, setCanEditCollaboration] = useState(false);
    const [canDeleteCollaboration, setCanDeleteCollaboration] = useState(false);
    const [collaborationUsers, setCollaborationUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedDrAccordion, setExpandedDrAccordion] = useState(false);
    const [specialityId, setSpecialityid] = useState('');
    const [expertiseId, setExpertiseId] = useState('');
    //pagination states
    const hasMore = useRef(true);
    const offset = useRef(0);
    const limit = 10;

    const handleFetchOneCollaboration = useCallback(async () => {
        try {
            const response = await axios.get(`collaboration-institution/${id}`);
            if (response.status === 200 || response.status === 201) {
                const { collaborationSpeciality, collaborationExpertise } = response.data;
                const specialitiesOptions = collaborationSpeciality.map((speciality) => ({ value: speciality.speciality.specialityLabel[0].specialityId, label: speciality.speciality.specialityLabel[0].label }));
                const expertisesOptions = collaborationExpertise.map((expertise) => ({ value: expertise.expertise.expertiseLabel[0].expertiseId, label: expertise.expertise.expertiseLabel[0].label }));

                setChoosenSpecialities(specialitiesOptions);
                setChoosenExpertises(expertisesOptions);
                setProvidingInstitution(response.data.providingInstitution);
                setAskingInstitution(response.data.askingInstitution)
                setLoading(false);
            }
        } catch (error) { console.log(error.message) }
    }, [id]);

    const getCollaborationActionPermissions = useCallback(async () => {
        try {
            const response = await axios.get(`collaboration-institution/getActionsPermissions/${id}`);
            if (response.status === 200 || response.status === 201) {
                setCanEditCollaboration(response.data.CanEditCollaboration);
                setCanDeleteCollaboration(response.data.CanDeleteCollaboration);
            }
        } catch (err) {
            console.error(err);
        }
    }, [id]);

    const fetchUsersDetails = useCallback(async () => {
        try {
            const response = await axios.get(`institutions-users/forCollaborationUsersDetails/${id}`, { params: { specialityId, expertiseId, limit, offsetDetails: offset.current } });
            setCollaborationUsers(prev => offset.current === 0 ? response.data : [...prev, ...response.data]);
            hasMore.current = response.data.length >= limit;
            offset.current += limit;
        } catch (error) {
            console.error(error);
        }
    }, [id, specialityId, expertiseId]);

    useEffect(() => {
        if (userData) {
            fetchUsersDetails();
        }
    }, [userData, fetchUsersDetails]);

    useEffect(() => {
        if (userData) {
            handleFetchOneCollaboration();
        }
    }, [userData, specialityId, expertiseId, handleFetchOneCollaboration]);

    useEffect(() => {
        if (userData) {
            getCollaborationActionPermissions();
        }
    }, [userData, getCollaborationActionPermissions]);

    const handleChnageSpeciality = (id) => {
        offset.current = 0;
        hasMore.current = true;
        setSpecialityid(specialityId === id ? '' : id);
        setExpertiseId('');
        setExpandedDrAccordion(true);
    }

    const handleChnageExpertise = (id) => {
        offset.current = 0;
        hasMore.current = true;
        setExpertiseId(expertiseId === id ? '' : id);
        setSpecialityid('');
        setExpandedDrAccordion(true);
    }

    const handleOpenActionMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Page title={t("collaborationDetails")} className="collaborationInfo-main-box" >
            {loading ? <CircularProgress className="collaborationInfo-loading" /> :
                <Box>
                    <Box className="collaborationInfo-main-page-header-box">
                        <ArrowBackIosIcon className='collaborationInfo-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                        <Stack className="collaborationInfo-main-page-header">
                            <Box className="collaborationInfo-page-title-box">
                                <Typography className='collaborationInfo-page-title'>{t("collaborationDetails")}</Typography>
                            </Box>

                            {isDesktop && <Box className='collaborationInfo-actions-box'>
                                {canEditCollaboration && <Box className='collaborationInfo-page-header-button' onClick={() => navigate(`/dashboard/collaborations/edit/${id}`)}>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Iconify icon="bytesize:edit" className="collaborationInfo-plus-icon" />
                                    <Typography className="collaborationInfo-button-title">{t('edit')}</Typography>
                                </Box>}

                                {canDeleteCollaboration && <Box className='onefile-btn-container' onClick={() => setOpenDelete(true)}>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Iconify icon="material-symbols:delete" className="collaborationInfo-plus-icon" />
                                    <Typography className="collaborationInfo-button-title">{t('dlt')}</Typography>
                                </Box>}
                            </Box>}

                            {!isDesktop &&
                                <IconButton
                                    className="main-page-threeDot-icon"
                                    id="userPageActionMenuIcom"
                                    aria-controls={openActionMenu ? 'long-menu' : undefined}
                                    aria-expanded={openActionMenu ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleOpenActionMenu}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            }

                            <Menu
                                id="collaborationePageActionMenu"
                                MenuListProps="collaborationPageActionMenuIcom"
                                open={openActionMenu}
                                anchorEl={anchorEl}
                                onClose={handleCloseActionMenu}
                                slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
                            >
                                {canEditCollaboration &&
                                    <MenuItem className='main-page-header-button' onClick={() => navigate(`/dashboard/collaborations/edit/${id}`)}>
                                        <Iconify icon="bytesize:edit" className="main-page-threeDot-icon" />
                                        <Typography>{t('edit')}</Typography>
                                    </MenuItem>}
                                {canDeleteCollaboration && < MenuItem className='main-page-header-button' onClick={() => setOpenDelete(true)}>
                                    <Iconify icon="material-symbols:delete" className="main-page-threeDot-icon" />
                                    <Typography>{t('dlt')} </Typography>
                                </MenuItem>}
                            </Menu>

                        </Stack>
                    </Box>

                    <Box className='collaborationInfo-institutionsData-box'>
                        <Box className='collaborationInfo-details-box'>
                            <Typography className="collaborationInfo-labels">{t('requerentInstitution')}</Typography>
                            <Typography className="collaborationInfo-values">{askingInstitution.name}</Typography>
                        </Box>

                        <Box className='collaborationInfo-details-box'>
                            <Typography className="collaborationInfo-labels">{t('requisInstitution')}</Typography>
                            <Typography className="collaborationInfo-values">{providingInstitution.name}</Typography>
                        </Box>
                    </Box>

                    <Box className='collaborationInfo-accordion-box'>
                        <Box className='collaborationInfo-accordion-sp-exp-box'>
                            {choosenSpecialities.length > 0 && <Accordion key="specialities" className='collaborationInfo-accordion-main'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content-specialities"
                                    id="panel1a-header-specialities"
                                    className="collaborationInfo-accordion-header"
                                >
                                    <Typography>{t('specialities')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <Box className="collaborationInfo-accordion-details">
                                        {choosenSpecialities && choosenSpecialities.map(({ value, label }) => (
                                            <Typography className={`collaborationInfo-accordion-values ${value === specialityId ? 'selected' : ''}`}
                                                onClick={() => handleChnageSpeciality(value)}>{label}</Typography>

                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>}

                            {choosenExpertises.length > 0 && <Accordion key="expertises" className='collaborationInfo-accordion-main'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content-expertises"
                                    id="panel1a-header-expertises"
                                    className="collaborationInfo-accordion-header"
                                >
                                    <Typography>{t('expertises')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className="collaborationInfo-accordion-details">
                                        {choosenExpertises && choosenExpertises.map(({ value, label }) => (
                                            <Typography className={`collaborationInfo-accordion-values ${value === expertiseId ? 'selected' : ''}`} onClick={() => handleChnageExpertise(value)}>{label}</Typography>

                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>}
                        </Box>

                        <Accordion key="Drs" className='collaborationInfo-accordion-drs' expanded={expandedDrAccordion} onClick={() => setExpandedDrAccordion(!expandedDrAccordion)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content-Drs"
                                id="panel1a-header-Drs"
                                className="collaborationInfo-accordion-header"
                            >
                                <Typography>{t('Drs')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className="collaborationInfo-accordion-details">
                                    <InfiniteScroll
                                        dataLength={collaborationUsers.length}
                                        next={fetchUsersDetails}
                                        hasMore={hasMore.current}
                                    >
                                        {collaborationUsers && collaborationUsers.map((institutionUser, index) => (
                                            <Box className="collaborationInfo-accordion-drs-values" key={index}>
                                                <Typography className='imeah_propre_name'>{institutionUser.user?.firstname} {institutionUser.user?.lastname}</Typography>
                                                <Typography className="collaborationInfo-accordion-drs-emails">({institutionUser.user?.email})</Typography>
                                            </Box>
                                        ))}
                                    </InfiniteScroll>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                </Box>}

            {openDelete &&
                <DeleteCollaboration
                    id={id}
                    openDelete={openDelete}
                    setOpenDelete={setOpenDelete}
                    collaborationData={collaborationData}
                    setCollaborationData={setCollaborationData}
                />
            }
        </Page>
    );
};