import { Dialog, DialogActions, DialogTitle, Button, Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import React, { useState } from 'react';
import axios from 'axios';

export const DeleteSpeciality = ({ id, openDelete, setOpenDelete, setSpecialities }) => {

    const { t } = useTranslation();
    const [errMsg, setErrMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handledelete = async () => {
        setIsLoading(true);
        try {
            const response = await axios.delete(`speciality/${id}`);
            if (response.status === 200 || response.status === 201) {
                setSpecialities((prevTypes) => prevTypes.filter((item) => item.specialityId !== id));
                setOpenDelete(false);
            }
        } catch (error) {
            setErrMsg(error.message || t('GenericErrorMessage')); // Handle generic errors
            console.error('Error deleting Speciality:', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
        setErrMsg(null)
    };

    return (
        <Dialog open={openDelete} keepMounted aria-describedby="alert-dialog-slide-description">
            {errMsg ? <DialogTitle>{t("canNotRemove")}</DialogTitle> : <DialogTitle>{t('sureDeleteSpeciality')}</DialogTitle>}
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogActions>
                {errMsg ?
                    <Button className="new-button" onClick={handleDeleteClose}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button className='new-button' onClick={handleDeleteClose}>{t('cancel')}</Button>
                        <LoadingButton loading={isLoading} className='new-button' onClick={handledelete}>{t('dlt')}</LoadingButton>
                    </Box>}
            </DialogActions>
        </Dialog >
    )
}