export const customInputStyles = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#17536B', // Default border color
      },
      '&:hover fieldset': {
        borderColor: '#17536B', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#17536B', // Border color when focused
      },
    },
    '& .MuiInputLabel-root': {
      color: '#17536B', // Default label color
    },
    '&:hover .MuiInputLabel-root': {
      color: '#17536B', // Label color on hover
    },
    '& .Mui-focused .MuiInputLabel-root': {
      color: '#17536B', // Label color when focused
    },
  };