import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import axios from 'axios';

export const UnshareFile = ({ data, setdata, openUnShare, setOpenUnShare }) => {

    const { t } = useTranslation();

    const handleUnShare = useCallback(async () => {
        try {
            const response = await axios.patch(`medical-file/UnShareMedicalFile/${data.id}`);
            if (response.status === 200 || response.status === 201) {
                const { shareStatus, status, newLog } = response.data;
                setdata((prevData) => ({ ...prevData, shareStatus, status, medical_Files_logs: [...prevData.medical_Files_logs, newLog] }));
                setOpenUnShare(false);
            }
        } catch (error) {
            console.error('Error unsharing medical file:', error);
        }
    }, [data.id, setOpenUnShare, setdata]);

    return (
        <Dialog open={openUnShare} keepMounted onClose={() => setOpenUnShare(false)}>
            <DialogTitle className="imeahSubTitle">{t('unshare')}</DialogTitle>
            <DialogContent className="imeahCaption">{t('sureUnshareUser')}</DialogContent>
            <DialogActions>
                <Button fullWidth className='buttonOutlined' onClick={() => setOpenUnShare(false)}>{t('cancel')}</Button>
                <Button fullWidth className='buttonFilled' onClick={handleUnShare}>{t('unshare')}</Button>
            </DialogActions>
        </Dialog>
    );
};