import { DashboardApp } from './pages/DashboardApp';
import { NewLoginForm } from './components/LoginForm/newLoginForm';
import NewRegisterForm from './components/SignupForm/newRegisterForm';
import { ViewAllNotification } from './components/notifications/viewAllNotification';
import { Home } from './pages/Home';
import LiveVideo from './components/meetings/liveVideo';
import { VersionLogs } from './components/versionLogs';
import { InstitutionsTypes } from './pages/InstitutionsTypes';
import { Specialities } from './pages/Specialities';
import { Expertises } from './pages/Expertises';
import { ManageUserCredentiels } from './components/Permissions/manageUserCredentiels';
import NotFound from './pages/Page404';
import { Navigate } from 'react-router-dom';

import SelectList from './pages/Select_list';
import { CreateList } from './components/selectList/createList';
import { EditList } from './components/selectList/editList';
import { ReadOneList } from './components/selectList/readOneList';

import User from './pages/User';
import UserProfile from './components/Users/userProfile/user-profile';
import { Editprofile } from './components/Users/userProfile/edit-user-profile';
import ChangePassword from './components/Users/changePassword';
import { AddNewUser } from './components/Users/addNewUser';
import { ForgotPassword } from './components/Users/forgotPassword';
import { UserPermissions } from './pages/UserPermissions';
import { NewUserPassword } from './pages/NewUserPassword';
import { UserResetPassword } from './pages/userResetPassword';
import { EmailSuccessfullySent } from './pages/emailSuccessfullySent';
import { PasswordSuccessfullyReset } from './pages/passwordSuccessfullyReset';

import { Feedbacks } from './pages/feedbacks';
import { EditReceiversList } from './components/feedbacks/editReceiversList';

import { PermissionGroups } from './pages/permissionGroups';
import { CreatePermissionGroup } from './components/Permissions/permissionGroups/createPermissionGroup';
import { EditPermissionGroup } from './components/Permissions/permissionGroups/editPermissionGroup';

import { EditPermission } from './components/Permissions/permission/editPermission';

import CollaborationInstitution from './pages/CollaborationInstitutions';
import { CollaborationInfo } from './components/Collaboration/CollaborationInfo';
import { AddCollaboration } from './components/Collaboration/AddCollaboration';
import { EditCollaboration } from './components/Collaboration/EditCollaboration';

import { ImportTemplate } from './components/medicalFileTemplates/importTemplate';
import { CreateTemplate } from './components/medicalFileTemplates/createTemplate';
import { EditTemplate } from './components/medicalFileTemplates/edit/editTemplate';
import { OneTemplate } from './components/medicalFileTemplates/read/readOneTemplate';
import { EditTemplateOwner } from './components/medicalFileTemplates/edit/editTemplateOwner';
import { CopyTemplate } from './components/medicalFileTemplates/copyTemplate';
import { Templates } from './pages/templates';

import { ReadOneInstitution } from './components/Institution/readOneInstitution';
import { AddInstitution } from './components/Institution/addInstitution';
import { EditInstitution } from './components/Institution/EditInstitution';
import Institutions from './pages/institutions';

import { MeetingsList } from './pages/MeetingsList';
import { MeetingDetails } from './components/meetings/MeetingDetails';
import { EditMeeting } from './components/meetings/EditMeeting';

import { CreateMedicalFile } from './components/medicalFile/create/createMedicalFile';
import { MedicalFiles } from './pages/MedicalFiles';
import { MedicalFileDetails } from './components/medicalFile/read/medicalFileDetails';
import { ImportMedicalFile } from './components/medicalFile/importFile';
import { MedicalFileByEmail } from './components/medicalFile/read/medicalFileByEmail';
import { CopyFile } from './components/medicalFile/copyFile'

import { Policies } from './pages/Policies';
import { AddPolicy } from './components/Policies/addPolicy';
import { EditPolicy } from './components/Policies/editPolicy';

import { Roles } from './pages/roles';
import { ReadRole } from './components/Roles/readRole';
import { EditRole } from './components/Roles/editRole';
import { AddRole } from './components/Roles/addRole';

// ✅ Dashboard Routes (Authenticated Users)
export const dashboardRoutes = [

    { path: 'roles', element: <Roles /> },
    { path: 'roles/add', element: <AddRole /> },
    { path: 'roles/read/:id', element: <ReadRole /> },
    { path: 'roles/edit/:id', element: <EditRole /> },

    { path: 'Policies', element: <Policies /> },
    { path: 'Policies/addPolicy', element: <AddPolicy /> },
    { path: 'Policies/editPolicy/:id', element: <EditPolicy /> },
    { path: 'medicalFiles', element: <MedicalFiles /> },
    { path: 'medicalFiles/:id', element: <MedicalFileDetails /> },
    { path: 'medicalFiles/copy/:id', element: <CopyFile /> },
    { path: 'medicalFiles/createFile', element: <CreateMedicalFile /> },
    { path: 'medicalFiles/import', element: <ImportMedicalFile /> },
    { path: 'medical_file_by_email/:id1/:id2', element: <MedicalFileByEmail /> },

    { path: 'Meetings', element: <MeetingsList /> },
    { path: 'Meetings/:id', element: <MeetingDetails /> },
    { path: 'Meetings/edit/:id', element: <EditMeeting /> },

    { path: 'hospitals', element: <Institutions /> },
    { path: 'hospitals/add', element: <AddInstitution /> },
    { path: 'hospitals/readOne/:id', element: <ReadOneInstitution /> },
    { path: 'hospitals/edit/:id', element: <EditInstitution /> },

    { path: 'medicalFilesTemplates/createTemplate', element: <CreateTemplate /> },
    { path: 'medicalFilesTemplates/importTemplate', element: <ImportTemplate /> },
    { path: 'medicalFilesTemplates/Copy/:id', element: <CopyTemplate /> },
    { path: 'medicalFilesTemplates/EditOwner/:id', element: <EditTemplateOwner /> },
    { path: 'medicalFilesTemplates/edit/:id', element: <EditTemplate /> },
    { path: 'medicalFilesTemplates/details/:id', element: <OneTemplate /> },
    { path: 'medicalFilesTemplates', element: <Templates /> },

    { path: 'collaborations', element: <CollaborationInstitution /> },
    { path: 'collaborations/add', element: <AddCollaboration /> },
    { path: 'collaborations/edit/:id', element: <EditCollaboration /> },
    { path: 'collaborations/details/:id', element: <CollaborationInfo /> },

    { path: 'permissionGroups/permissions/edit/:id', element: <EditPermission /> },

    { path: 'permissionGroups', element: <PermissionGroups /> },
    { path: 'permissionGroups/create', element: <CreatePermissionGroup /> },
    { path: 'permissionGroups/edit/:id', element: <EditPermissionGroup /> },

    { path: 'selectLists', element: <SelectList /> },
    { path: 'selectLists/add', element: <CreateList /> },
    { path: 'selectLists/read/:id', element: <ReadOneList /> },
    { path: 'selectLists/edit/:id', element: <EditList /> },

    { path: 'Feedbacks', element: <Feedbacks /> },
    { path: 'Feedbacks/editReceiversList', element: <EditReceiversList /> },

    { path: 'user', element: <User /> },
    { path: 'user/add', element: <AddNewUser /> },
    { path: 'userprofile', element: <UserProfile /> },
    { path: 'userprofile/edit', element: <Editprofile /> },
    { path: 'UserPermissions', element: <UserPermissions /> },

    { path: 'app', element: <DashboardApp /> },
    { path: 'specialities', element: <Specialities /> },
    { path: 'expertises', element: <Expertises /> },
    { path: 'hospitalsTypes', element: <InstitutionsTypes /> },
    { path: 'changePassword', element: <ChangePassword /> },
    { path: 'profile', element: <UserProfile /> },
    { path: 'allNotification', element: <ViewAllNotification /> },
    { path: 'credentielsSet/manageUserCredentiels', element: <ManageUserCredentiels /> },
    { path: 'versionLogs', element: <VersionLogs /> },
];

// ✅ Public Routes
export const publicRoutes = [
    { path: '/', element: <Navigate to="/dashboard/app" /> },
    { path: 'home', element: <Home /> },
    { path: 'live', element: <LiveVideo /> },
    { path: 'login', element: <NewLoginForm /> },
    { path: 'register', element: <NewRegisterForm /> },
    { path: 'email_successfully_sent', element: <EmailSuccessfullySent /> },
    { path: 'password_successfully_reset', element: <PasswordSuccessfullyReset /> },
    { path: 'newPassword/:accessCodeId', element: <NewUserPassword /> },
    { path: 'forgotPassword', element: <ForgotPassword /> },
    { path: 'resetPassword/:accessCodeId', element: <UserResetPassword /> },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <NotFound /> },
];