import { Dialog, DialogActions, DialogTitle, Button, Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';

export const DeleteCollaboration = ({ openDelete, setOpenDelete, collaborationData, setCollaborationData, id }) => {

    const { t } = useTranslation();
    const navigate = useNavigate()

    const [msg, setMsg] = useState('');

    const handledelete = (id) => {
        let newData = [];

        axios.delete(`collaboration-institution/${id}`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    newData = collaborationData.filter((item) => item.id !== id);
                    setCollaborationData(newData)
                    setOpenDelete(false);
                    navigate(-1)
                }
            })
            .catch((error) => { console.log(error); setMsg(error.message) });
    };

    return (
        <Dialog open={openDelete} keepMounted >
            {msg ? <DialogTitle>{t("canNotRemove")}</DialogTitle> : <DialogTitle>{t('sureDeleteCollaboration')}</DialogTitle>}
            {msg && <Alert severity="error">{msg}</Alert>}
            <DialogActions>
                {msg ?
                    <Button fullWidth className="new-button" onClick={() => setOpenDelete(false)}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button fullWidth className='new-button' onClick={() => setOpenDelete(false)}>{t('cancel')}</Button>
                        <Button fullWidth className='new-button' onClick={() => handledelete(id)}>{t('dlt')}</Button>
                    </Box>
                }
            </DialogActions>
        </Dialog>
    )
}
