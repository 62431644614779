import React from 'react';
import Iconify from '../components/Iconify';
import { Box, Typography } from '@mui/material';

const SelectedFilterItem = ({ label, onRemove }) => (
    <Box className="imeah_ships_box">
        <Typography variant="body2" className='imeah_propre_name'>{label}</Typography>
        <Iconify icon="material-symbols:close-rounded" sx={{ fontSize: '20px', cursor: 'pointer' }} onClick={onRemove} />
    </Box>
);

export const ImeahSelectedFilters = ({ selectedSpeciality, handleChangeSpeciality, selectedDoctor, handleChangeDoctor,
     selectedSharedDoctor, handleChangeSharedDoctor, selectedStatus, handleChangeStatus, searchName, handleChangeSearchName }) => {
    return (
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
            {searchName && (
                <SelectedFilterItem label={searchName} onRemove={() => handleChangeSearchName('')} />
            )}
            {selectedSpeciality && (
                <SelectedFilterItem label={selectedSpeciality.label} onRemove={() => handleChangeSpeciality(null)} />
            )}
            {selectedDoctor && (
                <SelectedFilterItem label={selectedDoctor.label} onRemove={() => handleChangeDoctor(null)} />
            )}
            {selectedSharedDoctor && (
                <SelectedFilterItem label={selectedSharedDoctor.label} onRemove={() => handleChangeSharedDoctor(null)} />
            )}
            {selectedStatus && (
                <SelectedFilterItem label={selectedStatus.label} onRemove={() => handleChangeStatus(null)} />
            )}
        </Box>
    );
};
