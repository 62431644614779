import { Divider, MenuItem, IconButton, Menu, Box, LinearProgress, Stack, Typography, Alert, Grid } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { HasPermission } from "../../utils/checkUserPermission.js";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useResponsive from '../../hooks/useResponsive';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import DeleteList from "./deleteList";
import Iconify from "../Iconify.js";
import Page from "../Page";
import axios from "axios";

const ActionButton = React.memo(({ icon, title, onClick }) => (
  <Box className='onefile-btn-container' onClick={onClick}>
    <Divider orientation="vertical" variant="middle" flexItem className='mdf-icon-button' />
    <Iconify icon={icon} className="onfile-headerBtn-icon" />
    <Typography className="onefile-btn-title">{title}</Typography>
  </Box>
));

const ActionMenuButton = React.memo(({ icon, title, onClick }) => (
  <MenuItem className='onefile-btn-container' onClick={onClick}>
    <Iconify icon={icon} className="onfile-headerBtn-icon" />
    <Typography className="onefile-btn-title">{title}</Typography>
  </MenuItem>
));

export const ReadOneList = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const currentInstitution = useSelector(state => state.currentInstitution);
  const isDesktop = useResponsive('up', 'lg');

  const isAllowedEditAllSelectList = HasPermission('Edit_AllSelectList');
  const isAllowedEditInstitutionSelectList = HasPermission('Edit_InstitutionSelectList');
  const isAllowedDeleteAllSelectList = HasPermission('Delete_AllSelectList');
  const isAllowedDeleteInstitutionSelectList = HasPermission('Delete_InstitutionSelectList');

  const [selectListData, setSelectListData] = useState({
    englishName: '',
    frenchName: '',
    institutionId: '',
    type: '',
    values: [],
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openActionMenu = Boolean(anchorEl);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchOneSelectList = useCallback(async () => {
    try {
      if (!currentInstitution) return;

      const response = await axios.get(`select-list/findOneForEditSelectList/${id}`);
      if (response.status === 200 || response.status === 201) {

        setSelectListData({
          englishName: response.data?.select_list_label?.find((record) => record.language === "en")?.label || 'N/A',
          frenchName: response.data?.select_list_label?.find((record) => record.language === "fr")?.label || 'N/A',
          institutionId: response.data.institutionId,
          type: response.data.select_type,
          values: response.data.select_list_values.map(value => {
            const enLabel = value?.select_list_values_label?.find(val => val.language === 'en')?.label || 'N/A';
            const frLabel = value?.select_list_values_label?.find(val => val.language === 'fr')?.label || 'N/A';

            return { id: value.id, enValue: enLabel, frValue: frLabel };
          }),
        });

        setLoading(false);
      }
    } catch (err) {
      setErrMsg(err.message);
      console.log(err);
    }
  }, [currentInstitution, id]);

  useEffect(() => {
    fetchOneSelectList();
  }, [fetchOneSelectList]);

  const handleActionMenu = useCallback((event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  }, []);

  // Menu component
  const renderMenu = useMemo(() => (
    <Menu
      id="role-action-menu"
      open={openActionMenu}
      anchorEl={anchorEl}
      onClose={handleActionMenu}
      onClick={handleActionMenu}
      slotProps={{ paper: { style: { width: "30ch", backgroundColor: "#453f3f" } } }}
    >
      {(isAllowedEditAllSelectList || (isAllowedEditInstitutionSelectList && currentInstitution?.institution?.id === selectListData.institutionId)) && (
        <ActionMenuButton
          icon="bytesize:edit"
          title={t('edit')}
          onClick={() => navigate(`/dashboard/selectLists/edit/${id}`)}
        />
      )}
      {(isAllowedDeleteAllSelectList || (isAllowedDeleteInstitutionSelectList && currentInstitution?.institution?.id === selectListData.institutionId)) && (
        <ActionMenuButton
          icon="material-symbols:delete"
          title={t('delete')}
          onClick={() => setOpenDelete(true)}
        />
      )}
    </Menu>
  ), [openActionMenu, anchorEl, handleActionMenu, isAllowedEditAllSelectList, isAllowedEditInstitutionSelectList, currentInstitution?.institution?.id, selectListData.institutionId, t, isAllowedDeleteAllSelectList, isAllowedDeleteInstitutionSelectList, navigate, id]);

  return (
    <Page title={t("Read_Select_list_Page_Title")}>

      <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <ArrowBackIosIcon className='editPolicy-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
        <Stack className="editPolicy-main-page-header">
          <Typography className='editPolicy-page-title'>{t("Read_Select_list_Page_Title")}</Typography>
          <Box>
            {isDesktop ? (
              <Box className='onefile-action-button-box'>
                {(isAllowedEditAllSelectList || (isAllowedEditInstitutionSelectList && currentInstitution?.institution?.id === selectListData.institutionId)) && (
                  <ActionButton icon="bytesize:edit" title={t('edit')} onClick={() => navigate(`/dashboard/selectLists/edit/${id}`)} />
                )}
                {(isAllowedDeleteAllSelectList || (isAllowedDeleteInstitutionSelectList && currentInstitution?.institution?.id === selectListData.institutionId)) && (
                  <ActionButton icon="material-symbols:delete" title={t('dlt')} onClick={() => setOpenDelete(true)} />
                )}
              </Box>
            ) : (
              <IconButton sx={{ color: 'white', padding: 0 }} onClick={handleActionMenu}>
                <MoreHorizIcon />
              </IconButton>
            )}
          </Box>
          {renderMenu}
        </Stack>
      </Box>

      {loading ?
        <LinearProgress />
        :
        <Box className="create-list-content">

          {errMsg && <Alert severity="error">{errMsg}</Alert>}

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography variant="subtitle1" color={'#17536B'}>{t('englishName')} :</Typography>
            <Typography>{selectListData.englishName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography variant="subtitle1" color={'#17536B'}>{t('frenchName')} :</Typography>
            <Typography>{selectListData.frenchName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography variant="subtitle1" color={'#17536B'}>{t('type')} :</Typography>
            <Typography>{t(selectListData?.type)}</Typography>
          </Box>
          <Divider />
          {selectListData.values?.length > 0 &&
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                <Grid item xs={5.5}>
                  <Typography className="create-list-values-title" color={'#17536B'} variant="h6">{t('englishList')}</Typography>
                </Grid>
                <Grid item xs={5.5}>
                  <Typography className="create-list-values-title" color={'#17536B'} variant="h6">{t('frenchList')}</Typography>
                </Grid>
              </Grid>
              {selectListData.values?.map((record) => (
                <Grid container key={record.id} spacing={2} sx={{ marginBottom: '30px' }}>
                  <Grid item xs={5.5}>
                    <Typography className="create-list-values-response">{record.enValue}</Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography className="create-list-values-response">{record.frValue}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }

        </Box>
      }

      {openDelete &&
        <DeleteList
          id={id}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
        />
      }

    </Page>
  );
};