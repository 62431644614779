import { Dialog, DialogContent, DialogTitle, Button, Typography, Box, TextField } from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StyledFieldset } from '../../../../../utils/styledFieldSet';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from "@material-ui/core/styles";
import { DialogActions } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import React, { useState } from "react";
import Label from "../../../../Label";
import axios from 'axios';
import '../../../../../css/CV/addSkills.css';

export const AddSkills = (props) => {
    const educationDialog = true
    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    const { setSection, skills, setSkills } = props
    const [oneValue, setOneValue] = useState("");
    const [newSkills, setNewSkills] = useState(skills ? skills : []);
    const [errorAddValue, setErrorAddValue] = useState("");


    const useStyles = makeStyles((theme) => ({
        notchedOutline: {
            borderWidth: "2px",
            borderColor: "#17536B !important",
        },
    }));
    const classes = useStyles();

    const handleOnDragEnd = (result) => {
        const item = Array.from(newSkills);
        const [reorderedItem] = item.splice(result.source.index, 1);
        item.splice(result.destination.index, 0, reorderedItem);
        setNewSkills(item);
    };

    const handlePushValue = () => {
        if (newSkills?.some((info) => info === oneValue) || oneValue === "") {
            setErrorAddValue(t('attributeNotAccepted'));
            return;
        }
        setNewSkills([...newSkills, oneValue]);
        setOneValue("");
        setErrorAddValue("");
    };

    const handleRemoveValue = (item) => {
        const AllValues = [...newSkills];
        const index = AllValues.indexOf(item);
        AllValues.splice(index, 1);
        setNewSkills(AllValues);
    };

    const addSkills = () => {
        const data = {
            skills: newSkills,
            userId: userData.id
        }
        axios.post(`user-cv`, data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSection("")
                    setSkills(newSkills)
                }
            })
    }


    return (
        <Dialog fullWidth open={educationDialog} >
            <DialogTitle className="page-title">{t('yourSkills')}</DialogTitle>
            <DialogContent className='education-dialog-content'>
                {errorAddValue !== "" ? (
                    <Typography
                        className="error-message"
                    >
                        {errorAddValue}
                    </Typography>
                ) : null}
                <Box sx={{ display: "flex", alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                    <TextField
                        id="value"
                        name="skillsValue"
                        label={t('addNewSkill')}
                        fullWidth
                        type="text"
                        value={oneValue}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        onChange={(e) => setOneValue(e.target.value)}
                    />
                    <AddCircleIcon
                        data-testid="add-value"
                        className="circle-add-icon"
                        onClick={handlePushValue}
                    />
                </Box>

                {newSkills.length > 0 && <StyledFieldset component="fieldset" sx={{ marginTop: '10px' }}>
                    <legend>{t('yourSkills')}</legend>
                    <Box className='addSkills-servise-legend'>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="values" direction="horizontal">
                                {(provided) => (
                                    <Box {...provided.droppableProps} ref={provided.innerRef} className='addSkills-servise-box'>
                                        {newSkills && newSkills.map((item, index) => (
                                            <Draggable key={item} draggableId={item} index={index}>
                                                {(provided) => (
                                                    <Typography {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                        <Label>{item}</Label>
                                                        <CloseRoundedIcon
                                                            data-testid="removeSkill"
                                                            onClick={() => handleRemoveValue(item)}
                                                            className="close-icon"
                                                        />
                                                    </Typography>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Box>
                </StyledFieldset>}
            </DialogContent>
            <DialogActions className="addSkills-actions-box">
                <Button className="new-button" onClick={() => setSection("")}>{t('cancel')}</Button>
                <Button className="new-button" onClick={addSkills}>{t('add')}</Button>
            </DialogActions>
        </Dialog>
    )
}