import React from 'react';
import Iconify from '../../Iconify';
import { Box, Typography } from '@mui/material';
import { smartDateTime } from '../../../utils/formatTime';
import ImeahActionIcon from '../../../utils/imeah_action_icon';

const MedicalFileListCardMode = React.memo(({ index, style, data }) => {
  const { files, navigate, currentInstitution, isAllowedDeleteAllMedicalFile, isAllowedDeleteInstitutionMedicalFile, isAllowedDeleteMyMedicalFile, handleOpenDelete, t, userData } = data;
  const file = files[index];

  return (
    <Box style={style} key={file ? file.id : index}>
      <Box className="mdf-list-responsive-card-details" onClick={file ? () => navigate(`/dashboard/medicalFiles/${file.id}`) : undefined}>
        <Box className="mdf-list-responsive-card-title-response-box">
          <Typography className="mdf-list-responsive-card-title">{t('Patient_name')}</Typography>
          <Typography className="mdf-list-responsive-card-response imeah_persone_name">
            {file ? `${file.first_name.toLowerCase()} ${file.last_name.toLowerCase()}` : 'Loading...'}
          </Typography>
        </Box>
        <Box className="mdf-list-responsive-card-title-response-box">
          <Typography className="mdf-list-responsive-card-title">{t('specialityExpertise')}</Typography>
          <Typography className="mdf-list-responsive-card-response">
            {file ? (file.specialityID !== 'NoSpeciality' ? file.speciality?.specialityLabel[0]?.label : file.expertise?.expertiseLabel[0]?.label) : 'Loading...'}
          </Typography>
        </Box>
        <Box className="mdf-list-responsive-card-title-response-box">
          <Typography className="mdf-list-responsive-card-title">{t('requerant')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography className='imeah_persone_name mdf-list-responsive-card-response'>
              {file ? `${file.doctor?.user?.firstname.toLowerCase()} ${file.doctor?.user?.lastname.toLowerCase()}` : 'Loading...'}
            </Typography>
            {file && file.institutionId !== currentInstitution.institution.id && (
              <Typography className='mdf-list-responsive-card-response'>({file.doctor?.institution?.name})</Typography>
            )}
          </Box>
        </Box>
        <Box className="mdf-list-responsive-card-title-response-box">
          <Typography className="mdf-list-responsive-card-title">{t('requis')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography className='imeah_persone_name mdf-list-responsive-card-response'>
              {file ? `${file.targetDoctorForShare?.user?.firstname.toLowerCase()} ${file.targetDoctorForShare?.user?.lastname.toLowerCase()}` : 'Loading...'}
            </Typography>
            {file && file.targetDoctorForShare?.institution?.name !== currentInstitution?.institution?.name && (
              <Typography className='mdf-list-responsive-card-response'>({file.targetDoctorForShare?.institution?.name})</Typography>
            )}
          </Box>
        </Box>
        <Box className="mdf-list-responsive-card-title-response-box">
          <Typography className="mdf-list-responsive-card-title">{t('Status')}</Typography>
          <Box className="mdf-list-responsive-card-status">
            <Iconify
              icon="tabler:point-filled"
              sx={{
                fontSize: '24px',
                color: file ? (file.status === 'Waiting' ? '#f7eb5e' : file.status === 'Answered' ? '#3ce641' : file.status === 'Shared' ? '#2065D1' : 'd9d6b0') : '#d9d6b0',
              }}
            />
            <Typography className="mdf-list-responsive-card-response">
              {file ? t(file.status) : 'Loading...'}
            </Typography>
          </Box>
        </Box>
        <Box className="mdf-list-responsive-card-title-response-box">
          <Typography className="mdf-list-responsive-card-title">{t('lastChanges')}</Typography>
          <Typography className="mdf-list-responsive-card-response">
            {file ? smartDateTime(file.updated_at, userData.local_code) : 'Loading...'}
          </Typography>
        </Box>
        <Box className="mdf-list-responsive-card-title-response-box" onClick={file ? (e) => e.stopPropagation() : undefined}>
          {file && (isAllowedDeleteAllMedicalFile || (isAllowedDeleteInstitutionMedicalFile && file.institutionId === currentInstitution.institution.id) || (isAllowedDeleteMyMedicalFile && file.doctorId === currentInstitution.id)) && (
            <ImeahActionIcon icon="ic:round-delete" color='#727179' onClick={() => handleOpenDelete(file.id)} />
          )}
        </Box>
      </Box>
    </Box>
  );
});

export default MedicalFileListCardMode;