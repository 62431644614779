import { Box, Typography, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const ProfileDetails = ({ userInfo, InstitutionUserData, isGuest }) => {

  const { t } = useTranslation();

  const { RPPS, phone } = userInfo;
  const { Scouting, recommandationTime, role, specialities, institutionUserExpertise } = InstitutionUserData || {};

  // Render RPPS
  const renderRPPS = () => (
    RPPS?.length > 0 && (
      <Box>
        <Typography className="label-part">{t('RPPS')}</Typography>
        <Typography sx={{ textAlign: 'center' }}>{RPPS}</Typography>
      </Box>
    )
  );

  // Render Scouting
  const renderScouting = () => (
    Scouting?.amount?.length > 0 && Scouting !== null && (
      <Box>
        <Typography className="label-part">{t('scouting')}</Typography>
        <Typography sx={{ textAlign: 'center' }}>
          {Scouting.amount} {Scouting.currency}
        </Typography>
      </Box>
    )
  );

  // Render Recommandation Time
  const renderRecommandationTime = () => (
    recommandationTime !== null && !isGuest && (
      <Box>
        <Typography className="label-part">{t('maxRecomDay')}</Typography>
        <Typography sx={{ textAlign: 'center' }}>{recommandationTime}</Typography>
      </Box>
    )
  );

  // Render Speciality
  const renderSpeciality = () => (
    role?.canHaveSpeciality && specialities?.id !== 'NoSpeciality' && (
      <Box color="textSecondary" className='label-response-box'>
        <Typography className="label-part">{t('speciality')}</Typography>
        <Typography className="response-part">{specialities?.specialityLabel[0].label}</Typography>
      </Box>
    )
  );

  // Render Expertises
  const renderExpertises = () => (
    role?.canHaveExpertise && institutionUserExpertise?.length > 0 && (
      <Box color="textSecondary" className='label-response-box'>
        <Typography className="label-part">{t('expertises')}</Typography>
        <Box className="response-part">
          {institutionUserExpertise.map((expertise) => (
            <Chip key={expertise.expertise.id} label={expertise.expertise.expertiseLabel?.[0]?.label} />
          ))}
        </Box>
      </Box>
    )
  );

  return (
    <Box className="account-prof-card-dtails-part">
      <Box color="textSecondary" variant="body3" className='label-response-box'>
        {renderRPPS()}
        {phone &&
          <Box>
            <Typography className="label-part">{t('phoneNumber')}</Typography>
            <Typography className="response-part">{phone}</Typography>
          </Box>
        }
        {renderScouting()}
        {renderRecommandationTime()}
        <Typography className="label-part">{t('role')}</Typography>
        <Typography className="response-part">{isGuest ? t('guest') : t(role?.roleLabel[0].label)}</Typography>
      </Box>
      {renderSpeciality()}
      {renderExpertises()}
    </Box>
  );
};