import { Box, Table, LinearProgress, TableRow, TableHead, Grid, TableBody, TableCell, Typography, useMediaQuery } from '@mui/material';
import { StyledTableRow } from '../../utils/StyledTableRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Page from '../Page';
import React from 'react';
import '../../css/institutions/readInstitutions.css';

export const ReadInstitutions = ({ loading, institutions, fetchInstitutions, hasMore }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Page title={t("sideBar-hospitals")}>

      {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} /> :
        <InfiniteScroll
          dataLength={institutions.length}
          next={fetchInstitutions}
          hasMore={hasMore}
          loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
        >

          {isDesktop &&
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className='read-institution-table-titles'>{t('name')}</TableCell>
                  <TableCell className='read-institution-table-titles'>{t('type')}</TableCell>
                  <TableCell className='read-institution-table-titles'>{t('institutionCity')}</TableCell>
                  <TableCell className='read-institution-table-titles'>{t('country')}</TableCell>
                  <TableCell className='read-institution-table-titles'>{t('phoneNumber')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {institutions && institutions.map((institution) => {
                  const { id, name, city, phone, institutionType, countryName } = institution;
                  return (
                    <StyledTableRow key={id} onClick={() => navigate(`readOne/${institution.id}`, { state: { institution } })}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{institutionType?.institutions_types_Label[0].label}</TableCell>
                      <TableCell>{city}</TableCell>
                      <TableCell>{countryName}</TableCell>
                      <TableCell>{phone || 'N/A'}</TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          }

          {!isDesktop &&
            <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
              {institutions && institutions.map((institution) => (
                <Grid item xs={12} sm={4} key={institutions.id} onClick={() => navigate(`readOne/${institution.id}`, { state: { institution } })}>
                  <Box className="read-institution-responsive-card-details">
                    <Typography className='read-institution-responsive-card-data'>{institution.name}</Typography>
                    <Typography className='read-institution-responsive-card-data'>{institution.institutionType?.institutions_types_Label[0].label}</Typography>
                    <Typography className='read-institution-responsive-card-data'>{institution.city}</Typography>
                    <Typography className='read-institution-responsive-card-data'>{institution.countryName}</Typography>
                    <Typography className='read-institution-responsive-card-data'>{institution.phone || 'N/A'}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          }

        </InfiniteScroll>
      }

    </Page>
  );
}