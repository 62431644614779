import * as React from 'react';
import { CardMedia, Box, Link } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import '../../css/homePage/navBar.css'
import Logo from '../../images/logo_e_health_pro.svg'
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from '../NoLoginLanguageSwitcher';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box className='home-navbar-main-box'>
            <CssBaseline />
            <AppBar className='home-app-bar' open={open}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className='home-navbar-burgur-icon-btn'
                    >
                        <MenuIcon className='home-navbar-burgur-icon' />
                    </IconButton>
                    <Box className="home-imeah-navbar-logo-links-box">
                        <CardMedia
                            className="home-imeah-navbar-logo"
                            component="img"
                            image={Logo}
                            alt="My Image"
                        />
                        <Box className="home-imeah-navbar-links-box">
                            <Link href={`#` + t('report')} className='home-navbar-typography'>{t('report')}</Link>
                            <Link href={`#` + t('networkManagement')} className='home-navbar-typography'>{t('networkManagement')}</Link>
                            <Link href={`#` + t('training')} className='home-navbar-typography'>{t('training')}</Link>
                            <Link href={`#` + t('library')} className='home-navbar-typography'>{t('library')}</Link>
                            <Link href={`#` + t('forWho')} className='home-navbar-typography'>{t('forWho')}?</Link>
                            <Link href={`#` + t('responsibleMedicine')} className='home-navbar-typography'>{t('responsibleMedicine')}</Link>
                            <Link href={`#` + t('whoWeAre')} className='home-navbar-typography'>{t('whoWeAre')}</Link>
                            <Link href={`#` + t('contactUs')} className='home-navbar-typography'>{t('contactUs')}</Link>
                        </Box>
                        <LanguageSwitcher />
                        <LoginIcon className='home-navbar-login' onClick={() => { navigate('/login') }} />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {[t('report'), t('networkManagement'), t('training'), t('library'), t('forWho'), t('responsibleMedicine'), t('whoWeAre'), t('contactUs')].map((text, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton>
                                <Link href={`#${text.replace(/ /g, '%20')}`} className='home-navbar-typography'>
                                    <ListItemText primary={text} />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
            </Drawer>
        </Box>
    );
}