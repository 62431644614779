import { Box, Typography, Alert, Stack, LinearProgress, Button, Grid } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MuiMultiSelect } from '../../utils/muiMultiSelect.js';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Page from '../Page.js';
import * as Yup from 'yup';
import axios from 'axios';
import '../../css/collaboration/editCollaboration.css';

export const EditCollaboration = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  const userData = useSelector(state => state.userData);

  const [choosenSpecialities, setChoosenSpecialities] = useState([]);
  const [choosenExpertises, setChoosenExpertises] = useState([]);
  const [providingInstitutionId, setProvidingInstitutionId] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');

  // Validation Schema
  const validationSchema = Yup.object().shape({
    specialities: Yup.array(),
    expertises: Yup.array(),
  })
    .test(
      'specialitiesOrExpertisesRequired',
      t('specialitiesOrExpertisesRequired'),
      function (values) {
        const { specialities, expertises } = values;
        return specialities?.length > 0 || expertises?.length > 0;
      }
    );

  const handleFetchOneCollaboration = useCallback(async () => {
    try {
      const response = await axios.get(`collaboration-institution/${id}`);
      const { collaborationSpeciality, collaborationExpertise, providingInstitutionId } = response.data;
      const specialitiesOptions = collaborationSpeciality?.map((speciality) => ({ value: speciality.speciality.specialityLabel[0].specialityId, label: speciality.speciality.specialityLabel[0].label }));
      const expertisesOptions = collaborationExpertise?.map((expertise) => ({ value: expertise.expertise.expertiseLabel[0].expertiseId, label: expertise.expertise.expertiseLabel[0].label }));
      setChoosenSpecialities(specialitiesOptions);
      setChoosenExpertises(expertisesOptions);
      setProvidingInstitutionId(providingInstitutionId);
    } catch (error) {
      console.error(error);
      setMsg(error.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (userData) {
      handleFetchOneCollaboration();
    }
  }, [userData, handleFetchOneCollaboration]);

  const fetchSpecialities = async (page, searchTerm) => {
    try {
      const response = await axios.get('speciality/specialitiesForCreateCollaborations', {
        params: { providingInstitutionId, page, search: searchTerm },
      });
      return response.data?.map(record => ({ value: record.value, label: record.label }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const fetchExpertises = async () => {
    try {
      const response = await axios.get('expertise/expertisesForCreateCollaboration', { params: { providingInstitutionId } });
      return response.data?.map(record => ({ value: record.value, label: record.label }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const editCollaborationData = async () => {

    const bodyData = {
      specialities: choosenSpecialities.map((speciality) => speciality.value),
      expertises: choosenExpertises.map((expertise) => expertise.value),
    };

    try {
      await validationSchema.validate(bodyData, { abortEarly: false });

      const response = await axios.patch(`collaboration-institution/${id}`, bodyData);
      if (response.status === 200 || response.status === 201) {
        navigate(-1);
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setMsg(error?.errors?.join(` ${t('and')} `));
      } else {
        setMsg(error.message);
      }
    }
  };

  return (
    <Page title={t("editCollaboration")}  >
      <Box className="editCollaboration-main-page-header-box">
        <ArrowBackIosIcon className='editCollaboration-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
        <Stack className="editCollaboration-main-page-header">
          <Box className="editCollaboration-page-title-box">
            <Typography className='editCollaboration-page-title'>{t("editCollaboration")}</Typography>
          </Box>
        </Stack>
      </Box>
      {msg && <Alert severity="error">{msg}</Alert>}
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6}>
            <MuiMultiSelect
              label={t('specialities')}
              required={false}
              searchable={true}
              value={choosenSpecialities}
              fetchFunction={fetchSpecialities}
              onChange={(selectedOptions) => setChoosenSpecialities(selectedOptions)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiMultiSelect
              label={t('expertises')}
              required={false}
              searchable={true}
              value={choosenExpertises}
              fetchFunction={fetchExpertises}
              onChange={(selectedOptions) => setChoosenExpertises(selectedOptions)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className="addCollaboration-requiredNote">{t('atLeastSpecialityOrExpertiseRequired')}</Typography>
          </Grid>

        </Grid>
      )}
      <Box className="editCollaboration-actions-box">
        <Button className='new-button' onClick={() => navigate(-1)}>{t('cancel')}</Button>
        <Button className='new-button' onClick={editCollaborationData}>{t('save')}</Button>
      </Box>
    </Page>

  );
};