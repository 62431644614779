import { Autocomplete, TextField, CircularProgress, Box, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const ImeahSelect = ({
    width,
    label,
    value,
    onChange,
    fetchFunction,
    required,
    searchable,
    valueField = 'value',
    labelField = 'label',
    error,
    helperText,
    onBlur,
}) => {

    const [options, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const limit = 10;

    const fetchOptions = useCallback(async (page, searchTerm) => {
        try {
            const newOptions = await fetchFunction(page, searchTerm);
            setOptions(prevOptions => {
                const mergedOptions = page === 0 ? newOptions : [...prevOptions, ...newOptions];
                if (value && !mergedOptions.some(option => option[valueField] === value[valueField])) {
                    mergedOptions.push(value);
                }
                return mergedOptions;
            });
            setHasMore(newOptions.length >= limit);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setIsFetching(false);
        }
    }, [fetchFunction, value, valueField]);

    useEffect(() => {
        if (isFetching) {
            fetchOptions(page, search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, page, search]);

    useEffect(() => {
        setOptions([]);
        setPage(0);
        setSearch('');
        setIsFetching(true);
    }, []);

    const handleScroll = (event) => {
        const bottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 1;
        if (bottom && hasMore && !isFetching) {
            setPage(prevPage => prevPage + 1);
            setIsFetching(true);
        }
    };

    const handleOpen = () => {
        if (options.length === 0 && !isFetching) {
            setIsFetching(true);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        if (newInputValue !== search) {
            setOptions([]);
            setPage(0);
            setSearch(newInputValue);
            setIsFetching(true);
        }
    };

    const handleRemove = () => {
        onChange({
            target: {
                value: null,
                label: null,
            }
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Autocomplete
                id="autocomplete-select"
                value={null} // Set value to null to prevent displaying inside the input
                inputValue={search} // Control the input value
                onChange={(event, newValue) => {
                    onChange({
                        target: {
                            ...newValue,
                            value: newValue ? newValue[valueField] : null,
                            label: newValue ? newValue[labelField] : null,
                        }
                    });
                    setSearch(""); // Reset the search value
                }}
                onInputChange={searchable ? handleInputChange : undefined}
                onOpen={handleOpen}
                options={options.filter(option => !value || option[valueField] !== value[valueField])}
                getOptionLabel={(option) => option[labelField] || ""}
                isOptionEqualToValue={(option, value) => option[valueField] === value[valueField]}
                renderInput={(params) => (
                    <TextField
                        margin="dense"
                        style={{ width: `${width}` }}
                        className='customTextField'
                        required={required}
                        {...params}
                        label={label}
                        variant="outlined" // Make sure this is outlined
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isFetching ? <CircularProgress size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        error={error}
                        helperText={helperText}
                        onBlur={onBlur}
                    />
                )}
                ListboxProps={{
                    onScroll: handleScroll,
                    style: {
                        maxHeight: 300,
                    },
                }}
            />

            {value[valueField] && (
                <Box className="imeah_ships_box">
                    <Typography variant="body1">{value[labelField]}</Typography>
                    <IconButton onClick={handleRemove} className="imeah_ships_button">
                        <CloseIcon className="imeah_ships_icon" />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};