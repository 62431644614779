import { Button, Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import Iconify from '../../Iconify';
import axios from 'axios';

export const ValidateRecommandation = ({
    medicalFileData,
    setMedicalFileData,
    openValidateRecommandation,
    setOpenValidateRecommandation
}) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    // Close dialog handler
    const handleCloseDialog = useCallback(() => {
        setOpenValidateRecommandation(false);
    }, [setOpenValidateRecommandation]);

    // Validate the recommendation
    const handleValidateRecommandation = useCallback(async () => {
        const bodyData = { medicalFileId: medicalFileData.id };
        setLoading(true);
        try {
            const response = await axios.patch(`recommandation/ValidateRecommandation/${medicalFileData?.recommandation?.id}`, bodyData);
            if (response.status === 200 || response.status === 201) {
                setMedicalFileData(prevData => ({ ...prevData, status: response.data.status, recommandation: response.data.recommandation }));
            }
        } catch (error) {
            console.error('Error validating recommendation:', error.message);
            CustomErrorLogger(error, 'Error validating recommendation');
        }
        finally {
            setLoading(false);
            handleCloseDialog();
        }
    }, [handleCloseDialog, medicalFileData.id, medicalFileData?.recommandation?.id, setMedicalFileData]);

    // Memoized dialog props to prevent unnecessary re-renders
    const dialogProps = useMemo(() => ({
        open: openValidateRecommandation,
        keepMounted: true,
        onClose: handleCloseDialog,
    }), [openValidateRecommandation, handleCloseDialog]);

    return (
        <Dialog {...dialogProps} >
            <DialogContent className='imeah_dialog_content'>
                <Iconify icon='grommet-icons:validate' className='imeah_dialog_icon' />
                <Typography className='imeah_dialog_title'>{t('sureValidateRecommandation')}</Typography>
                <Typography className='imeah_dialog_subtitle'>{t('validateRecommandationDescription')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth className='buttonOutlined' onClick={handleCloseDialog}>{t('cancel')}</Button>
                <LoadingButton fullWidth className='buttonFilled' loading={loading} onClick={handleValidateRecommandation}>{t('valid_and_send')}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
