import { TextField, LinearProgress, Typography, InputAdornment, IconButton, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../components/Iconify';
import React, { useState } from 'react';

export const RegisterPasswordStrengthMeter = ({ password, handleChange, showNewPassword, setShowNewPassword }) => {

    const { t } = useTranslation();
    const [strength, setStrength] = useState('');

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})");
    const mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})");

    const handlePasswordChange = (event) => {
        const { value } = event.target;
        handleChange(event);

        if (strongRegex.test(value)) {
            setStrength('Strong');
        } else if (mediumRegex.test(value)) {
            setStrength('Medium');
        } else {
            setStrength('Weak');
        }
    };

    const getStrengthColor = (strength) => {
        switch (strength) {
            case 'Strong':
                return 'success';
            case 'Medium':
                return 'warning';
            case 'Weak':
                return 'error';
            default:
                return 'primary';
        }
    };

    const getStrengthValue = (strength) => {
        switch (strength) {
            case 'Strong':
                return 100;
            case 'Medium':
                return 66;
            case 'Weak':
                return 33;
            default:
                return 0;
        }
    };

    return (
        <Box>
            <TextField
                id="hpassword"
                name="hpassword"
                label={t('password')}
                type={showNewPassword ? "text" : "password"}
                variant="outlined"
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowNewPassword((show) => !show)} edge="end">
                                <Iconify
                                    icon={
                                        showNewPassword
                                            ? "eva:eye-fill"
                                            : "eva:eye-off-fill"
                                    }
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                error={!password}
                helperText={!password && t('isRequired')}
            />
            <Box mt={2}>
                <LinearProgress variant="determinate"
                    value={getStrengthValue(strength)}
                    color={getStrengthColor(strength)}
                />
                <Typography variant="body2" color="textSecondary">{strength}</Typography>
            </Box>
        </Box>
    );
};