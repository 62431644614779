import { Typography, Card, CardContent, CardHeader, List, ListItem, ListItemText } from '@mui/material';
import { versionLogsData } from '../utils/versionLogsData';
import { format } from 'date-fns';

export const VersionLogs = () => {

    return (
        <Card sx={{ bgcolor: '#17536B', color: 'white', mb: 2 }}>
            <CardHeader title="Release Updates" />
            <CardContent>
                {versionLogsData && versionLogsData.map((versionLog, index) => (
                    <Card key={index} sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6">Version : {versionLog.version}</Typography>
                            <Typography variant="body2" color="textSecondary">{format(new Date(versionLog.date), 'dd/MM/yyyy')}</Typography>
                            <List dense>
                                {versionLog.features.map((feature, featureIndex) => (
                                    <ListItem key={featureIndex}>
                                        <ListItemText primary={feature} />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                ))}
            </CardContent>
        </Card>
    );
};