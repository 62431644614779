import { Box, Typography, Stack, Divider, List, ListItem, ListItemText, useMediaQuery, Menu, MenuItem } from '@mui/material';
import { HasPermission } from '../../utils/checkUserPermission';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DeleteInstitution } from './deleteInstitution';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Iconify from '../Iconify';
import Page from '../Page';
import axios from 'axios';
import '../../css/institutions/readOneInstitution.css';

export const ReadOneInstitution = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const currentInstitution = useSelector(state => state.currentInstitution);

    const isAllowedEditAllInstitution = HasPermission('Edit_All_Institutions');
    const isAllowedDeleteAllInstitution = HasPermission('Delete_All_Institutions');

    const [institution, setInstitution] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [isAllowedEditRelatedInstitution, setIisAllowedEditRelatedInstitution] = useState(false);
    const [isAllowedDeleteRelatedInstitution, setIsAllowedDeleteRelatedInstitution] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const openActionMenu = Boolean(anchorEl);

    const handleOpenActionMenu = (event) => setAnchorEl(event.currentTarget);

    const handleCloseActionMenu = () => setAnchorEl(null);

    const handleFetchOneInstitution = useCallback(async () => {
        try {
            const response = await axios.get(`institutions/${id}`);
            if (response.status === 200 || response.status === 201) {
                setInstitution(response.data.institutionData);
                setIisAllowedEditRelatedInstitution(response.data.canEditInstitution);
                setIsAllowedDeleteRelatedInstitution(response.data.canDeleteInstitution);
            }
        } catch (error) {
            console.log('Error fetching institution:', error);
        }
    }, [id]);

    useEffect(() => {
        handleFetchOneInstitution();
    }, [handleFetchOneInstitution]);

    return (
        <Page title={t("institution")} className="oneInstitution-main-box">

            <Box className="oneInstitution-main-page-header-box">
                <ArrowBackIosIcon className='oneInstitution-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                <Stack className="oneInstitution-main-page-header">
                    <Typography className='oneInstitution-page-title'>{t("institution")}</Typography>
                    {isDesktop &&
                        <Box className='oneInstitution-actions-box'>
                            {(isAllowedEditAllInstitution || (isAllowedEditRelatedInstitution)) &&
                                <Box className='oneInstitution-page-header-button' onClick={() => navigate(`/dashboard/hospitals/edit/${institution.id}`)}>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Iconify icon="bytesize:edit" className="oneInstitution-action-icon" />
                                    <Typography>{t('edit')}</Typography>
                                </Box>
                            }

                            {(isAllowedDeleteAllInstitution || (isAllowedDeleteRelatedInstitution && (institution.id === currentInstitution.institution.id))) &&
                                <Box className='onefile-btn-container' onClick={() => setOpenDelete(true)}>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Iconify icon="material-symbols:delete" className="oneInstitution-action-icon" />
                                    <Typography>{t('dlt')}</Typography>
                                </Box>
                            }
                        </Box>
                    }
                    {(!isDesktop && ((isAllowedEditAllInstitution || isAllowedEditRelatedInstitution) || (isAllowedDeleteAllInstitution || isAllowedDeleteRelatedInstitution))) &&
                        <IconButton
                            className="main-page-threeDot-icon"
                            id="selectListPageActionMenuIcom"
                            aria-controls={openActionMenu ? 'long-menu' : undefined}
                            aria-expanded={openActionMenu ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleOpenActionMenu}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    }

                    <Menu
                        id="selectListePageActionMenu"
                        MenuListProps="selectListPageActionMenuIcom"
                        open={openActionMenu}
                        anchorEl={anchorEl}
                        onClose={handleCloseActionMenu}
                        onClick={handleCloseActionMenu}
                        slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
                    >
                        {(isAllowedEditAllInstitution || isAllowedEditRelatedInstitution) &&
                            <MenuItem className='main-page-header-button' onClick={() => navigate(`/dashboard/hospitals/edit/${institution.id}`)}>
                                <Iconify icon="bytesize:edit" className="main-page-threeDot-icon" />
                                <Typography>{t('edit')}</Typography>
                            </MenuItem>
                        }
                        {(isAllowedDeleteAllInstitution || isAllowedDeleteRelatedInstitution) &&
                            < MenuItem className='main-page-header-button' onClick={() => setOpenDelete(true)}>
                                <Iconify icon="material-symbols:delete" className="main-page-threeDot-icon" />
                                <Typography>{t('dlt')} </Typography>
                            </MenuItem>
                        }
                    </Menu>
                </Stack>
            </Box>

            <Box>
                <Typography variant="body1" gutterBottom><strong>{t('name')}:</strong> {institution.name}</Typography>
                <Typography variant="body1" gutterBottom><strong>{t('type')}:</strong> {institution.institutionType?.institutions_types_Label[0].label}</Typography>
                {institution.phone && <Typography variant="body1" gutterBottom><strong>{t('phoneNumber')}:</strong> {institution.phone}</Typography>}
                <Typography variant="body1" gutterBottom><strong>{t('institutionAddress')}:</strong> {institution.address}</Typography>
                <Typography variant="body1" gutterBottom><strong>{t('institutionCity')}:</strong> {institution.city}</Typography>
                <Typography variant="body1" gutterBottom><strong>{t('country')}:</strong> {institution.countryName}</Typography>
                <Typography variant="body1" gutterBottom><strong>{t('ActiveInstitution')}:</strong> {institution.is_active ? t('yes') : t('no')}</Typography>
                <Typography variant="body1" gutterBottom><strong>{t('twoFactorVerification')}:</strong> {institution.twoFactorVerification ? t('yes') : t('no')}</Typography>
                <Typography variant="body1" gutterBottom><strong>{t('browserVerification')}:</strong> {institution.browserVerification ? t('yes') : t('no')}</Typography>
                <Typography variant="body1" gutterBottom><strong>{t('allowVisio')}:</strong> {institution.allowVisio ? t('yes') : t('no')}</Typography>
                {(institution.services?.length > 0) &&
                    <Box>
                        <Typography variant="body1"><strong>{t('services')}:</strong></Typography>
                        <List>
                            {institution.services.map((service, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={service} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                }
            </Box>

            {openDelete &&
                <DeleteInstitution
                    id={institution.id}
                    openDelete={openDelete}
                    setOpenDelete={setOpenDelete}
                />
            }
        </Page>
    );
};