import { styled } from '@mui/material/styles';
import { TableRow } from '@mui/material';

export const ImeahTableRow = styled(TableRow)(({ theme, titlesRow }) => ({
  cursor: 'pointer',
  position: 'relative', // Ensure the row is a positioning context for child elements
  backgroundColor: theme.palette.background.default, // Set a solid background color

  // Alternate row background color
  '&:nth-of-type(odd)': {
    backgroundColor: '#f4f6f9',
  },

  // Styles for all cells
  '& td, & th': {
    borderWidth: '1px 1px 0px 0px',
    borderStyle: 'solid',
    borderColor: 'var(--Neutral-200, #E9ECEF)', // Ensure borders are opaque
    backgroundColor: 'inherit', // Inherit background color from the row
    boxSizing: 'border-box', // Ensure padding and borders are included in the element's dimensions
    alignItems: 'center',
    gap: '5px',
    fontSize: '14px',
    color: '#343A40',
    lineHeight: '20px',
    height: '56px',
    padding: '0px 24px',
    opacity: 1,
    position: 'relative',
    zIndex: 0, // Ensure it is below the fixed column
    whiteSpace: 'nowrap', // Prevent text wrapping
    overflow: 'hidden', // Hide overflowed content
    textOverflow: 'ellipsis', // Display ellipsis for truncated content
    minWidth: 'max-content', // Ensure minimum width matches the content size
  },

  // Styles for titlesRow
  ...(titlesRow && {
    position: 'sticky',
    top: 0,
    zIndex: 3, // Ensure it stays above other rows
    backgroundColor: theme.palette.background.default,
    '&:only-child td, &:only-child th': {
      fontSize: '12px',
      color: '#6C757D',
      lineHeight: '16px',
      height: '40px',
      padding: '0px 24px',
      borderWidth: '0px 1px 0px 0px',
      borderLeft: 0,
      borderStyle: 'solid',
      borderColor: 'var(--Neutral-200, #E9ECEF)',
      opacity: 1,
      zIndex: 1,
      position: 'relative',
      cursor: 'default',
      backgroundColor: theme.palette.background.default, // Ensure the background color is consistent
    },
  }),

  // Remove the right border for the last cell
  '& td:last-child, & th:last-child': {
    borderRight: 'none',
  },

  // Apply styles to all cells in the first column
  '& td:first-child, & th:first-child': {
    position: 'sticky',
    left: 0,
    zIndex: 2, // Ensure this is higher than other cells
    backgroundColor: 'inherit', // Inherit background color from the row
    borderRight: 'none', // Remove default right border
    '&::after': {
      // Create a fixed right border
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: '1px',
      backgroundColor: 'var(--Neutral-200, #E9ECEF)',
      zIndex: 3, // Ensure it stays above the cell content
    },
  },

  // Ensure the first cell of titlesRow has the same background color and sticky behavior
  ...(titlesRow && {
    '& td:first-child, & th:first-child': {
      backgroundColor: theme.palette.background.default, // Set the same background color as other cells
      position: 'sticky', // Ensure it remains sticky
      left: 0, // Stick to the left edge
      zIndex: 4, // Ensure it stays above other sticky elements
    },
  }),
}));
