import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography, Box, TextField } from "@mui/material";
import { formatTime, fDate } from "../../utils/formatTime";
import '../../css/medicalFiles/discussionTab.css';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { socket } from "../../App";
import DOMPurify from 'dompurify';
import Iconify from '../Iconify';
import version from '../../App';
import * as Yup from 'yup';
import axios from 'axios';

export const DiscussionMessages = ({ medicalFileId, canSendMessage, setMedicalFileData }) => {

    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);

    const scrollableRef = useRef(null);
    const inputRef = useRef(null);
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 15;

    const [flexDirection, setFlexDirection] = useState("column-reverse");
    const [fileMessages, setFileMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [replyFor, setReplyFor] = useState("");

    const AuthenticatedUser = useMemo(() => ({
        id: userData.id,
        email: userData.email,
        phone: userData.phone,
        firstname: userData.firstname,
        lastname: userData.lastname,
        local_code: userData.local_code,
        location: userData.location,
        userInstitutionId: currentInstitution.id,
        roleId: currentInstitution.role.id,
        institutionId: currentInstitution.institution.id,
        institutionName: currentInstitution.institution.name,
        userSpeciality: currentInstitution.specialities,
        userExpertises: currentInstitution.institutionUserExpertise,
        version: version,
    }), [userData, currentInstitution]);

    //yup validation for message text
    const validationSchema = useMemo(() => Yup.object().shape({
        message: Yup.string().trim().required(),
    }), []);

    //check state validation for field label
    const isMessageValid = useMemo(() => validationSchema.isValidSync({ message }), [message, validationSchema]);

    useEffect(() => {
        if (canSendMessage) {
            inputRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            inputRef.current?.focus();
        }
    }, [canSendMessage]);

    const handleFetchFileMessages = useCallback(async () => {
        if (!currentInstitution) return;

        try {

            const response = await axios.get(`medical_file_discussion/${medicalFileId}`, { params: { offset: offset.current, limit } });
            if (response.status === 200 || response.status === 201) {
                setFileMessages(prevMessages => offset.current === 0 ? response.data : [...response.data, ...prevMessages]);
                hasMore.current = response.data.length === limit;
                offset.current += limit;
                // scroll a bit from the top to stop infinit scroll event from triggering
                scrollableRef.current.scrollTo({
                    top: scrollableRef.current.scrollTop + 600,
                    behavior: "smooth"
                });
            }
        } catch (error) {
            console.error('Error fetch medical file messages :', error.message);
        }
    }, [currentInstitution, medicalFileId]);

    const handleAddMessage = useCallback(() => {
        if (!isMessageValid) return;

        const sanitizedMessage = DOMPurify.sanitize(message);

        socket.emit("sendMessage", {
            AuthenticatedUser,
            Message: { message: sanitizedMessage, replyFor, medicalFileId },
        });

        setReplyFor("");
        setMessage("");
        // Update setMedicalFileData only if necessary
        setMedicalFileData((prev) => prev.shareStatus && prev.status !== "Waiting" ? { ...prev, status: "Waiting" } : prev);

    }, [isMessageValid, message, replyFor, medicalFileId, AuthenticatedUser, setMedicalFileData]);

    useEffect(() => {
        socket.emit('accessMedicalFile', medicalFileId);

        socket.on('newFileDiscussion', (newFileDiscussion) => {
            setFileMessages(prevMessages => [...prevMessages, newFileDiscussion]);
        });

        return () => {
            socket.emit('leaveMedicalFile', medicalFileId);
            socket.off('newFileDiscussion');
        };
    }, [medicalFileId]);

    useEffect(() => {
        setFileMessages([]);
        hasMore.current = null;
        offset.current = 0; // Reset offset
        handleFetchFileMessages();
    }, [medicalFileId, handleFetchFileMessages]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAddMessage();
        }
    };

    const updateFlexDirection = useCallback(() => {
        const scrollableBox = scrollableRef.current;
        if (scrollableBox) {
            const needsScroll = scrollableBox.scrollHeight > scrollableBox.clientHeight;
            setFlexDirection(needsScroll ? "column-reverse" : "column");
        }
    }, []);

    useEffect(() => {
        updateFlexDirection();
    }, [fileMessages, updateFlexDirection]);

    return (
        <Box className="discussion-container">
            <Box ref={scrollableRef} id="scrollableDiv" flexDirection={flexDirection} className='discussion-msg-box'>
                <InfiniteScroll
                    dataLength={fileMessages.length}
                    next={handleFetchFileMessages}
                    hasMore={hasMore}
                    inverse={true}
                    scrollableTarget="scrollableDiv"
                >
                    {fileMessages && fileMessages.map((message, index) => {

                        const showSender = (index === 0 || fileMessages[index - 1].messageSendBy.id !== message.messageSendBy.id) &&
                            message.messageSendBy.id !== currentInstitution.id;

                        const showDate = index === 0 || fDate(new Date(fileMessages[index - 1].created_at), 'yyyy-MM-dd') !== fDate(new Date(message.created_at), 'yyyy-MM-dd');

                        const isLastMessageBySender = (index === fileMessages.length - 1) || (fileMessages[index + 1].messageSendBy.id !== message.messageSendBy.id);

                        return (
                            <Box key={index}>
                                {showDate && <Typography className="discussion-date-box">{fDate(message.created_at, userData.local_code)}</Typography>}

                                <Box className={` ${message.messageSendBy.id === currentInstitution.id ? 'discussion-sender-header' : 'discussion-recipient-header'}`}>
                                    <Box sx={{ maxWidth: '80%', margin: '5px 26px' }}>

                                        {showSender &&
                                            <Typography className="discussion-sender-name">{`${message.messageSendBy.firstname} ${message.messageSendBy.lastname}`}</Typography>
                                        }

                                        <Box className={`discussion-message ${message.messageSendBy.id === currentInstitution.id ? 'discussion-message-sender' : 'discussion-message-recipient'} ${isLastMessageBySender ? 'last-message' : ''}`}>
                                            <Typography>{message.message}</Typography>
                                        </Box>
                                        <Typography className="discussion-message-time">{formatTime(message.created_at)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                </InfiniteScroll>
            </Box>

            {canSendMessage &&
                <TextField
                    fullWidth
                    className="customTextField"
                    placeholder={t("messageInputHolder")}
                    id="medical_file_discussion_message"
                    inputRef={inputRef}
                    value={message || ""}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                        endAdornment: (
                            <Iconify icon="material-symbols:send" onClick={handleAddMessage} className="discussion-send_icon" />
                        ),
                    }}

                />
            }
        </Box>
    );
};