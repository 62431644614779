import { Button, Dialog, Typography, DialogContent, DialogActions } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useCallback, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import Iconify from '../../Iconify';
import axios from 'axios';

export const DeleteField = ({ medicalFile, setMedicalFile, openDeleteField, setOpenDeleteField, FieldId }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    // Close dialog handler
    const handleCloseDialog = useCallback(() => {
        setOpenDeleteField(false);
    }, [setOpenDeleteField]);

    const handleRemoveField = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.patch(`medical-file/removeField/${medicalFile.id}`, { FieldId });
            if (response.status === 200 || response.status === 201) {
                setMedicalFile(prevData => ({
                    ...prevData,
                    additional_information: response.data.additional_information,
                    medical_Files_logs: response.data.medical_Files_logs,
                }));
            }
        } catch (error) {
            console.error('Error removing field:', error);
            CustomErrorLogger(error, 'Error removing field from medical file');
        } finally {
            setLoading(false);
            handleCloseDialog();
        }
    }, [FieldId, handleCloseDialog, medicalFile.id, setMedicalFile]);

    // Memoized dialog props to prevent unnecessary re-renders
    const dialogProps = useMemo(() => ({
        open: openDeleteField,
        keepMounted: true,
        onClose: handleCloseDialog,
    }), [openDeleteField, handleCloseDialog]);

    return (
        <Dialog {...dialogProps} >
            <DialogContent className='imeah_dialog_content'>
                <Iconify icon='ic:round-delete' className='imeah_dialog_icon' />
                <Typography className='imeah_dialog_title'>{t('sureDeleteField')}</Typography>
                <Typography className='imeah_dialog_subtitle'>{t('deleteFieldDialogDescription')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth className='buttonOutlined' onClick={handleCloseDialog}>{t('cancel')}</Button>
                <LoadingButton fullWidth loading={loading} className='buttonFilled' onClick={handleRemoveField}>{t('dlt')}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
