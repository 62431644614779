import React from 'react'
import { Box, Typography } from '@mui/material'
import '../../css/homePage/homeFirstPage.css'
import '../../css/homePage/homeThirdPart.css'
import { useTranslation } from "react-i18next";

export const HomeThirdPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeThirdPart-main-box'>
            <Box className='homeThirdPart-triangle-box' />
            <Box className='homeThirdPart-triangle-inside'>
                <Typography className='homeThirdPart-triangle-first-typo'>
                    {t("theSolution")} :
                </Typography>
                <Typography className='homeThirdPart-triangle-second-typo'>
                    ehealth pro
                </Typography>
            </Box>
        </Box>
    )
}
