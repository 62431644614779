import { format, formatDistanceToNow, isToday } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

const localeFormats = {
    'en-US': 'MM/dd/yyyy',
    'en': 'MM/dd/yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'fr': 'dd/MM/yyyy',
    'default': 'MM/dd/yyyy'
};

const languages = {
    'en-US': enUS,
    'en': enUS,
    'fr-FR': fr,
    'fr': fr,
    'default': enUS
};

function getDateFormat(localeCode) {
    return localeFormats[localeCode] || localeFormats['default'];
}

function getLocale(localeCode) {
    return languages[localeCode] || languages['default'];
}

export const fDate = (date, locale) => format(new Date(date), getDateFormat(locale), { locale: getLocale(locale) })

export const formatTime = (datetime) => format(new Date(datetime), 'HH:mm');

export const fDateTime = (datetime, locale) => format(new Date(datetime), `${getDateFormat(locale)} HH:mm`, { locale: getLocale(locale) })

export const fDateTimeSuffix = (date, locale) => format(new Date(date), `${getDateFormat(locale)} p`)

export const fToNow = (date, locale) => formatDistanceToNow(new Date(date), { addSuffix: true, locale: getLocale(locale) })

export const smartDateTime = (date, locale) => {
    const targetDate = new Date(date)
    const formattedDate = isToday(targetDate)
        ? format(targetDate, 'HH:mm')  // Display only time if today
        : format(targetDate, getDateFormat(locale), { locale: getLocale(locale) }); // Display full date otherwise

    return formattedDate;
};