import {
  Stack, Typography, InputBase, Divider, Table, TableRow, Grid, TableBody, TableCell, LinearProgress, Box, TableHead, MenuItem, useMediaQuery, InputAdornment,
  IconButton, Menu
} from "@mui/material";
import { SelectListFiltration } from "../components/selectList/selectListFiltration";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { CustomErrorLogger } from "../utils/CustomErrorLogger";
import InfiniteScroll from 'react-infinite-scroll-component';
import { HasPermission } from "../utils/checkUserPermission";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { StyledTableRow } from "../utils/StyledTableRow";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Scrollbar from "../components/Scrollbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDebounce from "../hooks/useDebounce";
import { act } from '@testing-library/react';
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import '../css/selectList/selectList.css';
import Page from "../components/Page";
import axios from "axios";

const SelectList = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const userData = useSelector(state => state.userData);
  const currentInstitution = useSelector(state => state.currentInstitution);
  //check user permissions
  const isAllowedReadAllSelectList = HasPermission('Read_AllSelectList');
  const isAllowedCreateSelectList = HasPermission('Create_SelectList');

  const [anchorEl, setAnchorEl] = useState(null);
  const openActionMenu = Boolean(anchorEl);

  const [list, setList] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [expertises, setExpertises] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  //filters states
  const [selectedExpretise, setSelectedExpretise] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [search, setSearch] = useState(null);
  const debouncedSearch = useDebounce(search, 1000);
  //pagination states
  const offset = useRef(0);
  const hasMore = useRef(null);
  const limit = 20;

  const hasFilters = selectedSpeciality || selectedExpretise || selectedInstitution

  const fetchLists = useCallback(() => {
    const filter = {
      search: debouncedSearch,
      institutionId: selectedInstitution?.id,
      specialityId: selectedSpeciality?.id,
      expertiseId: selectedExpretise?.id
    };

    const currentOffset = offset.current;
    axios.get("select-list", { params: { filter, limit, offset: currentOffset } })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setList(prevList => currentOffset === 0 ? response.data : [...prevList, ...response.data]);
          setLoading(false)
          offset.current = currentOffset + limit;
          hasMore.current = response.data.length === limit;
        }
      })
      .catch((err) => {
        console.log(err, 'Error fetching select list in select list main page');
        CustomErrorLogger(err, 'Error fetching select list in select list main page');
      });
  }, [debouncedSearch, selectedExpretise, selectedInstitution, selectedSpeciality]);

  useEffect(() => {
    if (userData.local_code) {
      setList([]);
      offset.current = 0;
      hasMore.current = true;
    }
  }, [userData.local_code]);

  useEffect(() => {
    if (!currentInstitution) return
    fetchLists()
  }, [currentInstitution, fetchLists]);

  useEffect(() => {
    if (userData.local_code) {
      axios.get("select-list/getFiltrationArrays")
        .then((response) => {
          act(() => {
            setSpecialities(response.data?.specialityArray);
            setExpertises(response.data?.expertiseArray)
            setInstitutions(response.data?.institutionArray)
          })
        })
        .catch((err) => {
          console.log(err);
          CustomErrorLogger(err, 'Error fetching select list filtration arrays in select list main page');
        });
    }
  }, [userData.local_code]);

  const handleChangeSearch = (searchWord) => {
    offset.current = 0;
    hasMore.current = true;
    setSearch(searchWord);
  };

  const handleChangeExpertise = (selectedExpertise) => {
    setSelectedExpretise(selectedExpertise);
    setSelectedSpeciality(null);
    offset.current = 0;
    hasMore.current = true;
  };

  const handleChangeSpeciality = (selectedSpeciality) => {
    setSelectedSpeciality(selectedSpeciality);
    setSelectedExpretise(null);
    offset.current = 0;
    hasMore.current = true;
  };

  const handleChangeInstitution = (institution) => {
    setSelectedInstitution(institution);
    offset.current = 0;
    hasMore.current = true;
  };

  const emptyFiltrationObject = () => {
    setSearch(null)
    setSelectedExpretise(null);
    setSelectedSpeciality(null);
    setSelectedInstitution(null);
    offset.current = 0;
    hasMore.current = true;
  }

  const FilterTag = ({ label, onDelete }) => (
    <Box className='selectList-page-filter-flag-item'>
      <Typography>{label}</Typography>
      <Iconify icon="mdi:close" onClick={onDelete} />
    </Box>
  );

  const handleActionMenu = useCallback((event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  }, []);

  return (
    <Page title={t("selectList")}>
      <Stack className="selectList-main-page-header">
        <Box className="selectList-page-title-box">
          <Box className="selectList-page-title-icon-box">
            <Iconify className="selectList-page-title-icon" icon="eva:people-fill" />
            <Typography className='selectList-page-title'>{t("selectList")}</Typography>
          </Box>
          <InputBase
            className='selectList-page-search-input'
            id="outlined-password-input"
            label={t('search')}
            type="text"
            placeholder={t('search')}
            value={search || ""}
            onChange={(e) => handleChangeSearch(e.target.value)}
            endAdornment={
              search && (
                <InputAdornment position="end">
                  <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                </InputAdornment>
              )
            }
          />
        </Box>

        {isDesktop && <Box className="selectList-page-action-box">
          <Box className='selectList-page-header-button' onClick={() => setOpenFilter(true)}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon="mdi:filter" className="plus-icon" />
            <Typography className="new-button-title">{t('actionFilter')}</Typography>
          </Box>

          {isAllowedCreateSelectList &&
            <Box className='selectList-page-header-button' onClick={() => navigate('add')}>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Iconify icon="eva:plus-fill" className="plus-icon" />
              <Typography className="selectList-button-title">{t('Create')}</Typography>
            </Box>
          }
        </Box>
        }

        {!isDesktop &&
          <IconButton
            className="main-page-threeDot-icon"
            id="selectListPageActionMenuIcom"
            aria-controls={openActionMenu ? 'long-menu' : undefined}
            aria-expanded={openActionMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleActionMenu}
          >
            <MoreHorizIcon />
          </IconButton>
        }

        <Menu
          id="selectListePageActionMenu"
          MenuListProps="selectListPageActionMenuIcom"
          open={openActionMenu}
          anchorEl={anchorEl}
          onClose={handleActionMenu}
          onClick={handleActionMenu}
          slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
        >
          {isAllowedCreateSelectList &&
            <MenuItem className='main-page-header-button' onClick={() => navigate('add')}>
              <Iconify icon="eva:plus-fill" className="main-page-threeDot-icon" />
              <Typography>{t('Create')}</Typography>
            </MenuItem>
          }
          < MenuItem className='main-page-header-button' onClick={() => setOpenFilter(true)}>
            <Iconify icon="mdi:filter" className="main-page-threeDot-icon" />
            <Typography>{t('actionFilter')} </Typography>
          </MenuItem>
        </Menu>
      </Stack>

      {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} /> :
        <Box>
          {openFilter &&
            <SelectListFiltration
              isAllowedReadAllSelectList={isAllowedReadAllSelectList}
              emptyFiltrationObject={emptyFiltrationObject}
              setOpenFilter={setOpenFilter}
              specialities={specialities}
              expertises={expertises}
              institutions={institutions}
              selectedSpeciality={selectedSpeciality}
              selectedExpretise={selectedExpretise}
              selectedInstitution={selectedInstitution}
              handleChangeSpeciality={handleChangeSpeciality}
              handleChangeExpertise={handleChangeExpertise}
              handleChangeInstitution={handleChangeInstitution}
            />
          }

          {(!openFilter && hasFilters) &&
            <Box className='selectList-page-filter-flag-box'>
              {selectedSpeciality && <FilterTag label={selectedSpeciality?.label} onDelete={() => handleChangeSpeciality(null)} />}
              {selectedExpretise && <FilterTag label={selectedExpretise?.label} onDelete={() => handleChangeExpertise(null)} />}
              {selectedInstitution && <FilterTag label={selectedInstitution?.name} onDelete={() => handleChangeInstitution(null)} />}
              <Typography className="selectList-page-clear-filter-flag" onClick={emptyFiltrationObject}>{t("clear_all_filter")}</Typography>
            </Box>
          }

          <Scrollbar>
            <InfiniteScroll
              dataLength={list.length}
              next={fetchLists}
              hasMore={hasMore.current}
              loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
            >
              {isDesktop &&
                <Table>
                  <TableHead>
                    <TableRow >
                      <TableCell className='selectList-table-titles'>{t("name")}</TableCell>
                      <TableCell className='selectList-table-titles'>{t("type")}</TableCell>
                      <TableCell className='selectList-table-titles'>{t("institution")}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {list && list.map((row) => {
                      return (
                        <StyledTableRow key={row.id} onClick={() => navigate(`/dashboard/selectLists/read/${row.selectListId}`)}>
                          <TableCell className="new-table-cell">{row?.label}</TableCell>
                          <TableCell className="new-table-cell">{t(row?.select_list.select_type)}</TableCell>
                          <TableCell className="new-table-cell">{row?.select_list.institution?.name}</TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }

              {!isDesktop &&
                <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'}>
                  {list && list.map((row) => (
                    <Grid item xs={12} sm={4} key={row.id} onClick={() => navigate(`/dashboard/selectLists/read/${row.selectListId}`)}>
                      <Box className="responsive-card-details">
                        <Typography className='card-resp-part'>{row?.label}</Typography>
                        <Typography className='card-resp-part'>{t(row?.select_list.select_type)}</Typography>
                        <Typography className='card-resp-part'>{row?.select_list.institution?.name}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              }

            </InfiniteScroll>
          </Scrollbar>
        </Box>
      }

    </Page>
  );
};

export default React.memo(SelectList);