import { ImeahSelectedFilters } from '../../../utils/imeah_selected_filters';
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, Typography, LinearProgress, Button } from '@mui/material';
import TableHeaderCell from '../../../utils/imeah_table_header_cell';
import { HasPermission } from '../../../utils/checkUserPermission';
import MedicalFileListTableMode from './medicalFileListTableMode';
import searchFilterImg from '../../../images/search_filter.svg';
import MedicalFileListCardMode from './medicalFileListCardMode';
import ImeahFilterMenu from '../../../utils/imeah_filter_menu';
import useResponsive from '../../../hooks/useResponsive';
import { TransferManyFile } from '../transferManyFile';
import { FixedSizeList as List } from "react-window";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../../../css/medicalFiles/cases.css';
import { useSelector } from 'react-redux';
import { DeleteOne } from '../deleteOne';
import { socket } from '../../../App';
import { debounce } from 'lodash';

export const MedicalFileList = ({
  dialogTransferAllMedicalFiles, setDialogTransferAllMedicalFiles, orderByName, orderByUpdateDate, loading, files, fetchFiles, handleSortByName,
  handleSortByUpdateDate, setFiles, medicalFilesFilteredCount, setMedicalFilesFilteredCount, medicalFilesTotalCount, setMedicalFilesTotalCount,
  handleChangeSpeciality, selectedSpeciality, selectedDoctor, handleChangeDoctor, selectedSharedDoctor, handleChangeSharedDoctor, filtersMenu,
  selectedStatus, handleChangeStatus, searchName, handleChangeSearchName, handleChangeGlobalSearch,
}) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useResponsive('down', 'sm');
  const userData = useSelector(state => state.userData);
  const currentInstitution = useSelector(state => state.currentInstitution);

  const isAllowedDeleteMyMedicalFile = HasPermission('delete_my_Medical_File');
  const isAllowedDeleteAllMedicalFile = HasPermission('delete_All_Medical_File');
  const isAllowedDeleteInstitutionMedicalFile = HasPermission('delete_institution_Medical_File');

  const [medicalFilesFetched, setMedicalFilesFetched] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [id, setId] = useState('');
  const prevScrollTop = useRef(0);
  const listRef = useRef(null);
  const tableHeadRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [offset, setOffset] = useState(null);

  const [filesColumnWidth, setFilesColumnWidth] = useState(
    JSON.parse(localStorage.getItem('filesColumnWidth')) ||
    {
      name: 250,
      specialityExpertise: 250,
      requerant: 250,
      requis: 250,
      status: 250,
      lastChanges: 250,
      actions: 150,
    }
  );

  // fetch medical files when a new file is imported
  const useFetchFiles = (fetchFiles) => {
    useEffect(() => {
      const fetchFilesHandler = () => fetchFiles();
      socket.on('importMedicalFile', (importMedicalFile) => {
        if (importMedicalFile.message === "Medical file imported successfully" || importMedicalFile.message === "Fichier médical importé avec succès") {
          fetchFilesHandler();
        }
      });
      return () => {
        socket.off('importMedicalFile', fetchFilesHandler);
      };
    }, [fetchFiles]);
  };

  const handleFilterIconClick = (event, filterKey) => {
    setAnchorEl(event.currentTarget);
    setCurrentFilter(filterKey);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
    setCurrentFilter(null);
  };

  // synchronize horizontal scroll between table head and list
  useEffect(() => {
    // Ensure both tableHeadRef and listRef.current with _outerRef are available
    const tableHeadElement = tableHeadRef.current;
    if (!tableHeadElement || !listRef.current || !listRef.current._outerRef) {
      return;
    }
    const listElement = listRef.current._outerRef;

    const handleTableHeadScroll = () => {
      listElement.scrollLeft = tableHeadElement.scrollLeft;
    };

    const handleListScroll = () => {
      tableHeadElement.scrollLeft = listElement.scrollLeft;
    };

    tableHeadElement.addEventListener('scroll', handleTableHeadScroll);
    listElement.addEventListener('scroll', handleListScroll);

    return () => {
      tableHeadElement.removeEventListener('scroll', handleTableHeadScroll);
      listElement.removeEventListener('scroll', handleListScroll);
    };
  }, [listRef.current?._outerRef, tableHeadRef, isMobile, files, loading]);


  useEffect(() => {
    // Scroll to the top and reset horizontal scroll when filters change
    if (listRef.current) {
      listRef.current.scrollTo(0);
    }
  }, []);

  useEffect(() => {
    // Calculate the number of fetched items depending on the screen height when the component mounts
    const calculatedValue = (!isMobile && window.innerHeight < 600)
      ? 3
      : (offset + Math.floor(Math.round(window.innerHeight - (isMobile ? 305 : 410)) / (isMobile ? 340 : 80))) + 1;

    // Ensure the calculated value does not exceed medicalFilesCount
    const finalValue = Math.min(calculatedValue, medicalFilesFilteredCount);
    setMedicalFilesFetched(finalValue);
  }, [isMobile, medicalFilesFilteredCount, offset]);


  useFetchFiles(fetchFiles);

  const handleOpenDelete = useCallback((id) => {
    setId(id);
    setOpenDelete(true);
  }, []);

  const resetFilters = () => {
    handleChangeSpeciality(null);
    handleChangeDoctor(null);
    handleChangeSharedDoctor(null);
    handleChangeStatus(null);
    handleChangeSearchName('');
    handleChangeGlobalSearch('');
  }

  const handleScrollTable = debounce(({ scrollOffset }) => {
    // Check if the scroll is vertical (scrollTop has changed)
    if (scrollOffset !== prevScrollTop.current) {
      // Calculate the new offset based on the scroll position and the item height size (80)
      const newOffset = Math.floor(scrollOffset / 80);

      // in order to pass offset value to the deleteOne component
      setOffset(newOffset)
      fetchFiles(newOffset);
      prevScrollTop.current = scrollOffset;
      // Set the number of fetched items depending on the screen height
      const newFetchedValue = (window.innerHeight < 600) ? newOffset + 3 : (newOffset + Math.floor((window.innerHeight - 410) / 70)) + 1;
      const finalFetchedValue = Math.min(newFetchedValue, medicalFilesTotalCount);
      setMedicalFilesFetched(finalFetchedValue);
    }
  }, 300);

  const handleScrollCard = debounce(({ scrollOffset }) => {
    // Check if the scroll is vertical (scrollTop has changed)
    if (scrollOffset !== prevScrollTop.current) {
      // Calculate the new offset based on the scroll position and the item height size (340)
      const newOffset = Math.floor(scrollOffset / 340);
      fetchFiles(newOffset);
      // in order to pass offset value to the deleteOne component
      setOffset(newOffset)
      prevScrollTop.current = scrollOffset;
      // Set the number of fetched items depending on the screen height
      const newFetchedValue = newOffset + (Math.floor(Math.round(window.innerHeight - 305) / 340)) + 1;
      const finalFetchedValue = Math.min(newFetchedValue, medicalFilesTotalCount);
      setMedicalFilesFetched(finalFetchedValue);
    }
  }, 300);

  const Row = ({ index, style, data }) => {
    const { files, navigate, currentInstitution, isAllowedDeleteAllMedicalFile, isAllowedDeleteInstitutionMedicalFile, isAllowedDeleteMyMedicalFile, handleOpenDelete, t, userData } = data;

    if (files.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', marginTop: '10%' }}>
          <img src={searchFilterImg} alt="search filter" style={{ margin: 'auto' }} />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={'10px'}
          >
            <Typography className="imeahTitle" sx={{ textAlign: 'center' }}>{t('noMedicalFileLengthTitle')}</Typography>
            <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '400' }}>{t('clearFilter')}</Typography>
            <Button
              sx={{
                padding: '12px 18px',
                backgroundColor: '#16536B',
                color: 'white',
                width: '180px',
                borderRadius: '15px',
                '&:hover': {
                  opacity: 0.9,
                  backgroundColor: '#16536B',
                },
              }}
              onClick={resetFilters}
            >
              {t('clear_all_filter')}
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <Box key={index} style={{ ...style, width: 'fit-content' }}>
        <MedicalFileListTableMode
          index={index}
          filesColumnWidth={filesColumnWidth}
          data={{
            files,
            navigate,
            currentInstitution,
            isAllowedDeleteAllMedicalFile,
            isAllowedDeleteInstitutionMedicalFile,
            isAllowedDeleteMyMedicalFile,
            handleOpenDelete,
            t,
            userData
          }}
        />
      </Box>
    );
  };

  const onColumnWidthChange = (value, columnKey) => {
    setFilesColumnWidth((prev) => ({
      ...prev,
      [columnKey]: value,
    }));
    localStorage.setItem('filesColumnWidth', JSON.stringify({
      ...filesColumnWidth,
      [columnKey]: value,
    }));
  };

  return (
    <Box>
      <Box>

        {!isMobile &&
          <ImeahSelectedFilters
            selectedSpeciality={selectedSpeciality}
            handleChangeSpeciality={handleChangeSpeciality}
            selectedDoctor={selectedDoctor}
            handleChangeDoctor={handleChangeDoctor}
            selectedSharedDoctor={selectedSharedDoctor}
            handleChangeSharedDoctor={handleChangeSharedDoctor}
            selectedStatus={selectedStatus}
            handleChangeStatus={handleChangeStatus}
            searchName={searchName}
            handleChangeSearchName={handleChangeSearchName}
          />
        }

        <ImeahFilterMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseFilterMenu}
          options={currentFilter ? filtersMenu[currentFilter].options : []}
          handleChange={currentFilter ? filtersMenu[currentFilter].handleChange : () => { }}
          isMobile={isMobile}
          currentFilter={currentFilter}
        />

        {!isMobile ? (
          <Box className='table'>
            <Box className='tableHead' ref={tableHeadRef}>
              <TableHeaderCell
                columnKey="name"
                label={t('Patient_name')}
                columnWidth={filesColumnWidth.name}
                orderActive={true}
                orderBy={orderByName}
                handleSort={handleSortByName}
                handleFilterIconClick={handleFilterIconClick}
                filterActive={true}
                filterInvisible={!searchName}
                filterKey="name"
                onColumnWidthChange={onColumnWidthChange}
              />
              <TableHeaderCell
                columnKey="specialityExpertise"
                label={t('specialityExpertise')}
                columnWidth={filesColumnWidth.specialityExpertise}
                orderBy={null}
                handleSort={null}
                handleFilterIconClick={handleFilterIconClick}
                filterActive={true}
                filterInvisible={!selectedSpeciality}
                filterKey="specialityExpertise"
                onColumnWidthChange={onColumnWidthChange}
              />
              <TableHeaderCell
                columnKey="requerant"
                columnWidth={filesColumnWidth.requerant}
                label={t('requerant')}
                orderBy={null}
                handleSort={null}
                handleFilterIconClick={handleFilterIconClick}
                filterActive={true}
                filterInvisible={!selectedDoctor}
                filterKey="requerant"
                onColumnWidthChange={onColumnWidthChange}
              />
              <TableHeaderCell
                columnKey="requis"
                columnWidth={filesColumnWidth.requis}
                label={t('requis')}
                orderBy={null}
                handleSort={null}
                handleFilterIconClick={handleFilterIconClick}
                filterActive={true}
                filterInvisible={!selectedSharedDoctor}
                filterKey="requis"
                onColumnWidthChange={onColumnWidthChange}
              />
              <TableHeaderCell
                columnKey="status"
                columnWidth={filesColumnWidth.status}
                label={t('Status')}
                orderBy={null}
                handleSort={null}
                handleFilterIconClick={handleFilterIconClick}
                filterActive={true}
                filterInvisible={!selectedStatus}
                filterKey="status"
                onColumnWidthChange={onColumnWidthChange}
              />
              <TableHeaderCell
                columnKey="lastChanges"
                columnWidth={filesColumnWidth.lastChanges}
                label={t('lastChanges')}
                orderActive={true}
                orderBy={orderByUpdateDate}
                handleSort={handleSortByUpdateDate}
                handleFilterIconClick={handleFilterIconClick}
                filterActive={false}
                filterInvisible={false}
                filterKey="lastChanges"
                onColumnWidthChange={onColumnWidthChange}
              />
              <TableHeaderCell
                columnKey="actions"
                columnWidth={filesColumnWidth.actions}
                label={t('Action')}
                onColumnWidthChange={onColumnWidthChange}
              />
            </Box>
            {loading ?
              <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} />
              :
              <Box className='tableBody'>
                <List
                  className='react_window_list'
                  ref={listRef}
                  height={(window.innerHeight < 600) ? 200 : window.innerHeight - 410}
                  width='100%'
                  itemCount={medicalFilesFilteredCount === 0 ? 1 : medicalFilesFilteredCount}
                  itemSize={80}
                  itemData={{
                    files,
                    navigate,
                    currentInstitution,
                    isAllowedDeleteAllMedicalFile,
                    isAllowedDeleteInstitutionMedicalFile,
                    isAllowedDeleteMyMedicalFile,
                    handleOpenDelete,
                    t,
                    userData
                  }}
                  onScroll={handleScrollTable}
                >
                  {Row}
                </List>
              </Box>
            }
          </Box>


        ) : (
          loading ?
            <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} />
            :
            files.length === 0 ?
              <Box sx={{ textAlign: 'center', marginTop: '10%', height: window.innerHeight - 340 }}>
                <img src={searchFilterImg} alt="search filter" style={{ margin: 'auto' }} />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={'10px'}
                >
                  <Typography className="imeahTitle" sx={{ textAlign: 'center' }}>{t('noMedicalFileLengthTitle')}</Typography>
                  <Typography sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '400' }}>{t('clearFilter')}</Typography>
                  <Button
                    sx={{
                      padding: '12px 18px',
                      backgroundColor: '#16536B',
                      color: 'white',
                      width: '180px',
                      borderRadius: '15px',
                      '&:hover': {
                        opacity: 0.9,
                        backgroundColor: '#16536B',
                      },
                    }}
                    onClick={resetFilters}
                  >
                    {t('clear_all_filter')}
                  </Button>
                </Box>
              </Box>
              :
              <List
                height={window.innerHeight - 305}
                onScroll={handleScrollCard}
                itemCount={medicalFilesFilteredCount}
                itemSize={340}
                width="100%"
                itemData={{
                  files,
                  navigate,
                  currentInstitution,
                  isAllowedDeleteAllMedicalFile,
                  isAllowedDeleteInstitutionMedicalFile,
                  isAllowedDeleteMyMedicalFile,
                  handleOpenDelete,
                  t,
                  userData
                }}
              >
                {MedicalFileListCardMode}
              </List>
        )}
        {!loading &&
          <Box className="mdf-list-files-fetched-Box">
            <Typography className="mdf-list-files-fetched-nbr">
              {`${medicalFilesFetched}/${medicalFilesTotalCount}`}
            </Typography>
            <Typography className="mdf-list-files-fetched-label">{t('Files')}</Typography>
          </Box>
        }
      </Box>


      {openDelete && (
        <DeleteOne
          id={id}
          files={files}
          setFiles={setFiles}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          setMedicalFilesTotalCount={setMedicalFilesTotalCount}
          setMedicalFilesFilteredCount={setMedicalFilesFilteredCount}
          offset={offset}
        />
      )}

      {dialogTransferAllMedicalFiles && (
        <TransferManyFile
          files={files}
          setFiles={setFiles}
          dialogTransferAllMedicalFiles={dialogTransferAllMedicalFiles}
          setDialogTransferAllMedicalFiles={setDialogTransferAllMedicalFiles}
        />
      )}
    </Box>
  );
};