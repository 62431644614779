import { Typography, TextField, Box } from '@mui/material';

export const NumberInList = ({ info, item, allowedActions, debouncedHandleUpdateOneListFieldData }) => {

    return (

        <Box display={'flex'} gap={'12px'} flexDirection={'column'}>

            <Typography className='medicalFileFields_listField_label' >{item.ItemLabel}</Typography>

            <TextField
                className='customTextField'
                id={item.id}
                name={item.ItemLabel}
                type="number"
                disabled={!allowedActions.canEditPrivateMedicalFile}
                defaultValue={typeof item.ItemValue === 'string' ? item.ItemValue : ''}
                onChange={event => debouncedHandleUpdateOneListFieldData(info.id, item.id, event.target.value)}
            />

        </Box>
    );
};