import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const DeleteMeeting = ({ id, setOpenDelete, openDelete }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handledelete = () => {

        axios.delete(`video_stream_request/${id}`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    navigate('/dashboard/MeetingRequests');
                    setOpenDelete(false)
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <Dialog open={openDelete} keepMounted onClose={() => setOpenDelete(false)}>
            <DialogTitle>{t('deleteMeetingDialogTitle')}</DialogTitle>
            <DialogActions>
                <Button fullWidth className='new-button' onClick={() => setOpenDelete(false)}>{t('Cancel')}</Button>
                <Button fullWidth className='new-button' onClick={handledelete}>{t('agree')}</Button>
            </DialogActions>
        </Dialog>
    );
};