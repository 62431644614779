import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, Typography } from '@mui/material';
import { smartDateTime } from '../../../utils/formatTime';
import ImeahActionIcon from '../../../utils/imeah_action_icon';

const MedicalFileListTableMode = React.memo(({ index, data, filesColumnWidth }) => {
  const { files, navigate, currentInstitution, isAllowedDeleteAllMedicalFile, isAllowedDeleteInstitutionMedicalFile, isAllowedDeleteMyMedicalFile, handleOpenDelete, t, userData } = data;
  const file = files[index];

  // Render an empty row if the file is not yet loaded
  if (!file) {
    return (
      <Box className="tableRow">
        <Box className="tableBodyCell" style={{ minWidth: filesColumnWidth.name, position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}> <Skeleton variant="text" width="100%" /></Box>
        <Box className="tableBodyCell" style={{ minWidth: filesColumnWidth.specialityExpertise }}> <Skeleton variant="text" width="100%" /></Box>
        <Box className="tableBodyCell" style={{ minWidth: filesColumnWidth.requerant }}> <Skeleton variant="text" width="100%" /></Box>
        <Box className="tableBodyCell" style={{ minWidth: filesColumnWidth.requis }}> <Skeleton variant="text" width="100%" /></Box>
        <Box className="tableBodyCell" style={{ minWidth: filesColumnWidth.status }}> <Skeleton variant="text" width="100%" /></Box>
        <Box className="tableBodyCell" style={{ minWidth: filesColumnWidth.lastChanges }}> <Skeleton variant="text" width="100%" /></Box>
        <Box className="tableBodyCell" style={{ minWidth: filesColumnWidth.actions }}> <Skeleton variant="text" width="100%" /></Box>
      </Box>
    );
  }

  return (
    <Box
      key={file.id}
      className="tableRow"
      onClick={() => navigate(`/dashboard/medicalFiles/${file.id}`)}
    >
      <Box
        className="tableBodyCell"
        style={{
          left: 0,
          zIndex: 1,
          position: 'sticky',
          backgroundColor: 'white',
          textTransform: "capitalize",
          minWidth: filesColumnWidth.name,
          maxWidth: filesColumnWidth.name,

        }}
      >
        <Typography
          className='tableBodyTypography'
          sx={{ fontWeight: 'bold' }}
        >
          {file.first_name.toLowerCase()} {file.last_name.toLowerCase()}
        </Typography>
      </Box>

      <Box
        className="tableBodyCell"
        style={{ minWidth: filesColumnWidth.specialityExpertise, maxWidth: filesColumnWidth.specialityExpertise }}
      >
        <Typography
          className='tableBodyTypography'
        >
          {file.specialityID !== 'NoSpeciality' ? file.speciality?.specialityLabel[0]?.label : file.expertise?.expertiseLabel[0]?.label}
        </Typography>
      </Box>

      <Box
        className="tableBodyCell"
        sx={{ display: 'flex', alignItems: 'center', gap: '5px', minWidth: filesColumnWidth.requerant, maxWidth: filesColumnWidth.requerant }}
      >
        <Typography
          className='imeah_persone_name tableBodyTypography'
        >
          {file.doctor?.user?.firstname.toLowerCase()} {file.doctor?.user?.lastname.toLowerCase()}
        </Typography>
        {file.institutionId !== currentInstitution.institution.id ?
          <Typography className="tableBodyTypography">({file?.doctor?.institution?.name})</Typography>
          : null}
      </Box>

      <Box
        className="tableBodyCell"
        style={{ minWidth: filesColumnWidth.requis, maxWidth: filesColumnWidth.requis, display: 'flex', alignItems: 'center', gap: '5px' }}
      >
        <Typography
          className='imeah_persone_name tableBodyTypography'
        >
          {file?.targetDoctorForShare.user?.firstname.toLowerCase()} {file.targetDoctorForShare?.user?.lastname.toLowerCase()}
        </Typography>
        {file?.targetDoctorForShare?.institution?.name !== currentInstitution.institution.name ?
          <Typography className="tableBodyTypography">({file.targetDoctorForShare?.institution?.name})</Typography>
          : null}
      </Box>

      <Box
        className="tableBodyCell"
        style={{ minWidth: filesColumnWidth.status, maxWidth: filesColumnWidth.status, display: 'flex' }}
      >
        <Typography
          className="tableBodyTypography"
          sx={{
            minWidth: filesColumnWidth.status - 20, maxWidth: filesColumnWidth.status - 20,
            padding: 'var(--Space-Extra-Small, 8px) 48px var(--Space-Extra-Small, 8px) 24px',
            borderRadius: '12px',
            backgroundColor: file.status === 'Waiting' ? '#FFE67866' : file.status === 'Answered' ? '#88FF5166' : file.status === 'Shared' ? '#ABBFF066' : '#B3B2B966',
            border: file.status === 'Waiting' ? '1px solid #C2A41D' : file.status === 'Answered' ? '1px solid #4FA826' : file.status === 'Shared' ? '1px solid #264BA8' : '1px solid #727179',
            color: file.status === 'Waiting' ? '#C2A41D' : file.status === 'Answered' ? '#4FA826' : file.status === 'Shared' ? '#264BA8' : '#727179',
          }}
        >
          {t(file.status)}
        </Typography>

      </Box>

      <Box
        className="tableBodyCell"
        style={{ minWidth: filesColumnWidth.lastChanges, maxWidth: filesColumnWidth.lastChanges }}
      >
        <Typography
          className="tableBodyTypography"
        >
          {smartDateTime(file.updated_at, userData.local_code)}
        </Typography>
      </Box>

      <Box
        className="tableBodyCell"
        style={{ minWidth: filesColumnWidth.actions, maxWidth: filesColumnWidth.actions }}
        onClick={(e) => e.stopPropagation()}
      >
        {(isAllowedDeleteAllMedicalFile || (isAllowedDeleteInstitutionMedicalFile && file.institutionId === currentInstitution.institution.id) || (isAllowedDeleteMyMedicalFile && file.doctorId === currentInstitution.id)) &&
          <ImeahActionIcon icon="ic:round-delete" color='#727179' onClick={() => handleOpenDelete(file.id)} />
        }
      </Box>
    </Box>
  );
});

export default MedicalFileListTableMode; 