import { Divider, Grid, Box, Card, Typography, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import { customInputStyles } from '../../utils/CustomInputStyle'
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';
import '../../css/templates/MedicalFileTemplatesFiltration.css';

export const SelectListFiltration = (props) => {

    const { t } = useTranslation();
    const { emptyFiltrationObject, handleChangeSpeciality, handleChangeExpertise, handleChangeInstitution, setOpenFilter, isAllowedReadAllSelectList, selectedInstitution,
        institutions, specialities, expertises, selectedSpeciality, selectedExpretise } = props

    const ActionButton = ({ icon, title, onClick }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
            <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
        </Box>
    );

    return (
        <Box className='template-filtration-container'>
            <Card className='template-filtration-card' >
                <Box className='template-filtration-header-box'>
                    <Typography className='template-filtration-header-title'>{t("Filter")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={emptyFiltrationObject} />
                        <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                    </Box>                </Box>
                <Divider />
                <Grid container spacing={2} marginTop={'5px'}>

                    {(isAllowedReadAllSelectList && institutions?.length > 1) &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                <InputLabel id="demo-simple-select-label">{t("institution")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="selectedExpretise"
                                    label={t("institution")}
                                    name="selectedInstitution"
                                    value={selectedInstitution || ""}
                                    onChange={(e) => handleChangeInstitution(e.target.value)}
                                    endAdornment={
                                        selectedInstitution && (
                                            <InputAdornment position="start">
                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeInstitution(null)} />
                                            </InputAdornment>
                                        )
                                    }
                                >
                                    {institutions && institutions.map((institution) => (
                                        <MenuItem key={institution.id} value={institution}>
                                            {institution.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>}

                    {(specialities?.length > 0) &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                <InputLabel id="demo-simple-select-speciality-label">{t("speciality")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-speciality-label"
                                    id="selectedSpeciality"
                                    label={t("speciality")}
                                    name="selectedSpeciality"
                                    value={selectedSpeciality || ""}
                                    onChange={(e) => handleChangeSpeciality(e.target.value)}
                                    endAdornment={
                                        selectedSpeciality && (
                                            <InputAdornment position="start">
                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeSpeciality(null)} />
                                            </InputAdornment>
                                        )
                                    }
                                >
                                    {specialities && specialities.map((Speciality) => (
                                        <MenuItem key={Speciality.id} value={Speciality}>
                                            {Speciality.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    {(expertises?.length > 0) &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                <InputLabel id="emo-simple-select-expertise-label">{t("expertise")}</InputLabel>
                                <Select
                                    labelId="emo-simple-select-expertise-label"
                                    id="selectedExpretise"
                                    label={t("expertise")}
                                    name="selectedExpretise"
                                    value={selectedExpretise || ""}
                                    onChange={(e) => handleChangeExpertise(e.target.value)}
                                    endAdornment={
                                        selectedExpretise && (
                                            <InputAdornment position="start">
                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeExpertise(null)} />
                                            </InputAdornment>
                                        )
                                    }
                                >
                                    {expertises && expertises.map((Expertise) => (
                                        <MenuItem key={Expertise.id} value={Expertise}>
                                            {Expertise.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>}

                </Grid>
            </Card>
        </Box>
    );
};