import { Alert, Typography, Box, Button, TextField, LinearProgress, Stack, Grid, Switch, Autocomplete, MenuItem, FormControl } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState, useEffect, useCallback } from "react";
import { HasPermission } from "../../utils/checkUserPermission";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ImeahPhoneInput } from "../../utils/imeah_phone_input";
import { StyledFieldset } from "../../utils/styledFieldSet";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate, useParams } from "react-router-dom";
import { MuiSelect } from '../../utils/muiSelect';
import { useTranslation } from "react-i18next";
import { act } from '@testing-library/react';
import { LoadingButton } from "@mui/lab";
import Label from "../Label";
import Page from '../Page';
import * as Yup from 'yup';
import axios from "axios";
import '../../css/institutions/editInstitution.css';

export const EditInstitution = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const isAllowedEditAllInstitution = HasPermission('Edit_All_Institutions');

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('');
  const [services, setServices] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [oneService, setOneService] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allowVisio, setAllowVisio] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [mfa, setMfa] = useState("nothing");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isAllowedEditRelatedInstitution, setIisAllowedEditRelatedInstitution] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t("institutionNameRequired")),
    typeId: Yup.string().required(t("institutionTypeRequired")),
    phone: Yup.string().trim().matches(/^\+?[0-9]+$/, t('PhoneNumberOnlyDigits')).required('PhoneNumberRequired').min(8, t('PhoneNumberMinLength')),
    address: Yup.string().trim().required(t("institutionAddressRequired")),
    city: Yup.string().trim().required(t("institutionCityRequired")),
    countryName: Yup.string().required(t("countryRequired")),
    twoFactorVerification: Yup.boolean(),
    browserVerification: Yup.boolean(),
    is_active: Yup.boolean(),
    allowVisio: Yup.boolean(),
  });

  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const response = await axios.get(`institutions/${id}`);
        const { name, address, city, phone, institutionType, services, allowVisio, is_active, twoFactorVerification, browserVerification, countryName } = response.data.institutionData;
        act(() => {
          setPhone(phone)
          setAddress(address)
          setName(name)
          setCity(city)
          setType({ value: institutionType.id, label: institutionType.institutions_types_Label[0].label })
          setServices(services)
          setAllowVisio(allowVisio)
          setIsActive(is_active)
          setMfa(twoFactorVerification ? 'institutionMFA' : browserVerification ? 'browserMFA' : 'nothing');
          setSelectedCountry(countryName)
          setPageLoading(false)
          setIisAllowedEditRelatedInstitution(response.data.canEditInstitution)
        })
      } catch (error) {
        console.error("Error fetching institution data:", error);
      }
    };

    fetchInstitution();
  }, [id]);

  const fetchCountries = async () => {
    try {
      const { status, data } = await axios.get("institutions/getCountries");
      if ((status === 200 || status === 201) && Array.isArray(data)) {
        setCountries(data.map((country) => country?.name));
      }
    } catch (error) {
      console.log("Error fetching countries:", error);
    }
  };

  const fetchInstitutionsTypes = useCallback(async (page) => {
    try {
      const limit = 10;
      const offset = page * limit;
      const response = await axios.get('institutions-types', { params: { offset, limit } });
      return response.data?.map(record => ({ value: record?.institutionTypeId, label: record?.label }));
    } catch (error) {
      console.error('Error fetching Institutions Types:', error);
      return [];
    }
  }, []);

  const handleEdit = async (event) => {
    event.preventDefault();

    const bodyData = {
      name: name.trim(),
      city,
      address,
      phone,
      typeId: type?.value,
      services,
      allowVisio,
      is_active: isActive,
      twoFactorVerification: mfa === "institutionMFA",
      browserVerification: mfa === "browserMFA",
      countryName: selectedCountry
    };
    console.log('bodyData', bodyData);

    try {

      await validationSchema.validate(bodyData, { abortEarly: false });

      setIsLoading(true);

      const response = await axios.patch(`institutions/${id}`, bodyData);
      if (response.status === 200 || response.status === 201) {
        navigate('/dashboard/hospitals');
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrorMsg(error?.errors?.join(' , '));
      } else {
        setErrorMsg(error.message);
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleOnDragEnd = useCallback((result) => {
    if (!result.destination) return;

    setServices((prevServices) => {
      const items = Array.from(prevServices);
      const [movedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, movedItem);
      return items;
    });
  }, []);

  const handlePushValue = useCallback(() => {
    if (services.includes(oneService) || oneService.trim() === "") {
      setErrorMsg(t('attributeNotAccepted'));
      return;
    }
    setServices((prevServices) => [...prevServices, oneService]);
    setOneService("");
    setErrorMsg(null);
  }, [oneService, services, t]);

  const handleRemoveValue = useCallback((item) => {
    setServices((prevServices) => prevServices.filter((service) => service !== item));
  }, []);

  return (
    <Page title={t("editInstitution")}>
      {pageLoading ? <LinearProgress /> :
        <Box >
          <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
            <ArrowBackIosIcon className='editInstituion-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
            <Stack className="editInstituion-main-page-header">
              <Box className="editInstituion-page-title-box">
                <Typography className='editInstituion-page-title'>{t("editInstitution")}</Typography>
              </Box>
            </Stack>
          </Box>

          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

          <Grid container spacing={2} mt={1}>

            <Grid item xs={12} sm={6}>
              <TextField
                id="edit_institution_name"
                name="name"
                className='customTextField'
                label={t("name")}
                required
                type="text"
                fullWidth
                onChange={(event) => setName(event.target.value)}
                value={name}
                error={!name}
                helperText={!name && t("isRequired")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <MuiSelect
                required={true}
                label={t('type')}
                value={type}
                onChange={(event) => setType(event.target)}
                fetchFunction={fetchInstitutionsTypes}
                searchable={false}
                className='customTextField'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ImeahPhoneInput
                name="phone"
                required={true}
                value={phone}
                label={t('Enter_phone_number')}
                onChange={(event) => setPhone(event)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="edit_institution_address"
                name="address"
                className='customTextField'
                label={t("institutionAddress")}
                required
                type="text"
                fullWidth
                onChange={(event) => setAddress(event.target.value)}
                value={address}
                error={!address}
                helperText={!address && t("isRequired")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="edit_institution_city"
                name="city"
                className='customTextField'
                label={t("institutionCity")}
                required
                type="text"
                fullWidth
                onChange={(event) => setCity(event.target.value)}
                value={city}
                error={!city}
                helperText={!city && t("isRequired")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="dense">
                <Autocomplete
                  id="edit_institution_countries"
                  name="countries"
                  options={countries}
                  onFocus={fetchCountries}
                  value={selectedCountry}
                  getOptionLabel={(option) => t(option)}
                  onChange={(event, newValue) => setSelectedCountry(newValue)}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={t('country')}
                      className='customTextField'
                      error={!selectedCountry}
                      helperText={!selectedCountry && t("isRequired")}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="edit_institution_twoFactorVerification"
                name="twoFactorVerification"
                className='customTextField'
                label={t('twoFactorVerification')}
                fullWidth
                select
                value={mfa}
                onChange={(event) => setMfa(event.target.value)}
                disabled={(!isAllowedEditAllInstitution && !isAllowedEditRelatedInstitution)}
              >
                <MenuItem key={'nothing'} value={'nothing'}>{t('nothing')}</MenuItem>
                <MenuItem key={'browserMFA'} value={'browserMFA'}>{t('browserMFA')}</MenuItem>
                <MenuItem key={'institutionMFA'} value={'institutionMFA'}>{t('institutionMFA')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: 'center', gap: '5px' }}>
              <TextField
                id="edit_institution_services"
                className='customTextField'
                name="institutionServices"
                value={oneService}
                label={t("addNewService")}
                type="text"
                fullWidth
                onChange={(e) => setOneService(e.target.value)}
              />
              <AddCircleIcon
                data-testid="add-value"
                className="circle-add-icon"
                onClick={handlePushValue}
              />
            </Grid>
          </Grid>

          {services?.length > 0 &&
            <StyledFieldset component="fieldset" sx={{ marginTop: '10px' }}>
              <legend>{t('services')}</legend>
              <Box className='editInstituion-servise-legend'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="values" direction="horizontal">
                    {(provided) => (
                      <Box {...provided.droppableProps} ref={provided.innerRef} className='editInstituion-servise-box'>
                        {services && services.map((item, index) => (
                          <Draggable key={item} draggableId={item} index={index}>
                            {(provided) => (
                              <Typography {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} data-testid="draggable-services">
                                <Label>{item}</Label>
                                <CloseRoundedIcon onClick={() => handleRemoveValue(item)} sx={{ cursor: 'pointer' }} />
                              </Typography>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </StyledFieldset>
          }

          <Box className="editInstituion_switch_box">

            <Box className="editInstituion_switch_label_box">
              <Typography>{t("ActiveInstitution")}</Typography>
              <Switch
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
                size="small"
                className="imeahSwitch"
                disabled={!isAllowedEditAllInstitution}
              />
            </Box>

            <Box className="editInstituion_switch_label_box">
              <Typography>{t("allowVisio")}</Typography>
              <Switch
                checked={allowVisio}
                onChange={() => setAllowVisio(!allowVisio)}
                size="small"
                className="imeahSwitch"
                disabled={!isAllowedEditAllInstitution}
              />
            </Box>

          </Box>

          <Box className="editInstituion-actions-box">
            <Button className='buttonOutlined' onClick={() => navigate(-1)}>{t("cancel")}</Button>
            <LoadingButton loading={isLoading} className='buttonFilled' onClick={handleEdit}>{t("save")}</LoadingButton>
          </Box>

        </Box>
      }
    </Page >
  );
};