import { Button, TextField, Box, Typography, Alert, Stack, Grid } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MuiMultiSelect } from "../../../utils/muiMultiSelect";
import { useNavigate, useLocation } from "react-router-dom";
import { MuiSelect } from "../../../utils/muiSelect";
import { MuiPhone } from "../../../utils/muiPhone";
import { useTranslation } from "react-i18next";
import '../../../css/User-css/editUser.css';
import React, { useState } from "react";
import * as Yup from 'yup';
import axios from "axios";

export const Editprofile = () => {
 
    const { t } = useTranslation();
    const navigate = useNavigate();

    const InstitutionUserData = useLocation().state?.InstitutionUserData;
    const [userInfo, setUserInfo] = useState(useLocation().state.userInfo);
    const [userRole, setUserRole] = useState({ value: InstitutionUserData.role?.id, label: InstitutionUserData.role?.roleLabel[0].label, canHaveSpeciality: InstitutionUserData.role?.canHaveSpeciality, canHaveExpertise: InstitutionUserData.role?.canHaveExpertise });
    const [UserSpeciality, setUserSpeciality] = useState({ value: InstitutionUserData.specialities?.id, label: InstitutionUserData.specialities?.specialityLabel[0].label });
    const [UserExpertises, setUserExpertises] = useState(InstitutionUserData.institutionUserExpertise?.map((expertise) => ({ value: expertise.expertise.expertiseLabel[0].expertiseId, label: expertise.expertise.expertiseLabel[0].label })));
    const [msg, setMsg] = useState("");

    const validationuserData = Yup.object().shape({
        firstname: Yup.string().trim().required(),
        lastname: Yup.string().trim().required(),
        email: Yup.string().email().trim().required(),
    });

    const checkUserValidation = validationuserData.isValidSync({
        firstname: userInfo?.firstname,
        lastname: userInfo?.lastname,
        email: userInfo?.email,
        phone: userInfo?.phone
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserInfo(prevInfo => ({ ...prevInfo, [name]: value }));
    };

    const handleEdit = async () => {
        if (checkUserValidation) {
            const targetSpeciality = UserSpeciality ? UserSpeciality.value : "NoSpeciality";
            const targetExpertises = UserExpertises?.map((expertise) => expertise.value) || [];

            const userData = {
                institutionUserId: InstitutionUserData.id,
                institutionId: InstitutionUserData.institutionId,
                roleId: userRole.value,
                expertises: targetExpertises,
                specialityId: targetSpeciality || 'NoSpeciality',
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                email: userInfo.email,
                phone: userInfo.phone
            };

            try {
                const response = await axios.put(`users/updateUserprofile/${InstitutionUserData.user?.id}`, userData);
                if (response.status === 200 || response.status === 201) {
                    navigate(-1);
                }
            } catch (error) {
                console.error(error);
                setMsg(error.response?.data?.message);
            }
        }
    };

    const fetchRoles = async (page, search) => {
        try {
            const limit = 10;
            const offset = page * limit;
            const response = await axios.get('roles', { params: { offset, limit, search, institutionId: InstitutionUserData.institutionId } });
            return response.data.map(record => ({
                value: record.roleId,
                label: record.label,
                canHaveSpeciality: record.role.canHaveSpeciality,
                canHaveExpertise: record.role.canHaveExpertise,
            }));
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const fetchSpecialities = async (page, search) => {
        try {
            const limit = 10;
            const offset = page * limit;
            const response = await axios.get('speciality', { params: { offset, limit, search: search } });

            return response.data.map(record => ({ value: record.specialityId, label: record.label }));
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    const fetchExpertises = async (page, search) => {
        try {
            const limit = 10;
            const offset = page * limit;
            const response = await axios.get('expertise', { params: { offset, limit, search: search } });
            return response.data.map(record => ({ value: record.expertiseId, label: record.label }));
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    const handleChangeRole = (selectedRole) => {
        setUserRole(selectedRole.target)
        setUserExpertises([]);
        setUserSpeciality('');
    }

    const handleSpecialityChange = (selectedOptions) => {
        setUserSpeciality(selectedOptions.target);
        setUserExpertises([])
    };

    const handleExpertiseChange = (selectedOptions) => {
        setUserExpertises(selectedOptions)
        setUserSpeciality('');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <ArrowBackIosIcon className='addNewUser-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                <Stack className="addNewUser-main-page-header">
                    <Box className="addNewUser-page-title-box">
                        <Typography className='addNewUser-page-title'>{t("editUsrInfo")}</Typography>
                    </Box>
                </Stack>
            </Box>
            {msg && <Alert severity="error">{msg}</Alert>}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        label={t('firstname')}
                        name="firstname"
                        onChange={handleChange}
                        defaultValue={InstitutionUserData.user?.firstname || ''}
                        variant="outlined"
                        error={!userInfo?.firstname}
                        helperText={!userInfo?.firstname && (t('isRequired'))}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        label={t('lastname')}
                        name="lastname"
                        onChange={handleChange}
                        defaultValue={InstitutionUserData.user?.lastname || ''}
                        variant="outlined"
                        error={!userInfo?.lastname}
                        helperText={!userInfo?.lastname && (t('isRequired'))}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>

                    <TextField
                        fullWidth
                        required
                        margin="dense"
                        label={t('email')}
                        name="email"
                        onChange={handleChange}
                        defaultValue={InstitutionUserData.user?.email || ''}
                        variant="outlined"
                        error={!userInfo?.email}
                        helperText={!userInfo?.email && (t('isRequired'))}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <MuiSelect
                        label={t('role')}
                        required={true}
                        searchable={true}
                        value={userRole}
                        fetchFunction={fetchRoles}
                        onChange={(selectedRole) => handleChangeRole(selectedRole)}
                    />
                </Grid>

                {(userRole.canHaveSpeciality) && (<Grid item xs={12} sm={6}>
                    <MuiSelect
                        key={`specialities`}
                        label={t('specialities')}
                        required={false}
                        searchable={true}
                        value={UserSpeciality || "NoSpeciality"}
                        fetchFunction={fetchSpecialities}
                        onChange={handleSpecialityChange}
                    />
                </Grid>)}


                {(userRole.canHaveExpertise) && (<Grid item xs={12} sm={6}>
                    <MuiMultiSelect
                        key={`expertises`}
                        label={t('expertises')}
                        required={false}
                        searchable={true}
                        value={UserExpertises || []}
                        fetchFunction={fetchExpertises}
                        onChange={handleExpertiseChange}
                    />
                </Grid>)}


                <Grid item xs={12} sm={6}>
                    <MuiPhone
                        required={false}
                        value={InstitutionUserData.user?.phone}
                        placeholder={t('Phone_Number')}
                        onChange={(value) => setUserInfo({ ...userInfo, phone: value })}
                    />
                </Grid>

            </Grid>

            <Box sx={{ display: 'flex', gap: '10px', alignSelf: 'flex-end', marginTop: '10px' }}>
                <Button className='new-button' onClick={() => navigate(-1)} >{t('cancel')}</Button>
                <Button className='new-button' onClick={handleEdit} disabled={!checkUserValidation}>{t('save')}</Button>
            </Box>
        </Box >
    )
}