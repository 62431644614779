import { Table, TableHead, TableRow, TableBody, TableCell, useMediaQuery, Chip, Box, MenuItem, FormControl, InputAdornment, InputLabel, Select, LinearProgress, Typography, Divider, Grid, Card } from '@mui/material';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { customInputStyles } from '../../utils/CustomInputStyle';
import InfiniteScroll from 'react-infinite-scroll-component';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import axios from "axios";

const FilterTag = React.memo(({ label, onDelete }) => (
    <Box className='main-page-filter-flag-item'>
        <Typography>{label}</Typography>
        <Iconify icon="mdi:close" onClick={onDelete} />
    </Box>
));

const ActionButton = React.memo(({ icon, title, onClick }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
        <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
    </Box>
));

export const UsersCredentiels = ({ search, openFilter, setOpenFilter }) => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const [users, setUsers] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [expertises, setExpertises] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const [selectedExpretise, setSelectedExpretise] = useState("");
    const [selectedInstitution, setSelectedInstitution] = useState("");
    const [loading, setLoading] = useState(true);
    const hasMore = useRef(true);
    const offset = useRef(0);
    const limit = 20;
    const [filter, setFilter] = useState({
        institutionId: "",
        specialityId: "",
        expertiseId: "",
        search: ""
    });

    const hasFilters = useMemo(() => (
        selectedInstitution || selectedSpeciality || selectedExpretise
    ), [selectedInstitution, selectedSpeciality, selectedExpretise]);

    const fetchAllUsers = useCallback(async () => {
        try {
            const currentOffset = offset.current;
            const response = await axios.get("users/findAllForManageUsersCredentials", { params: { filter, limit, offset: currentOffset } });
            if (response.status === 200 || response.status === 201) {
                setLoading(false);
                setUsers(prev => currentOffset === 0 ? response.data : [...prev, ...response.data]);
                hasMore.current = response.data.length === limit;
                offset.current = currentOffset + limit;
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    }, [filter]);

    useEffect(() => {
        axios.get("users/getFiltrationArrays").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setInstitutions(response.data.institutionArray)
                setSpecialities(response.data.specialityArray)
                setExpertises(response.data.expertiseArray)
            }
        })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        fetchAllUsers()
    }, [fetchAllUsers]);

    const handleChangeExpertise = useCallback((expertise) => {
        setFilter((prev) => ({ ...prev, expertiseId: expertise === "" ? "" : expertise.id, specialityId: "" }));
        setSelectedSpeciality("");
        setSelectedExpretise(expertise);
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const handleChangeSpeciality = useCallback((speciality) => {
        setFilter((prev) => ({ ...prev, specialityId: speciality === "" ? "" : speciality.id, expertiseId: "" }));
        setSelectedExpretise("");
        setSelectedSpeciality(speciality);
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    const handleChangeInstitution = useCallback((institution) => {
        setFilter((prev) => ({ ...prev, institutionId: institution?.id }));
        setSelectedInstitution(institution);
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    useEffect(() => {
        setFilter((prev) => ({ ...prev, search }));
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, [search]);

    const clearFilters = useCallback(() => {
        setFilter({
            institutionId: "",
            specialityId: "",
            expertiseId: "",
            search: '',
        });
        setSelectedExpretise("");
        setSelectedInstitution("");
        setSelectedSpeciality("");
        setUsers([]);
        offset.current = 0;
        hasMore.current = true;
    }, []);

    return (
        <Box>
            {loading ?
                <LinearProgress /> :
                <Box >
                    <Box>
                        {openFilter &&
                            <Box sx={{ marginTop: '5px', marginBottom: '5px' }}>
                                <Card sx={{ padding: '7px' }} >
                                    <Box display={'flex'} justifyContent={'space-between'} color={'#17536B'} >
                                        <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>{t("Filter")}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={clearFilters} />
                                            <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Grid container spacing={1} marginTop={'5px'}>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                                <InputLabel id="demo-simple-select-label-institution">{t("institution")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label-institution"
                                                    id="selectedExpretise"
                                                    label={t("institution")}
                                                    name="selectedInstitution"
                                                    value={selectedInstitution || ""}
                                                    onChange={(e) => handleChangeInstitution(e.target.value)}
                                                    endAdornment={
                                                        selectedInstitution && (
                                                            <InputAdornment position="start">
                                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeInstitution("")} />
                                                            </InputAdornment>
                                                        )
                                                    }
                                                >
                                                    {institutions && institutions.map((institution) => (
                                                        <MenuItem key={institution.id} value={institution}>{institution.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                                <InputLabel id="demo-simple-select-label-speciality">{t("speciality")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label-speciality"
                                                    id="selectedSpeciality"
                                                    label={t("speciality")}
                                                    name="selectedSpeciality"
                                                    value={selectedSpeciality || ""}
                                                    onChange={(e) => handleChangeSpeciality(e.target.value)}
                                                    endAdornment={
                                                        selectedSpeciality && (
                                                            <InputAdornment position="start">
                                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeSpeciality("")} />
                                                            </InputAdornment>
                                                        )
                                                    }
                                                >
                                                    {specialities && specialities.map((Speciality) => (
                                                        <MenuItem key={Speciality.id} value={Speciality}>{Speciality.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                                <InputLabel id="demo-simple-select-label-expertise">{t("expertise")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label-expertise"
                                                    id="selectedExpretise"
                                                    label={t("expertise")}
                                                    name="selectedExpretise"
                                                    value={selectedExpretise || ""}
                                                    onChange={(e) => handleChangeExpertise(e.target.value)}
                                                    endAdornment={
                                                        selectedExpretise && (
                                                            <InputAdornment position="start">
                                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeExpertise("")} />
                                                            </InputAdornment>
                                                        )
                                                    }
                                                >
                                                    {expertises && expertises.map((Expertise) => (
                                                        <MenuItem key={Expertise.id} value={Expertise}>{Expertise.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>


                                    </Grid>
                                </Card>

                            </Box>
                        }

                        {(!openFilter && hasFilters) &&
                            <Box className='filter-flag-box'>
                                {selectedSpeciality && <FilterTag label={selectedSpeciality?.label} onDelete={() => handleChangeSpeciality('')} />}
                                {selectedExpretise && <FilterTag label={selectedExpretise?.label} onDelete={() => handleChangeExpertise('')} />}
                                {selectedInstitution && <FilterTag label={selectedInstitution?.name} onDelete={() => handleChangeInstitution('')} />}
                                <Typography className="clear-filter-flag" onClick={clearFilters}>{t("clear_all_filter")}</Typography>
                            </Box>
                        }

                        <Scrollbar>
                            <InfiniteScroll
                                dataLength={users.length}
                                next={fetchAllUsers}
                                hasMore={hasMore.current}
                                loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>} >

                                {isDesktop &&
                                    <Table>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell className='table-titles'>{t("fullName")}</TableCell>
                                                <TableCell className='table-titles'>{t("email")}</TableCell>
                                                <TableCell className='table-titles'>{t("institu")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {users && users.map((user, index) => (
                                                <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                                                    <TableCell className="new-table-cell imeah_persone_name">{user.firstname.toLowerCase()} {user.lastname.toLowerCase()}</TableCell>
                                                    <TableCell className="new-table-cell">{user.email}</TableCell>
                                                    <TableCell className="new-table-cell">
                                                        {user.InstitutionUsers?.map((institutionUser) => (
                                                            <Chip
                                                                onClick={() => navigate('/dashboard/credentielsSet/manageUserCredentiels', {
                                                                    state: {
                                                                        institutionId: institutionUser.institution.id,
                                                                        institutionUserId: institutionUser.id
                                                                    }
                                                                })}
                                                                sx={{ cursor: 'pointer', transition: 'background-color 0.3s ease', '&:hover': { color: '#17536B', backgroundColor: '#C4E9EB' } }}
                                                                icon={<EditIcon sx={{ color: '#2E3F62', fontSize: '20px' }} />}
                                                                key={institutionUser.id}
                                                                label={institutionUser.institution?.name}
                                                            />
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                }

                                {!isDesktop &&
                                    <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
                                        {users && users.map((user, index) => (
                                            <Grid item xs={12} sm={4} key={index}  >
                                                <Box className="responsive-card-details">
                                                    <Typography className='card-resp-part imeah_persone_name'>{user.firstname.toLowerCase()} {user.lastname.toLowerCase()}</Typography>
                                                    {user.InstitutionUsers?.map((institutionUser) => (
                                                        <Chip
                                                            key={institutionUser.id}
                                                            onClick={() => navigate('/dashboard/credentielsSet/manageUserCredentiels', {
                                                                state: {
                                                                    institutionId: institutionUser.institution.id,
                                                                    institutionUserId: institutionUser.id
                                                                }
                                                            })}
                                                            sx={{ backgroundColor: '#C4E9EB', color: '#17536B', display: 'flex', textDecoration: 'none' }}
                                                            icon={<EditIcon sx={{ color: '#2E3F62', fontSize: '20px' }} />}
                                                            label={institutionUser.institution?.name}
                                                        />
                                                    ))}
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            </InfiniteScroll>

                        </Scrollbar>
                    </Box>
                </Box>
            }
        </Box>

    );
};