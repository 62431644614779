import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import axios from 'axios';

export const EditExpertise = ({ id, openEdit, setOpenEdit, setExpertises }) => {

    const { t } = useTranslation();

    const [englishName, setEnglishName] = useState('');
    const [frenchName, setFrenchName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const validationSchema = Yup.object().shape({
        englishName: Yup.string().trim().required(t('expertiseLableRequired')),
    });

    const fetchExpertise = useCallback(async () => {
        try {
            await axios.get(`expertise/${id}`)
                .then((response) => {
                    const expertiseLabels = response.data.expertiseLabel;
                    const englishLabel = expertiseLabels.find(label => label.language === 'en');
                    const frenchLabel = expertiseLabels.find(label => label.language === 'fr');
                    setEnglishName(englishLabel ? englishLabel.label : '');
                    setFrenchName(frenchLabel ? frenchLabel.label : '');
                })

        } catch (err) {
            console.error(err);
        }
    }, [id]);

    useEffect(() => {
        fetchExpertise();
    }, [fetchExpertise]);

    const handleEditExpertise = async () => {
        setIsLoading(true);
        try {
            await validationSchema.validate({ englishName }, { abortEarly: false });

            const bodyData = {
                enLanguage: englishName.trim(),
                frLanguage: frenchName.trim(),
            };

            const response = await axios.patch(`expertise/${id}`, bodyData);

            if (response.status === 200 || response.status === 201) {
                setExpertises((prevData) =>
                    prevData.map((item) =>
                        item.expertiseId === id
                            ? { ...item, label: response.data.expertiseLabel[0].label }
                            : item
                    )
                );
                setOpenEdit(false)
                setErrMsg(null)
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                // If validation fails, set the error message
                setErrMsg(err?.errors?.join(` ${t('and')} `));
            } else {
                // If API call fails, set the error message
                console.error('Error editing expertise:', err);
                setErrMsg(err.message);
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleEditClose = () => {
        setOpenEdit(false);
        setErrMsg(null)
    };

    return (
        <Dialog open={openEdit} keepMounted aria-describedby="alert-dialog-slide-description">
            <DialogTitle className='page-title'>{t('EditExpertise')}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    name="name"
                    label={t('Eng')}
                    type="text"
                    fullWidth
                    required
                    variant="outlined"
                    value={englishName}
                    onChange={(e) => setEnglishName(e.target.value)}
                    error={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName)}
                    helperText={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName) && t('isRequired')}
                />
                <TextField
                    margin="dense"
                    id="name"
                    name="name"
                    label={t('Fr')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={frenchName}
                    onChange={(e) => setFrenchName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button className='new-button' onClick={handleEditClose}>{t('cancel')}</Button>
                <LoadingButton loading={isLoading} className='new-button' onClick={handleEditExpertise}>{t('save')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}