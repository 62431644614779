import { useDebounceFunction } from '../../../hooks/useDebounceFunction';
import { AddRecommandationField } from './addCustomFieldRecommandation';
import { ValidateRecommandation } from './validateRecommandation.js';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { HasPermission } from '../../../utils/checkUserPermission';
import { RecommandationFileField } from './fieldTypes/file/file';
import { RecommandationTextField } from './fieldTypes/text.js';
import React, { useCallback, useState, useMemo } from 'react';
import { Box, Typography, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../../errorDialog';
import { useSelector } from 'react-redux';
import Iconify from '../../Iconify';
import axios from 'axios';
import '../../../css/medicalFiles/Recommandation.css'

const Recommandation = ({ medicalFileData, setMedicalFileData, Documents, setDocuments, setOpenShare, allowedActions }) => {

  const { t } = useTranslation();

  const currentInstitution = useSelector(state => state.currentInstitution);
  const isOnline = useSelector((state) => state.onlineStatus);

  const isSharedFile = Boolean(medicalFileData.shareStatus);
  const sharedwithMyInstitution = medicalFileData?.targetDoctorForShare?.institutionId === currentInstitution?.institution?.id;
  const isSharedWithMe = medicalFileData?.targetDoctorForShareId === currentInstitution?.id;

  // Check if the user has the permission to manage the recommendation for the medical file
  const isAllowedManageRecommandationForInstitutionMedicalFile = HasPermission('manage_Recommandation_for_institution_medicalFile');
  const isAllowedManageRecommandationForMedicalFileShareWithMe = HasPermission('manage_Recommandation_for_medicalFile_Share_with_Me');
  // Check if the user has the permission to validate the recommendation for the medical file
  const isAllowedValidateRecommandationForInstitutionMedicalFile = HasPermission('Valid_Recommandation_for_institution_medicalFile');
  const isAllowedValidateRecommandationForMedicalFileShareWithMe = HasPermission('Valid_Recommandation_for_medicalFile_Share_with_Me');

  const [openValidateRecommandation, setOpenValidateRecommandation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Check if the user has the permission to generate the recommendation for the medical file
  const canGenerateRecommandation = useMemo(() =>
    (
      (isAllowedManageRecommandationForInstitutionMedicalFile && sharedwithMyInstitution && isSharedFile) ||
      (isAllowedManageRecommandationForMedicalFileShareWithMe && isSharedWithMe && isSharedFile)
    )
    && (medicalFileData.recommandation === null || typeof medicalFileData.recommandation === 'undefined')
    && !medicalFileData?.recommandation?.confirmed,

    [isAllowedManageRecommandationForInstitutionMedicalFile, sharedwithMyInstitution, isSharedFile,
      isAllowedManageRecommandationForMedicalFileShareWithMe, isSharedWithMe, medicalFileData.recommandation]
  );

  // Check if the user has the permission to modify the recommendation for the medical file
  const canModifyRecommandation = useMemo(() => (
    (
      (isAllowedManageRecommandationForInstitutionMedicalFile && sharedwithMyInstitution && isSharedFile) ||
      (isAllowedManageRecommandationForMedicalFileShareWithMe && isSharedWithMe && isSharedFile)
    )
    && medicalFileData?.recommandation
    && (typeof medicalFileData.recommandation === 'object')
    && !medicalFileData?.recommandation?.confirmed

  ), [isAllowedManageRecommandationForInstitutionMedicalFile, sharedwithMyInstitution, isSharedFile,
    isAllowedManageRecommandationForMedicalFileShareWithMe, isSharedWithMe, medicalFileData.recommandation]);

  // Check if the user has the permission to validate the recommendation for the medical file
  const canValidRecommandation = useMemo(() => (
    (
      (isAllowedValidateRecommandationForInstitutionMedicalFile && sharedwithMyInstitution && isSharedFile) ||
      (isAllowedValidateRecommandationForMedicalFileShareWithMe && isSharedWithMe && isSharedFile)
    )
    && medicalFileData?.recommandation
    && typeof medicalFileData.recommandation === 'object'
    && medicalFileData?.recommandation?.messages?.length > 0
    && !medicalFileData?.recommandation?.confirmed

  ), [isAllowedValidateRecommandationForInstitutionMedicalFile, sharedwithMyInstitution, isSharedFile,
    isAllowedValidateRecommandationForMedicalFileShareWithMe, isSharedWithMe, medicalFileData.recommandation]);

  // Check if the user has the permission to read the recommendation for the medical file
  const canJustReadRecomandation = useMemo(() => (

    !(
      (isAllowedManageRecommandationForInstitutionMedicalFile && sharedwithMyInstitution && isSharedFile) ||
      (isAllowedManageRecommandationForMedicalFileShareWithMe && isSharedWithMe && isSharedFile)
    ) &&

    !(
      (isAllowedValidateRecommandationForInstitutionMedicalFile && sharedwithMyInstitution && isSharedFile) ||
      (isAllowedValidateRecommandationForMedicalFileShareWithMe && isSharedWithMe && isSharedFile)
    )

  ), [isAllowedManageRecommandationForInstitutionMedicalFile, sharedwithMyInstitution, isSharedFile,
    isAllowedManageRecommandationForMedicalFileShareWithMe, isSharedWithMe, isAllowedValidateRecommandationForInstitutionMedicalFile,
    isAllowedValidateRecommandationForMedicalFileShareWithMe]);

  // Create a new recommendation
  const CreateRecommandation = useCallback(async () => {
    const bodyData = { medicalFileId: medicalFileData.id };

    try {
      const response = await axios.post(`recommandation`, bodyData);
      if (response.status === 200 || response.status === 201) {

        const updatedMessages = response?.data?.messages.map(message => ({ ...message, editLabelStatus: true }));

        setMedicalFileData(prevData => ({ ...prevData, recommandation: { ...response.data, messages: updatedMessages } }));
      }
    } catch (error) {
      console.error('Error creating new recommendation:', error.message);
      setErrorMessage('createRecommendationError');
      CustomErrorLogger(error, 'Error creating new recommendation');
    }
  }, [medicalFileData.id, setMedicalFileData]);

  // Update the value of a field in the recommendation
  const handleUpdateOneFieldData = useCallback(async (recommandationId, FieldId, newValue) => {
    if (!isOnline) {
      setErrorMessage('offlineError');
      return;
    }
    try {
      const bodyData = {
        medicalFileId: medicalFileData.id,
        FieldId,
        value: newValue
      };

      const response = await axios.patch(`recommandation/updateOneFieldData/${recommandationId}`, bodyData);
      if (response.status === 200 || response.status === 201) {
        setMedicalFileData(prevData => ({ ...prevData, recommandation: { ...prevData.recommandation, messages: response.data } }));
      }
    } catch (error) {
      console.error('Error update recommandation field value :', error.message);
      CustomErrorLogger(error, 'Error update recommandation field value');
      setErrorMessage('updateRecommendationFieldError');
    }
  }, [isOnline, medicalFileData.id, setMedicalFileData]);

  const debouncedHandleUpdateOneFieldData = useDebounceFunction(handleUpdateOneFieldData, 1500);

  return (
    <Box>
      {canGenerateRecommandation &&
        <Box className='recommandation_noRcom_Box'>
          <Typography className="imeahTitle">{t("noRecommandationBoxTitle")}</Typography>
          <Button onClick={() => CreateRecommandation()} className='buttonFilled'>{t('ProvideRecommendations')}</Button>
        </Box>
      }

      {canJustReadRecomandation && !medicalFileData?.recommandation?.confirmed &&
        <Box className='recommandation_noRcom_Box'>
          <Typography className="imeahTitle">{t("noRecommandationBoxTitle")}</Typography>

          <Box className='recommandation_icon_box'>
            <Iconify icon="akar-icons:info" />
            <Typography className="imeahCaption"> {t("noRecommandationBoxCaption")}</Typography>
          </Box>

          {allowedActions.canShareMedicalFile && !medicalFileData.shareStatus &&
            <Button className='buttonFilled' onClick={() => setOpenShare(true)} >{t('send')}</Button>
          }
        </Box>
      }

      {(canModifyRecommandation || canValidRecommandation || medicalFileData?.recommandation?.confirmed) &&
        <Box className='recommandation_fields_container'>

          {medicalFileData?.recommandation?.messages && medicalFileData?.recommandation?.messages?.map((info) => {

            const hasDocument = Documents?.some((doc) => doc?.fieldId === info.id);
            const isEmptyField = !info.value || (Array.isArray(info.value) && info.value.length === 0);
            // Skip rendering if the field should not be displayed
            if (
              (info.type !== 'file' && !canModifyRecommandation && isEmptyField) ||
              (info.type === 'file' && !canModifyRecommandation && isEmptyField && !hasDocument)
            ) {
              return null;
            }

            return (
              <Box key={info.id}>
                {(info?.type === 'file') ?
                  <RecommandationFileField
                    recommandationId={medicalFileData?.recommandation?.id}
                    info={info}
                    data={medicalFileData}
                    setDocuments={setDocuments}
                    Documents={Documents}
                    debouncedHandleUpdateOneFieldData={debouncedHandleUpdateOneFieldData}
                    canModifyRecommandation={canModifyRecommandation}
                    medicalFileData={medicalFileData}
                    setMedicalFileData={setMedicalFileData}
                  />
                  :
                  <RecommandationTextField
                    recommandationId={medicalFileData?.recommandation?.id}
                    info={info}
                    debouncedHandleUpdateOneFieldData={debouncedHandleUpdateOneFieldData}
                    canModifyRecommandation={canModifyRecommandation}
                    medicalFileData={medicalFileData}
                    setMedicalFileData={setMedicalFileData}
                  />
                }
              </Box>
            )
          })}
        </Box>
      }

      {canModifyRecommandation &&
        <AddRecommandationField
          medicalFileData={medicalFileData}
          setMedicalFileData={setMedicalFileData}
          setErrorMessage={setErrorMessage}
        />
      }

      {canValidRecommandation &&
        <Box className='recommandation_validation_box'>
          <Button fullWidth disabled={medicalFileData?.recommandation?.messages?.length <= 0} onClick={() => setOpenValidateRecommandation(true)} startIcon={<SendIcon />} className='buttonFilled'>{t('valid_and_send')}</Button>
        </Box>
      }

      {openValidateRecommandation &&
        <ValidateRecommandation
          medicalFileData={medicalFileData}
          setMedicalFileData={setMedicalFileData}
          openValidateRecommandation={openValidateRecommandation}
          setOpenValidateRecommandation={setOpenValidateRecommandation}
        />
      }

      {errorMessage &&
        <ErrorDialog
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      }

    </Box >
  )
}

export default React.memo(Recommandation);