import { Dialog, DialogActions, DialogTitle, Button, DialogContent, Box, Chip, Tooltip } from '@mui/material';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const ReviewPermissions = ({ openReviewPermission, setOpenReviewPermission, permissions }) => {

    const { t } = useTranslation();

    const handleDeleteClose = useCallback(() => {
        setOpenReviewPermission(false);
    }, [setOpenReviewPermission]);

    // Memoize permission list for rendering
    const permissionsList = useMemo(() => {
        return (
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {permissions?.map(permission => (
                    <Tooltip key={permission?.PermissionName[0].label} title={permission?.PermissionDescription[0].label} arrow>
                        <Chip label={permission?.PermissionName[0].label} />
                    </Tooltip>
                ))}
            </Box>
        );
    }, [permissions]);

    return (
        <Dialog open={openReviewPermission} keepMounted aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="delete-role-dialog-title">{t("reviewPermission")}</DialogTitle>
            <DialogContent>{permissionsList}</DialogContent>
            <DialogActions>
                <Button className="new-button" onClick={handleDeleteClose}>{t("ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}