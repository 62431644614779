import { CustomErrorLogger } from './utils/CustomErrorLogger';
import LoadingFallback from '../src/utils/LoadingFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './redux/Store';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import './i18n';
import 'simplebar/src/simplebar.css';

axios.defaults.baseURL = window?.env?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL;

window.onerror = (message, error) => {
  CustomErrorLogger(error || new Error(message), "Global Error");
};

window.addEventListener("unhandledrejection", (event) => {
  CustomErrorLogger(event.reason, "Unhandled Promise Rejection");
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}
        >
          <ErrorBoundary
            onError={(error, info) => {
              // Log errors caught by the ErrorBoundary
              CustomErrorLogger(error);
              // Optionally log error component stack info as well:
              console.error("ErrorBoundary caught an error", info);
            }}
          >
            <Suspense fallback={<LoadingFallback />}>
              <App />
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();