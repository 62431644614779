import { Table, TableHead, TableRow, TableBody, TableCell, Chip, Box, MenuItem, InputAdornment, Grid, Menu, FormControl, InputLabel, Select, LinearProgress, useMediaQuery, Typography, Divider, Card } from '@mui/material';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DeleteInstitutionsUser } from './deleteInstitutionsUser';
import { customInputStyles } from '../../utils/CustomInputStyle';
import { HasPermission } from '../../utils/checkUserPermission';
import InfiniteScroll from 'react-infinite-scroll-component';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { act } from '@testing-library/react';
import { useSelector } from 'react-redux';
import Scrollbar from '../Scrollbar';
import Iconify from "../Iconify";
import axios from "axios";

const FilterTag = React.memo(({ label, onDelete }) => (
  <Box className='main-page-filter-flag-item'>
    <Typography>{label}</Typography>
    <Iconify icon="mdi:close" onClick={onDelete} />
  </Box>
));

const ActionButton = React.memo(({ icon, title, onClick }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
    <Divider orientation="vertical" variant="middle" flexItem />
    <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
    <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
  </Box>
));

export const InstitutionsUsersList = ({ openFilter, setOpenFilter, search, setSearch }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector(state => state.userData);
  const currentInstitution = useSelector(state => state.currentInstitution);

  const isAllowedcreateMedicalFileForSpecialist = HasPermission('create_Medical_File_for_specialist');
  const isAllowedcreateMedicalFileForExpert = HasPermission('create_Medical_File_for_expert');
  const isAllowedDeleteInstitutionUsers = HasPermission('Delete_Institution_User');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [loading, setLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [institutionUsers, setInstitutionUsers] = useState([]);
  const [selectedExpretise, setSelectedExpretise] = useState("");
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const [openExpertisesMenu, setOpenExpertisesMenu] = useState(null);
  const [targetExpertisesMenu, setTargetExpertisesMenu] = useState([]);
  const [expertisesForUsrsList, setExpertisesForUsersList] = useState([]);
  const [targetTemplateOwnerData, setTargetTemplateOwnerData] = useState('');
  const [TargetInstitutionUserId, setTargetInstitutionUserId] = useState(null);
  const [specialitiesForUsersList, setSpecialitiesForUsersList] = useState([]);
  const [targetDoctorInstitutionId, setTargetDoctorInstitutionId] = useState('');
  const [IdInstitutionUserForDelete, setIdInstitutionUserForDelete] = useState('');

  //pagination state
  const hasMore = useRef(true);
  const offset = useRef(0);
  const limit = 20;
  const [filter, setFilter] = useState({
    institutionId: "",
    specialityId: "",
    expertiseId: "",
    search,
  });

  const hasFilters = selectedSpeciality || selectedExpretise

  useEffect(() => {
    setFilter((prev) => ({ ...prev, search }));
    setInstitutionUsers([]);
    offset.current = 0;
    hasMore.current = true;
  }, [search]);

  const fetchInstitutionUsers = useCallback(() => {
    const currentOffset = offset.current;
    axios.get(`institutions-users/findAll`, { params: { filter, limit, offset: currentOffset } })
      .then((response) => {
        act(() => {
          setLoading(false);
          setInstitutionUsers(prev => currentOffset === 0 ? response.data : [...prev, ...response.data]);
          offset.current = currentOffset + limit;
          hasMore.current = response.data.length === limit;
        });
      })
      .catch((err) => { console.log(err); });
  }, [filter]);

  useEffect(() => {
    if (currentInstitution) {
      setInstitutionUsers([]);
      offset.current = 0;
      hasMore.current = true;
      fetchInstitutionUsers();
    }
  }, [currentInstitution, fetchInstitutionUsers, filter, userData.local_code]);

  useEffect(() => {
    if (userData.local_code) {
      axios.get('institutions-users/getFiltrationArrays')
        .then((response) => {
          act(() => {
            setSpecialitiesForUsersList(response.data.specialityArray)
            setExpertisesForUsersList(response.data.expertiseArray)
          })
        })
        .catch((err) => console.log(err));
    }
  }, [userData.local_code]);

  const handleChangeExpertise = useCallback((expertise) => {
    setSelectedSpeciality("");
    setSelectedExpretise(expertise);
    setFilter((prev) => ({ ...prev, expertiseId: expertise === "" ? "" : expertise.id, specialityId: "" }));
    setInstitutionUsers([]);
    offset.current = 0;
    hasMore.current = true;
  }, []);

  const handleChangeSpeciality = useCallback((speciality) => {
    setSelectedExpretise("");
    setSelectedSpeciality(speciality);
    setFilter((prev) => ({ ...prev, specialityId: speciality === "" ? "" : speciality.id, expertiseId: "" }));
    setInstitutionUsers([]);
    offset.current = 0;
    hasMore.current = true;
  }, []);

  const emptyFiltrationObject = () => {
    setFilter({
      specialityId: "",
      expertiseId: "",
      search: ""
    });
    setSearch("");
    setSelectedExpretise("");
    setSelectedSpeciality("");
    setInstitutionUsers([]);
    offset.current = 0;
    hasMore.current = true;
  }

  const handleDeleteOpen = (InstitutionUserId) => {
    setIdInstitutionUserForDelete(InstitutionUserId);
    setOpenDelete(true);
  };

  const handleAddDirectFile = (event, InstitutionUser) => {

    if (!InstitutionUser) return;

    const { id, specialityId, institutionUserExpertise, specialities, user, institutionId } = InstitutionUser;

    const isSpecialist = Boolean(specialityId && specialityId !== 'NoSpeciality' && specialityId != null);
    const isExpertWithOneExpertise = Array.isArray(institutionUserExpertise) && institutionUserExpertise?.length === 1;
    const isExpertWithMoreOneExpertise = Array.isArray(institutionUserExpertise) && institutionUserExpertise?.length > 1;

    if (isSpecialist) {
      const data = {
        targetTemplateOwnerData: user?.id,
        targetDoctorInstitutionId: institutionId,
        targetDoctorForShare: id,
        selectedSpeciality: { label: specialities?.specialityLabel[0]?.label, value: specialities?.id },
        selectedExpertise: '',
        activeStep: 2
      }
      navigate('/dashboard/medicalFiles/createFile', { state: data })
    }

    else if (isExpertWithOneExpertise) {
      const data = {
        targetTemplateOwnerData: user?.id,
        targetDoctorInstitutionId: institutionId,
        targetDoctorForShare: id,
        selectedSpeciality: '',
        selectedExpertise: { value: institutionUserExpertise[0]?.expertise?.expertiseLabel[0]?.expertiseId, label: institutionUserExpertise[0]?.expertise?.expertiseLabel[0]?.label },
        activeStep: 2
      }
      navigate('/dashboard/medicalFiles/createFile', { state: data })
    }
    else if (isExpertWithMoreOneExpertise) {
      setTargetInstitutionUserId(id)
      setTargetExpertisesMenu(institutionUserExpertise.map((expertise) => ({ id: expertise?.expertise?.expertiseLabel[0]?.expertiseId, label: expertise?.expertise?.expertiseLabel[0]?.label })))
      setOpenExpertisesMenu(event.currentTarget);
      setTargetTemplateOwnerData(user.id)
      setTargetDoctorInstitutionId(institutionId)
    }
    else {
      console.log('Error: Invalid role or data');
    }
  }

  const handleExpertisesMenuItemClick = (Expertise) => {
    const data = {
      targetTemplateOwnerData,
      targetDoctorInstitutionId,
      targetDoctorForShare: TargetInstitutionUserId,
      selectedSpeciality: '',
      selectedExpertise: { value: Expertise.id, label: Expertise.label },
      activeStep: 2
    }
    navigate('/dashboard/medicalFiles/createFile', { state: data })
  }

  return (
    <Box>
      {loading ?
        <LinearProgress /> :
        <Box>
          {openFilter &&
            <Box sx={{ marginTop: '5px', marginBottom: '5px' }}>
              <Card sx={{ padding: '7px' }} >
                <Box display={'flex'} justifyContent={'space-between'} color={'#17536B'} >
                  <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>{t("Filter")}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={emptyFiltrationObject} />
                    <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                  </Box>
                </Box>
                <Divider />
                <Grid container spacing={1} marginTop={'5px'}>

                  {(specialitiesForUsersList && specialitiesForUsersList?.length > 0) &&
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth sx={{ ...customInputStyles }} >
                        <InputLabel id="demo-simple-select-label-speciality">{t("speciality")}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label-speciality"
                          id="selectedSpeciality"
                          label={t("speciality")}
                          name="selectedSpeciality"
                          value={selectedSpeciality}
                          onChange={(e) => handleChangeSpeciality(e.target.value)}
                          endAdornment={
                            selectedSpeciality && (
                              <InputAdornment position="start">
                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeSpeciality("")} />
                              </InputAdornment>
                            )
                          }
                        >
                          {specialitiesForUsersList && specialitiesForUsersList.map((Speciality) => (
                            <MenuItem key={Speciality.id} value={Speciality}>{Speciality.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  }

                  {(expertisesForUsrsList && expertisesForUsrsList?.length > 0) &&
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth sx={{ ...customInputStyles }} >
                        <InputLabel id="demo-simple-select-label-expertise">{t("expertise")}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label-expertise"
                          id="selectedExpretise"
                          label={t("expertise")}
                          name="selectedExpretise"
                          value={selectedExpretise}
                          onChange={(e) => handleChangeExpertise(e.target.value)}
                          endAdornment={
                            selectedExpretise && (
                              <InputAdornment position="start">
                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeExpertise("")} />
                              </InputAdornment>
                            )
                          }
                        >
                          {expertisesForUsrsList && expertisesForUsrsList.map((expertise) => (
                            <MenuItem key={expertise.id} value={expertise}>{expertise.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  }
                </Grid>
              </Card>
            </Box>
          }

          {(!openFilter && hasFilters) &&
            <Box className='filter-flag-box'>
              {selectedSpeciality && <FilterTag label={selectedSpeciality?.label} onDelete={() => handleChangeSpeciality('')} />}
              {selectedExpretise && <FilterTag label={selectedExpretise?.label} onDelete={() => handleChangeExpertise('')} />}
              <Typography className="clear-filter-flag" onClick={emptyFiltrationObject}>{t("clear_all_filter")}</Typography>
            </Box>
          }

          <Scrollbar>
            <InfiniteScroll
              dataLength={institutionUsers?.length}
              next={fetchInstitutionUsers}
              hasMore={hasMore.current}
              loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
            >
              {isDesktop &&
                <Table>
                  <TableHead>
                    <TableRow className='table-row'>
                      <TableCell className='table-titles'>{t('fullName')}</TableCell>
                      <TableCell className='table-titles'>{t('email')}</TableCell>
                      <TableCell className='table-titles'>{t('institu')}</TableCell>
                      <TableCell className='table-titles'>{t('role')}</TableCell>
                      <TableCell className='table-titles'>{t('specialityExpertise')}</TableCell>
                      <TableCell className='table-titles'>{t('viewProfile')}</TableCell>
                      <TableCell className='table-titles'> {(isAllowedcreateMedicalFileForSpecialist || isAllowedcreateMedicalFileForExpert) && <Typography>{t('directFile')}</Typography>}</TableCell>
                      {isAllowedDeleteInstitutionUsers && <TableCell className='table-titles'>{t('dlt')}</TableCell>}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {institutionUsers && institutionUsers.map((institutionUser, index) => (
                      <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                        <TableCell className="new-table-cell imeah_persone_name">{institutionUser.user.firstname.toLowerCase()} {institutionUser.user.lastname.toLowerCase()}</TableCell>
                        <TableCell className="new-table-cell">{institutionUser.user?.email}</TableCell>
                        <TableCell className="new-table-cell">{institutionUser.institution?.name}</TableCell>
                        <TableCell className="new-table-cell" >{t(institutionUser.role?.roleLabel[0].label)}</TableCell>

                        <TableCell className="new-table-cell">
                          {(institutionUser.institutionUserExpertise?.length > 0) ? (
                            institutionUser.institutionUserExpertise.map((expertise, subIndex) => (
                              <Chip key={subIndex} label={expertise.expertise.expertiseLabel[0].label} className='chip' />
                            ))
                          ) : (institutionUser.specialityId !== 'NoSpeciality' && institutionUser.specialityId !== null) ? (
                            <Chip label={institutionUser.specialities?.specialityLabel?.[0].label} className='chip' />
                          ) : (
                            <Chip label={t('none')} className='chip' />
                          )}
                        </TableCell>

                        <TableCell className="new-table-cell">
                          <PersonIcon className='new-delete-icon actions-btn' onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: institutionUser.id, userId: institutionUser.user.id, userListTab: '2' } })} data-testid="accountIcon" />
                        </TableCell>


                        <TableCell className="new-table-cell">
                          {(
                            (isAllowedcreateMedicalFileForSpecialist && institutionUser.specialityId !== 'NoSpeciality' && institutionUser.specialityId !== null) ||
                            (isAllowedcreateMedicalFileForExpert && institutionUser.institutionUserExpertise?.length > 0)
                          ) &&
                            <PostAddIcon className='new-delete-icon actions-btn' onClick={(event) => handleAddDirectFile(event, institutionUser)} data-testid="fileIcon" />
                          }
                          <Menu
                            id="institutionUser-menu"
                            anchorEl={openExpertisesMenu}
                            open={Boolean(openExpertisesMenu)}
                            onClose={() => setOpenExpertisesMenu(null)}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            {targetExpertisesMenu && targetExpertisesMenu.map((Expertise) =>
                              <MenuItem key={Expertise.id} onClick={() => handleExpertisesMenuItemClick(Expertise)}>{Expertise.label}</MenuItem>
                            )}
                          </Menu>

                        </TableCell>

                        <TableCell align="left" className="new-table-cell">
                          {isAllowedDeleteInstitutionUsers &&
                            <DeleteIcon className='new-delete-icon actions-btn' onClick={() => handleDeleteOpen(institutionUser.id)} data-testid="deleteIcon" />
                          }
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }

              {!isDesktop &&
                <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'} >
                  {institutionUsers && institutionUsers.map((institutionUser, index) => (
                    <Grid item xs={12} sm={4} key={index}  >
                      <Box className="responsive-card-details">
                        <Typography className="card-resp-part imeah_persone_name">{institutionUser.user?.firstname.toLowerCase()} {institutionUser.user?.lastname.toLowerCase()}</Typography>
                        <Typography className='card-resp-part'>{t(institutionUser.role?.roleLabel[0].label)} </Typography>
                        <Typography className='card-resp-part'>
                          {(institutionUser.institutionUserExpertise?.length > 0) ? (
                            institutionUser.institutionUserExpertise?.map((expertise, subIndex) => (
                              <Chip key={subIndex} label={expertise.expertise.expertiseLabel[0].label} className='chip' />
                            ))
                          ) : (institutionUser.specialityId !== 'NoSpeciality' && institutionUser.specialityId !== null) ? (
                            <Chip label={institutionUser.specialities?.specialityLabel?.[0].label} className='chip' />
                          ) : (
                            <Chip label={t('none')} className='chip' />
                          )}
                        </Typography>
                        <Box className='card-actions'>
                          <PersonIcon className='new-delete-icon actions-btn' onClick={() => navigate(`/dashboard/userprofile`, { state: { institutionUserId: institutionUser.id, userId: institutionUser.user.id, userListTab: '2' } })} />
                          {isAllowedDeleteInstitutionUsers &&
                            <DeleteIcon className='new-delete-icon actions-btn' onClick={() => handleDeleteOpen(institutionUser.id)} />
                          }
                          {(
                            (isAllowedcreateMedicalFileForSpecialist && institutionUser.specialityId !== 'NoSpeciality' && institutionUser.specialityId !== null) ||
                            (isAllowedcreateMedicalFileForExpert && institutionUser.expertises?.length > 0)
                          ) &&
                            <Box>
                              <PostAddIcon className='new-delete-icon actions-btn' onClick={(event) => handleAddDirectFile(event, institutionUser)} />
                              <Menu
                                id="institutionUser-menu"
                                anchorEl={openExpertisesMenu}
                                open={Boolean(openExpertisesMenu)}
                                onClose={() => setOpenExpertisesMenu(null)}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                              >
                                {targetExpertisesMenu && targetExpertisesMenu.map((Expertise) =>
                                  <MenuItem key={Expertise.id} onClick={() => handleExpertisesMenuItemClick(Expertise)}>{Expertise.label}</MenuItem>
                                )}
                              </Menu>
                            </Box>
                          }
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              }

            </InfiniteScroll>
          </Scrollbar>
        </Box>
      }

      {openDelete &&
        <DeleteInstitutionsUser
          IdInstitutionUserForDelete={IdInstitutionUserForDelete}
          institutionUsers={institutionUsers}
          setInstitutionUsers={setInstitutionUsers}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
        />
      }

    </Box>

  );
};
