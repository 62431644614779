import { Dialog, DialogContent, DialogTitle, Button, Typography, Box, TextField, Radio, RadioGroup, FormControlLabel, Alert } from '@mui/material'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import React from 'react'
import { useTranslation } from "react-i18next";
import '../../../../../css/CV/education.css'
import axios from 'axios';
import { useSelector } from 'react-redux';

export const EditWork = (props) => {
    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    const { editWorkData, openEdit, setOpenEdit, dataIndex, work, setWork } = props
    const [newWork, setNewWork] = useState(editWorkData);
    const [errMsg, setErrMsg] = useState("");

    const useStyles = makeStyles((theme) => ({
        notchedOutline: {
            borderWidth: "2px",
            borderColor: "#17536B !important",
        },
    }));
    const classes = useStyles();

    const handleChangeInput = (id, event) => {
        const updatedWork = { ...newWork };
        updatedWork[id] = event.target.value;

        if (id === "End_date" || id === "Start_date") {
            const startDate = new Date(updatedWork.Start_date);
            const endDate = new Date(updatedWork.End_date);

            // Compare only the year, month, and day parts of the dates
            const isSameDay = startDate.getUTCFullYear() === endDate.getUTCFullYear() &&
                startDate.getUTCMonth() === endDate.getUTCMonth() &&
                startDate.getUTCDate() === endDate.getUTCDate();

            if (endDate < startDate || isSameDay) {
                setErrMsg(`${t('errToDateAfterFromDate')}`);
                updatedWork.End_date = "";
            } else {
                setErrMsg("");
            }
        }

        setNewWork(updatedWork);
    };

    const EditWork = () => {
        if (dataIndex !== null) {
            const updatedWork = [...work];
            updatedWork[dataIndex] = newWork;
            const data = {
                workExperience: updatedWork,
                userId: userData.id
            };
            axios
                .post(`user-cv`, data)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setWork(updatedWork)
                        setOpenEdit(false);
                    }
                });
        }
    };

    return (
        <Dialog fullWidth open={openEdit} >
            <DialogTitle className="page-title">{t("EditWork")}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogContent className='education-dialog-content'>
                <Box>
                    <Typography className='education-dialog-typo'>{t('company')}*</Typography>
                    <TextField
                        id="Company"
                        name="Company"
                        type="text"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        fullWidth
                        defaultValue={editWorkData.Company || ""}
                        onChange={event => handleChangeInput("Company", event)}
                    />
                </Box>
                <Box>
                    <Typography className='education-dialog-typo'>{t('post')}*</Typography>
                    <TextField
                        id="Post"
                        name="Post"
                        type="text"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        fullWidth
                        defaultValue={editWorkData.Post || ""}
                        onChange={event => handleChangeInput("Post", event)}
                    />
                </Box>
                <Box className='add-education-current-post-box'>
                    <Typography className='education-dialog-typo'>{t('currentWork')} ?</Typography>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={newWork.Current || ""}
                        name="radio-buttons-group"
                        row
                        onChange={event => handleChangeInput("Current", event)}
                    >
                        <FormControlLabel value="no" control={<Radio />} label={t('no')} />
                        <FormControlLabel value="yes" control={<Radio />} label={t('yes')} />
                    </RadioGroup>
                </Box>
                <Box className="education-date-box">
                    <Box className="education-date-field">
                        <TextField
                            sx={{ marginTop: '8px' }}
                            id="Start_date"
                            name="Start_date"
                            type="date"
                            label={t("startDate")}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            defaultValue={editWorkData?.Start_date || ""}
                            onChange={event => handleChangeInput("Start_date", event)}
                        />
                    </Box>
                    {newWork.Current === "no" ?
                        <Box className="education-date-field">
                            <TextField
                                id="End_date"
                                name="End_date"
                                type="date"
                                label={t("endDate")}
                                margin="dense"
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                defaultValue={editWorkData?.End_date || ""}
                                variant="outlined"
                                onChange={event => handleChangeInput("End_date", event)}
                            />
                        </Box> : null}
                </Box>
                <Box>
                    <Typography className='education-dialog-typo'>{t('description')}*</Typography>
                    <TextField
                        id="Description"
                        name="Description"
                        multiline
                        rows={4}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        fullWidth
                        defaultValue={editWorkData.Description || ""}
                        onChange={event => handleChangeInput("Description", event)}
                    />
                </Box>

            </DialogContent>
            <DialogActions className="addInstitution-actions-box">
                <Button className="new-button" onClick={() => { setOpenEdit(false) }}>{t('cancel')}</Button>
                <Button className="new-button" onClick={EditWork} disabled={newWork.Company === "" || newWork.Post === "" || newWork.Start_date === "" || (newWork.End_date === "" && newWork.Current === 'no') || newWork.Description === ""}>{t('edit')}</Button>
            </DialogActions>
        </Dialog>
    )
}