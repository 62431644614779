import { Dialog, DialogContent, DialogTitle, Button, Box, TextField } from '@mui/material'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import React from 'react'
import { useState } from "react";
import { useTranslation } from "react-i18next";
import '../../../../../css/CV/education.css'
import axios from 'axios';
import { useSelector } from 'react-redux';

export const AddSummary = (props) => {
    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    const educationDialog = true
    const { setSection, summary, setSummary } = props
    const [summaryInput, setSummaryInput] = useState("")

    const useStyles = makeStyles((theme) => ({
        notchedOutline: {
            borderWidth: "2px",
            borderColor: "#17536B !important",
        },
    }));
    const classes = useStyles();

    const addSummary = () => {
        const data = {
            summary: summaryInput,
            userId: userData.id
        }
        axios.post(`user-cv`, data)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setSection("")
                    setSummary(summaryInput)
                }
            })
    }


    return (
        <Dialog fullWidth open={educationDialog} >
            <DialogTitle className="page-title">{t('yourSummary')}</DialogTitle>
            <DialogContent className='education-dialog-content'>
                <Box sx={{ marginTop: '10px' }}>
                    <TextField
                        id="Summary"
                        name="Summary"
                        label={t('summary')}
                        multiline
                        rows={4}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        fullWidth
                        defaultValue={summary || ""}
                        onChange={event => setSummaryInput(event.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions className="addInstitution-actions-box">
                <Button className="new-button" onClick={() => { setSection("") }}>{t('cancel')}</Button>
                <Button className="new-button" onClick={addSummary} >{t('add')}</Button>
            </DialogActions>
        </Dialog>
    )
}