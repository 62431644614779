import { Alert, Typography, Box, Button, TextField, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddItemPoilicy } from "./addItemPolicy";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import Page from "../Page";
import * as Yup from 'yup';
import axios from "axios";
import '../../css/policies/editPolicy.css';

export const EditPolicy = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;

    const [msg, setMsg] = useState('');
    const [index, setIndex] = useState("");
    const [label, setLabel] = useState(location.state?.label);
    const [openAddItemsDialog, setOpenAddItemsDialog] = useState(false);
    const [descTitle, setDescTitle] = useState("");
    const [descSubTitle, setDescSubTitle] = useState("");
    const [description, setDescription] = useState(location.state.description);

    const validationSchema = Yup.object().shape({
        label: Yup.string().trim().required(t('titleRequired')),
        description: Yup.array()
            .of(
                Yup.object().shape({
                    descTitle: Yup.string().trim().required('descTitle is required'),
                    descSubTitle: Yup.string().trim().required('descSubtitle is required'),

                })
            )
            .required('Description is required')
            .min(1, 'At least one description is required'),
    });

    const data = { label, description };

    const handleEdit = (event) => {
        event.preventDefault();

        if (validationSchema.isValidSync({ label: data.label, description: data.description })) {
            axios
                .patch(`policies/${id}`, data)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        navigate(-1)
                    }
                })
                .catch((err) => { console.log(err.message); setMsg(err.message) });
        }
    };

    const handleEditPolicies = (item, index) => {
        setIndex(index)
        setDescTitle(item.descTitle)
        setDescSubTitle(item.descSubTitle)
        setOpenAddItemsDialog(true)
    }

    const handleRemovePolicy = (item) => {
        const allPolicies = [...description];
        const index = allPolicies.indexOf(item);
        allPolicies.splice(index, 1);
        setDescription(allPolicies);
    };

    const handleBackBtn = () => {
        navigate(-1);
        setMsg("")
    };

    return (
        <Page title={t("editPolicy")}>
            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                <ArrowBackIosIcon className='editPolicy-back-btn' onClick={() => navigate(-1)} data-testid="arrowBack" />
                <Stack className="editPolicy-main-page-header">
                    <Box className="editPolicy-page-title-box">
                        <Typography className='editPolicy-page-title'>{t("editPolicy")}</Typography>
                    </Box>
                </Stack>
            </Box>

            {msg && <Alert severity="error">{msg}</Alert>}
            <Box>
                <TextField
                    required
                    sx={{ marginBottom: '15px' }}
                    margin="dense"
                    id="name"
                    name="mainPolicyTitle"
                    label={t('label')}
                    type="text"
                    value={label}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setLabel(e.target.value)}
                    error={!label}
                    helperText={!label && (t('isRequired'))}
                />

                <Box>
                    {description && description.map((item, index) => (
                        <Box
                            key={index}
                            style={{
                                backgroundColor: '#f0f0f0',
                                padding: '10px',
                                marginBottom: '10px',
                                position: 'relative',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box>
                                <Typography variant="h6" sx={{ whiteSpace: 'pre-wrap' }}>{item.descTitle}</Typography>
                                <Typography noWrap={false} sx={{ whiteSpace: 'pre-wrap' }}>{item.descSubTitle}</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <EditIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleEditPolicies(item, index)}
                                />

                                <CloseRoundedIcon
                                    onClick={() => handleRemovePolicy(item)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>

                <AddCircleIcon
                    onClick={() => setOpenAddItemsDialog(true)}
                    sx={{ color: '#34697E', marginLeft: '50%', marginTop: '5%', height: '100%', fontSize: '50px', cursor: 'pointer' }} />
            </Box>

            {openAddItemsDialog &&
                <AddItemPoilicy
                    index={index}
                    setIndex={setIndex}
                    descTitle={descTitle}
                    setDescTitle={setDescTitle}
                    descSubTitle={descSubTitle}
                    setDescSubTitle={setDescSubTitle}
                    description={description}
                    setDescription={setDescription}
                    openAddItemsDialog={openAddItemsDialog}
                    setOpenAddItemsDialog={setOpenAddItemsDialog}
                />
            }

            <Box className="editPolicy-actions-box">
                <Button onClick={() => handleBackBtn()} className='new-button'>{t('cancel')}</Button>
                <Button className='new-button' onClick={handleEdit} disabled={!validationSchema.isValidSync({ label: data.label, description: data.description })}>{t('save')}</Button>
            </Box>
        </Page>
    )
}