/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Box, Button, TextField, InputAdornment, useMediaQuery, Menu, MenuItem } from "@mui/material";
import MedicalFileResponsiveFiltration from '../components/medicalFile/read/medicalFileResponsiveFiltration';
import { MedicalFileList } from '../components/medicalFile/read/mecicalFileList';
import NoFileLength from "../components/medicalFile/noFileLength";
import { useState, useEffect, useCallback, useRef } from "react";
import { CustomErrorLogger } from "../utils/CustomErrorLogger";
import { ImeahStyledBudge } from "../utils/imeah_styled_budge";
import { HasPermission } from "../utils/checkUserPermission";
import ImeahActionIcon from '../utils/imeah_action_icon';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDebounce from "../hooks/useDebounce";
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import Page from "../components/Page";
import '../css/medicalFiles.css';
import axios from 'axios';

export const MedicalFiles = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const userData = useSelector(state => state.userData);
    const currentInstitution = useSelector(state => state.currentInstitution);

    //check user permissions
    const isAllowedCreateMedicalFileForSpecialist = HasPermission('create_Medical_File_for_specialist');
    const isAllowedCreateMedicalFileForExpert = HasPermission('create_Medical_File_for_expert');
    const isAllowedChangeMedicalFilesOwner = HasPermission('Edit_File_Owner_institution_Medical_File');
    const isAllowedReadAllMedicalFile = HasPermission('read_All_Medical_File');
    const isAllowedReadAllSharedMedicalFile = HasPermission('read_All_shared_Medical_File');
    const isAllowedExportMedicalFile = HasPermission('import_export_Medical_File');

    const [dialogTransferAllMedicalFiles, setDialogTransferAllMedicalFiles] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNoFiles, setIsNoFiles] = useState(false);
    const [institutionUsers, setInstitutionUsers] = useState([]);
    const [shareInstitutionUsers, setShareInstitutionUsers] = useState([]);
    const [Specialitys, setSpecialitys] = useState([]);
    const [statusArray, setStatusArray] = useState([]);
    const [Expertises, setExpertises] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actions, setActions] = useState([]);

    //filtration and sort states
    const [globalSearch, setGlobalSearch] = useState(null);
    const debouncedGlobalSearch = useDebounce(globalSearch, 1000);
    const [searchName, setSearchName] = useState(null);
    const debouncedSearchName = useDebounce(searchName, 1000);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);
    const [selectedExpretise, setSelectedExpretise] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedSharedDoctor, setSelectedSharedDoctor] = useState(null);
    const [orderByName, setOrderByName] = useState(null);
    const [orderByUpdateDate, setOrderByUpdateDate] = useState(null);
    const [medicalFilesFilteredCount, setMedicalFilesFilteredCount] = useState(null);
    const [medicalFilesTotalCount, setMedicalFilesTotalCount] = useState(null);
    const [files, setFiles] = useState(Array(medicalFilesFilteredCount).fill(null));    // filtration array data states
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    //pagination state
    const hasMore = useRef(null);
    const offset = useRef(0);
    const limit = 30;

    const hasFilters = selectedSpeciality || selectedDoctor || searchName || selectedSharedDoctor || selectedStatus;

    useEffect(() => {
        document.body.style.background = 'linear-gradient(180deg, #ffffff 0%, #ffffff 10%, #ffffff 20%, #39AAFF1A 50%, #39AAFF1A 100%)';

        // Clean up the styles when the component unmounts
        return () => {
            document.body.style.background = '';
        };
    }, []);

    useEffect(() => {
        setLoading(true)
    }, [selectedSpeciality, selectedDoctor, searchName, selectedSharedDoctor, selectedStatus, debouncedGlobalSearch]);

    const fetchFiles = useCallback(async (newOffset) => {
        if (!currentInstitution) return;

        const filters = {
            searchName: debouncedSearchName,
            globalSearch: debouncedGlobalSearch,
            selectedSpeciality: selectedSpeciality?.id,
            selectedExpretise: selectedExpretise?.id,
            selectedStatus: selectedStatus?.id,
            selectedDoctor: selectedDoctor?.id,
            selectedSharedDoctor: selectedSharedDoctor?.id,
            orderByUpdateDate: orderByUpdateDate,
        };

        try {
            const adjustedOffset = Math.max(newOffset - 10, 0) || 0; // Ensure offset is not negative
            const response = await axios.get('medical-file', {
                params: { offset: adjustedOffset, limit: limit, filters }
            });

            if (response.status === 200 || response.status === 201) {
                // Create a copy of the current files array
                const updatedFiles = [];

                // Update the fetched records in their correct positions
                response.data.medicalFiles.forEach((file, index) => {
                    updatedFiles[adjustedOffset + index] = file;
                });

                // Update the state with the new files array
                setFiles(updatedFiles);

                setMedicalFilesFilteredCount(response.data.medicalFilesFilteredCount);
                setMedicalFilesTotalCount(response.data.medicalFilesTotalCount)
                setIsNoFiles(response.data.medicalFilesTotalCount === 0);
            }
        } catch (error) {
            console.error('Error Fetching medical files:', error);
            CustomErrorLogger(error, 'Error fetching medical files in MedicalFiles page');
        } finally {
            setLoading(false);
        }
    }, [limit, currentInstitution, orderByUpdateDate, debouncedSearchName, debouncedGlobalSearch, selectedDoctor, selectedExpretise, selectedSharedDoctor, selectedSpeciality, selectedStatus]);

    const handleNewOffset = (newOffset) => {
        fetchFiles(newOffset);
    };

    const fetchFiltrationArrays = useCallback(async () => {
        if (!currentInstitution) return;
        try {
            const response = await axios.get('medical-file/getFiltrationArrays');
            if (response.status === 200 || response.status === 201) {
                setStatusArray(response.data.statusArray.map((status) => ({ id: status, label: t(status) })));
                setSpecialitys(response.data.ParsedSpecialityArray);
                setExpertises(response.data.ParsedExpertiseArray);
                setInstitutionUsers(
                    isAllowedReadAllMedicalFile ?
                        response.data.ParsedDoctorArray.map((doctor) => ({ id: doctor.id, label: `${doctor.user.firstname} ${doctor.user.lastname} (${doctor.institution?.name})` }))
                        :
                        response.data.ParsedDoctorArray.map((doctor) => ({ id: doctor.id, label: `${doctor.user.firstname} ${doctor.user.lastname}` }))
                );
                setShareInstitutionUsers(
                    isAllowedReadAllSharedMedicalFile ?
                        response.data.ParsedTargetDoctorArray.map((doctor) => ({ id: doctor.id, label: `${doctor.user.firstname} ${doctor.user.lastname} (${doctor.institution?.name})` }))
                        :
                        response.data.ParsedTargetDoctorArray.map((doctor) => ({ id: doctor.id, label: `${doctor.user.firstname} ${doctor.user.lastname}` }))
                );
            }
        } catch (error) {
            console.error('Error Fetching Files Filtration Arrays:', error);
            CustomErrorLogger(error, 'Error fetching files filtration arrays in MedicalFiles page');
        }
    }, [currentInstitution]);

    useEffect(() => {
        fetchFiltrationArrays()
    }, [currentInstitution, userData.local_code]);

    useEffect(() => {
        setFiles([]);
        fetchFiles();
    }, [fetchFiles, userData.local_code]);

    const handleChangeSearchName = (searchWord) => {
        setSearchName(searchWord);
    };

    const handleChangeGlobalSearch = (searchWord) => {
        setGlobalSearch(searchWord);
    };

    const handleChangeSpeciality = (speciality) => {
        setSelectedSpeciality(speciality);
        setSelectedExpretise("");
    };

    const handleChangeStatus = (status) => {
        setSelectedStatus(status);
    };

    const handleChangeDoctor = (doctor) => {
        setSelectedDoctor(doctor);
        offset.current = 0;
        hasMore.current = true;
    };

    const handleChangeSharedDoctor = (doctor) => {
        setSelectedSharedDoctor(doctor);
    };

    const handleSortByName = () => {
        let nextOrderByName;
        let sortedMedicalFiles = [...files];

        switch (orderByName) {
            case null:
                nextOrderByName = 'asc';
                sortedMedicalFiles.sort((fileOne, fileTwo) => fileOne.first_name.localeCompare(fileTwo.first_name));
                break;
            case 'asc':
                nextOrderByName = 'desc';
                sortedMedicalFiles.sort((fileOne, fileTwo) => fileTwo.first_name.localeCompare(fileOne.first_name));
                break;
            case 'desc':
                nextOrderByName = null;
                break;
            default:
                nextOrderByName = null;
                break;
        }
        setOrderByName(nextOrderByName);
        setFiles(sortedMedicalFiles);
    };

    const handleSortByUpdateDate = () => {
        let nextOrderByUpdateDate;

        switch (orderByUpdateDate) {
            case null:
                nextOrderByUpdateDate = 'desc';
                break;
            case 'desc':
                nextOrderByUpdateDate = 'asc';
                break;
            case 'asc':
                nextOrderByUpdateDate = null;
                break;
            default:
                nextOrderByUpdateDate = null;
                break;
        }
        setOrderByUpdateDate(nextOrderByUpdateDate);
        offset.current = 0;
        hasMore.current = true;
    }

    const filtersMenu = {
        name: {
            options: [],
            handleChange: handleChangeSearchName,
        },
        specialityExpertise: {
            options: [...Specialitys, ...Expertises],
            handleChange: handleChangeSpeciality,
        },
        requerant: {
            options: institutionUsers,
            handleChange: handleChangeDoctor
        },
        requis: {
            options: shareInstitutionUsers,
            handleChange: handleChangeSharedDoctor
        },
        status: {
            options: statusArray,
            handleChange: handleChangeStatus
        }
    };

    useEffect(() => {
        const actionsList = [
            {
                condition: (isAllowedCreateMedicalFileForSpecialist || isAllowedCreateMedicalFileForExpert) && currentInstitution.institution.id !== 'imeah',
                label: t('New_Medical_File'),
                onClick: () => navigate(`/dashboard/medicalFiles/createFile`),
                icon: 'eva:plus-fill'
            },
            {
                condition: isAllowedExportMedicalFile && currentInstitution.institution.id !== 'imeah',
                label: t('importMDF'),
                onClick: () => navigate(`/dashboard/medicalFiles/import`),
                icon: 'fa6-solid:file-import'
            },
            {
                condition: isAllowedChangeMedicalFilesOwner,
                label: t('transfer'),
                onClick: () => setDialogTransferAllMedicalFiles(true),
                icon: 'material-symbols-light:sync-alt'
            }
        ].filter(action => action.condition);

        setActions(actionsList);
    }, [isAllowedCreateMedicalFileForSpecialist, isAllowedCreateMedicalFileForExpert, isAllowedExportMedicalFile, isAllowedChangeMedicalFilesOwner, currentInstitution, t, navigate, setDialogTransferAllMedicalFiles]);

    const handleMenuActionOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuActionClose = () => {
        setAnchorEl(null);
    };

    return (
        <Page title={t("sideBar-Medical-Files")} className="mdf-page-style specific_background" list={true}>

            <Typography className='mdf-page-title' >{t("sideBar-Medical-Files")}</Typography>

            <Box className="mdf-page-action-search-box" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box className="mdf-page-action-box">
                    {isDesktop ? (
                        actions.map((action, index) => (
                            <Button
                                key={index}
                                className="buttonOutlined"
                                onClick={action?.onClick}
                                startIcon={<Iconify icon={action?.icon} />}
                            >
                                {action.label}
                            </Button>
                        ))
                    ) : (
                        <>
                            {actions.length === 1 ? (
                                <Button
                                    className="buttonOutlined"
                                    onClick={actions[0]?.onClick}
                                    startIcon={<Iconify icon={actions[0]?.icon} />}
                                >
                                    {actions[0]?.label}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        className="buttonOutlined"
                                        onClick={actions[0]?.onClick}
                                        startIcon={<Iconify icon={actions[0]?.icon} />}
                                    >
                                        {actions[0]?.label}
                                    </Button>
                                    <ImeahActionIcon icon='tabler:dots' color='#727179' onClick={handleMenuActionOpen} />
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuActionClose}
                                    >
                                        {actions.slice(1).map((action, index) => (
                                            <MenuItem key={index} onClick={action?.onClick}>
                                                <Iconify icon={action.icon} style={{ marginRight: 8 }} />
                                                {action?.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                    <TextField
                        className="mdf-page-search-input customTextField"
                        placeholder={t('Search')}
                        value={globalSearch}
                        onChange={(e) => handleChangeGlobalSearch(e.target.value)}
                        size="small"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon='ic:round-search' />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                globalSearch && (
                                    <InputAdornment position="end">
                                        <Iconify
                                            icon='ic:round-close'
                                            onClick={() => setGlobalSearch('')}
                                            sx={{ cursor: 'pointer', fontSize: '20px' }}
                                        />
                                    </InputAdornment>
                                )
                            ),
                        }}
                    />
                    {!isDesktop &&
                        <Box>
                            <ImeahStyledBudge variant="dot" invisible={!hasFilters}>
                                <Iconify
                                    icon='material-symbols:filter-alt'
                                    sx={{ fontSize: '32px', cursor: 'pointer', color: '#727179' }}
                                    onClick={() => setIsDrawerOpen(true)} />
                            </ImeahStyledBudge>

                        </Box>
                    }
                </Box>
            </Box>

            {isDrawerOpen &&
                <MedicalFileResponsiveFiltration
                    isDrawerOpen={isDrawerOpen}
                    setIsDrawerOpen={setIsDrawerOpen}
                    filtersMenu={filtersMenu}
                    isMobile={!isDesktop}
                    selectedSpeciality={selectedSpeciality}
                    handleChangeSpeciality={handleChangeSpeciality}
                    selectedDoctor={selectedDoctor}
                    handleChangeDoctor={handleChangeDoctor}
                    selectedSharedDoctor={selectedSharedDoctor}
                    handleChangeSharedDoctor={handleChangeSharedDoctor}
                    selectedStatus={selectedStatus}
                    handleChangeStatus={handleChangeStatus}
                    searchName={searchName}
                    handleChangeSearchName={handleChangeSearchName}
                />
            }

            {isNoFiles ?
                <NoFileLength />
                :
                <MedicalFileList
                    dialogTransferAllMedicalFiles={dialogTransferAllMedicalFiles}
                    setDialogTransferAllMedicalFiles={setDialogTransferAllMedicalFiles}
                    loading={loading}
                    files={files}
                    medicalFilesFilteredCount={medicalFilesFilteredCount}
                    setMedicalFilesFilteredCount={setMedicalFilesFilteredCount}
                    medicalFilesTotalCount={medicalFilesTotalCount}
                    setMedicalFilesTotalCount={setMedicalFilesTotalCount}
                    fetchFiles={fetchFiles}
                    orderByName={orderByName}
                    handleSortByName={handleSortByName}
                    orderByUpdateDate={orderByUpdateDate}
                    handleSortByUpdateDate={handleSortByUpdateDate}
                    setFiles={setFiles}
                    onScroll={handleNewOffset}
                    selectedSpeciality={selectedSpeciality}
                    handleChangeSpeciality={handleChangeSpeciality}
                    selectedDoctor={selectedDoctor}
                    handleChangeDoctor={handleChangeDoctor}
                    selectedSharedDoctor={selectedSharedDoctor}
                    handleChangeSharedDoctor={handleChangeSharedDoctor}
                    selectedStatus={selectedStatus}
                    handleChangeStatus={handleChangeStatus}
                    searchName={searchName}
                    handleChangeSearchName={handleChangeSearchName}
                    handleChangeGlobalSearch={handleChangeGlobalSearch}
                    filtersMenu={filtersMenu}
                />
            }
        </Page>
    );
}