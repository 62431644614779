import { LanguageSwitcher } from "../components/NoLoginLanguageSwitcher";
import PasswordResetImage from '../images/passwordReset.svg';
import { Typography, Box, Button } from "@mui/material";
import '../css/User-css/passwordSuccessfullyReset.css';
import logo from '../images/logo_e_health_pro.svg';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";
import React from "react";

export const PasswordSuccessfullyReset = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Page title={t("Forgot_Password_Page_Title")} className='FG-container-pass'>
            <Box className='passwordReset_main_box' >

                <Box display='flex' justifyContent='space-between'>
                    <Box sx={{ width: '200px' }} component="img" src={logo} alt="imeah logo" />
                    <LanguageSwitcher/>
                </Box>

                <Box className="passwordReset_image" component="img" src={PasswordResetImage} alt="email sent image" />

                <Box className='passwordReset_title_typo_box'>
                    <Typography className="passwordReset_title">{t("successfullPassword")}</Typography>
                    <Typography className="passwordReset__typo">{t("youCanUseNewPassword")}</Typography>
                </Box>

               <Button className="password_successfully_reset" onClick={()=>navigate('/login')}>{t('loginn')}</Button>

            </Box>
        </Page>
    );
}