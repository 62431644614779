import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axios from 'axios';

export const AddSection = ({ template, setTemplate, showAddSection, setShowAddSection }) => {

    const { t } = useTranslation();

    const [sectionForAdd, setSectionForAdd] = useState('');
    const [errorDuplicationMessage, setErrorDuplicationMessage] = useState(null);

    //yup validation for new section
    const validationLabel = Yup.object().shape({
        sectionForAdd: Yup.string().trim().required(),
    });

    //check state validation for new section
    const checkValidation = validationLabel.isValidSync({ sectionForAdd });

    const handleAddSection = useCallback(async () => {
        try {
            const data = {
                sectionForAdd
            };

            const response = await axios.patch(`medical-file-templates/addSection/${template.id}`, data);

            if (response.status === 200 || response.status === 201) {
                setTemplate((prevData) => ({ ...prevData, sections: response.data }));
                setSectionForAdd('');
                setShowAddSection(false);
            }
        } catch (err) {
            console.error(err);
            CustomErrorLogger(err, 'Error adding new section to template');
            setErrorDuplicationMessage(err.message);
        }
    }, [sectionForAdd, template.id, setTemplate, setShowAddSection]);

    return (
        <Dialog open={showAddSection} keepMounted onClose={() => setShowAddSection(false)}>
            <DialogTitle>{t('addNewSection')}</DialogTitle>

            {errorDuplicationMessage && <Alert severity="error">{errorDuplicationMessage}</Alert>}

            <DialogContent>
                <TextField
                    fullWidth
                    required
                    autoFocus
                    margin="dense"
                    id="sectionName"
                    name='sectionName'
                    value={sectionForAdd}
                    label={t('sectionName')}
                    type="text"
                    variant="outlined"
                    onChange={e => setSectionForAdd(e.target.value)}
                    error={!sectionForAdd}
                    helperText={!sectionForAdd && t('isRequired')}
                />
            </DialogContent>
            <DialogActions>
                <Button className='new-button' fullWidth onClick={() => setShowAddSection(false)}>{t('cancel')}</Button>
                <Button className='new-button' fullWidth disabled={!checkValidation} onClick={handleAddSection}>{t('add')}</Button>
            </DialogActions>
        </Dialog>
    );
};
