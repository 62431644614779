import ThemeProvider from './theme';
import { useEffect } from 'react';
import io from 'socket.io-client';
import Router from './routes';
import axios from 'axios';
import './interceptors/refreshToken';
import './app.css';

export const socket = io(window?.env?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL);
export const version = "1.12.11";

export default function App() {

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const { status, data } = await axios.get('auth/CurrentVersion');
        if ((status === 200 || status === 201) && version !== data) {
          window.location.reload(true);
        }
      } catch (error) {
        console.error('Error fetching version:', error.message);
      }
    };

    const intervalId = setInterval(checkVersion, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(intervalId);

  }, []);

  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}
