import { Box, Stack, LinearProgress, InputBase, Typography, InputAdornment, Accordion, AccordionDetails, Tooltip, AccordionSummary } from '@mui/material';
import { DeletePermissionGroup } from '../components/Permissions/permissionGroups/deletePermissionGroup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useState, useEffect, useCallback } from 'react';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import { HasPermission } from "../utils/checkUserPermission";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useDebounce from '../hooks/useDebounce';
import Iconify from '../components/Iconify';
import '../css/permissions/permissions.css';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import axios from 'axios';

export const PermissionGroups = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userData);

    //check user permissions
    const isAllowedEditPermissions = HasPermission('Edit_Speciality');

    const [id, setId] = useState('');
    const [openDeleteGroup, setOpenDeleteGroup] = useState(false);

    const [loading, setLoading] = useState(true);
    const [allGroups, setAllGroups] = useState([]);

    //filtration states
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 1000);

    const fetchPermissions = useCallback(async () => {
        try {
            const response = await axios.get(`permission-entity`, { params: { searchTerm: debouncedSearch } });
            if (response.status === 200 || response.status === 201) {
                setAllGroups(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            CustomErrorLogger(error, 'Error fetching permissions entities in permissions main page');
        }
    }, [debouncedSearch]);

    // Fetch permissions once on component mount
    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions, userData.local_code]);

    const handleChangeSearch = (searchWord) => {
        setSearch(searchWord);
    };

    const handleDeleteGroupOpen = useCallback((id) => {
        setOpenDeleteGroup(true);
        setId(id);
    }, [])


    // Function to generate tooltip content
    const generateTooltipContent = (permission, t) => {
        return (
            <Box>
                {permission?.PermissionDescription[0]?.label && (
                    <Typography variant="caption" color="textSecondary">{permission.PermissionDescription[0].label}</Typography>
                )}
                {permission.parentDependencies?.length > 0 && (
                    <Typography variant="caption" component="div" color={"#FFA500"}>
                        {t('parentPermissions')}: {permission.parentDependencies.map(parent => parent?.childPermission?.PermissionName[0]?.label)?.join(' or ')}
                    </Typography>
                )}
                {permission.childDependencies?.length > 0 && (
                    <Typography variant="caption" component="div" color={"#E53935"}>
                        {t('childPermissions')}: {permission.childDependencies.map(parent => parent?.parentPermission?.PermissionName[0]?.label)?.join(' and ')}
                    </Typography>
                )}
            </Box>
        );
    };

    const updateGroupOrder = async (groupOrderData) => {
        try {
            await axios.patch('permission-entity/updateGroupsOrder', groupOrderData);
        } catch (error) {
            console.error('Failed to update order:', error);
            CustomErrorLogger(error, 'Failed to update group permission order in permissions group page');
        }
    };

    const updatePermissionOrder = async (permissionsOrderData) => {
        try {
            await axios.patch('permission/updatePermissionsOrder', permissionsOrderData);
        } catch (error) {
            console.error('Failed to update order:', error);
            CustomErrorLogger(error, 'Failed to update permission order in permissions group page');
        }
    };

    const onDragEndGroupPermission = (result) => {
        if (!result.destination) {
            return;
        }

        const newGroups = Array.from(allGroups);
        const [movedEntity] = newGroups.splice(result.source.index, 1);
        newGroups.splice(result.destination.index, 0, movedEntity);

        // Update the order property
        const updatedGroups = newGroups.map((group, idx) => ({
            ...group,
            order: idx + 1
        }));

        setAllGroups(updatedGroups);
        updateGroupOrder(updatedGroups.map(group => group.id));
    };

    const onDragEndPermissions = (result, groupId) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        // Find the group by groupId and reorder the permissions within that group
        const groupIndex = allGroups.findIndex(group => group.id === groupId);
        if (groupIndex === -1) {
            return;
        }

        const newGroups = [...allGroups];
        const newPermissions = Array.from(newGroups[groupIndex].Permission);
        const [movedPermission] = newPermissions.splice(source.index, 1);
        newPermissions.splice(destination.index, 0, movedPermission);

        // Update the specific group's permissions
        newGroups[groupIndex] = {
            ...newGroups[groupIndex],
            Permission: newPermissions
        };

        setAllGroups(newGroups);
        updatePermissionOrder(newPermissions.map(permission => permission.id));

    };

    return (
        <Page title={t("credentialsManagement")}>
            <Stack className="permissions-main-page-header">
                <Box className="permissions-page-title-box">
                    <Box className="permissions-page-title-icon-box">
                        <Iconify className="permissions-page-title-icon" icon="arcticons:permissionsmanager" />
                        <Typography className='permissions-page-title'>{t("credentialsManagement")}</Typography>
                    </Box>
                    <InputBase
                        className='permissions-page-search-input'
                        id="outlined-password-input"
                        label={t('search')}
                        type="text"
                        placeholder={t('search')}
                        value={search || ""}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        endAdornment={
                            search && (
                                <InputAdornment position="end">
                                    <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                                </InputAdornment>
                            )
                        }
                    />
                </Box>
                <Box className="role-page-action-box">
                    {isAllowedEditPermissions &&
                        <Box className='role-page-header-button' onClick={() => navigate('create')}>
                            <Iconify icon="eva:plus-fill" className="plus-icon" />
                            <Typography className="new-button-title">{t('Create')}</Typography>
                        </Box>
                    }
                </Box>
            </Stack>

            {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} />
                :
                <DragDropContext onDragEnd={onDragEndGroupPermission}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {allGroups && allGroups.map((entity, index) => (
                                    <Draggable key={entity.id} draggableId={entity.id.toString()} index={index}>
                                        {(provided) => (
                                            <Accordion
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', '&:before': { display: 'none' } }}
                                            >
                                                <AccordionSummary
                                                    {...provided.dragHandleProps}

                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{ margin: '0 0 10px 0', padding: 0, backgroundColor: '#ebeef4', borderRadius: '5px', display: 'flex', flexDirection: 'row-reverse', gap: '5px' }}
                                                >
                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                                                        <Tooltip title={entity.PermissionEntityDescription[0].label} arrow>
                                                            <Typography variant="h6" component="div">{entity.PermissionEntityName[0].label}</Typography>
                                                        </Tooltip>
                                                        <Box onClick={(event) => event.stopPropagation()} sx={{ cursor: 'default', display: 'flex', gap: '5px', paddingRight: '5px' }}>
                                                            <EditIcon onClick={() => navigate(`edit/${entity.id}`)} className='permissions-actions-btn' data-testid="editIcon" />
                                                            <DeleteIcon onClick={() => handleDeleteGroupOpen(entity.id)} className='new-delete-icon actions-btn' data-testid="deleteIcon" />
                                                        </Box>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ margin: 0, padding: 0 }}>
                                                    <DragDropContext onDragEnd={(result) => onDragEndPermissions(result, entity.id)}>
                                                        <Droppable droppableId={`droppable-${entity.id}`} type={`permission`}>
                                                            {(provided) => (
                                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                    {entity.Permission.map((permission, index) => (
                                                                        <Draggable key={permission.id} draggableId={permission.id.toString()} index={index}>
                                                                            {(provided) => (
                                                                                <Box ref={provided.innerRef} {...provided.draggableProps}  {...provided.dragHandleProps} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
                                                                                    <Tooltip title={generateTooltipContent(permission, t)} arrow>
                                                                                        <Typography>{permission?.PermissionName[0]?.label}</Typography>
                                                                                    </Tooltip>
                                                                                    <Box onClick={(event) => event.stopPropagation()} sx={{ cursor: 'default', display: 'flex', gap: '5px', paddingRight: '5px' }}>
                                                                                        <EditIcon onClick={() => navigate(`permissions/edit/${permission.id}`)} className='permissions-actions-btn' data-testid="editIcon" />
                                                                                    </Box>
                                                                                </Box>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }

            {openDeleteGroup &&
                <DeletePermissionGroup
                    id={id}
                    openDeleteGroup={openDeleteGroup}
                    setOpenDeleteGroup={setOpenDeleteGroup}
                    setAllGroups={setAllGroups}
                />
            }

        </Page>
    );
};