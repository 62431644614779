import { Stack, Typography, Box, useMediaQuery, Divider, InputBase, InputAdornment, IconButton, Menu, MenuItem } from "@mui/material";
import { InstitutionsUsersList } from "../components/Users/InstitutionsUsersList";
import { InvitedUsers } from "../components/Users/InvitedUsers";
import { AcceptUsers } from "../components/Users/AcceptUsers";
import React, { useState, useCallback, useMemo } from "react";
import { HasPermission } from "../utils/checkUserPermission";
import { useLocation, useNavigate } from "react-router-dom";
import { UsersList } from "../components/Users/UsersList";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import useDebounce from "../hooks/useDebounce";
import TabContext from '@mui/lab/TabContext';
import Iconify from "../components/Iconify";
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Page from "../components/Page";
import Tab from '@mui/material/Tab';
import '../css/User-css/users.css';

export default function User() {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  //check user permissions
  const isAllowedReadAllUsers = HasPermission('Read_All_Users');
  const isAllowedCreateAllUsers = HasPermission('Create_All_User');
  const isAllowedReadInstitutionUser = HasPermission('Read_Institution_User');
  const isAllowedCreateInstitutionUser = HasPermission('Create_Institution_User');
  const isAllowedAcceptOrRefuseUsers = HasPermission('AcceptOrRefuse_Users_Requests');

  const [anchorEl, setAnchorEl] = useState(null);
  const openActionMenu = Boolean(anchorEl);

  const [value, setValue] = useState(location?.state || "2");
  const [openFilter, setOpenFilter] = useState(false);
  // filtration states
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 1000);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    setSearch("");
    setOpenFilter(false);
  }, []);

  const handleOpenActionMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseActionMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch("");
  }, []);

  const tabStyles = useMemo(() => ({
    height: "50px",
    color: "#A8ADB8",
    fontWeight: "bold",
    borderBottom: "none",
  }), []);

  return (
    <Page title={t("sideBar-Users")}>

      <Stack className="user-main-page-header">
        <Box className="user-page-title-box">
          <Box className="user-page-title-icon-box">
            <Iconify className="user-page-title-icon" icon="eva:people-fill" />
            <Typography className='user-page-title'>{t("sideBar-Users")}</Typography>
          </Box>
          <InputBase
            className='user-page-search-input'
            id="outlined-password-input"
            label={t('search')}
            type="text"
            placeholder={t('search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            endAdornment={
              search && (
                <InputAdornment position="end">
                  <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={handleClearSearch} />
                </InputAdornment>
              )
            }
          />
        </Box>

        {isDesktop && <Box className="user-page-action-box">
          {(value === '1' || value === '2') &&
            <Box className='user-page-header-button' onClick={() => { setOpenFilter(true) }}>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Iconify icon="mdi:filter" className="plus-icon" />
              <Typography className="new-button-title">{t('actionFilter')}</Typography>
            </Box>}

          {(isAllowedCreateAllUsers || isAllowedCreateInstitutionUser) &&
            <Box className='user-page-header-button' onClick={() => navigate('add')}>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Iconify icon="mdi:user" className="plus-icon" />
              <Typography className="user-button-title">{t('new-users')}</Typography>
            </Box>
          }
        </Box>}

        {!isDesktop &&
          <IconButton
            className="main-page-threeDot-icon"
            id="userPageActionMenuIcom"
            aria-controls={openActionMenu ? 'long-menu' : undefined}
            aria-expanded={openActionMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOpenActionMenu}
          >
            <MoreHorizIcon />
          </IconButton>
        }

        <Menu
          id="usersePageActionMenu"
          MenuListProps="usersPageActionMenuIcom"
          open={openActionMenu}
          anchorEl={anchorEl}
          onClose={handleCloseActionMenu}
          onClick={handleCloseActionMenu}
          slotProps={{ paper: { style: { width: '30ch', backgroundColor: '#453f3f' } } }}
        >
          {(isAllowedCreateAllUsers || isAllowedCreateInstitutionUser) &&
            <MenuItem className='main-page-header-button' onClick={() => navigate('add')}>
              <Iconify icon="eva:plus-fill" className="main-page-threeDot-icon" />
              <Typography>{t('new-users')}</Typography>
            </MenuItem>}
          <MenuItem className='main-page-header-button' onClick={() => { setOpenFilter(true); }}>
            <Iconify icon="mdi:filter" className="main-page-threeDot-icon" />
            <Typography>{t('actionFilter')} </Typography>
          </MenuItem>
        </Menu>
      </Stack>

      <Box sx={{ display: "flex", alignItems: 'flex-end' }}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value} >

            <Box sx={{ width: '100%', bgcolor: 'white', display: 'flex', height: '50px', alignItems: 'center', boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.03)' }}>
              <TabList variant="scrollable" allowScrollButtonsMobile onChange={handleChange}>
                {isAllowedReadAllUsers && <Tab label={t("allUsers")} value="1" sx={{ ...tabStyles, color: value === "1" ? "#17536B" : "#A8ADB8", borderBottom: value === "1" ? "3px solid #17536B" : "none" }} />}
                {isAllowedReadInstitutionUser && <Tab label={t("ourUsers")} value="2" sx={{ ...tabStyles, color: value === "2" ? "#17536B" : "#A8ADB8", borderBottom: value === "2" ? "3px solid #17536B" : "none" }} />}
                {isAllowedAcceptOrRefuseUsers && <Tab label={t("acceptUsers")} value="3" sx={{ ...tabStyles, color: value === "3" ? "#17536B" : "#A8ADB8", borderBottom: value === "3" ? "3px solid #17536B" : "none" }} />}
                {(isAllowedCreateAllUsers || isAllowedCreateInstitutionUser) && <Tab label={t("invitedUsers")} value="4" sx={{ ...tabStyles, color: value === "4" ? "#17536B" : "#A8ADB8", borderBottom: value === "4" ? "3px solid #17536B" : "none" }} />}
              </TabList>
            </Box>

            <TabPanel value="1" sx={{ padding: '0px' }}>
              <UsersList
                search={debouncedSearch}
                setSearch={setSearch}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: '0px' }}>
              <InstitutionsUsersList
                search={debouncedSearch}
                setSearch={setSearch}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: '0px' }}><AcceptUsers /></TabPanel>
            <TabPanel value="4" sx={{ padding: '0px' }}><InvitedUsers /></TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Page>
  );
}