import { Box, Typography, CircularProgress, IconButton, TextField, Checkbox } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import Iconify from '../../Iconify';
import axios from 'axios';

const filter = createFilterOptions();

export const MultiSelect = ({ allowedActions, info, handleUpdateOneFieldData }) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false); // Open the dropdown
    const [options, setOptions] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [value, setValue] = useState(Array.isArray(info.value) ? info.value : []);
    const [inputValue, setInputValue] = useState('');

    // Fetch options from the API
    const fetchOneSelectList = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await axios.get(`select-list/${info.listId}`);
            const options = response?.data?.select_list_values?.map(value => value?.select_list_values_label[0]?.label) || [];
            setOptions(Array.isArray(options) ? options : []);
        } catch (err) {
            console.log(err);
        } finally {
            setIsFetching(false);
        }
    }, [info.listId]);

    const handleRemove = (valToRemove) => {
        const updatedValues = value.filter(val => val !== valToRemove);
        setValue(updatedValues);
        handleUpdateOneFieldData(info.id, updatedValues);
        setInputValue('');
    }

    const handleAutocompleteChange = useCallback((event, newValue) => {
        // Convert all values to strings (handle both existing and newly created options)
        const stringValues = newValue.flatMap((val) => typeof val === 'string' ? val : val.inputValue || val.title);
        const newValueToToggle = stringValues[0];

        setValue((prevValues) => {
            let updatedValues;
            if (prevValues.includes(newValueToToggle)) {
                // If the value exists, remove it
                updatedValues = prevValues.filter((val) => val !== newValueToToggle);
            } else {
                // If the value doesn't exist, add it
                updatedValues = [...prevValues, newValueToToggle];
            }

            handleUpdateOneFieldData(info.id, updatedValues);
            return updatedValues;
        });
        // Reset input value
        setInputValue('');
    }, [handleUpdateOneFieldData, info.id]);

    const customFilterOptions = useCallback((options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        if (inputValue && !options.includes(inputValue)) {
            filtered.push({
                inputValue,
                title: `${t('add')} "${inputValue}"`,
            });
        }

        return filtered;
    }, [t]);

    const renderOption = useCallback((props, option, { index }) => {
        if (index === 0) {
            return (
                <>
                    {/* Helper text as a non-interactive list item */}
                    <li>
                        <Box className='fieldSelect_helper_text_box'>
                            <Iconify icon="akar-icons:info" />
                            <Typography className="imeahCaption"> {t("currentOptionAvailableAreOnlyEnglish")}</Typography>
                        </Box>
                    </li>
                    {/* Render the actual option */}
                    <li {...props} style={{ color: option.inputValue ? '#16536B' : 'inherit' }}>
                        {options.includes(option) && (
                            <Checkbox
                                checked={value.includes(option)}
                                className="imeahCheckbox"
                            />
                        )}
                        <Typography>{option.title || option}</Typography>
                    </li>
                </>
            );
        }

        // Default rendering for other options
        return (
            <li {...props} style={{ color: option.inputValue ? '#16536B' : 'inherit' }}>
                {options.includes(option) && (
                    <Checkbox
                        checked={value.includes(option)}
                        className="imeahCheckbox"
                    />
                )}
                <Typography>{option.title || option}</Typography>
            </li>
        );
    }, [options, t, value]);

    const renderInput = useCallback((params) => (
        <TextField
            {...params}
            className="customTextField"
            placeholder={t('selectOptionPlaceHolder')}
            InputProps={{
                ...params.InputProps,
                style: {
                    ...params.InputProps.style,
                    opacity: params.disabled ? 0.5 : 1,
                },
                endAdornment: isFetching ? (
                    <CircularProgress size={20} />
                ) : (
                    <Iconify
                        onClick={() => setOpen(prev => !prev)}
                        icon='iconamoon:arrow-down-2-light'
                        className='fieldSelect_dropdown_icon'
                    />
                ),
            }}
        />
    ), [t, isFetching]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>

            <Typography className='medicalFileFields_Field_label'>{info.label}</Typography>

            {allowedActions?.canEditPrivateMedicalFile &&
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    freeSolo
                    id={info.id}
                    open={open} // controlled open state
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={[]}
                    disabled={!allowedActions?.canEditPrivateMedicalFile}
                    inputValue={inputValue}
                    onChange={handleAutocompleteChange}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Update input value
                    filterOptions={customFilterOptions}
                    onFocus={fetchOneSelectList}
                    options={options}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') return option;// Value selected with enter, right from the input
                        if (option.inputValue) return option.inputValue; // Add "abc" option created dynamically
                        return option; // Regular option
                    }}
                    renderOption={renderOption}
                    renderInput={renderInput}
                />
            }

            {value?.length > 0 && (
                <Box display="flex" gap="10px" flexWrap="wrap">
                    {value.map((val, index) => (
                        <Box key={index} className="imeah_ships_box">
                            <Typography variant="body1">{val}</Typography>
                            {allowedActions?.canEditPrivateMedicalFile &&
                                <IconButton onClick={() => handleRemove(val)} className="imeah_ships_button">
                                    <CloseIcon className="imeah_ships_icon" />
                                </IconButton>
                            }
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};