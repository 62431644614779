import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { useTranslation } from 'react-i18next';
import React from 'react';
import axios from 'axios';

export const DeleteTemplateFieldInList = ({ template, setTemplate, openDeleteFieldInList, setOpenDeleteFieldInList, FieldId, listFieldId }) => {

    const { t } = useTranslation();

    const handleRemoveItemList = async () => {
        try {
            const data = { FieldId, listFieldId };
            const response = await axios.patch(`medical-file-templates/removeFieldInList/${template.id}`, data);

            if (response.status === 200 || response.status === 201) {
                setTemplate((prevData) => ({ ...prevData, additional_fields: response.data }));
                setOpenDeleteFieldInList(false);
            }
        } catch (err) {
            console.log(err, 'Error removing field from template');
            CustomErrorLogger(err, 'Error removing field from template');
        }
    };

    return (
        <Dialog open={openDeleteFieldInList} keepMounted onClose={() => setOpenDeleteFieldInList(false)}>
            <DialogTitle>{t('sureDeleteField')}</DialogTitle>
            <DialogActions>
                <Button className='new-button' fullWidth onClick={() => setOpenDeleteFieldInList(false)}>{t('cancel')}</Button>
                <Button className='new-button' fullWidth onClick={handleRemoveItemList}>{t('dlt')}</Button>
            </DialogActions>
        </Dialog>
    );
};
