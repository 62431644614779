import { Autocomplete, TextField, CircularProgress, FormControl } from "@mui/material";
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const MuiSelect = (props) => {

    const { t } = useTranslation();
    const { label, value, onChange, fetchFunction, required, searchable, className, valueField = 'value', labelField = 'label', canHaveSpeciality = 'canHaveSpeciality', canHaveExpertise = "canHaveExpertise" } = props;

    const [options, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");
    const limit = 10;

    const fetchOptions = useCallback(async (page, searchTerm) => {
        try {
            const newOptions = await fetchFunction(page, searchTerm);
            setOptions(prevOptions => page === 0 ? newOptions : [...prevOptions, ...newOptions]);
            setHasMore(newOptions.length >= limit);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setIsFetching(false);
        }
    }, [fetchFunction]);

    useEffect(() => {
        if (isFetching) {
            fetchOptions(page, search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, page, search]);

    useEffect(() => {
        setOptions([]);
        setPage(0);
        setSearch('');
        setIsFetching(true);
    }, []);

    const handleScroll = (event) => {
        const bottom = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 1;
        if (bottom && hasMore && !isFetching) {
            setPage(prevPage => prevPage + 1);
            setIsFetching(true);
        }
    };

    const handleOpen = () => {
        if (options.length === 0 && !isFetching) {
            setIsFetching(true);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        if (newInputValue !== search) {
            setOptions([]);
            setPage(0);
            setSearch(newInputValue);
            setIsFetching(true);
        }
    };

    const selectedOption = options.find(option => option[valueField] === value.value) || { [valueField]: value.value, [labelField]: value.label };

    return (
        <FormControl fullWidth>
            <Autocomplete
                id="autocomplete-select"
                value={value.value ? selectedOption : null}
                onChange={(event, newValue) => {
                    onChange({
                        target: {
                            value: newValue ? newValue[valueField] : null,
                            label: newValue ? newValue[labelField] : null,
                            ...(newValue && newValue[canHaveSpeciality] !== undefined && { canHaveSpeciality: newValue[canHaveSpeciality] }),
                            ...(newValue && newValue[canHaveExpertise] !== undefined && { canHaveExpertise: newValue[canHaveExpertise] }),
                        }
                    });
                    setSearch(""); // Reset the search value
                }}
                onOpen={handleOpen}
                options={options}
                getOptionLabel={(option) => option[labelField] || ""}
                isOptionEqualToValue={(option, value) => option[valueField] === value[valueField]}
                onInputChange={searchable ? handleInputChange : undefined}
                renderInput={(params) => (
                    <TextField
                        className={className}
                        required={required}
                        {...params}
                        label={label}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isFetching ? <CircularProgress size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        error={!value.value && required}
                        helperText={(!value.value && required) && ((t('isRequired')))}
                    />
                )}
                ListboxProps={{
                    onScroll: handleScroll,
                    style: {
                        maxHeight: 300,
                    },
                }}
            />
        </FormControl>
    );
};