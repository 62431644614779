import React from 'react'
import { Box, Typography, CardMedia } from '@mui/material'
import '../../css/homePage/homeSevenPart.css'
import books from '../../images/imeah-books.svg'
import { useTranslation } from "react-i18next";

export const HomeSevenPart = () => {
    const { t } = useTranslation();

    return (
        <Box className='homeSevenPart-main-box'>
            <Box className="homeSevenPart-typo-img-box">
                <Box className="homeSevenPart-typo-box">
                    <Typography className="homeSevenPart-typo-ehealth">ehealth pro</Typography>
                    <Typography className="homeSevenPart-typo-aussi">{t('providesYouLibrary')}</Typography>
                </Box>
                    <CardMedia
                        className="homeSevenPart-image"
                        component="img"
                        image={books}
                        alt="My Image"
                    />
            </Box>
            <Typography className="homeSevenPart-typo-dr-junior">{t('ehealthEvolvingResearch')}</Typography>
        </Box>
    )
}
