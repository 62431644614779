import { Divider, Grid, Box, Card, Typography, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import { customInputStyles } from '../../utils/CustomInputStyle';
import Iconify from '../Iconify';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import '../../css/institutions/institutionsFiltration.css';

export const InstitutionsFiltration = ({ emptyFiltrationObject, institutionTypes, selectedType, selectedActivityStatus, handleChangeFilterType, handleChangeFilterStatus, setOpenFilter }) => {

    const { t } = useTranslation();

    const ActionButton = ({ icon, title, onClick }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={onClick}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Iconify icon={icon} sx={{ color: '#17536B', height: '20px', width: '20px' }} />
            <Typography sx={{ color: '#17536B', fontWeight: '500' }}>{title}</Typography>
        </Box>
    );

    return (
        <Box className='institution-filtration-container'>
            <Card className='institution-filtration-card' >
                <Box className='institution-filtration-header-box'>
                    <Typography className='institution-filtration-header-title'>{t("Filter")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <ActionButton icon="material-symbols:filter-alt-off" title={t('clear_all_filter')} onClick={emptyFiltrationObject} />
                        <ActionButton icon="material-symbols:close" title={t('hideFilters')} onClick={() => setOpenFilter(false)} />
                    </Box>                </Box>
                <Divider />
                <Grid container spacing={2} marginTop={'5px'}>

                    {(institutionTypes?.length >= 2) &&
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth sx={{ ...customInputStyles }} >
                                <InputLabel id="demo-simple-select-label-setSelectedType">{t('type')} </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-setSelectedType"
                                    id="setSelectedType"
                                    label={t('type')}
                                    name="setSelectedType"
                                    value={selectedType || ""}
                                    onChange={(e) => handleChangeFilterType(e.target.value)}
                                    endAdornment={
                                        selectedType && (
                                            <InputAdornment position="start">
                                                <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeFilterType(null)} />
                                            </InputAdornment>
                                        )
                                    }
                                >
                                    {institutionTypes && institutionTypes.map((record) => (
                                        <MenuItem key={record.id} value={record}>{record.type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth sx={{ ...customInputStyles }} >
                            <InputLabel id="demo-simple-select-label-setSelectedActivityStatus">{t('Status')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-setSelectedActivityStatus"
                                id="setSelectedActivityStatus"
                                label={t('Status')}
                                name="setSelectedActivityStatus"
                                value={selectedActivityStatus || ""}
                                onChange={(e) => handleChangeFilterStatus(e.target.value)}
                                endAdornment={
                                    selectedActivityStatus && (
                                        <InputAdornment position="start">
                                            <CloseIcon sx={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => handleChangeFilterStatus(null)} />
                                        </InputAdornment>
                                    )
                                }
                            >
                                <MenuItem value="true">{t('ActiveInstitution')}</MenuItem>
                                <MenuItem value="false">{t('inActiveInstitution')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Card>

        </Box>
    );
};