import { Typography, TextField, Box, Alert, IconButton, InputAdornment, CircularProgress } from "@mui/material";
import { LanguageSwitcher } from "../components/NoLoginLanguageSwitcher";
import { PasswordStrengthMeter } from "../utils/PasswordStrengthMeter";
import React, { useState, useCallback, useEffect } from "react";
import { CustomErrorLogger } from "../utils/CustomErrorLogger";
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../images/logo_e_health_pro.svg';
import { useTranslation } from "react-i18next";
import '../css/User-css/UserResetPassword.css';
import Iconify from "../components/Iconify";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import { useFormik } from 'formik';
import axios from "axios";
import * as Yup from 'yup';

export const UserResetPassword = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessCodeId } = useParams();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageIsLoading, setPageisLoading] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(navigator.language.startsWith('en') ? 'en' : 'fr');

  const handleLangUpdate = useCallback((langData) => setLanguage(langData), []);

  useEffect(() => {
    const fetchData = async () => {
      setPageisLoading(true);
      setError(null); // Clear any previous errors
      try {
        const response = await axios.get(`forgot-password-access-code/${accessCodeId}`);
        if (response.status === 200 || response.status === 201) {
          setPageisLoading(false);
        }
      } catch (err) {
        setError(err.message, 'Error fetching access code data for reset password');
        CustomErrorLogger(err, 'Error fetching access code data for reset password');
      } finally {
        setPageisLoading(false);
      }
    };

    fetchData();
  }, [accessCodeId]);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .trim()
        .min(12, t('passwordMinLength'))
        .matches(/[a-z]/, t('passwordLowercase'))
        .matches(/[A-Z]/, t('passwordUppercase'))
        .matches(/[0-9]/, t('passwordNumber'))
        .matches(/[!@#$%^&*.]/, t('passwordSpecialChar'))
        .required(t('passwordRequired')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], t('passwordsMustMatch'))
        .required(t('confirmPasswordRequired')),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      setButtonLoading(true);
      setError(null);
      try {
        const response = await axios.put(`forgot-password/ChangePassforgotPassword/${accessCodeId}`, { hpassword: values.newPassword, language });
        if (response.status === 200 || response.status === 201) {
          navigate("/password_successfully_reset");
        }
      } catch (err) {
        setError(err.message);
        CustomErrorLogger(err, 'Error changing password for reset password');
      } finally {
        setButtonLoading(false);
      }
    },
  });

  const { handleSubmit, values, handleChange, handleBlur, touched, errors, isValid } = formik;

  return (
    <Page title="Reset Password" className='RP-container-pass'>

      {pageIsLoading ?
        <CircularProgress color="inherit" size="5rem" /> :

        <Box className='RP-card-pass' >

          <Box display='flex' justifyContent='space-between'>
            <Box sx={{ width: '200px' }} component="img" src={logo} alt="Imeah Logo" />
            <LanguageSwitcher handleLangUpdate={handleLangUpdate} />
          </Box>

          <Typography className="RP_pass_title">{t("creatOwnPass")}</Typography>

          <Typography color={'#949494'} variant='subtitle2'>{t('passwordHint')}</Typography>

          {error && <Alert className="login-errMsg" severity="error">{error}</Alert>}

          <form onSubmit={handleSubmit} className="RP_form">
            <TextField
              id="ForgotPassNewPassword"
              name="newPassword"
              label={t('newPassword')}
              type={showNewPassword ? "text" : "password"}
              fullWidth
              className="customTextField"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.newPassword && Boolean(errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword((show) => !show)} edge="end">
                      <Iconify
                        icon={
                          showNewPassword
                            ? "eva:eye-fill"
                            : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <PasswordStrengthMeter newPassword={values.newPassword} />

            <TextField
              id="ForgotPassConfirmPassword"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              label={t('confirmPassword')}
              className="customTextField"
              color={values.confirmPassword === values.newPassword ? "success" : "error"}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword((show) => !show)} edge="end">
                      <Iconify
                        icon={
                          showConfirmPassword
                            ? "eva:eye-fill"
                            : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton fullWidth loading={buttonLoading} className='RP_button_valider' type="submit" disabled={!isValid || buttonLoading} >{t("resetPassword")}</LoadingButton>
          </form>
        </Box>
      }

    </Page>
  );
}