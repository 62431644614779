/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { Box, TextField, Typography } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicOffIcon from '@mui/icons-material/MicOff';
import ChatIcon from '@mui/icons-material/Chat';
import { useTranslation } from 'react-i18next';
import { socket } from '../../App';
import Page from '../Page';
import Peer from 'peerjs';
import '../../css/VideoCall.css';

const LiveVideo = React.memo(() => {

    const { t } = useTranslation();
    const MeetingId = sessionStorage.getItem('MeetingId');
    const MeetingTitle = sessionStorage.getItem('MeetingTitle');
    const replaceChar = '_'; // Choose your desired character (e.g., '_', '-')
    // Generating a unique room ID for the meeting using meeting title and ID
    const roomId = useMemo(() => `${MeetingTitle.replace(/\s/g, replaceChar)}_${MeetingId}_4498`, [MeetingId, MeetingTitle]);
    const randomSuffix = Math.floor(Math.random() * 1000);

    // State variables for camera and audio enable/disable, chat state, and messages
    const [cameraEnabled, setCameraEnabled] = useState(true);
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [chatState, setChatState] = useState(false);
    const [messages, setMessages] = useState([]);

    // Refs for local video, video grid, and PeerJS
    const myPeerRef = useRef(new Peer(sessionStorage.getItem('MeetingUserName') + randomSuffix));
    const videoGridRef = useRef(null);
    const myVideoContainerRef = useRef(null);
    const myVideoRef = useRef(null);
    const myNameTagRef = useRef(null);
    const peers = useRef({});

    useEffect(() => {
        // Get user media stream for video and audio
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                // Add local video stream to video grid
                addVideoStream(myVideoRef.current, myNameTagRef.current, myVideoContainerRef.current, stream, sessionStorage.getItem('MeetingUserName'), myPeerRef.current.id);
                // Event listener for incoming calls
                myPeerRef.current.on('call', call => {
                    const VideoContainer = document.createElement('div');
                    const Video = document.createElement('video');
                    const NameTag = document.createElement('p');
                    const name = call.peer;
                    const nameWithoutNumbers = name.replace(/\d+/g, '');

                    call.answer(stream);
                    call.on('stream', userVideoStream => addVideoStream(Video, NameTag, VideoContainer, userVideoStream, nameWithoutNumbers, call.peer));
                    call.on('close', () => VideoContainer.remove());

                });

                // Event listener for new user connection
                socket.on('newUserJoin', data => connectToNewUser(data.peerId, data.name, stream));

            });

        // Event listener for open connection
        socket.emit('join-meeting', { roomId, peerId: myPeerRef.current.id, name: sessionStorage.getItem('MeetingUserName') });

        // Event listener for incoming messages
        socket.on('newMeetingMessage', messageData => setMessages(prevMessages => [...prevMessages, messageData]));

        // Event listener for user disconnection
        socket.on('user-disconnected', peerId => removeVideoStream(peerId));

        // Cleanup function
        return () => {
            sessionStorage.removeItem('MeetingId');
            sessionStorage.removeItem('MeetingTitle');
            sessionStorage.removeItem('MeetingUserName');
            socket.off();
            myPeerRef.current.destroy();
        };

    }, []);


    // Function to add a video stream to the grid
    const addVideoStream = useCallback((video, nameTag, videoContainer, stream, targetName, peerId) => {

        video.srcObject = stream;
        video.addEventListener('loadedmetadata', () => {
            video.play();
        });

        videoContainer.setAttribute('id', `video-container-${peerId}`);
        nameTag.setAttribute('id', "nameTag")
        nameTag.innerHTML = targetName;

        videoContainer.append(video);
        videoContainer.append(nameTag);
        videoGridRef.current.append(videoContainer);

        // Update the video grid layout
        updateVideoGrid();

    }, []);

    // Function to connect to a new user
    const connectToNewUser = useCallback((peerId, name, stream) => {

        const call = myPeerRef.current.call(peerId, stream);
        const VideoContainer = document.createElement('div');
        const video = document.createElement('video');
        const nameTag = document.createElement('p');

        call.on('stream', userVideoStream => {
            addVideoStream(video, nameTag, VideoContainer, userVideoStream, name, peerId);
        });

        call.on('close', () => {
            VideoContainer.remove();
        });

        peers.current[peerId] = call;
    }, []);

    // Function to remove a video stream from the grid
    const removeVideoStream = useCallback((peerId) => {
        if (peers.current[peerId]) {
            peers.current[peerId].close();
            delete peers.current[peerId];
        }

        const oldVideoContainer = document.getElementById(`video-container-${peerId}`);
        if (oldVideoContainer) {
            oldVideoContainer.remove();
        }
    }, []);

    // Function to update the video grid layout
    const updateVideoGrid = useCallback(() => {

        // Calculate responsive video size based on the number of videos in the grid
        const numVideos = videoGridRef.current.childElementCount;
        const desktopRows = Math.ceil(numVideos / 3);
        const mobileRows = Math.ceil(numVideos / 2);

        let videoWidth, videoHeight;

        if (window.innerWidth <= 450) {
            // Apply responsive size for smaller devices
            if (numVideos === 1) {
                videoWidth = '95%';
                videoHeight = '85vh';
            } else if (numVideos === 2) {
                videoWidth = '95%';
                videoHeight = '40vh';
            } else {
                videoWidth = '47%';
                videoHeight = `${85 / mobileRows}vh`;
            }
        } else {
            // Apply default size for larger devices
            if (numVideos === 1) {
                videoWidth = '95%';
                videoHeight = '85vh';
            } else if (numVideos === 2) {
                videoWidth = '47%';
                videoHeight = '85vh';
            } else {
                videoWidth = '30%';
                videoHeight = `${85 / desktopRows}vh`;
            }
        }

        // Set styles for each video container
        Array.from(videoGridRef.current.children).forEach(container => {
            container.style.width = videoWidth;
            container.style.height = videoHeight;
            container.style.position = 'relative';
        });
    }, []);

    // Function to toggle audio mute/unmute
    const muteUnmute = useCallback(() => {
        const enabled = myVideoRef.current.srcObject.getAudioTracks()[0].enabled;
        setAudioEnabled(!enabled);
        myVideoRef.current.srcObject.getAudioTracks()[0].enabled = !enabled;
    }, []);

    // Function to toggle video play/stop
    const playStop = useCallback(() => {
        const enabled = myVideoRef.current.srcObject.getVideoTracks()[0].enabled;
        setCameraEnabled(!enabled);
        myVideoRef.current.srcObject.getVideoTracks()[0].enabled = !enabled;
    }, []);

    // Function to toggle chat state
    const handleChatToggle = useCallback(() => {
        setChatState(oldChatState => !oldChatState);
    }, []);

    // Function to send meeting message
    const handleSendMeetingMassage = useCallback((event, message) => {
        if (event.key === 'Enter' && message.trim() !== '') {
            socket.emit('message', message);
            event.target.value = '';
        }
    }, []);

    // Function to handle leaving the call
    const handleLeaveCall = useCallback(() => {
        // Close the local media stream
        if (myVideoRef.current && myVideoRef.current.srcObject) {
            myVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }

        // Destroy the Peer instance
        if (myPeerRef.current) {
            myPeerRef.current.destroy();
        }

        // Emit the leave meeting event
        socket.emit('leave_meeting');

        // Remove items from session storage
        sessionStorage.removeItem('MeetingId');
        sessionStorage.removeItem('MeetingTitle');
        sessionStorage.removeItem('MeetingUserName');

        // Potentially close the window or redirect the user
        window.close();
    }, []);

    return (
        <Page title={t('Live_Meeting_Page_Title')}>
            <Box className="main">
                <Box className={`${chatState ? 'main__left_part_width' : 'main__left_full_width'}`}>
                    <Box id="video-grid" ref={videoGridRef}>
                        <div ref={myVideoContainerRef}>
                            <video ref={myVideoRef} muted autoPlay></video>
                            <p ref={myNameTagRef}></p>
                        </div>
                    </Box>
                    <Box className="main__controls">
                        {audioEnabled ?
                            <KeyboardVoiceIcon className='onIcon' onClick={() => muteUnmute()} /> :
                            <MicOffIcon className='offIcon' onClick={() => muteUnmute()} />
                        }
                        {cameraEnabled ?
                            <VideocamIcon className='onIcon' onClick={() => playStop()} /> :
                            <VideocamOffIcon className='offIcon' onClick={() => playStop()} />
                        }
                        <ChatIcon className='onIcon' onClick={() => handleChatToggle()} />
                        <CallEndIcon className='offIcon' onClick={handleLeaveCall} />
                    </Box>
                </Box>

                {chatState &&
                    <Box className="main__right">
                        <Box className="main__chat_window">
                            <Box className="messages">
                                {messages.map((message, index) => (
                                    <Box key={index} className="message">
                                        <Box>
                                            <Typography className={"messageSender"}>{message.sender}</Typography>
                                            <Typography>{message.text}</Typography>
                                        </Box>
                                    </Box>
                                ))
                                }
                            </Box>
                        </Box>
                        <Box className="main__message_container">
                            <TextField
                                sx={{ '& .MuiInputBase-input::placeholder': { fontSize: '12px' } }}
                                onKeyDown={(event) => handleSendMeetingMassage(event, event.target.value)}
                                placeholder="Type message here..."
                                variant="filled"
                                size="small"
                            />
                        </Box>
                    </Box>
                }

            </Box>
        </Page>
    );
});

export default LiveVideo;