import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Alert } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState, useCallback, useEffect } from 'react';
import { StyledFieldset } from '../../../utils/styledFieldSet';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Label from "../../Label";
import * as Yup from 'yup';
import axios from 'axios';

const useStyles = makeStyles(() => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#09C3CC !important',
    },
}));

export const EditTemplateListLabel = ({ template, setTemplate, editFieldLabelInList, setEditFieldLabelInList, fieldToEditLabel, FieldId }) => {

    const { t } = useTranslation();
    const classes = useStyles();

    const [selectlist, setSelectlist] = useState([]);
    const [label, setLabel] = useState(fieldToEditLabel?.ItemLabel);
    const [listItemType, setListItemType] = useState(fieldToEditLabel?.ItemType);
    const [listItemListId, setListItemListId] = useState(fieldToEditLabel?.listItemListId);
    const [allMultiValues, setAllMultiValues] = useState(fieldToEditLabel?.multiValues);
    const [newValue, setNewValue] = useState('');
    const [errorDuplicationMessage, setErrorDuplicationMessage] = useState(null);

    const validationNormaleListItem = Yup.object().shape({
        label: Yup.string().trim().required(),
        listItemType: Yup.string().trim().required(),
    });

    const validationSelectListItem = Yup.object().shape({
        label: Yup.string().trim().required(),
        listItemType: Yup.string().trim().required(),
        listItemListId: Yup.string().trim().required(),
    });

    const checkNormalField = validationNormaleListItem.isValidSync({ label, listItemType });
    const checkSelectField = validationSelectListItem.isValidSync({ label, listItemType, listItemListId });

    const fetchSelectList = useCallback(async () => {
        if (selectlist.length > 0 || (listItemType !== 'select' && listItemType !== 'Multi_select')) return; // Avoid duplicate fetches
        try {
            const response = await axios.get(`select-list/getSpecificSelectEdit`, {
                params: {
                    specialityId: template.speciality.id,
                    expertiseId: template.expertise.id,
                    fieldType: listItemType === 'select' ? 'Select' : 'Multi_select'
                },
            });
            if (response.status === 200 || response.status === 201) {
                setSelectlist(response.data);
            }
        } catch (error) {
            console.error("Error fetching select list:", error);
            CustomErrorLogger(error, 'Error fetching select list to edit field label in template');
        }
    }, [listItemType, selectlist.length, template.expertise.id, template.speciality.id]);

    useEffect(() => {
        fetchSelectList();
    }, [fetchSelectList, listItemType]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const item = Array.from(allMultiValues);
        const [reorderedItem] = item.splice(result.source.index, 1);
        item.splice(result.destination.index, 0, reorderedItem);
        setAllMultiValues(item);
    };

    const handlePushValue = () => {
        if (!newValue || allMultiValues.includes(newValue)) {
            setErrorDuplicationMessage(t('attributeNotAccepted'));
        }
        else {
            setAllMultiValues((prevValues) => [...prevValues, newValue]);
            setNewValue('');
            setErrorDuplicationMessage(null);
        }
    };

    const handleRemoveValue = (item) => {
        setAllMultiValues((prevValues) => prevValues.filter((value) => value !== item));
    };

    const handleChangeType = (Type) => {
        setListItemType(Type);
        setListItemListId('');
        setAllMultiValues([]);
        setSelectlist([]);
    }

    const handleEditLabel = async () => {

        const data = {
            fieldToEditLabel,
            FieldId,
            label,
            listItemType,
            listItemListId,
            multiValues: listItemType === "Multi_value" ? allMultiValues : [],
            ItemValue: (listItemType === 'Multi_select' || listItemType === 'Multi_value') ? [] : "",
        };

        const isEditValid =
            (listItemType === 'Multi_select' && checkSelectField) ||
            (listItemType === 'select' && checkSelectField) ||
            (listItemType !== 'Multi_select' && listItemType !== 'select' && checkNormalField);

        if (isEditValid) {
            try {
                const response = await axios.patch(`medical-file-templates/editFieldLabelInList/${template.id}`, data);

                if (response.status === 200 || response.status === 201) {
                    setTemplate((prevData) => ({ ...prevData, additional_fields: response.data }));
                    setEditFieldLabelInList(false);
                }
            } catch (err) {
                console.log(err, 'Error editing field label in list in template');
                CustomErrorLogger(err, 'Error editing field label in list in template');
                setErrorDuplicationMessage(err.message);
            }
        }
    };

    return (
        <Dialog open={editFieldLabelInList} keepMounted onClose={() => setEditFieldLabelInList(false)}>
            <DialogTitle>{t('Change_Field_Title')}</DialogTitle>
            <DialogContent>

                {errorDuplicationMessage && <Alert severity="error">{errorDuplicationMessage}</Alert>}

                <TextField
                    fullWidth
                    required
                    autoFocus
                    margin="dense"
                    id="label"
                    name='label'
                    label={t('label')}
                    type="text"
                    value={label}
                    variant="outlined"
                    onChange={e => setLabel(e.target.value)}
                    error={!label}
                    helperText={!label && t('isRequired')}
                />
                <FormControl required fullWidth className='form-control'
                    error={!listItemType}
                    helperText={!listItemType && t('isRequired')}>
                    <InputLabel id="demo-simple-select-label-type">{t('itemType')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label-type"
                        id="ItemType"
                        label={t('itemType')}
                        name='ItemType'
                        value={listItemType}
                        onChange={(e) => handleChangeType(e.target.value)}
                    >
                        <MenuItem value={'text'}>{t('text')}</MenuItem>
                        <MenuItem value={'number'}>{t('number')}</MenuItem>
                        <MenuItem value={'date'}>{t('date')}</MenuItem>
                        <MenuItem value={'long Text'}>{t('longText')}</MenuItem>
                        <MenuItem value={'password'}>{t('password')}</MenuItem>
                        <MenuItem value={'select'}>{t('select')}</MenuItem>
                        <MenuItem value={'Multi_select'}>{t('multiSelect')}</MenuItem>
                        <MenuItem value={'Multi_value'}>{t('multiValue')}</MenuItem>
                        <MenuItem value={'file'}>{t('file')}</MenuItem>
                    </Select>
                </FormControl>
                {(listItemType === 'select' || listItemType === 'Multi_select') &&
                    <FormControl required fullWidth className='form-control'
                        error={!listItemListId}
                        helperText={!listItemListId && t('isRequired')}>
                        <InputLabel id="demo-simple-select-label">{t('selectList')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="selectlist"
                            label={t('selectList')}
                            name='selectlist'
                            value={listItemListId}
                            onChange={(e) => setListItemListId(e.target.value)}
                        >
                            {selectlist && selectlist.map((list) =>
                                <MenuItem key={list.id} value={list.selectListId}>{list.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                }
                {(listItemType === 'Multi_value') &&
                    <Box>
                        <Box sx={{ display: "flex", alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                            <TextField
                                id="value"
                                name="value"
                                fullWidth
                                label={t('inputNewValue')}
                                type="text"
                                value={newValue}
                                variant="outlined"
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline,
                                    },
                                }}
                                onChange={(e) => setNewValue(e.target.value)}
                            />
                            <AddCircleIcon
                                data-testid="add-value"
                                className="circle-add-icon"
                                onClick={handlePushValue}
                            />
                        </Box>

                        {allMultiValues.length > 0 &&
                            <StyledFieldset component="fieldset" sx={{ marginTop: '10px' }}>
                                <legend>{t('values')}</legend>
                                <Box className='addTempField-servise-legend'>
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="values" direction="horizontal">
                                            {(provided) => (
                                                <Box {...provided.droppableProps} ref={provided.innerRef} className='addTempField-servise-box'>
                                                    {allMultiValues && allMultiValues.map((item, index) => (
                                                        <Draggable key={item} draggableId={item} index={index}>
                                                            {(provided) => (
                                                                <Typography {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                                    <Label>{item}</Label>
                                                                    <CloseRoundedIcon
                                                                        onClick={() => handleRemoveValue(item)}
                                                                        className="close-icon"
                                                                    />
                                                                </Typography>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </Box>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Box>
                            </StyledFieldset>
                        }
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button className='new-button' fullWidth onClick={() => setEditFieldLabelInList(false)}>{t('cancel')}</Button>
                <Button className='new-button' fullWidth onClick={handleEditLabel}>{t('save')}</Button>
            </DialogActions>
        </Dialog>

    );
};