import { Box, Typography, CircularProgress, Button, Menu, MenuItem } from '@mui/material';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDebounceFunction } from '../../../hooks/useDebounceFunction';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { CustomErrorLogger } from '../../../utils/CustomErrorLogger';
import { HasPermission } from '../../../utils/checkUserPermission';
import ImeahActionIcon from '../../../utils/imeah_action_icon';
import Recommandation from '../recommandation/Recommandation';
import { AddComplementaryField } from '../edit/addCompInfo';
import useResponsive from '../../../hooks/useResponsive';
import { EditStaticData } from '../edit/editStaticData';
// import { ToArchiveDialoge } from '../toArchiveDialoge';
import { DeleteField } from '../edit/deleteCompInfo';
import MedicalFileFields from './medicalFileFields';
// import { MedicalFileLogs } from '../MedicalFile_logs';
import { DiscussionMessages } from '../discussionTab';
import { TransferOneFile } from '../transferOneFile';
import ShareAgreement from '../shareAgreementDialog';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { FileNotFound } from '../fileNotFound';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { UnshareFile } from '../unshareFile';
import pdfMake from "pdfmake/build/pdfmake";
import ErrorDialog from '../../errorDialog';
import { StaticData } from './staticData';
import { useSelector } from 'react-redux';
import { amiriFont } from '../amiri-font';
import { DeleteOne } from '../deleteOne';
import ShareFile from '../shareFile';
import { saveAs } from 'file-saver';
import Iconify from '../../Iconify';
import FileTabs from './fileTabs';
import Page from '../../Page';
import * as XLSX from 'xlsx';
import axios from 'axios';
import '../../../css/medicalFiles/oneFile.css';

pdfMake.vfs = {
    ...pdfFonts?.pdfMake?.vfs,
    'Amiri-Regular.ttf': amiriFont, // Add the custom font
};

pdfMake.fonts = {
    Amiri: {
        normal: 'Amiri-Regular.ttf',
        bold: 'Amiri-Regular.ttf',
        italics: 'Amiri-Regular.ttf',
        bolditalics: 'Amiri-Regular.ttf'
    },
};

// Function to reverse Arabic words
const reverseArabicWords = (text) => {
    const arabicRegex = /[\u0600-\u06FF]/;
    if (arabicRegex.test(text)) {
        // Split the text by whitespace, reverse the order of words, and join them back
        return text.split(' ').reverse().join(' ');
    }
    return text;
};

export const MedicalFileDetails = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isDesktop = useResponsive('up', 'md');

    const { id } = useParams();
    const currentInstitution = useSelector(state => state.currentInstitution);
    const isOnline = useSelector((state) => state.onlineStatus);

    //check user permissions
    const isAllowedShareInstitutionMedicalFile = HasPermission('share_institution_Medical_File');
    const isAllowedShareMyMedicalFile = HasPermission('share_my_Medical_File');
    const isAllowedUnShareInstitutionMedicalFile = HasPermission('unshare_institution_Medical_File');
    const isAllowedUnShareMyMedicalFile = HasPermission('unshare_my_Medical_File');
    const isAllowedCopyInstitutionMedicalFile = HasPermission('copy_institution_Medical_File');
    const isAllowedCopyMyMedicalFile = HasPermission('copy_my_Medical_File');
    // const isAllowedArchiveInstitutionMedicalFile = HasPermission('archives_institution_Medical_File');
    // const isAllowedArchiveMyMedicalFile = HasPermission('archives_my_Medical_File');
    const isAllowedDeleteAllMedicalFile = HasPermission('delete_All_Medical_File');
    const isAllowedDeleteInstitutionMedicalFile = HasPermission('delete_institution_Medical_File');
    const isAllowedDeleteMyMedicalFile = HasPermission('delete_my_Medical_File');
    const isAllowedChangeMedicalFilesOwner = HasPermission('Edit_File_Owner_institution_Medical_File');
    const isAllowedExportInstitutionMedicalFile = HasPermission('export_institution_Medical_File');
    const isAllowedExportMyMedicalFile = HasPermission('export_my_Medical_File');
    // const isAllowedCloseInstitutionMedicalFile = HasPermission('close_institution_Medical_File');
    // const isAllowedCloseMyMedicalFile = HasPermission('close_my_Medical_File');
    const isAllowedEditInstitutionMedicalFile = HasPermission('edit_institution_Medical_File');
    const isAllowedEditMyMedicalFile = HasPermission('edit_my_Medical_File');
    const isAllowedExportMedicalFile = HasPermission('import_export_Medical_File');
    const isAllowedExportMedicalFileSharedWithMe = HasPermission('export_Medical_File_sharedWithMe');
    const isAllowedEditInstitutionSharedMedicalFile = HasPermission('edit_institution_shared_Medical_File');
    const isAllowedEditUserShraredMedicalFile = HasPermission('edit_user_shrared_Medical_File');

    const [Documents, setDocuments] = useState([]);
    const [data, setdata] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentSection, setCurrentSection] = useState("");
    const [FieldId, setFieldId] = useState('');
    const [isAgreementDialogOpen, setAgreementDialogOpen] = useState(false);
    const [isFileNotFoundDialogOpen, setFileNotFoundDialogOpen] = useState(false);
    const [openDeleteField, setOpenDeleteField] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [openToArchiveDialoge, setOpenToArchiveDialoge] = useState(false);
    const [editStaticData, setEditStaticData] = useState(false);
    const [dialogTransfer, setDialogTransfer] = useState(false);
    const [openUnShare, setOpenUnShare] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [targetPolicyCountry, setTargetPolicyCountry] = useState(null);
    // export menu state
    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const exportMenuOpen = Boolean(exportAnchorEl);
    const [allowedExportActions, setAllowedExportActions] = useState([]);
    // mobile menu state
    const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
    const mobileMenuOpen = Boolean(mobileAnchorEl);
    const [mobileMenuActions, setMobileMenuActions] = useState([]);
    //permission state
    const [allowedActions, setAllowedActions] = useState({
        canEditPrivateMedicalFile: false,
        canEditSharedMedicalFile: false,
        canShareMedicalFile: false,
        canUnShareMedicalFile: false,
        canCopyMedicalFile: false,
        canDeleteMedicalFile: false,
        canChangeOwnerMedicalFile: false,
        canExportMedicalFile: false,
        canExportMedicalFileAsZip: false,
    });

    // Effect to set the current section based on location state, localStorage, or fetched data
    useEffect(() => {
        // Retrieve the stored section from localStorage
        const storedSection = localStorage.getItem('candidateSection') || "";
        const parsedStoredSection = storedSection ? JSON.parse(storedSection) : {};
        const { tabId, fileId } = parsedStoredSection;
        const targetTabId = fileId === id ? tabId : "";

        // Use location.state if available, or storedSection, or default to an empty string
        let candidateSection = location?.state || targetTabId || "";

        if (data?.sections && data?.sections?.length > 0) {
            // Validate that candidateSection exists in the fetched data
            const isValidSection = data?.sections?.some(section => String(section.id) === String(targetTabId));

            // If candidateSection is empty or invalid, fall back to the first section
            if (!isValidSection) {
                candidateSection = String(data?.sections[0]?.id);
            }
        }

        // Update state and localStorage with the validated candidateSection
        setCurrentSection(candidateSection);
        localStorage.setItem('candidateSection', JSON.stringify({ tabId: candidateSection, fileId: id }));

    }, [location?.state, data?.sections, id]);

    const fetchMedicalFile = useCallback(async () => {

        if (!currentInstitution) return; // Early return for unauthenticated users
        try {
            const response = await axios.get(`medical-file/${id}`);
            if (response.status === 200 || response.status === 201) {
                setdata(response.data);
                setDocuments(response.data.fileDocuments);
                setLoading(false);
            }
        } catch (error) {
            // Handle the error, if it's a 423 status code, open the agreement dialog
            if (error.statusCode === 423) {
                setTargetPolicyCountry(error.message)
                setAgreementDialogOpen(true)
            }
            else if (error.statusCod !== 401) {
                console.error('Error fetching medical file:', error.message);
                setFileNotFoundDialogOpen(true);
            }
            CustomErrorLogger(error, 'Error fetching medical file');
        }
    }, [currentInstitution, id]);

    const handleAgreeFilePolicies = useCallback(async (PolicyId) => {
        try {
            const response = await axios.post(`access-medical-file-signatures`, { PolicyId, medicalFileId: id });
            if (response.status === 200 || response.status === 201) {
                setAgreementDialogOpen(false);
                fetchMedicalFile()
            }
        } catch (error) {
            console.error('Error update agreement :', error.message);
            CustomErrorLogger(error, 'Error accepting medical file policies');
        }
    }, [fetchMedicalFile, id]);

    useEffect(() => {
        if (!currentInstitution) return; // Early return for unauthenticated users
        fetchMedicalFile();
    }, [id, currentInstitution, fetchMedicalFile]);

    const handleUpdateOneFieldData = async (FieldId, newValue) => {
        if (!isOnline) {
            setErrorMessage('offlineError');
            return;
        }
        try {
            const response = await axios.patch(`medical-file/updateOneFieldData/${id}`, { FieldId, value: newValue });
            if (response.status === 200 || response.status === 201) {
                setdata(prevData => ({
                    ...prevData,
                    additional_information: prevData.additional_information.map(field =>
                        field.id === FieldId ? { ...field, value: newValue } : field
                    ),
                    medical_Files_logs: response.data.medical_Files_logs || prevData.medical_Files_logs // Keep existing logs if not returned
                }));
            }
        } catch (error) {
            console.error('Error update field value :', error.message);
            CustomErrorLogger(error, 'Error updating medical file field value');
            setErrorMessage('updateMedicalFileFieldError');
        }
    }

    const debouncedHandleUpdateOneFieldData = useDebounceFunction(handleUpdateOneFieldData, 1500);

    const handleUpdateOneListFieldData = async (FieldId, ItemId, newValue) => {
        if (!isOnline) {
            setErrorMessage('offlineError');
            return;
        }
        try {
            const response = await axios.patch(`medical-file/updateOneListFieldData/${id}`, { FieldId, ItemId, value: newValue });

            if (response.status === 200 || response.status === 201) {
                setdata(prevData => ({
                    ...prevData,
                    additional_information: prevData?.additional_information?.map(field =>
                        field.id === FieldId
                            ? {
                                ...field,
                                value: Array.isArray(field.value)
                                    ? field.value?.map(item =>
                                        item.id === ItemId ? { ...item, ItemValue: newValue } : item
                                    )
                                    : field.value
                            }
                            : field
                    ),
                    medical_Files_logs: response.data.medical_Files_logs || prevData.medical_Files_logs // Keep existing logs if not returned
                }));
            }
        } catch (error) {
            console.error('Error updating list field item:', error);
            CustomErrorLogger(error, 'Error updating list field item');
            setErrorMessage('updateMedicalFileFieldError');
        }
    };

    const debouncedHandleUpdateOneListFieldData = useDebounceFunction(handleUpdateOneListFieldData, 1500);

    const handleDeleteFieldOpen = (id) => {
        setFieldId(id)
        setOpenDeleteField(true)
    };

    const handleRemoveRedDotReceiver = useCallback(async (clickedField) => {

        const isTargetRedDotUser = clickedField?.TargetRedDot?.some(item => item === currentInstitution.id) ?? false;
        if (!isTargetRedDotUser || clickedField.section !== 'Complementary_Information123') return;

        try {
            const response = await axios.patch(`medical-file/removeFieldRedDotReceiver/${id}`, { FieldId: clickedField.id });
            if (response.status === 200 || response.status === 201) {
                setdata(prevData => ({ ...prevData, additional_information: response.data }));
            }
        } catch (error) {
            CustomErrorLogger(error, 'Error removing red dot receiver');
            console.error('Error removing red dot receiver:', error);
        }

    }, [currentInstitution, id]);

    const exportMedicalFileAsZip = useCallback(async () => {
        try {
            const response = await axios.get(`medical-file/exportMedicalFileAsZip/${id}`, {
                responseType: 'blob', // Important to handle binary data
            });

            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Use file-saver to save the file
            saveAs(blob, `${id}.zip`);
        } catch (error) {
            console.error('Error exporting medical file:', error);
            CustomErrorLogger(error, 'Error exporting medical file as zip');
            setErrorMessage('exportMedicalFileError');
        }
    }, [id]);

    // Helper function to capitalize the first letter and lowercase the rest
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const exportToPDF = useCallback(async () => {
        try {
            const docDefinition = {
                content: [],
                defaultStyle: {
                    font: 'Amiri' // Use the custom font
                }
            };

            docDefinition.content.push({
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                stack: [
                                    { text: `Name: ${capitalizeFirstLetter(data.first_name)} ${capitalizeFirstLetter(data.last_name)}`, margin: [0, 5] }, { text: `File Number: ${data.file_number}`, margin: [0, 5] },
                                    { text: `Birth Date: ${data.birthDate}`, margin: [0, 5] },
                                    { text: `Birth Place: ${reverseArabicWords(data.BirthPlace)}`, margin: [0, 5] },
                                    { text: `Address: ${reverseArabicWords(data.address)}`, margin: [0, 5] },
                                    { text: `Age: ${data.age}`, margin: [0, 5] },
                                    { text: `Phone Number: ${data.Phone_number}`, margin: [0, 5] },
                                    { text: `Gender: ${data.gender}`, margin: [0, 5] }
                                ],
                                border: [false, false, false, true],
                                margin: [0, 5, 0, 15],
                                lineHeight: 1.5
                            }
                        ]
                    ]
                },
                layout: {
                    hLineWidth: function () { return 2; },
                    vLineWidth: function () { return 2; }
                }
            });

            const filteredSections = data?.sections?.filter(section => section.value !== "Chat" && section.value !== "Modifications History");
            const relevantSections = filteredSections?.filter(section => data?.additional_information?.some(info => info.section === section.id));

            // Add an empty row between the first section and other data
            docDefinition.content.push({ text: ' ', margin: [0, 5] });

            relevantSections.forEach((section) => {
                docDefinition.content.push({ text: `Section ${section.value}`, bold: true, margin: [0, 5], decoration: 'underline', lineHeight: 1.5 });
                data.additional_information.forEach(info => {
                    if (info.section === section.id) {
                        if (info.type === 'list' && Array.isArray(info.value)) {
                            docDefinition.content.push({ text: info.label, margin: [10, 5] });
                            info.value.forEach(item => {
                                docDefinition.content.push({ text: `${item.ItemLabel}: ${reverseArabicWords(item.ItemValue)}`, margin: [20, 5] });
                            });
                        } else {
                            if (Documents && Documents.length > 0) {
                                const matchingDocuments = Documents.filter(document => document.fieldId === info.id);
                                if (matchingDocuments.length > 0) {
                                    const fileNames = matchingDocuments.map(doc => doc.fileName).join(' / ');
                                    docDefinition.content.push({ text: `${info.label}: ${reverseArabicWords(info.value)} / ${fileNames}`, margin: [10, 5], alignment: 'left' });
                                } else {
                                    docDefinition.content.push({ text: `${info.label}: ${reverseArabicWords(info.value)}`, margin: [10, 5], alignment: 'left' });
                                }
                            } else {
                                docDefinition.content.push({ text: `${info.label}: ${reverseArabicWords(info.value)}`, margin: [10, 5], alignment: 'left' });
                            }
                        }
                    }
                });

                docDefinition.content.push({ text: ' ', margin: [0, 5] });
            });

            if (data.recommandation?.messages?.length > 0) {
                docDefinition.content.push({ text: 'Recommendation:', style: 'sectionHeader', fontSize: 16, bold: true, margin: [0, 5] });
                data.recommandation.messages.forEach(message => {
                    if (Documents && Documents.length > 0) {
                        const matchingDocuments = Documents.filter(document => document.fieldId === message.id);
                        if (matchingDocuments.length > 0) {
                            const fileNames = matchingDocuments.map(doc => doc.fileName).join(' / ');
                            docDefinition.content.push({ text: `${message.label}: ${reverseArabicWords(message.value)} / ${fileNames}`, margin: [10, 5], alignment: 'left' });
                        } else {
                            docDefinition.content.push({ text: `${message.label}: ${reverseArabicWords(message.value)}`, margin: [10, 5], alignment: 'left' });
                        }
                    } else {
                        docDefinition.content.push({ text: `${message.label}: ${reverseArabicWords(message.value)}`, margin: [10, 5], alignment: 'left' });
                    }
                });
            }

            pdfMake.createPdf(docDefinition).download(`${data.first_name}_${data.last_name}_medical_file.pdf`);
        } catch (error) {
            console.error('Error exporting to PDF:', error);
            CustomErrorLogger(error, 'Error exporting medical file to PDF');
            setErrorMessage('exportMedicalFileError');
        }
    }, [data, Documents]);

    const exportToExcel = useCallback(async () => {
        try {
            const rows = [
                ["Name", `${capitalizeFirstLetter(data.first_name)} ${capitalizeFirstLetter(data.last_name)}`],
                ["File Number", data.file_number],
                ["Birth Date", data.birthDate],
                ["Birth Place", data.BirthPlace],
                ["Address", data.address],
                ["Age", data.age],
                ["Phone Number", data.Phone_number],
                ["Gender", data.gender],
                []
            ];

            const filteredSections = data?.sections?.filter(section => section.value !== "Chat" && section.value !== "Modifications History");
            const relevantSections = filteredSections?.filter(section => data.additional_information?.some(info => info.section === section.id));

            relevantSections.forEach(section => {
                rows.push(['Section', section.value]);
                data.additional_information.forEach(info => {
                    if (info.section === section.id) {
                        if (info.type === 'list' && Array.isArray(info.value)) {
                            rows.push([info.label]);
                            info.value.forEach(item => {
                                rows.push([item.ItemLabel, item.ItemValue]);
                            });
                        } else {
                            // Include the file names within the field's value if Documents is defined and not empty
                            if (Documents && Documents.length > 0) {
                                const matchingDocuments = Documents.filter(document => document.fieldId === info.id);
                                if (matchingDocuments?.length > 0) {
                                    const fileNames = matchingDocuments.map(doc => doc.fileName).join(' / ');
                                    rows.push([`${info.label}: ${info.value} / ${fileNames}`]);
                                } else {
                                    rows.push([`${info.label}: ${info.value}`]);
                                }
                            } else {
                                rows.push([`${info.label}: ${info.value}`]);
                            }
                        }
                    }
                });
                rows.push([]);
            });

            if (data.recommandation?.messages?.length > 0) {
                rows.push(['Recommendation']);
                data.recommandation.messages.forEach(message => {
                    // Check if there are Documents with fieldId matching the message's id
                    if (Documents && Documents.length > 0) {
                        const matchingDocuments = Documents.filter(document => document.fieldId === message.id);
                        if (matchingDocuments?.length > 0) {
                            const fileNames = matchingDocuments.map(doc => doc.fileName).join(' / ');
                            rows.push([`${message.label}: ${message.value} / ${fileNames}`]);
                        } else {
                            rows.push([`${message.label}: ${message.value}`]);
                        }
                    } else {
                        rows.push([`${message.label}: ${message.value}`]);
                    }
                });
            }

            // Convert rows array to Excel sheet
            const ws = XLSX.utils.aoa_to_sheet(rows);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const buf = new ArrayBuffer(wbout?.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < wbout?.length; i++) {
                view[i] = wbout.charCodeAt(i) & 0xFF;
            }

            saveAs(new Blob([buf], { type: 'application/octet-stream' }), 'data.xlsx');

        } catch (error) {
            console.error('Error exporting to Excel:', error);
            CustomErrorLogger(error, 'Error exporting medical file to Excel');
            setErrorMessage('exportMedicalFileError');
        }
    }, [data, Documents]);

    useEffect(() => {
        if (!data) return;

        const { institutionId, doctorId, status, shareStatus, targetDoctorForShare, targetDoctorForShareId } = data;
        const allowedExportRecords = [];
        const mobileMenuRecords = [];

        const isSameInstitution = institutionId === currentInstitution?.institution?.id;
        const isFileOwner = doctorId === currentInstitution?.id;
        const isSharedFile = shareStatus === true;
        const sharedwithMyInstitution = targetDoctorForShare?.institutionId === currentInstitution?.institution?.id;
        const isSharedWithMe = targetDoctorForShareId === currentInstitution?.id;

        const isAllowedToEdit = (status !== 'Archived' && ((isAllowedEditInstitutionMedicalFile && isSameInstitution) || (isAllowedEditMyMedicalFile && isFileOwner)));
        const isAllowedToEditSharedFile = (status !== 'Archived' && isSharedFile && ((isAllowedEditInstitutionSharedMedicalFile && sharedwithMyInstitution) || (isAllowedEditUserShraredMedicalFile && isSharedWithMe)));
        const isAllowedExportPDFAndExcel = (isAllowedExportInstitutionMedicalFile && isSameInstitution) || (isAllowedExportMyMedicalFile && isFileOwner) || (isAllowedExportMedicalFileSharedWithMe && isSharedWithMe)
        const isAllowedExportZip = (isAllowedExportMedicalFile && isSameInstitution) || (isAllowedExportMedicalFileSharedWithMe && isSharedWithMe)
        const isAllowedCopy = (isAllowedCopyInstitutionMedicalFile && isSameInstitution) || (isAllowedCopyMyMedicalFile && isFileOwner);
        const isAllowedChangeOwner = isAllowedChangeMedicalFilesOwner && isSameInstitution
        const isAllowedDelete = (isAllowedDeleteAllMedicalFile || (isAllowedDeleteInstitutionMedicalFile && isSameInstitution) || (isAllowedDeleteMyMedicalFile && isFileOwner));

        if (isAllowedCopy) {
            mobileMenuRecords.push({ title: 'duplicateMedicalFileAction', icon: 'heroicons-solid:duplicate', action: () => navigate(`/dashboard/medicalFiles/copy/${id}`) });
        }

        if (isAllowedChangeOwner) {
            mobileMenuRecords.push({ title: 'transfer', icon: 'mdi:file-transfer-outline', action: () => setDialogTransfer(true) });
        }

        if (isAllowedExportPDFAndExcel) {
            allowedExportRecords.push(
                { title: 'ExcelExport', icon: 'vscode-icons:file-type-excel', action: () => exportToExcel() },
                { title: 'PDFExport', icon: 'vscode-icons:file-type-pdf2', action: () => exportToPDF() }
            );
            mobileMenuRecords.push(
                { title: 'ExcelExport', icon: 'vscode-icons:file-type-excel', action: () => exportToExcel() },
                { title: 'PDFExport', icon: 'vscode-icons:file-type-pdf2', action: () => exportToPDF() }
            );
        }

        if (isAllowedExportZip) {
            allowedExportRecords.push({ title: 'ZipExport', icon: 'bi:file-zip-fill', action: () => exportMedicalFileAsZip() });
            mobileMenuRecords.push({ title: 'ZipExport', icon: 'bi:file-zip-fill', action: () => exportMedicalFileAsZip() });
        }

        if (isAllowedDelete) {
            mobileMenuRecords.push({ title: 'dlt', icon: 'ic:round-delete', action: () => setOpenDelete(true) });
        }

        setAllowedActions({
            canEditPrivateMedicalFile: isAllowedToEdit,
            canEditSharedMedicalFile: isAllowedToEditSharedFile,
            canShareMedicalFile: (isAllowedShareInstitutionMedicalFile && isSameInstitution) || (isAllowedShareMyMedicalFile && isFileOwner),
            canUnShareMedicalFile: (isAllowedUnShareInstitutionMedicalFile && isSameInstitution) || (isAllowedUnShareMyMedicalFile && isFileOwner),
            canCopyMedicalFile: isAllowedCopy,
            canDeleteMedicalFile: isAllowedDelete,
            canChangeOwnerMedicalFile: isAllowedChangeOwner,
            canExportMedicalFile: isAllowedExportPDFAndExcel,
            canExportMedicalFileAsZip: isAllowedExportZip,
        });
        setAllowedExportActions(allowedExportRecords);
        setMobileMenuActions(mobileMenuRecords);

    }, [currentInstitution.id, currentInstitution?.institution?.id, data, exportMedicalFileAsZip, exportToExcel, exportToPDF,
        id, isAllowedChangeMedicalFilesOwner, isAllowedCopyInstitutionMedicalFile, isAllowedCopyMyMedicalFile,
        isAllowedDeleteAllMedicalFile, isAllowedDeleteInstitutionMedicalFile, isAllowedDeleteMyMedicalFile,
        isAllowedEditInstitutionMedicalFile, isAllowedEditInstitutionSharedMedicalFile, isAllowedEditMyMedicalFile,
        isAllowedEditUserShraredMedicalFile, isAllowedExportInstitutionMedicalFile, isAllowedExportMedicalFile,
        isAllowedExportMedicalFileSharedWithMe, isAllowedExportMyMedicalFile, isAllowedShareInstitutionMedicalFile,
        isAllowedShareMyMedicalFile, isAllowedUnShareInstitutionMedicalFile, isAllowedUnShareMyMedicalFile, navigate
    ]);

    const handleExportMenuClick = useCallback((event) => {
        setExportAnchorEl(event.currentTarget);
    }, []);

    const handleExportMenuClose = useCallback(() => {
        setExportAnchorEl(null);
    }, []);

    const handleMobileMenuClick = useCallback((event) => {
        setMobileAnchorEl(event.currentTarget);
    }, []);

    const handleMobileMenuClose = useCallback(() => {
        setMobileAnchorEl(null);
    }, []);

    const exportMenu = useMemo(() => (
        <Menu
            anchorEl={exportAnchorEl}
            open={exportMenuOpen}
            onClose={handleExportMenuClose}
            onClick={handleExportMenuClose}
            PaperProps={{
                sx: {
                    marginTop: '5px'
                },
            }}
        >
            {allowedExportActions.map((exportAction, index) => (
                <MenuItem key={index} value={exportAction.type} onClick={exportAction.action}>
                    <Iconify icon={exportAction.icon} style={{ marginRight: 8 }} />
                    {t(exportAction.title)}
                </MenuItem>
            ))}
        </Menu>
    ), [exportAnchorEl, exportMenuOpen, allowedExportActions, handleExportMenuClose, t]);

    const mobileMenu = useMemo(() => (
        <Menu
            anchorEl={mobileAnchorEl}
            open={mobileMenuOpen}
            onClose={handleMobileMenuClose}
            onClick={handleMobileMenuClose}
            PaperProps={{
                sx: {
                    marginTop: '5px'
                },
            }}
        >
            {mobileMenuActions.map((mobileAction, index) => (
                <MenuItem key={index} value={mobileAction.type} onClick={mobileAction.action}>
                    <Iconify icon={mobileAction.icon} style={{ marginRight: 8 }} />
                    {t(mobileAction.title)}
                </MenuItem>
            ))}
        </Menu>
    ), [mobileAnchorEl, mobileMenuOpen, mobileMenuActions, handleMobileMenuClose, t]);

    return (
        <Page title={t("Files_Details_Page_Title")}>
            {loading ?
                <Box className='onefile_loading'>
                    <CircularProgress size="5rem" />
                </Box> :
                <Box>
                    <Box className='onefile-top-bar'>

                        <Box display={'flex'} alignItems={'center'} gap={1}>
                            <Iconify icon='fluent:ios-arrow-24-filled' className='onefile-back-btn' onClick={() => navigate('/dashboard/medicalFiles')} />
                            <Typography className="imeahTitle imeah_persone_name">{`${data.first_name.toLowerCase()} ${data.last_name.toLowerCase()}`}</Typography>
                        </Box>

                        <Box display={'flex'} alignItems={'center'} gap={'17px'}>

                            {allowedActions.canUnShareMedicalFile && data.shareStatus &&
                                <Button
                                    className='buttonOutlined'
                                    onClick={() => setOpenUnShare(true)}
                                    startIcon={<Iconify icon="material-symbols:cancel-schedule-send" />}
                                >
                                    {t('unshare')}
                                </Button>
                            }

                            {allowedActions.canShareMedicalFile && !data.shareStatus &&
                                <Button
                                    className='buttonOutlined'
                                    onClick={() => setOpenShare(true)}
                                    startIcon={<Iconify icon="material-symbols:send" />}
                                >
                                    {t('send')}
                                </Button>
                            }

                            {allowedActions.canCopyMedicalFile && isDesktop &&
                                <Button
                                    className='buttonOutlined'
                                    onClick={() => navigate(`/dashboard/medicalFiles/copy/${id}`)}
                                    startIcon={<Iconify icon="heroicons-solid:duplicate" />}
                                >
                                    {t('duplicateMedicalFileAction')}
                                </Button>
                            }

                            {isDesktop && allowedActions.canChangeOwnerMedicalFile &&
                                <Button
                                    className='buttonOutlined'
                                    onClick={() => setDialogTransfer(true)}
                                    startIcon={<Iconify icon="mdi:file-transfer-outline" />}
                                >
                                    {t('transfer')}
                                </Button>
                            }

                            {(isDesktop && allowedExportActions.length > 1) ? (
                                <Button
                                    className='buttonOutlined'
                                    onClick={(event) => handleExportMenuClick(event)}
                                    startIcon={<Iconify icon="material-symbols:download" />}
                                    endIcon={<Iconify icon={exportMenuOpen ? 'iconamoon:arrow-down-2-light' : 'iconamoon:arrow-right-2-light'} />}
                                >
                                    {t('export_file')}
                                </Button>
                            ) : (isDesktop && allowedExportActions.length === 1) ? (
                                <Button
                                    className='buttonOutlined'
                                    onClick={allowedExportActions[0]?.action}
                                    startIcon={<Iconify icon={allowedExportActions[0]?.icon} />}
                                >
                                    {t(allowedExportActions[0]?.title)}
                                </Button>
                            ) :
                                null
                            }

                            {isDesktop && allowedActions.canDeleteMedicalFile &&
                                <Button
                                    className='buttonOutlined'
                                    onClick={() => setOpenDelete(true)}
                                    startIcon={<Iconify icon="ic:round-delete" />}
                                >
                                    {t('dlt')}
                                </Button>
                            }

                            {!isDesktop &&
                                <ImeahActionIcon icon='tabler:dots' color='#727179' onClick={(event) => handleMobileMenuClick(event)} />
                            }

                            {exportMenu}
                            {mobileMenu}
                        </Box>
                    </Box>

                    {/* display the static data ("personal data of patient") in the medical file  */}
                    <StaticData
                        medicalFileData={data}
                        setMedicalFileData={setdata}
                        setEditStaticData={setEditStaticData}
                        allowedActions={allowedActions}
                    />

                    <TabContext value={currentSection}>

                        {/* display the tabs of the medical file */}
                        <FileTabs data={data} setCurrentSection={setCurrentSection} />

                        <TabPanel value={currentSection} className='onefile-tabPanel'>

                            {/* {currentSection === 'Modifications_History123' &&
                                <MedicalFileLogs medicalFileData={data} />
                            } */}

                            {currentSection === 'discussion123' &&
                                <DiscussionMessages
                                    medicalFileId={data.id}
                                    canSendMessage={allowedActions.canEditPrivateMedicalFile || allowedActions.canEditSharedMedicalFile}
                                    setMedicalFileData={setdata}
                                />
                            }

                            {currentSection === "Expertise123" &&
                                <Recommandation
                                    medicalFileData={data}
                                    setMedicalFileData={setdata}
                                    Documents={Documents}
                                    setDocuments={setDocuments}
                                    allowedActions={allowedActions}
                                    setOpenShare={setOpenShare}
                                />
                            }

                            {currentSection !== 'Modifications_History123' &&
                                currentSection !== 'discussion123' &&
                                currentSection !== 'Expertise123' &&
                                <MedicalFileFields
                                    data={data}
                                    setdata={setdata}
                                    currentSection={currentSection}
                                    Documents={Documents}
                                    setDocuments={setDocuments}
                                    allowedActions={allowedActions}
                                    handleDeleteFieldOpen={handleDeleteFieldOpen}
                                    currentInstitution={currentInstitution}
                                    handleRemoveRedDotReceiver={handleRemoveRedDotReceiver}
                                    handleUpdateOneFieldData={handleUpdateOneFieldData}
                                    debouncedHandleUpdateOneFieldData={debouncedHandleUpdateOneFieldData}
                                    handleUpdateOneListFieldData={handleUpdateOneListFieldData}
                                    debouncedHandleUpdateOneListFieldData={debouncedHandleUpdateOneListFieldData}

                                />
                            }

                            {(currentSection === 'Complementary_Information123' && (allowedActions.canEditPrivateMedicalFile || allowedActions.canEditSharedMedicalFile)) &&
                                <AddComplementaryField
                                    fileId={data.id}
                                    setdata={setdata}
                                    setErrorMessage={setErrorMessage}
                                />
                            }

                        </TabPanel>
                    </TabContext >

                    {editStaticData &&
                        <EditStaticData
                            editStaticData={editStaticData}
                            setEditStaticData={setEditStaticData}
                            medicalFileData={data}
                            setMedicalFileData={setdata}
                        />
                    }

                    {openShare &&
                        <ShareFile
                            data={data}
                            setdata={setdata}
                            openShare={openShare}
                            setOpenShare={setOpenShare}
                        />
                    }

                    {openUnShare &&
                        <UnshareFile
                            data={data}
                            setdata={setdata}
                            openUnShare={openUnShare}
                            setOpenUnShare={setOpenUnShare}
                        />
                    }

                    {openDeleteField &&
                        <DeleteField
                            medicalFile={data}
                            setMedicalFile={setdata}
                            openDeleteField={openDeleteField}
                            setOpenDeleteField={setOpenDeleteField}
                            FieldId={FieldId}
                        />
                    }

                    {openDelete &&
                        <DeleteOne
                            id={id}
                            setOpenDelete={setOpenDelete}
                            openDelete={openDelete}
                        />
                    }

                    {dialogTransfer &&
                        <TransferOneFile
                            medicalFileData={data}
                            dialogTransfer={dialogTransfer}
                            setDialogTransfer={setDialogTransfer}
                        />
                    }

                    {/* {openToArchiveDialoge &&
                        <ToArchiveDialoge
                            data={data}
                            setdata={setdata}
                            openToArchiveDialoge={openToArchiveDialoge}
                            setOpenToArchiveDialoge={setOpenToArchiveDialoge}
                        />
                    } */}

                </Box >
            }

            {isAgreementDialogOpen &&
                <ShareAgreement
                    handleAgreeFilePolicies={handleAgreeFilePolicies}
                    isAgreementDialogOpen={isAgreementDialogOpen}
                    targetPolicyCountry={targetPolicyCountry}
                />
            }

            {isFileNotFoundDialogOpen &&
                <FileNotFound
                    isFileNotFoundDialogOpen={isFileNotFoundDialogOpen}
                    setFileNotFoundDialogOpen={setFileNotFoundDialogOpen}
                />
            }

            {errorMessage &&
                <ErrorDialog
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            }
        </Page >
    )
}