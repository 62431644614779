import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from "@mui/lab";
import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';

export const AddSpeciality = ({ openAdd, setOpenAdd, setSpecialities }) => {

    const { t } = useTranslation();

    const [englishName, setEnglishName] = useState('');
    const [frenchName, setFrenchName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const validationSchema = Yup.object().shape({
        englishName: Yup.string().trim().required(t('specilityLableRequired')),
    });

    const handleAddSpeciality = async () => {
        try {
            await validationSchema.validate({ englishName }, { abortEarly: false });

            setIsLoading(true);

            const bodyData = {
                enLanguage: englishName.trim(),
                frLanguage: frenchName.trim(),
            };

            const response = await axios.post('speciality', bodyData);

            if (response.status === 200 || response.status === 201) {
                setSpecialities((prevSpecialities) => [...prevSpecialities, response.data.specialityLabel[0]]);
                setOpenAdd(false);
                setEnglishName('');
                setFrenchName('');
                setErrMsg('');
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                // If validation fails, set the error message
                setErrMsg(err?.errors?.join(` ${t('and')} `));
            } else {
                // If API call fails, set the error message
                console.error('Error adding speciality:', err);
                setErrMsg(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddClose = () => {
        setEnglishName('');
        setFrenchName('');
        setOpenAdd(false);
        setErrMsg(null)
    };

    return (
        <Dialog open={openAdd} keepMounted aria-describedby="alert-dialog-slide-description">
            <DialogTitle className='page-title'>{t('AddNewSpeciality')}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    name="name"
                    label={t('Eng')}
                    type="text"
                    fullWidth
                    required
                    variant="outlined"
                    value={englishName}
                    onChange={(e) => setEnglishName(e.target.value)}
                    error={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName)}
                    helperText={!Yup.reach(validationSchema, 'englishName').isValidSync(englishName) && t('isRequired')}
                />
                <TextField
                    margin="dense"
                    id="name"
                    name="name"
                    label={t('Fr')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={frenchName}
                    onChange={(e) => setFrenchName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button className='new-button' onClick={handleAddClose}>{t('cancel')}</Button>
                <LoadingButton loading={isLoading} className='new-button' onClick={handleAddSpeciality}>{t('Create')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}