import { RegisterPasswordStrengthMeter } from "../../utils/registerPasswordStrengthMeter";
import { Typography, Alert, TextField, Link, Box, } from "@mui/material";
import { LanguageSwitcher } from "../NoLoginLanguageSwitcher";
import ImeahLogo from "../../images/logo_e_health_pro.svg";
import { useFormik, Form, FormikProvider } from "formik";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { MuiPhone } from "../../utils/muiPhone";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import * as Yup from "yup";
import Page from "../Page";
import axios from "axios";
import "./newRegisterForm.css";

export default function NewRegisterForm() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [language, setLanguage] = useState(navigator.language.startsWith('en') ? 'en' : 'fr');

  const handleLangUpdate = (langData) => {
    setLanguage(langData);
  }

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      hpassword: "",
    },

    validationSchema: Yup.object().shape({
      firstname: Yup.string().trim().required(t("fstnmReq")),
      lastname: Yup.string().trim().required(t("lstnmReq")),
      email: Yup.string().trim().email(t("validEml")).required(t("emailReq")),
      hpassword: Yup.string().trim().required(t("pwdReq")),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      setIsHuman(false);

      try {
        const response = await axios.post("auth/signup", { ...values, local_code: language });
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          navigate("/login");
        }
      } catch (err) {
        setErrMsg(err.message);
        setLoading(false);
      }
    },
  });

  const { values, handleBlur, handleSubmit } = formik;

  return (
    <Page title={t("register")} className="register-page">

      <Box className="register-Box"  >

        <Box display='flex' justifyContent='space-between' marginBottom='35px'>
          <Box sx={{ width: '200px' }} component="img" src={ImeahLogo} alt="Imeah Logo" />
          <LanguageSwitcher handleLangUpdate={handleLangUpdate} />
        </Box>

        {errMsg && <Alert sx={{ marginBottom: '25px' }} severity="error">{errMsg}</Alert>}

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} className="register-submitForm">

            <TextField
              className="register-inputs"
              fullWidth
              id="firstname"
              name="firstname"
              label={t("firstname")}
              autoComplete="firstname"
              value={values.firstname}
              onBlur={handleBlur}
              onChange={formik.handleChange}
              error={!values.firstname}
              helperText={!values.firstname && t('isRequired')}
            />

            <TextField
              className="register-inputs"
              fullWidth
              id="lastname"
              name="lastname"
              label={t("lastname")}
              autoComplete="lastname"
              value={values.lastname}
              onBlur={handleBlur}
              onChange={formik.handleChange}
              error={!values.lastname}
              helperText={!values.lastname && t('isRequired')}
            />

            <TextField
              className="register-inputs"
              fullWidth
              id="email"
              name="email"
              label={t("email")}
              autoComplete="email"
              value={values.email}
              type="email"
              onBlur={handleBlur}
              onChange={formik.handleChange}
              error={!values.email}
              helperText={!values.email && t('isRequired')}
            />

            <RegisterPasswordStrengthMeter password={values.hpassword} handleChange={formik.handleChange} showNewPassword={showNewPassword} setShowNewPassword={setShowNewPassword} />

            <MuiPhone
              required={true}
              name="phone"
              value={values.phone}
              placeholder={t('Phone_Number')}
              onChange={(value) => formik.setFieldValue("phone", value)}
            />

            <Box className="recapatchaBox">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={() => setIsHuman(true)}
              />
            </Box>

            <LoadingButton
              className="register-Button"
              loading={loading}
              type="submit"
              disabled={isHuman === false}
            >
              {t("register")}
            </LoadingButton>

            <Box className='loginLinkBox'>
              <Typography className="loginLinkLabel">{t("login")}</Typography>
              <Link className="loginLink" component={RouterLink} to="/login">{t("loginn")}</Link>
            </Box>

          </Form>
        </FormikProvider>
      </Box>
    </Page>
  );
}