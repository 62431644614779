import { Typography, TextField, Box } from '@mui/material';

export const Password = ({ info, allowedActions, debouncedHandleUpdateOneFieldData }) => {

    return (

        <Box display={'flex'} gap={'12px'} flexDirection={'column'}>

            <Typography className='medicalFileFields_Field_label' >{info.label}</Typography>

            <TextField
                className='customTextField'
                type="password"
                id={info.id}
                name={info.label}
                disabled={!allowedActions.canEditPrivateMedicalFile}
                defaultValue={typeof info.value === 'string' ? info.value : ''}
                onChange={event => debouncedHandleUpdateOneFieldData(info.id, event.target.value)}
            />

        </Box>
    );
};