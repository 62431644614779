import { Box, Table, Stack, LinearProgress, Grid, InputBase, TableRow, TableHead, TableBody, TableCell, Typography, useMediaQuery, InputAdornment } from '@mui/material';
import DeleteInstitutionTypes from '../components/InstitutionTypes/deleteInstitutionTypes';
import EditInstitutionTypes from '../components/InstitutionTypes/editInstitutionTypes';
import AddInstitutionTypes from '../components/InstitutionTypes/addInstitutionTypes';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CustomErrorLogger } from '../utils/CustomErrorLogger';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HasPermission } from "../utils/checkUserPermission";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import useDebounce from '../hooks/useDebounce';
import Iconify from '../components/Iconify';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import axios from 'axios';
import '../css/InstitutionsTypes.css';

export const InstitutionsTypes = () => {

  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const userData = useSelector(state => state.userData);
  //check user permissions
  const isAllowedCreateInstitutionsTypes = HasPermission('Create_InstitutionsTypes');
  const isAllowedEditInstitutionsTypes = HasPermission('Edit_InstitutionsTypes');
  const isAllowedDeleteInstitutionsTypes = HasPermission('Delete_InstitutionsTypes');

  const [institutionsTypes, setInstitutionsTypes] = useState([]);
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  //filtration states
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);
  //pagination state 
  const hasMore = useRef(null);
  const offset = useRef(0);
  const limit = 20;

  const fetchInstitutionsTypes = useCallback(async () => {
    try {
      const currentOffset = offset.current;
      const response = await axios.get('institutions-types', { params: { offset: currentOffset, limit, search: debouncedSearch } });
      setInstitutionsTypes((prevTypes) => (currentOffset === 0 ? response.data : [...prevTypes, ...response.data]));
      setLoading(false);
      offset.current = currentOffset + limit;
      hasMore.current = response.data.length === limit;
    } catch (err) {
      console.error('Error fetching institutions types:', err);
      CustomErrorLogger(err, 'Error fetching institutions types in institutions types main page');
      setLoading(false);
    }
  }, [limit, debouncedSearch]);

  useEffect(() => {
    setInstitutionsTypes([]);
    offset.current = 0;
    hasMore.current = true;
    fetchInstitutionsTypes();
  }, [userData.local_code, fetchInstitutionsTypes]);

  const handleChangeSearch = (searchWord) => {
    offset.current = 0;
    hasMore.current = true;
    setSearch(searchWord);
  };

  const handleDeleteOpen = useCallback((id) => {
    setId(id);
    setOpenDelete(true);
  }, []);

  const handleEditOpen = useCallback((item) => {
    setId(item.institutionTypeId);
    setOpenEdit(true);
  }, []);

  return (
    <Page title={t("InstitutionsTypes")}>

      <Stack className="institutionsTypes-main-page-header">
        <Box className="institutionsTypes-page-title-box">
          <Box className="institutionsTypes-page-title-icon-box">
            <Iconify className="institutionsTypes-page-title-icon" icon="icon-park-solid:hospital-four" />
            <Typography className='institutionsTypes-page-title'>{t("InstitutionsTypes")}</Typography>
          </Box>
          <InputBase
            className='institutionsTypes-page-search-input'
            id="outlined-password-input"
            label={t('search')}
            type="text"
            placeholder={t('search')}
            value={search || ""}
            onChange={(e) => handleChangeSearch(e.target.value)}
            endAdornment={
              search && (
                <InputAdornment position="end">
                  <CloseIcon sx={{ cursor: "pointer", color: "white" }} onClick={() => handleChangeSearch(null)} />
                </InputAdornment>
              )
            }
          />
        </Box>

        <Box className="institutionsTypes-page-action-box">
          {isAllowedCreateInstitutionsTypes &&
            <Box className='institutionsTypes-page-header-button' onClick={() => setOpenAdd(true)}>
              <Iconify icon="eva:plus-fill" className="plus-icon" />
              <Typography className="new-button-title">{t('Create')}</Typography>
            </Box>
          }
        </Box>
      </Stack>

      {loading ? <LinearProgress sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#17536B' }, backgroundColor: '#A8ADB8' }} /> :

        <Box>
          <InfiniteScroll
            dataLength={institutionsTypes.length}
            next={fetchInstitutionsTypes}
            hasMore={hasMore.current}
            loader={<div style={{ textAlign: 'center', marginTop: '10px' }}>{t('loading')}</div>}
          >
            {isDesktop &&
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='institutionsTypes-list-table-titles'>{t('type')}</TableCell>
                    {isAllowedEditInstitutionsTypes && <TableCell className='institutionsTypes-list-table-titles' >{t('Edit')}</TableCell>}
                    {isAllowedDeleteInstitutionsTypes && <TableCell className='institutionsTypes-list-table-titles'>{t('dlt')}</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {institutionsTypes && institutionsTypes.map((record, index) => {
                    return (
                      <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(145, 158, 171, 0.12)' : 'white' }}>
                        <TableCell width={'90%'} className="new-table-cell">{record.label}</TableCell>
                        {isAllowedEditInstitutionsTypes && <TableCell className="new-table-cell" align='center'><EditIcon onClick={() => handleEditOpen(record)} className='institutionsTypes-actions-btn' data-testid="editIcon" /></TableCell>}
                        {isAllowedDeleteInstitutionsTypes && <TableCell className="new-table-cell" align='center'><DeleteIcon onClick={() => handleDeleteOpen(record.institutionTypeId)} className='institutionsTypes-actions-btn' data-testid="deleteIcon" /></TableCell>}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            }

            {!isDesktop &&
              <Grid container spacing={2} justifyContent={'space-around'} padding={'10px'}>
                {institutionsTypes && institutionsTypes.map((record) => (
                  <Grid item xs={12} sm={4} key={record.id}>
                    <Box className="institutionsTypes-list-responsive-card-details">
                      <Typography className='institutionsTypes-list-responsive-card-data'>{record.label}</Typography>
                      <Box className='institutionsTypes-card-actions'>
                        {isAllowedEditInstitutionsTypes && <EditIcon className='institutionsTypes-actions-btn' onClick={() => handleEditOpen(record)} data-testid="editIcon" />}
                        {isAllowedDeleteInstitutionsTypes && <DeleteIcon onClick={() => handleDeleteOpen(record.institutionTypeId)} className='institutionsTypes-actions-btn' data-testid="deleteIcon" />}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            }

          </InfiniteScroll>
        </Box>
      }

      {openAdd &&
        <AddInstitutionTypes
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          institutionsTypes={institutionsTypes}
          setInstitutionsTypes={setInstitutionsTypes}
        />
      }

      {openEdit &&
        <EditInstitutionTypes
          id={id}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          setInstitutionsTypes={setInstitutionsTypes}
        />
      }

      {openDelete &&
        <DeleteInstitutionTypes
          id={id}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          setInstitutionsTypes={setInstitutionsTypes}
        />
      }

    </Page>
  );
};