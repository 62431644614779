import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TabList from '@mui/lab/TabList';
import { Box } from '@mui/material';
import Iconify from '../../Iconify';
import Tab from '@mui/material/Tab';

const FileTabs = ({ data, setCurrentSection }) => {

    const { t } = useTranslation();
    const currentInstitution = useSelector(state => state.currentInstitution);

    const handleChange = useCallback((event, newValue) => {
        setCurrentSection(newValue);
        localStorage.setItem('candidateSection', JSON.stringify({ tabId: newValue, fileId: data.id }));
    }, [data.id, setCurrentSection]);

    const filteredSections = useMemo(() =>
        data?.sections?.filter((section) => section.id !== 'Modifications_History123'),
        [data?.sections]
    );

    // Check if a section should display a red dot
    const shouldShowRedDot = useCallback((sectionId) =>
        sectionId === 'Complementary_Information123' &&
        data?.additional_information?.some((item) => item.TargetRedDot?.some((target) => target.trim() === currentInstitution.id)),
        [data?.additional_information, currentInstitution.id]
    );

    const tabs = useMemo(() => filteredSections.map((section) => {
        // const isModificationsHistory = section.id === "Modifications_History123";

        return (
            <Tab
                key={section.id}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
                        {/* {isModificationsHistory ? (
                            <Iconify icon="tdesign:history-setting" className="onfile-headerBtn-icon" />
                        ) :
                            t(section.value)
                        } */}
                        {t(section.value.toLowerCase())}
                        {shouldShowRedDot(section.id) && <Iconify icon="noto:red-circle" sx={{ marginLeft: 1 }} />}
                    </Box>
                }
                sx={{ textTransform: 'none', padding: '0px 0px 3px 0px', marginRight: '12px' }}
                value={section.id}
            />
        );
    }), [filteredSections, t, shouldShowRedDot]);

    return (
        <TabList
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            onChange={handleChange}
            sx={{
                marginBottom: '20px',
                '.MuiTabs-indicator': { backgroundColor: '#17536B' },
                '.MuiTab-root.Mui-selected': { color: '#45414A' },
            }}
        >
            {tabs}
        </TabList>
    );
};

export default React.memo(FileTabs);