import React, { useRef, useState, useCallback, useMemo } from 'react';
import { Box, MenuItem, Menu, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

const languages = [
    { value: 'en', label: 'Eng', icon: '/images/ic_flag_en.svg' },
    { value: 'fr', label: 'Fr', icon: '/images/ic_flag_fr.svg' },
];

export const LanguageSwitcher = ({ handleLangUpdate }) => {

    const { t } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState(i18next.language || 'en');
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorRef = useRef(null);
    const langMenuOpen = Boolean(anchorEl);

    const handleOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handleClose = useCallback(() => setAnchorEl(null), []);

    const handleChangeLanguage = (newLanguage) => {
        if (handleLangUpdate) {
            handleLangUpdate(newLanguage);
        }
        setSelectedLanguage(newLanguage);
        i18next.changeLanguage(newLanguage);
        setAnchorEl(null);
    }

    const selectedLanguageDetails = useMemo(() => {
        return languages?.find((language) => language.value === selectedLanguage) || languages[0];
    }, [selectedLanguage]);

    return (
        <Box>
            <IconButton
                data-testid="flagIcon"
                onClick={handleOpen}
                ref={anchorRef.current}
                sx={{ padding: 0, width: 44, height: 44 }}
            >
                <img src={selectedLanguageDetails?.icon} alt={selectedLanguageDetails?.label} />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={langMenuOpen}
                onClose={handleClose}
                onClick={handleClose}
            >
                {languages.map((language, index) => (
                    <MenuItem
                        key={index}
                        selected={language?.value === selectedLanguage}
                        onClick={() => handleChangeLanguage(language.value)}
                    >
                        <Box component="img" alt={language?.label} src={language?.icon} sx={{ width: 28, mr: 2 }} />
                        {t(language.label)}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}