import { LinearProgress, Typography, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PasswordStrengthMeter = ({ newPassword }) => {

    const { t } = useTranslation();
    const [strength, setStrength] = useState('');

    const strongRegex = React.useMemo(() => new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{16,})"), []);
    const mediumRegex = React.useMemo(() => new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.{12,})"), []);

    useEffect(() => {
        if (strongRegex.test(newPassword)) {
            setStrength('Strong');
        } else if (mediumRegex.test(newPassword)) {
            setStrength('Medium');
        } else {
            setStrength('Weak');
        }
    }, [mediumRegex, newPassword, strongRegex, t]);

    const getStrengthColor = (strength) => {
        switch (strength) {
            case 'Strong':
                return 'success';
            case 'Medium':
                return 'warning';
            case 'Weak':
                return 'error';
            default:
                return 'primary';
        }
    };

    const getStrengthValue = (strength) => {
        switch (strength) {
            case 'Strong':
                return 100;
            case 'Medium':
                return 66;
            case 'Weak':
                return 33;
            default:
                return 0;
        }
    };

    return (
        <Box>
            <LinearProgress variant="determinate"
                value={getStrengthValue(strength)}
                color={getStrengthColor(strength)}
            />
            <Typography variant="body2" color="textSecondary">{t(strength)}</Typography>
        </Box>
    );
};