/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from "react-international-phone";
import { useTranslation } from 'react-i18next';

export const MuiPhone = ({ value, onChange, placeholder, required, ...restProps }) => {
    
    const { t } = useTranslation();

    const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
        value,
        countries: defaultCountries,
        onChange: (data) => {
            if (data.phone !== `+${data.country.dialCode}`) {
                onChange(data.phone);
            }
        }
    });
    
    const [rawValue, setRawValue] = useState(phone);

    useEffect(() => {
        if (!value) {
            const fetchCountry = async () => {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                setCountry(data.country_code.toLowerCase());
                setRawValue(data.country_calling_code)
            };
            fetchCountry().catch(console.error);
        }
    }, []);

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setRawValue(newValue);
        handlePhoneValueChange(event);
    };

    const handleCountryChange = (event) => {
        const newCountryIso2 = event.target.value;
        setCountry(newCountryIso2);
        const transformedDefaultCountries = defaultCountries.map(([name, iso2, dialCode]) => ({
            name,
            iso2,
            dialCode
        }));
        const newCountry = transformedDefaultCountries.find((c) => c.iso2 === event.target.value);
        if (newCountry) {
            setRawValue(`+${newCountry.dialCode}`);
        }
    };

    return (
        <TextField
            fullWidth
            margin="dense"
            required={required}
            variant="outlined"
            label={placeholder}
            color="primary"
            placeholder={placeholder}
            value={rawValue}
            onChange={handleInputChange}
            type="tel"
            inputRef={inputRef}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" style={{ marginRight: "2px", marginLeft: "-8px" }}>
                        <Select
                            MenuProps={{
                                style: {
                                    height: "300px",
                                    width: "360px",
                                    top: "10px",
                                    left: "-34px"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                }
                            }}
                            sx={{
                                width: "max-content",
                                fieldset: {
                                    display: "none"
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: "block"
                                    }
                                },
                                ".MuiSelect-select": {
                                    padding: "8px",
                                    paddingRight: "24px !important"
                                },
                                svg: {
                                    right: 0
                                }
                            }}
                            value={country.iso2}
                            onChange={handleCountryChange}
                            renderValue={(value) => (
                                <FlagImage iso2={value} style={{ display: "flex" }} />
                            )}
                        >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                    <MenuItem key={country.iso2} value={country.iso2}>
                                        <FlagImage
                                            iso2={country.iso2}
                                            style={{ marginRight: "8px" }}
                                        />
                                        <Typography marginRight="8px">{country.name}</Typography>
                                        <Typography color="gray">+{country.dialCode}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </InputAdornment>
                )
            }}
            error={rawValue === `+${country.dialCode}` && required}
            helperText={(rawValue === `+${country.dialCode}` && required) && (t('isRequired'))}
            {...restProps}
        />
    );
};
