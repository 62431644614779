import { createSlice } from '@reduxjs/toolkit';

const institutionsSlice = createSlice({
    name: 'institutions',
    initialState: [],
    reducers: {
        setInstitutions: (state, action) => action.payload
    },
});

export const { setInstitutions } = institutionsSlice.actions;

export default institutionsSlice.reducer;