import { MultiSelect } from '../components/medicalFile/fieldTypes/multiSelect';
import { MultiValue } from '../components/medicalFile/fieldTypes/multiValue';
import { FieldSelect } from '../components/medicalFile/fieldTypes/select';
import { LongText } from '../components/medicalFile/fieldTypes/longText';
import { Password } from '../components/medicalFile/fieldTypes/password';
import { File } from '../components/medicalFile/fieldTypes/file/file';
import { Number } from '../components/medicalFile/fieldTypes/number';
import { Text } from '../components/medicalFile/fieldTypes/Text';
import { Date } from '../components/medicalFile/fieldTypes/date';

export const InfoSwitch = (info) => {
    switch (info.type) {
        case 'text':
            return Text;
        case 'number':
            return Number;
        case 'date':
            return Date;
        case 'long Text':
            return LongText;
        case 'password':
            return Password;
        case 'select':
            return FieldSelect;
        case 'Multi_select':
            return MultiSelect;
        case 'Multi_value':
            return MultiValue;
        case 'file':
            return File;
        default:
            return null;
    }
}