import React, { useState } from 'react';
import Iconify from '../components/Iconify';
import { ImeahStyledBudge } from './imeah_styled_budge';
import { Box, Typography, TableSortLabel, Menu, Slider } from '@mui/material';

const TableHeaderCell = ({
    columnKey,
    columnWidth,
    label,
    orderActive,
    orderBy,
    handleSort,
    handleFilterIconClick,
    filterActive,
    filterInvisible,
    filterKey,
    onColumnWidthChange
}) => {
    const [anchorSlider, setAnchorSlider] = useState(null);

    const handleOpenSlider = (event) => {
        setAnchorSlider(event.currentTarget);
    };

    const handleCloseSlider = () => {
        setAnchorSlider(null);
    };

    return (
        <Box
            className='imeah_tableHeaderCell_box'
            sx={{
                minWidth: columnWidth,
                maxWidth: columnWidth,
                position: columnKey === 'name' ? 'sticky' : 'relative',
                zIndex: columnKey === 'name' ? '2' : '1',
                left: columnKey === 'name' ? 0 : 'auto'
            }}
        >
            <Box sx={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Iconify
                    icon='ix:width'
                    sx={{ cursor: 'pointer', fontSize: '18px' }}
                    onClick={handleOpenSlider}
                />
            </Box>
            {orderActive ?
                <TableSortLabel
                    active={orderBy !== null}
                    direction={orderBy || 'asc'}
                    hideSortIcon={orderBy === null}
                    sx={{ cursor: 'auto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                    {label}
                </TableSortLabel>
                :
                <Typography sx={{ fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{label}</Typography>
            }
            {orderActive &&
                <Box sx={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Iconify
                        icon='ic:round-sort-by-alpha'
                        className='imeah_filter_icon'
                        onClick={handleSort}
                    />
                </Box>
            }
            {filterActive &&
                <ImeahStyledBudge variant="dot" invisible={filterInvisible}>
                    <Iconify
                        icon='material-symbols:filter-alt'
                        className='imeah_filter_icon'
                        onClick={(e) => handleFilterIconClick(e, filterKey)}
                        data-id={filterKey}
                    />
                </ImeahStyledBudge>
            }

            <Menu
                id="simple-menu"
                anchorEl={anchorSlider}
                keepMounted
                open={Boolean(anchorSlider)}
                onClose={handleCloseSlider}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ width: '300px', padding: '20px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography id="discrete-slider" gutterBottom>
                            modifier la largeur de la colonne
                        </Typography>
                        <Slider
                            onChange={(e, value) => onColumnWidthChange(value, columnKey)}
                            defaultValue={columnWidth}
                            getAriaValueText={(value) => `${value}px`}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={10}
                            marks
                            min={100}
                            max={800}
                        />
                    </Box>
                </Box>
            </Menu>
        </Box>
    );
};

export default TableHeaderCell;