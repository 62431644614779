import { Dialog, DialogActions, DialogTitle, Button, Alert, Box } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export const DeletePermissionGroup = ({ openDeleteGroup, setOpenDeleteGroup, setAllGroups, id }) => {

    const { t } = useTranslation();

    const [errMsg, setErrMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Close the dialog and reset the error message
    const handleDeleteClose = useCallback(() => {
        setOpenDeleteGroup(false);
        setErrMsg(null);
    }, [setOpenDeleteGroup]);

    // Handle role deletion
    const handleDelete = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.delete(`permission-entity/${id}`);
            if (response.status === 200 || response.status === 201) {
                setAllGroups((prevgrp) => prevgrp.filter((item) => item.id !== id));
                handleDeleteClose();
            }
        } catch (err) {
            console.error('Error deleting role:', err);
            setErrMsg(err.message);
        } finally {
            setIsLoading(false);
        }
    }, [id, setAllGroups, handleDeleteClose]);

    return (
        <Dialog open={openDeleteGroup} keepMounted aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="delete-role-dialog-title">{errMsg ? t("canNotRemove") : t('sureDeletegroup')}</DialogTitle>
            {errMsg && <Alert severity="error">{errMsg}</Alert>}
            <DialogActions>
                {errMsg ?
                    <Button className="new-button" onClick={handleDeleteClose}>{t("ok")}</Button>
                    :
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Button className='new-button' onClick={handleDeleteClose}>{t('cancel')}</Button>
                        <Button className='new-button' disabled={isLoading} onClick={handleDelete}>{t('dlt')}</Button>
                    </Box>
                }
            </DialogActions>
        </Dialog>
    )
}