/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Card } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import timezone from 'dayjs/plugin/timezone';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import utc from 'dayjs-plugin-utc';
import dayjs from 'dayjs';

export const ReadUserAvailability = ({ availability, inVaccation, setInVaccation, setSection, userInfo }) => {

    const { t } = useTranslation();
    const userData = useSelector(state => state.userData);

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const currentDate = new Date();
    const endVaccationDate = new Date(inVaccation.endVaccation);

    const availableDays = [];

    for (const [day, info] of Object.entries(availability)) {
        if (info.checked === true) {

            const localFrom = convertUtcToLocalTime(info.from)
            const localTo = convertUtcToLocalTime(info.to)

            availableDays.push(`${t('on')} ${t(day)} ${t('from')} ${localFrom} ${t('to')} ${localTo}`);
        }
    }

    function convertUtcToLocalTime(utcTime) {
        if (!utcTime) {
            return "";
        }
        const [utcHours, utcMinutes] = utcTime.split(':').map(Number);
        const utcDate = new Date();
        utcDate.setUTCHours(utcHours);
        utcDate.setUTCMinutes(utcMinutes);

        // Convert UTC time to local time using the browser's time zone
        return utcDate.toLocaleTimeString([], {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    }

    useEffect(() => {
        if (endVaccationDate < currentDate) {
            setInVaccation({ ...inVaccation, checked: 'false', endVaccation: '' });
        }
    }, [inVaccation.checked === 'true']);

    if ((inVaccation.checked === false || inVaccation.checked === 'false') && availableDays.length === 0) {
        return null;
    }

    return (
        <Box className="userCv-main-box">
            <Card sx={{ display: 'flex', flexDirection: 'column', gap: '25px', position: 'relative', width: '100%', padding: '5%' }}>
                <Typography className="userCv-main-typo">{t('availability')}</Typography>
                {userData.id === userInfo.id && <EditIcon sx={{ fontSize: '28px', cursor: 'pointer', position: 'absolute', right: '5%', top: '5%' }} onClick={() => { setSection("Availability") }} data-testid="editIcon" />}
                {inVaccation.checked === 'true' && <Typography variant="h6" sx={{ backgroundColor: '#17536B', color: 'white', borderRadius: '7px', padding: '5px', marginTop: '30px' }}>{t('currentlyInVaccation')} {t('till')} {inVaccation.endVaccation}</Typography>}

                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '10px', marginTop: '20px' }}>
                    {availableDays.map((dayInfo, index) => (
                        <Typography key={index} sx={{ backgroundColor: '#c9dcdd', borderRadius: '7px', padding: '5px', color: 'black' }}>{dayInfo}</Typography>
                    ))}
                </Box>
            </Card>
        </Box>
    )
}