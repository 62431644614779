import ImeahActionIcon from '../../../../utils/imeah_action_icon';
import DisplayFileDocument from '../../displayFileDocument';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import '../../../../css/medicalFiles/fieldType/file.css';

const DocumentBox = ({ fileDocument, handleDownload, handleDeleteDocument, isFieldEnabled }) => {

    const [openFileDocunent, setOpenFileDocument] = useState(false);
    const [targetFileKey, setTargetFileKey] = useState(null);

    const fileExtension = fileDocument?.document?.fileName?.split('.').pop() || 'unknown';

    const handleOpenDocumentDialog = (fileKey) => {
        setTargetFileKey(fileKey);
        setOpenFileDocument(true);
    };

    return (
        <Box>
            <Box key={fileDocument.id} className='fieldType-file-document-details-box' data-testid="downloadFile">
                <Box className='fieldType-file-document-data-container' onClick={() => handleOpenDocumentDialog(fileDocument?.document?.fileKey)}>
                    <Box className='fieldType-file-icon-box'>
                        <Typography variant="subtitle2">{fileExtension}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" className="fieldType-file-doc-title">
                            {fileDocument.document?.fileName.substring(0, 25)}...
                        </Typography>
                        <Typography variant="subtitle2" className="fieldType-file-doc-title" color={'#8E8E93'}>
                            {fileDocument.document?.size} KB
                        </Typography>
                    </Box>
                </Box>
                <Box display={'flex'} gap={1}>
                    <ImeahActionIcon icon="material-symbols:download" color='#727179' onClick={() => handleDownload(fileDocument.document)} />
                    {isFieldEnabled && <ImeahActionIcon icon="ic:round-delete" color='#727179' onClick={() => handleDeleteDocument(fileDocument)} />}
                </Box>

            </Box>

            {openFileDocunent && targetFileKey === fileDocument?.document?.fileKey &&
                <DisplayFileDocument
                    openFileDocunent={openFileDocunent}
                    setOpenFileDocument={setOpenFileDocument}
                    documentFileData={{
                        fileName: fileDocument?.document?.fileName,
                        fileKey: fileDocument?.document?.fileKey
                    }}
                />
            }
        </Box>
    )
}

export default React.memo(DocumentBox); 