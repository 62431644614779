import React, { useEffect, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import L from 'leaflet';

export const ProfileSkillsSummary = ({ userInfo, skills, summary, setSection }) => {

  const { t } = useTranslation();

  const userData = useSelector(state => state.userData);
  const [distance, setDistance] = useState(null);
  const isUserAuthenticated = userData?.id === userInfo?.id;
  const showDistance = userData?.location !== null && userInfo?.location !== null && userInfo?.id !== userData?.id;

  const calculateDistance = useCallback(() => {
    if (userInfo?.location && userData?.location) {
      const locationA = L.latLng(userInfo.location.lat, userInfo.location.lng);
      const locationB = L.latLng(userData.location.lat, userData.location.lng);
      const calculatedDistance = locationA.distanceTo(locationB) / 1000;
      setDistance(calculatedDistance);
    }
  }, [userInfo, userData]);

  useEffect(() => {
    calculateDistance();
  }, [calculateDistance, userInfo, userData]);

  const renderEditIcon = (section) => isUserAuthenticated && (
    <EditIcon className='new-delete-icon' onClick={() => setSection(section)} />
  );

  const renderSkills = () => (
    <Box className="account-prof-card-skills-part">
      <Box className="account-prof-card-skills-box">
        <Typography className="account-prof-card-skills-typo">{t('userSkills')}</Typography>
        {renderEditIcon('Skills')}
      </Box>
      <Box className="account-prof-card-skills-items">
        {skills && skills.length > 0 ? (
          skills.map((skill, index) => (
            <Typography key={index} className='onefile-additional-info-select-data'>{skill}</Typography>
          ))
        ) : (
          <Typography className='onefile-additional-info-select-data'>{t('noSkills')}</Typography>
        )}
      </Box>
    </Box>
  );

  const renderSummary = () => (
    <Box className="account-prof-card-skills-part">
      <Box className="account-prof-card-skills-box">
        <Typography className="account-prof-card-skills-typo">{t('summary')}</Typography>
        {renderEditIcon('Summary')}
      </Box>
      <Typography className="account-prof-card-summary-typo">
        {summary ? summary : t('noSummary')}
      </Typography>
    </Box>
  );

  const renderLocation = () => (
    <Box className="account-prof-card-skills-part">
      <Box className="account-prof-card-skills-box">
        <Typography className="account-prof-card-skills-typo">{t('location')}</Typography>
        {renderEditIcon('location')}
      </Box>
      <Typography>{userInfo?.location?.name ? userInfo.location.name : t('noLocation')}</Typography>
    </Box>
  );

  return (
    <Box className="account-prof-card-summary-skills-box">
      {renderSkills()}
      {renderSummary()}
      {renderLocation()}
      {showDistance && distance && (<Typography data-testid='distance'>{t('distanceWithYou')}: {distance.toFixed(2)} Km</Typography>)}
    </Box>
  );
};