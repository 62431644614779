export const CanManageTemplate = (template, userData) => {

    const { filteredPermissions, institutionId, id } = userData;
    const { template_owners } = template;

    const userPermissions = filteredPermissions || [];
    const templateOwners = template_owners || [];

    const isAllowedManageMyInstitutionTemplate = userPermissions?.some(item => item.trim() === 'manage_myInstitution_template') ?? false;
    const isAllowedManageMyOwnTemplate = userPermissions?.some(item => item.trim() === 'manage_myOwn_template') ?? false;

    const isSameInstitution = template.institutionId === institutionId;
    const isTemplateOwner = templateOwners.some(data => data.user?.id === id);

    // Return the result of the conditions
    return (
        (isAllowedManageMyInstitutionTemplate && isSameInstitution) ||
        (isAllowedManageMyOwnTemplate && isSameInstitution && isTemplateOwner)
    );
}